import TimelineSkeleton from "components/Timeline/TimelineSkeleton";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  container: {
    display: "flex",
  },
  sidebar: {
    display: "none",
    height: "210rem",
    width: "18.75rem",

    ...gStyles.skeletonPlaceholder,
    borderRadius: 8,
    color: colours.grey,

    [ScreenSizes.lgAndAbove]: {
      display: "block",
    },
  },
};

const TIMELINE_STYLES = {
  loadingContainer: {
    width: "100%",

    [ScreenSizes.lgAndAbove]: {
      width: "calc(100% - 300px)",

      paddingRight: "10%",
      paddingLeft: "2rem",
    },
  },
};

function DashboardSkeleton(props) {
  const { styles, css } = useStyles(baseStyles, props);

  return (
    <div className={css(styles.container)}>
      <TimelineSkeleton styles={TIMELINE_STYLES} />
      <div className={css(styles.sidebar)} />
    </div>
  );
}

export default DashboardSkeleton;
