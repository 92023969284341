import { listEntity } from "api/schema/creator_podcast";
import { List } from "immutable";
import { denormalize } from "normalizr";
import { createSelector } from "reselect";

import { CREDIT_SUBMISSION_TYPE } from "components/Creator/CreatorEditorNew/Model/CreditTypes";

import { selectAllEntities } from "selectors/app";
import { selectAuthUser } from "selectors/auth";
import groupBy from "utils/arrays/groupBy";

export const selectCreators = (state) =>
  state.creator ? state.creator.getIn(["creators", "entities"]) : List();

export const selectSpecificCreator = (state, id = "") =>
  state.creator?.getIn(["creators", "entities", id?.toString()]);
const selectSpecificCreatorMemoized = createSelector(
  [selectCreators, (_, creatorId) => creatorId],
  (creators, creatorId) => creators.get(creatorId?.toString())
);
export const makeSelectSpecificCreator = () => selectSpecificCreatorMemoized;

export const selectCreatorLoading = (state, id = "") =>
  state.creator?.getIn(["loading", id?.toString()]) || false;
export const selectCreatorFailed = (state, id = "") =>
  (state.creator &&
    state.creator.getIn(["creators", "failed", id?.toString()])) ||
  false;

export const selectCreatorEditingID = (state) =>
  state.getIn(["creators", "editing"]);
export const selectCreatorEditing = (state) =>
  selectSpecificCreator(state, selectCreatorEditingID(state));

export const selectCreatorCredits = (state, creator_id) =>
  state.creator &&
  state.creator.getIn(
    ["creators", "entities", creator_id?.toString(), "credits"],
    []
  );

export const selectSpecificCredit = (state, id) =>
  state.creator?.getIn(["credits", "entities", id?.toString()]);

const selectCredits = (state) => state.creator?.getIn(["credits", "entities"]);
const selectSubmissionCredits = (state) =>
  state.creator?.getIn(["credit_submissions", "entities"]);

const selectCreditsOfAnEpisode = createSelector(
  [selectCredits, (_, episodeId) => episodeId],
  (allCredits, episodeId) =>
    allCredits.filter((elem) => elem.get("episode_id") === episodeId)
);
export const makeSelectCreditsOfAnEpisode = () => selectCreditsOfAnEpisode;

const selectPodcastCredits = (state) =>
  state.creator?.getIn(["podcastCredits", "entities"]);
export const selectPodcastCreditLoading = (state) =>
  state.creator?.getIn(["podcastCredits", "loading"]);
export const selectPodcastCreditSubmitting = (state) =>
  state.creator?.getIn(["podcastCredits", "submitting"]);
export const selectPodcastCreditError = (state) =>
  state.creator?.getIn(["podcastCredits", "error"]);

const selectSpecificPodcastCredit = createSelector(
  [selectPodcastCredits, (_, creditId) => creditId],
  (podcastCredits, creditId) => podcastCredits.get(creditId?.toString())
);
export const makeSelectSpecificPodcastCredit = () =>
  selectSpecificPodcastCredit;

const selectPodcastCreditByPodcastId = createSelector(
  [selectPodcastCredits, (_, podcastId) => podcastId],
  (podcastCredits, podcastId) =>
    podcastCredits.find(
      (elem) => elem.get("podcast_id") === podcastId?.toString()
    )
);
export const makeSelectPodcastCreditByPodcastId = () =>
  selectPodcastCreditByPodcastId;

const selectAddedSubmissionCredits = createSelector(
  [selectSubmissionCredits],
  (allCredits) =>
    allCredits.filter(
      (elem) => elem.get("operation_type") === "addEpisodeCredits"
    )
);

const selectRemovedSubmissionCredits = createSelector(
  [selectSubmissionCredits],
  (allCredits) =>
    allCredits.filter(
      (elem) => elem.get("operation_type") === "removeEpisodeCredits"
    )
);

const filterSubmissionCreditsByEpisodeId = (submissionCredits, episodeId) =>
  submissionCredits.filter((elem) => {
    if (elem.get("episode_ids")) {
      return elem.get("episode_ids")?.includes(episodeId);
    }

    return false;
  });

const selectAddedSubmissionCreditsOfAnEpisode = createSelector(
  [selectAddedSubmissionCredits, (_, episodeId) => episodeId],
  (allCredits, episodeId) =>
    filterSubmissionCreditsByEpisodeId(allCredits, episodeId)
);
export const makeSelectAddedSubmissionCreditsOfAnEpisode = () =>
  selectAddedSubmissionCreditsOfAnEpisode;

const selectRemovedSubmissionCreditsOfAnEpisode = createSelector(
  [selectRemovedSubmissionCredits, (_, episodeId) => episodeId],
  (allCredits, episodeId) =>
    filterSubmissionCreditsByEpisodeId(allCredits, episodeId)
);
export const makeSelectRemovedSubmissionCreditsOfAnEpisode = () =>
  selectRemovedSubmissionCreditsOfAnEpisode;

const selectRecurringCredits = (state) =>
  state.creator?.getIn(["recurringCredits", "entities"]);
export const selectRecurringCreditsLoading = (state) =>
  state.creator?.getIn(["recurringCredits", "loading"]);
export const selectRecurringCreditsSubmitting = (state) =>
  state.creator?.getIn(["recurringCredits", "submitting"]);
export const selectRecurringCreditsError = (state) =>
  state.creator?.getIn(["recurringCredits", "error"]);
export const selectRecurringCreditsErrorMessage = (state) =>
  state.creator?.getIn(["recurringCredits", "errorMessage"]);

const selectSpecificRecurringCredit = createSelector(
  [selectRecurringCredits, (_, recurringCreditId) => recurringCreditId],
  (recurringCredits, recurringCreditId) =>
    recurringCredits.get(recurringCreditId?.toString())
);
export const makeSelectSpecificRecurringCredit = () =>
  selectSpecificRecurringCredit;

const selectRecurringCreditByPodcastId = createSelector(
  [selectRecurringCredits, (_, podcastId) => podcastId],
  (recurringCredits, podcastId) =>
    recurringCredits.find(
      (elem) => elem.get("podcast_id")?.toString() === podcastId?.toString()
    )
);
export const makeSelectRecurringCreditByPodcastId = () =>
  selectRecurringCreditByPodcastId;

const selectSpecificRecurringCreditByCreditId = createSelector(
  [
    (state, podcastId) => selectRecurringCreditByPodcastId(state, podcastId),
    (_, _podcastId, recurringCreditId) => recurringCreditId,
  ],
  (recurringCredit, recurringCreditId) =>
    recurringCredit
      ?.get("roles")
      .find((role) => role.get("recurring_credit_id") === recurringCreditId)
);

const selectRoleOfRecurringCreditByRecurringCreditId = createSelector(
  [
    (state, podcastId, recurringCreditId) =>
      selectSpecificRecurringCreditByCreditId(
        state,
        podcastId,
        recurringCreditId
      ),
    (state, _podcastId, _recurringCreditId, roleCode) =>
      selectSpecificRoleByCode(state, roleCode),
  ],
  (recurringCreditRole, roleInfo) => {
    if (recurringCreditRole) {
      return roleInfo.mergeDeep(recurringCreditRole);
    }

    return roleInfo ? roleInfo : null;
  }
);

export const makeSelectRoleOfRecurringCreditByRecurringCreditId = () =>
  selectRoleOfRecurringCreditByRecurringCreditId;

const selectSubmissionCreditsByPodcastIdAndCreatorId = createSelector(
  [
    selectSubmissionCredits,
    (_, podcastId) => podcastId,
    (_, _podcastId, creatorId) => creatorId,
  ],
  (allSubmittedCredits, podcastId, creatorId) =>
    allSubmittedCredits.filter(
      (creditSubmission) =>
        creditSubmission.get("creator_id") === creatorId &&
        creditSubmission.get("podcast_id") === podcastId &&
        creditSubmission.get("submission_type") ===
          CREDIT_SUBMISSION_TYPE.ONGOING_SUBMISSION
    )
);

export const makeSelectSubmissionCreditsByPodcastIdAndCreatorId = () =>
  selectSubmissionCreditsByPodcastIdAndCreatorId;

const selectPendingRecurringCredits = createSelector(
  [
    selectSubmissionCreditsByPodcastIdAndCreatorId,
    (_, _podcastId, _creatorId, roleCode) => roleCode,
    (_, _podcastId, _creatorId, _roleCode, characterName) => characterName,
  ],
  (allSubmittedCredits, roleCode, characterName) => {
    if (characterName) {
      return allSubmittedCredits.find(
        (submittedCredit) =>
          submittedCredit.get("role_code") === roleCode &&
          submittedCredit.get("character_name") === characterName
      );
    }
    return allSubmittedCredits.find(
      (submittedCredit) => submittedCredit.get("role_code") === roleCode
    );
  }
);

export const makeSelectPendingRecurringCredits = () =>
  selectPendingRecurringCredits;

export const selectSpecificEpisodeGroup = (state, id) =>
  state.creator?.getIn(["episodesGroup", "entities", id?.toString()]);

export const selectPodcastCreditsFromCreator = (state, creator = null) =>
  creator
    ? denormalize(
        creator.get("credits", new List()),
        [{ entity: listEntity }],
        selectAllEntities(state)
      )
    : null;

export const selectRoles = (state) =>
  state.creator?.getIn(["roles", "entities"]);
export const selectRolesLoading = (state) =>
  state.creator?.getIn(["roles", "loading"]);

const selectSpecificRoleByCode = createSelector(
  [selectRoles, (_, roleCode) => roleCode],
  (roles, roleCode) => roles.get(roleCode?.toString())
);
export const makeSelectSpecificRoleByCode = () => selectSpecificRoleByCode;

const selectRoleOfPodcastCredit = createSelector(
  [
    (state, podcastId) => selectPodcastCreditByPodcastId(state, podcastId),
    (state, _podcastId, roleCode) => selectSpecificRoleByCode(state, roleCode),
    (_, podcastId) => podcastId,
    (_, _podcastId, roleCode) => roleCode,
  ],
  (podcastCredit, roleInfo, _podcastId, roleCode) => {
    if (podcastCredit) {
      const roles = podcastCredit.get("roles");

      for (let index = 0; index < roles.size; index++) {
        const role = roles.get(index);
        if (role.get("role") === roleCode) {
          return roleInfo.mergeDeep(role);
        }
      }
    }

    return roleInfo ? roleInfo : null;
  }
);
export const makeSelectRoleOfPodcastCredit = () => selectRoleOfPodcastCredit;

const selectRolesGroupedByRoleGroup = createSelector(
  [selectRoles, (_, searchTerm) => searchTerm],
  (roles, searchTerm) => {
    let filteredRoles = roles;

    if (searchTerm && searchTerm.trim()) {
      const splitFilterValue = searchTerm ? searchTerm.trim().split(" ") : [];

      filteredRoles = filteredRoles
        .map((role) => {
          const matching = splitFilterValue.filter((word) => {
            const regex = new RegExp(word, "i");

            return regex.test(role.get("title"));
          });

          return role.set("matchingCount", matching.length);
        })
        .filter((op) => op.get("matchingCount") > 0)
        .sort((a, b) => {
          if (b.get("matchingCount") === 0 && a.get("matchingCount") === 0) {
            return 0;
          }

          return b.get("matchingCount") > a.get("matchingCount") ? 1 : -1;
        });
    }

    const rolesByGroup = groupBy(filteredRoles, (role) => role.get("group"));

    return rolesByGroup;
  }
);
export const makeSelectRolesGroups = () => selectRolesGroupedByRoleGroup;

export const selectSpecificCharacter = (state, id) =>
  state.creator?.getIn(["character", "entities", id?.toString()]);

const selectAllCharacters = (state) =>
  state.creator?.getIn(["character", "entities"]);
export const makeSelectAllCharacters = () => selectAllCharacters;

export const selectSpecificCreditSubmission = (state, id) =>
  state.creator?.getIn(["credit_submissions", "entities", id?.toString()]);

export const selectSpecificCreatorSubmission = (state, id) =>
  state.creator?.getIn(["creator_submissions", "entities", id?.toString()]);

export const selectCreatorIsUser = (state, id) => {
  if (id) {
    const creator = selectSpecificCreator(state, id);
    const user = selectAuthUser(state);

    return (
      user &&
      creator &&
      creator.has("creator_user") &&
      creator.getIn(["creator_user", "id"]) === user.get("id")
    );
  }

  return false;
};

export const selectCreatorWasSubmittedByUser = (state, id) => {
  if (id) {
    const creator = selectSpecificCreator(state, id);
    const user = selectAuthUser(state);

    return (
      user &&
      creator &&
      creator.getIn(["submitted_by", "id"]) === user.get("id")
    );
  }

  return false;
};

export const selectUserFollowsCreator = (state, id) =>
  selectSpecificCreator(state, id).getIn(["user_data", "follows"], false);
