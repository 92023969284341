import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const BlogIndex = Loadable(() => import("./BlogIndex"));

function BlogIndexAsyncLoading(props) {
  return <LoadableLoading {...props} page />;
}

const BlogIndexContainer = (props) => {
  return <BlogIndex {...props} fallback={<BlogIndexAsyncLoading />} />;
};

export default BlogIndexContainer;
