import Loadable from "@loadable/component";
const ProLeadFormModal = Loadable(() => import("./ProLeadFormModal"));

function ProLeadFormModalAsyncLoading() {
  return null;
}

const ProLeadFormModalContainer = (props) => {
  return (
    <ProLeadFormModal {...props} fallback={<ProLeadFormModalAsyncLoading />} />
  );
};

export default ProLeadFormModalContainer;
