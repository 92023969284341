import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const UserListEditPageContainer = Loadable(() =>
  import("./UserListEditPageContainer")
);

function UserListEditContainerAsyncLoading(props) {
  return <LoadableLoading {...props} page />;
}

const UserListEditPageContainerContainer = (props) => {
  return (
    <UserListEditPageContainer
      {...props}
      fallback={<UserListEditContainerAsyncLoading />}
    />
  );
};

export default UserListEditPageContainerContainer;
