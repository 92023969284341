import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const LandingPage = Loadable(() => import("./LandingPage"));

function LandingPageAsyncLoading(props) {
  return <LoadableLoading {...props} page />;
}

const LandingPageContainer = (props) => {
  return <LandingPage {...props} fallback={<LandingPageAsyncLoading />} />;
};

export default LandingPageContainer;
