import tokens from "../../../styles/tokens/tokens";

import generateTransition from "utils/generateTransition";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ListPageStyles from "styles/ListPageStyles";

export default {
  ...ListPageStyles,
  sidebarheader: {
    ...gStyles.avalonBold,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 13,
    color: colours.bodyText,
    marginBottom: "1.75rem",
  },
  sidebarheaderTitle: {},
  sidebarheaderLink: {},
  profileNavigationSidebarSection: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginBottom: "2.5rem",
  },
  profileNavigationSidebarPanels: {
    display: "flex",
    flexDirection: "column",
    marginTop: ".75rem",
  },
  profileNavigationSidebarPanelsHorizontal: {
    flexDirection: "row",
  },

  profileNavigationSidebarPanel: {
    position: "relative",
    display: "flex",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    border: `1px solid ${tokens.color.border.default}`,
    borderRadius: 5,
    overflow: "hidden",
    marginTop: ".25rem",
    padding: "0.375rem",
    cursor: "pointer",

    ":first-child": {
      marginTop: 0,
    },
  },
  profileNavigationSidebarPanelLink: {
    cursor: "pointer",
    ":focus": {
      borderColor: "rgb(200, 200, 200)",
    },
  },
  profileNavigationSidebarPanelActive: {},
  profileNavigationSidebarPanelBar: {
    display: "block",
    left: 0,
    top: 0,
    width: "0.25rem",
    height: "100%",
    position: "absolute",
    background: "transparent",
    transition: generateTransition({ targets: ["background"], speed: "400ms" }),
  },
  profileNavigationSidebarPanelBarActive: {
    background: colours.primary,
  },
  profileNavigationSidebarPanelHorizontal: {
    flexDirection: "column",
    alignItems: "center",
    marginTop: 0,
    marginLeft: "1.5rem",

    ":first-child": {
      marginLeft: 0,
    },
  },
  profileNavigationSidebarPanelTitle: {
    ...gStyles.avalonBold,
    fontSize: 15,
    color: colours.bodyText,
    marginBottom: ".25rem",
    transition: "400ms color",
    ":focus": {
      textDecoration: "underline",
    },
  },
  profileNavigationSidebarPanelInfo: {
    display: "flex",
    flexDirection: "column",
    marginLeft: ".5rem",
    marginRight: "1.125rem",
    // whiteSpace: 'nowrap',
  },
  profileNavigationSidebarPanelInfoHorizontal: {
    display: "block",
    width: "100%",
    marginLeft: 0,
    marginRight: 0,
    padding: "0 .5rem",
    marginTop: ".75rem",
    textAlign: "center",
  },
  profileNavigationSidebarPanelTitleActive: {
    color: colours.primary,
  },
  profileNavigationSidebarPanelTitleAfter: {
    ...gStyles.avalonLight,
    marginLeft: "0.25em",
  },
  profileNavigationSidebarPanelItemCount: {
    display: "block",
    ...gStyles.fontRegular,
    color: colours.bodyText,
    fontSize: 11,
  },
  profileNavigationSidebarPanelItemCountLink: {
    ":hover": {
      textDecoration: "underline",
    },
    ":focus": {
      textDecoration: "underline",
    },
  },
  profileNavigationSidebarPanelItemCountLinkActive: {
    ...gStyles.fontSemiBold,
    color: colours.primary,
  },
  profileNavigationSidebarPanelActiveBox: {
    display: "block",
    transition: "400ms backgroundColor",
    height: 3,
    width: "100%",
    backgroundColor: colours.white,
  },
  imageIconContainer: {
    width: "2.5rem",
    height: "2.5rem",
    flex: "0 0 auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "flex-start",
  },
  imageIconContainerHorizontal: {
    margin: 0,
  },
  panelImage: {
    display: "block",
    width: "100%",
    height: "auto",
    padding: "0.125rem",
    position: "relative",

    ":focus": {
      opacity: 0.8,
    },
  },
  personImage: {
    borderRadius: "10em",
    overflow: "hidden",
  },
  blockImage: {},
  blockImageImg: {
    width: "100%",
    height: "auto",
  },
};
