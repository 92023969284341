import ScreenSizes from "./ScreenSizes";
import { createBreakpoint } from "styles/ScreenSizes";

export default {
  hasMobileMenu: ScreenSizes.smAndBelow,
  hasCustom: createBreakpoint({ min: 400, max: 575 }),
  hasTabletMenu: ScreenSizes.mdOnly,
  hasDesktopMenu: ScreenSizes.lgAndAbove,
  hasMobileOrTabletMenu: ScreenSizes.mdAndBelow,
};
