import { getRefCountedManager } from "redux-dynamic-modules";
import createSagaMiddleware from "redux-saga";

import { sagaEquals } from "./SagaComparer";
import { getSagaManager } from "./SagaManager";

/**
 * Get an extension that integrates saga with the store
 * @param sagaContext The context to provide to the saga
 */
export function getSagaExtension(sagaContext, effectMiddlewares = []) {
  let sagaMonitor;

  // @ts-ignore
  if (process.env.NODE_ENV === "development") {
    sagaMonitor =
      (typeof window !== "undefined" && window.__SAGA_MONITOR_EXTENSION__) ||
      undefined;
  }

  // Setup the saga middleware
  const sagaMiddleware = createSagaMiddleware({
    context: sagaContext,
    sagaMonitor,
    effectMiddlewares,
  });

  const _sagaManager = getRefCountedManager(
    getSagaManager(sagaMiddleware),
    sagaEquals
  );

  return {
    middleware: [sagaMiddleware],
    manager: _sagaManager,

    onModuleManagerCreated: (moduleManager) => {
      if (sagaContext) {
        sagaContext.moduleManager = moduleManager;
      }
    },

    onModuleAdded: (module) => {
      if (module.sagas) {
        _sagaManager.add(module.sagas);
      }
    },

    onModuleRemoved: (module) => {
      if (module.sagas) {
        _sagaManager.remove(module.sagas);
      }
    },

    dispose: () => {
      _sagaManager.dispose();
    },
  };
}
