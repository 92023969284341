import Loadable from "@loadable/component";
const OpenExclusiveModal = Loadable(() => import("./OpenExclusiveModal"));

function OpenExclusiveModalAsyncLoading() {
  return null;
}

const OpenExclusiveModalContainer = (props) => {
  return (
    <OpenExclusiveModal
      {...props}
      fallback={<OpenExclusiveModalAsyncLoading />}
    />
  );
};

export default OpenExclusiveModalContainer;
