import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo } from "react";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";

const baseStyles = {
  asyncLoadingEntityCard: {
    ...gStyles.skeletonPlaceholder,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    borderRadius: "6px",
    border: "1px solid rgba(0,0,0,0.1)",
    marginTop: "1rem",

    ":first-child": {
      marginTop: "1rem",
    },
  },
  icon: {
    maxWidth: "1em",
  },
  desktop: {
    minHeight: "12rem",
    maxHeight: "12rem",
  },
  mobile: {
    minHeight: "15.7rem",
    maxHeight: "15.7rem",
  },
  compact: {
    minHeight: "4.125rem",
    maxHeight: "4.125rem",
  },
  overview: {
    minHeight: "3.5rem",
    maxHeight: "3.5rem",
  },
};

const AsyncLoadingEntityCard = (props) => {
  const { type } = props;

  const { styles } = useStyles(baseStyles, props);

  return <div className={css(styles.asyncLoadingEntityCard, styles[type])} />;
};

AsyncLoadingEntityCard.propTypes = {
  type: PropTypes.string,
};

AsyncLoadingEntityCard.defaultProps = {
  type: "desktop",
};

export default memo(AsyncLoadingEntityCard);
