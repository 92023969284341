import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

export default {
  content: {
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
  },
  mobileContent: {
    width: "100%",
  },
  topInfoRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  profileImage: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "3.75rem",
    height: "3.75rem",
    flex: "0 0 auto",

    [ScreenSizes.lgAndAbove]: {
      width: "2.75rem",
      height: "2.75rem",
    },
  },
  namesColumn: {
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
    width: "100%",
    marginLeft: "1.125rem",
    wordBreak: "break-word",
    overflow: "hidden",

    [ScreenSizes.lgAndAbove]: {
      marginLeft: "0.5rem",
    },
  },
  name: {
    // ...gStyles.textEllipsis,
    ...gStyles.avalonBold,
    fontSize: "1.25rem",
    color: "#000",
    marginBottom: ".5rem",

    [ScreenSizes.lgAndAbove]: {
      marginBottom: ".25rem",
      fontSize: ".813rem",
    },
  },
  divider: {
    display: "block",
    margin: "1.5rem 0",
    borderBottom: "#dfe1e6 2px solid",
    width: "100%",

    [ScreenSizes.lgAndAbove]: {
      margin: ".75rem 0",
      borderBottom: "#dfe1e6 1px solid",
    },
  },
  subtitle: {
    ...gStyles.fontRegular,
    display: "block",
    color: "#000",
    padding: "0 0.5rem",
    fontSize: "0.875rem",
    marginBottom: "0.75rem",
    maxWidth: "100%",
    wordBreak: "break-word",
  },
  subtitleLink: {
    ...gStyles.fontBold,
    color: "#000",
  },
  description: {
    ...gStyles.fontRegular,
    display: "block",
    color: "#000",
    padding: "0 0.5rem",
    fontSize: "0.875rem",
    marginBottom: "0.75rem",
    maxWidth: "100%",
  },
  descriptionLink: {
    ...gStyles.fontBold,
    color: "#000",
  },
  infoIcons: {
    marginBottom: "0.75rem",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  buttonContainer: {
    display: "flex",
    marginBottom: ".5rem",

    [ScreenSizes.lgAndAbove]: {
      marginBottom: 0,
    },
  },
};
