export default function getEpisodeType(episode, options = {}) {
  const { specialOnly = true } = options;

  if (!episode || !episode.get("episode_type")) {
    return null;
  }

  switch (episode.get("episode_type")) {
    case "full": {
      return specialOnly ? null : "Full";
    }
    case "bonus": {
      return "Bonus";
    }
    case "trailer": {
      return "Trailer";
    }
  }
}
