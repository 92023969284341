import Loadable from "@loadable/component";
const PrivacyPolicyUpdateModal = Loadable(() =>
  import("./PrivacyPolicyUpdateModal")
);

function PrivacyPolicyUpdateModalAsyncLoading() {
  return null;
}

const PrivacyPolicyUpdateModalContainer = (props) => {
  return (
    <PrivacyPolicyUpdateModal
      {...props}
      fallback={<PrivacyPolicyUpdateModalAsyncLoading />}
    />
  );
};

export default PrivacyPolicyUpdateModalContainer;
