export default function parseBoolString(str, returnOriginalString) {
  if (!returnOriginalString) {
    return str.toLowerCase() === "true";
  }
  if (str.toLowerCase() === "true") {
    return true;
  }
  if (str.toLowerCase() === "false") {
    return false;
  }
  return str;
}
