import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const PodcastClaimModal = Loadable(() => import("./PodcastClaimModal"));

function PodcastClaimModalAsyncLoading(props) {
  return <LoadableLoading {...props} />;
}

const PodcastClaimModalContainer = (props) => {
  return (
    <PodcastClaimModal
      {...props}
      fallback={<PodcastClaimModalAsyncLoading />}
    />
  );
};

export default PodcastClaimModalContainer;
