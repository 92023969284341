import Loadable from "@loadable/component";
const SendNetworkFeedbackModal = Loadable(() =>
  import("./SendNetworkFeedbackModal")
);

function SendNetworkFeedbackModalAsyncLoading() {
  return null;
}

const SendNetworkFeedbackModalContainer = (props) => {
  return (
    <SendNetworkFeedbackModal
      {...props}
      fallback={<SendNetworkFeedbackModalAsyncLoading />}
    />
  );
};

export default SendNetworkFeedbackModalContainer;
