import Loadable from "@loadable/component";

import DashboardSkeleton from "./DashboardSkeleton";

const Dashboard = Loadable(() =>
  import(/* webpackChunkName: "Dashboard" */ "./Dashboard")
);

const DashboardContainer = (props) => {
  return <Dashboard {...props} fallback={<DashboardSkeleton />} />;
};

export default DashboardContainer;
