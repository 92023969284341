import { css } from "aphrodite";
import PropTypes from "prop-types";
import { useMemo, useCallback } from "react";
import { useLocation } from "react-router-dom";

import authActions from "../../actions/auth";
import StandardButton from "../../components/Buttons/StandardButton";
import useActionCreators from "../../hooks/useActionCreators";
import useWindowSize from "../../hooks/useWindowSize";
import gStyles from "../../styles/GenericStyles";
import ScreenSizes from "../../styles/ScreenSizes";

import { useStyles } from "hooks/useStyles";

const baseStyles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  buttons: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginBottom: "1rem",
    [ScreenSizes.mdAndAbove]: {
      flexDirection: "row",
    },
  },
  button: {
    flex: "0 1 50%",
    marginRight: 0,
    marginBottom: "0.5rem",
    [ScreenSizes.mdAndAbove]: {
      marginRight: "1rem",
      marginBottom: 0,
    },
    ":last-child": {
      marginRight: 0,
      marginBottom: 0,
    },
  },
  loginButton: {
    ...gStyles.fontBold,
    cursor: "pointer",
    border: "none",
  },
  cancelLink: {
    ...gStyles.fontBold,
    fontSize: "0.8125rem",
    color: "#999",
    margin: "0.5rem auto",
    ":hover": {
      textDecoration: "underline",
      color: "var(--color-neutral-d4)",
    },
  },
};

function OAuthLoginRegisterPrompt(props) {
  const { redirect_uri } = props;
  const { styles } = useStyles(baseStyles, props);

  const { isWindowSizeOrLess } = useWindowSize();

  const { startAuthFlow } = useActionCreators({
    startAuthFlow: authActions.startAuthFlow,
  });

  const declineUrl = useMemo(() => {
    const url = new URL(redirect_uri);
    url.searchParams.append("error_code", "authorization_declined");
    url.searchParams.append("error_message", "The authorization was declined");
    return url.href;
  }, [redirect_uri]);

  const location = useLocation();

  const previousPathName = `${location?.pathname || "/"}${location.search}`;

  const handleRegister = useCallback(
    (e) => {
      e.preventDefault();
      startAuthFlow(false, {
        analyticsVariables: {
          triggered_by: "OAuthLoggedOutRegisterButton",
        },
        previousPathName,
      });
      return false;
    },
    [previousPathName, startAuthFlow]
  );

  const handleLogin = useCallback(
    (e) => {
      e.preventDefault();
      startAuthFlow(true, {
        analyticsVariables: {
          triggered_by: "OAuthLoggedOutLoginButton",
        },
        previousPathName,
      });
      return false;
    },
    [previousPathName, startAuthFlow]
  );

  const registerButton = (
    <div className={css(styles.button, styles.buttonRegister)}>
      <StandardButton
        variation="subtle"
        onClick={handleRegister}
        label="Register"
      />
    </div>
  );

  const loginButton = (
    <div className={css(styles.button, styles.buttonLogin)}>
      <StandardButton variation="pink" onClick={handleLogin} label="Login" />
    </div>
  );

  return (
    <div className={css(styles.container)}>
      <p>
        Please{" "}
        <button className={css(styles.loginButton)} onClick={handleLogin}>
          log in
        </button>{" "}
        to continue.
      </p>
      <div className={css(styles.buttons)}>
        {isWindowSizeOrLess("medium") ? loginButton : registerButton}
        {isWindowSizeOrLess("medium") ? registerButton : loginButton}
      </div>
      <a href={declineUrl} className={css(styles.cancelLink)}>
        Cancel
      </a>
    </div>
  );
}

OAuthLoginRegisterPrompt.propTypes = {
  redirect_uri: PropTypes.string.isRequired,
};

OAuthLoginRegisterPrompt.defaultProps = {};

export default OAuthLoginRegisterPrompt;
