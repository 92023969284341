import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const BlogCategoryIndex = Loadable(() => import("./BlogCategoryIndex"));

function BlogCategoryIndexAsyncLoading(props) {
  return <LoadableLoading {...props} />;
}

const BlogCategoryIndexContainer = (props) => {
  return (
    <BlogCategoryIndex
      {...props}
      fallback={<BlogCategoryIndexAsyncLoading />}
    />
  );
};

export default BlogCategoryIndexContainer;
