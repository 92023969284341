import TopLevelRouteSkeleton from "pages/Common/TopLevelRouteSkeleton";

import PodcastHeaderSkeleton from "./PodcastHeaderSkeleton";
import PodcastViewSidebarSkeleton from "./PodcastViewSidebarSkeleton";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  container: {
    display: "flex",
    flexDirection: "row",
  },
  tabs: {
    ...gStyles.skeletonPlaceholder,
    color: colours.grey,

    height: "3rem",
    width: "100%",
  },
  content: {
    height: "32rem",
    background: "#fff",
  },
  sidebar: {
    display: "none",
    height: "210rem",
    width: "18.75rem",
    marginTop: "-1rem",

    [ScreenSizes.lgAndAbove]: {
      display: "block",
    },
  },
  main: {
    width: "100%",
    paddingRight: "2rem",
    paddingLeft: "2rem",

    marginTop: "3.25rem",

    [ScreenSizes.lgAndAbove]: {
      width: "calc(100% - 21rem)",

      paddingRight: "4%",
      paddingLeft: "2rem",
    },
  },
};

function PodcastViewSkeleton() {
  const { styles, css } = useStyles(baseStyles);

  return (
    <TopLevelRouteSkeleton withHeader>
      <PodcastHeaderSkeleton />
      <div className={css(styles.container)}>
        <div className={css(styles.main)}>
          <div className={css(styles.tabs)} />
          <div className={css(styles.content)} />
        </div>
        <div className={css(styles.sidebar)}>
          <PodcastViewSidebarSkeleton />
        </div>
      </div>
    </TopLevelRouteSkeleton>
  );
}

export default PodcastViewSkeleton;
