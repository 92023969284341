import { memo, useEffect } from "react";

const UseJimoLoader = () => {
  useEffect(() => {
    window.jimo = [];
    const s = document.createElement("script");

    s.type = "text/javascript";
    s.defer = true;
    s.src = "https://undercity.usejimo.com/jimo-invader.js";
    window["JIMO_PROJECT_ID"] = "c4aa8921-9dda-4ad2-97e3-e6a512e61795"; // Update this
    document.getElementsByTagName("head")[0].appendChild(s);
  }, []);

  return null;
};

export default memo(UseJimoLoader);
