import gStyles from "styles/GenericStyles";

export default {
  a: {
    ...gStyles.fontRegular,
    textDecoration: "none",
    lineHeight: "inherit",
    cursor: "pointer",
  },
  ul: {
    lineHeight: "1.9",
  },
};
