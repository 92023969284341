import { css } from "aphrodite";

import gStyles from "../../styles/GenericStyles";
import { oAuthStyles } from "./OAuthStyles";

import { useStyles } from "hooks/useStyles";

const baseStyles = {
  ...oAuthStyles,
  intro: {
    ...oAuthStyles.intro,
    ...gStyles.avalonBold,
  },
};

function OAuthError(props) {
  const { error, error_description, error_hint } = props;
  const { styles } = useStyles(baseStyles, props);

  return (
    <div className={css(styles.oAuthPrompt)}>
      <style>
        {`html, body, #app {
      padding: 0;
      margin: 0;
      min-height: 100%;
      height: auto;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      width: 100%;
    }
    `}
      </style>
      <div className={css(styles.inner)}>
        <h2 className={css(styles.intro)}>Connect With Podchaser</h2>
        <div className={css(styles.errorMessage)}>
          {error ? (
            <div>
              {error}: {error_description} - {error_hint}
            </div>
          ) : (
            <div>
              The URL is not configured properly. Please contact the app
              developer for assistance.
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default OAuthError;
