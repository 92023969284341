import Loadable from "@loadable/component";
const EditCharacterRoleModal = Loadable(() =>
  import("./EditCharacterRoleModal")
);

function EditCharacterRoleModalAsyncLoading() {
  return null;
}

const EditCharacterRoleModalContainer = (props) => {
  return (
    <EditCharacterRoleModal
      {...props}
      fallback={<EditCharacterRoleModalAsyncLoading />}
    />
  );
};

export default EditCharacterRoleModalContainer;
