import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

const BrandHeaderMobileEllipsisModal = Loadable(() =>
  import(
    /* webpackChunkName: "BrandHeaderMobileEllipsisModal" */ "./BrandHeaderMobileEllipsisModal"
  )
);

function BrandHeaderMobileEllipsisModalAsyncLoading(props) {
  return <LoadingOverlay {...props} span />;
}

const BrandHeaderMobileEllipsisModalContainer = (props) => {
  return (
    <BrandHeaderMobileEllipsisModal
      {...props}
      fallback={<BrandHeaderMobileEllipsisModalAsyncLoading />}
    />
  );
};

export default BrandHeaderMobileEllipsisModalContainer;
