import PropTypes from "prop-types";
import { memo, useContext } from "react";
import { Route } from "react-router-dom";

import RequestContext from "pages/RequestContext";

import LoadingOverlay from "./LoadingOverlay";

import { selectAuthLoggedIn, selectUserHasPermission } from "selectors/auth";

import useReduxState from "hooks/useReduxState";

function RegisteredRoute(props) {
  const {
    notRegisteredComponent: NotRegistered,
    notAuthorizedComponent: NotAuthorized,
    requiredPermissions,
    children,
    ...rest
  } = props;

  const requestContext = useContext(RequestContext);

  const { isLoggedIn, isAuthorized, isLoggingIn } = useReduxState(
    (state) => ({
      isLoggedIn: selectAuthLoggedIn(state),
      isAuthorized:
        selectAuthLoggedIn(state) &&
        (requiredPermissions.length === 0 ||
          requiredPermissions.every((permission) =>
            selectUserHasPermission(state, permission)
          )),
      isLoggingIn: state.auth.get("loggingIn"),
    }),
    [requiredPermissions]
  );

  if (isLoggingIn) {
    // don't render the loading overlay on the server as the hydration gets messed up
    return requestContext.server ? null : (
      <LoadingOverlay key="registeredRouteLoadingOverlay" />
    );
  }

  if (!isLoggedIn) {
    return <NotRegistered {...rest} key="not_registered" />;
  }

  if (!isAuthorized && NotAuthorized) {
    return <NotAuthorized {...rest} key="not_authorized" />;
  }

  return <Route {...rest}>{children}</Route>;
}

RegisteredRoute.propTypes = {
  requiredPermissions: PropTypes.array,
  notRegisteredComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.node])
    .isRequired,
  notAuthorizedComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
};
RegisteredRoute.defaultProps = {
  requiredPermissions: [],
  notAuthorizedComponent: null,
};

export default memo(RegisteredRoute);
