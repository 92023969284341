import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const ChartsListContainer = Loadable(() => import("./ChartsListContainer"));

function ChartsListContainerAsyncLoading(props) {
  return (
    <LoadableLoading
      key="chartsListLoadingOverlay"
      withPageHeader
      height={"136rem"}
    />
  );
}

const ChartsListContainerContainer = (props) => {
  return (
    <ChartsListContainer
      {...props}
      fallback={<ChartsListContainerAsyncLoading />}
    />
  );
};

export default ChartsListContainerContainer;
