import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import ImageFallback from "components/Global/Images/ImageFallback";

import DropdownNavMenuIconBackground from "./DropdownNavMenuIconBackground";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";

const TRANSITION_METHOD = "cubic-bezier(0.465, 0.183, 0.153, 0.946)";

const baseStyles = {
  navImage: {
    filter: "drop-shadow(-2px 1px 1px transparent)",
  },
  navIconSvg: {
    transition: `transform 500ms ${TRANSITION_METHOD}, filter 500ms ${TRANSITION_METHOD}`,
    backfaceVisibility: "hidden",
    position: "relative",
    transform: "translate3d(0,0,0) scale(1.01, 1.01)",
    transformStyle: "preserve-3d",
    filter: "drop-shadow(-3px 2px 1px transparent)",
    zIndex: 2,
  },
  navIconSvgHover: {
    transform: "skew(-7deg, -3deg) rotate(-8deg) scale(0.9, 1.01)",
    filter: "drop-shadow(-3px 2px 1px rgb(144, 6, 194))",
  },
  navImageHover: {
    transform: "skew(-6deg, -2deg) rotate(-8deg) scale(0.9, 1.01)",
    filter: `drop-shadow(-2px 1px 1px ${colours.lightishGrey})`,
  },
  navIcon: {
    position: "relative",
    width: "3rem",
    height: "3rem",
    minWidth: "3rem",
    minHeight: "3rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    color: colours.white,
    fontSize: "1.25rem",
    marginRight: "15px",
    transform: "scale(1.01, 1.01)",
    transition: `boxShadow 300ms ${TRANSITION_METHOD}, transform 300ms ${TRANSITION_METHOD}`,
    backfaceVisibility: "hidden",
  },
  navIconHover: {
    boxShadow: "0 3px 14px rgba(255, 34, 123, 0.2)",
    transform: "scale(1.07, 1.07)",
  },
};

const DropdownNavMenuIcon = (props) => {
  const { menuItemProps, index, hover } = props;
  const { css, styles } = useStyles(baseStyles, props);

  const { icon, image, iconStyles, iconBackgroundStyles, imageStyles } =
    menuItemProps;

  if (!icon && !image) {
    return null;
  }

  return (
    <span
      className={css(styles.navIcon, hover && styles.navIconHover)}
      style={iconStyles}
    >
      <DropdownNavMenuIconBackground
        image={image}
        index={index}
        hover={hover}
        styles={iconBackgroundStyles}
      />
      {image && (
        <ImageFallback
          src={image}
          className={css(
            styles.navIconSvg,
            styles.navImage,
            hover && styles.navImageHover
          )}
          style={imageStyles}
          width="48px"
          height="48px"
          animate={false}
          renderOnMount
        />
      )}
      {icon && (
        <FontAwesomeIcon
          icon={icon}
          className={css(styles.navIconSvg, hover && styles.navIconSvgHover)}
          fixedWidth
        />
      )}
    </span>
  );
};

DropdownNavMenuIcon.propTypes = {
  menuItemProps: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  hover: PropTypes.bool,
};

DropdownNavMenuIcon.defaultProps = {
  hover: false,
};

export default DropdownNavMenuIcon;
