import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { useCallback, useMemo } from "react";

// import RatingWithHover from 'components/Rating/RatingWithHover';
import { useEntityFromTimeline } from "../useEntityFromTimeline";
import Activity from "./Activity/ActivityAsync";

import { entityNames } from "constants/entity";
import indefiniteArticle from "utils/text/indefiniteArticle";

import useReviewWithEntity from "hooks/useReviewWithEntity";
import { useStyles } from "hooks/useStyles";

import cardStyles from "styles/CardStyles";
import { feedColours as feedTextColours } from "styles/feedStyles";

const baseStyles = {
  headerTextContainer: {
    width: "100%",
  },
  entityTitle: {
    display: "inline-block",
    marginRight: ".2rem",
  },
  verbText: {
    ...cardStyles.verbText,
    color: feedTextColours.feedReviewText || "#000",
  },
};

const ReviewActivity = (props) => {
  const { activity, mobile } = props;
  const { styles } = useStyles(baseStyles, props);

  const { entity: user } = useEntityFromTimeline(
    activity && activity.get("actor")
  );
  const { entity: timelineReview } = useEntityFromTimeline(
    activity && activity.get("foreign_id")
  );
  const review = useReviewWithEntity(
    timelineReview && timelineReview.get("id")
  );
  const reviewedEntity = review && review.get("entity");

  const filterEntities = useMemo(
    () => ({
      review,
      [review && review.get("entity_type")]: reviewedEntity,
    }),
    [review, reviewedEntity]
  );

  const userRating = activity.get("rating");

  const renderIntroText = useCallback(
    ({ renderEntityLink }) => (
      <div className={css(styles.headerTextContainer)}>
        <span className={css(styles.entityTitle)}>
          {renderEntityLink("user", user)}{" "}
        </span>
        <span className={css(styles.verbText)}>{"reviewed "}</span>
        {`${indefiniteArticle(review.get("entity_type"))} ${
          entityNames[review.get("entity_type")]
        }`}
      </div>
    ),
    [
      styles.headerTextContainer,
      styles.entityTitle,
      styles.verbText,
      user,
      review,
    ]
  );

  const renderIntroImage = useCallback(
    ({ renderEntityImage }) => renderEntityImage("user", user),
    [user]
  );

  const sections = useMemo(
    () => [
      {
        type: "intro",
        renderImage: renderIntroImage,
        renderText: renderIntroText,
        highlightType: "Review",
      },
      {
        type: "entity",
        entityCardProps: {
          entity_type: "review",
          entity: review,
          showCreators: mobile,
          mobile,
          hideInfoIcons: true,
          userRating,
        },
      },
    ],
    [renderIntroImage, renderIntroText, mobile, review, userRating]
  );

  if (!activity) {
    return "No activity?";
  }

  return (
    <Activity
      {...props}
      sections={sections}
      loaded={!!(user && review && reviewedEntity)}
      filterEntities={filterEntities}
    />
  );
};

ReviewActivity.propTypes = {
  activity: PropTypes.instanceOf(Map),
  mobile: PropTypes.bool,
};

ReviewActivity.defaultProps = {
  activity: Map(),
  mobile: false,
};

export default ReviewActivity;
