import { css } from "aphrodite";
import PropTypes from "prop-types";

import ProfileNavigationSidebarStyles from "components/Profiles/ProfileNavigationSidebar/ProfileNavigationSidebarStyles";
import ProfileSubtitle from "components/Profiles/ProfileSubtitle";

import { useStyles } from "hooks/useStyles";

const baseStyles = ProfileNavigationSidebarStyles;

function SidebarSection(props) {
  const {
    title,
    horizontal,
    viewAll,
    icon,
    children,
    image,
    profileSubtitleStyles,
  } = props;
  const { styles } = useStyles(baseStyles, props);

  return (
    <div className={css(styles.profileNavigationSidebarSection)}>
      <ProfileSubtitle
        image={image}
        icon={icon}
        viewAll={viewAll}
        title={title}
        styles={profileSubtitleStyles}
      >
        {title}
      </ProfileSubtitle>

      <div
        className={css(
          styles.profileNavigationSidebarPanels,
          horizontal && styles.profileNavigationSidebarPanelsHorizontal
        )}
      >
        {children}
      </div>
    </div>
  );
}

SidebarSection.propTypes = {
  title: PropTypes.node,
  horizontal: PropTypes.bool,
  viewAll: PropTypes.string,
  icon: PropTypes.object,
  children: PropTypes.node,
  image: PropTypes.node,
  profileSubtitleStyles: PropTypes.object,
};

SidebarSection.defaultProps = {
  title: null,
  horizontal: false,
  viewAll: null,
  icon: null,
  children: null,
  image: null,
  profileSubtitleStyles: null,
};

export default SidebarSection;
