import Loadable from "@loadable/component";

const ListPageHelmet = Loadable(() =>
  import(/* webpackChunkName: "ListPageHelmet" */ "./ListPageHelmet")
);

function ListPageHelmetAsyncLoading() {
  return null;
}

const ListPageHelmetContainer = (props) => {
  return (
    <ListPageHelmet {...props} fallback={<ListPageHelmetAsyncLoading />} />
  );
};

export default ListPageHelmetContainer;
