export const CLAIMING_METHOD = "CLAIMING_METHOD";
export const CLAIM_PODCAST_OPEN_MODAL = "CLAIM_PODCAST_OPEN_MODAL";
export const CLAIM_PODCAST_FEED_DETAILS_SUCCESS =
  "CLAIM_PODCAST_FEED_DETAILS_SUCCESS";
export const CLAIM_PODCAST_FEED_DETAILS_FAILURE =
  "CLAIM_PODCAST_FEED_DETAILS_FAILURE";
export const CLAIM_PODCAST_SKIP_CLAIM_SEND = "CLAIM_PODCAST_SKIP_CLAIM_SEND";
export const CLAIM_PODCAST_SEND_CLAIM_REQUEST =
  "CLAIM_PODCAST_SEND_CLAIM_REQUEST";
export const CLAIM_PODCAST_SEND_CLAIM_SUCCESS =
  "CLAIM_PODCAST_SEND_CLAIM_SUCCESS";
export const CLAIM_PODCAST_SEND_CLAIM_FAILURE =
  "CLAIM_PODCAST_SEND_CLAIM_FAILURE";

export const CLAIM_PODCAST_VALIDATE_CLAIM_REQUEST =
  "CLAIM_PODCAST_VALIDATE_CLAIM_REQUEST";
export const CLAIM_PODCAST_VALIDATE_CLAIM_SUCCESS =
  "CLAIM_PODCAST_VALIDATE_CLAIM_SUCCESS";
export const CLAIM_PODCAST_VALIDATE_CLAIM_FAILURE =
  "CLAIM_PODCAST_VALIDATE_CLAIM_FAILURE";

export const CLAIM_TOKEN_ENTERED_IN_FORM = "CLAIM_TOKEN_ENTERED_IN_FORM";

export const CLAIM_TOKEN_VALIDATED = "CLAIM_TOKEN_VALIDATED";

export const CLAIM_METHOD_SELECTED_EMAIL_TOKEN =
  "CLAIM_METHOD_SELECTED_EMAIL_TOKEN";
export const CLAIM_METHOD_SELECTED_FEED_TOKEN =
  "CLAIM_METHOD_SELECTED_FEED_TOKEN";
export const CLAIM_PODCAST_TO_METHOD_SELECT = "CLAIM_PODCAST_TO_METHOD_SELECT";
export const CLAIM_PODCAST_FEED_TOKEN_ADDED = "CLAIM_PODCAST_FEED_TOKEN_ADDED";
