import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { ReactReduxContext } from "react-redux";

import RequestContext from "pages/RequestContext";

import { onlyUnique } from "utils/misc";

const useAddModules = (props) => {
  const { modules, path, disable } = props;

  const requestContext = useContext(RequestContext);

  const [modulesLoaded, setModulesLoaded] = useState(
    requestContext.loadedModules || []
  );
  const reduxContext = useContext(ReactReduxContext);

  const mounted = useRef(false);

  const allModulesLoaded = useMemo(() => {
    const notLoadedModules = modules.filter((m) => !modulesLoaded.includes(m));
    if (requestContext.server && notLoadedModules.length > 0) {
      console.warn(
        `Modules missing from routeData for ${path}: ${notLoadedModules.join(
          ","
        )}`
      );
      // still return true because we don't want to stop server rendering
      return true;
    }
    return notLoadedModules.length === 0;
  }, [modules, modulesLoaded, path, requestContext.server]);

  useEffect(() => {
    if (!disable && !allModulesLoaded && modules && modules.length > 0) {
      Promise.all(
        modules
          .filter(onlyUnique)
          .map((name) =>
            import(
              /* webpackChunkName: "modulesDynamic" */ `modules/${name}.js`
            )
          )
      ).then((resolvedModules) => {
        reduxContext.store.addModules(resolvedModules.map((m) => m.default()));
        if (mounted.current) {
          setModulesLoaded((loaded) =>
            [...loaded, ...modules].filter(onlyUnique)
          );
        }
      });
    }
    mounted.current = true;
  }, [allModulesLoaded, modules, modulesLoaded, reduxContext.store, disable]);

  return { allModulesLoaded };
};

export default useAddModules;
