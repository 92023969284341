import { faToggleOff } from "@fortawesome/free-solid-svg-icons/faToggleOff";
import { faToggleOn } from "@fortawesome/free-solid-svg-icons/faToggleOn";
import { faBat as faBatLight } from "@fortawesome/pro-light-svg-icons/faBat";
import { faBat } from "@fortawesome/pro-solid-svg-icons/faBat";
import { faLock } from "@fortawesome/pro-solid-svg-icons/faLock";
import { Link } from "react-router-dom";

import ProfileNavigationSidebarStyles from "components/Profiles/ProfileNavigationSidebar/ProfileNavigationSidebarStyles";
import SidebarSection from "components/Profiles/ProfileNavigationSidebar/SidebarSection";
import ProfilePanelIcon from "components/Profiles/ProfilePanelIcon";

import { selectUserHasPermission } from "selectors/auth";
import { CAN_MODERATE_PERMISSION } from "utils/entity/user";

import useIsBatmanMode from "hooks/useIsBatmanMode";
import useIsModerator from "hooks/useIsModerator";
import useReduxState from "hooks/useReduxState";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";

const baseStyles = {
  profileNavigationSidebarPanel: {
    ...ProfileNavigationSidebarStyles.profileNavigationSidebarPanel,
    ...ProfileNavigationSidebarStyles.profileNavigationSidebarPanelTitle,
  },
  imageIconContainer: ProfileNavigationSidebarStyles.imageIconContainer,
  panelImage: ProfileNavigationSidebarStyles.panelImage,
  batmanMode: {
    color: colours.newYellow,
    background: colours.black,
  },
};

const proSectionSubtitleStyles = {
  profileSubtitleImage: {
    position: "absolute",
    right: ".2rem",
    top: "-.1rem",
    marginRight: 0,
  },
};

const HomepageSidebarInternalUsers = (props) => {
  const { styles, css } = useStyles(baseStyles, props);

  const { canModerate } = useReduxState((state) => {
    return {
      canModerate: selectUserHasPermission(state, CAN_MODERATE_PERMISSION),
    };
  }, []);

  const { isModerator, toggleModeratingStatus } = useIsModerator();
  const { isBatmanMode, toggleBatmanMode } = useIsBatmanMode();

  if (!canModerate) {
    return null;
  }

  return (
    <SidebarSection
      icon={faLock}
      title="Admin"
      profileSubtitleStyles={proSectionSubtitleStyles}
    >
      <button
        onClick={toggleModeratingStatus}
        className={css(styles.profileNavigationSidebarPanel)}
      >
        <span className={css(styles.imageIconContainer)}>
          <span className={css(styles.panelImage)}>
            <ProfilePanelIcon icon={isModerator ? faToggleOn : faToggleOff} />
          </span>
        </span>
        Moderator Mode
      </button>
      <button
        onClick={toggleBatmanMode}
        className={css(styles.profileNavigationSidebarPanel)}
      >
        <span className={css(styles.imageIconContainer)}>
          <span className={css(styles.panelImage)}>
            <ProfilePanelIcon icon={isBatmanMode ? faBat : faBatLight} />
          </span>
        </span>
        <span className={css(styles.batmanMode)}>Batman Mode</span>
      </button>
      <Link to="/pcadmin" className={css(styles.profileNavigationSidebarPanel)}>
        <span className={css(styles.imageIconContainer)}>
          <span className={css(styles.panelImage)}>
            <ProfilePanelIcon icon={faLock} />
          </span>
        </span>
        Admin Panel
      </Link>
    </SidebarSection>
  );
};

export default HomepageSidebarInternalUsers;
