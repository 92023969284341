import Loadable from "@loadable/component";
const SlidingContainer = Loadable(() => import("./SlidingContainer"));

function AboutAsyncLoading() {
  return null;
}

const SlidingContainerContainer = (props) => {
  return <SlidingContainer {...props} fallback={<AboutAsyncLoading />} />;
};

export default SlidingContainerContainer;
