import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo, useMemo } from "react";
import { Link } from "react-router-dom";

import ScreenSizes from "../../styles/ScreenSizes";

import { useStyles } from "hooks/useStyles";

const baseStyles = {
  link: {
    display: "flex",
    position: "relative",
    alignSelf: "center",
    transition: "ease-out 2s",

    [ScreenSizes.xlAndAbove]: {
      height: "100%",
    },
    ":focus": {
      opacity: 0.75,
    },
  },
  image: {
    width: "100%",
    height: "auto",
  },
};

const baseString = "/images/podchaser/logos/";
const logoString = "Podchaser - Logo";
const formatString = ".svg";

const PodchaserBrandLogo = (props) => {
  const {
    dark,
    onClick,
    flat,
    large,
    iconOnly,
    width: passedWidth,
    height: passedHeight,
    linked,
    headerLogo,
    loggedInProUser,
    imgProps,
  } = props;
  const { width, height } = (() => {
    if (iconOnly) {
      const defaultDimensions = {
        width: large ? 1024 : 100,
        height: large ? 1024 : 100,
      };
      return {
        width: passedWidth || passedHeight || defaultDimensions.width,
        height: passedHeight || passedWidth || defaultDimensions.height,
      };
    }

    if (passedWidth && passedHeight) {
      return {
        width: passedWidth,
        height: passedHeight,
      };
    }
    if (passedWidth) {
      return {
        width: passedWidth,
        height: "auto",
      };
    }
    if (passedHeight) {
      return {
        width: "auto",
        height: passedHeight,
      };
    }
    return {
      width: large ? 525 : 192,
      height: large ? 106 : 38,
    };
  })();

  const dynamicStyles = useMemo(
    () => ({
      image: {
        width: width || "auto",
        height: height || 38,
      },
    }),
    [width, height]
  );

  const { styles } = useStyles([baseStyles, dynamicStyles], props);

  const iconString = useMemo(
    () => (iconOnly ? " - Icon" : " - Icon Text"),
    [iconOnly]
  );
  const sizeString = useMemo(() => (large ? " - Large" : ""), [large]);
  const flatString = useMemo(() => (flat ? " - Flat" : ""), [flat]);
  const valueString = useMemo(() => {
    if (iconOnly) {
      return "";
    }
    return dark ? " - Dark" : " - Light";
  }, [iconOnly, dark]);

  const src =
    headerLogo && !iconOnly && loggedInProUser
      ? `${baseString}Podchaser - Pro - Logo - Icon Text${valueString}${formatString}`
      : `${baseString}${logoString}${iconString}${sizeString}${valueString}${flatString}${formatString}`;

  const img = (
    <img
      className={css(styles.image)}
      src={src}
      alt="Podchaser Logo"
      title="Podchaser - Follow, Share and Rate Podcasts"
      width={width}
      height={height}
      {...imgProps}
    />
  );

  if (!linked) {
    return img;
  }

  return (
    <Link
      onClick={onClick}
      to="/"
      className={css(styles.link)}
      title="Podchaser - Follow, Share and Rate Podcasts"
    >
      {img}
    </Link>
  );
};

PodchaserBrandLogo.propTypes = {
  className: PropTypes.string,
  dark: PropTypes.bool,
  flat: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  iconOnly: PropTypes.bool,
  imgProps: PropTypes.object,
  large: PropTypes.bool,
  linked: PropTypes.bool,
  onClick: PropTypes.func,
  textSize: PropTypes.oneOf(["medium"]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

PodchaserBrandLogo.defaultProps = {
  className: "",
  dark: false,
  flat: false,
  height: undefined,
  iconOnly: false,
  imgProps: {},
  large: false,
  linked: true,
  onClick: null,
  textSize: null,
  width: undefined,
};

export default memo(PodchaserBrandLogo);
