import { Map } from "immutable";
import { useCallback, useMemo } from "react";

import { isSizeOrMore, isSizeOrLess } from "utils/size/isSizeOr";

import useReduxState from "hooks/useReduxState";

export default function useWindowSize(forceComponentSize = undefined) {
  const windowSize = useReduxState((state) => state.app.get("window_size"), []);

  const windowSizeOrMore = useCallback(
    (size) =>
      isSizeOrMore(
        forceComponentSize || (windowSize && windowSize.get("size")),
        size
      ),
    [forceComponentSize, windowSize]
  );
  const windowSizeOrLess = useCallback(
    (size) =>
      isSizeOrLess(
        forceComponentSize || (windowSize && windowSize.get("size")),
        size
      ),
    [forceComponentSize, windowSize]
  );

  const windowWidthOrMore = useCallback(
    (width) =>
      windowSize && windowSize.get("width") && windowSize.get("width") >= width,
    [windowSize]
  );
  const windowWidthOrLess = useCallback(
    (width) =>
      windowSize && windowSize.get("width") && windowSize.get("width") <= width,
    [windowSize]
  );

  const windowNumericWidthIsLessThan = useCallback(
    (width) => {
      if (windowSize) {
        return windowSize?.get("width") <= width;
      }

      return false;
    },
    [windowSize]
  );

  return useMemo(
    () => ({
      sizeString: forceComponentSize
        ? Map({ size: forceComponentSize })
        : windowSize,
      getWindowWidth: () => windowSize && windowSize.get("width"),
      isWindowSize: (size) =>
        (forceComponentSize || (windowSize && windowSize.get("size"))) === size,
      isWindowSizeOrMore: windowSizeOrMore,
      isWindowSizeOrLess: windowSizeOrLess,
      isWindowSizesOrBetween: (minSize, maxSize) =>
        windowSizeOrMore(minSize) && windowSizeOrLess(maxSize),
      isWindowWidth: (width) =>
        (windowSize && windowSize.get("width")) === width,
      isWindowWidthOrMore: windowWidthOrMore,
      isWindowWidthOrLess: windowWidthOrLess,
      isWindowWidthsOrBetween: (minWidth, maxWidth) =>
        windowWidthOrMore(minWidth) && windowWidthOrLess(maxWidth),
      mobile: windowSizeOrLess("small"),
      isWindowWidthOrLessNumeric: windowNumericWidthIsLessThan,
    }),
    [
      windowNumericWidthIsLessThan,
      forceComponentSize,
      windowSize,
      windowSizeOrLess,
      windowSizeOrMore,
      windowWidthOrLess,
      windowWidthOrMore,
    ]
  );
}
