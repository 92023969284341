import { Map } from "immutable";
import PropTypes from "prop-types";
import { useMemo } from "react";

import BaseEntityLink from "./BaseEntityLink";

import { selectSpecificCreator } from "selectors/creator";
import getCategoryName from "utils/entity/getCreatorName";
import getCreatorUrl from "utils/entity/getCreatorUrl";

import useReduxState from "hooks/useReduxState";

const CreatorLink = (props) => {
  const { entity: passedEntity, entity_id, linkless, asDiv } = props;

  const entity = useReduxState(
    (state) => passedEntity || selectSpecificCreator(state, entity_id),
    [passedEntity, entity_id]
  );

  const linkProps = useMemo(() => {
    if (linkless || asDiv) {
      if (!entity.get("confirmed")) {
        return {
          title:
            "This creator needs approval from a moderator before their profile page will be accessible",
        };
      }

      return null;
    }
  }, [entity, linkless, asDiv]);

  return (
    <BaseEntityLink
      {...props}
      entity={entity}
      to={getCreatorUrl(entity)}
      name={getCategoryName(entity)}
      linkProps={linkProps}
      asDiv={entity && !entity.get("confirmed")}
      isClaimed={entity && entity.get("user")}
      isProfileType
    />
  );
};

CreatorLink.propTypes = {
  entity: PropTypes.instanceOf(Map),
  entity_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  linkless: PropTypes.bool,
  asDiv: PropTypes.bool,
};

CreatorLink.defaultProps = {
  entity: null,
  entity_id: null,
  linkless: false,
  asDiv: false,
};

export default CreatorLink;
