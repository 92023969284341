import { List } from "immutable";
import { useEffect, useState } from "react";
import { loadListOfBadgesPromise } from "routines/badges";

import { selectListOfBadgeById } from "selectors/badges";

import useReduxState from "hooks/useReduxState";
import useRoutinePromises from "hooks/useRoutinePromises";

/**
 *
 * @param {Map} userBadgesAwarded
 * new Map([{
 *   "badge_id": 1,
 *   "badge_code": "super_reviewer",
 *   "awarded_at": "2021-08-24 16:43:09"
 * }])
 * @param {Map} listOfBadges
 * new Map([{
 *  "id": 1,
 *  "title": "Super Reviewer",
 *  "code": "super_reviewer",
 *  "color": "linear-gradient(#fcdb00, #f7b500)",
 *  "badge_image_url": "https://images.podchaser.com/badges/super_reviewer@3x.png",
 *  "description": "Leave 200 reviews to unlock",
 *  "date_earned": "2021-08-24 16:43:09"
 * }])
 */
function calculateBadgesCodesWithoutInformation(
  userBadgesAwarded,
  listOfBadges
) {
  if (!userBadgesAwarded || !listOfBadges) {
    return [];
  }

  return userBadgesAwarded.reduce((acc, userBadge) => {
    const existsOnList = listOfBadges.some(
      (listBadge) => listBadge.get("id") === userBadge.get("badge_id")
    );
    if (existsOnList) {
      return acc;
    }
    return acc.push(userBadge);
  }, new List());
}

export default function useUserBadges(badgesAwarded) {
  const [isAwaitingForBadgesRequest, setIsAwaitingForBadgesRequest] =
    useState(true);

  const { loadListOfBadges } = useRoutinePromises({
    loadListOfBadges: loadListOfBadgesPromise,
  });

  const userBadgesIds = badgesAwarded?.map((badge) => badge.get("badge_id"));

  console.log("userBadgesIds", userBadgesIds);
  const listOfBadges = useReduxState((state) =>
    selectListOfBadgeById(state.badges, userBadgesIds)
  );

  useEffect(() => {
    const badgesCodeToBeRequested = calculateBadgesCodesWithoutInformation(
      badgesAwarded,
      listOfBadges
    );
    const badgesCodes = badgesCodeToBeRequested.map((badge) =>
      badge.get("badge_code")
    );

    if (badgesCodes && badgesCodes.size > 0) {
      loadListOfBadges({
        badgesCodes,
      });
    }

    setIsAwaitingForBadgesRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { listOfBadges, isAwaitingForBadgesRequest };
}
