import { createRoutine, promisifyRoutine } from "redux-saga-routines";

export const loadTimeline = createRoutine("LOAD_TIMELINE");
export const loadTimelinePromise = promisifyRoutine(loadTimeline);

export const loadMoreActivities = createRoutine(
  "LOAD_MORE_TIMELINE_ACTIVITIES"
);
export const removeTimelineActivity = createRoutine("REMOVE_TIMELINE_ACTIVITY");
export const deleteTimelineActivity = createRoutine("DELETE_TIMELINE_ACTIVITY");
export const markActivitiesAs = createRoutine("MARK_TIMELINE_ACTIVITIES_AS");
