import { css } from "aphrodite";
import PropTypes from "prop-types";
import React, { useRef, useMemo } from "react";
import { useParams } from "react-router-dom";

import MobileSaveSearchButton from "components/Buttons/MobileSaveSearchButton";
import entityList from "components/Entities/EntityList";
import ListPageHeader from "pages/Common/ListPageHeaderAsync";

import BetaBadge from "../../Brand/components/BetaBadge";
import BrandViewEpisodeCompactCard from "./BrandViewEpisodeCompactCardAsync";
import BrandViewEpisodesSkeletonLoading from "./BrandViewEpisodesSkeletonLoading";
import { TranscriptProvider } from "./TranscriptContext";

import { keysToConstants } from "constants/sort";
import * as sortConstants from "constants/sort";
import loadEpisodesList from "sagas/pagination/lists/loadEpisodesList";
import { selectSpecificEpisode } from "selectors/podcast";

import useBrandData from "hooks/useBrandData";
import useList from "hooks/useList";
import { useStyles } from "hooks/useStyles";
import useUserHasPro from "hooks/useUserHasPro";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import fontStyles from "styles/FontStyles";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const { SORT_ORDER_RECENT, SORT_ORDER_NEW, SORT_ORDER_POWER_SCORE } =
  sortConstants;

const baseStyles = {
  episodes: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "100%",
    width: "100%",
    padding: "0.5rem",
    minHeight: 100,

    [ScreenSizes.xxlAndAbove]: {
      padding: 0,
      minHeight: "46.5rem",
    },
  },
  episode: {
    marginBottom: "1rem",

    [ScreenSizes.lgAndAbove]: {
      marginBottom: "1.5rem",
    },
  },
  title: {
    ...fontStyles.avalonBold,
    margin: 0,
    marginBottom: "1.5rem",
    padding: 0,
    fontSize: "1rem",

    [ScreenSizes.mdAndAbove]: {
      fontSize: "1.25rem",
    },
  },
  subTitle: {
    ...gStyles.fontRegular,
    display: "block",
    marginTop: 0,
    padding: 0,
    fontSize: "1rem",
    lineHeight: "1.6rem",
  },
  disclaimerText: {
    ...fontStyles.fontRegularItalic,
    fontSize: "13px",
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "1.6rem",
    color: "rgb(116, 116, 116)",
    margin: 0,
    marginTop: "0.5rem",
  },
  contentWrapper: {
    minHeight: "15rem",

    [ScreenSizes.lgAndAbove]: {
      minHeight: "25rem",
    },
  },
  disclaimerContainer: {
    border: `1px solid ${colours.brightOrange}`,
    borderRadius: "1rem",
    padding: "1rem",
    marginTop: "1rem",
    marginBottom: "2rem",
    position: "relative",
  },
  click: {
    ...gStyles.avalonMedium,

    ":hover": {
      cursor: "pointer",
    },
  },
};

const overrideSortTitles = {
  [SORT_ORDER_POWER_SCORE]: {
    heading: "By Series Power Score - Episodes",
  },
};

const headerStyles = {
  titleContainer: {
    width: "100%",
  },
  actionButtons: {
    marginBottom: 0,
    justifyContent: "space-between",
    [ScreenSizes.lgAndAbove]: {
      justifyContent: "flex-start",
    },
  },
};

const badgeStyles = {
  beta: {
    marginTop: -28,
    marginLeft: 10,
    backgroundColor: colours.brightOrange,
    boxShadow: "0px 0px 14px 0px rgba(255,208,0,0.41)",
  },
};

const NUMBER_MOBILE_SKELETONS = 4; // 2 ROWS
const NUMBER_TABLET_SKELETONS = 6; // 2 ROWS
const NUMBER_DESKTOP_SKELETONS = 12; // 3 ROWS

const FILTERS_HEADING = "Filtered by %filters%";

const BrandViewEpisode = (props) => {
  const { styles } = useStyles(baseStyles, props);
  const hasPro = useUserHasPro();

  const { brandId } = props;

  const { isWindowSizeOrMore } = useWindowSize();
  const isTablet = isWindowSizeOrMore("medium");
  const isDesktop = isWindowSizeOrMore("xlarge");

  const { sort: sortParamURL } = useParams();

  const brand = useBrandData(brandId);

  const episodesKey = `brand/${brandId}/episodes`;

  let numberOfLoadingComponents = NUMBER_MOBILE_SKELETONS;

  if (isTablet) {
    numberOfLoadingComponents = NUMBER_TABLET_SKELETONS;
  }
  if (isDesktop) {
    numberOfLoadingComponents = NUMBER_DESKTOP_SKELETONS;
  }

  const { loading, filters } = useList(episodesKey);

  const renderItem = (episode, index) => {
    const episodeTranscripts = episode?.get("episode_ads", []);
    const brandSpecificTranscripts = episodeTranscripts?.filter(
      (ad) => ad?.get("brand_id") === parseInt(brandId, 10)
    );

    if (brandSpecificTranscripts?.size === 0) {
      return null;
    }

    return (
      <div
        className={css(styles.episode)}
        data-id="episodes-section"
        data-testid="episodes-section"
        key={episode ? episode.get("id") : index}
      >
        <BrandViewEpisodeCompactCard episodeId={episode?.get("id")} />
      </div>
    );
  };

  const listSortItems = useMemo(
    () => [SORT_ORDER_NEW, hasPro && SORT_ORDER_POWER_SCORE].filter((i) => i),
    [hasPro]
  );

  const header = (
    <>
      <h2 className={css(styles.subTitle)}>
        Each episode includes a playable aircheck. Expand the aircheck to read
        the transcript and view information about the placement. Sponsor data
        typically appears within 24 hours of episode publication. To learn more
        about how we track sponsor data,{" "}
        <a
          className={css(styles.click)}
          target="_blank"
          href="https://support.podchaser.com/en/articles/8067292-airchecks-faq"
          rel="noreferrer"
        >
          click here.
        </a>
      </h2>
      <div className={css(styles.disclaimerContainer)}>
        <BetaBadge styles={badgeStyles} />
        <h2 className={css(styles.disclaimerText)}>
          Airchecks are now in beta and we need your help to make our detection
          AI even better. Here’s how you can help: If you come across an
          incorrect aircheck or anything that looks a bit fishy, hit the "flag
          this aircheck" button on the individual aircheck. Our system will
          optimize based on your feedback.{" "}
          <a
            className={css(styles.click)}
            target="_blank"
            href="https://support.podchaser.com/en/articles/8067292-airchecks-faq"
            rel="noreferrer"
          >
            Learn more.
          </a>
        </h2>
      </div>
    </>
  );

  const sortKey = keysToConstants[sortParamURL] || SORT_ORDER_RECENT;

  const EpisodesList = useRef(
    entityList({
      key: episodesKey,
      sort: sortKey,
      pageSize: 25,
      list_type: "episodes_filtered",
      loadListAction: loadEpisodesList,
      entity_type: "episode",
      staticFilters: {
        sponsored_by: {
          value: brand?.get("code"),
        },
        air_date: {
          value: {
            from: "2023-03-15 00:00:00",
          },
        },
      },
      options: {
        include_ads: true,
      },
      getEntity: selectSpecificEpisode,
      dataId: "episodes-list-section",
    })
  ).current;

  if (!brand) {
    return (
      <div className={css(styles.episodes)}>
        <h1 className={css(styles.title)}>{"Episodes sponsored"}</h1>
        {header}
      </div>
    );
  }

  const renderSavedSearchButton = () => (
    <MobileSaveSearchButton
      entity_type="episode"
      context="EpisodesIndexPageTitle"
    />
  );

  return (
    <div className={css(styles.episodes)}>
      <h1 className={css(styles.title)}>
        {`Episodes sponsored by ${brand?.get("name")}`}
      </h1>
      {header}
      <div className={css(styles.contentWrapper)}>
        <ListPageHeader
          listKey={episodesKey}
          listSortItems={listSortItems}
          isSearchable={false}
          entity_type="episode"
          hideAbove
          isSortable
          filterHeading={FILTERS_HEADING}
          overrideSortTitles={overrideSortTitles}
          titleAside={filters && filters.size > 0 && renderSavedSearchButton()}
          styles={headerStyles}
          showHeading
          showFilters
        />
        {loading && (
          <ul className={css(styles.podcastList)}>
            <BrandViewEpisodesSkeletonLoading
              numberOfLoadingComponents={numberOfLoadingComponents}
            />
          </ul>
        )}
        <TranscriptProvider>
          <EpisodesList
            renderItem={renderItem}
            noResultsMessage="No episodes were found"
            noSearchResultsMessage="No episodes were found for your search"
            showLoadMore
          />
        </TranscriptProvider>
      </div>
    </div>
  );
};

BrandViewEpisode.propTypes = {
  brandId: PropTypes.number.isRequired,
};

export default BrandViewEpisode;
