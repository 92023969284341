import { faHammer } from "@fortawesome/free-solid-svg-icons/faHammer";
import { faHeadphones } from "@fortawesome/free-solid-svg-icons/faHeadphones";
import { faAnalytics } from "@fortawesome/pro-solid-svg-icons/faAnalytics";
import { faHeartRate } from "@fortawesome/pro-solid-svg-icons/faHeartRate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo } from "react";

import BrowseNavMenu, { NAV_LINKS as browseNavLinks } from "./BrowseNavMenu";
import MonitoringNavMenu, {
  NAV_LINKS as monitorNavLinks,
} from "./MonitoringNavMenu";
import MoreNavMenu from "./MoreNavMenu";
import PodcastersToolsNavMenu from "./PodcastersToolsNavMenu";
import ProToolsNavMenu from "./ProToolsNavMenu";
import ToolsNavMenu from "./ToolsNavMenu";
import { useToolsMenuRoutes, useToolNavLinks } from "./useToolNavLinks";

import generateTransition from "utils/generateTransition";

import { useLoggedIn } from "hooks/useLoggedInUser";
import { useStyles } from "hooks/useStyles";
import useUserHasInternalPermission from "hooks/useUserHasInternalPermission";
import useWindowSize from "hooks/useWindowSize";

import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  navIcon: {
    width: "1rem",
    height: "1rem",
    marginTop: "-.1rem",

    alignSelf: "center",
    transition: generateTransition({
      targets: ["transform", "text-shadow"],
      speed: "150ms",
    }),
    transform: "scale(1.1,1.1)",
    marginRight: "0.6em",
    fontSize: "1rem",
    lineHeight: "1.5em",

    display: "none",

    [ScreenSizes.smOnly]: {
      fontSize: "0.875rem",
    },

    [ScreenSizes.smAndAbove]: {
      display: "flex",
    },

    [ScreenSizes.lgAndAbove]: {
      display: "none",
    },
  },
};

function NavIcon({ icon }) {
  const { styles, css } = useStyles(baseStyles, {});

  return (
    <div className={css(styles.navIcon)}>
      <FontAwesomeIcon icon={icon} />
    </div>
  );
}

const useNavLinks = () => {
  const isLoggedIn = useLoggedIn();
  const { isWindowSizeOrLess } = useWindowSize();
  const mobile = isWindowSizeOrLess("medium");
  const { podcasterLinks, brandLinks } = useToolNavLinks();
  const userIsInternal = useUserHasInternalPermission();

  const podcasterNavLinks = useToolsMenuRoutes(podcasterLinks);
  const brandNavLinks = useToolsMenuRoutes(brandLinks);

  const leftItems = useMemo(
    () =>
      [
        {
          id: "browse",
          title: "Browse Podcasts | Episodes | Categories | Lists",
          label: "Podcasts",
          menu: BrowseNavMenu,
          menuRoutes: browseNavLinks.map((link) => link.url),
          icon: <NavIcon icon={faHeadphones} />,
        },

        {
          id: "charts",
          title: "Charts",
          label: "Charts",
          route: "/charts",
          path: "/charts",
          icon: <NavIcon icon={faAnalytics} />,
        },
        {
          id: "monitoring",
          title: "Monitoring",
          label: "Monitoring",
          menu: MonitoringNavMenu,
          menuRoutes: monitorNavLinks.map((link) => link.url),
          icon: <NavIcon icon={faHeartRate} />,
        },
        mobile && {
          id: "tools",
          title: "Tools",
          label: "Tools",
          menu: ToolsNavMenu,
          menuRoutes: [...podcasterNavLinks, ...brandNavLinks],
          icon: <NavIcon icon={faHammer} />,
        },
      ].filter((i) => !!i),
    [isLoggedIn, mobile, podcasterNavLinks, brandNavLinks]
  );

  const rightItems = useMemo(
    () =>
      [
        {
          id: "business",
          title: "For Business",
          label: "For Business",
          context: "NavMenuItemSecondaryWithDropdown",
          arrowPlacement: "top-end",
          menu: ProToolsNavMenu,
          menuRoutes: brandNavLinks,
        },
        {
          id: "podcasters",
          title: "For Podcasters",
          label: "For Podcasters",
          context: "NavMenuItemSecondaryWithDropdown",
          arrowPlacement: "top-end",
          menu: PodcastersToolsNavMenu,
          menuRoutes: podcasterNavLinks,
        },
        {
          id: "more",
          title: "More",
          label: "More",
          context: "NavMenuItemSecondaryWithDropdown",
          menu: MoreNavMenu,
          arrowPlacement: "top-end",
        },
      ].filter((i) => !!i),
    [brandNavLinks, userIsInternal, podcasterNavLinks]
  );

  return {
    leftItems,
    rightItems,
  };
};

export default useNavLinks;
