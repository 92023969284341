import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

const IntegrationLink = Loadable(() => import("./IntegrationLink"));

function LinkAsyncLoading(props) {
  return <LoadingOverlay {...props} span />;
}

const IntegrationLinkContainer = (props) => {
  return <IntegrationLink {...props} fallback={<LinkAsyncLoading />} />;
};

export default IntegrationLinkContainer;
