import PropTypes from "prop-types";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";

const TRANSITION_METHOD = "cubic-bezier(0.465, 0.183, 0.153, 0.946)";

const baseStyles = {
  navIconBackgroundContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    borderRadius: "100px",
    display: "block",
    backfaceVisibility: "hidden",
    transform: "translate3d(0,0,0)",
  },
  navIconBackground: {
    borderRadius: "100px",
    overflow: "hidden",
    display: "block",
    width: "100%",
    height: "100%",
    // NOTE: The following doesn't work because the compnent rerenders on every hover,
    // probably because the hover prop is being passed in and it changes each time.
    transition: `transform 500ms ${TRANSITION_METHOD}, background 500ms ${TRANSITION_METHOD}`,
    backfaceVisibility: "hidden",
    transform: "translate3d(0,0,0)",
    background: gStyles.strongNewSecondaryGradient(0),
  },
  navIconBackgroundHover: {
    transform: "translate3d(0,0,0) rotate(90deg) scale(3,3)",
    borderRadius: "100px",
  },

  navIconBackground0: {
    background: gStyles.strongNewSecondaryGradient(0),
  },
  navIconBackground0Hover: {
    background: gStyles.vibrantBrandGradient(0),
  },
  navIconBackground1: {
    background: gStyles.strongNewSecondaryGradient(90),
  },
  navIconBackground1Hover: {
    background: gStyles.vibrantBrandGradient(90),
  },
  navIconBackground2: {
    background: gStyles.strongNewSecondaryGradient(180),
  },
  navIconBackground2Hover: {
    background: gStyles.vibrantBrandGradient(180),
  },
  navIconBackground3: {
    background: gStyles.strongNewSecondaryGradient(270),
  },
  navIconBackground3Hover: {
    background: gStyles.vibrantBrandGradient(270),
  },
};

const DropdownNavMenuIconBackground = (props) => {
  const { index, hover, image } = props;
  const { css, styles } = useStyles(baseStyles, props);

  return (
    <span className={css(styles.navIconBackgroundContainer)}>
      <span
        className={css(
          styles.navIconBackground,
          !image && styles[`navIconBackground${index % 4}`],
          hover && styles.navIconBackgroundHover,
          !image && hover && styles[`navIconBackground${index % 4}Hover`]
        )}
      />
    </span>
  );
};

DropdownNavMenuIconBackground.propTypes = {
  index: PropTypes.number.isRequired,
  hover: PropTypes.bool,
  image: PropTypes.node,
};

DropdownNavMenuIconBackground.defaultProps = {
  hover: false,
  image: null,
};

export default DropdownNavMenuIconBackground;
