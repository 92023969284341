import { List, Map } from "immutable";
import createCachedSelector from "re-reselect";
import { createSelector } from "reselect";

export const selectPodcasts = (state) =>
  state.podcast ? state.podcast.getIn(["podcasts", "entities"]) : Map();

export const selectPodcastAd = (state) =>
  state.podcast ? state.podcast.getIn(["ads", "entities"]) : Map();

export const selectEpisodes = (state) =>
  state.podcast ? state.podcast.getIn(["episodes", "entities"]) : Map();

export const selectBrands = (state) =>
  state.podcast?.getIn(["brands", "entities"]);

export const selectEpisodeProData = (state) =>
  state.podcast?.getIn(["episode_pro_data", "entities"]);

export const selectSpecificPodcast = (state, id) => {
  if (!id) {
    return null;
  }
  const podcast =
    state.podcast &&
    state.podcast.getIn(["podcasts", "entities", id?.toString()]);
  return podcast;
};

export const selectSpecificPodcasts = (state, ids) => {
  if (!ids) {
    return null;
  }
  const podcasts = [];
  if (state.podcast) {
    for (let id of ids) {
      if (state.podcast.getIn(["podcasts", "entities", id?.toString()])) {
        podcasts.push(
          state.podcast.getIn(["podcasts", "entities", id?.toString()])
        );
      }
    }
  }
  return podcasts;
};

const selectSpecificPodcastMemoized = createSelector(
  [selectPodcasts, (_, podcastId) => podcastId],
  (podcasts, podcastId) => podcasts.get(podcastId?.toString())
);
const selectSpecificPodcastAdMemoized = createSelector(
  [selectPodcastAd, (_, podcastId) => podcastId],
  (podcasts, podcastId) => podcasts?.get(podcastId?.toString())
);
export const makeSelectSpecificPodcast = () => selectSpecificPodcastMemoized;
export const makeSelectSpecificPodcastAd = () =>
  selectSpecificPodcastAdMemoized;

const selectSpecificEpisodeMemoized = createSelector(
  [selectEpisodes, (_, episodeId) => episodeId],
  (episodes, episodeId) => episodes.get(episodeId?.toString())
);
export const makeSelectSpecificEpisode = () => selectSpecificEpisodeMemoized;

export const selectProDataForPodcast = (state, id) => {
  if (!id) {
    return null;
  }
  const proData =
    state.podcast &&
    state.podcast.getIn(["pro_data", "entities", id?.toString()]);
  return proData;
};

export const selectFlaggedTranscripts = (state) => {
  const flaggedTranscript =
    state.podcast &&
    state.podcast.getIn([
      "episodes",
      "entities",
      "flaggedTrasncripts",
      "transcripts",
    ]);
  return flaggedTranscript;
};

const selectFlaggedTranscriptsMemoized = createSelector(
  [selectFlaggedTranscripts, (_, episodeId) => episodeId],
  (episodes) => episodes
);

export const makesSlectFlaggedTranscripts = () =>
  selectFlaggedTranscriptsMemoized;

export const selectFlaggedTranscriptsLoading = (state) =>
  (state.podcast &&
    state.podcast.getIn([
      "episodes",
      "entities",
      "flaggedTrasncripts",
      "loading",
    ])) ||
  false;

export const selectFlaggedTranscriptsFailed = (state) =>
  (state.podcast &&
    state.podcast.getIn([
      "episodes",
      "entities",
      "flaggedTrasncripts",
      "failed",
    ])) ||
  false;

export const selectCategoriesForPodcast = createCachedSelector(
  (state) => state.category && state.category.getIn(["categories", "entities"]),
  selectSpecificPodcast,
  (categories, podcast) =>
    (podcast &&
      podcast.has("categories") &&
      podcast.get("categories").map((id) => categories.get(id?.toString()))) ||
    List()
)((state, podcast_id) => podcast_id);

function parseId(id) {
  return id ? id?.toString() : "";
}

export const selectPodcastLoading = (state, id) =>
  (state.podcast &&
    state.podcast.getIn(["podcasts", "loading", parseId(id)])) ||
  false;

export const selectPodcastFailed = (state, id) =>
  (state.podcast && state.podcast.getIn(["podcasts", "failed", parseId(id)])) ||
  false;

export const selectEpisodeLoading = (state, id) =>
  (state.podcast &&
    state.podcast.getIn(["episodes", "loading", parseId(id)])) ||
  false;

const selectSpecificEpisodeLoadingMemoized = createSelector(
  [selectEpisodes, (_, id) => id],
  (episodes, id) =>
    episodes.getIn([id?.toString(), "transcriptLoading"]) || false
);

export const makeSelectTranscriptLoading = () =>
  selectSpecificEpisodeLoadingMemoized;

export const selectEpisodeFailed = (state, id) =>
  (state.podcast && state.podcast.getIn(["episodes", "failed", id])) || false;

export const selectSpecificUserlistItem = (state, id, entity_type) => {
  if (!id) {
    return null;
  }
  const podcast =
    state.podcast &&
    state.podcast.getIn([
      entity_type === "episode" ? "episodes" : "podcasts",
      "entities",
      id?.toString(),
    ]);

  return podcast;
};
export const selectUserlistItemLoading = (state, id, entity_type) =>
  (state.podcast &&
    state.podcast.getIn([
      entity_type === "episode" ? "episodes" : "podcasts",
      "loading",
      parseId(id),
    ])) ||
  false;

export const selectUserlistItemFailed = (state, id, entity_type) =>
  (state.podcast &&
    state.podcast.getIn([
      entity_type === "episode" ? "episodes" : "podcasts",
      "failed",
      parseId(id),
    ])) ||
  false;

export const selectProDataLoading = (state, id) =>
  (state.podcast &&
    state.podcast.getIn(["pro_data", "loading", parseId(id)])) ||
  false;

export const selectProDataFailed = (state, id) =>
  (state.podcast && state.podcast.getIn(["pro_data", "failed", parseId(id)])) ||
  false;

export const selectSpecificEpisode = (state, id) =>
  state.podcast?.getIn(["episodes", "entities", parseId(id)]);

export const selectSpecificEpisodeWithPodcast = (state, id) => {
  const episode = selectSpecificEpisode(state, id);
  if (!episode) {
    return episode;
  }
  const podcast = selectSpecificPodcast(state, episode.get("podcast_id"));

  return episode.set("podcast", podcast);
};

const selectEpisodesByPodcastId = createSelector(
  selectEpisodes,
  (_, podcastId) => podcastId,
  (episodes, podcastId) =>
    episodes.reduce((acc, episode) => {
      if (episode.get("podcast_id")?.toString() !== podcastId?.toString()) {
        return acc;
      }

      return acc.push(episode);
    }, List())
);

export const makeSelectEpisodesByPodcastId = () => selectEpisodesByPodcastId;

const selectEpisodesByPodcastIdAndYear = createSelector(
  selectEpisodesByPodcastId,
  (_, _podcastId, year) => year,
  (episodes, year) =>
    episodes.reduce((acc, episode) => {
      const episodeDate = new Date(episode?.get("air_date"));

      if (episodeDate.getFullYear() === year) {
        return acc.push(episode);
      }

      return acc;
    }, List())
);

export const makeSelectEpisodesByPodcastIdAndYear = () =>
  selectEpisodesByPodcastIdAndYear;

export const selectPodcastByVanityUrl = (state, url) => {
  const id =
    state.podcast &&
    state.podcast.getIn(["podcasts", "by_vanity_url", url.toLowerCase()]);
  return id && selectSpecificPodcast(state, id);
};

export const selectIsSavedToUserList = (state, id, entity_type = "podcast") => {
  const entity =
    entity_type === "episode"
      ? selectSpecificEpisode(state, id)
      : selectSpecificPodcast(state, id);
  const lists = entity && entity.getIn(["user_data", "in_lists"]);

  return lists && lists.size > 0;
};

export const selectIsBookmarking = (state, entity_type = null) => {
  if (entity_type) {
    return state.podcast
      .getIn(["bookmarking"])
      .some((item) => item.includes(`${entity_type}:`));
  }

  return state.podcast.getIn(["bookmarking"]).size > 0;
};

const selectSpecificBrand = createSelector(
  [selectBrands, (_, brandId) => brandId],
  (brands, brandId) => brands.get(brandId?.toString())
);

export const makeSelectSpecificBrand = () => selectSpecificBrand;

const selectBrandsByPodcastId = createSelector(
  [selectBrands, (_, podcastId) => podcastId],
  (brands, podcastId) => {
    if (!podcastId) {
      return List();
    }

    return brands
      .filter((brand) => {
        if (brand.get("sponsored_podcasts")?.size === 0) {
          return false;
        }

        return brand
          .get("sponsored_podcasts")
          .some(
            (sponsoredPodcast) =>
              sponsoredPodcast.get("podcast_id") === podcastId
          );
      })
      .toList();
  }
);

export const makeSelectBrandsByPodcastId = () => selectBrandsByPodcastId;

export const selectEpisodeProDataReselect = createSelector(
  [selectEpisodeProData, (_, episodeId) => episodeId],
  (episodeProData, episodeId) => {
    if (!episodeId) {
      return Map();
    }

    return episodeProData.get(episodeId?.toString());
  }
);

export const makeSelectEpisodeProData = () => selectEpisodeProDataReselect;

export const selectEpisodeProDataLoading = (state, id) =>
  state.podcast?.getIn(["episode_pro_data", "loading", parseId(id)]) || false;

export const selectEpisodeProDataFailed = (state, id) =>
  state.podcast?.getIn(["episode_pro_data", "failed", parseId(id)]) || false;

export const selectSpecificPodcastFromEpisode = (state, episode) => {
  const pulledPodcast = selectSpecificPodcast(
    state,
    episode && episode.get("podcast_id")
  );

  if (!pulledPodcast && episode && typeof episode.get("podcast") === "object") {
    return episode.get("podcast");
  }

  return pulledPodcast;
};
