import ScreenSizes from "./ScreenSizes";

import generateTransition from "utils/generateTransition";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import getHoverQuery from "styles/getHoverQuery";

const inputRequired = {
  ...gStyles.fontSemiBold,
  width: 5,
  height: 5,
  backgroundColor: colours.primary,
  borderRadius: "50%",
  display: "inline-block",
  verticalAlign: "super",
  marginLeft: "0.25em",
  marginTop: "0.25em",
  position: "absolute",

  [ScreenSizes.lgAndAbove]: {
    width: 3,
    height: 3,
  },
};

const fieldStyles = {
  outer: {
    ...gStyles.fontRegular,
    display: "block",
    position: "relative",
    background: colours.white,
    color: colours.bodyText,
    margin: 0,
    width: "100%",
    maxWidth: 500,
    textAlign: "left",
  },
  label: {
    position: "absolute",
    height: "100%",
    zIndex: 15,
  },
  labelBox: {
    width: "3em",
    height: "100%",
    marginBottom: "-3em",
    position: "relative",
    display: "block",
    borderRight: "1px solid #ddd",
    paddingTop: "0.6em",
  },
  labelIcon: {
    color: colours.greyishBlue,
    width: "3em",
    textAlign: "center",
    display: "block",
    position: "absolute",
    transition: generateTransition({
      target: "visual",
      targets: ["transform"],
      speed: "300ms",
    }),
  },
  labelIconFieldFocused: {
    color: "#333",
    transform: "scale(1.2,1.2)",
  },
  labelIconFieldError: {
    color: colours.negative,
  },
  labelIconFieldValid: {
    color: colours.positive,
  },
  labelIconFieldDisabled: {
    opacity: 0.2,
    transform: "scale(.8)",
  },
  labelText: {
    ...gStyles.fontLight,
    color: colours.greyishBlue,
    paddingLeft: "0.7em",
    position: "absolute",
    left: "100%",
    display: "block",
    transition: generateTransition({
      target: "visual",
      targets: ["transform"],
      speed: "300ms",
    }),
    width: "300px",
    opacity: 0,
    transform: "translate(0em,1em)",
  },
  labelTextFieldShow: {
    opacity: 1,
    transform: "initial",
  },
  labelTextFieldDisabled: {
    opacity: 0.2,
  },
  fieldContainer: {
    display: "block",
    width: "100%",
    position: "relative",
  },
  inputRequired: {
    width: 5,
    height: 5,
    backgroundColor: colours.primary,
    borderRadius: "50%",
    display: "inline-block",
    verticalAlign: "super",
    marginLeft: "0.25em",
    marginTop: "0.25em",
    position: "absolute",

    [ScreenSizes.lgAndAbove]: {
      width: 3,
      height: 3,
    },
  },
  field: {
    color: colours.bodyText,
    background: "none",
    width: "100%",
    boxShadow: "none",
    boxSizing: "border-box",
    lineHeight: "2.5em",
    height: "2.5em",
    border: "1px solid #ddd",

    ":focus": {
      outline: "none",
      borderColor: "#ddd",
      boxShadow: "none",
    },
  },
  selectWrap: {
    position: "relative",
  },
  carretDown: {
    position: "absolute",
    right: "0.625rem",
    top: "50%",
    transform: "translateY(-50%)",
    pointerEvents: "none",
  },
  fieldNoLabelPadding: {
    padding: "0.6em 0.7em 0.4em 0.7em",
  },
  fieldLabelPadding: {
    padding: "0.6em 0.7em 0.4em 3.7em",
  },
  fieldWithIcon: {
    paddingLeft: "3.7em",
  },
  fieldError: {
    position: "absolute",
    display: "block",
    background: colours.negative,
    padding: "0.4em 0.8em 0.3em",
    fontSize: ".875rem",
    lineHeight: "1.25rem",
    boxShadow: "0 0.2em 0.3em rgba(0,0,0,0.2)",
    color: colours.white,
    width: "100%",
    boxSizing: "border-box",
    margin: 0,
    left: 0,
    zIndex: 99,

    ":before": {
      content: "''",
      background: "inherit",
      width: "0.7em",
      height: "0.7em",
      display: "block",
      position: "absolute",
      top: 0,
      left: "50%",
      marginTop: "-0.35em",
      transform: "rotate(45deg)",
    },
    popover: {},

    [ScreenSizes.lgAndAbove]: {
      fontSize: ".75rem",
      lineHeight: "1rem",
    },
  },
  inputBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    height: "100%",
    position: "relative",
  },
  inputLabel: {
    ...gStyles.avalonBold,
    position: "relative",
    display: "block",
    margin: "1rem 0 .5rem",
    fontSize: "1rem",
    lineHeight: "1.75",
    color: colours.bodyText,

    [ScreenSizes.mdAndAbove]: {
      fontSize: "0.9375rem",
    },
  },
  inputLabelTextOptional: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  optionalLabel: {
    ...gStyles.avalonLight,
  },
  inputField: {
    ...gStyles.avalonMedium,
    marginTop: "auto",
    padding: "0.75em 1.1em 0.65em",
    borderRadius: "4px",
    border: "1px solid #DADADA",
    fontSize: "1rem",
    color: colours.bodyText,
    outline: "none",
    width: "100%",
    transition: generateTransition({ target: "visual", speed: "250ms" }),
    textAlign: "left",

    ...getHoverQuery({
      boxShadow: "0 4px 14px 0 rgba(0,0,0,0.05)",
    }),
    ":focus": {
      boxShadow: "0 4px 14px 0 rgba(0,0,0,0.05)",
    },
    "::placeholder": {
      ...gStyles.fontRegularItalic,
      color: "var(--color-neutral-d5)",
    },
  },
  inputFieldDisabled: {
    cursor: "not-allowed",
    backgroundColor: "#EEE",
    opacity: 0.4,
  },
  inputFieldWithFocusBar: {
    border: "1px transparent solid",
  },
  inputRequiredStar: {
    ...inputRequired,
    width: "auto",
    height: "auto",
    backgroundColor: "transparent",
    color: colours.negative,
    top: 0,
    right: 0,
    fontSize: "1.4rem",
    zIndex: 1,

    [ScreenSizes.lgAndAbove]: {
      width: "auto",
      height: "auto",
    },
  },
  focusBar: {
    position: "absolute",
    width: "100%",
    left: 0,
    bottom: 0,
    height: 2,
    display: "block",
    overflow: "hidden",
    borderRadius: 14,
    backgroundColor: "#000",
    transition: generateTransition({
      target: "visual",
      targets: ["width"],
      speed: "250ms",
    }),
    pointerEvents: "none",
  },
  focusBarClosed: {
    backgroundColor: "transparent",
    width: 0,
  },
  errorFocusBar: {
    backgroundColor: colours.negative,
  },
  validatedFocusBar: {
    backgroundColor: colours.positive,
  },
  errorMessage: {
    minHeight: "0.875rem",
    fontSize: "0.75rem",
    color: colours.negative,
    paddingTop: ".3rem",
  },
  underInput: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    width: "100%",
  },
  bottom: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    width: "100%",
  },
  inputInfo: {
    ...gStyles.fontMediumItalic,
    marginTop: ".3rem",
    marginBottom: "1rem",
    fontSize: "0.75rem",
    lineHeight: "1.0625rem",
    textAlign: "right",
    color: "var(--color-neutral-d3)",
    maxWidth: "60%",
    minWidth: "10rem",
    marginLeft: "auto",
  },
  inputError: {
    border: `1px ${colours.negative} solid`,
  },
  inputValidated: {
    border: `1px ${colours.positive} solid`,
  },
};

export default fieldStyles;
