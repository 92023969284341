import Loadable from "@loadable/component";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";

const HomepageSidebar = Loadable(() => import("./HomepageSidebar"));

const baseStyles = {
  container: {
    ...gStyles.skeletonPlaceholder,
    height: "210rem",
    width: "18.75rem",
  },
};

function HomepageSidebarLoading(props) {
  const { styles, css } = useStyles(baseStyles);

  return <div className={css(styles.container)} />;
}

const HomepageSidebarContainer = (props) => {
  return <HomepageSidebar {...props} fallback={<HomepageSidebarLoading />} />;
};

export default HomepageSidebarContainer;
