const EMOJIS_UNICODE_REGEX =
  /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi;

export const removeEmojis = (string) => {
  if (string && typeof string === "string" && string.length > 0) {
    return string.replace(EMOJIS_UNICODE_REGEX, "");
  }

  return string;
};

export default EMOJIS_UNICODE_REGEX;
