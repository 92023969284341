import { css } from "aphrodite";
import { useMemo, useCallback, useState } from "react";
import { Link } from "react-router-dom";

import LoadingIcon from "components/Common/LoadingIcon";

import authActions from "../../actions/auth";
import StandardButton from "../../components/Buttons/StandardButton";
import EntityImage from "../../components/Entities/EntityImage";
import useActionCreators from "../../hooks/useActionCreators";
import useLoggedInUser from "../../hooks/useLoggedInUser";
import useWindowSize from "../../hooks/useWindowSize";
import colours from "../../styles/colours";
import gStyles from "../../styles/GenericStyles";
import ScreenSizes from "../../styles/ScreenSizes";
import { oAuthStyles } from "./OAuthStyles";

import { approveOauthRequest } from "api/live/oauth";
import { mergeNewQueryParamsWithUrl } from "utils/url";

import { useStyles } from "hooks/useStyles";

const baseStyles = {
  ...oAuthStyles,
  container: {},
  buttons: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginBottom: "1rem",
    [ScreenSizes.mdAndAbove]: {
      flexDirection: "row",
    },
  },
  button: {
    flex: "0 1 50%",
    marginRight: 0,
    marginBottom: "0.5rem",
    [ScreenSizes.mdAndAbove]: {
      marginRight: "1rem",
      marginBottom: 0,
    },
    ":last-child": {
      marginRight: 0,
      marginBottom: 0,
    },
  },
  userInfo: {
    ...gStyles.avalonRegular,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  userImage: {
    width: 48,
    height: 48,
    marginRight: "0.75rem",
  },
  username: {
    ...gStyles.avalonBold,
  },
  changeLink: {
    ...gStyles.fontRegular,
    color: colours.primary,
  },
};

function OAuthAuthorizationPrompt(props) {
  const { styles } = useStyles(baseStyles, props);

  const { isWindowSizeOrLess } = useWindowSize();
  const [authorizing, setAuthorizing] = useState(false);
  const [error, setError] = useState();

  const user = useLoggedInUser();

  const { authToken, redirect_uri, scopes, state, client_id } = props;

  const handleAuthorize = useCallback(
    (e) => {
      e.preventDefault();
      setAuthorizing(true);
      approveOauthRequest({
        auth_token: authToken,
        redirect_uri,
        scope: scopes.join(" "),
        client_id,
      })
        .then((response) => {
          if (response.data.redirect) {
            const redirectUri = response.data.redirect_uri[0];
            const additionalQueryParams = { state };
            console.log(`forwarding to final location ${redirectUri}`);
            const finalRedirectUri = mergeNewQueryParamsWithUrl(
              redirectUri,
              additionalQueryParams
            );
            window.location.href = finalRedirectUri;
          }
        })
        .catch((e) => {
          setError(e.message);
        })
        .finally(() => setAuthorizing(false));
      return false;
    },
    [authToken, client_id, redirect_uri, scopes, state]
  );

  const declineUrl = useMemo(() => {
    const url = new URL(redirect_uri);
    url.searchParams.append("error_code", "authorization_declined");
    url.searchParams.append("error_message", "The authorization was declined");
    return url.href;
  }, [redirect_uri]);

  const handleDecline = useCallback(
    (e) => {
      e.preventDefault();
      if (window && window.location) {
        window.location.href = declineUrl;
      }
      return false;
    },
    [declineUrl]
  );

  const { startAuthFlow } = useActionCreators({
    startAuthFlow: authActions.startAuthFlow,
  });

  const { logout } = useActionCreators({
    logout: authActions.logout,
  });

  const handleChangeUser = useCallback(
    (e) => {
      e.preventDefault();
      startAuthFlow(true, {
        analyticsVariables: {
          triggered_by: "OAuthChangeUserButton",
        },
      });
      setTimeout(() => {
        // This is delayed so the form doesn't jump around before the modal iss hown.
        logout && logout();
      }, 700);
      return false;
    },
    [logout, startAuthFlow]
  );

  const declineButton = (
    <div className={css(styles.button, styles.buttonDecline)}>
      <StandardButton
        variation="subtle"
        onClick={handleDecline}
        label="Decline"
        disabled={authorizing}
      />
    </div>
  );

  const authorizeButton = (
    <div className={css(styles.button, styles.buttonAuthorize)}>
      <StandardButton
        variation="pink"
        onClick={handleAuthorize}
        label={authorizing ? <LoadingIcon /> : "Authorize"}
      />
    </div>
  );

  if (!user) {
    // TODO: Figure out why sometimes user is null but we still get to this component
    return <div className={css(styles.container)}>Not logged in</div>;
  }

  if (error) {
    return <div className={css(styles.errorMessage)}>{error}</div>;
  }

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.buttons)}>
        {isWindowSizeOrLess("medium") ? authorizeButton : declineButton}
        {isWindowSizeOrLess("medium") ? declineButton : authorizeButton}
      </div>
      <span className={css(styles.userInfo)}>
        <span className={css(styles.userImage)}>
          <EntityImage
            entity={user}
            entity_type="user"
            size={80}
            disablePopup
            externalLink
          ></EntityImage>
        </span>
        <div>
          Logged in as{" "}
          <span className={css(styles.username)}>{user.get("username")}</span> (
          <Link
            to={null}
            onClick={handleChangeUser}
            className={css(styles.changeLink)}
          >
            change
          </Link>
          )
        </div>
      </span>
    </div>
  );
}

OAuthAuthorizationPrompt.propTypes = {};

OAuthAuthorizationPrompt.defaultProps = {};

export default OAuthAuthorizationPrompt;
