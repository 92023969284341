import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const HomepageSidebarPodcasts = Loadable(() =>
  import("./HomepageSidebarPodcasts")
);

function HomepageSidebarPodcastsAsyncLoading(props) {
  return <LoadableLoading {...props} transparent />;
}

const HomepageSidebarPodcastsContainer = (props) => {
  return (
    <HomepageSidebarPodcasts
      {...props}
      fallback={<HomepageSidebarPodcastsAsyncLoading />}
    />
  );
};

export default HomepageSidebarPodcastsContainer;
