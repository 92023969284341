import { Map } from "immutable";

export const selectReviews = (state) =>
  state.review ? state.review?.getIn(["reviews", "entities"]) : Map();
export const selectSpecificReview = (state, id) =>
  state.review?.getIn(["reviews", "entities", id?.toString()]);
export const selectReviewLoading = (state, id) =>
  state.review?.getIn(["reviews", "loading", id?.toString()]);
export const selectReviewFailed = (state, id) =>
  state.review?.getIn(["reviews", "failed", id?.toString()]);
