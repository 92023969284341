import localStorage from "local-storage";
import { useCallback, useRef, useState } from "react";

import useWindowSize from "hooks/useWindowSize";

const TourManager = (props) => {
  const windowSize = useWindowSize();
  const toursRef = useRef([]);

  const getValidTour = () => {
    if (localStorage.get("disable_all_tours")) {
      return null;
    }
    const validTour = toursRef.current.find(
      (tour) =>
        tour.shouldRun(props) &&
        !localStorage.get(`tour_finished_${tour.tourKey}`)
    );

    return validTour || null;
  };

  const [runningTour] = useState(getValidTour());

  const stateCallback = useCallback(
    (state) => {
      if (state.action === "close") {
        localStorage.set(`tour_finished_${runningTour.tourKey}`, true);
      }
    },
    [runningTour]
  );

  if (!runningTour) {
    return null;
  }

  const TourToRun = runningTour;

  return <TourToRun stateCallback={stateCallback} windowSize={windowSize} />;
};

export default TourManager;
