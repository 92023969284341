import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

import ScreenSizes from "styles/ScreenSizes";

const loadingStyles = {
  height: {
    height: "406rem",
    maxHeight: "406rem",
    minHeight: 0,

    [ScreenSizes.lgAndAbove]: {
      height: "350rem",
      maxHeight: "350rem",
    },
  },
};

const TagViewContainer = Loadable(() => import("./TagViewContainer"));

function TagViewContainerAsyncLoading(props) {
  return <LoadingOverlay height={"137rem"} styles={loadingStyles} />;
}

const TagViewContainerContainer = (props) => {
  return (
    <TagViewContainer {...props} fallback={<TagViewContainerAsyncLoading />} />
  );
};

export default TagViewContainerContainer;
