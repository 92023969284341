import { fromJS, Map } from "immutable";
import { call, put } from "redux-saga/effects";

import * as paginationConstants from "constants/pagination";
import { SORT_ORDER_RECENT } from "constants/sort";

function* createSuggestedCreditList(creator, pagination, result = null) {
  const key = `creator/${creator.get("id")}/suggested_credits`;

  const suggestedCreditFilters = pagination.getIn([
    "filters",
    "suggested_credit_filters",
    "value",
  ]);
  let parentFilters = {};
  if (suggestedCreditFilters) {
    parentFilters = suggestedCreditFilters
      .mapEntries(([k, v]) => [k, Map().set("value", v)])
      .toJS();
  }

  // initialise the list and fire off a success with just our current values in there
  yield put({
    type: paginationConstants.PAGINATION_INIT_LIST_CREATE,
    key,
    list_type: "suggested_credits_filtered",
    entity_type: "suggested_credit",
    staticFilters: {
      creator_id: { value: creator.get("id") },
      ...parentFilters,
    },
    sort: SORT_ORDER_RECENT,
    pageSize: 25,
    additional: result.getIn(["response", "additional"]),
    total: creator.getIn(["suggested_credits", "total"]),
    hasMore: creator.getIn(["suggested_credits", "total"]) > 25,
  });

  yield put({
    type: paginationConstants.PAGINATION_UPDATE_LIST,
    key,
    idsBySort: {
      [SORT_ORDER_RECENT]: creator.getIn(["suggested_credits", "credits"]),
    },
  });
}

// create lists for suggested credits if present
export default function* onCreatorListLoad(passedData) {
  const data = fromJS(passedData);

  if (!data.hasIn(["response", "entities", "creators"])) {
    return;
  }

  const creatorsList = data
    .getIn(["response", "entities", "creators"])
    .toList();

  // for each leaf node in the response, create a new list
  for (let i = 0; i < creatorsList.size; i++) {
    if (creatorsList.get(i).has("suggested_credits")) {
      yield call(
        createSuggestedCreditList,
        creatorsList.get(i),
        data.get("pagination"),
        data
      );
    }
  }
}
