import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const HomepageViewAsync = Loadable(() =>
  import("./HomepageFeedNothingMoreToLoad")
);

function HomepageViewAsyncLoading(props) {
  return <LoadableLoading {...props} transparent />;
}

const HomepageViewAsyncContainer = (props) => {
  return (
    <HomepageViewAsync {...props} fallback={<HomepageViewAsyncLoading />} />
  );
};

export default HomepageViewAsyncContainer;
