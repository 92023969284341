import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const AccountManagementContainer = Loadable(() =>
  import("./AccountManagementContainer")
);

function AccountManagementContainerAsyncLoading(props) {
  return <LoadableLoading {...props} page />;
}

const AccountManagementContainerContainer = (props) => {
  return (
    <AccountManagementContainer
      {...props}
      fallback={<AccountManagementContainerAsyncLoading />}
    />
  );
};

export default AccountManagementContainerContainer;
