import * as actions from "constants/modals";

export default {
  showModal: (modalType, modalProps) => ({
    type: actions.SHOW_MODAL,
    modalType,
    modalProps,
  }),
  closeModal: (modalType) => ({
    type: actions.CLOSE_MODAL,
    modalType,
  }),
  updateModalProps: (modalType, modalProps = {}) => ({
    type: actions.UPDATE_MODAL_PROPS,
    modalType,
    modalProps,
  }),
  changeActivityModalStep: (modalKey, step, props = {}) => ({
    type: actions.CHANGE_ACTIVITY_MODAL_STEP,
    modalKey,
    step,
    props,
  }),
};
