import PropTypes from "prop-types";
import { memo } from "react";

import CategoryLink from "./Links/CategoryLinkAsync";
import CreatorLink from "./Links/CreatorLink";
import EpisodeLink from "./Links/EpisodeLink";
import IntegrationLink from "./Links/IntegrationLinkAsync";
import NetworkLink from "./Links/NetworkLink";
import PodcastLink from "./Links/PodcastLink";
import ReviewLink from "./Links/ReviewLinkAsync";
import TagLink from "./Links/TagLinkAsync";
import UserLink from "./Links/UserLink";
import UserListLink from "./Links/UserListLink";

const EntityLink = (props) => {
  const { entity_type, children } = props;

  switch (entity_type) {
    case "category":
      return <CategoryLink {...props} />;
    case "creator":
      return <CreatorLink {...props} />;
    case "episode":
      return <EpisodeLink {...props} />;
    case "integration":
      return <IntegrationLink {...props} />;
    case "network":
      return <NetworkLink {...props} />;
    case "podcast":
      return <PodcastLink {...props} />;
    case "review":
      return <ReviewLink {...props} />;
    case "tag":
    case "hashtag":
      return <TagLink {...props} />;
    case "user":
      return <UserLink {...props} />;
    case "userlist":
    case "list":
      return <UserListLink {...props} />;
    default:
      return children;
  }
};

EntityLink.propTypes = {
  entity_type: PropTypes.string,
  children: PropTypes.node,
};

EntityLink.defaultProps = {
  children: null,
};

export default memo(EntityLink);
