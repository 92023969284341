import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

const TagLink = Loadable(() => import("./TagLink"));

function LinkAsyncLoading(props) {
  return <LoadingOverlay {...props} span />;
}

const TagLinkContainer = (props) => {
  return <TagLink {...props} fallback={<LinkAsyncLoading />} />;
};

export default TagLinkContainer;
