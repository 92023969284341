import Loadable from "@loadable/component";
const EmailVerificationBanner = Loadable(() =>
  import("./EmailVerificationBanner")
);

function AboutAsyncLoading() {
  return null;
}

const EmailVerificationBannerContainer = (props) => {
  return (
    <EmailVerificationBanner {...props} fallback={<AboutAsyncLoading />} />
  );
};

export default EmailVerificationBannerContainer;
