import { format } from "date-fns/format";
import { parseISO } from "date-fns/parseISO";
import { subDays } from "date-fns/subDays";
import querystring from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";

import Col from "components/Common/Col";
import { Grid, Row } from "components/Common/FlexGrid";
import ChartsListContainer from "components/Lists/Charts/async";
import ListPageHelmet from "pages/Common/ListPageHelmetAsync";
import Error404 from "pages/Error404/Async";

import ChartSidebarContainer from "./Sidebar/async";
import ChartsDateSelect from "./Sidebar/ChartsDateSelect";
import UnlockChartsPast90DaysCTA from "./Sidebar/UnlockChartsPast90DaysCTA";

import { getBaseUrl } from "constants/base";
import {
  DEFAULT_PLATFORM,
  DEFAULT_DATE,
  DEFAULT_CATEGORY,
  DEFAULT_COUNTRY,
} from "constants/charts";
import {
  makeSelectSpecificAppleCountryMemoized,
  makeSelectSpecificPlatformMemoized,
  makeSelectSpecificCategoryMemoized,
  makeSelectSpecificSubCategoryMemoized,
  selectApplePodcasts,
  selectSpotifyPodcasts,
} from "selectors/charts";
import { capitalizeWords } from "utils/case";
import { capitalize } from "utils/misc";
import sendGAEvent from "utils/sendGAEvent";

import useChartFilters from "hooks/useChartFilters";
import useReduxState from "hooks/useReduxState";
import { useStyles } from "hooks/useStyles";
import useUserHasPro from "hooks/useUserHasPro";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ListPageStyles from "styles/ListPageStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  contentOuter: {
    ...ListPageStyles.contentOuter,
  },
  contentContainer: {
    ...ListPageStyles.contentContainer,
  },
  mainColumn: {
    flex: 2,
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    [ScreenSizes.lgAndAbove]: {
      width: "calc(100% - 250px)",
      paddingRight: "4%",
      paddingLeft: "2rem",
      marginLeft: "-2rem",
    },
    [ScreenSizes.xlAndAbove]: {
      width: "calc(100% - 300px)",
      paddingRight: "5%",
    },
  },
  mainColumnPadding: {
    paddingTop: "1.875rem",
  },
  columnContainer: {
    ...ListPageStyles.columnContainer,
    borderTop: `1px solid ${colours.outsideBorder}`,
    [ScreenSizes.mdAndBelow]: {
      flexDirection: "column",
    },
  },
  listPageHeader: {
    display: "flex",
    flexDirection: "column",
    gap: "0.25rem",
  },
  breadcrumbs: {
    ...gStyles.avalonBold,
    margin: 0,
    fontSize: "1.25rem",
    color: colours.silver,
    marginBottom: "2rem",
  },
  placeholder: {
    ...gStyles.skeletonPlaceholder,
    margin: 0,
    height: 24,
    width: "40%",
    marginBottom: "2rem",
  },
  title: {
    ...ListPageStyles.title,
  },
  flexContainer: {
    display: "flex",
    [ScreenSizes.xlAndBelow]: {
      flexWrap: "wrap",
    },
  },
};

export const hasSubOption = (category) => category?.toString()?.split("/")[1];

const findCategory = (categories, category) => {
  if (categories) {
    return categories?.some((option) => {
      let key = decodeURIComponent(category).toLowerCase();
      const subOption = hasSubOption(key);
      let catMain;

      if (subOption) {
        catMain = key?.split("/")[0];
      }
      let found =
        option.id.toLowerCase() === catMain || option.id.toLowerCase() === key;

      if (!found && subOption) {
        option?.subOptions?.some((subOption) => {
          found = subOption.id.toLowerCase() === subOption;
          return found;
        });
      }

      return found;
    });
  }

  return false;
};

export default function Charts(props) {
  const {
    platformId: initialPlatform,
    countryId: initialCountry,
    categoryId: initialCategory,
    subCategoryId: initialSubCategory,
  } = useParams();

  const formattedInitialCategory = `${initialCategory}${
    initialSubCategory ? "/" + initialSubCategory : ""
  }`;

  const { styles, css } = useStyles(baseStyles, props);

  const history = useHistory();
  const hasPro = useUserHasPro();

  const { search } = useLocation();
  const query = querystring.parse(search?.replace("?", ""));
  const queryDate = query?.date;

  const [chartFilters, setChartFilters] = useState({
    platform: initialPlatform
      ? decodeURIComponent(initialPlatform)
      : DEFAULT_PLATFORM,
    country: initialCountry
      ? decodeURIComponent(initialCountry)
      : DEFAULT_COUNTRY,
    category: formattedInitialCategory
      ? decodeURIComponent(formattedInitialCategory)
      : DEFAULT_CATEGORY,
  });

  const subCatIncluded = hasSubOption(chartFilters.category);
  const dateForProUsers = queryDate
    ? decodeURIComponent(queryDate)
    : DEFAULT_DATE;

  const [chartFiltersDate, setChartFiltersDate] = useState(() => {
    const parsedDate = new Date(dateForProUsers);

    // Ensure parsedDate is valid (not "Invalid Date")
    return hasPro && !isNaN(parsedDate) ? parsedDate : new Date(DEFAULT_DATE);
  });

  const dateFormatted = format(chartFiltersDate, "yyyy-MM-dd");

  const applePodcasts = useReduxState(
    (state) => selectApplePodcasts(state),
    []
  );

  const spotifyPodcasts = useReduxState(
    (state) => selectSpotifyPodcasts(state),
    []
  );

  let listSize = useMemo(() => {
    switch (chartFilters?.platform) {
      case "apple":
        return applePodcasts?.size;
      case "spotify":
        return spotifyPodcasts?.size;

      default:
        return 0;
    }
  }, [applePodcasts, chartFilters?.platform, spotifyPodcasts]);

  const selectSpecificAppleCountry = makeSelectSpecificAppleCountryMemoized();
  const selectSpecificCategory = makeSelectSpecificCategoryMemoized();
  const selectSpecificSubCategory = makeSelectSpecificSubCategoryMemoized();
  const selectSpecificPlatform = makeSelectSpecificPlatformMemoized();

  const {
    filtersLoading,
    platformOptions,
    appleCategoryOptions,
    appleCountryOptions,
    spotifyCategoryOptions,
    spotifyCountryOptions,
  } = useChartFilters();

  const countryLabel = useReduxState(
    (state) => {
      const selectedCountry = selectSpecificAppleCountry(
        state,
        chartFilters.country
      );
      return selectedCountry?.label;
    },
    [chartFilters.country]
  );

  const platform = useReduxState(
    (state) => {
      return selectSpecificPlatform(state, chartFilters?.platform);
    },
    [chartFilters.platform]
  );

  const platformLabel = platform?.label;

  const categoryLabel = useReduxState(
    (state) => {
      if (subCatIncluded) {
        const parentSelectedCat = chartFilters.category?.split("/")[0];
        const childSelectedCat = chartFilters.category?.split("/")[1];

        const selectedParentCategory = selectSpecificCategory(
          state,
          parentSelectedCat
        );
        const selectedChildCategory = selectSpecificSubCategory(
          state,
          parentSelectedCat,
          childSelectedCat
        );

        return `${selectedParentCategory?.label} / ${selectedChildCategory?.label}`;
      }

      const selectedCategory = selectSpecificCategory(
        state,
        chartFilters?.category
      );
      return selectedCategory?.label || DEFAULT_CATEGORY;
    },
    [chartFilters.category, subCatIncluded]
  );

  const updateRoute = useCallback(
    ({ country, platform, category, date }) => {
      let link = `/charts/${platform}/${country}/${category?.toLowerCase()}`;

      if (date !== DEFAULT_DATE) {
        link = link + `?${querystring.stringify({ date })}`;
      }

      history?.push(link);
    },
    [history]
  );

  useEffect(() => {
    if (hasPro && queryDate && dateFormatted !== queryDate) {
      setChartFilters((prevState) => ({
        ...prevState,
      }));

      setChartFiltersDate(parseISO(queryDate));
    }
  }, [dateFormatted, hasPro, queryDate]);

  const handleFilterUpdate = useCallback(
    (selection, section) => {
      sendGAEvent({
        action: "browseChartsFiltersUpdated",
        sectionUpdated: section,
        sectionValue: selection,
        page: "Browse Charts Page",
        context: "Browse Charts Page",
        currentPlatform: chartFilters.platform,
        currentCountry: chartFilters.country,
        currentCategory: chartFilters.category,
        currentDate: dateFormatted,
      });

      switch (section) {
        case "platform":
          if (chartFilters.platform !== selection) {
            setChartFilters((prevState) => ({
              ...prevState,
              platform: selection,
              country: DEFAULT_COUNTRY,
              category: DEFAULT_CATEGORY,
            }));
            updateRoute({
              country: DEFAULT_COUNTRY,
              platform: selection,
              category: DEFAULT_CATEGORY,
              date: dateFormatted,
            });
          }
          break;
        case "country":
          if (chartFilters.country !== selection) {
            setChartFilters((prevState) => ({
              ...prevState,
              country: selection,
            }));
            updateRoute({
              country: selection,
              platform: chartFilters.platform,
              category: chartFilters.category,
              date: dateFormatted,
            });
          }
          break;
        case "category":
          if (chartFilters.category !== selection) {
            setChartFilters((prevState) => ({
              ...prevState,
              category: selection,
            }));
            updateRoute({
              country: chartFilters.country,
              platform: chartFilters.platform,
              category: selection,
              date: dateFormatted,
            });
          }
          break;
        case "date":
          if (chartFiltersDate !== selection) {
            setChartFiltersDate(selection);

            const dateFormatted = format(selection, "yyyy-MM-dd");

            updateRoute({
              country: chartFilters.country,
              platform: chartFilters.platform,
              category: chartFilters.category,
              date: dateFormatted,
            });
          }

          break;

        default:
          break;
      }
    },
    [
      chartFilters.platform,
      chartFilters.country,
      chartFilters.category,
      dateFormatted,
      chartFiltersDate,
      updateRoute,
    ]
  );

  const handleMobileFilterUpdate = useCallback(
    ({ platform, country, category, date }) => {
      sendGAEvent({
        action: "browseChartsFiltersUpdated",
        page: "Browse Charts Page",
        context: "Browse Charts Page",
        platform,
        country,
        category,
        date,
      });

      setChartFilters((prevState) => ({
        ...prevState,
        platform,
        country,
        category,
      }));

      setChartFiltersDate(date);

      const dateFormatted = format(date, "yyyy-MM-dd");

      updateRoute({
        country,
        platform,
        category,
        date: dateFormatted,
      });
    },
    [updateRoute]
  );

  const { canonicalUrl, description, title } = useMemo(() => {
    const titleCategory =
      categoryLabel === "Top Podcasts" ? "Top" : `${categoryLabel}`;

    const descriptionCategory =
      categoryLabel === "Top Podcasts" ? "" : `${categoryLabel} `;

    const description = `Daily rankings of the ${listSize} top ${descriptionCategory?.toLowerCase()}podcasts in ${countryLabel} on ${capitalize(
      chartFilters.platform
    )}. View historical charts, popularity scores, and insights.`;

    const canonicalUrl = `${getBaseUrl()}/charts/${chartFilters.platform}/${
      chartFilters.country
    }/${chartFilters.category}`;

    let title = `${capitalize(chartFilters.platform)}'s ${
      subCatIncluded ? titleCategory?.split("/")[1] : titleCategory
    } Podcast Charts - ${countryLabel}`;

    if (platformLabel === undefined) {
      const fallbackCategory =
        chartFilters.category === "top podcasts"
          ? ""
          : capitalizeWords(chartFilters.category);
      title = `${
        subCatIncluded ? fallbackCategory?.split("/")[1] : fallbackCategory
      } Top Podcasts Chart in ${chartFilters.country?.toUpperCase()} on ${capitalizeWords(
        chartFilters.platform
      )}`;
    }

    return {
      description,
      canonicalUrl,
      title,
    };
  }, [
    categoryLabel,
    chartFilters,
    countryLabel,
    platformLabel,
    subCatIncluded,
    listSize,
  ]);

  const renderBreadcrumbs = useCallback(() => {
    if (filtersLoading) {
      return <div className={css(styles.placeholder)} />;
    }

    if (platformLabel) {
      return (
        <h3 className={css(styles.breadcrumbs)}>
          {`${platformLabel} / ${countryLabel} / ${categoryLabel}`}
        </h3>
      );
    }

    return null;
  }, [categoryLabel, countryLabel, filtersLoading, platformLabel]);

  const isValidPlatform = platformOptions.some(
    (option) => option.id === chartFilters.platform
  );

  const isValidCategory = useMemo(
    () =>
      (chartFilters.platform === "apple" &&
        findCategory(appleCategoryOptions, chartFilters.category)) ||
      (chartFilters.platform === "spotify" &&
        findCategory(spotifyCategoryOptions, chartFilters.category)),
    [
      appleCategoryOptions,
      chartFilters.category,
      chartFilters.platform,
      spotifyCategoryOptions,
    ]
  );

  const isValidCountry =
    (chartFilters.platform === "apple" &&
      appleCountryOptions.some(
        (option) => option.id === decodeURIComponent(chartFilters.country)
      )) ||
    (chartFilters.platform === "spotify" &&
      spotifyCountryOptions.some(
        (option) => option.id === decodeURIComponent(chartFilters.country)
      ));

  if (
    platform?.id &&
    (!isValidPlatform || !isValidCategory || !isValidCountry)
  ) {
    return <Error404 />;
  }

  const additionalEventTrackingProps = {
    chartPlatform: chartFilters.platform,
    chartCountry: chartFilters.country,
    chartCategory: chartFilters.category,
  };

  return (
    <div>
      <ListPageHelmet
        title={title}
        url={canonicalUrl}
        description={description}
      />
      <div className={css(styles.contentOuter)}>
        <Grid className={css(styles.contentContainer)}>
          <div className={css(styles.listPageHeader)}>
            <h1 className={css(styles.title)}>Podcast Charts & Rankings</h1>
            {renderBreadcrumbs()}
          </div>
          <Row>
            <Col xs={12} className={css(styles.columnContainer)}>
              <ChartSidebarContainer
                handleFilterUpdate={handleFilterUpdate}
                platform={chartFilters.platform}
                country={chartFilters.country}
                category={chartFilters.category}
                date={dateFormatted}
                handleMobileFilterUpdate={handleMobileFilterUpdate}
              />
              <div className={css(styles.mainColumn, styles.mainColumnPadding)}>
                <div className={css(styles.flexContainer)}>
                  <ChartsDateSelect
                    fromDate={subDays(new Date(), 90)}
                    toDate={new Date()}
                    onChange={(date) => {
                      handleFilterUpdate && handleFilterUpdate(date, "date");
                    }}
                    selectedDate={chartFiltersDate}
                  />
                  <UnlockChartsPast90DaysCTA
                    additionalEventTrackingProps={additionalEventTrackingProps}
                    page={"Charts Browse Page"}
                  />
                </div>

                <ChartsListContainer
                  platform={chartFilters.platform}
                  country={chartFilters.country}
                  category={subCatIncluded || chartFilters.category}
                  date={dateFormatted}
                />
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    </div>
  );
}
