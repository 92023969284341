import Loadable from "@loadable/component";
const ShareButton = Loadable(() => import("./ShareButton"));

function ButtonAsyncLoading(props) {
  return null;
}

const ShareButtonContainer = (props) => {
  return <ShareButton {...props} fallback={<ButtonAsyncLoading />} />;
};

export default ShareButtonContainer;
