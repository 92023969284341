import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const BrandViewContainer = Loadable(() =>
  import(/* webpackChunkName: "BrandViewContainer" */ "./BrandViewContainer")
);

function BrandViewContainerAsyncLoading(props) {
  return <LoadableLoading {...props} transparent page />;
}

const BrandViewContainerContainer = (props) => {
  return (
    <BrandViewContainer
      {...props}
      fallback={<BrandViewContainerAsyncLoading />}
    />
  );
};

export default BrandViewContainerContainer;
