import { css } from "aphrodite";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { Link } from "react-router-dom";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";

const baseStyles = {
  sideBarSection: {
    display: "flex",
    flexDirection: "column",
    color: "#000",
    marginBottom: "2.5rem",
  },
  sideBarSectionHeader: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    paddingBottom: "1.25rem",
    fontSize: "1rem",
  },
  sideBarSectionTitle: {
    ...gStyles.avalonBold,
    display: "block",
    fontSize: "1em",
    margin: 0,
    padding: 0,
    lineHeight: 1,
  },
  sideBarSectionTitleInFeed: {
    fontSize: "1.125rem",
  },
  sideBarSectionOuterComponent: {},
  sideBarSectionOuterComponentLink: {
    ":hover": {
      textDecoration: "underline",
    },
    ":focus": {
      textDecoration: "underline",
    },
  },
  sideBarSectionLink: {
    ...gStyles.avalonLight,
    display: "block",
    fontSize: "1em",
    margin: 0,
    padding: 0,
    lineHeight: 1,
  },
  sideBarSectionLinkInFeed: {
    fontSize: "1.125rem",
  },
  sidebarSectionList: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
};

const HomepageSidebarSection = (props) => {
  const { header, children, showAsList, TitleComponent, inFeed } = props;
  const { styles } = useStyles(baseStyles, props);

  const { OuterComponent, outerProps } = useMemo(() => {
    if (header && header.to) {
      return header.external
        ? {
            OuterComponent: "a",
            outerProps: {
              href: header.to,
              target: "_blank",
              rel: "noopener noreferrer",
            },
          }
        : {
            OuterComponent: Link,
            outerProps: {
              to: header.to,
            },
          };
    }

    return { OuterComponent: "span" };
  }, [header]);

  return (
    <div className={css(styles.sideBarSection)}>
      {header && (
        <div className={css(styles.sideBarSectionHeader)}>
          <TitleComponent
            className={css(
              styles.sideBarSectionTitle,
              inFeed && styles.sideBarSectionTitleInFeed
            )}
          >
            <OuterComponent
              className={css(
                styles.sideBarSectionOuterComponent,
                header && header.to && styles.sideBarSectionOuterComponentLink
              )}
              {...outerProps}
            >
              {header.title}
            </OuterComponent>
          </TitleComponent>
          {header.to && !header.hideViewAll && (
            <div
              className={css(
                styles.sideBarSectionLink,
                inFeed && styles.sideBarSectionLinkInFeed
              )}
            >
              <OuterComponent
                to={header.to}
                className={css(
                  styles.sideBarSectionOuterComponent,
                  styles.sideBarSectionOuterComponentLink
                )}
                title={`View All ${header.title}`}
              >
                {header.link || "View All"}
              </OuterComponent>
            </div>
          )}
          {header.inlineWithTitle && (
            <div
              className={css(
                styles.sideBarSectionLink,
                inFeed && styles.sideBarSectionLinkInFeed
              )}
            >
              <span
                className={css(
                  styles.sideBarSectionOuterComponent,
                  styles.sideBarSectionOuterComponentLink
                )}
              >
                {header.inlineWithTitle}
              </span>
            </div>
          )}
        </div>
      )}
      {showAsList ? (
        <div className={css(styles.sidebarSectionList)}>{children}</div>
      ) : (
        children
      )}
    </div>
  );
};

HomepageSidebarSection.propTypes = {
  header: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  children: PropTypes.node,
  showAsList: PropTypes.bool,
  TitleComponent: PropTypes.node,
  inFeed: PropTypes.bool,
};

HomepageSidebarSection.defaultProps = {
  children: null,
  showAsList: false,
  TitleComponent: "span",
  inFeed: false,
};

export default HomepageSidebarSection;
