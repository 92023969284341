import Cookies from "universal-cookie";

import configGet from "../../config";

const cookies = new Cookies();

export const getCookie = (name, defaultValue) => {
  const value = cookies.get(name);
  if (!value) {
    return defaultValue;
  }
  return value;
};

export const setCookie = (name, value, options) => {
  cookies.set(name, value, {
    path: "/",
    domain: configGet("cookie_domain"),
    ...options,
  });
};

export const removeCookie = (name, options) => {
  cookies.remove(name, {
    path: "/",
    domain: configGet("cookie_domain"),
    ...options,
  });
};
