import { selectUserHasPermission, selectUserPermission } from "selectors/auth";

import useReduxState from "hooks/useReduxState";

export default function useUserHasPermission(permission) {
  return useReduxState(
    (state) => selectUserHasPermission(state, permission),
    [permission]
  );
}

export function useUserPermission() {
  return useReduxState((state) => selectUserPermission(state), []);
}
