import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const EmbedApp = Loadable(() => import("./EmbedApp"));

function EmbedAppAsyncLoading(props) {
  return <LoadableLoading {...props} />;
}

const EmbedAppContainer = (props) => {
  return <EmbedApp {...props} fallback={<EmbedAppAsyncLoading />} />;
};

export default EmbedAppContainer;
