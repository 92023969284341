import mixpanel from "mixpanel-browser";

import { log } from "utils/dev";

const getName = (node) => {
  const name = node.nodeName;
  return node.nodeType === 1
    ? name.toLowerCase()
    : name.toUpperCase().replace(/^#/, "");
};

const getSelector = (node, maxLen) => {
  let sel = "";

  try {
    while (node && node.nodeType !== 9) {
      const el = node;
      const part = el.id
        ? "#" + el.id
        : getName(el) +
          (el.className && el.className.trim().length
            ? "." + el.className.trim().replace(/\s+/g, ".")
            : "");
      if (sel.length + part.length > (maxLen || 100) - 1) {
        return sel || part;
      }
      sel = sel ? part + ">" + sel : part;
      if (el.id) {
        break;
      }
      node = el.parentNode;
    }
  } catch (err) {
    // Do nothing...
  }
  return sel;
};

function sendPerformanceAnalyticsToMixPanel(name, eventParams) {
  log(`web-performance-${name}`, JSON.stringify(eventParams));

  let environment;

  try {
    environment =
      window.__CLIENT_REQUEST_CONTEXT__ &&
      JSON.parse(window.__CLIENT_REQUEST_CONTEXT__)?.environment;
  } catch (error) {
    environment = null;
  }

  if (environment !== "production") {
    return;
  }

  if (window.mixpanel) {
    mixpanel.track(`web-performance-${name}`, eventParams);
  }
}

function getCLSDebugTarget(largestEntry) {
  if (largestEntry && largestEntry.sources && largestEntry.sources.length) {
    const largestSource = largestEntry.sources.reduce((a, b) => {
      return a.node &&
        a.previousRect.width * a.previousRect.height >
          b.previousRect.width * b.previousRect.height
        ? a
        : b;
    });

    if (largestSource) {
      return largestSource;
    }
  }
}

const reportWebVitals = () => {
  if (typeof window !== "undefined" && PerformanceObserver) {
    new PerformanceObserver((list) => {
      const entries = list.getEntries().filter((entry) => entry.value >= 0.03);

      if (entries.length === 0) {
        return;
      }

      const largestEntry = entries.reduce((a, b) => {
        return a && a.value > b.value ? a : b;
      });

      const clsDebugTarget = getCLSDebugTarget(largestEntry);

      const { value } = largestEntry;

      let path;

      if (clsDebugTarget) {
        path = getSelector(clsDebugTarget.node);
      }

      if (value > 0.1) {
        sendPerformanceAnalyticsToMixPanel("CLS", {
          elementPath: path,
          width: window.innerWidth,
          height: window.innerHeight,
          urlPage: window !== undefined && window.location.href,
          value,
          currentRect: clsDebugTarget
            ? JSON.stringify(clsDebugTarget?.currentRect)
            : "",
          previousRect: clsDebugTarget
            ? JSON.stringify(clsDebugTarget?.previousRect)
            : "",
          userAgent: window !== undefined && window.navigator.userAgent,
        });
      }
    }).observe({
      type: "layout-shift",
      buffered: true,
      includeSoftNavigationObservations: true,
    });
  }
};

export default reportWebVitals;
