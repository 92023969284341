import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useMemo } from "react";

import BasicTooltip from "components/Common/Tooltip/BasicTooltip";

import getEpisodeType from "utils/entity/getEpisodeType";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";

const baseStyles = {
  // TODO: Share with AuthorityBadge and ClaimBadge?
  outer: {
    lineHeight: 1,
    whiteSpace: "nowrap",
    display: "inline-flex",
  },
  tooltip: {},
  noTooltip: {},
  episodeType: {
    color: "#fff",
    borderRadius: "5em",
    display: "inline-flex",
    alignItems: "center",

    ...gStyles.avalonBold,
    background: "#8B91AC",
    fontSize: "0.75rem",
    padding: "0.2em 0.7em",
    lineHeight: 1,
  },
  episodeTypeIcon: {
    display: "flex",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  episodeTypeText: {
    ...gStyles.avalonBold,
    display: "inline-block",
    color: "inherit",
  },
};

const tooltipStyles = {
  tooltip: {
    zIndex: 9999,
  },
};

const EpisodeTypeBadge = (props) => {
  const {
    episode,
    showLabel,
    label: passedLabel,
    tooltipText,
    showTooltip,
  } = props;

  const { styles } = useStyles([baseStyles], props);

  const episodeTypeIcon = useMemo(() => null, []);

  const episodeType = getEpisodeType(episode);

  const label = passedLabel || episodeType;

  const badge = (
    <span className={css(styles.episodeType)}>
      {episodeTypeIcon && (
        <span className={css(styles.episodeTypeIcon)}>
          <FontAwesomeIcon icon={episodeTypeIcon} />
        </span>
      )}
      {showLabel && label && (
        <span className={css(styles.episodeTypeText)}>{label}</span>
      )}
    </span>
  );

  return showTooltip && tooltipText ? (
    <BasicTooltip renderTooltip={() => tooltipText} styles={tooltipStyles}>
      {(tooltipProps) => (
        <span {...tooltipProps} className={css(styles.outer, styles.tooltip)}>
          {badge}
        </span>
      )}
    </BasicTooltip>
  ) : (
    <span className={css(styles.outer, styles.noTooltip)}>{badge}</span>
  );
};

EpisodeTypeBadge.propTypes = {
  passedLabel: PropTypes.string,
  showLabel: PropTypes.bool,
  showTooltip: PropTypes.bool,
  tooltipText: PropTypes.string,
  padded: PropTypes.bool,
  size: PropTypes.number,
  label: PropTypes.node,
  episode: PropTypes.instanceOf(Map),
};

EpisodeTypeBadge.defaultProps = {
  passedLabel: null,
  showLabel: true,
  showTooltip: false,
  tooltipText: null,
  padded: false,
  size: undefined,
  label: null,
  episode: null,
};

export default memo(EpisodeTypeBadge);
