export function size(...breakpointParams) {
  const [passedBreakpoints, identifier = ""] = breakpointParams;
  let breakpoints = passedBreakpoints;

  if (typeof breakpoints !== "object" && arguments.length > 0) {
    breakpoints = {
      ...(breakpointParams[0] && { tiny: breakpointParams[0] }),
      ...(breakpointParams[1] && { small: breakpointParams[1] }),
      ...(breakpointParams[2] && { medium: breakpointParams[2] }),
      ...(breakpointParams[3] && { large: breakpointParams[3] }),
      ...(breakpointParams[4] && { xlarge: breakpointParams[4] }),
      ...(breakpointParams[5] && { xxlarge: breakpointParams[5] }),
      default: ["tiny", "small", "medium", "large", "xlarge", "xxlarge"][
        arguments.length - 1
      ], // Choose the highest valid breakpoint as default
    };
  }

  return (props) => {
    const size = sizeFromBreakpoints(props.width, breakpoints, identifier);
    return {
      sizeString: size,
      dimensions: props,
    };
  };
}

export function sizeFromBreakpoints(width, breakpoints) {
  if (width === null || width === 0) {
    // TODO: get from user agent if possible
    return breakpoints.default || "xxlarge";
  }

  if (breakpoints.tiny && width < breakpoints.tiny) {
    return "tiny";
  }
  if (breakpoints.small && width < breakpoints.small) {
    return "small";
  }
  if (breakpoints.medium && width < breakpoints.medium) {
    return "medium";
  }
  if (breakpoints.large && width < breakpoints.large) {
    return "large";
  }
  if (breakpoints.xlarge && width < breakpoints.xlarge) {
    return "xlarge";
  }
  return breakpoints.default || "xxlarge";
}
