import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo, Fragment, useState } from "react";
import { Link } from "react-router-dom";

import dropdownTokens from "../../../styles/tokens/tokens-dropdown";
import SubMenu from "./SubMenu";

import generateTransition from "utils/generateTransition";

import { useStyles } from "hooks/useStyles";
import { useUserPermission } from "hooks/useUserHasPermission";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  menuContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    height: "100%",
  },
  menu: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  menuItem: {
    backgroundColor: dropdownTokens.color.background.default,
    color: dropdownTokens.color.text.default,
    display: "flex",
    flex: 1,
    borderTop: "#dfe1e6 1px solid",
    overflow: "hidden",
    textAlign: "left",
    transition: generateTransition({ target: "visual", speed: "250ms" }),
  },
  firstMenuItem: {
    borderTop: "none",
  },
  menuItemHover: {
    backgroundColor: "#fdf2fb",
  },
  item: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    padding: "1em 1.25em 0.8125em",
    cursor: "pointer",
    outline: "none",
    maxWidth: "100%",
    position: "relative",
    fontSize: "0.9375rem",

    [ScreenSizes.mdAndAbove]: {
      fontSize: "0.8125rem",
    },
    [ScreenSizes.lgAndAbove]: {
      fontSize: "0.75rem",
    },
  },
  itemWithAnchor: {
    padding: 0,
  },
  anchor: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    padding: "1em 1.25em 0.8125em",
    cursor: "pointer",
    outline: "none",
    maxWidth: "100%",
    fontSize: "0.9375rem",
    [ScreenSizes.mdAndAbove]: {
      fontSize: "0.8125rem",
    },
    [ScreenSizes.lgAndAbove]: {
      fontSize: "0.75rem",
    },
  },
  label: {
    ...gStyles.fontSemiBold,
    fontSize: "1em",
    display: "block",
  },
  subtitle: {
    ...gStyles.fontLight,
    fontSize: "0.7rem",
    display: "block",
  },
  icon: {
    fontSize: "1rem",
    marginRight: ".5rem",

    [ScreenSizes.mdAndAbove]: {
      fontSize: ".9rem",
      marginRight: ".4rem",
    },
    [ScreenSizes.lgAndAbove]: {
      fontSize: ".813rem",
      marginRight: ".3rem",
    },
  },
  extra: {
    position: "absolute",
    top: ".6rem",
    right: ".6rem",
    transition: generateTransition({ target: "visual", speed: "250ms" }),
  },
  extraHover: {
    opacity: 0,
  },
  category: {
    ...gStyles.avalonSemiBold,
    fontSize: ".75rem",
    color: colours.grey,
    backgroundColor: colours.white,
    padding: "1.1rem 1rem .5rem",
    cursor: "initial",
    zIndex: 1,
    position: "relative",
    bottom: -1,
  },
  subMenu: {
    position: "absolute",
    right: "100%",
    background: "white",
    height: "auto",
    borderRadius: "0.625rem",
    boxShadow: "0rem 0.375rem 0.688rem 0rem rgba(0, 0, 0, 0.15)",
  },
  subMenuItems: {
    ...gStyles.fontSemiBold,
    padding: "0.5rem 2rem",
    fontSize: "0.75rem",
  },
  subMenuHeader: {
    ...gStyles.avalonBold,
    fontSize: "0.8125rem",
    padding: "0.4rem 2rem",
  },
  break: {
    width: "100%",
    alignItems: "center",
    alignSelf: "center",
    margin: "0.25rem",
    height: "0.031rem",
    background: colours.lightishGreyBorder,
  },
  bolder: {
    fontWeight: "bolder",
  },
};

const Menu = (props) => {
  const userPermissions = useUserPermission();
  const [openSubMenu, setOpenSubMenu] = useState(null);

  const {
    menuItems,
    renderHeader,
    renderItem,
    downshiftProps,
    ariaLabel,
    asLink,
    onOpenInNewTab,
    renderMenuItem: passedRenderMenuItem,
  } = props;
  const { styles } = useStyles(baseStyles, props);
  const handleAnchorClick = (item) => (e) => {
    if (e && (e.ctrlKey || e.metaKey)) {
      e.stopPropagation();
      onOpenInNewTab && onOpenInNewTab(item, e);
    }
  };
  const preventDefault = (e) => {
    e.preventDefault();
  };

  const renderDefaultMenuItem = (menuItemProps, index) => {
    const {
      icon,
      extra,
      label,
      subtitle,
      iconStyles,
      url,
      asLink: itemAsLink,
      target,
      bolder,
    } = menuItemProps;
    const content = (
      <Fragment>
        {icon && (
          <span className={css(styles.icon)} style={iconStyles}>
            <FontAwesomeIcon icon={icon} fixedWidth />
          </span>
        )}
        <span>
          <span className={css(styles.label, bolder && styles.bolder)}>
            {label}
          </span>
          {subtitle && <span className={css(styles.subtitle)}>{subtitle}</span>}
        </span>
        {extra && (
          <span
            className={css(
              styles.extra,
              downshiftProps.highlightedIndex === index && styles.extraHover
            )}
          >
            {extra}
          </span>
        )}
      </Fragment>
    );

    if ((asLink || itemAsLink) && url) {
      const openInNewTab = target === "_blank";
      const ComponentType = openInNewTab ? "a" : Link;
      const linkProps = openInNewTab ? { href: url, target } : { to: url };

      return (
        <ComponentType
          className={css(styles.anchor)}
          onClick={handleAnchorClick(menuItemProps)}
          onKeyUp={preventDefault}
          onKeyDown={preventDefault}
          {...linkProps}
        >
          {content}
        </ComponentType>
      );
    }

    return content;
  };

  const renderMenuItem = (item, index, submenu = false) => {
    const menuItemProps = {
      role: "button",
      tabIndex: 0,
      className: css(
        styles.item,
        (asLink || item.asLink) && item.url && styles.itemWithAnchor
      ),
      "data-id": "menu-item",
      ...(!submenu &&
        downshiftProps.getItemProps({ item, onClick: item.onClick })),
    };
    if (passedRenderMenuItem) {
      return passedRenderMenuItem(menuItemProps, item, index);
    }

    return (
      <div {...menuItemProps}>
        {renderItem
          ? renderItem(item, index)
          : renderDefaultMenuItem(item, index)}
      </div>
    );
  };

  return (
    <div className={css(styles.menuContainer)} data-id="menu-container">
      {renderHeader && renderHeader(downshiftProps)}
      <ul
        className={css(styles.menu)}
        {...downshiftProps.getMenuProps({
          "aria-labelledby": undefined,
          "aria-label": ariaLabel,
        })}
      >
        {menuItems.map((menuItem, index) => {
          if (
            menuItem.checkPermission &&
            !menuItem.checkPermission(userPermissions)
          ) {
            return null;
          }

          if (menuItem.subMenu) {
            return (
              <Fragment key={menuItem.id || index}>
                <li
                  className={css(
                    styles.menuItem,
                    index === 0 && styles.firstMenuItem,
                    downshiftProps.highlightedIndex === index &&
                      styles.menuItemHover
                  )}
                  key={menuItem.dataId || index}
                  data-id={menuItem.dataId || `menu_item_${index}`}
                  onMouseEnter={() => {
                    setOpenSubMenu(index);
                    menuItem.subMenu.onHoverOpenSubmenu(menuItem.id);
                  }}
                  onMouseLeave={() => {
                    setOpenSubMenu(null);
                  }}
                >
                  {renderMenuItem(menuItem, index)}
                  {openSubMenu === index && (
                    <SubMenu
                      {...props}
                      subMenu={menuItem.subMenu}
                      parentIndex={index}
                      renderMenuItem={renderMenuItem}
                    />
                  )}
                </li>
                {menuItem.lineBreak && (
                  <div className={css(styles.break)}></div>
                )}
              </Fragment>
            );
          }

          const listItem = (
            <Fragment key={menuItem.id || index}>
              <li
                className={css(
                  styles.menuItem,
                  index === 0 && styles.firstMenuItem,
                  downshiftProps.highlightedIndex === index &&
                    styles.menuItemHover
                )}
                key={menuItem.dataId || index}
                data-id={menuItem.dataId || `menu_item_${index}`}
              >
                {renderMenuItem(menuItem, index)}
              </li>
              {menuItem.lineBreak && <div className={css(styles.break)}></div>}
            </Fragment>
          );

          if (menuItem.categoryItem) {
            return (
              <Fragment key={menuItem.id || index}>
                <div
                  className={css(styles.category)}
                  key={menuItem.categoryItem}
                >
                  {menuItem.categoryItem}
                </div>
                {listItem}
              </Fragment>
            );
          }

          return listItem;
        })}
      </ul>
    </div>
  );
};

Menu.propTypes = {
  downshiftProps: PropTypes.object.isRequired,
  renderHeader: PropTypes.func,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.object,
      label: PropTypes.node,
      subtitle: PropTypes.string,
      checkPermission: PropTypes.func,
    })
  ).isRequired,
  renderItem: PropTypes.func,
  ariaLabel: PropTypes.string,
  asLink: PropTypes.bool,
  onOpenInNewTab: PropTypes.func,
  renderMenuItem: PropTypes.func,
};

Menu.defaultProps = {
  renderHeader: null,
  renderItem: null,
  ariaLabel: undefined,
  asLink: false,
  onOpenInNewTab: null,
  renderMenuItem: null,
};

export default memo(Menu);
