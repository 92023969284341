import { css } from "aphrodite";
import PropTypes from "prop-types";
import React from "react";

import { headerButtonStyles } from "./headerButtonStyles";

import useHover from "hooks/useHover";
import { useStyles } from "hooks/useStyles";

const LoginButton = (props) => {
  const { onClick, expand, dataId } = props;
  const { styles } = useStyles(headerButtonStyles, props);
  const [hoverRef, isHovered] = useHover();

  return (
    <button
      ref={hoverRef}
      type="button"
      className={css(
        styles.button,
        expand && styles.expand,
        isHovered && styles.hover
      )}
      data-id={dataId}
      onClick={onClick}
    >
      Log In
    </button>
  );
};

LoginButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  dataId: PropTypes.string,
  expand: PropTypes.bool,
  hover: PropTypes.bool,
};
LoginButton.defaultProps = {
  dataId: null,
  expand: false,
  hover: false,
};

export default React.memo(LoginButton);
