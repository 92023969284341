import { Map } from "immutable";
import PropTypes from "prop-types";

import BaseEntityLink from "./BaseEntityLink";

import getNetworkUrl from "utils/entity/getNetworkUrl";

const NetworkLink = (props) => {
  const { entity, externalLink } = props;

  return (
    <BaseEntityLink
      {...props}
      to={getNetworkUrl(entity)}
      name={entity && entity.get("title")}
      externalLink={externalLink}
    />
  );
};

NetworkLink.propTypes = {
  entity: PropTypes.instanceOf(Map),
  entity_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

NetworkLink.defaultProps = {
  entity: null,
  entity_id: null,
};

export default NetworkLink;
