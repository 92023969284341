import { css } from "aphrodite";
import PropTypes from "prop-types";
import deepmerge from "ramda/src/mergeDeepRight";
import { useCallback, useEffect, useState } from "react";

import Col from "components/Common/Col";

import NavMenuItem from "../NavMenu/NavMenuItem";
import useNavLinks from "../NavMenu/useNavLinks";
import HeaderMenuToggle from "./HeaderMenuToggle";
import HeaderSearch from "./HeaderSearch";
import HeaderUser from "./HeaderUserAsync";

import { userHasPermission } from "utils/entity/user";
import eventIsFieldTrigger from "utils/misc/eventIsFieldTrigger";

import useCookie from "hooks/useCookie";
import useLoggedInUser, { useLoggedIn } from "hooks/useLoggedInUser";
import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import HeaderStyles from "styles/HeaderStyles";
import ScreenSizes, { createBreakpoint } from "styles/ScreenSizes";

const baseStyles = {
  ...HeaderStyles,
  container: {
    ...gStyles.gradientBackground,
    height: 68,
    lineHeight: 1,
    zIndex: 1000,
    position: "relative",
    transition: "ease-out 0.2s",

    [ScreenSizes.lgAndAbove]: {
      height: 74,
    },
  },
  headerLogoContainer: {
    display: "flex",
    flexDirection: "row",
    marginRight: 0,
    [ScreenSizes.mdAndBelow]: {
      justifyContent: "center",
      position: "absolute",
      width: "3rem",
      height: "100%",
      left: "50%",
      marginLeft: "-1.5rem",
    },
  },
  headerLogoLinkContainer: {
    zIndex: 5,
    lineHeight: 1,
    display: "flex",
    [ScreenSizes.xlAndAbove]: {
      height: 38,
    },
  },
  transparentContainer: {
    width: "100%",
    background: "none",
    boxShadow: "none",
    transition: "ease-out 0.2s",
    [createBreakpoint({ max: 1400 })]: {
      borderBottom: `1px solid rgba(255, 255, 255, 0.2)`,
    },
  },
  mainHeaderContainer: {
    ...HeaderStyles.headerContent,

    display: "grid",
    gridTemplateColumns: "max-content 1fr max-content",
    gridGap: "0.625rem",
    padding: "0.875rem 1rem",
    alignItems: "center",
    height: "100%",
    color: colours.white,

    [ScreenSizes.mdAndAbove]: {
      padding: "0.875rem 33px",
    },

    [createBreakpoint({ min: 1280 })]: {
      padding: "0.875rem 1rem",
    },

    [createBreakpoint({ min: 1400 })]: {
      gridTemplateColumns: "max-content 1fr max-content max-content",
    },
  },
  mainMenuContainer: {
    display: "grid",
    width: "100%",
    padding: "0 1rem",
    paddingRight: 0,
  },
  secondaryMenuContainer: {
    maxWidth: "100%",
    display: "grid",
    placeItems: "center",
    [createBreakpoint({ min: 1400 })]: {
      width: "100%",
      gridTemplateColumns: "max-content 1fr max-content",
    },
  },
  section: {
    margin: "0 0.5rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    [ScreenSizes.smAndAbove]: {
      margin: "0 1rem",
    },
    [ScreenSizes.mdAndAbove]: {
      margin: "0 2rem",
    },
    [ScreenSizes.lgAndAbove]: {
      margin: 0,
      justifyContent: "flex-start",
      display: "none",
    },
    [createBreakpoint({ min: 1400 })]: {
      display: "flex",
    },
  },
  sectionLeft: {
    [ScreenSizes.mdAndBelow]: {
      display: "none",
    },
  },
  sectionRight: {
    [ScreenSizes.mdAndBelow]: {
      display: "none",
    },
  },
  headerPlaceholder: {
    width: "100%",
  },
};

const headerSearchOverride = {
  headerSearch: {
    width: "100%",
  },
};

const overrideStyles = {
  navItem: {
    ":hover": {
      textShadow: "none",
    },
  },
  navItemOuter: {
    ":nth-child(1)": {
      marginRight: "-0.25rem",
      marginLeft: "0.438rem",
    },
    ":last-child": {
      marginRight: "-0.4rem",
    },
  },
};

const leftStyles = {
  navItemOuter: {
    ":nth-child(2)": {
      marginRight: "0.75rem",
    },
  },
  navItem: {
    fontSize: "0.875rem",
    [ScreenSizes.mdAndAbove]: {
      fontSize: "1rem",
    },
    ":hover": {
      textShadow: "none",
    },
    ":last-child": {
      marginRight: "2.01rem",
    },
  },
  navItemActive: {
    fontSize: "0.875rem",
    [ScreenSizes.mdAndAbove]: {
      fontSize: "1rem",
    },
  },
};

const HeaderStandard = (props) => {
  const {
    onMenuToggleClick,
    showUserContainer,
    logo,
    isHomepage,
    searchAutocompleteStyles,
    navItemStyles: passedNavItemStyles = {},
    topSearchContainerStyles,
  } = props;

  const { isWindowSizeOrMore } = useWindowSize();
  const isLargeOrMore = isWindowSizeOrMore("large");

  const { styles } = useStyles(baseStyles, props);

  const isLoggedIn = useLoggedIn();

  const [showSearch, setShowSearch] = useState(isLargeOrMore);
  const [searchToggled, setSearchToggled] = useState(false);
  const { leftItems, rightItems } = useNavLinks();
  const user = useLoggedInUser();

  useEffect(() => {
    if (isLargeOrMore || !searchToggled) {
      setShowSearch(isLargeOrMore);
    }
  }, [isLargeOrMore, searchToggled]);

  // TODO: Give focus to search box
  const onSearchToggleClick = useCallback(
    () => setShowSearch((prevShowSearch) => !prevShowSearch),
    []
  );

  const onSearchCloseClick = useCallback(() => {
    if (!isLargeOrMore) {
      setShowSearch(false);
    }
  }, [isLargeOrMore]);

  const handleSearchChange = useCallback(() => setSearchToggled(true), []);
  const handleCancelSearch = useCallback(() => setSearchToggled(false), []);

  const handleSearchToggleKeyDown = useCallback(
    (e) => eventIsFieldTrigger(e) && onSearchToggleClick(e),
    [onSearchToggleClick]
  );

  const hasCookie = useCookie();

  const renderSearch = () => {
    if (!isLoggedIn && !hasCookie && isHomepage) {
      return <div className={css(styles.headerPlaceholder)} />;
    }

    return (
      <HeaderSearch
        onCancelSearch={handleCancelSearch}
        onSearchChange={handleSearchChange}
        onSearchCloseClick={onSearchCloseClick}
        setShowSearch={setShowSearch}
        showSearch={showSearch}
        styles={headerSearchOverride}
        searchAutocompleteStyles={searchAutocompleteStyles}
        topSearchContainerStyles={topSearchContainerStyles}
      />
    );
  };

  const renderNavItems = (items, side) =>
    items.reduce((contents, item, index) => {
      if (!item.minScreenSize || isWindowSizeOrMore(item.minScreenSize)) {
        const navItemStyles = side === "left" ? leftStyles : overrideStyles;

        contents.push(
          <NavMenuItem
            key={item.id || index}
            item={item}
            styles={deepmerge(navItemStyles, passedNavItemStyles)}
          />
        );
      }

      return contents;
    }, []);

  const checkItemPermission = (item) =>
    (!item.permission || userHasPermission(user, item.permission)) &&
    (!item.noPermission || !userHasPermission(user, item.noPermission));

  return (
    <div
      className={css(styles.container)}
      key={`HeaderStandard-${user?.get("id")}`}
    >
      <div className={css(styles.headerContentContainer)}>
        <div
          className={css(
            styles.mainHeaderContainer,
            showSearch && styles.headerContentSearchVisible
          )}
        >
          <HeaderMenuToggle
            onMenuToggleClick={onMenuToggleClick}
            onSearchToggleClick={onSearchToggleClick}
            onSearchToggleKeyDown={handleSearchToggleKeyDown}
          />
          <Col className={css(styles.headerLogoContainer)}>
            <div className={css(styles.headerLogoLinkContainer)}>{logo}</div>
          </Col>
          <div className={css(styles.mainMenuContainer)}>
            <div className={css(styles.secondaryMenuContainer)}>
              <div className={css(styles.section, styles.sectionLeft)}>
                {renderNavItems(leftItems.filter(checkItemPermission), "left")}
              </div>
              {showSearch && renderSearch()}
              <div className={css(styles.section, styles.sectionRight)}>
                {renderNavItems(
                  rightItems.filter(checkItemPermission),
                  "right"
                )}
              </div>
            </div>
          </div>
          {showUserContainer && <HeaderUser />}
        </div>
      </div>
    </div>
  );
};

HeaderStandard.propTypes = {
  onMenuToggleClick: PropTypes.func,
  showUserContainer: PropTypes.bool,
  isHomepage: PropTypes.bool,
};

HeaderStandard.defaultProps = {
  onMenuToggleClick: null,
  showUserContainer: false,
};

export default HeaderStandard;
