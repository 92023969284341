import Loadable from "@loadable/component";

const SearchResults = Loadable(() => import("./SearchResults"));

function SearchResultsAsyncLoading(props) {
  return null;
}

const SearchResultsContainer = (props) => {
  return <SearchResults {...props} fallback={<SearchResultsAsyncLoading />} />;
};

export default SearchResultsContainer;
