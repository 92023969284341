import { memo } from "react";

import HeaderNavWrapper from "components/Layout/Header/HeaderNavWrapper";

import TopLevelRouteStructure from "./TopLevelRouteStructure";

import { useStyles } from "hooks/useStyles";

const baseStyles = {
  contentPlaceholder: {
    minHeight: "1500px",
    background: "#fff",
  },
};

function TopLevelRouteSkeleton(props) {
  const { styles, css } = useStyles(baseStyles, props);

  return (
    <TopLevelRouteStructure
      header={
        <HeaderNavWrapper
          withHeader={props.withHeader}
          withNavMenu
          isHomepage
        />
      }
    >
      {props.children || (
        <div
          data-testid="contentPlaceholder"
          className={css(styles.contentPlaceholder)}
        />
      )}
    </TopLevelRouteStructure>
  );
}

export default memo(TopLevelRouteSkeleton);
