import Loadable from "@loadable/component";
import { useParams } from "react-router-dom";

import LoadingOverlay from "components/Common/LoadingOverlay";

import getListEpisodesHeight from "./Episodes/getListEpisodesHeight";

import usePodcastData from "hooks/usePodcastData";

import ScreenSizes from "styles/ScreenSizes";

const PodcastEpisodes = Loadable(() =>
  import(
    /* webpackChunkName: "PodcastViewContainer" */ "./PodcastViewContainer"
  )
);

const HEIGHT_REST_PAGE = 370;

function PodcastEpisodesAsyncLoading({ podcast }) {
  const listEpisodesHeight = getListEpisodesHeight(podcast);

  const loadingStyles = {
    height: {
      height: listEpisodesHeight + HEIGHT_REST_PAGE,
      maxHeight: listEpisodesHeight + HEIGHT_REST_PAGE,
      minHeight: 0,

      [ScreenSizes.lgAndAbove]: {
        height: "159rem",
        maxHeight: "159rem",
      },
    },
  };

  return <LoadingOverlay height={"293rem"} styles={loadingStyles} />;
}

const PodcastEpisodesAsyncContainer = (props) => {
  const params = useParams();

  let podcastID;

  if (params && params.id) {
    const idMatch = params.id.match(/[0-9]+\//) || params.id.match(/\d+$/);

    podcastID = idMatch && idMatch[0].replace("/", "");
  }

  const podcast = usePodcastData(podcastID);

  return (
    <PodcastEpisodes
      {...props}
      fallback={<PodcastEpisodesAsyncLoading podcast={podcast} />}
    />
  );
};

export default PodcastEpisodesAsyncContainer;
