const fonts = {
  bodyFontFamily: "Montserrat, Montserrat-fallback-700, arial, sans-serif",
  georgiaFontFamily: "Georgia, Times, Times New Roman, serif",
  codeFontFamily: "Source Code Pro, monospace, sans-serif",
  avalonFontFamily:
    "Avalon, Avalon-fallback, Montserrat, Display Sans, arial, sans-serif",
};

const styles = {
  ...fonts,
  codeFont: {
    fontFamily: fonts.codeFontFamily,
    fontWeight: 400,
  },
  positionReset: {
    padding: 0,
    margin: 0,
    position: "static",
  },
  textReset: {
    font: "inherit",
    fontWeight: "inherit",
    fontSize: "1em",
    lineHeight: "inherit",
  },
  /* Montserrat */
  fontUltraLight: {
    fontFamily: fonts.bodyFontFamily,
    fontWeight: 100,
    fontStyle: "normal",
  },
  fontExtraLight: {
    fontFamily: fonts.bodyFontFamily,
    fontWeight: 200,
    fontStyle: "normal",
  },
  fontLight: {
    fontFamily: fonts.bodyFontFamily,
    fontWeight: 300,
    fontStyle: "normal",
  },
  fontLightItalic: {
    fontFamily: fonts.bodyFontFamily,
    fontWeight: 300,
    fontStyle: "italic",
  },
  fontRegular: {
    fontFamily: "Montserrat, Montserrat-fallback-400, arial, sans-serif",
    fontWeight: 400,
    fontStyle: "normal",
  },
  fontRegularItalic: {
    fontFamily: "Montserrat, Montserrat-fallback-400, arial, sans-serif",
    fontWeight: 400,
    fontStyle: "italic",
  },
  fontMedium: {
    fontFamily: fonts.bodyFontFamily,
    fontWeight: 500,
    fontStyle: "normal",
  },
  fontMediumItalic: {
    fontFamily: fonts.bodyFontFamily,
    fontWeight: 500,
    fontStyle: "italic",
  },
  fontSemiBold: {
    fontFamily: fonts.bodyFontFamily,
    fontWeight: 600,
    fontStyle: "normal",
  },
  fontSemiBoldItalic: {
    fontFamily: fonts.bodyFontFamily,
    fontWeight: 600,
    fontStyle: "italic",
  },
  fontBold: {
    fontFamily: fonts.bodyFontFamily,
    fontWeight: 700,
    fontStyle: "normal",
  },
  fontBoldItalic: {
    fontFamily: fonts.bodyFontFamily,
    fontWeight: 700,
    fontStyle: "italic",
  },

  /* Georgia */
  fontLightGeorgiaItalic: {
    fontFamily: fonts.georgiaFontFamily,
    fontWeight: 300,
    fontStyle: "italic",
  },
  fontRegularGeorgiaItalic: {
    fontFamily: fonts.georgiaFontFamily,
    fontWeight: 400,
    fontStyle: "italic",
  },

  /* Avalon */
  avalonExtraLight: {
    fontFamily: fonts.avalonFontFamily,
    fontWeight: 200,
    fontStyle: "normal",
  },
  avalonLight: {
    fontFamily: fonts.avalonFontFamily,
    fontWeight: 300,
    fontStyle: "normal",
  },
  avalonLightItalic: {
    fontFamily: fonts.avalonFontFamily,
    fontWeight: 300,
    fontStyle: "italic",
  },
  avalonRegular: {
    fontFamily: fonts.avalonFontFamily,
    fontWeight: 400,
    fontStyle: "normal",
  },
  avalonMedium: {
    fontFamily: fonts.avalonFontFamily,
    fontWeight: 500,
    fontStyle: "normal",
  },
  avalonMediumItalic: {
    fontFamily: fonts.avalonFontFamily,
    fontWeight: 500,
    fontStyle: "italic",
  },
  avalonSemiBold: {
    fontFamily: fonts.avalonFontFamily,
    fontWeight: 600,
    fontStyle: "normal",
  },
  avalonBold: {
    fontFamily: fonts.avalonFontFamily,
    fontWeight: 700,
    fontStyle: "normal",
  },
  avalonBoldOblique: {
    fontFamily: fonts.avalonFontFamily,
    fontWeight: 700,
    fontStyle: "oblique",
  },
  avalonBoldItalic: {
    fontFamily: fonts.avalonFontFamily,
    fontWeight: 700,
    fontStyle: "italic",
  },
};

export default styles;
