import { isImmutable } from "immutable";
import { useCallback } from "react";
import { useMappedState } from "redux-react-hook";
import { createSelectorCreator, defaultMemoize } from "reselect";

import isEqual from "utils/isEqual";

export const checkEquality = (value, lastValue) => {
  if (isImmutable(value)) {
    return value === lastValue;
  }

  return isEqual(value, lastValue);
};

export default (getState, updateProps = []) =>
  useMappedState(
    useCallback(
      getState,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [...updateProps]
    ),
    checkEquality
  );

export const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  checkEquality
);
