import { List } from "immutable";
import PropTypes from "prop-types";
import { useCallback } from "react";

import InfiniteListSensorItem from "./InfiniteListSensorItem";
import ListCarousel from "./ListCarousel";

const InfiniteList = (props) => {
  const {
    items,
    renderItem,
    onLoadMore,
    loadItemsSize,
    loadAtPercentage,
    loadOnCountFromEnd,
    inlineItems,
    getHeightManagementId,
    styles,
  } = props;

  const renderListItem = useCallback(
    (id, index, isCarousel) => (
      <InfiniteListSensorItem
        key={`infinite-list-item-${id || index}`}
        id={id}
        index={index}
        isCarousel={isCarousel}
        renderItem={renderItem}
        onLoadMore={onLoadMore}
        items={items}
        loadItemsSize={loadItemsSize}
        loadAtPercentage={loadAtPercentage}
        loadOnCountFromEnd={loadOnCountFromEnd}
        inlineItems={inlineItems}
        getHeightManagementId={getHeightManagementId}
        styles={styles}
      />
    ),
    [
      renderItem,
      onLoadMore,
      items,
      loadItemsSize,
      loadAtPercentage,
      loadOnCountFromEnd,
      inlineItems,
      getHeightManagementId,
      styles,
    ]
  );

  return <ListCarousel {...props} items={items} renderItem={renderListItem} />;
};

InfiniteList.propTypes = {
  items: PropTypes.oneOfType([PropTypes.array, PropTypes.instanceOf(List)]),
  renderItem: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func,
  loadItemsSize: PropTypes.number,
  loadAtPercentage: PropTypes.number,
  loadOnCountFromEnd: PropTypes.number,
  inlineItems: PropTypes.bool,
  getHeightManagementId: PropTypes.func,
  styles: PropTypes.object,
};

InfiniteList.defaultProps = {
  items: [],
  loadItemsSize: undefined,
  loadAtPercentage: undefined,
  loadOnCountFromEnd: undefined,
  onLoadMore: null,
  inlineItems: false,
  getHeightManagementId: null,
  styles: null,
};

export default InfiniteList;
