import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const BrandHubContainer = Loadable(() => import("./BrandHubContainer"));

function BrandViewContainerAsyncLoading(props) {
  return <LoadableLoading {...props} transparent page />;
}

const BrandHubContainerContainer = (props) => {
  return (
    <BrandHubContainer
      {...props}
      fallback={<BrandViewContainerAsyncLoading />}
    />
  );
};

export default BrandHubContainerContainer;
