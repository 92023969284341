export const LOAD_PODCASTS_REQUEST = "LOAD_PODCASTS_REQUEST";
export const LOAD_PODCASTS_SUCCESS = "LOAD_PODCASTS_SUCCESS";
export const LOAD_PODCASTS_FAILURE = "LOAD_PODCASTS_FAILURE";

export const LOAD_SPECIFIC_PODCASTS_REQUEST = "LOAD_SPECIFIC_PODCASTS_REQUEST";
export const LOAD_SPECIFIC_PODCASTS_LOADING = "LOAD_SPECIFIC_PODCASTS_LOADING";
export const LOAD_SPECIFIC_PODCASTS_SUCCESS = "LOAD_SPECIFIC_PODCASTS_SUCCESS";
export const LOAD_SPECIFIC_PODCASTS_LOADED = "LOAD_SPECIFIC_PODCASTS_LOADED";
export const LOAD_SPECIFIC_PODCASTS_FAILURE = "LOAD_SPECIFIC_PODCASTS_FAILURE";

export const LOAD_SPECIFIC_EPISODE_REQUEST = "LOAD_SPECIFIC_EPISODE_REQUEST";
export const LOAD_SPECIFIC_EPISODE_LOADING = "LOAD_SPECIFIC_EPISODE_LOADING";
export const LOAD_SPECIFIC_EPISODE_LOADED = "LOAD_SPECIFIC_EPISODE_LOADED";
export const LOAD_SPECIFIC_EPISODE_SUCCESS = "LOAD_SPECIFIC_EPISODE_SUCCESS";
export const LOAD_SPECIFIC_EPISODE_FAILURE = "LOAD_SPECIFIC_EPISODE_FAILURE";

export const PODCAST_NEXT_PAGE = "PODCAST_NEXT_PAGE";

export const SET_PODCAST_SORT_ORDER = "SET_PODCAST_SORT_ORDER";
export const SET_PODCAST_FORMAT = "SET_PODCAST_FORMAT";
export const CHANGE_PODCAST_PAGE = "CHANGE_PODCAST_PAGE";

export const RATE_ITEM_REQUEST = "RATE_ITEM_REQUEST";
export const RATE_ITEM_UPDATE = "RATE_ITEM_UPDATE";
export const RATE_ITEM_UPDATE_DELETE = "RATE_ITEM_UPDATE_DELETE";
export const RATE_ITEM_SUCCESS = "RATE_ITEM_SUCCESS";
export const RATE_ITEM_FAILURE = "RATE_ITEM_FAILURE";

export const RATE_EPISODE_REQUEST = "RATE_EPISODE_REQUEST";
export const RATE_EPISODE_UPDATE = "RATE_EPISODE_UPDATE";
export const RATE_EPISODE_SUCCESS = "RATE_EPISODE_SUCCESS";
export const RATE_EPISODE_FAILURE = "RATE_EPISODE_FAILURE";

export const RECTANGLE_FORMAT = "RECTANGLE_FORMAT";
export const SQUARE_FORMAT = "SQUARE_FORMAT";
export const CARD_FORMAT = "CARD_FORMAT";
export const LIST_FORMAT = "LIST_FORMAT";

// review stuff TODO: move to own file?
export const OPEN_ITEM_REVIEW_MODAL = "OPEN_ITEM_REVIEW_MODAL";
export const REQUEST_PODCAST_REVIEW_PROMPT = "REQUEST_PODCAST_REVIEW_PROMPT";
export const REQUEST_PODCAST_REVIEW_PROMPT_CANCEL =
  "REQUEST_PODCAST_REVIEW_PROMPT_CANCEL";
export const CLOSE_PROMPT_FOR_PODCAST_REVIEW =
  "CLOSE_PROMPT_FOR_PODCAST_REVIEW";
export const OPEN_PROMPT_FOR_PODCAST_REVIEW = "OPEN_PROMPT_FOR_PODCAST_REVIEW";
export const REVIEW_PROMPT_CLICKED = "REVIEW_PROMPT_CLICKED";
export const REVIEW_SUBMIT_REQUEST = "REVIEW_SUBMIT_REQUEST";
export const REVIEW_SUBMIT_SUCCESS = "REVIEW_SUBMIT_SUCCESS";
export const REVIEW_SUBMIT_FAILURE = "REVIEW_SUBMIT_FAILURE";
export const REVIEW_CANCELLED = "REVIEW_CANCELLED";

export const REVIEW_DELETE_REQUEST = "REVIEW_DELETE_REQUEST";
export const REVIEW_DELETE_SUCCESS = "REVIEW_DELETE_SUCCESS";
export const REVIEW_DELETE_FAILURE = "REVIEW_DELETE_FAILURE";

export const LOAD_USER_FAVOURITES_SUCCESS = "LOAD_USER_FAVOURITES_SUCCESS";

export const EPISODE_CARD_HEIGHT_DESKTOP = 170;
export const EPISODE_CARD_HEIGHT_MOBILE = 254;

export const PODCAST_CARD_HEIGHT_DESKTOP = 170;
export const PODCAST_CARD_HEIGHT_MOBILE = 209;

export const DESKTOP_ICON_ROW_HEIGHT = 25;
export const DESKTOP_ACTIONS_HEIGHT = 26;
export const DESKTOP_BOTTOM_ROW_HEIGHT = 51;

export const MOBILE_ICON_ROW_HEIGHT = 25;
export const MOBILE_ACTIONS_HEIGHT = 26;
export const MOBILE_BOTTOM_ROW_HEIGHT = 51;

export const CLAIMED_BADGE_HEIGHT = 12;
export const EPISODE_TYPE_BADGE_HEIGHT = 15;
export const AUTHORITY_BADGE_HEIGHT = 12;
export const HAS_GUESTS_BADGE_HEIGHT = 12;

export const PRO_NOTE_CHANGE = "PRO_NOTE_CHANGE";

export const ADD_LIST_PLACEHOLDERS = "ADD_LIST_PLACEHOLDERS";

export const PODCAST_CATEGORIES = {
  TRUE_CRIME: "true-crime-category",
  BOOKS: "books",
  BUSINESS: "business",
  ENTREPRENEUR: "entrepreneur",
  BEAUTY: "beauty",
  MENTALHEALTH: "mental-health",
  RELATIONSHIPS: "relationships",
  DRAMA: "drama",
};
