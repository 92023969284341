import { faCircleNotch } from "@fortawesome/free-solid-svg-icons/faCircleNotch";
import { faExclamation } from "@fortawesome/free-solid-svg-icons/faExclamation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo } from "react";

import BadgeContainer from "components/Tastemaker/BadgeContainer";

import { MISSING_PROFILE_IMAGE_URL } from "constants/base";
import cachedImage from "utils/entity/cachedImage";

import useLoggedInUser from "hooks/useLoggedInUser";
import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import ScreenSizes from "styles/ScreenSizes";

const LARGE_SIZE = 44;
const SMALL_SIZE = 32;

const baseStyles = {
  imageContainer: {
    position: "relative",
    display: "block",
    width: "2rem",
    height: "2rem",

    [ScreenSizes.lgAndAbove]: {
      width: "2.75rem",
      height: "2.75rem",
    },
  },
  alertBadge: {
    display: "flex",
    background: colours.primary,
    width: 14,
    height: 14,
    padding: ".15rem",
    position: "absolute",
    top: 0,
    right: 0,
    borderRadius: "50%",
    color: "#fff",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 8,
    zIndex: 1,

    [ScreenSizes.mdAndAbove]: {
      width: 16,
      height: 16,
      fontSize: 10,
    },
  },
  loadingCircle: {
    zIndex: 1,
    color: colours.grey,
    backgroundColor: "#FFF",
    borderRadius: "50%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    fontSize: ".75rem",
  },
  loadingCircleLarge: {
    minWidth: LARGE_SIZE,
    minHeight: LARGE_SIZE,
    padding: ".75rem",
  },
  loadingCircleSmall: {
    minWidth: SMALL_SIZE,
    minHeight: SMALL_SIZE,
    padding: ".35rem",
  },
  entityImage: {
    display: "block",
    transform: "translate3d(0, 0, 0)",
    backfaceVisibility: "hidden",
    background: "#fff",
    width: "100%",
    height: "auto",
    borderRadius: "50%",
  },
};

const badgeContainerStyles = {
  badgeContainer: {
    left: "-0.188rem",
    bottom: "-0.5rem",

    [ScreenSizes.lgAndAbove]: {
      left: "-0.25rem",
      bottom: "-0.25rem",
    },
  },
  badge: {
    width: "0.813rem",
    height: "0.813rem",

    [ScreenSizes.lgAndAbove]: {
      width: "1.25rem",
      height: "1.25rem",
    },
  },
  userProfileImage: {
    padding: "0.125rem",
  },
};

const IMAGE_SIZE = 64;

const ProfileImage = (props) => {
  const { showAlertBadge, alertBadgeText, loading, dataId } = props;
  const { styles } = useStyles(baseStyles, props);
  const user = useLoggedInUser();
  const { isWindowSizeOrMore } = useWindowSize();
  const isMediumOrMore = isWindowSizeOrMore("medium");
  const showLoading = loading || !user;

  const src = user && user?.get("profile_image_url");
  const isMissingImage = src === MISSING_PROFILE_IMAGE_URL;

  const podcastHeaderImageUrl = isMissingImage
    ? MISSING_PROFILE_IMAGE_URL
    : cachedImage(src, IMAGE_SIZE);

  return (
    <span
      className={css(styles.imageContainer)}
      aria-label={
        showLoading ? "Logging in" : `Logged in as ${user.get("username")}`
      }
      data-id={dataId}
    >
      {showAlertBadge && (
        <FontAwesomeIcon
          className={css(styles.alertBadge)}
          aria-label={alertBadgeText}
          title={alertBadgeText}
          icon={faExclamation}
        />
      )}
      {showLoading ? (
        <FontAwesomeIcon
          className={css(
            styles.loadingCircle,
            isMediumOrMore
              ? styles.loadingCircleLarge
              : styles.loadingCircleSmall
          )}
          icon={faCircleNotch}
          spin
        />
      ) : (
        <BadgeContainer
          styles={badgeContainerStyles}
          userId={user?.get("id")?.toString()}
        >
          <img
            src={podcastHeaderImageUrl}
            className={css(styles.entityImage)}
            alt={"User Profile"}
            width={154}
            height={154}
          />
        </BadgeContainer>
      )}
    </span>
  );
};

ProfileImage.propTypes = {
  showAlertBadge: PropTypes.bool,
  alertBadgeText: PropTypes.string,
  loading: PropTypes.bool,
  dataId: PropTypes.string,
};

ProfileImage.defaultProps = {
  showAlertBadge: false,
  alertBadgeText: null,
  loading: false,
  dataId: "profile-image",
};

export default ProfileImage;
