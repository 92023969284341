import { css } from "aphrodite";
import PropTypes from "prop-types";
import { useCallback } from "react";

import BasicTooltip from "components/Common/Tooltip/BasicTooltip";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  toolTipContainer: {
    padding: "0.25rem 0",
    display: "flex",
  },
  toolTipBadge: {
    width: "3.25rem",
    height: "3.25rem",
    marginRight: "0.375rem",
  },
  toolTipTitle: {
    ...gStyles.avalonBold,
    color: colours.bodyText,
    margin: "0.25rem 0",
    fontSize: "1rem",
  },
  toolTipDescription: {
    ...gStyles.avalonMedium,
    margin: "0",
    opacity: "50%",
    fontSize: "1rem",
  },
};

const MODIFIERS = {
  modifiers: {
    offset: { offset: "0, 10" },
    flip: { enabled: true },
  },
};

const BadgeToolTip = (props) => {
  const { badgeUrl, bagdeStyles, description, name, onClick, onHover } = props;
  const { styles } = useStyles(baseStyles, props);

  const renderTooltip = useCallback(
    () => (
      <div className={css(styles.toolTipContainer)}>
        <img
          src={badgeUrl}
          className={css(styles.toolTipBadge)}
          alt="Tooltip User Badge Icon"
        />
        <div>
          <p className={css(styles.toolTipTitle)}>{name}</p>
          <p className={css(styles.toolTipDescription)}>{description}</p>
        </div>
      </div>
    ),
    [
      badgeUrl,
      description,
      name,
      styles.toolTipBadge,
      styles.toolTipContainer,
      styles.toolTipDescription,
      styles.toolTipTitle,
    ]
  );

  return (
    <BasicTooltip
      renderTooltip={renderTooltip}
      placement="bottom"
      light
      modifiers={MODIFIERS}
    >
      {(tooltipProps) => {
        const onMouseEnter = (e) => {
          if (onHover) {
            onHover(e);
          }

          tooltipProps?.onMouseEnter(e);
        };
        const newProps = {
          ...tooltipProps,
          onMouseEnter,
        };

        return (
          <img
            src={badgeUrl}
            className={bagdeStyles}
            alt="User Badge Icon"
            onClick={onClick}
            {...newProps}
          />
        );
      }}
    </BasicTooltip>
  );
};

BadgeToolTip.propTypes = {
  badgeUrl: PropTypes.string.isRequired,
  bagdeStyles: PropTypes.string,
  description: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onHover: PropTypes.func,
};

export default BadgeToolTip;
