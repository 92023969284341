import Loadable from "@loadable/component";

import ProfileImage from "./ProfileImage";

const ProfileMenu = Loadable(() => import("./ProfileMenu"));

function AboutAsyncLoading() {
  return <ProfileImage loading />;
}

const ProfileMenuContainer = (props) => {
  return <ProfileMenu {...props} fallback={<AboutAsyncLoading />} />;
};

export default ProfileMenuContainer;
