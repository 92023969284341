import { Map, List } from "immutable";

export const selectActivityModalStep = (state, modalKey) =>
  state.modals.getIn(["activity_modals", modalKey?.toString(), "step"]);
export const selectActivityModalProps = (state, modalKey) =>
  state.modals.hasIn(["activity_modals", modalKey?.toString()])
    ? state.modals.getIn(["activity_modals", modalKey?.toString(), "props"])
    : Map();

export const selectModalStack = (state) =>
  state.modals.get("modalStack") || List();
export const selectCurrentModal = (state) => {
  const stack = selectModalStack(state);

  return stack.first();
};

export const selectCurrentModalType = (state) => {
  const current = selectCurrentModal(state);

  return current && current.get("modalType");
};
