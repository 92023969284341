import Loadable from "@loadable/component";
const ListProfileHoverPopover = Loadable(() =>
  import("./ListProfileHoverPopover")
);

function PopoverLoading() {
  return null;
}

const ListProfileHoverPopoverContainer = (props) => {
  return <ListProfileHoverPopover {...props} fallback={<PopoverLoading />} />;
};

export default ListProfileHoverPopoverContainer;
