import { css } from "aphrodite";
import PropTypes from "prop-types";
import { useCallback } from "react";

import NavLink from "components/Common/NavLink";

import NavMenuItemWithDropdown from "./NavMenuDropdownItem/NavMenuItemWithDropdown";
import NavMenuItemLabel from "./NavMenuItemLabel";
import navMenuStyles from "./navMenuStyles";

import sendGAEvent from "utils/sendGAEvent";

import { useStyles } from "hooks/useStyles";

const NavMenuItem = (props) => {
  const { item, labelStyles: passedLabelStyles, switchToWhiteStyles } = props;
  const { styles } = useStyles(navMenuStyles, props);

  const Link = (item.route && NavLink) || "a";

  const getLinkProps = () => ({
    ...(item.route && {
      to: item.route,
      path: item.path,
      exact: !!item.exact,
      activeClassName: css(
        styles.navItemOuter,
        styles.navItemActive,
        switchToWhiteStyles && styles.navItemOuterLight,
        switchToWhiteStyles && styles.whiteNavBarLabel,
        styles.hoveredItem
      ),
    }),
    ...(item.type === "external" && {
      target: "_blank",
    }),
  });

  const handleClick = useCallback(
    (e) => {
      sendGAEvent({
        action: "navMenuLinkClicked",
        link: item.id,
        route: item.route || item.path,
        ...(item.context ? { context: item.context } : {}),
      });

      if (item.action) {
        e.preventDefault();
        item.action({ ...item });

        return false;
      }

      return true;
    },
    [item]
  );

  if (item.menu) {
    return (
      <NavMenuItemWithDropdown
        item={item}
        switchToWhiteStyles={switchToWhiteStyles}
        {...props}
      />
    );
  }

  const ItemComponent = item.menu ? "span" : Link;
  const badge =
    item.badge && item.badge.text ? (
      <span className={css(styles.navTitleBadge, styles.navTitleBadgeText)}>
        {item.badge.text}
      </span>
    ) : null;

  const navElement = (
    <ItemComponent
      className={css(
        !badge && styles.navItemOuter,
        styles.navItem,
        styles.hoveredItem,
        switchToWhiteStyles && styles.navItemOuterLight,
        switchToWhiteStyles && styles.whiteNavBarLabel,
        !item.title === "" && styles.emptyNavItem
      )}
      title={item.title}
      href={item.href || "#"}
      onClick={handleClick}
      {...getLinkProps()}
    >
      <NavMenuItemLabel item={item} styles={passedLabelStyles} />
    </ItemComponent>
  );

  if (badge) {
    return (
      <div className={css(styles.navItemOuter)}>
        {navElement}
        {badge}
      </div>
    );
  }

  return navElement;
};

NavMenuItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string,
    title: PropTypes.string,
    label: PropTypes.node.isRequired,
    href: PropTypes.string,
    badge: PropTypes.object,
    route: PropTypes.string,
    path: PropTypes.string,
    context: PropTypes.string,
    exact: PropTypes.bool,
    noIcon: PropTypes.bool,
    action: PropTypes.func,
    menu: PropTypes.func,
  }).isRequired,
  switchToWhiteStyles: PropTypes.bool,
  labelStyles: PropTypes.object,
};

export default NavMenuItem;
