import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo } from "react";

import Dotdotdot from "components/Common/Dotdotdot";
import EntityLink from "components/Entities/EntityLink";
import entityList from "components/Entities/EntityList";
import EntitySplitImage from "components/Entities/EntitySplitImage";

import HomepageSidebarSection from "./HomepageSidebarSection";

import * as sortConstants from "constants/sort";
import loadCreatorsList from "sagas/pagination/lists/loadCreatorsList";
import { selectSpecificCreator } from "selectors/creator";

import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  creatorPanel: {
    width: "calc(50% - 6px)",
    marginBottom: "1.5rem",
  },
  creatorPanelInFeed: {
    width: "calc(20% - 6px)",
    marginBottom: "1.5rem",
  },
  creatorName: {
    ...gStyles.avalonBold,
    color: colours.bodyText,
    fontSize: 13,
    textAlign: "center",
    width: "100%",
    marginTop: ".5rem",
  },
  podcastPanelImage: {
    width: "100%",
    height: "100%",
    border: "0.0625rem solid #fff",
  },
};

const TrendingCreatorsList = entityList({
  key: "homepageView/trendingCreators",
  sort: sortConstants.SORT_ORDER_TRENDING,
  list_type: "creators_filtered",
  loadListAction: loadCreatorsList,
  entity_type: "creator",
  pageSize: 10,
  staticFilters: {
    profile_image: { value: true },
  },
  getEntity: (state, id) => selectSpecificCreator(state, id),
});

const HEADER = {
  title: "Trending Creators",
  to: "/creators",
};

const HomepageSidebarCreators = (props) => {
  const { inFeed } = props;
  const { styles } = useStyles(baseStyles, props);
  const { isWindowSizeOrLess } = useWindowSize();
  const mobile = isWindowSizeOrLess("large");

  const renderCreatorPanel = (creator, index) => {
    if (!inFeed && index >= 6) {
      return null;
    }

    const podcast = creator.getIn(["most_recent_episode", "podcast"]);

    return (
      <div
        className={css(
          styles.creatorPanel,
          !mobile && inFeed && styles.creatorPanelInFeed
        )}
      >
        <EntitySplitImage
          mainEntity={creator}
          mainEntityType="creator"
          subEntity={podcast}
          subEntityType="podcast"
        />
        <Dotdotdot clamp={2} className={css(styles.creatorName)} tagName="div">
          <EntityLink entity_type="creator" entity={creator} />
        </Dotdotdot>
      </div>
    );
  };

  return (
    <HomepageSidebarSection
      header={HEADER}
      showAsList
      TitleComponent="h3"
      inFeed={inFeed}
    >
      <TrendingCreatorsList renderItem={renderCreatorPanel} inFeed={inFeed} />
    </HomepageSidebarSection>
  );
};

HomepageSidebarCreators.propTypes = {
  inFeed: PropTypes.bool,
};

HomepageSidebarCreators.defaultProps = {
  inFeed: false,
};

export default memo(HomepageSidebarCreators);
