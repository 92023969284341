import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { Fragment, useCallback } from "react";

import { useEntityFromTimeline } from "../useEntityFromTimeline";
import Activity from "./Activity/ActivityAsync";

import { entityNames } from "constants/entity";
import { selectSpecificUser } from "selectors/user";
import getUserDisplayName from "utils/entity/getUserDisplayName";

import useMemo from "hooks/useLoggedMemo";
import useReduxState from "hooks/useReduxState";
import { useStyles } from "hooks/useStyles";

import cardStyles from "styles/CardStyles";
import { feedColours as feedTextColours } from "styles/feedStyles";

const baseStyles = {
  verbText: {
    ...cardStyles.verbText,
    color: feedTextColours.feedLikeText || "#000",
  },
};

const LikedActivity = (props) => {
  const { activity, mobile } = props;
  const { styles } = useStyles(baseStyles, props);

  const [type] = activity.get("object").split(":");

  const { entity: user } = useEntityFromTimeline(activity.get("actor"));
  const { entity: likedEntity } = useEntityFromTimeline(activity.get("object"));

  if (!likedEntity) {
    console.warn("No liked entity, object", activity);
  }

  const entityUser = useReduxState(
    (state) =>
      selectSpecificUser(
        state,
        likedEntity
          ? likedEntity.get("user") || likedEntity.getIn(["owners", 0])
          : ""
      ),
    [likedEntity]
  );

  const filterEntities = useMemo(
    () => ({
      [activity.get("object").split(":")[0]]: likedEntity,
    }),
    [activity, likedEntity]
  );

  const typeName = useMemo(() => {
    if ((type === "review" || type === "userlist") && likedEntity) {
      return `${likedEntity.get("entity_type")} ${entityNames[type]}`;
    }

    return entityNames[type];
  }, [type, likedEntity]);

  const renderIntroText = useCallback(
    ({ renderEntityLink }) => (
      <Fragment>
        {renderEntityLink("user", user)}{" "}
        <span className={css(styles.verbText)}>liked</span>{" "}
        {renderEntityLink("user", entityUser, {
          content: getUserDisplayName(entityUser, true),
        })}
        {`'s ${typeName}`}
      </Fragment>
    ),
    [user, styles.verbText, entityUser, typeName]
  );

  const renderIntroImage = useCallback(
    ({ renderEntityImage }) => renderEntityImage("user", user),
    [user]
  );

  const sections = useMemo(
    () => [
      {
        type: "intro",
        renderImage: renderIntroImage,
        renderText: renderIntroText,
        highlightType: "Like",
      },
      {
        type: "entity",
        entityCardProps: {
          entity_type: type,
          entity: likedEntity,
          showCreators: mobile,
          mobile,
        },
      },
    ],
    [renderIntroImage, renderIntroText, mobile, type, likedEntity],
    "LikedActivitySections"
  );

  if (!activity) {
    console.warn("No activity for LikedActivity");
    return null;
  }

  if (!likedEntity) {
    return null;
  }

  return (
    <Activity
      {...props}
      sections={sections}
      loaded={!!(user && likedEntity && entityUser)}
      filterEntities={filterEntities}
    />
  );
};

LikedActivity.propTypes = {
  activity: PropTypes.instanceOf(Map),
  mobile: PropTypes.bool,
};

LikedActivity.defaultProps = {
  activity: Map(),
  mobile: false,
};

// LikedActivity.whyDidYouRender = { displayName: 'LikedActivity', logOnDifferentValues: true };

export default LikedActivity;
