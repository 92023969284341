import FooterColumn from "./FooterColumn";

const PRODUCTS_SERVICES_LINKS = [
  { title: "Solutions" },
  {
    title: "Podchaser Pro",
    to: "https://features.podchaser.com/pro/",
    external: true,
  },
  {
    title: "Podchaser API",
    to: "https://features.podchaser.com/api/",
    external: true,
  },
  { title: "Podchaser Alerts", to: "/alerts" },
  {
    title: "Podcharts",
    to: "https://podcharts.co/?utm_source=podchaser&utm_medium=web&utm_campaign=podchaser_navbar",
    external: true,
  },
  {
    title: "Podrover Reviews",
    to: "https://www.podrover.com/?utm_source=podchaser&utm_medium=web&utm_campaign=podchaser_navbar",
    external: true,
  },
];

const SolutionsFooterLinks = () => (
  <FooterColumn links={PRODUCTS_SERVICES_LINKS} />
);

export default SolutionsFooterLinks;
