import PropTypes from "prop-types";
import { Fragment, useCallback } from "react";
import { Link } from "react-router-dom";

import navMenuStyles from "../navMenuStyles";
import DropdownNavMenuIcon from "./DropdownNavMenuIcon";

import withHover from "utils/react-with-hover";
import sendGAEvent from "utils/sendGAEvent";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";

const baseStyles = {
  navInfoColumn: {
    flexDirection: "column",
    paddingTop: ".375rem",
    display: "block",
  },
  navLabel: {
    ...gStyles.avalonBold,
    fontSize: "1rem",
    marginBottom: "7px",
    display: "block",
  },
  navDescription: {
    ...gStyles.fontRegular,
    fontSize: ".875rem",
    letterSpacing: "-0.3px",
  },
  navLabelWBadge: {
    display: "flex",
  },
  navTitleBadge: {
    ...navMenuStyles.navTitleBadge,
    position: "relative",
    bottom: 0,
    marginLeft: "0.7rem",
  },
};

const DropdownNavMenuItem = (props) => {
  const { dropdownProps, menuItemProps, index, hover, hoverProps, closeMenu } =
    props;
  const { css, styles } = useStyles(baseStyles, props);

  const { label, description } = menuItemProps;

  const handleStopPropogation = useCallback(
    (e) => {
      closeMenu();
      e.stopPropagation();
      sendGAEvent({
        action: "navMenuLinkClicked",
        link: menuItemProps.id,
        route: menuItemProps.url,
        context: menuItemProps.context || "DropdownNavMenuItem",
      });
    },
    [menuItemProps, closeMenu]
  );

  const content = (
    <Fragment>
      <DropdownNavMenuIcon
        menuItemProps={menuItemProps}
        index={index}
        hover={hover}
      />
      <span className={css(styles.navInfoColumn)}>
        <span
          className={css(
            styles.navLabel,
            menuItemProps.badge && styles.navLabelWBadge
          )}
        >
          {label}
          {menuItemProps.badge && menuItemProps.badge.text ? (
            <span className={css(styles.navTitleBadge)}>
              {menuItemProps.badge.text}
            </span>
          ) : null}
        </span>
        <span className={css(styles.navDescription)}>{description}</span>
      </span>
    </Fragment>
  );

  const LinkComponent =
    menuItemProps.external || !menuItemProps.url ? "a" : Link;

  return (
    <LinkComponent
      {...dropdownProps}
      {...hoverProps}
      {...(menuItemProps.url
        ? {
            onClick: handleStopPropogation, // prevents parent firing double navigation events on key clicks
            [menuItemProps.external ? "href" : "to"]:
              menuItemProps.url || menuItemProps.href,
          }
        : {})}
      rel={menuItemProps.external ? "noreferrer noopener" : undefined}
      to={menuItemProps.url}
      target={menuItemProps.external ? "_blank" : ""}
    >
      {content}
    </LinkComponent>
  );
};

DropdownNavMenuItem.propTypes = {
  closeMenu: PropTypes.func.isRequired,
  dropdownProps: PropTypes.object.isRequired,
  menuItemProps: PropTypes.object.isRequired,
  hoverProps: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  hover: PropTypes.bool,
};

DropdownNavMenuItem.defaultProps = {
  hover: false,
};

export default withHover()(DropdownNavMenuItem);
