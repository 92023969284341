import {
  selectAuthLoggedIn,
  selectAuthLoggingIn,
  selectAuthUser,
  selectHasLoggedOut,
} from "../selectors/auth";

import useReduxState from "hooks/useReduxState";

export default function useLoggedInUser() {
  return useReduxState((state) => selectAuthUser(state), []);
}

export function useHasLoggedOut() {
  return useReduxState((state) => selectHasLoggedOut(state), []);
}

export const useIsAuthUser = () =>
  useReduxState((state) => {
    const isLoggedIn = selectAuthLoggedIn(state);
    const isLoggingIn = selectAuthLoggingIn(state);

    return isLoggedIn || isLoggingIn;
  }, []);

export function useLoggedIn() {
  return useReduxState((state) => selectAuthLoggedIn(state), []);
}
