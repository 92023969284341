import PropTypes from "prop-types";
import { useMemo } from "react";

const classMap = {
  xs: "col-xs-",
  sm: "col-sm-",
  md: "col-md-",
  lg: "col-lg-",
  xl: "col-xl-",
  xsOffset: "col-xs-offset-",
  smOffset: "col-sm-offset-",
  mdOffset: "col-md-offset-",
  lgOffset: "col-lg-offset-",
  xlOffset: "col-xl-offset-",
  first: "first-",
  last: "last-",
};

const Col = (props) => {
  const { reverse, className, children, ...other } = props;

  const { classes, colProps } = useMemo(() => {
    const newClasses = [];
    const passingProps = {};

    if (className) {
      newClasses.push(className);
    }

    if (reverse) {
      newClasses.push("reverse");
    }

    for (const otherKey in other) {
      if (classMap[otherKey]) {
        const value = other[otherKey];

        if (typeof value !== "undefined") {
          newClasses.push(`${classMap[otherKey]}${value}`);
        }
      } else {
        passingProps[otherKey] = other[otherKey];
      }
    }

    return {
      colProps: passingProps,
      classes: newClasses,
    };
  }, [reverse, className, other]);

  return (
    <div className={classes.join(" ")} {...colProps}>
      {children}
    </div>
  );
};

Col.propTypes = {
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  xsOffset: PropTypes.number,
  smOffset: PropTypes.number,
  mdOffset: PropTypes.number,
  lgOffset: PropTypes.number,
  xlOffset: PropTypes.number,
  reverse: PropTypes.bool,
  first: PropTypes.string,
  last: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};

Col.defaultProps = {
  children: null,
  className: null,
};

export default Col;
