import Loadable from "@loadable/component";

import { useStyles } from "hooks/useStyles";

import ScreenSizes from "styles/ScreenSizes";

const Footer = Loadable(() => import("./Footer/Footer"));

const baseStyles = {
  footerContainer: {
    minHeight: "106.2rem",

    [ScreenSizes.mdAndAbove]: {
      minHeight: "60rem",
    },
  },
};

function FooterAsyncLoading(props) {
  const { styles, css } = useStyles(baseStyles, props);

  return <div className={css(styles.container)} />;
}

const FooterContainer = (props) => {
  return <Footer {...props} fallback={<FooterAsyncLoading />} />;
};

export default FooterContainer;
