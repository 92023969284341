import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const HomepageSidebarCreators = Loadable(() =>
  import("./HomepageSidebarCreators")
);

function HomepageSidebarCreatorsAsyncLoading(props) {
  return <LoadableLoading {...props} transparent />;
}

const HomepageSidebarCreatorsContainer = (props) => {
  return (
    <HomepageSidebarCreators
      {...props}
      fallback={<HomepageSidebarCreatorsAsyncLoading />}
    />
  );
};

export default HomepageSidebarCreatorsContainer;
