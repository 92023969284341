import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo, Fragment, useCallback } from "react";
import { Link } from "react-router-dom";

import StandardButton from "components/Buttons/StandardButton";

import HomepageFeedNotFollowingAnything from "./HomepageFeedNotFollowingAnything";

import { useSmoothScroll } from "hooks/useSmoothScroll";
import { useStyles } from "hooks/useStyles";
import useTimelineAction from "hooks/useTimelineAction";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  homepageFeedNothingMoreToLoad: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "5rem",
    paddingTop: "1.875rem",
    borderTop: "1px #dfe1e6 solid",
  },
  scrollButtonContainer: {
    margin: "2rem auto 0",
    width: "10rem",
  },
  headerTitle: {
    ...gStyles.avalonBold,
    fontSize: "1.25rem",
    marginBottom: "1.1rem",

    [ScreenSizes.lgAndAbove]: {
      fontSize: "1.5rem",
    },
  },
  headerLink: {
    ...gStyles.fontSemiBold,
  },
};

const placeholderStyles = {
  header: {
    backgroundColor: colours.white,
  },
};

const HomepageFeedNothingMoreToLoad = (props) => {
  const { timelineKey } = props;
  const { styles } = useStyles(baseStyles, props);
  const {
    actions: { loadTimeline },
    timelineModuleLoaded,
  } = useTimelineAction();
  const { scrollToTop } = useSmoothScroll({ duration: 500, align: "top" });

  const handleScroll = useCallback(() => {
    scrollToTop();

    if (timelineModuleLoaded) {
      loadTimeline({ timelineKey });
    }
  }, [scrollToTop, loadTimeline, timelineKey, timelineModuleLoaded]);

  const renderHeader = useCallback(
    () => (
      <Fragment>
        <div className={css(styles.headerTitle)}>
          Want to see more posts in your feed?
        </div>
        <div>
          Your personalized podcast feed is curated from your activity on
          Podchaser. Follow more{" "}
          <Link to="/podcasts" className={css(styles.headerLink)}>
            podcasts
          </Link>
          {", "}
          <Link to="/creators" className={css(styles.headerLink)}>
            creators
          </Link>{" "}
          and{" "}
          <Link to="/users" className={css(styles.headerLink)}>
            users
          </Link>
          , build more lists, and rate more podcasts to see better
          recommendations!
        </div>
      </Fragment>
    ),
    [styles.headerTitle, styles.headerLink]
  );

  return (
    <div className={css(styles.homepageFeedNothingMoreToLoad)}>
      <HomepageFeedNotFollowingAnything
        renderHeader={renderHeader}
        placeholderStyles={placeholderStyles}
      />
      <div className={css(styles.scrollButtonContainer)}>
        <StandardButton
          onClick={handleScroll}
          variation="white"
          label="Refresh Feed"
          flat
        />
      </div>
    </div>
  );
};

HomepageFeedNothingMoreToLoad.propTypes = {
  timelineKey: PropTypes.string.isRequired,
};

export default memo(HomepageFeedNothingMoreToLoad);
