import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const HomepageFeedNotFollowingAnything = Loadable(() =>
  import("./HomepageFeedNotFollowingAnything")
);

function HomepageViewAsyncLoading(props) {
  return <LoadableLoading {...props} transparent height={"136rem"} />;
}

const HomepageFeedNotFollowingAnythingContainer = (props) => {
  return (
    <HomepageFeedNotFollowingAnything
      {...props}
      fallback={<HomepageViewAsyncLoading />}
    />
  );
};

export default HomepageFeedNotFollowingAnythingContainer;
