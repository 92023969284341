import React, { useState, createContext } from "react";

const TranscriptContext = createContext();
const { Provider } = TranscriptContext;

function TranscriptProvider({ children }) {
  const [selectedAdId, setSelectedAdId] = useState(null);

  const store = {
    selectedAdId,
    setSelectedAdId,
  };
  return <Provider value={store}>{children}</Provider>;
}

export { TranscriptContext, TranscriptProvider };
