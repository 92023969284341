import { Map } from "immutable";

import paginationActions from "actions/pagination";

import useActionCreators from "hooks/useActionCreators";
import useQuery from "hooks/useQuery";

function useClearSearchResults() {
  const { applyFilters } = useActionCreators({
    applyFilters: paginationActions.applyFilters,
    initList: paginationActions.rawInitList,
  });

  const searchResultsListKey = [
    "search_results/podcasts",
    "search_results/episodes",
    "search_results/networks",
    "search_results/brands",
    "search_results/creators",
    "search_results/users",
    "search_results/lists",
  ];

  const [, setQuery] = useQuery();

  return () => {
    searchResultsListKey.forEach((listKey) => {
      applyFilters(listKey, Map({})); // Reset filters in Redux
    });
    setQuery({}); // Clear the URL query (filters)
  };
}

export default useClearSearchResults;
