import baseX from "base-x";
import { Buffer } from "buffer";

const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
export const base62 = baseX(BASE62);

export function base62Encode(str) {
  return base62.encode(Buffer.from(str.toString(), "utf-8"));
}

export function base62Decode(str) {
  return base62.decode(str.toString()).toString("utf-8");
}
