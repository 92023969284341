import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

const BadgesModal = Loadable(() => import("./BadgesModal"));

function BadgesModalAsyncLoading(props) {
  return <LoadingOverlay {...props} span />;
}

const BadgesModalContainer = (props) => {
  return <BadgesModal {...props} fallback={<BadgesModalAsyncLoading />} />;
};

export default BadgesModalContainer;
