import { faCircleNotch } from "@fortawesome/free-solid-svg-icons/faCircleNotch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo, useMemo } from "react";

import colours from "../../styles/colours";

import generateTransition from "utils/generateTransition";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";
import getHoverQuery from "styles/getHoverQuery";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  loadMore: {
    ...gStyles.avalonSemiBold,
    display: "block",
    textAlign: "center",
    padding: "1.3em 0.8em 1.1em",
    width: "100%",
    maxWidth: "300px",
    textTransform: "none",
    margin: "1rem auto 1rem",
    letterSpacing: "0",
    background: "none",
    fontSize: "0.9em",
    cursor: "pointer",
    boxShadow: "0 0 0 0 rgba(0, 0, 0, 0)",
    transition: generateTransition({ target: "color", speed: "400ms" }),
    minHeight: "3.375rem",
    lineHeight: "1rem",
    borderRadius: 6,
    border: "1px solid rgba(0,0,0,0.2)",
    color: "#555",

    [ScreenSizes.xlAndAbove]: {
      border: "1px solid rgba(0,0,0,0.2)",
      color: "#555",
    },

    ...getHoverQuery({
      color: colours.midGrey,
    }),
  },
  endOfList: {
    border: "1px solid rgba(0,0,0,0.08)",
    color: colours.lightishGrey,

    ...getHoverQuery({
      color: "#BBB",
    }),
  },
  loading: {
    boxShadow: "none",
    borderColor: "transparent",
    outline: "none",
  },
  loadingIcon: {
    marginLeft: 0,
    fontSize: "1rem",
    maxWidth: "1em",
  },
  loadingIconWithText: {
    marginLeft: ".5rem",
  },
};

const LoadMoreButton = (props) => {
  const {
    loading,
    hasMore,
    onClick,
    total,
    noResultsText,
    renderNoResultsMessage,
    endOfListText,
    loadingText,
    loadMoreText,
    dataId,
    showLoading,
    className,
  } = props;
  const { styles } = useStyles(baseStyles, props);

  const noResults = !loading && !hasMore && total === 0;

  const ariaLabel = useMemo(() => {
    if (loading) {
      return loadingText || "Loading";
    }
    if (hasMore) {
      return loadMoreText;
    }

    return noResultsText || "Nothing more to load";
  }, [hasMore, loading, loadingText, loadMoreText, noResultsText]);

  if (noResults && renderNoResultsMessage) {
    return renderNoResultsMessage();
  }

  if (loading && !showLoading) {
    return null;
  }

  return (
    <button
      type="submit"
      disabled={loading}
      onClick={onClick}
      className={`${css(
        styles.loadMore,
        loading && styles.loading,
        !loading && !hasMore && styles.endOfList
      )} ${className}`}
      data-id={dataId}
      aria-label={ariaLabel}
    >
      {loading && loadingText}
      {loading && (
        <FontAwesomeIcon
          className={css(
            styles.loadingIcon,
            loadingText && styles.loadingIconWithText
          )}
          icon={faCircleNotch}
          spin
        />
      )}
      {!loading && hasMore && loadMoreText}
      {!loading && !hasMore && total > 0 && endOfListText}
      {noResults && noResultsText}
    </button>
  );
};

LoadMoreButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  hasMore: PropTypes.bool,
  noResultsText: PropTypes.node,
  renderNoResultsMessage: PropTypes.func,
  endOfListText: PropTypes.node,
  loadingText: PropTypes.node,
  loadMoreText: PropTypes.node,
  total: PropTypes.number,
  dataId: PropTypes.string,
  showLoading: PropTypes.bool,
  className: PropTypes.string,
};

LoadMoreButton.defaultProps = {
  loading: false,
  hasMore: true,
  noResultsText: "Nothing here.",
  renderNoResultsMessage: null,
  endOfListText: "That's all there is!",
  loadingText: "", // 'Loading...',
  loadMoreText: "Load More...",
  total: 0,
  dataId: "load-more-button",
  showLoading: true,
  className: "",
};

export default memo(LoadMoreButton);
