import { css } from "aphrodite";
import { List, Map } from "immutable";
import PropTypes from "prop-types";
import { Fragment, useCallback, useMemo } from "react";

import AggregateActivity from "./Activity/AggregateActivity";
import {
  allActivitiesEpisodeOfTheSamePodcast,
  getShownByField,
  getUniqueEntityCountString,
  getUniqueObjectsByType,
} from "./Activity/AggregateUtils";
import RateActivity from "./RateActivity";
import ReviewActivity from "./ReviewActivity";

import { selectSpecificPodcast } from "selectors/podcast";

import useReduxState from "hooks/useReduxState";
import { useStyles } from "hooks/useStyles";

import cardStyles from "styles/CardStyles";
import { feedColours as feedTextColours } from "styles/feedStyles";

const baseStyles = {
  verbText: {
    ...cardStyles.verbText,
    color: feedTextColours.feedReviewText || "#000",
  },
};

const RateReviewAggregate = (props) => {
  const { aggregateProps } = props;
  const { styles } = useStyles(baseStyles, props);

  const {
    activitiesByObjectType,
    activitiesByActor,
    activitiesByVerb,
    activitiesState: {
      firstActor,
      firstActorType,
      firstObject,
      firstObjectType,
      hasOneActor,
      hasOneObject,
    },
  } = aggregateProps;

  const actorCount = activitiesByActor.size;

  const allEpisodesOfSamePodcast = useMemo(
    () => allActivitiesEpisodeOfTheSamePodcast(activitiesByObjectType, true),
    [activitiesByObjectType]
  );

  const podcastEntity = useReduxState(
    (state) => selectSpecificPodcast(state, allEpisodesOfSamePodcast),
    [allEpisodesOfSamePodcast]
  );

  const { reviews, ratings } = useMemo(() => {
    const baseRatings = activitiesByVerb.get("rate") || List();
    const baseReviews = activitiesByVerb.get("review") || List();

    const newGrouped = baseRatings.reduce(
      (grouped, rating) => {
        if (grouped.reviews.has(rating.get("object"))) {
          return {
            ...grouped,
            reviews: grouped.reviews.setIn(
              [rating.get("object"), "rating"],
              rating.get("rating")
            ),
          };
        }

        return {
          ...grouped,
          ratings: grouped.ratings.push(rating),
        };
      },
      {
        reviews: baseReviews.reduce(
          (reviewsMap, review) => reviewsMap.set(review.get("object"), review),
          Map()
        ),
        ratings: List(),
      }
    );

    return {
      ...newGrouped,
      reviews: newGrouped.reviews.toList(),
    };
  }, [activitiesByVerb]);

  const { reviewsByObjectType, ratingsByObjectType } = useMemo(
    () => ({
      reviewsByObjectType: getUniqueObjectsByType(reviews).toList(),
      ratingsByObjectType: getUniqueObjectsByType(ratings).toList(),
    }),
    [reviews, ratings]
  );

  const getActivityEntityCountString = useCallback(
    (aggrActivities) => {
      if (hasOneActor && !hasOneObject) {
        return getUniqueEntityCountString(aggrActivities);
      }

      return null;
    },
    [hasOneActor, hasOneObject]
  );

  const renderIntroText = useCallback(
    (aggrActivities, isRatings) => (introProps) => {
      const { renderEntityLink } = introProps;

      if (hasOneActor) {
        return (
          <Fragment>
            {renderEntityLink(firstActorType, firstActor)}{" "}
            <span className={css(styles.verbText)}>
              {isRatings ? "rated" : "reviewed"}{" "}
              {hasOneObject
                ? renderEntityLink(firstObjectType, firstObject)
                : getActivityEntityCountString(aggrActivities)}
            </span>
            {allEpisodesOfSamePodcast && (
              <Fragment>
                {" "}
                of {renderEntityLink("podcast", podcastEntity)}
              </Fragment>
            )}
          </Fragment>
        );
      }
      if (hasOneObject) {
        return (
          <Fragment>
            {`${aggrActivities.size} users `}
            {isRatings ? "rated" : "reviewed"}{" "}
            {renderEntityLink(firstObjectType, firstObject)}
          </Fragment>
        );
      }

      return (
        <Fragment>
          {actorCount}
          {` ${isRatings ? "rated" : "reviewed"} `}
          {getActivityEntityCountString(aggrActivities)}
          {allEpisodesOfSamePodcast && (
            <Fragment>
              {" "}
              of {renderEntityLink("podcast", podcastEntity)}
            </Fragment>
          )}
        </Fragment>
      );
    },
    [
      hasOneActor,
      hasOneObject,
      actorCount,
      getActivityEntityCountString,
      allEpisodesOfSamePodcast,
      podcastEntity,
      firstActorType,
      firstActor,
      styles.verbText,
      firstObjectType,
      firstObject,
    ]
  );

  const renderAggregate = (aggrActivities, isRatings = false) => (
    <AggregateActivity
      {...props}
      activities={aggrActivities}
      shownByField={getShownByField(aggregateProps)}
      renderIntroText={renderIntroText(aggrActivities, isRatings)}
      highlightType="Review"
      loaded
    />
  );

  const renderRatings = (ratingActivities, index) => (
    <div key={index}>
      {ratingActivities.size === 1 && (
        <RateActivity {...props} activity={ratingActivities.first()} />
      )}
      {ratingActivities.size > 1 && renderAggregate(ratingActivities, true)}
    </div>
  );

  const renderReviews = (reviewActivities, index) => (
    <div key={index}>
      {reviewActivities.size === 1 && (
        <ReviewActivity {...props} activity={reviewActivities.first()} />
      )}
      {reviewActivities.size > 1 && renderAggregate(reviewActivities)}
    </div>
  );

  return (
    <Fragment>
      {reviewsByObjectType.map(renderReviews)}
      {ratingsByObjectType.map(renderRatings)}
    </Fragment>
  );
};

RateReviewAggregate.propTypes = {
  aggregateProps: PropTypes.object.isRequired,
};

export default RateReviewAggregate;
