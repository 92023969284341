import { CancelToken } from "axios";
import { call, put } from "redux-saga/effects";

import { startTime } from "../utils/timing";

import { clearToken } from "utils/auth";
import { prodLog, log } from "utils/dev";
import { equals } from "utils/misc";

export function* apiCall({
  fn,
  data = {},
  axiosOptions = undefined,
  requestContext = {},
}) {
  const getTime = startTime(requestContext.server);
  try {
    data.cancelToken = CancelToken.source();

    if (axiosOptions) {
      data.axiosOptions = axiosOptions;
    }

    const response = yield call(fn, data);
    const timeTaken = getTime();

    log("API call time", timeTaken, fn.name);

    return response;
  } catch (e) {
    prodLog("apiCall error", e);

    if (!e.response) {
      if (e.message === "Network Error") {
        yield put({ type: "NETWORK_ERROR" });
        // Still throw this so other sagas can catch it
        throw e;
      }
    } else if (equals(e.response.status, 401)) {
      // clear tokens and cookie
      clearToken();
      yield put({ type: "AUTH_FAILURE" });
    }

    throw e;
  }
}
