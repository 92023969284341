import Loadable from "@loadable/component";
const AlertLimitModal = Loadable(() => import("./AlertLimitModal"));

function AlertLimitModalAsyncLoading() {
  return null;
}

const AlertLimitModalContainer = (props) => {
  return (
    <AlertLimitModal {...props} fallback={<AlertLimitModalAsyncLoading />} />
  );
};

export default AlertLimitModalContainer;
