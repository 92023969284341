import { faExclamation } from "@fortawesome/free-solid-svg-icons/faExclamation";
import { css } from "aphrodite";

import HomepageSidebar from "pages/Homepage/HomepageSidebar/HomepageSidebar";

import AccountHubHeader from "./AccountHubHeader";
import AccountHubVerifyLink from "./AccountHubVerifyLink";

import modalActions from "actions/modals";

import useActionCreators from "hooks/useActionCreators";
import useLoggedInUser from "hooks/useLoggedInUser";
import { useStyles } from "hooks/useStyles";

import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  accountHubContainer: {
    padding: "1rem",
    background: "#fff",
    position: "relative",
  },
  backArrow: {
    position: "absolute",
    top: "1rem",
    right: "1rem",
    fontSize: "1.25rem",

    [ScreenSizes.smAndAbove]: {
      position: "relative",
      right: "auto",
      top: "auto",
      marginRight: "0.75rem",
    },
  },
  verifyLinks: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginBottom: "1.5rem",
  },
};

const AccountHub = (props) => {
  const { styles } = useStyles(baseStyles, props);
  const user = useLoggedInUser();
  const { showModal } = useActionCreators({
    showModal: modalActions.showModal,
  });

  const needsToVerifyEmail = !user.get("email_verified");
  const needsProfileImage = !user.get("profile_image_url");

  const renderVerifyLink = (linkProps) => (
    <AccountHubVerifyLink {...linkProps} />
  );

  return (
    <div>
      <div className={css(styles.accountHubContainer)}>
        <AccountHubHeader />
        {(needsToVerifyEmail || needsProfileImage) && (
          <div className={css(styles.verifyLinks)}>
            {needsToVerifyEmail &&
              renderVerifyLink({
                "data-id": "verify-profile-link",
                id: "verifyprofile",
                label: "Verify Profile",
                icon: faExclamation,
                action: () => showModal("sendEmailVerification"),
              })}

            {needsProfileImage &&
              renderVerifyLink({
                "data-id": "add-profile-image-link",
                id: "addprofileimage",
                label: "Add Profile Image",
                icon: faExclamation,
                url: "/profile/settings/account",
              })}
          </div>
        )}
        <HomepageSidebar />
      </div>
    </div>
  );
};

export default AccountHub;
