import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const Leaderboard = Loadable(() => import("./Leaderboard"));

function LeaderboardAsyncLoading(props) {
  return <LoadableLoading {...props} page />;
}

const LeaderboardContainer = (props) => {
  return <Leaderboard {...props} fallback={<LeaderboardAsyncLoading />} />;
};

export default LeaderboardContainer;
