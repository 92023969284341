import { commonModuleGroup, podcastModuleGroup } from "modules/groups";
import { Switch } from "react-router-dom";
import { Route } from "react-router-dom";

import TopLevelRoute from "pages/Common/TopLevelRoute";

import Networks from "../Discover/Networks/async";
import NetworkViewContainer from "./async";

function NetworkRoutes() {
  return (
    <Switch>
      <TopLevelRoute
        path="/network"
        exact
        modules={[...commonModuleGroup, ...podcastModuleGroup]}
      >
        <Networks />
      </TopLevelRoute>
      <TopLevelRoute
        path="/networks"
        exact
        modules={[...commonModuleGroup, ...podcastModuleGroup]}
      >
        <Networks />
      </TopLevelRoute>
      <TopLevelRoute
        path="/network(s?)/:sort(alpha|podcastCount|monthlyReach|bestMatch)"
        exact
        modules={[...commonModuleGroup, ...podcastModuleGroup]}
      >
        <Networks />
      </TopLevelRoute>

      <TopLevelRoute
        path="/network(s?)/:id/:tab?"
        modules={[...commonModuleGroup, ...podcastModuleGroup]}
        routeComponent={Route}
      >
        <NetworkViewContainer />
      </TopLevelRoute>
    </Switch>
  );
}

export default NetworkRoutes;
