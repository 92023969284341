import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const PublicDownloadContactsModal = Loadable(() =>
  import("./PublicDownloadContactsModal")
);

function PublicDownloadContactsModalAsyncLoading(props) {
  return <LoadableLoading {...props} />;
}

const PublicDownloadContactsModalContainer = (props) => {
  return (
    <PublicDownloadContactsModal
      {...props}
      fallback={<PublicDownloadContactsModalAsyncLoading />}
    />
  );
};

export default PublicDownloadContactsModalContainer;
