import { css } from "aphrodite";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";

const baseStyles = {
  container: {
    ...gStyles.skeletonPlaceholder,
    borderRadius: 4,
    width: "100%",
    height: "2.75rem",
    minHeight: "2.75rem",
  },
};

export default function LoadingEntityFollowButtonAsync(props) {
  const { styles } = useStyles(baseStyles, props);

  return <div className={css(styles.skeleotn)} />;
}
