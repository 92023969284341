import btoa from "btoa";

import { Base64 } from "../encoding";
import { remToPx } from "../styles";

import { MISSING_IMAGE_URL } from "constants/base";
import { isDataURL } from "utils/images";

export default function cachedImage(
  imageUrl,
  passedWidth = 256,
  passedHeight = null,
  options = {}
) {
  let width = passedWidth;
  let height = passedHeight;

  if (!imageUrl) {
    return options.disableMissingImage ? null : MISSING_IMAGE_URL;
  }

  if (isDataURL(imageUrl)) {
    return imageUrl;
  }

  if (typeof width === "string" && width.includes("em")) {
    width = remToPx(width);
  }

  if (isNaN(width)) {
    return null;
  }

  const variance =
    typeof options.variance !== "undefined" ? options.variance : 0.1; // Percent the width can be aboev each width we're testing

  // Get standard version of width (8, 16, 32, 64, 128, 256, etc) that's larger than requested width
  let newWidth = 0;
  let newHeight = 0;
  if (width) {
    newWidth = 8;
    while (newWidth < width - newWidth * variance) {
      newWidth *= 2;
    }
    if (height) {
      height =
        height === width
          ? newWidth
          : height * (Math.round((newWidth / width) * 100) / 100);
    }
    width = newWidth;
  } else if (height) {
    while (newHeight < height - newHeight * variance) {
      newHeight *= 2;
    }
    height = newHeight;
  }

  // Don't cache images from our server
  if (imageUrl.indexOf("/") === 0) {
    // TODO: Also exclude podchaser.com/ calls? Will that also exclude CDN files?
    return imageUrl;
  }

  const forceRefreshString =
    typeof window !== "undefined" &&
    window.location.search.indexOf("refresh-cached-images") >= 0
      ? "refresh/"
      : "";

  width = Math.ceil(width);
  height = Math.ceil(height);

  // If we want a variable width but a fixed height, width needs to be an empty string
  if (width <= 0 && !!height) {
    width = "";
  } else if (height <= 0 && !!width) {
    height = "";
  }

  // ensure we never use the full size image accidentally
  if ((!width || width === "") && (!height || height === "")) {
    width = 128;
    height = 128;
  }

  let dimensions = `${width}x${width}`;
  if (height) {
    dimensions = `${width}x${height}`;
  }
  if (options.notSquare) {
    dimensions = `${width}x`;
  }
  const missingExtension = options.disableMissingImage
    ? ""
    : `/${encodeURIComponent(btoa(MISSING_IMAGE_URL))}`;

  return `https://cachedimages.podchaser.com/${forceRefreshString}${dimensions}/${encodeURIComponent(
    Base64.encode(imageUrl)
  )}${missingExtension}`;
}

export function cachedSrcsetImages(
  imageUrl,
  width1x = 256, // This should be the 1x size
  height = null,
  options = {}
) {
  // Creates "URL1 256w, URL2 512w"
  return `${cachedImage(
    imageUrl,
    width1x,
    height,
    options
  )} ${width1x}w, ${cachedImage(imageUrl, width1x * 2, height, options)} ${
    width1x * 2
  }w`;
}
