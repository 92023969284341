import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

import ScreenSizes from "styles/ScreenSizes";

export const loadingStyles = {
  height: {
    height: "293rem",
    maxHeight: "293rem",
    minHeight: 0,

    [ScreenSizes.lgAndAbove]: {
      height: "136rem",
      maxHeight: "136rem",
    },
  },
};

const NetworkViewContainer = Loadable(() =>
  import(
    /* webpackChunkName: "NetworkViewContainer" */ "./NetworkViewContainer"
  )
);

function NetworkViewLoading() {
  return <LoadingOverlay height={"293rem"} styles={loadingStyles} />;
}

const NetworkViewContainerContainer = (props) => {
  return <NetworkViewContainer {...props} fallback={<NetworkViewLoading />} />;
};

export default NetworkViewContainerContainer;
