import { categorySchema } from "api/schema/category";
import { schema } from "normalizr";

// Need to put these all into the same file otherwise we end up with circular dependencies

const podcastSchema = new schema.Entity("podcasts");
const character = new schema.Entity("character");

const episodeSchema = new schema.Entity("episodes");
const creatorSchema = new schema.Entity("creators", {}, { idAttribute: "id" });

const suggestedCreditSchema = new schema.Entity(
  "suggested_credits",
  {},
  {
    idAttribute: "id",
  }
);

const creditsProcessStrategy = (value) => ({
  ...value,
  id: value.podcast_id,
});

const podcastCreditSchema = new schema.Entity(
  "podcastCredits",
  {
    roles: [
      {
        characters: [character],
      },
    ],
  },
  {
    idAttribute: "podcast_id",
    processStrategy: creditsProcessStrategy,
  }
);

const recurringCreditSchema = new schema.Entity(
  "recurringCredits",
  {
    roles: [
      {
        character,
      },
    ],
  },
  {
    idAttribute: "podcast_id",
    processStrategy: creditsProcessStrategy,
  }
);

const episodesGroupProcessStrategy = (value) => ({
  ...value,
  id: value.group_key,
});
const episodesGroupSchema = new schema.Entity(
  "episodesGroup",
  {},
  {
    idAttribute: "group_key",
    processStrategy: episodesGroupProcessStrategy,
  }
);

const episodeCreditSchema = new schema.Entity("credits");

const groupedCreditSchema = new schema.Entity(
  "credits",
  {},
  {
    idAttribute: (value /* , parent, key*/) => `${value.parentID}`,
  }
);

const creditSubmissionSchema = new schema.Entity(
  "credit_submissions",
  {},
  {
    idAttribute: "credit_id",
  }
);

const creatorSubmissionSchema = new schema.Entity(
  "creator_submissions",
  {},
  { idAttribute: "id" }
);

const roleSchema = new schema.Entity(
  "roles",
  {},
  {
    idAttribute: "code",
  }
);

const listEntity = new schema.Union(
  { podcast: podcastSchema, episode: episodeSchema },
  (entity, parent) => parent.entity_type
);

creatorSchema.define({
  credits: [{ entity: listEntity }],
  suggested_credits: { credits: [suggestedCreditSchema] },
  podcast_credits: { credits: [podcastCreditSchema] },
});

podcastSchema.define({
  episodes: [episodeSchema],
  categories: [categorySchema],
});

export {
  creatorSchema,
  creatorSubmissionSchema,
  creditSubmissionSchema,
  episodeCreditSchema,
  episodeSchema,
  episodesGroupSchema,
  groupedCreditSchema,
  listEntity,
  podcastCreditSchema,
  podcastSchema,
  recurringCreditSchema,
  roleSchema,
  suggestedCreditSchema,
};
