import Loadable from "@loadable/component";
const VerifyCreatorModal = Loadable(() => import("./VerifyCreatorModal"));

function AboutAsyncLoading() {
  return null;
}

const VerifyCreatorModalContainer = (props) => {
  return <VerifyCreatorModal {...props} fallback={<AboutAsyncLoading />} />;
};

export default VerifyCreatorModalContainer;
