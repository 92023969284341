export const selectSpecificCategory = (state, slug) =>
  state.category &&
  state.category.getIn(["categories", "entities", slug?.toString()]);

export const selectCategoryLoading = (state, slug) =>
  state.category &&
  state.category.getIn(["categories", "loading", slug?.toString()]);
export const selectCategoryFailed = (state, slug) =>
  state.category &&
  state.category.getIn(["categories", "failed", slug?.toString()]);

export const selectSpecificTag = (state, slug) =>
  state.tag && state.tag.getIn(["tags", "entities", slug?.toString()]);

export const selectTagLoading = (state, slug) =>
  state.tag && state.tag.getIn(["tags", "loading", slug?.toString()]);
