import useFrontendSetting from "hooks/useFrontendSetting";
import { useLoggedIn } from "hooks/useLoggedInUser";

const useSiteTourDismissed = (settingID, loggedInOnly = true) => {
  const isLoggedIn = useLoggedIn();

  const [dismissedSiteTour, setDismissedSiteTour] = useFrontendSetting(
    `podchaser:site_tour_dismissed:${settingID}`,
    false
  );

  const hideTour = dismissedSiteTour || (loggedInOnly && !isLoggedIn);

  return {
    dismissedSiteTour,
    setDismissedSiteTour,
    hideTour,
  };
};

export default useSiteTourDismissed;
