import mixpanel from "mixpanel-browser";
import { useCallback, useEffect, useState } from "react";

import client from "api/live/client";

const TEN_MINUTES = 600000;

function UserIpTracker() {
  const [ipAddress, setIpAddress] = useState();

  const fetchIpAddress = useCallback(() => {
    client
      .get("/user/ipAddress")
      .then((response) => {
        const newIpAddress = response?.data?.ipAddress;
        if (newIpAddress && newIpAddress !== ipAddress) {
          setIpAddress(newIpAddress);
        }
      })
      .catch(function (error) {
        console.error("Ip Address Request Error", error);
      });
  }, [ipAddress]);

  useEffect(() => {
    if (!document.hidden) {
      fetchIpAddress();
    }
  }, [fetchIpAddress]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!document.hidden) {
        fetchIpAddress();
      }
    }, TEN_MINUTES);

    return () => clearInterval(intervalId);
  }, [fetchIpAddress]);

  useEffect(() => {
    if (window.mixpanel && ipAddress) {
      mixpanel.register({ ip_address: ipAddress });
    }
  }, [ipAddress]);

  return null;
}

export default UserIpTracker;
