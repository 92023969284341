import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { Fragment, useCallback, useMemo } from "react";

import { useEntityFromTimeline } from "../useEntityFromTimeline";
import Activity from "./Activity/ActivityAsync";

import { entityNames } from "constants/entity";
import { selectSpecificUser } from "selectors/user";
import indefiniteArticle from "utils/text/indefiniteArticle";

import useReduxState from "hooks/useReduxState";
import { useStyles } from "hooks/useStyles";

import cardStyles from "styles/CardStyles";
import { feedColours as feedTextColours } from "styles/feedStyles";

const baseStyles = {
  verbText: {
    ...cardStyles.verbText,
    color: feedTextColours.feedFollowText || "#000",
  },
};

const FollowActivity = (props) => {
  const { activity, mobile } = props;
  const { styles } = useStyles(baseStyles, props);

  const [followedEntityType] = activity.get("object").split(":");
  const [actorType] = activity.get("actor").split(":");

  const { entity: actor } = useEntityFromTimeline(activity.get("actor"));
  const [type] = activity.get("object").split(":");
  const { entity: followedEntity } = useEntityFromTimeline(
    activity.get("object")
  );
  const entityUser = useReduxState(
    (state) =>
      selectSpecificUser(
        state,
        followedEntity
          ? followedEntity.get("user") || followedEntity.getIn(["owners", 0])
          : ""
      ),
    [followedEntity]
  );

  const isUserType = entityUser && (type === "review" || type === "userlist");

  const filterEntities = useMemo(
    () => ({
      [activity.get("object").split(":")[0]]: followedEntity,
    }),
    [activity, followedEntity]
  );

  const typeName = useMemo(() => {
    if (isUserType) {
      return `'s ${followedEntity.get("entity_type")} ${entityNames[type]}`;
    }

    const entityType = entityNames[type];

    return `${indefiniteArticle(entityType)} ${entityType}`;
  }, [type, followedEntity, isUserType]);

  if (!followedEntity) {
    console.warn("No followed entity, object", activity);
  }

  const renderIntroText = useCallback(
    ({ renderEntityLink }) => (
      <Fragment>
        {renderEntityLink(actorType, actor)}{" "}
        <span className={css(styles.verbText)}>followed</span>{" "}
        {isUserType && renderEntityLink("user", entityUser)}
        {typeName}
      </Fragment>
    ),
    [actor, actorType, typeName, entityUser, isUserType, styles.verbText]
  );

  const renderIntroImage = useCallback(
    ({ renderEntityImage }) => renderEntityImage(actorType, actor),
    [actor, actorType]
  );

  const sections = useMemo(
    () => [
      {
        type: "intro",
        renderImage: renderIntroImage,
        renderText: renderIntroText,
        highlightType: "Follow",
      },
      {
        type: "entity",
        entityCardProps: {
          entity_type: followedEntityType,
          entity: followedEntity,
          mobile,
        },
      },
    ],
    [
      renderIntroImage,
      renderIntroText,
      mobile,
      followedEntityType,
      followedEntity,
    ]
  );

  if (!activity) {
    console.warn("No activity in FollowActivity");
    return null;
  }

  if (!followedEntity) {
    return null;
  }

  return (
    <Activity
      {...props}
      sections={sections}
      loaded={!!(actor && followedEntity)}
      filterEntities={filterEntities}
    />
  );
};

FollowActivity.propTypes = {
  activity: PropTypes.instanceOf(Map),
  mobile: PropTypes.bool,
};

FollowActivity.defaultProps = {
  activity: Map(),
  mobile: false,
};

export default FollowActivity;
