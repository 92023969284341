import { css, StyleSheet } from "aphrodite";
import PropTypes from "prop-types";
import { Children } from "react";

import normalize from "styles/normalize";

const styles = StyleSheet.create({
  nav: {
    right: "0",
    position: "relative",
  },
  box: {
    position: "absolute",
    display: "block",
    width: "10em",
    height: "10em",
    left: "0",
    top: "0",
  },
  ul: {
    ...normalize.ul,
    position: "relative",
    margin: "0",
    listStyle: "none",
    height: "100%",
    display: "block",
    width: "100%",
    textAlign: "right",
  },
  li: {
    margin: 0,
    verticalAlign: "middle",
    display: "inline-block",
    listStyle: "none",
    textAlign: "right",
  },
});

const UserContainer = (props) => {
  const { children } = props;

  return (
    <nav id="user-menu" className={css([styles.nav])} data-id="user-container">
      <ul className={css([[styles.ul]])}>
        {Children.map(
          children,
          (child) => child && <li className={css([styles.li])}>{child}</li>
        )}
      </ul>
    </nav>
  );
};

UserContainer.propTypes = {
  children: PropTypes.node,
};

UserContainer.defaultProps = {
  children: null,
};

export default UserContainer;
