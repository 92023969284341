import { TRANSPARENT_IMAGE } from "../constants/app";
import { maybeRemToPx } from "./styles";

import { MISSING_IMAGE_URL, MISSING_PROFILE_IMAGE_URL } from "constants/base";
import cachedImage from "utils/entity/cachedImage";

export function isDataURL(str) {
  if (!str || !str.match) {
    return false;
  }

  const regex =
    /^\s*data:([a-z]+\/[a-z]+(;[a-z-]+=[a-z-]+)?)?(;base64)?,[a-z0-9!$&',()*+;=\-._~:@/?%\s]*\s*$/i;

  return !!str.match(regex);
}

export const getCachedUrl = (src, width, height, options) => {
  if (src === MISSING_IMAGE_URL || src === MISSING_PROFILE_IMAGE_URL) {
    return src;
  }
  if (!src || typeof src !== "string") {
    return MISSING_IMAGE_URL;
  }
  const alreadyCached = src.indexOf("cachedimages.podchaser.com") !== -1;
  if (alreadyCached) {
    return src.replace(
      /podchaser.com\/\d*x\d*/,
      `podchaser.com/${width}x${height || width}`
    );
  }

  return cachedImage(src, width, height, options);
};

export function getSrcUrls(src, width, height, options = { cached: true }) {
  return {
    pre:
      !options || (options.cached && options.fuzzyPlaceholder)
        ? getCachedUrl(
            src,
            Math.round(maybeRemToPx(width || height || options.size) / 10, 0),
            Math.round(maybeRemToPx(height || width || options.size) / 10, 0)
          )
        : TRANSPARENT_IMAGE,
    regular:
      !options || options.cached
        ? getCachedUrl(src, maybeRemToPx(width || height) || 128, null, {
            disableMissingImage: options && options.disableMissingImage,
            ...(options && options.imageCacheOptions),
          })
        : src,
    retina: options.cached
      ? getCachedUrl(
          src,
          (maybeRemToPx(width || height || options.size) || 128) * 2,
          null,
          {
            disableMissingImage: options && options.disableMissingImage,
            ...(options && options.imageCacheOptions),
          }
        )
      : src,
  };
}
