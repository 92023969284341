import Loadable from "@loadable/component";
import React from "react";

import LoadableLoading from "components/Common/LoadableLoading";

const WatchListViewContainer = Loadable(() =>
  import("./WatchListViewContainer")
);

function WatchlistViewAsyncLoading(props) {
  return <LoadableLoading {...props} />;
}

const WatchListViewContainerContainer = (props) => {
  return (
    <WatchListViewContainer
      {...props}
      fallback={<WatchlistViewAsyncLoading />}
    />
  );
};

export default WatchListViewContainerContainer;
