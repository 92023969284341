import { Map } from "immutable";
import PropTypes from "prop-types";

import CreatorProfileHoverPopover from "./CreatorProfileHoverPopoverAsync";
import ListProfileHoverPopover from "./ListProfileHoverPopoverAsync";
import PodcastProfileHoverPopover from "./PodcastProfileHoverPopoverAsync";
import UserProfileHoverPopover from "./UserProfileHoverPopoverAsync";

const ProfilePopover = (props) => {
  const { entity_type, entity, mobile, children, inline, expand, entity_id } =
    props;

  const popoverProps = {
    mobile,
    inline,
    expand,
    entity_id,
  };

  if (mobile) {
    // temporarily removes popover on mobile
    return children;
  }

  if (entity_type === "podcast") {
    return (
      <PodcastProfileHoverPopover {...popoverProps} podcast={entity}>
        {children}
      </PodcastProfileHoverPopover>
    );
  }

  if (entity_type === "userlist") {
    return (
      <ListProfileHoverPopover {...popoverProps} list={entity}>
        {children}
      </ListProfileHoverPopover>
    );
  }

  if (entity_type === "creator") {
    if (entity && !entity.get("confirmed")) {
      return children;
    }

    return (
      <CreatorProfileHoverPopover {...popoverProps} creator={entity}>
        {children}
      </CreatorProfileHoverPopover>
    );
  }

  return (
    <UserProfileHoverPopover {...popoverProps} user={entity}>
      {children}
    </UserProfileHoverPopover>
  );
};

ProfilePopover.propTypes = {
  entity_type: PropTypes.string,
  entity_id: PropTypes.number,
  entity: PropTypes.instanceOf(Map),
  mobile: PropTypes.bool,
  children: PropTypes.node,
  inline: PropTypes.bool,
  expand: PropTypes.bool,
};

ProfilePopover.defaultProps = {
  entity_type: null,
  entity_id: null,
  entity: null,
  mobile: false,
  children: null,
  inline: false,
  expand: false,
};

export default ProfilePopover;
