import { is, isImmutable } from "immutable";
import deepEqual from "ramda/src/equals";

export const isType = (val, type) => typeof val === type;

export default function isEqual(valA, valB) {
  if (valA === valB) {
    return true;
  }
  if (isImmutable(valA) || isImmutable(valB)) {
    return is(valA, valB);
  }

  return deepEqual(valA, valB);
}
