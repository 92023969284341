import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const AuthModalContainer = Loadable(() => import("./AuthModalContainer"));

function AuthAsyncLoading(props) {
  return <LoadableLoading {...props} />;
}

const AuthModalContainerContainer = (props) => {
  return <AuthModalContainer {...props} fallback={<AuthAsyncLoading />} />;
};

export default AuthModalContainerContainer;
