import PropTypes from "prop-types";
import { memo, useCallback, useEffect, useRef, useState } from "react";

function LazyHydrate(props) {
  const { whenVisible, children, onHydrate, height, className, offset } = props;

  const [metRenderCondition, setMetRenderCondition] = useState(false);

  const [checkedSSR, setCheckedSSR] = useState(false);
  const [renderedPlaceholder, setRenderedPlaceholder] = useState(false);
  const hasSsrContent = useRef(false);
  const placeholderElement = useRef(null);

  const refChanged = useCallback((node) => {
    if (node) {
      hasSsrContent.current = node.children.length > 0;
      placeholderElement.current = node;
      setRenderedPlaceholder(true);
      setCheckedSSR(true);
    }
  }, []);

  const placeholderRefChanged = useCallback((node) => {
    if (node) {
      placeholderElement.current = node;
      setRenderedPlaceholder(true);
    }
  }, []);

  const io = useRef(
    typeof IntersectionObserver !== "undefined"
      ? new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (
                entry.target === placeholderElement.current &&
                (entry.isIntersecting || entry.intersectionRatio > 0)
              ) {
                setMetRenderCondition(true);
              }
            });
          },
          { rootMargin: `${offset}px 0px`, threshold: 0 }
        )
      : null
  );

  useEffect(() => {
    const ioVal = io.current;
    // As root node does not have any box model, it cannot intersect.
    let elVal;

    if (!renderedPlaceholder) {
      return undefined;
    }
    if (placeholderElement.current) {
      elVal = placeholderElement.current;
    }
    if (whenVisible && !metRenderCondition) {
      if (ioVal && elVal) {
        ioVal.observe(elVal);
      }
    }

    return () => {
      if (whenVisible && ioVal && elVal) {
        ioVal.unobserve(elVal);
      }
    };
  }, [metRenderCondition, renderedPlaceholder, whenVisible]);

  useEffect(() => {
    if (hasSsrContent.current && metRenderCondition && onHydrate) {
      onHydrate();
    }
  }, [metRenderCondition, onHydrate]);

  // first render we assume it has SSR content so we can measure it
  if (!checkedSSR || (!metRenderCondition && hasSsrContent.current)) {
    const style = {
      display: "block",
      ...(height === null ? {} : { minHeight: height }),
    };
    return (
      <div
        ref={refChanged}
        style={style}
        className={className}
        suppressHydrationWarning
        dangerouslySetInnerHTML={{ __html: "" }}
      />
    );
  }

  // if no ssr content, render a placeholder if it's not to be rendered yet
  if (!metRenderCondition && !hasSsrContent.current) {
    return (
      <div
        ref={placeholderRefChanged}
        data-placeholder="true"
        className={className}
        {...(height === null ? {} : { style: { minHeight: height } })}
      />
    );
  }

  return children;
}

LazyHydrate.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  offset: PropTypes.number,
  className: PropTypes.string,
  whenVisible: PropTypes.bool,
  children: PropTypes.node,
  onHydrate: PropTypes.func,
};

LazyHydrate.defaultProps = {
  height: 100,
  offset: 100,
  className: undefined,
  whenVisible: false,
  children: null,
  onHydrate: null,
};

export default memo(LazyHydrate);
