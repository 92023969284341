import Loadable from "@loadable/component";

import TimelineSkeletonItem from "components/Timeline/TimelineSkeletonItem";

const ReviewActivity = Loadable(() =>
  import(/* webpackChunkName: "ReviewActivity" */ "./ReviewActivity")
);

function ReviewActivityLoading(props) {
  return <TimelineSkeletonItem />;
}

const ReviewActivityContainer = (props) => {
  return <ReviewActivity {...props} fallback={<ReviewActivityLoading />} />;
};

export default ReviewActivityContainer;
