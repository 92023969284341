import { loadEpisodesOfEpisodeGroup } from "routines/podcast";

import * as constants from "constants/podcast";

export const loadSpecificPodcast = (
  podcast_id,
  { throwErrorIfUnSuccessful = false } = {}
) => ({
  type: constants.LOAD_SPECIFIC_PODCASTS_REQUEST,
  podcast_id,
  throwErrorIfUnSuccessful,
});

export const loadSpecificEpisode = (
  episode_id,
  { throwErrorIfUnSuccessful = false } = {}
) => ({
  type: constants.LOAD_SPECIFIC_EPISODE_REQUEST,
  episode_id,
  throwErrorIfUnSuccessful,
});

export const loadSpecificUserlistItem = (id, entity_type) => {
  if (entity_type === "episode") {
    return loadSpecificEpisode(id);
  }

  return loadSpecificPodcast(id);
};

const podcastActions = {
  loadSpecificPodcast,
  loadSpecificEpisode,
  loadSpecificUserlistItem,
  loadSpecificEntity: (entity_id, entity_type) => {
    if (entity_type === "podcast") {
      return loadSpecificPodcast(entity_id);
    }
    if (entity_type === "episode") {
      return loadSpecificEpisode(entity_id);
    }

    return null;
  },
  changeFormat: (format) => ({
    type: constants.SET_PODCAST_FORMAT,
    format,
  }),
  addListPlaceholders: (entities, listId) => ({
    type: constants.ADD_LIST_PLACEHOLDERS,
    entities,
    listId,
  }),
  rateItem: (entity_type, entity_id, rating, entity) => ({
    type: constants.RATE_ITEM_REQUEST,
    entity_type,
    entity_id,
    rating,
    entity,
  }),
  ratePodcast: (podcast_id, rating) => ({
    type: constants.RATE_ITEM_REQUEST,
    entity_type: "podcast",
    entity_id: podcast_id,
    rating,
  }),
  openReviewPodcastModal: (entity_type, entity_id) => ({
    type: constants.OPEN_ITEM_REVIEW_MODAL,
    entity_type,
    entity_id,
  }),
  submitReview: (entity_type, entity_id, review_text, entity) => ({
    type: constants.REVIEW_SUBMIT_REQUEST,
    entity_type,
    entity_id,
    review_text,
    entity,
  }),
  deleteReview: (entity_type, entity_id) => ({
    type: constants.REVIEW_DELETE_REQUEST,
    entity_type,
    entity_id,
  }),
  cancelReview: (entity_type, entity_id) => ({
    type: constants.REVIEW_CANCELLED,
    entity_type,
    entity_id,
  }),
  rateEpisode: (episode_id, rating, promptForReview) => ({
    type: constants.RATE_ITEM_REQUEST,
    entity_type: "episode",
    entity_id: episode_id,
    rating,
    promptForReview,
  }),
  proNoteChange: (podcast_id, content) => ({
    type: constants.PRO_NOTE_CHANGE,
    payload: {
      entity_type: "podcast",
      entity_id: podcast_id,
      content,
    },
  }),
  loadEpisodesOfEpisodeGroup: ({
    podcastId,
    year,
    sort_direction,
    filters,
  }) => ({
    type: loadEpisodesOfEpisodeGroup.TRIGGER,
    payload: {
      podcastId,
      year,
      sort_direction,
      filters,
    },
  }),
};

export default podcastActions;
