import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const HeaderUser = Loadable(() => import("./HeaderUser"));

function HeaderUserAsyncLoading(props) {
  return <LoadableLoading {...props} />;
}

const HeaderUserContainer = (props) => {
  return <HeaderUser {...props} fallback={<HeaderUserAsyncLoading />} />;
};

export default HeaderUserContainer;
