import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { forwardRef, memo, Fragment, useMemo } from "react";

import ImageFallback from "components/Global/Images/ImageFallback";

import isBorderedEntityType from "../../utils/entity/isBorderedEntityType";
import EntityLink from "./EntityLink";
import ListImage from "./Images/ListImageAsync";
import NetworkImage from "./Images/NetworkImageAsync";

import { MISSING_IMAGE_URL, MISSING_PROFILE_IMAGE_URL } from "constants/base";
import { selectSpecificPodcast } from "selectors/podcast";
import { getEntityDefaultColor } from "utils/colours";
import cachedImage from "utils/entity/cachedImage";
import getEntityImageTitle, {
  getEntityImageAlt,
} from "utils/entity/getEntityImageTitle";
import getEntityImageUrl from "utils/entity/getEntityImageUrl";
import isCircularEntityType from "utils/entity/isCircularEntityType";
import { getSrcUrls } from "utils/images";
import { maybeRemToPx } from "utils/styles";

import useReduxState from "hooks/useReduxState";
import { useStyles } from "hooks/useStyles";

const baseStyles = {
  entityImage: {
    position: "relative",
    background: "rgba(0,0,0,0.04)",
    display: "block",
    transform: "translate3d(0, 0, 0)",
    backfaceVisibility: "hidden",
  },
  entityImageWithoutBackground: {
    background: "none",
  },
  entityImageOnDark: {
    background: "#fff",
  },
  entityImageSquare: {
    // Fills the square
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  entityImageBackgroundCircular: {
    borderRadius: "50%",
    overflow: "hidden",
  },
  entityLoaded: {},
  link: {
    ":focus": {
      opacity: 0.8,
    },
  },
  insetShadow: {
    position: "absolute",
    width: "100%",
    height: "100%",
    boxShadow: "inset 0 0 2px 0 rgba(0,0,0,0.4)",
    top: 0,
    left: 0,
    pointerEvents: "none",
  },
  insetShadowRound: {
    borderRadius: "50%",
  },
  contentWithChildren: {
    position: "relative",
    background: "rgba(0,0,0,0.04)",
    width: "100%",
    aspectRatio: "1 / 1",
  },
};

const blockStyles = {
  // This may need to be default to ensure :focus opacity change works
  link: {
    display: "block",
  },
};

const EntityImage = forwardRef((props, ref) => {
  const {
    entity_type,
    size: passedSize,
    cacheSize,
    width,
    height,
    borderRadius: passedBorderRadius,
    disableMissingImage,
    href,
    disableLink,
    showTitle,
    externalLink,
    disablePopup,
    fullWidth,
    onDark,
    imageCacheOptions,
    renderEntityImageOverlay,
    insetShadow: passedInsetShadow,
    imgProps,
    disableAnimation,
    block,
    className,
    profileImage,
    image_url,
    id,
    alt: passedAlt,
    title: passedTitle,
    square,
    flat,
    children,
    onClick,
    entity: passedEntity,
    maxBorderRadius,
    useImgElement,
  } = props;

  const insetShadow =
    passedInsetShadow === undefined
      ? isBorderedEntityType(entity_type)
      : passedInsetShadow;

  let entity = passedEntity;
  const entity_id = entity ? entity.get("id") : id;
  const isCircularImage =
    !square && (isCircularEntityType(entity_type) || profileImage);

  const size = useMemo(
    () => passedSize || width || height || cacheSize,
    [passedSize, width, height, cacheSize]
  );
  const pxSize = maybeRemToPx(size) || 128;

  let borderRadius =
    passedBorderRadius === undefined
      ? Math.round((pxSize || 128) / 20)
      : passedBorderRadius;
  borderRadius = Math.min(
    maxBorderRadius === undefined ? 9999 : maxBorderRadius,
    borderRadius
  );

  const imageFallbackStyles = useMemo(() => {
    let fallStyles = {
      imageFallback: {
        background:
          entity_type === "userlist" || entity_type === "integration"
            ? "transparent"
            : "rgba(0,0,0,0.04)",
        display: "block",
        width: "100%",
        height: "100%",
        borderRadius: isCircularImage ? "50%" : borderRadius,
        overflow: "hidden",
      },
    };

    if (profileImage || entity_type === "user" || entity_type === "creator") {
      fallStyles = {
        imageFallback: {
          ...fallStyles.imageFallback,
          width: fullWidth ? "100%" : size || 256,
          height: fullWidth ? "auto" : size || "auto",
        },
      };

      if (!image_url) {
        const colors = getEntityDefaultColor(entity_id);

        fallStyles = {
          imageFallback: {
            ...fallStyles.imageFallback,
            backgroundColor: `rgba(${colors.r},${colors.g},${colors.b})`,
          },
        };
      }
    }

    return fallStyles;
  }, [
    entity_id,
    entity_type,
    fullWidth,
    image_url,
    size,
    profileImage,
    isCircularImage,
    borderRadius,
  ]);

  const entityStyles = useMemo(() => {
    const entityStyles = {
      entityImage: {
        borderRadius: isCircularImage ? "50%" : borderRadius,
      },
      insetShadow: {
        borderRadius: isCircularImage ? "50%" : borderRadius,
      },
      contentWithChildren: {
        borderRadius: isCircularImage ? "50%" : borderRadius,
        overflow: "hidden",
      },
    };
    if (entity_type === "integration" || entity_type === "userlist") {
      entityStyles.entityImage = {
        ...entityStyles.entityImage,
        backgroundColor: "transparent",
      };
    }
    if (!fullWidth && size) {
      entityStyles.entityImageContainer = {
        ...entityStyles.entityImageContainer,
        width: size,
      };
    }
    return entityStyles;
  }, [borderRadius, entity_type, fullWidth, isCircularImage, size]);

  const { styles } = useStyles(
    [baseStyles, entityStyles, block && blockStyles],
    props
  );

  const nestChildrenInContainer =
    children && (insetShadow || renderEntityImageOverlay);
  const containerClasses = useMemo(
    () => [
      styles.entityImage,
      onDark && styles.entityImageOnDark,
      // fullWidth && (square || isCircularEntityType(entity_type)) && styles.entityImageSquare,
      isCircularImage && styles.entityImageBackgroundCircular,
      nestChildrenInContainer && styles.entityImageWithoutBackground,
    ],
    [
      isCircularImage,
      onDark,
      nestChildrenInContainer,
      styles.entityImage,
      styles.entityImageOnDark,
      styles.entityImageBackgroundCircular,
      styles.entityImageWithoutBackground,
    ]
  );

  const podcast = useReduxState(
    (state) =>
      selectSpecificPodcast(state, entity ? entity.get("podcast_id") : null),
    [entity]
  );

  if (podcast) {
    entity = entity.set("podcast", podcast);
  }

  const { src, alt, title } = useMemo(() => {
    if (entity) {
      return {
        src: getEntityImageUrl(entity, entity_type),
        alt: passedAlt || getEntityImageAlt(entity, entity_type),
        title: passedTitle || getEntityImageTitle(entity, entity_type),
      };
    }

    return {
      src: profileImage
        ? image_url || MISSING_PROFILE_IMAGE_URL
        : image_url || MISSING_IMAGE_URL,
      alt: passedAlt,
      title: passedTitle,
    };
  }, [entity, entity_type, passedAlt, passedTitle, image_url, profileImage]);

  const cachedSrc = useMemo(() => {
    if (src === MISSING_IMAGE_URL || src === MISSING_PROFILE_IMAGE_URL) {
      return src;
    }

    return cachedImage(src, pxSize * 2, null, {
      disableMissingImage,
      ...imageCacheOptions,
    });
  }, [src, pxSize, disableMissingImage, imageCacheOptions]);

  const cached = true;
  const fuzzyPlaceholder = true;

  const srcUrls = useMemo(
    () =>
      getSrcUrls(src, pxSize, pxSize, {
        cached,
        fuzzyPlaceholder,
        disableMissingImage,
        imageCacheOptions,
      }),
    [
      src,
      pxSize,
      cached,
      fuzzyPlaceholder,
      disableMissingImage,
      imageCacheOptions,
    ]
  );

  const renderWithContainers = (content) => {
    const main = (
      <Fragment>
        {content}
        {renderEntityImageOverlay && renderEntityImageOverlay(entity)}
        {insetShadow && (
          <span
            className={css(
              styles.insetShadow,
              isCircularImage && styles.insetShadowRound
            )}
          />
        )}
        {children && !insetShadow && !renderEntityImageOverlay
          ? children
          : null}
      </Fragment>
    );

    if (nestChildrenInContainer) {
      return (
        <Fragment>
          <div className={css(styles.contentWithChildren)}>{main}</div>
          {children}
        </Fragment>
      );
    }

    return main;
  };

  if (entity_type === "network" && !image_url) {
    return (
      <NetworkImage
        pxSize={pxSize}
        disablePopup={disablePopup}
        className={className}
        renderWithContainers={renderWithContainers}
        entity={entity}
        containerClasses={containerClasses}
        ref={ref}
      />
    );
  }

  if (entity_type === "userlist" && !flat) {
    return (
      <ListImage
        pxSize={pxSize}
        disablePopup={disablePopup}
        className={className}
        renderWithContainers={renderWithContainers}
        entity={entity}
        containerClasses={containerClasses}
        image_url={image_url}
        ref={ref}
      />
    );
  }

  const image = (
    <ImageFallback
      ref={ref}
      styles={imageFallbackStyles}
      src={cachedSrc}
      srcs={srcUrls}
      alt={alt}
      title={showTitle ? title : null}
      animate={!disableAnimation}
      width={pxSize}
      height={pxSize}
      imgType={profileImage || isCircularImage ? "person" : undefined}
      useImgElement={useImgElement}
      {...imgProps}
    />
  );

  if (href) {
    return (
      <a
        className={`${css(...containerClasses, styles.link)} ${className}`}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={title}
      >
        {renderWithContainers(image)}
      </a>
    );
  }

  if (disableLink) {
    return (
      <div className={`${css(styles.entityImage)} ${className}`}>
        {renderWithContainers(image)}
      </div>
    );
  }

  return (
    <EntityLink
      className={`${css(...containerClasses, styles.link)} ${className}`}
      entity={entity}
      entity_type={entity_type}
      disablePopup={disablePopup}
      block={fullWidth}
      expand={fullWidth}
      profileImage={profileImage}
      isImage
      onClick={onClick}
      externalLink={externalLink}
    >
      {renderWithContainers(image)}
    </EntityLink>
  );
});

EntityImage.propTypes = {
  entity: PropTypes.instanceOf(Map),
  entity_type: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disableMissingImage: PropTypes.bool,
  href: PropTypes.string,
  disableLink: PropTypes.bool,
  showTitle: PropTypes.bool,
  externalLink: PropTypes.bool,
  disablePopup: PropTypes.bool,
  fullWidth: PropTypes.bool,
  imageCacheOptions: PropTypes.object,
  onDark: PropTypes.bool,
  renderEntityImageOverlay: PropTypes.func,
  insetShadow: PropTypes.bool,
  imgProps: PropTypes.object,
  disableAnimation: PropTypes.bool,
  block: PropTypes.bool,
  className: PropTypes.string,
  profileImage: PropTypes.bool,
  image_url: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  alt: PropTypes.node,
  title: PropTypes.node,
  square: PropTypes.bool,
  flat: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
  cacheSize: PropTypes.number,
  borderRadius: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxBorderRadius: PropTypes.number,
};

EntityImage.defaultProps = {
  entity: null,
  size: null,
  width: null,
  height: null,
  disableMissingImage: false,
  href: null,
  disableLink: false,
  showTitle: false,
  externalLink: false,
  disablePopup: false,
  fullWidth: true,
  imageCacheOptions: {},
  onDark: false,
  renderEntityImageOverlay: null,
  insetShadow: undefined,
  imgProps: {},
  disableAnimation: false,
  block: false,
  className: null,
  profileImage: false,
  image_url: null,
  id: null,
  alt: null,
  title: null,
  square: false,
  flat: false,
  children: null,
  onClick: undefined,
  cacheSize: undefined,
  borderRadius: undefined,
  maxBorderRadius: 8,
};

EntityImage.displayName = "EntityImage";

export default memo(EntityImage);
