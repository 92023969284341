import { badgeSchema } from "api/schema/badge";
import { creatorSchema, podcastSchema } from "api/schema/creator_podcast";
import { schema } from "normalizr";

import omit from "utils/objects/omit";

const userProcessStrategy = (value) => {
  if (value.featured_badge) {
    const valueWithoutKey = omit("featured_badge", value);

    return {
      ...valueWithoutKey,
      featuredBadge: {
        ...value.featuredBadge,
        id: value.featured_badge.badge_id,
        awarded_at: value.featured_badge.awarded_at,
      },
    };
  }
  return value;
};

export const userSchema = new schema.Entity(
  "users",
  {},
  { processStrategy: userProcessStrategy }
);

userSchema.define({
  top_podcasts: [podcastSchema],
  claimed_podcasts_summary: [podcastSchema],
  managed_creators_summary: [creatorSchema],
  creator: creatorSchema,
  featuredBadge: badgeSchema,
});
