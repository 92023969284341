import {
  CREDIT_SEARCH_KEY,
  PODCAST_SEARCH_KEY,
  EPISODES_SELECT_KEY,
} from "components/Creator/CreatorEditorNew/Context/RoutingKeys";
import CREDIT_TYPES from "components/Creator/CreatorEditorNew/Model/CreditTypes";

import getCreatorSlug from "utils/entity/getCreatorSlug";
import { formatURL } from "utils/format";

export const getCreatorUrl = (creator, tab = null) => {
  const creatorUrl = `/creators/${getCreatorSlug(creator)}${
    tab ? `/${tab}` : ""
  }`;

  return formatURL(creatorUrl);
};

function parseObjectToString(params) {
  if (!params) {
    return "";
  }

  return Object.keys(params).reduce(
    (string, key) => `${string}&${key}=${params[key]}`,
    ""
  );
}

export const getCreatorEditUrl = (
  creator,
  tab = "basic-info",
  params = null
) => {
  const paramsString = parseObjectToString(params);
  const editUrl = `/creators/edit/${getCreatorSlug(
    creator
  )}?tab=${tab}${paramsString}`;

  return formatURL(editUrl);
};

// TODO - Refactor this method and remove the getCreatorEditUrl
export const getCreatorEditUrlNew = (creator) => {
  const editUrl = `/creators/edit/${getCreatorSlug(creator)}`;

  return formatURL(editUrl);
};

export const getCreatorEditCreditsOverview = (creator) => {
  const editUrl = `/creators/edit/${getCreatorSlug(creator)}/credits-overview`;

  return formatURL(editUrl);
};

export const getCreatorEditSelectEpisodesUrlByCreatorSlug = (
  creatorSlug,
  podcastId,
  selectedEpisodes
) => {
  const paramsString = parseObjectToString({
    [CREDIT_SEARCH_KEY]: CREDIT_TYPES.CURRENT_CREDIT,
    [PODCAST_SEARCH_KEY]: podcastId,
    ...(selectedEpisodes ? { [EPISODES_SELECT_KEY]: selectedEpisodes } : {}),
  });

  return formatURL(
    `/creators/edit/${creatorSlug}/episode-selection?${paramsString}`
  );
};

export const getCreatorEditSelectPodcastUrl = (creator) => {
  const paramsString = parseObjectToString({
    [CREDIT_SEARCH_KEY]: CREDIT_TYPES.CURRENT_CREDIT,
  });

  return formatURL(
    `/creators/edit/${getCreatorSlug(creator)}/choose-podcast?${paramsString}`
  );
};
