import { memo, useCallback, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

import RequestContext from "pages/RequestContext";

import { sendPageView } from "utils/ga";

import useLoadScript from "hooks/useLoadScript";
import useReduxState from "hooks/useReduxState";

const SCRIPT_OPTIONS = { loadOnMount: true };

const loadAnalytics = () => {
  // eslint-disable-next-line no-param-reassign
  const scriptTag = document.createElement("script");
  const lastElement = document.getElementsByTagName("script")[0];
  scriptTag.defer = 1;
  scriptTag.src = "https://www.googletagmanager.com/gtag/js?id=G-545H1K4XT5";
  lastElement.parentNode.insertBefore(scriptTag, lastElement);

  window.dataLayer = window.dataLayer || [];

  function gtag() {
    window.dataLayer.push(arguments);
  }

  window.ga = gtag;
};

const gaID = "G-545H1K4XT5";

const GoogleLoader = () => {
  const location = useLocation();

  const requestContext = useContext(RequestContext);

  const user = useReduxState((state) => state.auth.getIn(["user"]), []);

  const loadScript = useCallback(() => {
    loadAnalytics();

    window.ga("js", new Date());
    window.ga("config", gaID);

    if (user) {
      window.ga("set", "userId", user.get("id"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLoadScript("google-analytics", loadScript, SCRIPT_OPTIONS);

  useEffect(() => {
    if (requestContext.environment === "production") {
      sendPageView({ location });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return null;
};

export default memo(GoogleLoader);
