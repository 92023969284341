import { useContext, useEffect, useLayoutEffect } from "react";

import RequestContext from "pages/RequestContext";

const useLayoutEffectOnServer = (callback, deps = undefined) => {
  const requestContext = useContext(RequestContext);
  const hook = requestContext.server ? useEffect : useLayoutEffect;

  hook(callback, deps);
};

export default useLayoutEffectOnServer;
