import { css } from "aphrodite";
import { List } from "immutable";
import PropTypes from "prop-types";
import { Fragment, useCallback, useMemo } from "react";

import { useEntityFromTimeline } from "components/Timeline/useEntityFromTimeline";

import AggregateActivity from "./Activity/AggregateActivity";
import {
  getActivitiesByActorOrObjectType,
  getActorLinks,
  getEntityTypeCounts,
  getShownByField,
  getUniqueFields,
  groupActivities,
} from "./Activity/AggregateUtils";
import FollowActivity from "./FollowActivity";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";

const baseStyles = {
  badgeTitle: {
    ...gStyles.fontBold,
    wordBreak: "break-word",
    ":focus": {
      textDecoration: "underline",
    },
  },
};

const RenderAggregated = (props) => {
  const { grouped, aggregateProps } = props;

  const { styles } = useStyles(baseStyles, props);

  const actors = getUniqueFields(grouped.activities, "actor");
  const objects = getUniqueFields(grouped.activities, "object");

  const { entity: actor } = useEntityFromTimeline(actors.first());
  const { entity: object } = useEntityFromTimeline(objects.first());

  let singleActor = null;
  let singleObject = null;

  let entity_type = null;
  let entity = null;
  let card_entity_type = null;
  let card_entity = null;

  if (grouped.groupedBy === "actor") {
    const [actor_type] = actors.first().split(":");

    entity_type = actor_type;
    entity = actor;
    singleActor = actor;
  } else if (grouped.groupedBy === "object") {
    const [object_type] = objects.first().split(":");

    entity_type = object_type;
    entity = object;
    card_entity_type = object_type;
    card_entity = {
      badge: object,
    };
    singleObject = object;
  }

  const followProps = {
    actors,
    objects,
    singleActor,
    singleObject,
    entity_type,
    entity,
    activities: grouped.activities,
  };

  const filterEntities = useMemo(
    () => ({
      [entity_type]: entity,
    }),
    [entity_type, entity]
  );

  const renderIntroText = useCallback(
    (introProps) => {
      if (followProps.singleObject) {
        const [object_type] = followProps.objects.first().split(":");

        const badgeTitle = (
          <span className={css(styles.badgeTitle)}>{object.get("title")}</span>
        );
        return (
          <Fragment>
            {introProps.renderEntityLink(
              object_type,
              followProps.singleObject,
              { content: badgeTitle }
            )}{" "}
            was earned by {getActorLinks(followProps.actors)}
          </Fragment>
        );
      }

      const [actor_type] = followProps.actors.first().split(":");
      const actorNames = followProps.singleActor
        ? introProps.renderEntityLink(actor_type, followProps.singleActor)
        : getActorLinks(followProps.actors);

      return (
        <Fragment>
          {actorNames} earned {getEntityTypeCounts(followProps.objects)}
        </Fragment>
      );
    },
    [
      followProps.actors,
      followProps.objects,
      followProps.singleActor,
      followProps.singleObject,
      object,
      styles.badgeTitle,
    ]
  );

  return (
    <AggregateActivity
      {...props}
      activities={grouped.activities}
      shownByField={getShownByField(aggregateProps)}
      renderIntroText={renderIntroText}
      entity_type={entity_type}
      entity={entity}
      card_entity_type={card_entity_type}
      card_entity={card_entity}
      highlightType="BadgeAwarded"
      loaded
      filterEntities={filterEntities}
      collapsable
    />
  );
};

RenderAggregated.propTypes = {
  grouped: PropTypes.object.isRequired,
  aggregateProps: PropTypes.object.isRequired,
};

const UserEarnedBadgeAggregate = (props) => {
  const { aggregateProps } = props;

  const filteredActivities = useMemo(
    () => getActivitiesByActorOrObjectType(aggregateProps),
    [aggregateProps]
  );

  const groupedActivities = useMemo(() => {
    const { grouped: actorGrouped, ungrouped: actorUngrouped } =
      groupActivities(filteredActivities.toArray(), "actor", "object");
    const { grouped: objectGrouped, ungrouped: objectUngrouped } =
      groupActivities(actorUngrouped, "object", "object");

    return [
      ...actorGrouped,
      ...objectGrouped,
      ...objectUngrouped.map((act) => ({
        activities: List([act]),
        groupedBy: "none",
      })),
    ];
  }, [filteredActivities]);

  const renderActivity = (activity, index) => (
    <FollowActivity {...props} key={index} activity={activity} />
  );

  return (
    <Fragment>
      {groupedActivities.map((grouped, index) => {
        if (grouped.groupedBy === "none") {
          return renderActivity(grouped.activities.first(), index);
        }

        const key = `${index}-${grouped.activities.first().get("id")}`;

        return <RenderAggregated {...props} key={key} grouped={grouped} />;
      })}
    </Fragment>
  );
};

UserEarnedBadgeAggregate.propTypes = {
  aggregateProps: PropTypes.object.isRequired,
};

export default UserEarnedBadgeAggregate;
