import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const ModalConfirmationAction = Loadable(() =>
  import("./ModalConfirmationAction")
);

function ModalConfirmationActionAsyncLoading(props) {
  return <LoadableLoading {...props} height="12.125rem" />;
}

const ModalConfirmationActionContainer = (props) => {
  return (
    <ModalConfirmationAction
      {...props}
      fallback={<ModalConfirmationActionAsyncLoading />}
    />
  );
};

export default ModalConfirmationActionContainer;
