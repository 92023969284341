import { useCallback, useEffect, useRef } from "react";

import useList from "./useList";

import paginationActions from "actions/pagination";
import isEqual from "utils/isEqual";
import { getPaginationListKeys } from "utils/pagination";

import useActionCreators from "hooks/useActionCreators";
import useQuery from "hooks/useQuery";

const DEFAULT_PAGE_SIZE = 25;

// eslint-disable-next-line no-unused-vars
function useLoadListEffect(listKey, initListOptions) {
  const [query] = useQuery();

  const listState = useList(listKey);
  const { list, currentPage } = listState;

  const { initList } = useActionCreators({
    initList: paginationActions.initList,
  });

  const listOptions = useRef(initListOptions);
  const listOptionsChanged = !isEqual(initListOptions, listOptions.current);

  // not ideal, but until I can figure out why this hook gets called twice before the list is updated to 'loading' we need to do a deep comparison
  if (listOptionsChanged) {
    listOptions.current = initListOptions;
  }

  const pageSize = initListOptions?.pageSize || DEFAULT_PAGE_SIZE;

  const doInit = useCallback(() => {
    initList({
      key: listKey,
      ...getPaginationListKeys({ query }, pageSize, { allPaginated: true }),
      ...listOptions.current,
    });
  }, [initList, listKey, pageSize, query]);

  useEffect(() => {
    // TODO: why is this effect loading multiple times?
    const queryChange = currentPage !== parseInt(query?.page ?? 1, 10);

    if (
      !initListOptions.doNotLoad &&
      !list.get("loading") &&
      (!list.get("loaded") || queryChange || listOptionsChanged)
    ) {
      doInit();
    }
  }, [
    currentPage,
    doInit,
    initListOptions.doNotLoad,
    list,
    listOptionsChanged,
    query?.page,
  ]);

  return listState;
}

export default useLoadListEffect;
