import Loadable from "@loadable/component";
const VerifyEmailModal = Loadable(() => import("./VerifyEmailModal"));

function AboutAsyncLoading() {
  return null;
}

const VerifyEmailModalContainer = (props) => {
  return <VerifyEmailModal {...props} fallback={<AboutAsyncLoading />} />;
};

export default VerifyEmailModalContainer;
