import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const FilterSelectModal = Loadable(() => import("./FilterSelectModal"));

function FilterSelectModalAsyncLoading(props) {
  return <LoadableLoading {...props} />;
}

const FilterSelectModalContainer = (props) => {
  return (
    <FilterSelectModal
      {...props}
      fallback={<FilterSelectModalAsyncLoading />}
    />
  );
};

export default FilterSelectModalContainer;
