import { useCallback } from "react";

import NavDropdownMenu, {
  defaultMenuStyles,
} from "./NavMenuDropdownItem/DropdownNavMenu";
import useToolNavLinks from "./useToolNavLinks";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  toolsNavMenu: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "51rem",

    [ScreenSizes.lgAndAbove]: {
      flexDirection: "row",
    },
  },
  toolsNavMenuDivider: {
    backgroundColor: colours.greyBorder,
    minHeight: "1px",
    width: "calc(100% - 2.5rem)",
    margin: "0 1.25rem",

    [ScreenSizes.lgAndAbove]: {
      minHeight: "calc(100% - 2rem)",
      width: "1px",
      margin: "1rem 0",
    },
  },
  submenuHeader: {
    ...gStyles.fontSemiBold,
    fontSize: ".75rem",
    letterSpacing: "4.95px",
    color: colours.menuHeader,
    margin: "1.5rem 1.5rem 0",
  },
};

const leftMenuStyles = {
  ...defaultMenuStyles,
  menuItem: {
    ...defaultMenuStyles.menuItem,
    paddingRight: "1.5rem",
  },
};

const ToolsNavMenu = (props) => {
  const { styles, css } = useStyles(baseStyles, props);
  const { podcasterLinks, brandLinks } = useToolNavLinks();

  const renderBrandHeader = useCallback(
    () => <h4 className={css(styles.submenuHeader)}>FOR BUSINESS</h4>,
    [css, styles.submenuHeader]
  );

  const renderPodcasterHeader = useCallback(
    () => <h4 className={css(styles.submenuHeader)}>FOR PODCASTERS</h4>,
    [css, styles.submenuHeader]
  );

  return (
    <div className={css(styles.toolsNavMenu)}>
      <NavDropdownMenu
        {...props}
        renderHeader={renderBrandHeader}
        menuItems={brandLinks}
        menuStyles={leftMenuStyles}
        asLink
      />
      <div className={css(styles.toolsNavMenuDivider)} />
      <NavDropdownMenu
        {...props}
        renderHeader={renderPodcasterHeader}
        menuStyles={leftMenuStyles}
        menuItems={podcasterLinks}
        asLink
      />
    </div>
  );
};

export default ToolsNavMenu;
