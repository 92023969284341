import { css } from "aphrodite";

import TimelineSkeletonItemBody from "./TimelineSkeletonItemBody";
import TimelineSkeletonItemTitle from "./TimelineSkeletonItemTitle";

import { useStyles } from "hooks/useStyles";

import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  activitySkeletonContainer: {
    padding: "12px",

    width: "100%",
    marginBottom: "2rem",

    [ScreenSizes.lgAndAbove]: {
      padding: 0,
    },
  },
};

const TimelineSkeletonItem = (props) => {
  const { styles } = useStyles(baseStyles, props);

  return (
    <div className={css(styles.activitySkeletonContainer)}>
      <TimelineSkeletonItemTitle styles={props} />
      <TimelineSkeletonItemBody styles={props} />
    </div>
  );
};

export default TimelineSkeletonItem;
