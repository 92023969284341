import { css } from "aphrodite";
import { memo } from "react";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import fontStyles from "styles/FontStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  beta: {
    transition: "ease-out 0.2s",
    padding: "0.188rem 0.625rem",
    height: "max-content",
    maxHeight: "25px",
    width: "max-content",
    maxWidth: "76px",
    backgroundColor: colours.brightYellow,
    fontWeight: 900,
    fontSize: "0.8rem",
    display: "grid",
    placeItems: "center",
    borderRadius: 6,
    transform: "skew(20deg)",
    boxShadow: "0px 0px 14px 0px rgba(255,242,0,0.41)",

    [ScreenSizes.mdAndAbove]: {
      position: "relative",
    },
  },
  betaText: {
    ...fontStyles.avalonBold,
    transform: "skew(-20deg)",
    padding: "0px",
    margin: "0px",
    color: colours.bodyText,
  },
};

function BetaBadge(props) {
  const { styles } = useStyles(baseStyles, props);

  return (
    <div className={css(styles.beta)}>
      <p className={css(styles.betaText)}>beta</p>
    </div>
  );
}

export default memo(BetaBadge);
