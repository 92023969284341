import Bugsnag from "@bugsnag/js";
import { css } from "aphrodite";
import PropTypes from "prop-types";
import { Component, useCallback } from "react";

import StandardButton from "../Buttons/StandardButton";

import sendGAEvent from "utils/sendGAEvent";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  container: {
    background: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    padding: "1em 2em 2em",
    [ScreenSizes.mdAndAbove]: {
      padding: "2em",
    },
  },
  box: {
    padding: "20px 2rem",
    backgroundColor: "white",
    textAlign: "center",
    maxWidth: "44rem",
  },
  heading: {
    ...gStyles.avalonBold,
    fontSize: "1.75rem",
  },
  content: {
    padding: "0 1rem",
    lineHeight: 1.5,
    [ScreenSizes.mdAndAbove]: {
      padding: "0 2rem",
    },
  },
  link: {
    color: colours.primary,
  },
  errorMessageContainer: {
    marginTop: "2rem",
    [ScreenSizes.mdAndAbove]: {
      marginTop: "4rem",
    },
  },
  errorMessageIntro: {
    ...gStyles.avalonBold,
    fontSize: 13,
    marginBottom: "1.5rem",
  },
  errorMessage: {
    padding: "1.5rem 1rem",
    fontSize: "1rem",
    backgroundColor: "#f3f3f3",
    borderRadius: 5,
    [ScreenSizes.mdAndAbove]: {
      padding: "2rem 1rem",
    },
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "3rem",
  },
  buttonContainer: {
    display: "inline-block",
    width: 150,
    margin: "0 0.5rem",
  },
};

const SLACK_HREF =
  "https://join.slack.com/t/podchaser-community/" +
  "shared_invite/enQtNDE5MjEzNjMyOTM1LTFjMmZmOWNmMmUyMGE3ZDM0NWZi" +
  "NzYxMGRkZDE5NDUwZjUzYmY2MzAyNGU2MTZhNGVmZjg5YzZlZTNkYzZkNDE";

const RenderErrorBoundary = (props) => {
  const { hasError, error } = props;

  const { styles } = useStyles(baseStyles, props);

  const handleReloadPage = useCallback(() => {
    if (window && window.location) {
      window.location.reload();
    }
  }, []);

  const handleOpenHomepage = useCallback(() => {
    if (window && window.location) {
      window.location.href = "/";
    }
  }, []);

  if (hasError) {
    return (
      <div className={css(styles.container)}>
        <div className={css(styles.box)}>
          <h2 className={css(styles.heading)}>
            Argh, sorry! Something has gone wrong.
          </h2>
          <div className={css(styles.content)}>
            Please contact us at{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="mailto:contact@podchaser.com"
              className={css(styles.link)}
            >
              contact@podchaser.com
            </a>{" "}
            or via our{" "}
            <a
              href={SLACK_HREF}
              target="_blank"
              rel="noopener noreferrer"
              className={css(styles.link)}
            >
              Community Slack
            </a>{" "}
            for further assistance.
          </div>
          {error.message && (
            <div className={css(styles.errorMessageContainer)}>
              <div className={css(styles.errorMessageIntro)}>
                Please include the following error when you contact us:
              </div>
              <div className={css(styles.errorMessage)}>{error.message}</div>
            </div>
          )}
          <div className={css(styles.buttons)}>
            <div className={css(styles.buttonContainer)}>
              <StandardButton
                label="Reload Page"
                onClick={handleReloadPage}
                variation="pink"
              />
            </div>
            <div className={css(styles.buttonContainer)}>
              <StandardButton
                label="Open Homepage"
                onClick={handleOpenHomepage}
                variation="subtle"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return this.props.children;
};

class ErrorBoundary extends Component {
  state = {
    hasError: false,
    error: null,
  };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    Bugsnag.notify(error, (event) => {
      event.addMetadata("errorData", { url: window.location.href });
    });
    sendGAEvent({
      action: "frontendError",
      message: error.message,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <RenderErrorBoundary {...this.state}>
          {this.props.children}
        </RenderErrorBoundary>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.array,
  ]).isRequired,
};

export default ErrorBoundary;
