export default (time) => {
  let seconds = 0;
  if (time) {
    if (!validTimeString(time)) return 0;

    const timeValues = time.split("").reduce(
      (totals, char) => {
        if (/^\d$/.test(char)) {
          return { ...totals, val: `${totals.val}${char}` };
        }
        if (char && char.trim()) {
          return { ...totals, [char]: totals.val, val: "" };
        }

        return totals;
      },
      { h: "", m: "", s: "", val: "" }
    );

    seconds = Object.entries(timeValues).reduce((secs, [key, val]) => {
      const numVal = parseInt(val, 10) || 0;

      if (numVal > 0) {
        if (key === "h") {
          return secs + numVal * 60 * 60; // hours
        }
        if (key === "m") {
          return secs + numVal * 60; // minutes
        }
        if (key === "s") {
          return secs + numVal; // seconds
        }
      }

      return secs;
    }, 0);
  }

  return seconds;
};

const validTimeString = (time) => time.match(/^[HhMmSs0-9]*$/) !== null;
