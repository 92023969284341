import { faUserCog } from "@fortawesome/free-solid-svg-icons/faUserCog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import { memo } from "react";
import { Link } from "react-router-dom";

import EntityImage from "components/Entities/EntityImage";

import authActions from "actions/auth";
import getUserUrl from "utils/entity/getUserUrl";

import useActionCreators from "hooks/useActionCreators";
import useIsModerator from "hooks/useIsModerator";
import useLoggedInUser from "hooks/useLoggedInUser";
import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: "1.5rem",

    [ScreenSizes.smAndAbove]: {
      alignItems: "center",
    },
  },
  userImage: {
    minWidth: 48,
    width: 48,
    height: 48,
    marginRight: "0.75rem",
  },
  pageTitleContainer: {
    display: "flex",
    flexDirection: "column",
  },
  pageTitle: {
    ...gStyles.avalonBold,
    fontSize: "1.5rem",
    margin: 0,
    padding: 0,
    wordBreak: "break-word",
  },
  viewProfile: {
    fontSize: "0.8125rem",
    marginTop: "0.25rem",
  },
  logoutLink: {
    ...gStyles.fontBold,
    marginLeft: "0.5rem",
  },
  settingsLink: {
    paddingLeft: "1rem",
    marginLeft: "auto",
    marginRight: "1rem",
  },
};

const AccountHubHeader = (props) => {
  const { styles } = useStyles(baseStyles, props);

  const user = useLoggedInUser();
  const { logout } = useActionCreators({ logout: authActions.logout });
  const { turnOffModerator } = useIsModerator();

  function handleLogoutClick() {
    logout();
    turnOffModerator();
  }
  return (
    <div className={css(styles.header)}>
      <EntityImage
        className={css(styles.userImage)}
        entity_type="user"
        entity={user}
        cacheSize={96}
        fullWidth
        profileImage
      />
      <div className={css(styles.pageTitleContainer)}>
        <h1 className={css(styles.pageTitle)}>
          <Link to={getUserUrl(user)}>
            {user.get("display_name") || user.get("username")}
          </Link>
        </h1>
        <Link className={css(styles.viewProfile)} to={getUserUrl(user)}>
          View Profile
        </Link>
      </div>
      <Link to="/profile/settings/account" className={css(styles.settingsLink)}>
        <FontAwesomeIcon icon={faUserCog} />
      </Link>
      <span
        onClick={handleLogoutClick}
        className={css(styles.logoutLink)}
        data-id="logout-link"
      >
        Logout
      </span>
    </div>
  );
};

export default memo(AccountHubHeader);
