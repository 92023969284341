import { useMemo } from "react";

import { selectAuthLoggingIn } from "selectors/auth";
import { selectLoggedInStatus } from "selectors/user";

import useCookie from "hooks/useCookie";
import useReduxState from "hooks/useReduxState";

export default function useIsLoggingIn() {
  const hasToken = useCookie();

  const { loggedInUser, authLoggingIn } = useReduxState((state) => {
    return {
      loggedInUser: selectLoggedInStatus(state),
      authLoggingIn: selectAuthLoggingIn(state),
    };
  }, []);

  const tokenLoggingIn = useMemo(
    () => hasToken && !loggedInUser.get("user_id"),
    [hasToken, loggedInUser]
  );

  return authLoggingIn || tokenLoggingIn;
}
