import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const RatingStepsModal = Loadable(() => import("./RatingStepsModal"));

function RatingStepsModalAsyncLoading(props) {
  return <LoadableLoading {...props} />;
}

const RatingStepsModalContainer = (props) => {
  return (
    <RatingStepsModal {...props} fallback={<RatingStepsModalAsyncLoading />} />
  );
};

export default RatingStepsModalContainer;
