import PropTypes from "prop-types";
import { memo, useEffect } from "react";
import sizeMe from "react-sizeme";
import { compose } from "redux";

function OnResize(props) {
  const { size, children, onResize } = props;
  const { width, height } = size || {};

  useEffect(() => {
    if (onResize && width && height) {
      onResize({ width, height });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height]);

  return children;
}

OnResize.propTypes = {
  onResize: PropTypes.func.isRequired,
  size: PropTypes.object,
  children: PropTypes.node,
};

OnResize.defaultProps = {
  children: null,
  size: null,
};

export default compose(
  memo,
  sizeMe({
    monitorWidth: true,
    monitorHeight: true,
    noPlaceholder: true,
  })
)(OnResize);
