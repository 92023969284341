import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const UserProfileContainer = Loadable(() => import("./UserProfileContainer"));

function UserProfileContainerAsyncLoading(props) {
  return <LoadableLoading {...props} page />;
}

const UserProfileContainerContainer = (props) => {
  return (
    <UserProfileContainer
      {...props}
      fallback={<UserProfileContainerAsyncLoading />}
    />
  );
};

export default UserProfileContainerContainer;
