import Loadable from "@loadable/component";
const RecurringCreditsSubmittedModal = Loadable(() =>
  import("./RecurringCreditsSubmittedModal")
);

function RecurringCreditsSubmittedModalAsyncLoading() {
  return null;
}

const RecurringCreditsSubmittedModalContainer = (props) => {
  return (
    <RecurringCreditsSubmittedModal
      {...props}
      fallback={<RecurringCreditsSubmittedModalAsyncLoading />}
    />
  );
};

export default RecurringCreditsSubmittedModalContainer;
