import Loadable from "@loadable/component";
const RegisterAppModal = Loadable(() => import("./RegisterAppModal"));

function RegisterAppModalAsyncLoading() {
  return null;
}

const RegisterAppModalContainer = (props) => {
  return (
    <RegisterAppModal {...props} fallback={<RegisterAppModalAsyncLoading />} />
  );
};

export default RegisterAppModalContainer;
