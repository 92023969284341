import { PAGE_SIZE } from "./pageSize";

const HEIGHT_EPISODE_CARD = 235.35;

function getListEpisodesHeight(podcast) {
  const podcastNumberOfEpisodes =
    (podcast && podcast.get("number_of_episodes")) || 0;

  let numberEpisodes = podcastNumberOfEpisodes;

  if (numberEpisodes >= PAGE_SIZE) {
    numberEpisodes = PAGE_SIZE;
  }
  return HEIGHT_EPISODE_CARD * numberEpisodes;
}

export default getListEpisodesHeight;
