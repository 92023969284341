import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const HomepageSidebarSuggestedFollows = Loadable(() =>
  import("./HomepageSidebarSuggestedFollows")
);

function HomepageViewAsyncLoading(props) {
  return <LoadableLoading {...props} transparent />;
}

const HomepageSidebarSuggestedFollowsContainer = (props) => {
  return (
    <HomepageSidebarSuggestedFollows
      {...props}
      fallback={<HomepageViewAsyncLoading />}
    />
  );
};

export default HomepageSidebarSuggestedFollowsContainer;
