import Loadable from "@loadable/component";

import TimelineSkeletonItem from "components/Timeline/TimelineSkeletonItem";

const AppearActivity = Loadable(() =>
  import(/* webpackChunkName: "AppearActivity" */ "./AppearActivity")
);

function AppearActivityLoading(props) {
  return <TimelineSkeletonItem />;
}

const AppearActivityContainer = (props) => {
  return <AppearActivity {...props} fallback={<AppearActivityLoading />} />;
};

export default AppearActivityContainer;
