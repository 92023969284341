import Loadable from "@loadable/component";
import { css } from "aphrodite";
import { memo } from "react";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";

const baseStyles = {
  container: {
    ...gStyles.skeletonPlaceholder,
    width: "100%",
    height: "4.125rem",
  },
};

const BrandViewEpisodeCompactCardAsync = (props) => {
  const { styles } = useStyles(baseStyles, props);

  return <div className={css(styles.container)} />;
};

const BrandViewEpisodeCompactCardAsyncMemo = memo(
  BrandViewEpisodeCompactCardAsync
);

const BrandViewEpisodeCompactCard = Loadable(() =>
  import(
    /* webpackChunkName: "BrandViewEpisodeCompactCard" */ "./BrandViewEpisodeCompactCard"
  )
);

function BrandViewEpisodeCompactCardAsyncLoading() {
  return <BrandViewEpisodeCompactCardAsyncMemo />;
}

const BrandViewEpisodeCompactCardContainer = (props) => {
  return (
    <BrandViewEpisodeCompactCard
      {...props}
      fallback={<BrandViewEpisodeCompactCardAsyncLoading />}
    />
  );
};

export default BrandViewEpisodeCompactCardContainer;
