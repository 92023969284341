import { css } from "aphrodite";
import PropTypes from "prop-types";
import deepmerge from "ramda/src/mergeDeepRight";
import { Fragment, useCallback } from "react";

import LoadMoreButton from "components/Buttons/LoadMoreButton";
import InfiniteList from "components/Common/Lists/InfiniteList";
import ListCarousel from "components/Common/Lists/ListCarousel";
import LoadingOverlay from "components/Common/LoadingOverlay";

import EntityListItem from "./EntityListItem";
import EntityListNoResults from "./EntityListNoResults";

import paginationActions from "actions/pagination";
import * as sortConstants from "constants/sort";
import eventIsFieldTrigger from "utils/misc/eventIsFieldTrigger";

import useActionCreators from "hooks/useActionCreators";
import useList from "hooks/useList";
import useLoadListEffect from "hooks/useLoadListEffect";
import useReduxState from "hooks/useReduxState";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  loadMoreContainer: {
    width: "100%",
  },
  spacer: {
    width: "100%",
    minHeight: "1px",
  },
  viewMoreContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "calc(100vw - 1rem)",
    height: "100%",
  },
  viewMoreBadge: {
    ...gStyles.noButton,
    ...gStyles.fontBold,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1rem",
    color: colours.grey,
    backgroundColor: colours.white,
    width: "8.5rem",
    height: "8.5rem",
    minWidth: "8.5rem",
    borderRadius: "50%",
    cursor: "pointer",
    border: `1px ${colours.grey} solid`,
    boxShadow: "0 5px 16px 0 rgba(0, 0, 0, 0.07)",
    margin: "3rem auto 0",
  },
};

const loadingStyles = {
  noOverlay: {
    fontSize: "1.25rem",
  },
  icon: {
    maxWidth: "1.25em",
  },
};

const EntityList = (props) => {
  const {
    apiProps,
    asEntity,
    dontRenderList,
    entity_type,
    filters,
    getEntity,
    getItemIds,
    infinite,
    listKey,
    list_type,
    loadListAction,
    loadingStyles: passedLoadingStyles,
    noResultsMessage,
    noSearchResultsMessage,
    onMoreClick,
    pageSize,
    render,
    renderItem: passedRenderItem,
    renderLoadingComponent,
    renderMore: passedRenderMore,
    rescore,
    showLoadMore,
    showMore,
    sort,
    staticFilters,
    options,
  } = props;
  const { styles } = useStyles(baseStyles, props);

  const pagActions = useActionCreators({ ...paginationActions });
  const { nextPage } = pagActions;

  useLoadListEffect(listKey, {
    key: listKey,
    sort,
    options,
    list_type,
    loadListAction,
    entity_type,
    pageSize,
    apiProps,
    filters,
    staticFilters,
    rescore,
  });

  const listInfo = useList(listKey);
  const { ids, total, loading, hasMore, searchTerm, listExists } = listInfo;

  const items = useReduxState(
    (state) => {
      if (asEntity) {
        return ids.map((id) => getEntity(state, id));
      } else if (getItemIds) {
        return getItemIds(state);
      }

      return ids;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [ids]
  );

  const defaultRenderMore = useCallback(
    () => (
      <div className={css(styles.viewMoreContainer)}>
        <button
          className={css(styles.viewMoreBadge)}
          onClick={onMoreClick}
          onKeyDown={(e) => eventIsFieldTrigger(e) && onMoreClick(e)}
        >
          View More
        </button>
      </div>
    ),
    [onMoreClick, styles]
  );

  const renderItem = useCallback(
    (id, index, carousel) => (
      <EntityListItem
        key={id}
        itemId={id}
        listKey={listKey}
        renderItem={passedRenderItem}
        getEntity={getEntity}
        index={index}
        small={!carousel}
      />
    ),
    [getEntity, listKey, passedRenderItem]
  );

  const renderMore =
    hasMore && (passedRenderMore || (showMore && defaultRenderMore));

  const handleNextPage = useCallback(
    () => nextPage(listKey),
    [nextPage, listKey]
  );

  if (dontRenderList) {
    return null;
  }

  if (!listExists || (loading && !total)) {
    if (renderLoadingComponent) {
      return renderLoadingComponent();
    }

    const stylesCalculated = deepmerge(
      loadingStyles,
      passedLoadingStyles || {}
    );

    return <LoadingOverlay styles={stylesCalculated} noOverlay noPadding />;
  }

  const list = infinite ? (
    <InfiniteList
      {...props}
      items={items}
      renderItem={renderItem}
      onLoadMore={onMoreClick || handleNextPage}
    />
  ) : (
    <ListCarousel
      {...props}
      items={items}
      renderItem={renderItem}
      renderMore={renderMore}
    />
  );

  if (render) {
    return render({
      list,
      ...props,
      ...pagActions,
      listInfo,
      loading,
      items,
    });
  }

  if (total === 0) {
    if (noResultsMessage) {
      return (
        <EntityListNoResults styles={props?.styles}>
          {searchTerm && noSearchResultsMessage
            ? noSearchResultsMessage
            : noResultsMessage}
        </EntityListNoResults>
      );
    }

    return null;
  }

  if (showLoadMore) {
    return (
      <Fragment>
        {list}
        <div className={css(styles.spacer)} />
        {hasMore && (
          <LoadMoreButton
            className={css(styles.loadMoreContainer)}
            onClick={handleNextPage}
            loading={loading}
            hasMore={hasMore}
          />
        )}
      </Fragment>
    );
  }

  return list;
};

EntityList.propTypes = {
  apiProps: PropTypes.object,
  asEntity: PropTypes.bool,
  dontRenderList: PropTypes.bool,
  entity_type: PropTypes.string,
  filters: PropTypes.object,
  getEntity: PropTypes.func,
  getItemIds: PropTypes.func,
  infinite: PropTypes.bool,
  listKey: PropTypes.string.isRequired,
  list_type: PropTypes.string,
  loadingStyles: PropTypes.object,
  loadListAction: PropTypes.func,
  noResultsMessage: PropTypes.node,
  noSearchResultsMessage: PropTypes.node,
  onMoreClick: PropTypes.func,
  pageSize: PropTypes.number,
  render: PropTypes.func,
  renderItem: PropTypes.func,
  renderLoadingComponent: PropTypes.func,
  renderMore: PropTypes.func,
  rescore: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  searchTerm: PropTypes.string,
  showLoadMore: PropTypes.bool,
  showMore: PropTypes.bool,
  sort: PropTypes.string,
  staticFilters: PropTypes.object,
  options: PropTypes.object,
};

EntityList.defaultProps = {
  apiProps: {},
  asEntity: false,
  dontRenderList: false,
  entity_type: null,
  filters: {},
  getItemIds: null,
  infinite: false,
  list_type: null,
  loadListAction: null,
  noResultsMessage: "No results found",
  noSearchResultsMessage: "No results found for your search",
  onMoreClick: null,
  pageSize: 4,
  render: null,
  renderItem: null,
  renderMore: null,
  rescore: null,
  searchTerm: null,
  showLoadMore: false,
  showMore: false,
  sort: sortConstants.SORT_ORDER_RANKING,
  staticFilters: {},
  options: {},
};

// eslint-disable-next-line react/display-name
const renderEntityList = (parentProps) => (props) =>
  <EntityList {...parentProps} listKey={parentProps.key} {...props} />;

export default renderEntityList;
