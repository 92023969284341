import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const ClaimedPodcasts = Loadable(() => import("./ClaimedPodcasts"));

function ClaimedPodcastsAsyncLoading(props) {
  return <LoadableLoading {...props} page />;
}

const ClaimedPodcastsContainer = (props) => {
  return (
    <ClaimedPodcasts {...props} fallback={<ClaimedPodcastsAsyncLoading />} />
  );
};

export default ClaimedPodcastsContainer;
