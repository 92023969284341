import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo, Fragment } from "react";
import { useLocalStorage } from "usehooks-ts";

import EmailVerificationBanner from "components/Banners/EmailVerificationBannerAsync";
import HeaderStandard from "components/Layout/Header/HeaderStandard";
import HeaderStandardBatmanLogo from "components/Layout/Header/HeaderStandardBatmanLogo";
import HeaderStandardLogo from "components/Layout/Header/HeaderStandardLogo";
import NavMenu from "components/Layout/NavMenu/NavMenu";

import useIsBatmanMode from "hooks/useIsBatmanMode";
import useLoggedInUser, { useLoggedIn } from "hooks/useLoggedInUser";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import ScreenSizes, { createBreakpoint } from "styles/ScreenSizes";

const baseStyles = {
  container: {},
  secondNav: {
    position: "relative",
    display: "block",
    [createBreakpoint({ min: 1400 })]: {
      display: "none",
    },
  },
};

const SEARCH_AUTOCOMPLETE_STYLES = {
  search: {
    [ScreenSizes.lgAndAbove]: {
      borderRadius: 25,
      background: colours.newYellow,
      border: "1px solid rgb(217, 217, 217, 0.21)",
    },
  },
  input: {
    color: colours.black,
  },
  icon: {
    color: colours.black,

    [ScreenSizes.lgAndAbove]: {
      color: colours.black,
    },
  },
};

const NAV_ITEM_STYLES = {
  navItem: {
    color: colours.newYellow,
  },
};

const TOP_SEARCH_CONTAINER_STYLES = {
  searchContainer: {
    flex: 1,
  },
  shortcut: {
    background: colours.black,

    color: colours.newYellow,
  },
};

const NAV_MENU_STYLES = {
  container: {
    background: colours.black,
  },
};

const HEADER_STANDARD_STYLES = {
  container: {
    background: colours.black,
  },
};

function HeaderNavWrapper(props) {
  const {
    withHeader,
    withNavMenu,
    showUserContainer,
    onMenuToggleClick,
    isHomepage,
  } = props;

  const { styles } = useStyles(baseStyles, props);

  const [emailBannerIsDismissed] = useLocalStorage(
    "pc_banner_dismissed:email_verification",
    null
  );

  const loggedIn = useLoggedIn();
  const user = useLoggedInUser();
  const { isBatmanMode } = useIsBatmanMode();

  if (!withHeader) {
    return null;
  }

  if (isBatmanMode) {
    return (
      <Fragment>
        {loggedIn && !user.get("email_verified") && !emailBannerIsDismissed && (
          <EmailVerificationBanner />
        )}
        <div className={css(styles.container)}>
          <HeaderStandard
            isHomepage={isHomepage}
            onMenuToggleClick={onMenuToggleClick}
            showUserContainer={showUserContainer}
            styles={HEADER_STANDARD_STYLES}
            logo={<HeaderStandardBatmanLogo />}
            searchAutocompleteStyles={SEARCH_AUTOCOMPLETE_STYLES}
            navItemStyles={NAV_ITEM_STYLES}
            topSearchContainerStyles={TOP_SEARCH_CONTAINER_STYLES}
          />
          <div className={css(styles.secondNav)}>
            {withNavMenu && (
              <NavMenu
                user={user}
                styles={NAV_MENU_STYLES}
                navItemStyles={NAV_ITEM_STYLES}
              />
            )}
          </div>
        </div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      {loggedIn && !user.get("email_verified") && !emailBannerIsDismissed && (
        <EmailVerificationBanner />
      )}
      <div className={css(styles.container)}>
        <HeaderStandard
          isHomepage={isHomepage}
          onMenuToggleClick={onMenuToggleClick}
          showUserContainer={showUserContainer}
          logo={<HeaderStandardLogo />}
        />
        <div className={css(styles.secondNav)}>
          {withNavMenu && <NavMenu user={user} />}
        </div>
      </div>
    </Fragment>
  );
}

HeaderNavWrapper.propTypes = {
  withHeader: PropTypes.bool,
  withNavMenu: PropTypes.bool,
  showUserContainer: PropTypes.bool,
};

HeaderNavWrapper.defaultProps = {
  withNavMenu: true,
  withHeader: false,
  showUserContainer: false,
};

export default memo(HeaderNavWrapper);
