export const selectSimpleFormLoading = (state, formKey) =>
  state.simple_forms.getIn(["forms", formKey?.toString(), "loading"]);

export const selectSimpleFormError = (state, formKey) =>
  state.simple_forms.getIn(["forms", formKey?.toString(), "error"]);

export const selectSimpleFormValue = (state, formKey, fieldName) =>
  state.simple_forms.getIn(["forms", formKey?.toString(), "values", fieldName]);
export const selectSimpleFormValues = (state, formKey) =>
  state.simple_forms.getIn(["forms", formKey?.toString(), "values"]);

export const selectSimpleFormFieldTouched = (state, formKey, fieldName) =>
  state.simple_forms.getIn([
    "forms",
    formKey?.toString(),
    "touched",
    fieldName,
  ]);
export const selectSimpleFormTouched = (state, formKey) =>
  state.simple_forms.getIn(["forms", formKey?.toString(), "formTouched"]);

export const selectSimpleFormMounted = (state, formKey) =>
  state.simple_forms.getIn(["forms", formKey?.toString(), "mounted"]);

export const selectSimpleForm = (state, formKey) => {
  if (formKey && state.simple_forms.hasIn(["forms", formKey])) {
    return state.simple_forms.getIn(["forms", formKey]);
  }

  return null;
};

export const selectSimpleFormIsSubmitting = (state, formKey) =>
  state.simple_forms.getIn(["forms", formKey?.toString(), "submitting"]);
