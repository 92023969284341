import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const HomepageSidebarProLinks = Loadable(() =>
  import("./HomepageSidebarProLinks")
);

function HomepageSidebarProLinksAsyncLoading(props) {
  return <LoadableLoading {...props} transparent />;
}

const HomepageSidebarProLinksContainer = (props) => {
  return (
    <HomepageSidebarProLinks
      {...props}
      fallback={<HomepageSidebarProLinksAsyncLoading />}
    />
  );
};

export default HomepageSidebarProLinksContainer;
