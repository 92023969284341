import Loadable from "@loadable/component";
const ProConciergeQuestionModal = Loadable(() =>
  import("./ProConciergeQuestionModal")
);

function AboutAsyncLoading() {
  return null;
}

const ProConciergeQuestionModalContainer = (props) => {
  return (
    <ProConciergeQuestionModal {...props} fallback={<AboutAsyncLoading />} />
  );
};

export default ProConciergeQuestionModalContainer;
