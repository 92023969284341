import { css } from "aphrodite";
import PropTypes from "prop-types";
import { useCallback } from "react";

import ButtonPopout from "components/Buttons/ButtonPopout";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";

const contentDecorSize = 18;
const contentDecorWidth =
  Math.round((Math.sqrt(contentDecorSize * contentDecorSize * 2) / 2) * 100) /
  100;

const baseStyles = {
  desktopOuter: {
    display: "block",
    margin: -Math.ceil(contentDecorWidth),
    marginTop: -(Math.ceil(contentDecorWidth) + 12), // 12 = contentWrapperOuter paddingTop
    marginBottom: -(Math.ceil(contentDecorWidth) + 12), // 12 = contentWrapperOuter paddingTop
    padding: Math.floor(contentDecorWidth),
  },
  desktopContent: {
    display: "block",
    width: "14rem",
    paddingTop: 12,
    paddingBottom: 12,
    position: "relative",
  },
  mobileContent: {
    ...gStyles.floatBubble,
    padding: "1.5rem",
    borderRadius: 0,
    borderTopRightRadius: 5,
    borderTopRightLeft: 5,
    width: "100%",
    pointerEvents: "auto",
    maxHeight: "calc(100% - 4rem)",
    overflowY: "auto",
  },
};

const ProfileHoverPopoverContent = (props) => {
  const { children, popoutProps } = props;
  const { styles } = useStyles(baseStyles, props);

  const stopPropagation = useCallback((e) => e.stopPropagation(), []);

  return (
    <ButtonPopout {...popoutProps}>
      <span
        data-id="profile-hover-popover"
        className={css(styles.desktopOuter)}
        onMouseEnter={popoutProps.onContentHover}
        onMouseLeave={popoutProps.onContentEndHover}
        onClick={stopPropagation}
        onTouchStart={stopPropagation}
      >
        <span className={css(styles.desktopContent)}>{children}</span>
      </span>
    </ButtonPopout>
  );
};

ProfileHoverPopoverContent.propTypes = {
  children: PropTypes.node,
  mobile: PropTypes.bool,
  inline: PropTypes.bool,
  expand: PropTypes.bool,
  ariaLabel: PropTypes.string,
  popoutProps: PropTypes.object.isRequired,
};

ProfileHoverPopoverContent.defaultProps = {
  children: null,
  mobile: false,
  inline: false,
  expand: false,
  ariaLabel: undefined,
};

export default ProfileHoverPopoverContent;
