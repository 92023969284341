import { css } from "aphrodite";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  activitySkeletonTitle: {
    ...gStyles.skeletonPlaceholder,
    borderRadius: 8,
    color: colours.grey,
    height: "3rem",
    marginBottom: "0.5rem",

    [ScreenSizes.mdAndAbove]: {
      height: "4.5rem",
      width: "3rem",
      marginBottom: 0,
    },
    [ScreenSizes.lgAndAbove]: {
      height: "4.5rem",
      width: "32rem",
      marginBottom: "0.8rem",
    },
  },
};

const TimelineSkeletonItemTitle = (props) => {
  const { styles } = useStyles(baseStyles, props);

  return <div className={css(styles.activitySkeletonTitle)} />;
};

export default TimelineSkeletonItemTitle;
