import Loadable from "@loadable/component";
const SendAlertFeedbackModal = Loadable(() =>
  import("./SendAlertFeedbackModal")
);

function SendAlertFeedbackModalAsyncLoading() {
  return null;
}

const SendAlertFeedbackModalContainer = (props) => {
  return (
    <SendAlertFeedbackModal
      {...props}
      fallback={<SendAlertFeedbackModalAsyncLoading />}
    />
  );
};

export default SendAlertFeedbackModalContainer;
