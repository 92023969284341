import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const IntegrationsLeaderboard = Loadable(() =>
  import(
    /* webpackChunkName: "IntegrationsLeaderboard" */ "./IntegrationsLeaderboard"
  )
);

function IntegrationsLeaderboardViewAsyncLoading(props) {
  return <LoadableLoading {...props} page />;
}

const IntegrationsLeaderboardContainer = (props) => {
  return (
    <IntegrationsLeaderboard
      {...props}
      fallback={<IntegrationsLeaderboardViewAsyncLoading />}
    />
  );
};

export default IntegrationsLeaderboardContainer;
