import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const HomepageSidebarMyCreators = Loadable(() =>
  import("./HomepageSidebarMyCreators")
);

function HomepageSidebarMyCreatorsAsyncLoading(props) {
  return <LoadableLoading {...props} transparent />;
}

const HomepageSidebarMyCreatorsContainer = (props) => {
  return (
    <HomepageSidebarMyCreators
      {...props}
      fallback={<HomepageSidebarMyCreatorsAsyncLoading />}
    />
  );
};

export default HomepageSidebarMyCreatorsContainer;
