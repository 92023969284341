import Loadable from "@loadable/component";
const ExportModal = Loadable(() => import("./ExportModal"));

function AboutAsyncLoading() {
  return null;
}

const ExportModalContainer = (props) => {
  return <ExportModal {...props} fallback={<AboutAsyncLoading />} />;
};

export default ExportModalContainer;
