import Loadable from "@loadable/component";

const ClaimNetworkModal = Loadable(() => import("./ClaimNetworkModal"));

function ClaimNetworkModalAsyncLoading() {
  return null;
}

const ClaimNetworkModalContainer = (props) => {
  return (
    <ClaimNetworkModal
      {...props}
      fallback={<ClaimNetworkModalAsyncLoading />}
    />
  );
};

export default ClaimNetworkModalContainer;
