import { faChevronCircleLeft } from "@fortawesome/pro-solid-svg-icons/faChevronCircleLeft";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";

const baseStyles = {
  backBar: {
    ...gStyles.fontSemiBold,
    background: "#fff",
    padding: "0.75rem 1rem",
    fontSize: "0.8125rem",
  },
  backBarNoPadding: {
    padding: 0,
  },
  backBarTransparent: {
    background: "none",
    color: "#fff",
  },
  icon: {
    marginRight: "0.5rem",
  },
};

const BackBar = (props) => {
  const { label, onClick, hideIntro, transparent, children, noPadding } = props;
  const { styles } = useStyles(baseStyles, props);

  const renderIcon = () => (
    <span className={css(styles.icon)}>
      <FontAwesomeIcon icon={faChevronCircleLeft} />
    </span>
  );

  const renderLabel = () => {
    if (children) {
      return children;
    }
    return (
      <span>
        {renderIcon()}
        {label ? `${hideIntro || "Back to"} ${label}` : "Back"}
      </span>
    );
  };

  return (
    <div
      className={css(
        styles.backBar,
        noPadding && styles.backBarNoPadding,
        transparent && styles.backBarTransparent
      )}
    >
      <Link onClick={onClick} to="/account">
        {renderLabel()}
      </Link>
    </div>
  );
};

BackBar.propTypes = {
  label: PropTypes.node,
  onClick: PropTypes.func,
  hideIntro: PropTypes.bool,
  transparent: PropTypes.bool,
  children: PropTypes.node,
  noPadding: PropTypes.bool,
};

BackBar.defaultProps = {
  label: null,
  onClick: null,
  hideIntro: false,
  transparent: false,
  children: null,
  noPadding: false,
};

export default BackBar;
