import buttonTokens from "./tokens/tokens-button";

export const buttonStyles = {
  secondary: {
    border: `1px solid ${buttonTokens.color.border.outer.secondary.default.default}`,
    backgroundColor: buttonTokens.color.background.secondary.default.default,
    color: buttonTokens.color.text.secondary.default.default,
    ":hover": {
      border: `1px solid ${buttonTokens.color.border.outer.secondary.hover.default}`,
      backgroundColor: buttonTokens.color.background.secondary.hover.default,
      color: buttonTokens.color.text.secondary.hover.default,
    },
    ":active": {
      border: `1px solid ${buttonTokens.color.border.outer.secondary.active.default}`,
      backgroundColor: buttonTokens.color.background.secondary.active.default,
      color: buttonTokens.color.text.secondary.active.default,
    },
    ":focus": {
      border: `1px solid ${buttonTokens.color.border.outer.secondary.active.default}`,
      backgroundColor: buttonTokens.color.background.secondary.active.default,
      color: buttonTokens.color.text.secondary.active.default,
    },
  },
  accent: {
    border: `1px solid ${buttonTokens.color.border.outer.accent.default.default}`,
    backgroundColor: buttonTokens.color.background.accent.default.default,
    color: buttonTokens.color.text.accent.default.default,
    ":hover": {
      border: `1px solid ${buttonTokens.color.border.outer.accent.hover.default}`,
      backgroundColor: buttonTokens.color.background.accent.hover.default,
      color: buttonTokens.color.text.accent.hover.default,
    },
    ":active": {
      border: `1px solid ${buttonTokens.color.border.outer.accent.active.default}`,
      backgroundColor: buttonTokens.color.background.accent.active.default,
      color: buttonTokens.color.text.accent.active.default,
    },
    ":focus": {
      border: `1px solid ${buttonTokens.color.border.outer.accent.active.default}`,
      backgroundColor: buttonTokens.color.background.accent.active.default,
      color: buttonTokens.color.text.accent.active.default,
    },
  },
  selected: {
    border: `1px solid ${buttonTokens.color.border.outer.selected.default.default}`,
    backgroundColor: buttonTokens.color.background.selected.default.default,
    color: buttonTokens.color.text.selected.default.default,
    ":hover": {
      border: `1px solid ${buttonTokens.color.border.outer.selected.hover.default}`,
      backgroundColor: buttonTokens.color.background.selected.hover.default,
      color: buttonTokens.color.text.selected.hover.default,
    },
    ":active": {
      border: `1px solid ${buttonTokens.color.border.outer.selected.active.default}`,
      backgroundColor: buttonTokens.color.background.selected.active.default,
      color: buttonTokens.color.text.selected.active.default,
    },
    ":focus": {
      border: `1px solid ${buttonTokens.color.border.outer.selected.active.default}`,
      backgroundColor: buttonTokens.color.background.selected.active.default,
      color: buttonTokens.color.text.selected.active.default,
    },
  },
};
