import PropTypes from "prop-types";
import { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";

import RequestContext from "pages/RequestContext";

import modalActions from "actions/modals";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import useIsLoggingIn from "hooks/useIsLoggingIn";
import useQuery from "hooks/useQuery";

const removeActionQueries = (setQuery, query, action, location) => {
  const newQuery = Object.entries(query).reduce(
    (qu, [queryKey, queryValue]) => {
      if (
        queryKey !== "action" &&
        (!action.clearQueries || !action.clearQueries.includes(queryKey))
      ) {
        return { ...qu, [queryKey]: queryValue };
      }

      return qu;
    },
    {}
  );

  setQuery({ ...location, query: newQuery });
};

const ActionHandler = (props) => {
  const { actions } = props;

  const location = useLocation();

  const [query, setQuery] = useQuery();
  const queryActionKey = query && query.action;

  const requestContext = useContext(RequestContext);
  const inBrowser = !requestContext.server;

  const isLoggingIn = useIsLoggingIn();
  const isLoggingInRef = useRef(isLoggingIn);

  const { showModal } = useActionCreators({
    showModal: modalActions.showModal,
  });

  const executeAction = useCallback(
    (action) => {
      if (action.modal) {
        showModal("generic", {
          title: action.getTitle ? action.getTitle(query) : undefined,
          renderContent: action.renderContent,
        });
      }
      if (action.handler) {
        action.handler(query);
      }
      if (action.showModalName) {
        showModal(action.showModalName, action.showModalProps);
      }

      removeActionQueries(setQuery, query, action, location);
      sendGAEvent({
        action: "actionHandlerTriggered",
        handlerAction: action.query,
        ...(action.analyticsParams || {}),
      });
    },
    [setQuery, location, query, showModal]
  );

  const queryActions = useMemo(
    () =>
      actions.reduce((queries, action) => {
        if (action.query) {
          return {
            ...queries,
            [action.query]: action,
          };
        }

        return queries;
      }, {}),
    [actions]
  );

  useEffect(() => {
    isLoggingInRef.current = isLoggingIn;
    // needs to be done this way, isLoggingIn won't update in a recursive function
  }, [isLoggingIn]);

  const handleLoginAction = (queryAction) => {
    if (isLoggingInRef.current) {
      removeActionQueries(setQuery, query, queryAction, location);
      setTimeout(() => handleLoginAction(queryAction), 2000);
    } else {
      executeAction(queryAction);
    }
  };

  useEffect(() => {
    const queryAction =
      queryActions && queryActionKey && queryActions[queryActionKey];

    if (queryAction && inBrowser) {
      if (isLoggingIn) {
        handleLoginAction(queryAction);
      } else {
        executeAction(queryAction);
      }
    }

    // Make sure we only do this once on a page load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

ActionHandler.propTypes = {
  actions: PropTypes.array,
};

ActionHandler.defaultProps = {
  actions: [],
};

export default ActionHandler;
