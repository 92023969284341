import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const HomepageSidebarProfile = Loadable(() =>
  import("./HomepageSidebarProfile")
);

function HomepageSidebarProfileAsyncLoading(props) {
  return <LoadableLoading {...props} transparent />;
}

const HomepageSidebarProfileContainer = (props) => {
  return (
    <HomepageSidebarProfile
      {...props}
      fallback={<HomepageSidebarProfileAsyncLoading />}
    />
  );
};

export default HomepageSidebarProfileContainer;
