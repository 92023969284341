export function log(...messages) {
  const showLogs =
    window && window.requestContext && window.requestContext.showLogs;

  if (process.env.NODE_ENV === "development" || showLogs) {
    console.log(...messages);
  }
}

export function prodLog(...messages) {
  if (process.env.NODE_ENV === "development") {
    console.log(...messages);
  } else {
    const finalMessages = messages.map((mes) => {
      if (typeof mes === "object") {
        return JSON.stringify(mes);
      }

      return mes;
    });

    console.log(...finalMessages);
  }
}

export const setShowLogs = (user) => {
  if (window && window.requestContext) {
    const isAdmin =
      user &&
      user.get("roles") &&
      (user.get("roles").includes("admin") ||
        user.get("roles").includes("podchaser staff") ||
        user.get("roles").includes("moderator"));

    window.requestContext.showLogs = isAdmin;
  }
};
