import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo, useCallback } from "react";
import { useLocation } from "react-router-dom";

import podchaserProBadge from "./PodchaserProBadge.svg";

import sendGAEvent from "utils/sendGAEvent";

import { useStyles } from "hooks/useStyles";
import useUserHasPro from "hooks/useUserHasPro";

const baseStyles = {
  logo: {},
  proImage: {
    height: "1em",
  },
};

const PodchaserProBadge = (props) => {
  const { height, linked } = props;
  const customStyles = {
    proImage: {
      height: height,
    },
  };
  const { styles } = useStyles([baseStyles, customStyles], props);

  const location = useLocation();

  const hasPro = useUserHasPro();
  const isOnProPage = hasPro
    ? location.pathname.includes("/profile/pro")
    : location.pathname === "/pro";

  const handleLinkClick = useCallback(() => {
    sendGAEvent({
      action: "podchaserProBadgeClick",
    });
    if (isOnProPage) {
      window.location.reload();
    }
    return true;
  }, [isOnProPage]);

  if (linked) {
    return (
      <a
        className={css(styles.proImageLink)}
        href={hasPro ? "/profile/pro" : "/pro"}
        title="Pro website"
        target="_blank"
        onClick={handleLinkClick}
        rel="noreferrer"
      >
        <img
          className={css(styles.proImage)}
          src={podchaserProBadge}
          height={height}
          alt={"Podchaser Pro"}
        />
      </a>
    );
  }

  return (
    <span className={css(styles.proImageContainer, styles.proImageLink)}>
      <img
        className={css(styles.proImage)}
        src={podchaserProBadge}
        height={height}
        alt={"Podchaser Pro"}
      />
    </span>
  );
};

PodchaserProBadge.propTypes = {
  showPodchaserLogo: PropTypes.bool,
  onClick: PropTypes.func,
  height: PropTypes.string,
  linked: PropTypes.bool,
};

PodchaserProBadge.defaultProps = {
  showPodchaserLogo: false,
  onClick: null,
  height: "1em",
  linked: true,
};

export default memo(PodchaserProBadge);
