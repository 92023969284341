import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const ColourTestPage = Loadable(() => import("./ColourTestPage"));

function ColourTestPageAsyncLoading(props) {
  return <LoadableLoading {...props} page />;
}

const ColourTestPageContainer = (props) => {
  return (
    <ColourTestPage {...props} fallback={<ColourTestPageAsyncLoading />} />
  );
};

export default ColourTestPageContainer;
