import Loadable from "@loadable/component";
const CreditsSubmittedModal = Loadable(() => import("./CreditsSubmittedModal"));

function CreditsSubmittedModalAsyncLoading() {
  return null;
}

const CreditsSubmittedModalContainer = (props) => {
  return (
    <CreditsSubmittedModal
      {...props}
      fallback={<CreditsSubmittedModalAsyncLoading />}
    />
  );
};

export default CreditsSubmittedModalContainer;
