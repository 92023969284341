import { Map } from "immutable";
import PropTypes from "prop-types";

import BaseEntityLink from "./BaseEntityLink";

import { selectSpecificList } from "selectors/userlist";
import getUserListUrl from "utils/entity/getUserListUrl";

import useReduxState from "hooks/useReduxState";

const UserListLink = (props) => {
  const { entity: passedEntity, entity_id } = props;

  const entity = useReduxState(
    (state) => passedEntity || selectSpecificList(state, entity_id),
    [passedEntity, entity_id]
  );

  return (
    <BaseEntityLink
      {...props}
      entity={entity}
      to={getUserListUrl(entity)}
      name={entity && entity.get("title")}
      isProfileType
    />
  );
};

UserListLink.propTypes = {
  entity: PropTypes.instanceOf(Map),
  entity_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

UserListLink.defaultProps = {
  entity: null,
  entity_id: null,
};

export default UserListLink;
