import Loadable from "@loadable/component";
const DismissableErrorModal = Loadable(() => import("./DismissableErrorModal"));

function DismissableErrorModalAsyncLoading() {
  return null;
}

const DismissableErrorModalContainer = (props) => {
  return (
    <DismissableErrorModal
      {...props}
      fallback={<DismissableErrorModalAsyncLoading />}
    />
  );
};

export default DismissableErrorModalContainer;
