import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const ClaimPodcast = Loadable(() => import("./ClaimPodcast"));

function ClaimPodcastAsyncLoading(props) {
  return <LoadableLoading {...props} page />;
}

const ClaimPodcastContainer = (props) => {
  return <ClaimPodcast {...props} fallback={<ClaimPodcastAsyncLoading />} />;
};

export default ClaimPodcastContainer;
