// use this to conditionally shallow merge a set of styles, ignoring falsey values
export const mergeStyles = (...possibleStyles) =>
  possibleStyles.filter((s) => !!s).reduce((a, n) => ({ ...a, ...n }));

export const remToPx = (rem) =>
  parseFloat(
    typeof rem === "string" ? rem.replace("rem", "").replace("em", "") : rem
  ) * 16;

export const maybeRemToPx = (rem) =>
  typeof rem === "string" && rem.includes("em")
    ? remToPx(rem)
    : parseFloat(rem);
