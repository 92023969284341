import eventIsFieldTrigger from "utils/misc/eventIsFieldTrigger";

export default function accessibleClickProps(
  handler,
  options = {},
  handlers = {}
) {
  const triggerValidator = options.triggerValidator || eventIsFieldTrigger;
  const preventDefault =
    options.preventDefault === undefined ? true : options.preventDefault;
  const stopPropagation =
    options.stopPropagation === undefined ? true : options.stopPropagation;

  return (options.handlers || ["onClick", "onKeyDown"]).reduce(
    (handlerProps, handlerName) => ({
      ...handlerProps,
      [handlerName]: (e) => {
        if (handlerName !== "onKeyDown" || triggerValidator(e)) {
          if (preventDefault && e.preventDefault) {
            e.preventDefault();
          }
          if (stopPropagation && e.stopPropagation) {
            e.stopPropagation();
          }

          if (handler) {
            handler(e);
          }
        }

        if (handlers[handlerName]) {
          return handlers[handlerName](e);
        }

        return options.return || false;
      },
    }),
    {}
  );
}
