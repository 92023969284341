import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";

import Dotdotdot from "components/Common/Dotdotdot";
import LazyLoadComponent from "components/Common/LazyLoad/LazyLoadComponent";
import ClaimedBadge from "components/Podcast/ClaimedBadgeAsync";
import ProfilePopover from "components/Profiles/ProfilePopover";

import { CLAIMED_BADGE_HEIGHT } from "constants/podcast";
import sendGAEvent from "utils/sendGAEvent";

import useActivityContext from "hooks/useActivityContext";
import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import cardStyles from "styles/CardStyles";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  link: {
    cursor: "pointer",
  },
  linklessLink: {
    cursor: "initial",
  },
  linkInline: {
    display: "inline",
  },
  clamp: {
    display: "block",
  },
  entityLink: {
    ...gStyles.fontBold,
    transform: "translate3d(0, 0, 0)",
    backfaceVisibility: "hidden",
    wordBreak: "break-word",

    ":focus": {
      textDecoration: "underline",
    },
  },
  entityLinkAvalon: {
    ...cardStyles.desktopCardPrimaryTitle,
    ...gStyles.noEllipsis,
    fontSize: "1em",
    ":focus": {
      textDecoration: "underline",
    },
  },
  entityLinkImage: {
    display: "block",
    ":focus": {
      opacity: "0.75",
    },
  },
  ellipsisOverflow: {
    ...gStyles.textEllipsis,
    display: "block",
  },
  profileEllipsisOverflow: {
    ...gStyles.textEllipsis,
    display: "inline-block",
    maxWidth: "100%",
  },
  containedRenderedLinkInline: {
    display: "inline",
  },
  entityLinkWithClamp: {
    display: "block",
  },
  entityLinkWithClampStyle: {
    display: "inline-block",
    overflow: "hidden",
  },
};

const BaseEntityLink = (props) => {
  const {
    dataId,
    entity_type,
    children,
    avalon,
    noLinkFont,
    ellipsisOverflow,
    inline,
    clamp,
    clampMaxHeight,
    externalLink,
    disablePopup,
    disableLink,
    dotdotdot,
    showClaimedBadge,
    isImage,
    onClick,
    claimedBadgeProps,
    // hashLinkOffset,
    // useHashLink,
    className,
    linkless,
    renderOnMount,
    profileImage,
    isClaimed,
    isClaimableEntity,
    isProfileType: passedIsProfileType,
    asDiv,
    name,
    to,
    entity,
    linkProps: passedLinksProps,
    badge,
  } = props;

  const { styles } = useStyles(baseStyles, props);

  const isProfileType = profileImage || passedIsProfileType;

  const { isWindowSizeOrMore, isWindowSizeOrLess } = useWindowSize();
  const isMediumOrLess = isWindowSizeOrLess("medium");

  const feedActivityContext = useActivityContext();

  const handleClick = useCallback(
    (e) => {
      if (onClick) {
        onClick(e);
      } else {
        sendGAEvent({
          action: externalLink
            ? "externalEntityLinkClicked"
            : "entityLinkClicked",
          entity_type,
          entity_id: entity && entity.get("id"),
          entity_name: name,
          to,
          name,
          ...feedActivityContext,
        });
      }
    },
    [externalLink, entity_type, entity, to, name, feedActivityContext, onClick]
  );

  const LinkElement = useMemo(() => {
    if (asDiv || disableLink) {
      return "div";
    }
    if (externalLink) {
      return "a";
    }

    return Link;
  }, [asDiv, disableLink, externalLink]);

  const linkProps = useMemo(() => {
    if (passedLinksProps) {
      return passedLinksProps;
    }
    if (linkless || asDiv) {
      return {};
    }
    if (externalLink) {
      return {
        href: to,
        target: "_blank",
        rel: "noopener",
        "aria-label": name,
        onClick: handleClick,
      };
    }

    return {
      to,
      onClick: handleClick,
      "aria-label": name,
      smooth: "true",
      // scroll: useHashLink ? scrollWithOffset : undefined,
    };
  }, [externalLink, linkless, asDiv, to, handleClick, name, passedLinksProps]);

  const clampStyle = useMemo(() => {
    if (clampMaxHeight) {
      return {
        maxHeight: clampMaxHeight,
      };
    }

    return null;
  }, [clampMaxHeight]);

  const linkContent =
    isClaimed && isClaimableEntity && showClaimedBadge ? (
      <LazyLoadComponent
        renderOnMount={renderOnMount}
        height={CLAIMED_BADGE_HEIGHT}
      >
        <span>
          {children || name}
          <ClaimedBadge
            showLabel={false}
            showTooltip={isWindowSizeOrMore("medium")}
            {...claimedBadgeProps}
          />
        </span>
      </LazyLoadComponent>
    ) : (
      children || name
    );

  const renderedLink = linkless ? (
    linkContent
  ) : (
    <LinkElement
      data-id={dataId}
      className={`${css(
        !avalon && !noLinkFont && styles.entityLink,
        avalon && !noLinkFont && styles.entityLinkAvalon,
        clamp && styles.entityLinkWithClamp,
        clampStyle && styles.entityLinkWithClampStyle,
        styles.link,
        asDiv && styles.linklessLink,
        inline && styles.linkInline,
        isImage && styles.entityLinkImage
      )} ${className}`}
      style={clampStyle}
      {...linkProps}
    >
      {badge}
      {dotdotdot || clamp ? (
        <Dotdotdot
          className={css(inline && styles.linkInline)}
          clamp={clamp}
          tagName={"span"}
        >
          {linkContent}
        </Dotdotdot>
      ) : (
        linkContent
      )}
    </LinkElement>
  );

  const containedRenderedLink =
    !clamp && ellipsisOverflow ? (
      <span
        className={css(
          styles.ellipsisOverflow,
          isProfileType && styles.containedRenderedLinkInline,
          isProfileType && styles.profileEllipsisOverflow
        )}
      >
        {renderedLink}
      </span>
    ) : (
      renderedLink
    );

  if (isProfileType) {
    return disablePopup ? (
      renderedLink
    ) : (
      <ProfilePopover
        {...props}
        entity={entity}
        mobile={isMediumOrLess}
        entity_id={entity && entity.get("id")}
        entity_type={entity_type}
      >
        {containedRenderedLink}
      </ProfilePopover>
    );
  }

  return containedRenderedLink;
};

BaseEntityLink.propTypes = {
  dataId: PropTypes.string,
  entity: PropTypes.instanceOf(Map),
  entity_type: PropTypes.string.isRequired,
  entity_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
  avalon: PropTypes.bool,
  noLinkFont: PropTypes.bool,
  ellipsisOverflow: PropTypes.bool,
  clamp: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  clampMaxHeight: PropTypes.number,
  externalLink: PropTypes.bool,
  disablePopup: PropTypes.bool,
  dotdotdot: PropTypes.bool,
  disableLink: PropTypes.bool,
  showClaimedBadge: PropTypes.bool,
  nameOptions: PropTypes.object,
  className: PropTypes.string,
  linkless: PropTypes.bool,
  renderOnMount: PropTypes.bool,
  profileImage: PropTypes.bool,
};

BaseEntityLink.defaultProps = {
  dataId: "entity-link",
  entity_id: null,
  entity: null,
  children: null,
  avalon: false,
  noLinkFont: false,
  ellipsisOverflow: false,
  clamp: null,
  clampMaxHeight: null,
  externalLink: false,
  disablePopup: false,
  dotdotdot: false,
  disableLink: false,
  showClaimedBadge: false,
  claimedBadgeProps: null,
  nameOptions: {},
  className: "",
  linkless: false,
  renderOnMount: false,
  profileImage: false,
};

export default memo(BaseEntityLink);
