import Loadable from "@loadable/component";

import HomepageViewSkeleton from "./HomepageViewSkeleton";

const HomepageView = Loadable(() =>
  import(/* webpackChunkName: "HomepageView" */ "./HomepageView")
);

function HomepageViewAsyncLoading(props) {
  return <HomepageViewSkeleton />;
}

const HomepageViewContainer = (props) => {
  return <HomepageView {...props} fallback={<HomepageViewAsyncLoading />} />;
};

export default HomepageViewContainer;
