import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const SaveSearchModal = Loadable(() => import("./SaveSearchModal"));

function SaveSearchModalAsyncLoading(props) {
  return <LoadableLoading {...props} />;
}

const SaveSearchModalContainer = (props) => {
  return (
    <SaveSearchModal {...props} fallback={<SaveSearchModalAsyncLoading />} />
  );
};

export default SaveSearchModalContainer;
