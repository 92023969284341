import { faBolt } from "@fortawesome/free-solid-svg-icons/faBolt";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faCode } from "@fortawesome/free-solid-svg-icons/faCode";
import { faLock } from "@fortawesome/free-solid-svg-icons/faLock";
import { faNewspaper } from "@fortawesome/free-solid-svg-icons/faNewspaper";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons/faQuestionCircle";
import { faScroll } from "@fortawesome/free-solid-svg-icons/faScroll";
import { faGift } from "@fortawesome/pro-solid-svg-icons/faGift";
import { faMagic } from "@fortawesome/pro-solid-svg-icons/faMagic";
import { faPlus } from "@fortawesome/pro-solid-svg-icons/faPlus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import PropTypes from "prop-types";
import { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";

import HomepageSidebarSection from "./HomepageSidebarSection";

import authActionCreators from "actions/auth";
import modalActions from "actions/modals";
import * as authStates from "constants/auth-states";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import { useHasGeneratedAppToken } from "hooks/useAppData";
import { useLoggedIn } from "hooks/useLoggedInUser";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  row: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-start",
  },
  column: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    width: "50%",
    overflow: "hidden",
    textAlign: "left",

    ":first-child": {
      paddingRight: "1rem",
    },
  },
  link: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    marginTop: "1rem",

    [ScreenSizes.lgAndAbove]: {
      marginTop: ".5rem",
    },
    ":first-child": {
      marginTop: 0,
    },
  },
  linkIcon: {
    fontSize: "1.5rem",
    textAlign: "center",
    minWidth: "1.75rem",
    marginRight: "1rem",
    color: colours.greyishBlue,

    [ScreenSizes.lgAndAbove]: {
      fontSize: "1.25rem",
      minWidth: "1.5rem",
      marginRight: ".5rem",
    },
    ":hover": {
      color: colours.greyishBlueDarker,
    },
    ":focus": {
      color: colours.greyishBlueDarker,
    },
  },
  linkText: {
    ...gStyles.fontSemiBold,
    fontSize: ".875rem",

    [ScreenSizes.lgAndAbove]: {
      fontSize: ".75rem",
    },
  },
};

const HEADER = {
  title: "More",
};

const HomepageSidebarMore = (props) => {
  const { inFeed } = props;
  const { styles } = useStyles(baseStyles, props);
  const isLoggedIn = useLoggedIn();
  const hasGeneratedAppToken = useHasGeneratedAppToken();

  const { showModal, startAuthFlow } = useActionCreators({
    showModal: modalActions.showModal,
    startAuthFlow: authActionCreators.startAuthFlow,
  });

  const handleClick = useCallback(
    (link) => (e) => {
      if (link.action) {
        link.action(e);
      }
      sendGAEvent({
        action: "sidebarMoreClicked",
        link: link.id || link.label,
      });
    },
    []
  );

  const { leftLinks, rightLinks } = useMemo(() => {
    const lLinks = [
      {
        label: "Get Started",
        id: "getStarted",
        icon: faMagic,
        action: () => {
          if (isLoggedIn) {
            showModal("onboarding", { hideDoLater: true });
          } else {
            startAuthFlow(authStates.REGISTER_FORM, {
              showModalOnSuccess: {
                modalType: "onboarding",
                modalProps: { hideDoLater: true },
              },
              analyticsVariables: {
                triggered_by: "HomepageSidebarMoreGetStarted",
              },
            });
          }
        },
      },
      {
        label: "Add Podcast",
        id: "addPodcast",
        icon: faPlus,
        href: "/add",
        target: "_blank",
      },
      {
        label: "Claim a Podcast",
        id: "claimPodcast",
        icon: faCheck,
        href: "/claim",
        target: "_blank",
      },
      {
        id: "articles",
        label: "Articles",
        icon: faNewspaper,
        href: "/articles",
        target: "_blank",
      },
      {
        label: "Support",
        id: "support",
        icon: faQuestionCircle,
        href: "https://support.podchaser.com",
        target: "_blank",
      },
    ];

    const rLinks = [
      {
        label: "Podchaser Pro",
        id: "pro",
        icon: faBolt,
        href: "/pro",
        target: "_blank",
      },
      {
        label: "API",
        id: "api",
        icon: faCode,
        target: "_blank",
        ...(hasGeneratedAppToken
          ? {
              href: "/profile/settings/api",
            }
          : {
              href: "https://features.podchaser.com/api/",
            }),
      },
      {
        label: "What's New",
        id: "whatsNew",
        icon: faGift,
        href: "https://updates.podchaser.com/",
        target: "_blank",
      },
      {
        label: "Terms",
        id: "terms",
        icon: faScroll,
        href: "/terms.html",
        target: "_blank",
      },
      {
        label: "Privacy",
        id: "privacy",
        icon: faLock,
        href: "/privacy.html",
        target: "_blank",
      },
      /*
      {
        label: "Import OPML",
        icon: faFileUpload,
        action: () => {
          if (isLoggedIn) {
            showModal("onboarding", { onboardingStep: "podcasts" });
          }
          else {
            startAuthFlow(
              authStates.LOGIN_FORM,
              {
                entityAction: "action",
                actionTitle: "Login or create an account to import your podcasts from an OPML file",
              },
            );
          }
        },
      },
      */
    ];

    return {
      leftLinks: lLinks.filter((link) => !!link),
      rightLinks: rLinks.filter((link) => !!link),
    };
  }, [showModal, startAuthFlow, isLoggedIn, hasGeneratedAppToken]);

  const renderLink = (link) => {
    const LinkComponent = link.to ? Link : "a";

    return (
      <LinkComponent
        key={link.id}
        className={css(styles.link)}
        onClick={handleClick(link)}
        href={link.href}
        target={link.target}
        to={link.to}
      >
        <div className={css(styles.linkIcon)}>
          <FontAwesomeIcon icon={link.icon} />
        </div>
        <div className={css(styles.linkText)}>{link.label}</div>
      </LinkComponent>
    );
  };

  return (
    <HomepageSidebarSection
      header={HEADER}
      showAsList
      TitleComponent="h3"
      inFeed={inFeed}
    >
      <div className={css(styles.row)}>
        <div className={css(styles.column)}>{leftLinks.map(renderLink)}</div>
        <div className={css(styles.column)}>{rightLinks.map(renderLink)}</div>
      </div>
    </HomepageSidebarSection>
  );
};

HomepageSidebarMore.propTypes = {
  inFeed: PropTypes.bool,
};

HomepageSidebarMore.defaultProps = {
  inFeed: false,
};

export default HomepageSidebarMore;
