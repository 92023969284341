import Loadable from "@loadable/component";
const ApiTokenModal = Loadable(() => import("./ApiTokenModal"));

function ApiTokenModalAsyncLoading() {
  return null;
}

const ApiTokenModalContainer = (props) => {
  return <ApiTokenModal {...props} fallback={<ApiTokenModalAsyncLoading />} />;
};

export default ApiTokenModalContainer;
