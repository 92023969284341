import Loadable from "@loadable/component";

import TimelineSkeleton from "./TimelineSkeleton";

const Timeline = Loadable(() =>
  import(/* webpackChunkName: "Timeline" */ "./Timeline")
);

function TimelineLoading(props) {
  return <TimelineSkeleton key="TimelineContainer" />;
}

const TimelineContainer = (props) => {
  return <Timeline {...props} fallback={<TimelineLoading />} />;
};

export default TimelineContainer;
