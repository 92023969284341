import { Map } from "immutable";

export const selectTimeline = (state, timelineKey) =>
  timelineKey &&
  state.timeline &&
  state.timeline.getIn(["timelines", timelineKey?.toString()], Map());

export const selectActivities = (state, timelineKey) =>
  state.timeline && selectTimeline(state, timelineKey).get("activities");

export const selectTimelineLoading = (state, timelineKey) => {
  if (state.timeline) {
    const timeline = selectTimeline(state, timelineKey);

    return timeline?.get("loading");
  }
};
