import getBrandSlug from "./getBrandSlug";

import { formatURL } from "utils/format";

export default (entity) => entity && entity.get("url");

export const getNetworkUrl = (networkId, networkName) => {
  if (!networkId) {
    return null;
  }

  const netWorkUrl = `/networks/${getBrandSlug(networkId, networkName)}`;

  return formatURL(netWorkUrl);
};

export const getNetworkUrlByEntity = (network) => {
  if (!network) {
    return null;
  }

  return getNetworkUrl(
    network?.get("id") || network?.getIn(["_source", "id"]),
    network?.get("title") || network?.getIn(["_source", "title"])
  );
};

export const getNetworkSlug = (network) => {
  return getBrandSlug(
    network?.get("id") || network?.getIn(["_source", "id"]),
    network?.get("title") || network?.getIn(["_source", "title"])
  );
};
