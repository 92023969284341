import { memo, useEffect, useMemo, useCallback, useContext } from "react";

import RequestContext from "pages/RequestContext";

import { userHasProPermission } from "utils/entity/user";

import useLoadScript from "hooks/useLoadScript";
import useLoggedInUser from "hooks/useLoggedInUser";
import useWindowScripts from "hooks/useWindowScripts";

const domain = "https://widget.intercom.io";

const IntercomLoader = () => {
  const requestContext = useContext(RequestContext);
  const { setScript } = useWindowScripts();
  const loggedInUser = useLoggedInUser();
  const pro_paid_user = userHasProPermission(loggedInUser);

  const intercomSettings = useMemo(() => {
    let settings = { app_id: "abebagrx" };

    if (loggedInUser) {
      settings = {
        ...settings,
        user_id: loggedInUser.get("id"),
        email: loggedInUser.get("email"),
        name: loggedInUser.get("username"),
        user_hash: loggedInUser.get("intercom_user_hash"),
        pro_paid_user,
        avatar: {
          type: "avatar",
          image_url: loggedInUser.get("profile_image_url"),
        },
      };
    }

    return settings;
  }, [loggedInUser, pro_paid_user]);

  const loadScript = useCallback(
    (passed = {}) => {
      const w = window;
      const ic = w.Intercom;

      if (typeof ic === "function") {
        ic("reattach_activator");
        // eslint-disable-next-line no-undef
        ic("update", intercomSettings);

        if (passed.loaded) {
          setScript("intercom", ic);
        }
      } else {
        const d = document;

        // eslint-disable-next-line no-inner-declarations
        const s = d.createElement("script");

        s.type = "text/javascript";
        s.defer = true;
        s.async = false;
        s.src = `${domain}/widget/abebagrx`;

        s.addEventListener(
          "load",
          () => {
            loadScript({ loaded: true });
          },
          false
        );

        const x = d.getElementsByTagName("script")[0];

        x.parentNode.insertBefore(s, x);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [intercomSettings]
  );

  useEffect(() => {
    if (window && window.Intercom && loggedInUser) {
      loadScript();
    } else if (window && window.Intercom) {
      window.Intercom("shutdown");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUser]);

  useLoadScript("intercom", loadScript, {
    disable: requestContext.isTestingEnv,
  });

  return null;
};
export default memo(IntercomLoader);
