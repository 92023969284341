import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useContext, useMemo } from "react";

import ActivityContext from "components/Analytics/ActivityContext";
import { TimelineContext } from "components/Analytics/TimelineProvider";
import AppearActivity from "components/Timeline/Activities/AppearActivityAsync";
import FollowActivity from "components/Timeline/Activities/FollowActivityAsync";
import IntegrationVoteActivity from "components/Timeline/Activities/IntegrationVoteActivityAsync";
import LikedActivity from "components/Timeline/Activities/LikedActivityAsync";
import RateActivity from "components/Timeline/Activities/RateActivityAsync";
import ReleaseActivity from "components/Timeline/Activities/ReleaseActivityAsync";
import ReviewActivity from "components/Timeline/Activities/ReviewActivityAsync";
import ReviewReplyActivity from "components/Timeline/Activities/ReviewReplyActivityAsync";
import UserEarnedBadgeActivity from "components/Timeline/Activities/UserEarnedBadgeActivityAsync";
import UserListActivity from "components/Timeline/Activities/UserListActivityAsync";

const TimelineActivitySelector = (props) => {
  // return different activities based on verbs, actors, etc
  const { activity } = props;
  const timelineContext = useContext(TimelineContext);

  let card = <div>{activity.get("id")}</div>;
  let feed_card_type = "Unknown";

  switch (activity.get("verb")) {
    case "rate":
      card = <RateActivity {...props} />;
      feed_card_type = "rate";
      break;
    case "review":
      card = <ReviewActivity {...props} />;
      feed_card_type = "review";
      break;
    case "review_reply":
      card = <ReviewReplyActivity {...props} />;
      feed_card_type = "review_reply";
      break;
    case "appear":
      card = <AppearActivity {...props} />;
      feed_card_type = "appear";
      break;
    case "follow":
      card = <FollowActivity {...props} />;
      feed_card_type = "follow";
      break;
    case "release":
      card = <ReleaseActivity {...props} />;
      feed_card_type = "release";
      break;
    case "like":
      card = <LikedActivity {...props} />;
      feed_card_type = "liked";
      break;
    case "add_item_to_list":
    case "publish_list":
      card = <UserListActivity {...props} />;
      feed_card_type = "userlist";
      break;
    case "integration_vote":
      card = <IntegrationVoteActivity {...props} />;
      feed_card_type = "integration_vote";
      break;
    case "award_badge":
      card = <UserEarnedBadgeActivity {...props} />;
      feed_card_type = "badge_awarded";
      break;
    default:
      card = null;
      break;
  }

  const contextValue = useMemo(
    () => ({
      ...(timelineContext || {}),
      feed_card_type,
      in_feed: true,
    }),
    [feed_card_type, timelineContext]
  );

  return (
    <ActivityContext.Provider value={contextValue}>
      {card}
    </ActivityContext.Provider>
  );
};

TimelineActivitySelector.propTypes = {
  activity: PropTypes.instanceOf(Map),
};

TimelineActivitySelector.defaultProps = {
  activity: Map(),
};

export default memo(TimelineActivitySelector);
