import { useEffect } from "react";

export default function useWindowSizeChangeEffect(effect) {
  useEffect(() => {
    window.addEventListener("resize", effect);

    return () => {
      window.removeEventListener("resize", effect);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
