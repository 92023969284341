/* eslint-disable max-len */

export default {
  prefix: "fpc",
  iconName: "pc-list",
  icon: [
    570,
    512,
    [],
    null,
    "M472.121212,467.097575 L472.121212,489.848147 L391.515152,481.094345 L391.515152,37.5683984 L472.121212,28.8145969 L472.121212,68.5528206 L472.121212,467.097575 Z M500.909091,447.559835 L500.909091,65.3399892 L570,57.6291937 L570,455.270631 L500.909091,447.559835 Z M362.727273,495.433906 L362.727273,512.899824 L0,473.945407 L0,38.954417 L362.727273,0 L362.727273,40.6947562 L362.727273,495.433906 Z",
  ],
};
