export const selectTagAutocompleteLoading = (key, state) =>
  state.tag.getIn(["tags", "autocomplete", key, "loading"]);

export const selectTagAutocompleteResults = (key, state) =>
  state.tag.getIn(["tags", "autocomplete", key, "results"]);

export const selectSpecificTag = (state, slug) =>
  slug && state.tag.getIn(["tags", "entities", slug?.toString()]);
export const selectTagLoading = (state, slug = "") =>
  state.tag.getIn(["tags", "loading", state, slug?.toString()]) || false;
export const selectTagFailed = (state, slug = "") =>
  state.tag.getIn(["tags", "failed", state, slug?.toString()]) || false;
