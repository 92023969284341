import getUserDisplayName from "utils/entity/getUserDisplayName";

export function getEntityImageAlt(entity, type) {
  if (!entity) {
    return null;
  }

  switch (type) {
    case "creator":
      return entity.get("name");
    case "user":
      return getUserDisplayName(entity);
    default:
      return entity.get("title");
  }
}

export default function getEntityImageTitle(entity, type) {
  if (!entity) {
    return null;
  }

  switch (type) {
    case "creator":
      return entity.get("name");
    case "user":
      return getUserDisplayName(entity);
    default:
      return entity.get("title");
  }
}
