import { css } from "aphrodite";
import PropTypes from "prop-types";
import React from "react";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  podcastImageSkeleton: {
    ...gStyles.skeletonPlaceholder,
    height: "13.63rem",
    marginBottom: "1.25rem",
    [ScreenSizes.mdAndAbove]: {
      height: "11.3rem",
    },
    [ScreenSizes.xxlAndAbove]: {
      height: "6.13rem",
    },
  },
};

const BrandViewEpisodesSkeletonLoading = (props) => {
  const { numberOfLoadingComponents } = props;
  const { styles } = useStyles(baseStyles, props);

  const LoadingComponents = [];

  for (let index = 0; index < numberOfLoadingComponents; index++) {
    LoadingComponents.push(
      <li key={index}>
        <div
          data-testid={`podcastEpisodeSkeleton-${index}`}
          className={css(styles.podcastImageSkeleton)}
        />
      </li>
    );
  }

  return LoadingComponents;
};

BrandViewEpisodesSkeletonLoading.propTypes = {
  numberOfLoadingComponents: PropTypes.number.isRequired,
};

export default BrandViewEpisodesSkeletonLoading;
