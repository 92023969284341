const groupBy = (list, keyGetter) =>
  list.reduce((acc, item) => {
    const key = keyGetter(item);

    if (!key) {
      return acc;
    }

    acc[key] = acc[key] ? acc[key].concat(item) : [item];

    return acc;
  }, {});

export default groupBy;
