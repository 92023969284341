import { equals } from "utils/misc";

const ALL_SIZES = [
  "tiny",
  "xsmall",
  "small",
  "medium",
  "large",
  "xlarge",
  "xxlarge",
];
const SM_UP_SIZES = ["small", "medium", "large", "xlarge", "xxlarge"];
const MD_UP_SIZES = ["medium", "large", "xlarge", "xxlarge"];
const LG_UP_SIZES = ["large", "xlarge", "xxlarge"];
const XL_UP_SIZES = ["xlarge", "xxlarge"];
const XX_UP_SIZES = ["xxlarge"];

export function isSizeOrMore(currentSize, size) {
  let isMatch = false;
  switch (size) {
    case "tiny":
      isMatch = !equals(ALL_SIZES.indexOf(currentSize), -1);
      break;
    case "xsmall":
      isMatch = !equals(ALL_SIZES.indexOf(currentSize), -1);
      break;
    case "small":
      isMatch = !equals(SM_UP_SIZES.indexOf(currentSize), -1);
      break;
    case "medium":
      isMatch = !equals(MD_UP_SIZES.indexOf(currentSize), -1);
      break;
    case "large":
      isMatch = !equals(LG_UP_SIZES.indexOf(currentSize), -1);
      break;
    case "xlarge":
      isMatch = !equals(XL_UP_SIZES.indexOf(currentSize), -1);
      break;
    case "xxlarge":
      isMatch = !equals(XX_UP_SIZES.indexOf(currentSize), -1);
      break;
  }
  return isMatch;
}

const XS_DOWN_SIZES = ["tiny", "xsmall"];
const SM_DOWN_SIZES = ["tiny", "xsmall", "small"];
const MD_DOWN_SIZES = ["tiny", "xsmall", "small", "medium"];
const LG_DOWN_SIZES = ["tiny", "xsmall", "small", "medium", "large"];
const XL_DOWN_SIZES = ["tiny", "xsmall", "small", "medium", "large", "xlarge"];
const XX_DOWN_SIZES = [
  "tiny",
  "xsmall",
  "small",
  "medium",
  "large",
  "xlarge",
  "xxlarge",
];

export function isSizeOrLess(currentSize, size) {
  let isMatch = false;
  switch (size) {
    case "tiny":
      isMatch = !equals(XS_DOWN_SIZES.indexOf(currentSize), -1);
      break;
    case "small":
      isMatch = !equals(SM_DOWN_SIZES.indexOf(currentSize), -1);
      break;
    case "medium":
      isMatch = !equals(MD_DOWN_SIZES.indexOf(currentSize), -1);
      break;
    case "large":
      isMatch = !equals(LG_DOWN_SIZES.indexOf(currentSize), -1);
      break;
    case "xlarge":
      isMatch = !equals(XL_DOWN_SIZES.indexOf(currentSize), -1);
      break;
    case "xxlarge":
      isMatch = !equals(XX_DOWN_SIZES.indexOf(currentSize), -1);
      break;
  }
  return isMatch;
}

export function isSizesOrBetween(currentSize, minSize, maxSize) {
  return (
    isSizeOrMore(currentSize, minSize) && isSizeOrLess(currentSize, maxSize)
  );
}
