import Loadable from "@loadable/component";
const SharedEpisodeWithTimeModal = Loadable(() =>
  import("./SharedEpisodeWithTimeModal")
);

function SharedEpisodeWithTimeModalAsyncLoading() {
  return null;
}

const SharedEpisodeWithTimeModalContainer = (props) => {
  return (
    <SharedEpisodeWithTimeModal
      {...props}
      fallback={<SharedEpisodeWithTimeModalAsyncLoading />}
    />
  );
};

export default SharedEpisodeWithTimeModalContainer;
