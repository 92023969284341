import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const SideMenuAsync = Loadable(() => import("./index"));

function SideMenuAsyncLoading(props) {
  return <LoadableLoading {...props} />;
}

const SideMenuAsyncContainer = (props) => {
  return <SideMenuAsync {...props} fallback={<SideMenuAsyncLoading />} />;
};

export default SideMenuAsyncContainer;
