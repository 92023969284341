import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const CreatorEdit = Loadable(() => import("./CreatorEdit"));

function CreatorEditAsyncLoading(props) {
  return <LoadableLoading {...props} page />;
}

const CreatorEditContainer = (props) => {
  return <CreatorEdit {...props} fallback={<CreatorEditAsyncLoading />} />;
};

export default CreatorEditContainer;
