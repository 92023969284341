import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const Error404 = Loadable(() =>
  import(/* webpackChunkName: "Error404" */ "./Error404")
);

function Error404Loading(props) {
  return <LoadableLoading {...props} />;
}

const Error404Container = (props) => {
  return <Error404 {...props} fallback={<Error404Loading />} />;
};

export default Error404Container;
