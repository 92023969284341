import Loadable from "@loadable/component";
const SingleReviewModal = Loadable(() => import("./SingleReviewModal"));

function AboutAsyncLoading() {
  return null;
}

const SingleReviewModalContainer = (props) => {
  return <SingleReviewModal {...props} fallback={<AboutAsyncLoading />} />;
};

export default SingleReviewModalContainer;
