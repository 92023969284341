import Loadable from "@loadable/component";
const EntityFollowModal = Loadable(() => import("./EntityFollowModal"));

function AboutAsyncLoading() {
  return null;
}

const EntityFollowModalContainer = (props) => {
  return <EntityFollowModal {...props} fallback={<AboutAsyncLoading />} />;
};

export default EntityFollowModalContainer;
