import { css } from "aphrodite";
import { memo } from "react";

import LoadingOverlay from "components/Common/LoadingOverlay";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    minHeight: 350,
    width: "100%",
  },
  title: {
    ...gStyles.fontSemiBold,
    fontSize: "1.5rem",
    color: colours.greyishBlue,
    marginBottom: "2rem",
  },
};

const loadingOverlayStyles = {
  noOverlay: {
    flex: 0,
    fontSize: "2rem",
    maxWidth: "2em",
  },
};

const SocialLoginLoading = (props) => {
  const { styles } = useStyles(baseStyles, props);

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.title)}>Connecting to your account</div>
      <LoadingOverlay
        styles={loadingOverlayStyles}
        noOverlay
        noPadding
        key="socialLoginLoadingOverlay"
      />
    </div>
  );
};

export default memo(SocialLoginLoading);
