export const entityNames = {
  category: "category",
  creator: "creator",
  episode: "episode",
  podcast: "podcast",
  review: "review",
  tag: "tag",
  user: "user",
  userlist: "list",
  integration: "integration",
  badge: "badge",
  all: "all",
};

export const entityNamesToTypes = Object.entries(entityNames).reduce(
  (reversed, [type, name]) => ({ ...reversed, [name]: type }),
  {}
);

export const SUBSCRIBABLE_ENTITY_TYPES = ["podcast", "creator"];
