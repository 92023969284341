import getEpisodeImageUrl from "./getEpisodeImageUrl";

import { MISSING_IMAGE_URL, MISSING_PROFILE_IMAGE_URL } from "constants/base";
import { getIntegrationImageUrl } from "utils/entity/integration";

export default function getEntityImageUrl(entity, type) {
  if (!entity) {
    return null;
  }

  switch (type) {
    case "podcast":
    case "network":
      return entity.get("image_url");
    case "episode":
      return typeof entity.get("podcast") === "object"
        ? getEpisodeImageUrl(entity.get("podcast"), entity)
        : MISSING_IMAGE_URL;

    case "review":
      return entity.get("entity") && typeof entity.get("entity") === "object"
        ? entity.getIn(["entity", "image_url"]) ||
            entity.getIn(["entity", "profile_image_url"])
        : entity.get("image_url") ||
            entity.get("profile_image_url") ||
            MISSING_IMAGE_URL;
    case "creator":
    case "user":
      return entity.get("profile_image_url") || MISSING_PROFILE_IMAGE_URL;
    case "integration":
      return getIntegrationImageUrl(entity);
    case "badge":
      return entity.get("badge_image_url") || MISSING_IMAGE_URL;
    default:
      return MISSING_IMAGE_URL;
  }
}
