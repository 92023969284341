import Loadable from "@loadable/component";
const HomepageTourPrompt = Loadable(() => import("./HomepageTourPrompt"));

function AboutAsyncLoading() {
  return null;
}

const HomepageTourPromptContainer = (props) => {
  return <HomepageTourPrompt {...props} fallback={<AboutAsyncLoading />} />;
};

export default HomepageTourPromptContainer;
