import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const HomepageSidebarMyPodcasts = Loadable(() =>
  import("./HomepageSidebarMyPodcasts")
);

function HomepageSidebarMyPodcastsAsyncLoading(props) {
  return <LoadableLoading {...props} transparent />;
}

const HomepageSidebarMyPodcastsContainer = (props) => {
  return (
    <HomepageSidebarMyPodcasts
      {...props}
      fallback={<HomepageSidebarMyPodcastsAsyncLoading />}
    />
  );
};

export default HomepageSidebarMyPodcastsContainer;
