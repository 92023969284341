import Loadable from "@loadable/component";
const CreatorProfileHoverPopover = Loadable(() =>
  import("./CreatorProfileHoverPopover")
);

function PopoverLoading() {
  return null;
}

const CreatorProfileHoverPopoverContainer = (props) => {
  return (
    <CreatorProfileHoverPopover {...props} fallback={<PopoverLoading />} />
  );
};

export default CreatorProfileHoverPopoverContainer;
