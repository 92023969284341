import { css } from "aphrodite";
import PropTypes from "prop-types";

import BadgeToolTip from "./BadgeTooltip";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";

const baseStyles = {
  userProfileImage: {
    width: "100%",
    height: "100%",
    backgroundImage: `linear-gradient(${colours.badgeHighlight}, #f7b500)`,
    padding: "0.25rem",
    borderRadius: "50%",
  },
  container: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  badgeContainer: {
    position: "absolute",
    left: 0,
    bottom: 0,
    cursor: "pointer",
  },
  badge: {
    width: "3.125rem",
    height: "3.125rem",
  },
};

const Badge = (props) => {
  const {
    description,
    badgeUrl,
    children,
    name,
    disableTooltip,
    onHover,
    onClick,
    badgeContainerComponent: ContainerComponent,
    badgeContainerProps,
  } = props;

  const { styles } = useStyles(baseStyles, props);

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.userProfileImage)}>{children}</div>
      <ContainerComponent
        className={css(styles.badgeContainer)}
        {...badgeContainerProps}
      >
        {disableTooltip ? (
          <img
            src={badgeUrl}
            className={css(styles.badge)}
            alt="User Badge Icon"
          />
        ) : (
          <BadgeToolTip
            bagdeStyles={css(styles.badge)}
            badgeUrl={badgeUrl}
            description={description}
            name={name}
            onHover={onHover}
            onClick={onClick}
          />
        )}
      </ContainerComponent>
    </div>
  );
};

Badge.propTypes = {
  description: PropTypes.string.isRequired,
  badgeUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
  disableTooltip: PropTypes.bool,
  onHover: PropTypes.func,
  onClick: PropTypes.func,
  badgeContainerComponent: PropTypes.node,
  badgeContainerProps: PropTypes.object,
};

Badge.defaultProps = {
  children: null,
  disableTooltip: false,
  badgeContainerComponent: "div",
  badgeContainerProps: {},
};

export default Badge;
