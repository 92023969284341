import { createRoutine, promisifyRoutine } from "redux-saga-routines";

export const checkUsername = createRoutine("CHECK_USERNAME");
export const checkUsernamePromise = promisifyRoutine(checkUsername);
export const checkEmail = createRoutine("CHECK_EMAIL");
export const checkEmailPromise = promisifyRoutine(checkEmail);
export const updateProfileImage = createRoutine("UPDATE_PROFILE_IMAGE");
export const updateProfileImagePromise = promisifyRoutine(updateProfileImage);
export const updatePassword = createRoutine("UPDATE_PASSWORD");
export const updatePasswordPromise = promisifyRoutine(updatePassword);
export const updateProfile = createRoutine("UPDATE_PROFILE");
export const updateProfilePromise = promisifyRoutine(updateProfile);
export const saveTop8 = createRoutine("SAVE_TOP_8");
export const sendEmailVerification = createRoutine("SEND_EMAIL_VERIFICATION");
export const sendEmailVerificationPromise = promisifyRoutine(
  sendEmailVerification
);
export const regenerateUserImage = createRoutine("REGENERATE_USER_IMAGE");
export const verifyEmail = createRoutine("VERIFY_EMAIL");
export const verifyEmailPromise = promisifyRoutine(verifyEmail);
