import { useMemo, useRef } from "react";
import { bindActionCreators } from "redux";
import { useDispatch } from "redux-react-hook";

function useActionCreators(actions) {
  const dispatch = useDispatch();

  // let's us pass in an object that's created in the render, but won't change
  const actionsRef = useRef(actions);

  return useMemo(
    () => bindActionCreators(actionsRef.current, dispatch),
    [dispatch]
  );
}

export default useActionCreators;
