import { call, cancel, fork, take } from "redux-saga/effects";

import loadList, { getApiProps } from "./loadList";

import * as podcastApi from "api/live/podcast";
import * as paginationConstants from "constants/pagination";
import { apiCall } from "sagas/api";
import onEpisodeListLoad from "sagas/podcast/onEpisodeListLoad";

export default function* loadEpisodesList(action) {
  const { apiProps, pagination } = yield call(getApiProps, action);

  const response = yield call(apiCall, {
    fn: podcastApi.loadEpisodesFilterList,
    data: apiProps,
  });

  yield call(apiCall, {
    fn: loadList,
    data: {
      list_type: "episodes_filtered",
      action,
      pagination,
      pageListener: onEpisodeListLoad,
      apiProps,
      response,
    },
  });
}

export function* loadEpisodesListWatch() {
  const tasksByKey = {};

  while (true) {
    const action = yield take(paginationConstants.LOAD_EPISODES_LIST);
    if (tasksByKey[action.key]) {
      yield cancel(tasksByKey[action.key]);
    }
    tasksByKey[action.key] = yield fork(loadEpisodesList, action);
  }
}

loadEpisodesListWatch.displayName = "loadEpisodesListWatch";
