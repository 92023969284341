export default function getEpisodeImageUrl(
  podcast,
  episode /* , width = 256 */
) {
  /* const cachedPodcastImageUrl = podcast &&
    podcast.get("image_url") &&
    cachedImage(podcast.get("image_url"), width && (maybeRemToPx(width) * 2));

  const cachedEpisodeImageUrl = episode &&
    episode.get("image_url") &&
    cachedImage(episode.get("image_url"), width && (maybeRemToPx(width) * 2));*/

  const finalEpisodeImageUrl =
    /* cachedEpisodeImageUrl ||
    cachedPodcastImageUrl ||*/
    episode?.get("image_url") || podcast?.get("image_url");

  return finalEpisodeImageUrl;
}

export function getRawEpisodeImageUrl(podcast, episode) {
  return episode?.get("image_url") || podcast?.get("image_url");
}
