import { faCircleNotch } from "@fortawesome/free-solid-svg-icons/faCircleNotch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css, StyleSheet } from "aphrodite";
import PropTypes from "prop-types";
import { forwardRef, memo, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";

import accessibleClickProps from "utils/misc/accessibleClickProps";
import withHover from "utils/react-with-hover";
import sendGAEvent from "utils/sendGAEvent";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

/* TODO - it really isn't necessary for this to be its own class, all it's really doing at this point is holding the styles for the container   */
const AbstractButton = forwardRef((props, ref) => {
  const {
    label,
    onClick,
    primary,
    secondary,
    hover,
    hoverStyles,
    customStyles,
    activeStyles,
    type,
    disabled,
    showDisabled,
    disabledStyles,
    dataId,
    dataValue,
    style,
    aphStyles,
    overwriteStyles,
    anchor,
    link,
    to,
    href,
    submitting,
    renderLabel,
    outerComponent,
    gaAction,
    gaProps = {},
    preventDefault,
    stopPropagation,
    ariaLabel,
    buttonProps,
    accessible,
    accessibleOptions,
    className,
    ...rest
  } = props;

  // cleans out props that shouldn't be passed to dom
  delete rest.toggleMenu;
  delete rest.hoverProps;
  delete rest.flat;
  delete rest.dataId;
  delete rest.isPlayed;
  delete rest.entity;

  const { styles } = useStyles(
    {
      container: {
        display: "block",
        width: "100%",
        height: "auto",
        fontSize: "1em",
        padding: 0,
        border: 0,
        textAlign: "center",
        color: "var(--color-neutral-d3)",
        backgroundColor: "transparent",
        cursor: "pointer",
        ":focus": {
          outline: "none",
        },
        appearance: "none",

        ":hover": !(disabled && showDisabled)
          ? hoverStyles || {
              backgroundColor: "white",
            }
          : {},
      },
      fontStyles: gStyles.avalonBold,
      primary: {
        background: colours.primary,
        color: colours.white,
      },
      secondary: {
        background: colours.secondary,
        color: colours.white,
      },
      active: activeStyles || {
        backgroundColor: primary ? colours.primaryHighlight : colours.offWhite,
      },
      custom: customStyles || {},
      disabled: disabledStyles,
      buttonLoading: {
        maxWidth: "1.5em",
      },
    },
    props
  );

  const { overwriteStylesheet } = useMemo(
    () => ({
      overwriteStylesheet: StyleSheet.create({
        ...overwriteStyles,
        button: overwriteStyles.button
          ? {
              ...overwriteStyles.button,
              ...(overwriteStyles.hover && !(disabled && showDisabled)
                ? { ":hover": overwriteStyles.hover }
                : {}),
            }
          : {},
      }),
    }),
    [overwriteStyles, disabled, showDisabled]
  );

  const labelFinal = renderLabel ? renderLabel({ hover }) : label;
  const ComponentType = useMemo(() => {
    let componentType = "button";

    if (anchor) {
      componentType = "a";
    } else if (link) {
      componentType = Link;
    }

    if (outerComponent) {
      componentType = outerComponent;
    }

    if (disabled && componentType !== "button") {
      return "div";
    }

    return componentType;
  }, [anchor, link, disabled, outerComponent]);

  const handleClick = useCallback(
    (e) => {
      if (gaAction) {
        sendGAEvent({
          action: gaAction,
          ...gaProps,
        });
      }

      if (preventDefault) {
        e.preventDefault();
      }
      if (stopPropagation) {
        e.stopPropagation();
      }

      if (onClick) {
        onClick(e);
      }
    },
    [onClick, gaAction, gaProps, preventDefault, stopPropagation]
  );

  const accessibleProps = useMemo(() => {
    if (accessible && !disabled) {
      return accessibleClickProps(handleClick, {
        preventDefault: false,
        stopPropagation: false,
        ...accessibleOptions,
      });
    }

    return {};
  }, [handleClick, accessible, disabled, accessibleOptions]);

  return (
    <ComponentType
      type={link || anchor ? undefined : type}
      onClick={disabled || submitting ? null : handleClick}
      {...accessibleProps}
      className={`${css(
        styles.container,
        style,
        styles.fontStyles,
        ...aphStyles,
        primary && styles.primary,
        secondary && styles.secondary,
        styles.custom,
        disabled && showDisabled && styles.disabled,
        overwriteStylesheet && overwriteStylesheet.button,
        overwriteStylesheet &&
          disabled &&
          showDisabled &&
          overwriteStylesheet.disabled
      )} ${className}`}
      role={link || anchor ? undefined : "button"}
      data-id={dataId}
      data-testid={dataId}
      data-value={dataValue}
      aria-label={ariaLabel}
      to={anchor ? undefined : to || href || link || undefined}
      href={anchor ? href || to || link || undefined : undefined}
      ref={ref}
      {...(disabled && { disabled: "disabled" })}
      {...buttonProps}
      {...rest}
    >
      {submitting ? (
        <FontAwesomeIcon
          icon={faCircleNotch}
          className={css(styles.buttonLoading)}
          spin
        />
      ) : (
        labelFinal
      )}
    </ComponentType>
  );
});

AbstractButton.propTypes = {
  label: PropTypes.any,
  onClick: PropTypes.func,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  hover: PropTypes.bool,
  submitting: PropTypes.bool,
  customStyles: PropTypes.object,
  type: PropTypes.string,
  activeStyles: PropTypes.object,
  hoverStyles: PropTypes.object,
  disabledStyles: PropTypes.object,
  disabled: PropTypes.bool,
  showDisabled: PropTypes.bool,
  dataId: PropTypes.string,
  dataValue: PropTypes.string,
  style: PropTypes.object,
  aphStyles: PropTypes.array,
  overwriteStyles: PropTypes.object,
  anchor: PropTypes.bool,
  link: PropTypes.any, // this is a bool, we're going by is passed exists so could be anything
  href: PropTypes.string,
  renderLabel: PropTypes.func,
  outerComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  gaAction: PropTypes.string,
  gaProps: PropTypes.object,
  preventDefault: PropTypes.bool,
  stopPropagation: PropTypes.bool,
  ariaLabel: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  accessible: PropTypes.bool,
  accessibleOptions: PropTypes.object,
  buttonProps: PropTypes.object,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
};

AbstractButton.defaultProps = {
  label: null,
  primary: false,
  secondary: false,
  hover: false,
  submitting: false,
  customStyles: {},
  activeStyles: {},
  hoverStyles: {},
  type: "button",
  onClick: () => null,
  disabledStyles: {},
  disabled: false,
  showDisabled: true,
  dataId: "button",
  dataValue: null,
  style: null,
  aphStyles: [],
  overwriteStyles: {},
  anchor: false,
  link: false,
  href: null,
  renderLabel: null,
  gaAction: null,
  gaProps: null,
  preventDefault: false,
  stopPropagation: false,
  ariaLabel: null,
  accessible: false,
  accessibleOptions: {},
  buttonProps: null,
  to: null,
  className: "",
};

AbstractButton.displayName = "AbstractButton";

export default memo(withHover()(AbstractButton));
