import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const HomepageSidebarMenu = Loadable(() => import("./HomepageSidebarMenu"));

function HomepageSidebarMenuAsyncLoading(props) {
  return <LoadableLoading {...props} transparent />;
}

const HomepageSidebarMenuContainer = (props) => {
  return (
    <HomepageSidebarMenu
      {...props}
      fallback={<HomepageSidebarMenuAsyncLoading />}
    />
  );
};

export default HomepageSidebarMenuContainer;
