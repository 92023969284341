import Loadable from "@loadable/component";

import TimelineSkeletonItem from "components/Timeline/TimelineSkeletonItem";

const ReviewReplyActivity = Loadable(() =>
  import(/* webpackChunkName: "ReviewReplyActivity" */ "./ReviewReplyActivity")
);

function ReviewReplyActivityLoading(props) {
  return <TimelineSkeletonItem />;
}

const ReviewReplyActivityContainer = (props) => {
  return (
    <ReviewReplyActivity {...props} fallback={<ReviewReplyActivityLoading />} />
  );
};

export default ReviewReplyActivityContainer;
