import truncateUrlSlug from "./truncateUrlSlug";

import { formatURL } from "utils/format";

export default (podcast) => {
  if (podcast) {
    const podcastURL = `/podcasts/${truncateUrlSlug(
      podcast.get("slug")
    )}-${podcast.get("id")}/insights`;

    return formatURL(podcastURL);
  }

  return podcast;
};
