import PodchaserBrandLogo from "components/Branding/PodchaserBrandLogo";

import { useLoggedIn } from "hooks/useLoggedInUser";
import useLoggedInUser from "hooks/useLoggedInUser";
import useMemo from "hooks/useLoggedMemo";
import useUserHasPro from "hooks/useUserHasPro";
import useWindowSize from "hooks/useWindowSize";

const HeaderStandardLogo = () => {
  const { isWindowSizeOrMore } = useWindowSize();
  const isLargeOrMore = isWindowSizeOrMore("large");

  const loggedIn = useLoggedIn();
  const user = useLoggedInUser();
  const hasPro = useUserHasPro();

  const logoProps = useMemo(
    () => ({
      iconOnly: !isLargeOrMore,
      textSize: "medium",
      ...(!isLargeOrMore && {
        width: "3rem",
        height: "3rem",
      }),
    }),
    [isLargeOrMore]
  );

  return (
    <PodchaserBrandLogo
      {...logoProps}
      headerLogo
      loggedInProUser={loggedIn && hasPro}
      key={`PodchaserBrandLogo-${user?.get("id")}`}
    />
  );
};

export default HeaderStandardLogo;
