import { css } from "aphrodite";
import PropTypes from "prop-types";
import { useCallback, useContext, useMemo, useState } from "react";
import * as React from "react";
import { Img } from "react-image";

import LazyLoadComponent from "components/Common/LazyLoad/LazyLoadComponent";
import RequestContext from "pages/RequestContext";

import { MISSING_IMAGE_URL, MISSING_PROFILE_IMAGE_URL } from "constants/base";
import generateTransition from "utils/generateTransition";
import isRetina from "utils/isRetina";

import { useStyles } from "hooks/useStyles";

const baseStyles = {
  imageFallback: {
    display: "block",
  },
  imageFallbackLoaded: {},
  imageFallbackWithAnimation: {
    opacity: 0,
    transformOrigin: "center center",
    transition: generateTransition({ target: "opacity", speed: "500ms" }),
  },
  imageFallbackWithAnimationLoaded: {
    opacity: 1,
    transform: "scale(1,1)",
  },
  imageLazyLoaded: {
    aspectRatio: "1 / 1",
  },
};

const ImageFallback = React.forwardRef((props, ref) => {
  const {
    animate,
    className,
    imgType,
    onLoad,
    renderOnMount,
    src: passedSrc,
    srcs,
    useImgElement,
    ...rest
  } = props;

  const { styles } = useStyles(baseStyles, props);

  const missingSrc =
    imgType === "person" ? MISSING_PROFILE_IMAGE_URL : MISSING_IMAGE_URL;

  const { isCrawler } = useContext(RequestContext);

  const [loaded, setLoaded] = useState(false);
  const [animationDone, setAnimationDone] = useState(animate);

  const src = useMemo(() => {
    let newSrc = passedSrc;

    if (!Array.isArray(newSrc)) {
      newSrc = newSrc ? [newSrc] : [];
    }

    if (srcs) {
      if (!passedSrc && srcs.retina) {
        newSrc.push(srcs.retina);
      }
      if (srcs.regular) {
        newSrc.push(srcs.regular);
      }
    }

    newSrc.push(missingSrc);

    return newSrc;
  }, [passedSrc, srcs, missingSrc]);

  const imgProps = useMemo(() => {
    const newImgProps = {
      ...rest,
      ref: rest.forwardRef,
    };

    if (srcs) {
      newImgProps.src = isRetina() ? [srcs.retina, srcs.regular] : srcs.regular;
      newImgProps.srcSet = `${srcs.regular} 1x, ${
        srcs.retina || srcs.regular
      } 2x`;
    }

    delete newImgProps.renderOnMount;
    delete newImgProps.forwardRef;
    delete newImgProps.entity;
    delete newImgProps.entity_type;
    delete newImgProps.styles;
    delete newImgProps.animate;
    delete newImgProps.useImgElement;

    return newImgProps;
  }, [rest, srcs]);

  const onImageLoad = useCallback(
    (...args) => {
      if (onLoad) {
        onLoad(...args);
      }

      setLoaded(true);
    },
    [onLoad]
  );

  const handleAnimationEnd = useCallback(() => {
    setAnimationDone(true);
  }, []);

  const loadStatus = useMemo(() => {
    if (!loaded) {
      return "loading";
    }
    if (loaded && !animationDone) {
      return "animating";
    }
    if (loaded && animationDone) {
      return "loaded";
    }
  }, [animationDone, loaded]);

  if (isCrawler || useImgElement) {
    // just a standard image as we won't have React loaded
    return (
      <img
        {...props}
        alt={props.alt}
        onLoad={onImageLoad}
        className={css(styles.imageFallback)}
        src={srcs.regular}
        srcSet={imgProps.srcSet}
      />
    );
  }

  return (
    <LazyLoadComponent
      height={rest.height}
      width={rest.width}
      renderOnMount={renderOnMount}
      className={css(styles.imageFallback, styles.imageLazyLoaded)}
    >
      <Img
        ref={ref}
        src={src}
        width={rest.width}
        height={rest.height}
        loader={
          <div
            className={css(styles.imageFallback, styles.imageLazyLoaded)}
            style={{ height: rest.height, width: rest.width }}
          />
        }
        {...imgProps}
        onLoad={onImageLoad}
        onAnimationEnd={handleAnimationEnd}
        className={`${css(
          styles.imageFallback,
          animate && styles.imageFallbackWithAnimation,
          loaded && styles.imageFallbackLoaded,
          animate && loaded && styles.imageFallbackWithAnimationLoaded
        )} ${className}`}
        data-imgfallback-loadstatus={loadStatus}
        unloader={
          <img
            alt={props.alt}
            src={MISSING_IMAGE_URL}
            className={css(styles.imageFallback)}
            {...(imgProps.width
              ? {
                  width: imgProps.width,
                }
              : {})}
            {...(imgProps.height
              ? {
                  height: imgProps.height,
                }
              : {})}
          />
        }
      />
    </LazyLoadComponent>
  );
});

ImageFallback.propTypes = {
  imgType: PropTypes.string,
  style: PropTypes.object,
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  alt: PropTypes.string,
  onClick: PropTypes.func,
  animate: PropTypes.bool,
  onLoad: PropTypes.func,
  forwardRef: PropTypes.object,
  srcs: PropTypes.object,
  className: PropTypes.string,
  renderOnMount: PropTypes.bool,
};
ImageFallback.defaultProps = {
  imgType: null,
  style: null,
  src: null,
  alt: null,
  onClick: null,
  animate: true,
  onLoad: null,
  forwardRef: null,
  srcs: null,
  className: "",
  renderOnMount: false,
};

ImageFallback.displayName = "ImageFallback";

export default ImageFallback;
