import { faCog } from "@fortawesome/free-solid-svg-icons/faCog";
import { faDatabase } from "@fortawesome/free-solid-svg-icons/faDatabase";
import { faBoltLightning } from "@fortawesome/pro-duotone-svg-icons/faBoltLightning";
import { faBadgeDollar } from "@fortawesome/pro-solid-svg-icons/faBadgeDollar";
import { faBellOn } from "@fortawesome/pro-solid-svg-icons/faBellOn";
import { useMemo } from "react";

import { useLoggedIn } from "hooks/useLoggedInUser";
import useUserHasPro from "hooks/useUserHasPro";

import gStyles from "styles/GenericStyles";

const PODCASTER_LINKS = [
  {
    id: "managePodcasts",
    label: "Manage my podcast",
    url: "/profile/claimed",
    icon: faCog,
    description:
      "Customize your podcast page with links, merch, categories, badges, and more",
  },
  {
    id: "podcharts",
    label: "Podcharts",
    url: "https://podcharts.co?utm_source=podchaser&utm_medium=web&utm_campaign=podchaser_navbar",
    external: true,
    image: "/images/nav/podcharts.png",
    description:
      "Track daily podcast chart rankings on Apple Podcasts and Spotify",
    iconBackgroundStyles: {
      navIconBackground: {
        background: gStyles.subtleGreyGradient(180),
      },
    },
    imageStyles: {
      width: "110%",
      height: "auto",
    },
  },
  {
    id: "podrover",
    label: "Podrover",
    url: "https://www.podrover.com?utm_source=podchaser&utm_medium=web&utm_campaign=podchaser_navbar",
    external: true,
    image: "/images/nav/podrover.png",
    description:
      "One spot to track ratings and reviews from Podchaser and Apple Podcasts",
    iconBackgroundStyles: {
      navIconBackground: {
        background: gStyles.subtleGreyGradient(180),
      },
    },
    imageStyles: {
      width: "100%",
      height: "auto",
      marginBottom: ".4rem",
      maxWidth: "2.5rem",
      maxHeight: "2.5rem",
    },
  },
];

export const useToolNavLinks = () => {
  const isLoggedIn = useLoggedIn();
  const hasPro = useUserHasPro();

  const brandLinks = useMemo(
    () => [
      {
        id: "pro",
        label: "Podchaser Pro",
        url: hasPro
          ? "/profile/pro/exports"
          : "https://features.podchaser.com/pro?utm_source=podchaser&utm_medium=web&utm_campaign=podchaser_navbar",
        external: !hasPro,
        icon: faBoltLightning,
        description:
          "Access podcast insights including reach, demographics, and verified contacts",
      },
      {
        id: "alerts",
        label: "Alerts",
        url: "/alerts",
        icon: faBellOn,
        description:
          "Get notified every time your keyword is mentioned on a podcast.",
      },
      {
        id: "brands",
        label: "Brands",
        description: "View sponsor data across top podcasts",
        url: "/brands",
        icon: faBadgeDollar,
      },
      {
        id: "api",
        label: "API",
        url: "/profile/settings/api",
        action: () => {
          if (!isLoggedIn && window) {
            window.open(
              "https://features.podchaser.com/api?utm_source=podchaser&utm_medium=web&utm_campaign=podchaser_navbar",
              "_blank"
            );
          }
        },
        icon: faDatabase,
        description:
          "Podchaser data and engagement features delivered by our GraphQL API",
      },
    ],
    [hasPro, isLoggedIn]
  );

  return {
    podcasterLinks: PODCASTER_LINKS,
    brandLinks,
  };
};

export const useToolsMenuRoutes = (links) => {
  return links
    .filter((link) => link.url && !link.external)
    .map((link) => link.url);
};

export default useToolNavLinks;
