import Loadable from "@loadable/component";

import PodcastViewSkeleton from "./PodcastViewSkeleton";

const PodcastAboutViewContainer = Loadable(() =>
  import("./PodcastAboutViewContainer")
);

function PodcastAboutViewContainerAsyncLoading() {
  return <PodcastViewSkeleton />;
}

const PodcastAboutViewContainerContainer = (props) => {
  return (
    <PodcastAboutViewContainer
      {...props}
      fallback={<PodcastAboutViewContainerAsyncLoading />}
    />
  );
};

export default PodcastAboutViewContainerContainer;
