import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

const UserEarnedBadgeActivity = Loadable(() =>
  import(
    /* webpackChunkName: "UserEarnedBadgeActivity" */ "./UserEarnedBadgeActivity"
  )
);

function UserEarnedBadgeActivityLoading(props) {
  return <LoadingOverlay {...props} span />;
}

const UserEarnedBadgeActivityContainer = (props) => {
  return (
    <UserEarnedBadgeActivity
      {...props}
      fallback={<UserEarnedBadgeActivityLoading />}
    />
  );
};

export default UserEarnedBadgeActivityContainer;
