import PropTypes from "prop-types";
import { createContext, useCallback, useEffect, useMemo, useRef } from "react";
import StreamAnalytics from "stream-analytics";

import useLoggedInUser from "hooks/useLoggedInUser";

export const StreamFeedContext = createContext();

function StreamFeedProvider(props) {
  const { children } = props;
  const client = useRef(
    new StreamAnalytics({
      apiKey: "btw3nke3y73d",
      token:
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJyZXNvdXJjZSI6ImFuYWx5dGl" +
        "jcyIsImFjdGlvbiI6IioiLCJ1c2VyX2lkIjoiKiJ9.XQolk2I-11LU7nZKccAjTqAy8hP" +
        "pmEvwawYXxkvsOWw",
    })
  );

  const user = useLoggedInUser();

  useEffect(() => {
    if (user) {
      client.current.setUser({
        id: user.get("id"),
        alias: user.get("username"),
      });
    }
  }, [user]);

  const registerImpression = useCallback((data) => {
    try {
      client.current.trackImpression(data);
    } catch (e) {
      console.warn(e.message);
    }
  }, []);

  const context = useMemo(
    () => ({
      client: client.current,
      registerImpression,
    }),
    [registerImpression]
  );

  return (
    <StreamFeedContext.Provider value={context}>
      {children}
    </StreamFeedContext.Provider>
  );
}

StreamFeedProvider.propTypes = {
  children: PropTypes.node,
};

StreamFeedProvider.defaultProps = {
  children: null,
};

export default StreamFeedProvider;
