import colours from "../../../styles/colours";
import gStyles from "../../../styles/GenericStyles";
import ScreenSizes from "../../../styles/ScreenSizes";
import generateTransition from "../../../utils/generateTransition";

export const headerButtonStyles = {
  button: {
    ...gStyles.avalonBold,
    minWidth: "5.625rem",
    color: colours.white,
    borderRadius: 50,
    border: "none",
    letterSpacing: 0,
    background: colours.primary,
    opacity: "0.8",
    padding: "0.4em 0.7em 0.35em",
    outline: 0,
    cursor: "pointer",
    transition: generateTransition({
      targets: ["opacity", "background", "box-shadow"],
      speed: "150ms",
    }),

    fontSize: "0.75rem",
    [ScreenSizes.smAndAbove]: {
      padding: "0.6em 1.035em 0.4em",
      fontSize: "0.8125rem",
    },
    [ScreenSizes.mdOnly]: {
      padding: "0.5rem 0.6rem 0.3rem",
    },
    [ScreenSizes.lgAndAbove]: {
      fontSize: "1rem",
      padding: "0.5em 1.035em 0.5em",
    },
  },
  expand: {
    width: "100%",
    paddingLeft: 5,
    paddingRight: 5,
  },
  hover: {
    opacity: 1,
  },
};
