import Loadable from "@loadable/component";

const InsightsExportModal = Loadable(() => import("./InsightsExportModal"));

function AboutAsyncLoading() {
  return null;
}

const InsightsExportModalContainer = (props) => {
  return <InsightsExportModal {...props} fallback={<AboutAsyncLoading />} />;
};

export default InsightsExportModalContainer;
