import { floorToDecimalPlaces } from "../misc";

export default function getPodcastAuthorityLevel(podcast, decimal_places = 2) {
  if (typeof podcast === "number") {
    return floorToDecimalPlaces(podcast, decimal_places);
  }

  return podcast && podcast.hasIn(["user_data", "pro_data", "power_score"])
    ? floorToDecimalPlaces(
        podcast.getIn(["user_data", "pro_data", "power_score"]),
        decimal_places
      )
    : null;
}
