import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const ProfileUserLists = Loadable(() => import("./ProfileUserLists"));

function ProfileUserListsAsyncLoading(props) {
  return <LoadableLoading {...props} page />;
}

const ProfileUserListsContainer = (props) => {
  return (
    <ProfileUserLists {...props} fallback={<ProfileUserListsAsyncLoading />} />
  );
};

export default ProfileUserListsContainer;
