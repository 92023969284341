import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const HomepageSidebarMyActivity = Loadable(() =>
  import("./HomepageSidebarMyActivity")
);

function HomepageSidebarMyActivityAsyncLoading(props) {
  return <LoadableLoading {...props} transparent />;
}

const HomepageSidebarMyActivityContainer = (props) => {
  return (
    <HomepageSidebarMyActivity
      {...props}
      fallback={<HomepageSidebarMyActivityAsyncLoading />}
    />
  );
};

export default HomepageSidebarMyActivityContainer;
