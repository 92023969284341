import Loadable from "@loadable/component";
const CreateAlertModal = Loadable(() => import("./CreateAlertModal"));

function CreateAlertModalAsyncLoading() {
  return null;
}

const CreateAlertModalContainer = (props) => {
  return (
    <CreateAlertModal {...props} fallback={<CreateAlertModalAsyncLoading />} />
  );
};

export default CreateAlertModalContainer;
