const CREDIT_TYPES = {
  CURRENT_CREDIT: "CURRENT_CREDIT",
  RECURRING_CREDIT: "RECURRING_CREDIT",
};

export const CREDIT_SUBMISSION_TYPE = {
  ONGOING_SUBMISSION: "ongoing_submission",
  EPISODE_SUBMISSION: "episodes_submission",
  REMOVE_EPISODE_CREDITS: "removeEpisodeCredits",
};

export default CREDIT_TYPES;
