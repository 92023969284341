import { List } from "immutable";
import PropTypes from "prop-types";
import { Fragment, useCallback, useMemo } from "react";

import AggregateActivity from "./Activity/AggregateActivity";
import {
  allActivitiesEpisodeOfTheSamePodcast,
  getActivitiesByActorOrObjectType,
  getActorLinks,
  getAggregateEntity,
  getEntityTypeCounts,
  getShownByField,
  getUniqueFields,
  groupActivities,
  useAggregateEntities,
} from "./Activity/AggregateUtils";
import LikedActivity from "./LikedActivity";

const LikedAggregate = (props) => {
  const { aggregateProps } = props;
  const { activities } = aggregateProps;

  const entities = useAggregateEntities(activities);
  const filteredActivities = useMemo(
    () => getActivitiesByActorOrObjectType(aggregateProps),
    [aggregateProps]
  );

  const groupedActivities = useMemo(() => {
    const { grouped: actorGrouped, ungrouped: actorUngrouped } =
      groupActivities(filteredActivities.toArray(), "actor", "object");
    const { grouped: objectGrouped, ungrouped: objectUngrouped } =
      groupActivities(actorUngrouped, "object", "object");

    return [
      ...actorGrouped,
      ...objectGrouped,
      ...objectUngrouped.map((act) => ({
        activities: List([act]),
        groupedBy: "none",
      })),
    ];
  }, [filteredActivities]);

  const renderIntroText = useCallback(
    (appearProps) => (introProps) => {
      if (appearProps.singleActor) {
        return (
          <Fragment>
            {introProps.renderEntityLink("user", appearProps.singleActor)} liked{" "}
            {getEntityTypeCounts(appearProps.objects)}
            {appearProps.allSamePodcast && (
              <Fragment>
                of{" "}
                {introProps.renderEntityLink(
                  "podcast",
                  appearProps.allSamePodcast
                )}
              </Fragment>
            )}
          </Fragment>
        );
      }
      if (appearProps.singleObject) {
        return (
          <Fragment>
            {introProps.renderEntityLink(
              appearProps.singleObjectType,
              appearProps.singleObject
            )}{" "}
            was liked by {getActorLinks(appearProps.actors)}
          </Fragment>
        );
      }

      return (
        <Fragment>
          {getActorLinks(appearProps.actors)} liked{" "}
          {getEntityTypeCounts(appearProps.objects)}
          {appearProps.allSamePodcast && (
            <Fragment>
              of{" "}
              {introProps.renderEntityLink(
                "podcast",
                appearProps.allSamePodcast
              )}
            </Fragment>
          )}
        </Fragment>
      );
    },
    []
  );

  const renderActivity = (activity, index) => (
    <LikedActivity {...props} key={index} activity={activity} />
  );

  const renderAggregate = (grouped, index) => {
    const actors = getUniqueFields(grouped.activities, "actor");
    const objects = getUniqueFields(grouped.activities, "object");
    const allEpisodesOfSamePodcast = allActivitiesEpisodeOfTheSamePodcast(
      grouped.activities
    );

    let singleActor = null;
    let singleObject = null;
    let singleObjectType = null;
    let allSamePodcast = null;

    let entity_type = null;
    let entity = null;
    let card_entity_type = null;
    let card_entity = null;

    if (grouped.groupedBy === "actor") {
      const [actor_type, actor_id] = actors.first().split(":");
      const actor = getAggregateEntity(entities, actor_type, actor_id);

      entity_type = actor_type;
      entity = actor;
      singleActor = actor;
    } else if (grouped.groupedBy === "object") {
      const [object_type, object_id] = objects.first().split(":");
      const object = getAggregateEntity(entities, object_type, object_id);

      entity_type = object_type;
      entity = object;
      card_entity_type = object_type;
      card_entity = object;
      singleObject = object;
      singleObjectType = object_type;
    }
    if (allEpisodesOfSamePodcast) {
      card_entity_type = "podcast";
      card_entity = allEpisodesOfSamePodcast;
      allSamePodcast = getAggregateEntity(
        entities,
        "podcast",
        allEpisodesOfSamePodcast
      );
    }

    const followProps = {
      actors,
      objects,
      singleActor,
      singleObject,
      singleObjectType,
      allSamePodcast,
      entity_type,
      entity,
      activities: grouped.activities,
    };

    return (
      <AggregateActivity
        {...props}
        key={index}
        activities={grouped.activities}
        shownByField={getShownByField(aggregateProps)}
        renderIntroText={renderIntroText(followProps)}
        entity_type={entity_type}
        entity={entity}
        card_entity_type={card_entity_type}
        card_entity={card_entity}
        highlightType="Like"
        filterEntities={{ [entity_type]: entity }}
        loaded
      />
    );
  };

  return (
    <Fragment>
      {groupedActivities.map((grouped, index) => {
        if (grouped.groupedBy === "none") {
          return renderActivity(grouped.activities.first(), index);
        }

        return renderAggregate(grouped, index);
      })}
    </Fragment>
  );
};

LikedAggregate.propTypes = {
  aggregateProps: PropTypes.object.isRequired,
};

export default LikedAggregate;
