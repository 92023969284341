import {
  selectSearch,
  selectSearchLoading,
  selectSearchResults,
} from "selectors/search";

import useReduxState from "hooks/useReduxState";

export default (searchKey) =>
  useReduxState(
    (state) => {
      if (!state.search) {
        // not loaded yet
        return {};
      }
      const search = selectSearch(state, searchKey) || Map();
      return {
        ...search.toJS(),
        searching: selectSearchLoading(state, searchKey),
        results: selectSearchResults(state, searchKey),
      };
    },
    [searchKey]
  );
