import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

const CategoryLink = Loadable(() => import("./CategoryLink"));

function LinkAsyncLoading(props) {
  return <LoadingOverlay {...props} span />;
}

const CategoryLinkContainer = (props) => {
  return <CategoryLink {...props} fallback={<LinkAsyncLoading />} />;
};

export default CategoryLinkContainer;
