import { css } from "aphrodite";

import BasicTooltip from "components/Common/Tooltip/BasicTooltip";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  betaTag: {
    ...gStyles.avalonBold,
    position: "absolute",
    top: "-.15rem",
    right: "-3rem",
    color: colours.lightishGrey,
    fontSize: ".65rem",
  },
};

const BetaLogo = (props) => {
  const { styles } = useStyles(baseStyles, props);

  return (
    <BasicTooltip
      renderTooltip={() => (
        <span>{"We're still working on this feature!"}</span>
      )}
      placement="bottom"
      zIndex={9999}
    >
      {(tooltipProps) => (
        <div
          data-testid="betaLabel"
          {...tooltipProps}
          className={css(styles.betaTag)}
        >
          Beta
        </div>
      )}
    </BasicTooltip>
  );
};

export default BetaLogo;
