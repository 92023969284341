import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo, useCallback } from "react";

import Col from "components/Common/Col";

import eventIsFieldTrigger from "utils/misc/eventIsFieldTrigger";

import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import HeaderStyles from "styles/HeaderStyles";

const HeaderMenuToggle = (props) => {
  const { onMenuToggleClick, onSearchToggleClick, onSearchToggleKeyDown } =
    props;

  const { isWindowSizeOrLess } = useWindowSize();
  const isMediumOrLess = isWindowSizeOrLess("medium");

  const { styles } = useStyles(HeaderStyles, props);

  const handleMenuToggleKeyDown = useCallback(
    (e) => {
      if (eventIsFieldTrigger(e) && onMenuToggleClick) {
        onMenuToggleClick(e);
      }
    },
    [onMenuToggleClick]
  );

  return (
    <Col
      xs={4}
      sm={5}
      md={4}
      lg={1}
      className={css(styles.menuToggleContainer)}
    >
      <button
        className={css(styles.menuToggle)}
        onClick={onMenuToggleClick}
        onKeyDown={handleMenuToggleKeyDown}
      >
        <FontAwesomeIcon icon={faBars} />
      </button>
      {isMediumOrLess && (
        <button
          className={css(styles.searchToggle, styles.searchToggleLeft)}
          onClick={onSearchToggleClick}
          onKeyDown={onSearchToggleKeyDown}
        >
          <FontAwesomeIcon icon={faSearch} />
        </button>
      )}
    </Col>
  );
};

HeaderMenuToggle.propTypes = {
  onMenuToggleClick: PropTypes.func,
  onSearchToggleClick: PropTypes.func.isRequired,
  onSearchToggleKeyDown: PropTypes.func.isRequired,
};

HeaderMenuToggle.defaultProps = {
  onMenuToggleClick: null,
};

export default memo(HeaderMenuToggle);
