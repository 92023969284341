import Loadable from "@loadable/component";
const ReviewLink = Loadable(() => import("./ReviewLink"));

function LinkAsyncLoading() {
  return null;
}

const ReviewLinkContainer = (props) => {
  return <ReviewLink {...props} fallback={<LinkAsyncLoading />} />;
};

export default ReviewLinkContainer;
