import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useCallback, useState } from "react";
import { followEntityPromise, unfollowEntityPromise } from "routines/follow";

import StandardButton from "components/Buttons/StandardButton";

import useRoutinePromises from "hooks/useRoutinePromises";

const HoverCardFollowButton = (props) => {
  const { name, entity, entity_type } = props;

  const [savingFollow, setSavingFollow] = useState(false);
  const { followEntity, unfollowEntity } = useRoutinePromises({
    followEntity: followEntityPromise,
    unfollowEntity: unfollowEntityPromise,
  });
  const userFollows = entity.getIn(["user_data", "follows"]);

  const handleFollow = useCallback(() => {
    setSavingFollow(true);

    const fn = userFollows ? unfollowEntity : followEntity;

    fn({ entity_type, entity_id: entity.get("id"), entity }).finally(() =>
      setSavingFollow(false)
    );
  }, [followEntity, unfollowEntity, entity, entity_type, userFollows]);

  return (
    <StandardButton
      dataId="hover-card-follow-button"
      key="followButton"
      variation={userFollows ? "actioned" : "white"}
      hoverLabel={userFollows ? `Unfollow ${name}` : undefined}
      label={`${userFollows ? "Following" : "Follow"} ${name}`}
      onClick={handleFollow}
      submitting={savingFollow}
      active={userFollows}
      flat
    />
  );
};

HoverCardFollowButton.propTypes = {
  entity: PropTypes.instanceOf(Map),
  entity_type: PropTypes.string.isRequired,
  name: PropTypes.string,
};

HoverCardFollowButton.defaultProps = {
  entity: null,
  name: null,
};

export default memo(HoverCardFollowButton);
