import { TIMELINE_MODULE } from "modules/moduleIds";
import { useMemo } from "react";
import {
  loadTimeline,
  loadMoreActivities,
  removeTimelineActivity,
  deleteTimelineActivity,
  markActivitiesAs,
} from "routines/timeline";

import useActionCreators from "hooks/useActionCreators";
import useAddModules from "hooks/useAddModules";

const useTimelineAction = (disable = false) => {
  const moduleConfig = useMemo(
    () => ({
      path: "useTimelineAction",
      modules: [TIMELINE_MODULE],
      disable,
    }),
    [disable]
  );

  const actions = useActionCreators({
    loadTimeline,
    loadMoreActivities,
    removeTimelineActivity,
    deleteTimelineActivity,
    markActivitiesAs,
  });

  const { allModulesLoaded } = useAddModules(moduleConfig);

  return { timelineModuleLoaded: allModulesLoaded, actions };
};

export default useTimelineAction;
