import { css } from "aphrodite";
import { List } from "immutable";
import PropTypes from "prop-types";
import { Fragment, useCallback, useMemo } from "react";

import IntegrationVoteActivity from "components/Timeline/Activities/IntegrationVoteActivity";
import { useEntityFromTimeline } from "components/Timeline/useEntityFromTimeline";

import AggregateActivity from "./Activity/AggregateActivity";
import {
  getActivitiesByActorOrObjectType,
  getActorLinks,
  getEntityTypeCounts,
  getShownByField,
  getUniqueFields,
  groupActivities,
} from "./Activity/AggregateUtils";

import { useStyles } from "hooks/useStyles";

import cardStyles from "styles/CardStyles";
import { feedColours as feedTextColours } from "styles/feedStyles";

const baseStyles = {
  verbText: {
    ...cardStyles.verbText,
    color: feedTextColours.feedIntegrationVoteText || "#000",
  },
};

const RenderAggregated = (props) => {
  const { grouped, aggregateProps } = props;
  const { styles } = useStyles(baseStyles, props);
  const actors = getUniqueFields(grouped.activities, "actor");
  const objects = getUniqueFields(grouped.activities, "object");

  const { entity: actor } = useEntityFromTimeline(actors.first());
  const { entity: object } = useEntityFromTimeline(objects.first());

  let singleActor = null;
  let singleObject = null;

  let entity_type = null;
  let entity = null;
  let card_entity_type = null;
  let card_entity = null;

  if (grouped.groupedBy === "actor") {
    const [actor_type] = actors.first().split(":");

    entity_type = actor_type;
    entity = actor;
    singleActor = actor;
  } else if (grouped.groupedBy === "object") {
    const [object_type] = objects.first().split(":");

    entity_type = object_type;
    entity = object;
    card_entity_type = object_type;
    card_entity = object;
    singleObject = object;
  }

  const voteProps = {
    actors,
    objects,
    singleActor,
    singleObject,
    entity_type,
    entity,
    activities: grouped.activities,
  };

  const filterEntities = useMemo(
    () => ({
      [entity_type]: entity,
    }),
    [entity_type, entity]
  );

  const renderIntroText = useCallback(
    (introProps) => {
      if (voteProps.singleActor) {
        const [actor_type] = voteProps.actors.first().split(":");

        return (
          <Fragment>
            {introProps.renderEntityLink(actor_type, voteProps.singleActor)}{" "}
            <span className={css(styles.verbText)}>voted for</span>{" "}
            {getEntityTypeCounts(voteProps.objects)}
          </Fragment>
        );
      }
      if (voteProps.singleObject) {
        const [object_type] = voteProps.objects.first().split(":");

        return (
          <Fragment>
            {introProps.renderEntityLink(object_type, voteProps.singleObject)}{" "}
            was voted for by {getActorLinks(voteProps.actors)}
          </Fragment>
        );
      }

      return (
        <Fragment>
          {getActorLinks(voteProps.actors)} users voted for{" "}
          {getEntityTypeCounts(voteProps.objects)}
        </Fragment>
      );
    },
    [voteProps, styles]
  );

  return (
    <AggregateActivity
      {...props}
      activities={grouped.activities}
      shownByField={getShownByField(aggregateProps)}
      renderIntroText={renderIntroText}
      entity_type={entity_type}
      entity={entity}
      card_entity_type={card_entity_type}
      card_entity={card_entity}
      // highlightType="IntegrationVote"
      loaded
      filterEntities={filterEntities}
      collapsable
    />
  );
};

RenderAggregated.propTypes = {
  grouped: PropTypes.object.isRequired,
  aggregateProps: PropTypes.object.isRequired,
};

const IntegrationVoteAggregate = (props) => {
  const { aggregateProps } = props;

  const filteredActivities = useMemo(
    () => getActivitiesByActorOrObjectType(aggregateProps),
    [aggregateProps]
  );

  const groupedActivities = useMemo(() => {
    const { grouped: actorGrouped, ungrouped: actorUngrouped } =
      groupActivities(filteredActivities.toArray(), "actor", "object");
    const { grouped: objectGrouped, ungrouped: objectUngrouped } =
      groupActivities(actorUngrouped, "object", "object");

    return [
      ...actorGrouped,
      ...objectGrouped,
      ...objectUngrouped.map((act) => ({
        activities: List([act]),
        groupedBy: "none",
      })),
    ];
  }, [filteredActivities]);

  const renderActivity = (activity, index) => (
    <IntegrationVoteActivity {...props} key={index} activity={activity} />
  );

  return (
    <Fragment>
      {groupedActivities.map((grouped, index) => {
        if (grouped.groupedBy === "none") {
          return renderActivity(grouped.activities.first(), index);
        }

        const key = `${index}-${grouped.activities.first().get("id")}`;

        return <RenderAggregated {...props} key={key} grouped={grouped} />;
      })}
    </Fragment>
  );
};

IntegrationVoteAggregate.propTypes = {
  aggregateProps: PropTypes.object.isRequired,
};

export default IntegrationVoteAggregate;
