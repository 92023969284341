import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const ProfileLikedView = Loadable(() => import("./ProfileLikedView"));

function LikedViewAsyncLoading(props) {
  return <LoadableLoading {...props} page />;
}

const ProfileLikedViewContainer = (props) => {
  return <ProfileLikedView {...props} fallback={<LikedViewAsyncLoading />} />;
};

export default ProfileLikedViewContainer;
