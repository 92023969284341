import { css } from "aphrodite";
import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import Helmet from "react-helmet-async";
import { useHistory, useLocation } from "react-router-dom";

import LoadingOverlay from "components/Common/LoadingOverlay";

import authActions from "actions/auth";
import * as authStates from "constants/auth-states";
import { selectCurrentModalType } from "selectors/modals";
import { generateMetaArray } from "utils/meta";

import useActionCreators from "hooks/useActionCreators";
import { useHasLoggedOut, useLoggedIn } from "hooks/useLoggedInUser";
import useReduxState from "hooks/useReduxState";
import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";

const baseStyles = {
  contentContainer: {
    ...gStyles.contentContainer,
  },
};

const loadingStyles = {
  overlay: {
    fontSize: "3rem",
  },
  icon: {
    maxWidth: "3em",
  },
};

const RegistrationRequired = (props) => {
  const { actionTitle } = props;
  const { styles } = useStyles(baseStyles, props);

  const history = useHistory();
  const location = useLocation();

  const isLoggedIn = useLoggedIn();
  const hasLoggedOut = useHasLoggedOut();
  const lastModalTypeRef = useRef(null);
  const mountedRef = useRef(false);
  const { startAuthFlow } = useActionCreators({
    startAuthFlow: authActions.startAuthFlow,
  });
  const currentModalType = useReduxState(selectCurrentModalType, []);
  useEffect(() => {
    if (hasLoggedOut) {
      history.push("/");
    } else {
      const previousPathName = location?.pathname || "/";

      startAuthFlow(authStates.LOGIN_FORM, {
        entityAction: "action",
        actionTitle:
          actionTitle || "You need to login or register to view this page.",
        analyticsVariables: {
          triggered_by: "registrationRequiredPage",
        },
        previousPathName,
      });
      mountedRef.current = true;
    }

    // only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      currentModalType !== "login" &&
      lastModalTypeRef.current === "login" &&
      !isLoggedIn
    ) {
      history.push("/");
    } else if (currentModalType === "login") {
      lastModalTypeRef.current = "login";
    }
    // only on currentModalType changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentModalType, isLoggedIn]);

  return (
    <div className={css(styles.contentContainer)} id="reg-required">
      <Helmet meta={generateMetaArray({ index: false, follow: false })} />
      <LoadingOverlay styles={loadingStyles} />
    </div>
  );
};

RegistrationRequired.propTypes = {
  actionTitle: PropTypes.node,
};

RegistrationRequired.defaultProps = {
  actionTitle: null,
};

export default RegistrationRequired;
