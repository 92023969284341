import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const CreatorContainer = Loadable(() => import("./CreatorContainer"));

function CreatorContainerAsyncLoading(props) {
  return <LoadableLoading {...props} transparent page />;
}

const CreatorContainerContainer = (props) => {
  return (
    <CreatorContainer {...props} fallback={<CreatorContainerAsyncLoading />} />
  );
};

export default CreatorContainerContainer;
