import { createRoutine, promisifyRoutine } from "redux-saga-routines";

export const followEntity = createRoutine("FOLLOW_ENTITY");
export const followEntityPromise = promisifyRoutine(followEntity);

export const unfollowEntity = createRoutine("UNFOLLOW_ENTITY");
export const unfollowEntityPromise = promisifyRoutine(unfollowEntity);

export const subscribeEntity = createRoutine("SUBSCRIBE_ENTITY");
export const subscribeEntityPromise = promisifyRoutine(subscribeEntity);

export const unsubscribeEntity = createRoutine("UNSUBSCRIBE_ENTITY");
export const unsubscribeEntityPromise = promisifyRoutine(unsubscribeEntity);
