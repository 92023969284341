import Loadable from "@loadable/component";

import { useStyles } from "hooks/useStyles";

const RatingWithHover = Loadable(() => import("./RatingWithHover"));

const baseStyles = {
  container: {
    minHeight: "1rem",
  },
};

function RatingWithHoverAsyncLoading() {
  const { styles, css } = useStyles(baseStyles);

  return <div className={css(styles.container)} />;
}

const RatingWithHoverContainer = (props) => {
  return (
    <RatingWithHover {...props} fallback={<RatingWithHoverAsyncLoading />} />
  );
};

export default RatingWithHoverContainer;
