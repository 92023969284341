import LayoutSizes from "./LayoutSizes";

export const secondaryVisibleStyles = {
  sectionsWrapper: {
    [LayoutSizes.hasTabletMenu]: {
      transform: "translateX(40%)", // TODO IMPORTANT: GET THIS WORKING AGAIN
    },

    [LayoutSizes.hasMobileMenu]: {
      transform: "translateX(80%)", // TODO IMPORTANT: GET THIS WORKING AGAIN
    },
  },
};

export const tertiaryVisibleStyles = {
  wrapper: {
    overflowY: "visible",
    overflowX: "hidden",
  },
  sectionsWrapper: {
    [LayoutSizes.hasTabletMenu]: {
      transform: "translateX(40%)",
    },

    [LayoutSizes.hasMobileMenu]: {
      transform: "translateX(80%)",
    },
  },
};
