import { Map } from "immutable";

export const selectIntegrations = (state) =>
  state.integration
    ? state.integration.getIn(["integrations", "entities"])
    : Map();

export const selectSpecificIntegration = (state, id) =>
  state.integration &&
  state.integration.getIn(["integrations", "entities", id?.toString()]);

export const selectSpecificIntegrationByCode = (state, code) =>
  state.integration
    .getIn(["integrations", "entities"])
    .find((i) => i.get("code") === code);
