import { faComment } from "@fortawesome/free-solid-svg-icons/faComment";
import { faHeart } from "@fortawesome/free-solid-svg-icons/faHeart";
import { faPlug } from "@fortawesome/free-solid-svg-icons/faPlug";
import { faStar } from "@fortawesome/free-solid-svg-icons/faStar";
import { faSparkles } from "@fortawesome/pro-solid-svg-icons/faSparkles";
import { faTrophy } from "@fortawesome/pro-solid-svg-icons/faTrophy";
import { faUserFriends } from "@fortawesome/pro-solid-svg-icons/faUserFriends";

import colours from "./colours";
import faListSmall from "./icons/faListSmall";

import faFollow from "styles/icons/faFollow";

export const feedColours = {
  feedList: {
    xxforeground: "#e77a00",
    xbackground: "#fff200",

    foreground: "#fff",
    background: "var(--color-selected)",
  },
  feedRate: {
    foreground: "#fff",
    background: "#e651ca",
  },
  feedReview: {
    foreground: "#fff",
    background: "#e651ca",
  },
  feedLike: {
    foreground: "#fff",
    background: "#e651ca",
  },
  feedRelease: {
    foreground: "#fff",
    background: "var(--color-positive-d4)",
  },
  feedAppear: {
    foreground: "#fff",
    background: "#613db2",
  },
  feedFollow: {
    foreground: "#fff",
    background: "#824aec",
  },
  feedIntegrationVote: {
    foreground: "#fff",
    background: "#4173e0",
  },
  feedBadgeAwarded: {
    foreground: "#fff",
    background: colours.awardGoldBorder,
  },
  awardBadge: {
    foreground: "#fff",
    background: colours.awardGoldBorder,
  },
};

export const feedTextColours = {
  feedListText: "#000",
  feedRateText: "#000",
  feedReviewText: "#000",
  feedReleaseText: "#000",
  feedAppearText: "#000",
  feedFollowText: "#000",
  feedLikeText: "#000",
  feedIntegrationVoteText: "#000",
};

export const activityIcons = {
  List: faListSmall,
  Rate: faStar,
  Review: faComment,
  Release: faSparkles,
  Appear: faUserFriends,
  Follow: faFollow,
  Like: faHeart,
  IntegrationVote: faPlug,
  BadgeAwarded: faTrophy,
};

export default {
  ...feedColours,
  ...feedTextColours,
};
