import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const ValidatePodcastClaim = Loadable(() => import("./ValidatePodcastClaim"));

function ValidatePodcastClaimAsyncLoading(props) {
  return <LoadableLoading {...props} />;
}

const ValidatePodcastClaimContainer = (props) => {
  return (
    <ValidatePodcastClaim
      {...props}
      fallback={<ValidatePodcastClaimAsyncLoading />}
    />
  );
};

export default ValidatePodcastClaimContainer;
