import { useContext, useEffect } from "react";

import RequestContext from "pages/RequestContext";

import { isHydrateRender } from "utils/misc";

import useReduxState from "hooks/useReduxState";

export default function useLoadEntity(options = {}) {
  const {
    entity_type,
    entity_id,
    loader,
    selector,
    loadingSelector,
    failedSelector,
    reloadTest,
    dontLoad,
    loadDuringHydrate,
  } = options;
  const requestContext = useContext(RequestContext);

  const entity = useReduxState(
    (state) => selector(state, entity_id, entity_type),
    [entity_id, entity_type]
  );
  const loading = useReduxState(
    (state) => loadingSelector(state, entity_id, entity_type),
    [entity_id, entity_type]
  );
  const failed = useReduxState(
    (state) => failedSelector(state, entity_id, entity_type),
    [entity_id, entity_type]
  );

  const reload = entity && reloadTest && reloadTest(entity);

  // we don't want this to fire on the first hydration render because not all modules will be loaded
  const hydrateRender = !loadDuringHydrate && isHydrateRender(requestContext);

  useEffect(() => {
    if (
      !dontLoad &&
      (!entity || reload) &&
      !loading &&
      !failed &&
      !hydrateRender &&
      entity_type &&
      entity_id
    ) {
      loader(entity_id, entity_type);
    }
  }, [
    entity,
    entity_id,
    failed,
    hydrateRender,
    loader,
    loading,
    dontLoad,
    reload,
    entity_type,
  ]);

  if (dontLoad) {
    return {};
  }

  return {
    entity,
    loading: loading || (!failed && (!entity || reload)),
    failed,
  };
}
