import qs from "qs";

import client from "api/live/client";

export function getOAuthRequestDetails(query) {
  const request = client.get(
    `/oauth/authorize?${qs.stringify({ ...query, response_type: "code" })}`
  );
  return request;
}

export function approveOauthRequest(query) {
  const request = client.post(
    `/oauth/authorize?${qs.stringify({ ...query, response_type: "code" })}`
  );
  return request;
}
