import PropTypes from "prop-types";
import { createContext, useCallback, useMemo, useRef } from "react";

export const HeightManagerContext = createContext();

const HeightManager = ({ children }) => {
  const heights = useRef({});

  const registerElement = useCallback((key, height) => {
    heights.current[key] = height;
  }, []);

  const getHeight = useCallback((key) => heights.current[key] || null, []);

  const value = useMemo(
    () => ({
      registerElement,
      getHeight,
      heights: heights.current,
    }),
    [getHeight, heights, registerElement]
  );

  return (
    <HeightManagerContext.Provider value={value}>
      {children}
    </HeightManagerContext.Provider>
  );
};

HeightManager.propTypes = {
  children: PropTypes.node,
};

HeightManager.defaultProps = {
  children: null,
};

export default HeightManager;
