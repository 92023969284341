import PropTypes from "prop-types";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useMappedState } from "redux-react-hook";
import { setListenedPromise } from "routines/listens";

import AudioPlayerContext from "./AudioPlayerContext";
import useStoredPlayerProgress from "./useStoredPlayerProgress";

import {
  selectSpecificEpisode,
  selectSpecificPodcast,
  selectEpisodeLoading,
  selectPodcastLoading,
} from "selectors/podcast";

import useReduxState from "hooks/useReduxState";
import useRoutinePromises from "hooks/useRoutinePromises";

const AudioPlayerProvider = (props) => {
  const { children } = props;

  const hasRef = useRef(false);

  const [url, setUrl] = useState(null);
  const [playerRef, setPlayerRef] = useState(null);
  const [progress, setProgress] = useState({
    played: 0,
    loaded: 0,
    playedSeconds: 0,
  });
  const [playing, setPlaying] = useState(false);
  const [seekTo, setSeekTo] = useState(0.0);
  const [episodePlayingID, setEpisodePlayingId] = useState(null);
  const [adPlayingID, setAdPlayingId] = useState(null);
  const [episodePlayingAudioUrl, setEpisodePlayingAudioUrl] = useState(null);
  const [error, setError] = useState(null);

  const episode = useReduxState(
    (state) => selectSpecificEpisode(state, episodePlayingID),
    [episodePlayingID]
  );
  const podcast = useReduxState(
    (state) =>
      selectSpecificPodcast(state, episode && episode.get("podcast_id")),
    [episode]
  );

  const { getStoredEpisodeProgress, storeEpisodeProgress } =
    useStoredPlayerProgress(episode, episodePlayingID);

  const { setListened } = useRoutinePromises({
    setListened: setListenedPromise,
  });

  const entitiesLoading = useMappedState(
    useCallback(
      (state) =>
        (episodePlayingID && selectEpisodeLoading(state, episodePlayingID)) ||
        (episode && selectPodcastLoading(state, episode.get("podcast_id"))),
      [episodePlayingID, episode]
    )
  );

  const audioUrl = useMemo(() => {
    const rawAudioUrl = episode
      ? episode.get("audio_url")
      : episodePlayingAudioUrl;

    if (rawAudioUrl && rawAudioUrl.includes("http://")) {
      return rawAudioUrl.replace("http://", "https://");
    }

    return rawAudioUrl;
  }, [episode, episodePlayingAudioUrl]);

  useEffect(() => {
    if (episode) {
      const newEpisodePreviousProgress =
        getStoredEpisodeProgress(episodePlayingID);
      const newPlayed = newEpisodePreviousProgress / episode.get("duration"); // turn seconds played to decimal

      setSeekTo(newEpisodePreviousProgress);
      setProgress((progress) => ({
        ...progress,
        played: newPlayed,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [episodePlayingID]);

  useEffect(() => {
    if (progress.played > 0.95) {
      // check if episode has been marked as listened
      if (!episode.getIn(["user_data", "listened"])) {
        // mark episode listened
        setListened({ listened: true, episode_id: episode.get("id") });
      }
    }
    if (progress.played >= 1) {
      setPlaying(false);
      storeEpisodeProgress(null);
    } else if (progress.loaded > 0) {
      storeEpisodeProgress(progress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress, episode, setListened]);

  const getPlayStateOfEpisode = useCallback(
    (episode_id, ad_id) => {
      const isSelected = episode_id === episodePlayingID;
      const isAdSelected =
        episode_id === episodePlayingID && adPlayingID === ad_id;

      return {
        isSelected,
        isAdPlaying: isAdSelected && playing,
        isPlaying: isSelected && playing,
        progress: isSelected && progress,
        episode,
      };
    },
    [episodePlayingID, adPlayingID, playing, progress, episode]
  );

  const closePlayer = useCallback(() => {
    storeEpisodeProgress(progress, true);
    setUrl(null);
    setPlayerRef(null);
    setProgress({ played: 0, loaded: 0 });
    setPlaying(false);
    setSeekTo(0.0);
    setEpisodePlayingId(null);
    setAdPlayingId(null);
    setEpisodePlayingAudioUrl(null);
    setError(null);
  }, [progress, storeEpisodeProgress]);

  const handlePlayerPlay = useCallback(
    (newUrl) => {
      if (
        playerRef &&
        playerRef.player &&
        playerRef.player.player &&
        playerRef.player.player.src
      ) {
        playerRef.player.player.src = newUrl;
        playerRef.player?.handlePlay();
      } else {
        setUrl(newUrl);
      }
    },
    [playerRef]
  );

  const handlePlayEpisode = useCallback(
    (episode_id, audio_url, play = true, ad_id) => {
      const isNewEpisode = episode_id !== episodePlayingID;

      if (episodePlayingID && isNewEpisode) {
        storeEpisodeProgress(progress, true);
      }

      setEpisodePlayingId(episode_id);
      setAdPlayingId(ad_id);
      setEpisodePlayingAudioUrl(audio_url);
      setPlaying(play);

      setSeekTo(0.0);
      setError(null);

      if (isNewEpisode) {
        setProgress({ played: 0, loaded: 0 });
      }
    },
    [episodePlayingID, progress, storeEpisodeProgress]
  );

  const handleSetPlayerRef = useCallback(
    (ref) => {
      if (!hasRef.current) {
        hasRef.current = true;
      }

      setPlayerRef(ref);

      if (!hasRef && url) {
        handlePlayerPlay(url);
      }
    },
    [handlePlayerPlay, url]
  );

  const handleClearPlayerRef = useCallback(() => {
    setUrl(null);
    setPlayerRef(null);
    hasRef.current = false;
  }, []);

  const seekPlayerTo = useCallback(
    (episode_id, audio_url, seekTo) => {
      handlePlayerPlay(audio_url);
      handlePlayEpisode(episode_id, audio_url, false);
      setSeekTo(seekTo);
    },
    [handlePlayerPlay, handlePlayEpisode]
  );

  const playPlayerAt = useCallback(
    (episode_id, audio_url, seekTo, ad_id) => {
      setAdPlayingId(ad_id);
      handlePlayerPlay(audio_url);
      handlePlayEpisode(episode_id, audio_url, false, ad_id);
      setSeekTo(seekTo);
      setPlaying(true);
    },
    [handlePlayerPlay, handlePlayEpisode]
  );

  const contextState = useMemo(
    () => ({
      playerRef,
      url,
      setPlayerRef: handleSetPlayerRef,
      clearPlayerRef: handleClearPlayerRef,
      closePlayer,
      togglePlaying: () => setPlaying((prev) => !prev),
      playerPlay: handlePlayerPlay,
      playEpisode: handlePlayEpisode,
      seekPlayerTo,
      getPlayStateOfEpisode,
      episodePlayingID,
      setEpisodePlayingId,
      setProgress,
      progress,
      playing,
      setPlaying,
      setSeekTo,
      seekTo,
      error,
      setError,
      episodePlayingAudioUrl,
      setEpisodePlayingAudioUrl,
      episode,
      podcast,
      entitiesLoading,
      audioUrl,
      playPlayerAt,
    }),
    [
      playerRef,
      url,
      handleSetPlayerRef,
      handleClearPlayerRef,
      handlePlayEpisode,
      handlePlayerPlay,
      closePlayer,
      getPlayStateOfEpisode,
      progress,
      playing,
      seekTo,
      error,
      episodePlayingID,
      episodePlayingAudioUrl,
      episode,
      podcast,
      entitiesLoading,
      audioUrl,
      seekPlayerTo,
      playPlayerAt,
    ]
  );

  return (
    <AudioPlayerContext.Provider value={contextState}>
      {children}
    </AudioPlayerContext.Provider>
  );
};

AudioPlayerProvider.propTypes = {
  children: PropTypes.node,
};

AudioPlayerProvider.defaultProps = {
  children: null,
};

export default AudioPlayerProvider;
