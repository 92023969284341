import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { useMemo } from "react";

import Badge from "./Badge";

import { selectSpecificUser } from "selectors/user";

import useReduxState from "hooks/useReduxState";
import { useStyles } from "hooks/useStyles";
import useUserFeaturedBadge from "hooks/useUserFeaturedBadge";

const DEFAULT_COLOR = "linear-gradient(#fcdb00, #f7b500)";

const BadgeContainer = (props) => {
  const {
    children,
    disableTooltip,
    onHover,
    onClick,
    styles,
    userId,
    badgeContainerComponent,
    badgeContainerProps,
  } = props;
  const usedStyles = useStyles(styles, props);

  const user = useReduxState((state) => selectSpecificUser(state, userId));
  const badge = useUserFeaturedBadge(user);

  const badgeColor = badge && badge.get("color");
  const passedStyles = useMemo(
    () => ({
      ...styles,
      userProfileImage: {
        backgroundImage: badgeColor || DEFAULT_COLOR,
        backgroundColor: badgeColor,
        ...(styles.userProfileImage || {}),
      },
    }),
    [badgeColor, styles]
  );

  if (!badge) {
    return <div className={css(usedStyles.styles.container)}>{children}</div>;
  }

  return (
    <Badge
      styles={passedStyles}
      description={badge.get("description")}
      badgeUrl={badge.get("badge_image_url")}
      name={badge.get("title")}
      disableTooltip={disableTooltip}
      onHover={onHover}
      onClick={onClick}
      badgeContainerComponent={badgeContainerComponent}
      badgeContainerProps={badgeContainerProps}
    >
      {children}
    </Badge>
  );
};

BadgeContainer.propTypes = {
  children: PropTypes.node,
  disableTooltip: PropTypes.bool,
  onHover: PropTypes.func,
  onClick: PropTypes.func,
  styles: PropTypes.object,
  userId: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]),
  user: PropTypes.instanceOf(Map),
  badgeContainerComponent: PropTypes.node,
  badgeContainerProps: PropTypes.object,
};

export default BadgeContainer;
