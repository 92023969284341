import Loadable from "@loadable/component";
const TellMeWhy = Loadable(() => import("./TellMeWhyModal"));

function TellMeWhyLoadingAsync() {
  return null;
}

const TellMeWhyModalContainer = (props) => {
  return <TellMeWhy {...props} fallback={<TellMeWhyLoadingAsync />} />;
};

export default TellMeWhyModalContainer;
