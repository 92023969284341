import PropTypes from "prop-types";
import { forwardRef, Fragment } from "react";

import NavTitle from "../NavTitle";

const NavMenuDropdownItemLabel = forwardRef((props, ref) => {
  const { item } = props;

  const { id, label, icon } = item;

  return (
    <Fragment>
      <div ref={ref}>{icon}</div>
      {label && <NavTitle id={id}>{label}</NavTitle>}
    </Fragment>
  );
});

NavMenuDropdownItemLabel.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.node.isRequired,
    icon: PropTypes.object,
  }).isRequired,
};

NavMenuDropdownItemLabel.displayName = "NavMenuDropdownItemLabel";

export default NavMenuDropdownItemLabel;
