import { css } from "aphrodite";
import PropTypes from "prop-types";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  noResults: {
    ...gStyles.fontLight,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flex: 1,
    color: colours.grey,
    fontSize: "1.4rem",
    lineHeight: "2.2rem",
    padding: "4rem",
    textAlign: "center",
  },
  noResultsInner: {
    maxWidth: "30rem",
  },
};

const EntityListNoResults = (props) => {
  const { children } = props;
  const { styles } = useStyles(baseStyles, props);

  return (
    <div className={css(styles.noResults)}>
      <div className={css(styles.noResultsInner)}>{children}</div>
    </div>
  );
};

EntityListNoResults.propTypes = {
  children: PropTypes.node,
};

export default EntityListNoResults;
