import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const TopSearchContainer = Loadable(() => import("./TopSearchContainer"));

function TopSearchContainerAsyncLoading(props) {
  return <LoadableLoading {...props} span />;
}

const TopSearchContainerContainer = (props) => {
  return (
    <TopSearchContainer
      {...props}
      fallback={<TopSearchContainerAsyncLoading />}
    />
  );
};

export default TopSearchContainerContainer;
