// const bodyFontSize = 16;
const breakpoints = {
  sm: 400, // 48 * bodyFontSize, // 768
  md: 640, // 64 * bodyFontSize, // 1024
  lg: 768, // 75 * bodyFontSize, // 1200
  xl: 1024, // 90 * bodyFontSize, // 1440
  xxl: 1200,
  xxxl: 1536,
};

export const standardBreakpoints = {
  tiny: breakpoints.sm,
  small: breakpoints.md,
  medium: breakpoints.lg,
  large: breakpoints.xl,
  xlarge: breakpoints.xxl,
  xxlarge: 100000,
};

const retinaOnly =
  "@media only screen and (-webkit-min-device-pixel-ratio : 2), only screen and (min-device-pixel-ratio : 2)";
const retinaOnlyMdAndAbove = `@media only screen and (-webkit-min-device-pixel-ratio : 2) and (min-width: ${breakpoints.md}px)`;
const retinaOnlyMdAndBelow = `@media only screen and (-webkit-min-device-pixel-ratio : 2) and (max-width: ${breakpoints.md}px)`;

export function createBreakpoint({ min, max }) {
  let breakpoint = "@media only screen";
  if (min) {
    const minValue = parseInt(min, 10);
    breakpoint = `${breakpoint} and (min-width: ${minValue}px)`;
  }
  if (max) {
    const maxValue = parseInt(max, 10);
    breakpoint = `${breakpoint} and (max-width: ${maxValue}px)`;
  }
  return breakpoint;
}

export default {
  xsAndBelow: createBreakpoint({ max: breakpoints.sm - 1 }),
  xsOnly: createBreakpoint({ max: breakpoints.sm - 1 }),
  xsAndAbove: createBreakpoint({}),

  sm: breakpoints.sm,

  smAndBelow: createBreakpoint({ max: breakpoints.md - 1 }),
  smOnly: createBreakpoint({ min: breakpoints.sm, max: breakpoints.md - 1 }),
  smAndAbove: createBreakpoint({ min: breakpoints.sm }),

  md: breakpoints.md,

  mdAndBelow: createBreakpoint({ max: breakpoints.lg - 1 }),
  mdOnly: createBreakpoint({ min: breakpoints.md, max: breakpoints.lg - 1 }),
  mdAndAbove: createBreakpoint({ min: breakpoints.md }),

  lg: breakpoints.lg,

  lgAndBelow: createBreakpoint({ max: breakpoints.xl - 1 }),
  lgOnly: createBreakpoint({ min: breakpoints.lg, max: breakpoints.xl - 1 }),
  lgAndAbove: createBreakpoint({ min: breakpoints.lg }),

  xl: breakpoints.xl,

  xlAndBelow: createBreakpoint({ max: breakpoints.xxl - 1 }),
  xlOnly: createBreakpoint({ min: breakpoints.xl, max: breakpoints.xxl - 1 }),
  xlAndAbove: createBreakpoint({ min: breakpoints.xl }),

  xxl: breakpoints.xxl,

  xxlAndBelow: createBreakpoint({}),
  xxlOnly: createBreakpoint({ min: breakpoints.xxl }),
  xxlAndAbove: createBreakpoint({ min: breakpoints.xxl }),

  xxxlAndBelow: createBreakpoint({}),
  xxxlOnly: createBreakpoint({ min: breakpoints.xxxl }),
  xxxlAndAbove: createBreakpoint({ min: breakpoints.xxxl }),

  xsAndSm: createBreakpoint({ max: breakpoints.md - 1 }),
  smAndMd: createBreakpoint({ min: breakpoints.sm, max: breakpoints.lg - 1 }),
  mdAndLg: createBreakpoint({ min: breakpoints.md, max: breakpoints.xl - 1 }),
  LgAndXl: createBreakpoint({ min: breakpoints.lg }),

  retinaOnly,
  retinaOnlyMdAndAbove,
  retinaOnlyMdAndBelow,
};
