import { createRoutine, promisifyRoutine } from "redux-saga-routines";

export const registerApp = createRoutine("REGISTER_APP");
export const registerAppPromise = promisifyRoutine(registerApp);

export const reloadAppSecret = createRoutine("RELOAD_APP_SECRET");
export const reloadAppSecretPromise = promisifyRoutine(reloadAppSecret);

export const updateApp = createRoutine("UPDATE_APP");
export const updateAppPromise = promisifyRoutine(updateApp);

export const loadAppData = createRoutine("LOAD_APP_DATA");
export const loadAppDataPromise = promisifyRoutine(loadAppData);
