import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const HomepageSidebarMore = Loadable(() => import("./HomepageSidebarMore"));

function HomepageSidebarMoreAsyncLoading(props) {
  return <LoadableLoading {...props} transparent />;
}

const HomepageSidebarMoreContainer = (props) => {
  return (
    <HomepageSidebarMore
      {...props}
      fallback={<HomepageSidebarMoreAsyncLoading />}
    />
  );
};

export default HomepageSidebarMoreContainer;
