import Loadable from "@loadable/component";

import AsyncLoadingEntityCard from "../AsyncLoadingEntityCard";

const PodcastCardContainer = Loadable(() => import("./PodcastCardContainer"));

function ContainerAsyncLoading(props) {
  return <AsyncLoadingEntityCard {...props} />;
}

const PodcastCardContainerContainer = (props) => {
  return (
    <PodcastCardContainer {...props} fallback={<ContainerAsyncLoading />} />
  );
};

export default PodcastCardContainerContainer;
