import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const TokenTestPage = Loadable(() => import("./TokenTestPage"));

function TokenTestPageAsyncLoading(props) {
  return <LoadableLoading {...props} page />;
}

const TokenTestPageContainer = (props) => {
  return <TokenTestPage {...props} fallback={<TokenTestPageAsyncLoading />} />;
};

export default TokenTestPageContainer;
