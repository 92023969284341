import Loadable from "@loadable/component";

import AsyncLoadingEntityCard from "../AsyncLoadingEntityCard";

const UserCardContainer = Loadable(() => import("./UserCardContainer"));

function ContainerAsyncLoading(props) {
  return <AsyncLoadingEntityCard {...props} />;
}

const UserCardContainerContainer = (props) => {
  return <UserCardContainer {...props} fallback={<ContainerAsyncLoading />} />;
};

export default UserCardContainerContainer;
