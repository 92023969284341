import { Map } from "immutable";
import { call, put, select } from "redux-saga/effects";

import * as paginationConstants from "constants/pagination";
import addCacheTags from "sagas/utils/addCacheTags";
import { selectList, selectListIds } from "selectors/pagination";
import { log, prodLog } from "utils/dev";
import { getSort, getApiSort, getApiOptions } from "utils/pagination";

export function* getApiProps(action) {
  let pagination = yield select(selectList, { key: action.key });

  if (!pagination || action.reloadList) {
    yield put({
      ...action,
      type: paginationConstants.PAGINATION_INIT_LIST_CREATE,
      loading: true,
      loaded: false,
    });
    pagination = yield select(selectList, { key: action.key });
  }

  const currentIds = pagination.getIn(["idsBySort", pagination.get("sort")]);
  const offset = currentIds ? currentIds.size : 0;

  const apiProps = {
    ...pagination.get("apiProps").toJS(),
    sort_order: getApiSort(pagination.get("sort")),
    sort_direction: pagination.get("sort_direction"),
    count: action.pageCount || pagination.get("pageSize"),
    offset:
      action.replaceList || action.reloadList
        ? pagination.get("offset")
        : offset,
    filters: pagination
      .get("filters", Map())
      .merge(pagination.get("staticFilters"))
      .map((val) => val && val.get("value")),
    groups: pagination.get("groups") || [],
    rescore: pagination.get("rescore"),
    options: getApiOptions(pagination.get("sort"), pagination.get("options")),
  };

  return {
    apiProps,
    pagination,
  };
}

// Don't call this directly or we won't get cancellation,call fireNextPage to put an action on the channel instead
export default function* loadList({
  list_type,
  action: passedAction,
  apiProps,
  response,
  pagination,
  pageListener,
}) {
  const action = {
    ...passedAction,
    list_type,
  };

  if (!action.silent) {
    yield put({
      ...action,
      type: paginationConstants.PAGINATION_NEXT_PAGE_LOADING,
    });
  }

  if (!apiProps.sort_direction) {
    delete apiProps.sort_direction;
  }

  const actionProps = {
    sort: apiProps.sort_order,
    sort_direction: apiProps.sort_direction,
  };

  try {
    if (response) {
      // check for a changed sort value, in case the sort we requested wasn't available
      if (response.meta.sort && response.meta.sort.sort !== actionProps.sort) {
        actionProps.sort = response.meta.sort.sort;
        log("AFTER CHANGE", actionProps);
      }

      actionProps.sort = getSort(actionProps.sort, apiProps.options);

      if (pageListener) {
        yield call(pageListener, {
          pagination,
          ...action,
          ...actionProps,
          response,
        });
      }

      yield put({
        ...action,
        ...actionProps,
        type: paginationConstants.PAGINATION_NEXT_PAGE_SUCCESS,
        response,
      });

      const newIds = yield select(selectListIds, action.key);

      // sometimes we store the full object in the list rather than the normalized ID
      yield call(
        addCacheTags,
        newIds
          .filter((id) => typeof id === "string" || typeof id === "number")
          .map((id) => `${pagination.get("entity_type")}:${id}`)
      );
    }
  } catch (e) {
    yield put({
      ...action,
      ...actionProps,
      type: paginationConstants.PAGINATION_NEXT_PAGE_FAILURE,
      error: e.message,
    });
    prodLog("nextPage catch", e);
  }
}
