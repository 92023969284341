import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const Top8Editor = Loadable(() => import("./Top8Editor"));

function Top8EditorAsyncLoading(props) {
  return <LoadableLoading {...props} transparent />;
}

const Top8EditorContainer = (props) => {
  return <Top8Editor {...props} fallback={<Top8EditorAsyncLoading />} />;
};

export default Top8EditorContainer;
