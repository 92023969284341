import { faPlug } from "@fortawesome/pro-solid-svg-icons/faPlug";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import { useEffect, useMemo } from "react";
import { loadOauthAppPromise } from "routines/auth";

import LoadingIcon from "components/Common/LoadingIcon";
import OAuthAuthorizationPrompt from "pages/OAuthConnect/OAuthAuthorizationPrompt";
import OAuthLoginRegisterPrompt from "pages/OAuthConnect/OAuthLoginRegisterPrompt";

import PodchaserBrandLogo from "../../components/Branding/PodchaserBrandLogo";
import gStyles from "../../styles/GenericStyles";
import ScreenSizes from "../../styles/ScreenSizes";
import OAuthError from "./OAuthError";
import { oAuthStyles } from "./OAuthStyles";
import OauthStyleWrapper from "./OauthStyleWrapper";

import { toPascalCase } from "utils/case";

import useLoggedInUser, { useLoggedIn } from "hooks/useLoggedInUser";
import useQuery from "hooks/useQuery";
import useReduxState from "hooks/useReduxState";
import useRoutinePromises from "hooks/useRoutinePromises";
import { useStyles } from "hooks/useStyles";

const baseStyles = {
  ...oAuthStyles,
  apps: {
    display: "flex",
    marginBottom: "1.5rem",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  appDivider: {
    margin: "0 0.75rem",
    color: "#ddd",
    fontSize: "2rem",
    maxWidth: "3rem",
  },
  app: {
    margin: "0 0.5rem",
  },
  introApp: {
    ...gStyles.fontBold,
  },
  container: {},
  scopes: {
    listStyle: "none",
    appearance: "normal",
    borderTop: "1px solid #eee",
    borderBottom: "1px solid #eee",
    padding: 0,
    margin: 0,
    marginBottom: "1rem",
    [ScreenSizes.mdAndAbove]: {
      border: "1px solid #eee",
    },
  },
  scope: {
    ...gStyles.fontSemiBold,
    listStyle: "none",
    appearance: "normal",
    borderBottom: "1px solid #eee",
    padding: "0.5rem 0",
    fontSize: "0.8125rem",
    margin: 0,
    textAlign: "center",
    [ScreenSizes.mdAndAbove]: {
      borderBottom: "1px solid #eee",
      padding: "0.75rem 1.25rem",
      textAlign: "left",
    },
    ":last-child": {
      borderBottom: 0,
    },
  },
};

function OAuthPrompt(props) {
  const { styles } = useStyles(baseStyles, props);

  const isLoggedIn = useLoggedIn();

  const authState = useReduxState((state) => {
    return state.auth.get("oauthContext");
  }, []);

  const { loadOauthApp } = useRoutinePromises({
    loadOauthApp: loadOauthAppPromise,
  });

  const [query] = useQuery();

  let authStateNotLoaded =
    !authState ||
    authState.size === 0 ||
    (isLoggedIn && !authState.get("authToken"));

  useEffect(() => {
    if (authStateNotLoaded) {
      loadOauthApp({ query });
    }
  }, [authStateNotLoaded, loadOauthApp, query]);

  const {
    client,
    scope: requestedScopes,
    redirect_uri,
    scopes: availableScopes,
    error,
    error_description,
    hint: error_hint,
    authToken,
    state,
    client_id,
  } = (authState && authState.toJS()) || {};

  const readableScopes = useMemo(
    () =>
      availableScopes &&
      availableScopes.reduce(
        (agg, next) => ({
          ...agg,
          [next.id]: next.description,
        }),
        {}
      ),
    [availableScopes]
  );

  const user = useLoggedInUser();

  if (authStateNotLoaded) {
    return (
      <OauthStyleWrapper>
        <LoadingIcon />
      </OauthStyleWrapper>
    );
  }

  if (!client || error || error_description || error_hint) {
    return (
      <OAuthError
        error={error}
        error_description={error_description}
        error_hint={error_hint}
      />
    );
  }

  const prompt = isLoggedIn ? (
    <OAuthAuthorizationPrompt
      scopes={requestedScopes}
      user={user}
      redirect_uri={redirect_uri}
      authToken={authToken}
      state={state}
      client_id={client_id}
    />
  ) : (
    <OAuthLoginRegisterPrompt redirect_uri={redirect_uri} />
  );

  return (
    <OauthStyleWrapper>
      <h2 className={css(styles.intro)}>
        <div className={css(styles.apps)}>
          <div
            className={css(
              styles.app,
              styles[`app${toPascalCase(client.name)}`]
            )}
          >
            <img
              src={client.logo_image_url}
              width="50"
              height="50"
              alt="Authentication Client Logo"
            />
          </div>
          <span className={css(styles.appDivider)}>
            <FontAwesomeIcon icon={faPlug} rotation={90} />
          </span>
          <div className={css(styles.app, styles.appPodchaser)}>
            <PodchaserBrandLogo width="50" height="50" iconOnly />
          </div>
        </div>
        <span className={css(styles.introApp)}>{client.name}</span> is
        requesting access to your Podchaser account.
      </h2>
      {requestedScopes && requestedScopes.length > 0 && (
        <ul className={css(styles.scopes)}>
          {requestedScopes.map((scope) => (
            <li className={css(styles.scope)} key={scope}>
              {readableScopes[scope] || scope}
            </li>
          ))}
        </ul>
      )}
      {prompt}
    </OauthStyleWrapper>
  );
}

export default OAuthPrompt;
