import Loadable from "@loadable/component";

import LoadingEntityFollowButtonAsync from "./LoadingEntityFollowButtonAsync";

const EntityFollowButton = Loadable(() => import("./EntityFollowButton"));

function EntityFollowButtonAsyncLoading(props) {
  return <LoadingEntityFollowButtonAsync {...props} />;
}

const EntityFollowButtonContainer = (props) => {
  return (
    <EntityFollowButton
      {...props}
      fallback={<EntityFollowButtonAsyncLoading />}
    />
  );
};

export default EntityFollowButtonContainer;
