import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const HomepageSidebarCategories = Loadable(() =>
  import("./HomepageSidebarCategories")
);

function HomepageSidebarCategoriesAsyncLoading(props) {
  return <LoadableLoading {...props} transparent />;
}

const HomepageSidebarCategoriesContainer = (props) => {
  return (
    <HomepageSidebarCategories
      {...props}
      fallback={<HomepageSidebarCategoriesAsyncLoading />}
    />
  );
};

export default HomepageSidebarCategoriesContainer;
