import {
  AUTH_MODULE,
  BADGES_MODULE,
  BRANDS_MODULE,
  CATEGORY_MODULE,
  CLAIMING_MODULE,
  CREATOR_VIEW_MODULE,
  PAGINATION_MODULE,
  PODCAST_MODULE,
  RATING_MODULE,
  REVIEW_MODULE,
  TAG_MODULE,
  TABLE_MODULE,
  USER_MODULE,
  USERLIST_COMMON_MODULE,
  USER_PROFILE_MODULE,
  NETWORK_MODULE,
} from "modules/moduleIds";

export const commonModuleGroup = [
  USER_MODULE,
  PAGINATION_MODULE,
  USERLIST_COMMON_MODULE,
  AUTH_MODULE,
  USER_PROFILE_MODULE,
  CLAIMING_MODULE,
  BADGES_MODULE,
  TABLE_MODULE,
];

export const podcastModuleGroup = [
  PAGINATION_MODULE,
  PODCAST_MODULE,
  CATEGORY_MODULE,
  REVIEW_MODULE,
  USERLIST_COMMON_MODULE,
  RATING_MODULE,
  TAG_MODULE,
  CREATOR_VIEW_MODULE,
  BRANDS_MODULE,
  NETWORK_MODULE,
];
