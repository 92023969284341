import { css } from "aphrodite";
import PropTypes from "prop-types";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  navTitle: {
    ...gStyles.avalonBold,
    transition:
      "transform 100ms cubic-bezier(0.465, 0.183, 0.153, 0.946), opacity 100ms cubic-bezier(0.465, 0.183, 0.153, 0.946) 200ms",
    display: "block",
    textTransform: "none",
    letterSpacing: "0.01em",
    alignSelf: "center",
    opacity: 1,
    verticalAlign: "middle",
    lineHeight: "2em",
    fontSize: "0.875rem",
    [ScreenSizes.smOnly]: {
      fontSize: "0.875rem",
    },
  },
};

const NavTitle = (props) => {
  const { id, children } = props;
  const { styles } = useStyles(baseStyles, props);

  return (
    <span data-id={`nav-${id}`} className={css(styles.navTitle)}>
      {children}
    </span>
  );
};

NavTitle.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default NavTitle;
