import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const ProfileFollowedView = Loadable(() => import("./ProfileFollowedView"));

function FollowedViewAsyncLoading(props) {
  return <LoadableLoading {...props} page />;
}

const ProfileFollowedViewContainer = (props) => {
  return (
    <ProfileFollowedView {...props} fallback={<FollowedViewAsyncLoading />} />
  );
};

export default ProfileFollowedViewContainer;
