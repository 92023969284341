/* eslint-disable max-len */

export default {
  prefix: "fpc",
  iconName: "pc-x-twitter",
  icon: [
    450,
    450,
    [],
    null,
    "M362.4,0 L433,0 L278.8,176.2 L460.2,416 L318.2,416 L206.9,270.6 L79.7,416 L9,416 L173.9,227.5 L4.61852778e-14,0 L145.6,0 L246.1,132.9 L362.4,0 Z M337.6,373.8 L376.7,373.8 L124.3,40 L82.3,40 L337.6,373.8 Z",
  ],
};
