import { camelCaseToDashCase } from "utils/meta";

export default (props = {}) =>
  (Array.isArray(props) ? props : [props]).reduce(
    (transition, transitProps, index) => {
      const {
        target = "",
        targets = [],
        speed = "250ms",
        easing = "cubic-bezier(0.465, 0.183, 0.153, 0.946)",
        delay = "0s",
      } = props;
      let finalTargets = [...targets];

      if (target && target !== "visual") {
        finalTargets = [...finalTargets, target];
      }
      if (target === "visual") {
        finalTargets = [
          ...finalTargets,
          "opacity",
          "border-color",
          "color",
          "background-color",
          "box-shadow",
        ];
      }
      if (!target && finalTargets.length === 0) {
        finalTargets = ["all"];
      }
      const fullSpeed = typeof speed === "number" ? `${speed}ms` : speed;

      const transitValue = finalTargets
        .map((targetName) => {
          const cleanTargetName = camelCaseToDashCase(targetName);
          return `${cleanTargetName} ${fullSpeed} ${easing} ${delay}`;
        })
        .join(", ");

      return index === 0 ? transitValue : `, ${transitValue}`;
    },
    ""
  );
