import { List } from "immutable";

export default function commaList(passedList, mapFunc, options = {}) {
  if (passedList === null) {
    return null;
  }
  const cleanList =
    typeof passedList.toList === "function" ? passedList.toList() : passedList;
  if (!Array.isArray(cleanList) && !List.isList(cleanList)) {
    throw new TypeError("humanize-list expected an array");
  }

  const list = cleanList.filter(
    (item) => !!item && (typeof item !== "string" || item.trim() !== "")
  );
  const conjunction = options.conjunction || "and";
  const getListItem = (index) =>
    List.isList(list) ? list.get(index) : list[index];

  const commaFunc = options.commaFunc
    ? options.commaFunc
    : (i, str, commaType = null) => (
        <span
          className={options.commaClass}
          key={`cat-span-${i}${commaType ? `-${commaType}` : ""}`}
        >
          {str}
        </span>
      );

  const listLength = list?.length || list?.size;

  if (listLength === 1) {
    return [mapFunc ? mapFunc(getListItem(0), 0) : getListItem(0)];
  }

  const humanizedList = [];
  const conjunctionString =
    typeof conjunction === "string" ? ` ${conjunction} ` : conjunction;

  for (let i = 0; i < listLength; i++) {
    if (i === listLength - 1) {
      if (options.skipConjunction) {
        humanizedList.push(commaFunc(i, ", ", "skipConj"));
      } else {
        if (options.oxfordComma && listLength > 2) {
          humanizedList.push(commaFunc(i, ",", "oxfordConj"));
        }

        humanizedList.push(commaFunc(i, conjunctionString, "customConj"));
      }
    } else if (i !== 0) {
      humanizedList.push(commaFunc(i, ", ", "conj"));
    }

    humanizedList.push(mapFunc ? mapFunc(getListItem(i), i) : getListItem(i));
  }

  return humanizedList;
}
