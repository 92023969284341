import { userHasInternalPermission } from "utils/entity/user";

import useLoggedInUser from "hooks/useLoggedInUser";

const useUserHasInternalPermission = () => {
  const user = useLoggedInUser();

  return userHasInternalPermission(user);
};

export default useUserHasInternalPermission;
