import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const VerifyMagicLink = Loadable(() => import("./VerifyMagicLink"));

function VerifyMagicLinkAsyncLoading(props) {
  return <LoadableLoading {...props} />;
}

const VerifyMagicLinkContainer = (props) => {
  return (
    <VerifyMagicLink {...props} fallback={<VerifyMagicLinkAsyncLoading />} />
  );
};

export default VerifyMagicLinkContainer;
