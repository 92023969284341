import PropTypes from "prop-types";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

export const WindowScriptContext = createContext({});

export const WindowScriptProvider = (props) => {
  const { children } = props;
  const [scripts, setScripts] = useState({});

  const setScript = useCallback(
    (name, script) =>
      setScripts((scr) => ({
        ...scr,
        [name]: script,
      })),
    []
  );

  const context = useMemo(
    () => ({
      setScript,
      ...scripts,
    }),
    [setScript, scripts]
  );

  return (
    <WindowScriptContext.Provider value={context}>
      {children}
    </WindowScriptContext.Provider>
  );
};

WindowScriptProvider.propTypes = {
  children: PropTypes.node,
};
WindowScriptProvider.defaultProps = {
  children: null,
};

export default function useWindowScripts() {
  return useContext(WindowScriptContext);
}

export function useIntercomHasLoaded() {
  const { intercom } = useWindowScripts();

  if (intercom && window && window.Intercom) {
    return intercom;
  }

  return null;
}

export function useShowIntercom() {
  const intercom = useIntercomHasLoaded();

  if (intercom && window.Intercom) {
    return () =>
      window.Intercom("update", {
        hide_default_launcher: false,
      });
  }

  return () => {};
}

export function useHideIntercom() {
  const intercom = useIntercomHasLoaded();

  if (intercom && window.Intercom) {
    return () =>
      window.Intercom("update", {
        hide_default_launcher: true,
      });
  }

  return () => {};
}
