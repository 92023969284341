import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const ChartsSidebar = Loadable(() => import("./ChartSidebarContainer"));

function ChartsSidebarAsyncLoading(props) {
  return (
    <LoadableLoading
      key="chartsSidebarLoadingOverlay"
      withPageHeader
      height={"136rem"}
    />
  );
}

const ChartsSidebarContainer = (props) => {
  return <ChartsSidebar {...props} fallback={<ChartsSidebarAsyncLoading />} />;
};

export default ChartsSidebarContainer;
