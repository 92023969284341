import { faBookmark } from "@fortawesome/free-solid-svg-icons/faBookmark";
import { faBookmark as faBookmarkRegular } from "@fortawesome/pro-regular-svg-icons/faBookmark";
import { Map } from "immutable";
import PropTypes from "prop-types";
import deepmerge from "ramda/src/mergeDeepRight";
import { memo, useCallback, useMemo } from "react";
import { setBookmarkedPromise } from "routines/bookmarks";

import BaseCardAction from "./BaseCardActionAsync";

import { capitalize } from "utils/misc";

import useRoutinePromises from "hooks/useRoutinePromises";

const BookmarkAction = (props) => {
  const {
    entity,
    entity_id,
    entity_type,
    regular,
    action,
    feedActivityContext,
    index,
    customButtonStyles: passedCustomButtonStyles,
  } = props;

  const { setBookmarked } = useRoutinePromises({
    setBookmarked: setBookmarkedPromise,
  });

  const bookmarked = entity && entity.getIn(["user_data", "bookmarked"]);

  const handleBookmarkClick = useCallback(
    (bookmark) => (e) => {
      e.preventDefault();
      e.stopPropagation();
      setBookmarked({
        entity,
        entity_type,
        entity_id: entity_id || (entity && entity.get("id")),
        bookmark,
        analyticsVariables: feedActivityContext,
      });
    },
    [setBookmarked, entity_type, entity_id, entity, feedActivityContext]
  );

  const customButtonStyles = useMemo(
    () =>
      deepmerge(passedCustomButtonStyles, {
        icon: {
          maxWidth: ".75em",
        },
      }),
    [passedCustomButtonStyles]
  );

  return (
    <BaseCardAction
      {...props}
      index={index}
      {...action}
      icon={regular ? faBookmarkRegular : faBookmark}
      tooltip={
        bookmarked ? "Remove Bookmark" : `Bookmark ${capitalize(entity_type)}`
      }
      actioned={bookmarked}
      onClick={handleBookmarkClick(!bookmarked)}
      customButtonStyles={customButtonStyles}
      active={bookmarked}
    />
  );
};

BookmarkAction.propTypes = {
  entity: PropTypes.instanceOf(Map),
  entity_type: PropTypes.string,
  entity_id: PropTypes.string,
  regular: PropTypes.bool,
  action: PropTypes.object.isRequired,
  feedActivityContext: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  customButtonStyles: PropTypes.object,
};

BookmarkAction.defaultProps = {
  entity: null,
  entity_type: null,
  entity_id: null,
  regular: false,
  customButtonStyles: undefined,
};

export default memo(BookmarkAction);
