export const SEARCH_ON_CHANGE = "SEARCH_ON_CHANGE";
export const SEARCH_TYPE_START = "SEARCH_TYPE_START";
export const SEARCH_REQUEST = "SEARCH_REQUEST";
export const SEARCH_RESULTS_RECEIVED = "SEARCH_RESULTS_RECEIVED";
export const SEARCH_CANCEL = "SEARCH_CANCEL";
export const SEARCH_URL_ENTERED = "SEARCH_URL_ENTERED";
export const SEARCH_URL_RESULTS_RECEIVED = "SEARCH_URL_RESULTS_RECEIVED";
export const SEARCH_SUBMIT_FEED_URL_REQUEST = "SEARCH_SUBMIT_FEED_URL_REQUEST";
export const SEARCH_SUBMIT_FEED_URL_REQUESTING =
  "SEARCH_SUBMIT_FEED_URL_REQUESTING";
export const SEARCH_SUBMIT_FEED_URL_FAILED = "SEARCH_SUBMIT_FEED_URL_FAILED";

export const SUBMIT_URL_NO_URL_ENTERED = "SUBMIT_URL_NO_URL_ENTERED";
export const SUBMIT_URL_SEARCHING_FOR_URL = "SUBMIT_URL_SEARCHING_FOR_URL";
export const SUBMIT_URL_SEARCH_RESULTS_RECEIVED =
  "SUBMIT_URL_SEARCH_RESULTS_RECEIVED";
export const SUBMIT_URL_SUBMITTING = "SUBMIT_URL_SUBMITTING";
export const SUBMIT_URL_SUBMIT_FAILED = "SUBMIT_URL_SUBMIT_FAILED";

export const FACETED_SEARCH_INIT = "FACETED_SEARCH_INIT";
export const SEARCH_WITHIN_FACETS = "SEARCH_WITHIN_FACETS";
export const FACETED_SEARCH_UPDATE_SELECTED = "FACETED_SEARCH_UPDATE_SELECTED";
export const FACETED_SEARCH_REQUESTING = "FACETED_SEARCH_REQUESTING";
export const FACETED_SEARCH_RESULTS_RECEIVED =
  "FACETED_SEARCH_RESULTS_RECEIVED";
export const FACETED_SEARCH_FACET_CHANGE = "FACETED_SEARCH_FACET_CHANGE";
export const FACETED_SEARCH_SET_ENTITY_TYPE = "FACETED_SEARCH_SET_ENTITY_TYPE";
export const FACETED_SEARCH_UPDATE_SORT = "FACETED_SEARCH_UPDATE_SORT";
export const FACETED_SEARCH_UPDATE_PAGE = "FACETED_SEARCH_UPDATE_PAGE";

export const SEARCH_ITEM_SELECTED = "SEARCH_ITEM_SELECTED";

export const GET_RECENT_SEARCH_HISTORY = "GET_RECENT_SEARCH_HISTORY";
export const POST_RECENT_SEARCH = "POST_RECENT_SEARCH";
export const POST_RECENT_SEARCH_SUCCESS = "POST_RECENT_SEARCH_SUCCESS";

export const GET_RECENT_SEARCH_HISTORY_SUCCESS =
  "GET_RECENT_SEARCH_HISTORY_SUCCESS";

export const DELETE_RECENT_SEARCH = "DELETE_RECENT_SEARCH";

export const DELETE_RECENT_SEARCH_SUCCESS = "DELETE_RECENT_SEARCH_SUCCESS";
