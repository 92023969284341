// TODO: Move elsewhere
import { equals } from "utils/misc";

import { rateColours } from "styles/colours";

export const clamp = (num, min, max) => {
  if (num <= min) return min;
  if (num >= max) return max;

  return num;
};

export const getColorBetween = (color1, color2, percent) => {
  const diff = Math.abs(color2 - color1) * percent;
  const color = color1 > color2 ? color1 - diff : color1 + diff;

  return clamp(color, 0, 255);
};

export const getRgbBetween = (rgb1, rgb2, percent) => ({
  r: getColorBetween(rgb1.r, rgb2.r, percent),
  g: getColorBetween(rgb1.g, rgb2.g, percent),
  b: getColorBetween(rgb1.b, rgb2.b, percent),
});

export const calcStarColor = (rating) => {
  const ratingFloor = Math.floor(rating);
  const ratingDecimal = rating - Math.floor(rating);
  let currentColour;

  switch (ratingFloor) {
    case 0:
      if (ratingDecimal === 0) {
        currentColour = { r: 240, g: 240, b: 240 };
      } else {
        currentColour = getRgbBetween(
          rateColours.one,
          rateColours.two,
          ratingDecimal
        );
      }
      break;
    case 1:
      currentColour = getRgbBetween(
        rateColours.one,
        rateColours.two,
        ratingDecimal
      );
      break;
    case 2:
      currentColour = getRgbBetween(
        rateColours.two,
        rateColours.three,
        ratingDecimal
      );
      break;
    default:
    case 3:
      currentColour = getRgbBetween(
        rateColours.three,
        rateColours.four,
        ratingDecimal
      );
      break;
    case 4:
      currentColour = getRgbBetween(
        rateColours.four,
        rateColours.five,
        ratingDecimal
      );
      break;
    case 5:
      currentColour = rateColours.five;
      break;
  }

  return `rgb(${Math.round(currentColour.r)},${Math.round(
    currentColour.g
  )},${Math.round(currentColour.b)})`;
};

export const getEntityDefaultColor = (entity_id) => {
  const rgbRange = { low: 200, range: 52 };
  const idModified = Math.round(
    parseFloat(entity_id) * parseFloat(entity_id) * 6.9
  );
  const id = `${idModified}`.padStart(6, "0").split("").reverse().join("");
  const values = {
    r: parseFloat(`.${id.substr(0, 3)}`),
    g: parseFloat(`.${id.substr(3, 3)}`),
    b: parseFloat(`.${`${id.substr(0, 3)}`.split("").reverse().join("")}`),
  };
  const colors = {
    r: Math.round(rgbRange.low + values.r * rgbRange.range),
    g: Math.round(rgbRange.low + values.g * rgbRange.range),
    b: Math.round(rgbRange.low + values.b * rgbRange.range),
  };
  // Avoid greys
  const lowest = Math.min(colors.r, colors.g, colors.b);
  const highest = Math.max(colors.r, colors.g, colors.b);
  const range = highest - lowest;
  const mid = lowest + range / 2;

  if (equals(range, 0)) {
    // If it's pure grey, just make it blueish (and cap blue at 250)
    colors.r -= 10;
    colors.b += 10;
    if (colors.b > 250) {
      colors.b = 250;
    }
  } else if (range < 30) {
    let newMid = mid;
    if (mid > 235) {
      // Make sure we don't go over 255
      newMid = 230;
    }
    const fromMid = {
      r: colors.r - mid,
      g: colors.g - mid,
      b: colors.b - mid,
    };
    const fromMidPercent = {
      r: fromMid.r / (range / 2),
      g: fromMid.g / (range / 2),
      b: fromMid.b / (range / 2),
    };
    // Stretch out values so they reach the ideal range
    const idealRange = 20;
    colors.r = newMid + fromMidPercent.r * idealRange;
    colors.g = newMid + fromMidPercent.g * idealRange;
    colors.b = newMid + fromMidPercent.b * idealRange;
  }

  return {
    r: Math.round(Math.min(255, colors.r)),
    g: Math.round(Math.min(255, colors.g)),
    b: Math.round(Math.min(255, colors.b)),
  };
};
