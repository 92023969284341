import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useCallback, useMemo } from "react";

import StandardButton from "components/Buttons/StandardButton";
import EntityImage from "components/Entities/EntityImage";
import PodcastSubtitle from "components/Podcast/PodcastSubtitle";
import RatingWithHover from "components/Rating/RatingWithHoverAsync";

import BookmarkAction from "../Entities/Cards/CardActions/BookmarkAction";
import EntityLink from "../Entities/EntityLink";
import InfoIcons from "../Entities/Items/InfoIconsAsync";
import HoverCardFollowButton from "./HoverCardFollowButton";
import profileHoverCardStyles from "./ProfileHoverCardStyles";
import ProfileHoverPopoverContent from "./ProfileHoverPopoverContent";

import podcastActions from "actions/podcast";
import {
  selectSpecificPodcast,
  selectPodcastLoading,
  selectPodcastFailed,
} from "selectors/podcast";
import getPodcastAuthorityLevel from "utils/entity/getPodcastAuthorityLevel";
import getPodcastUrl from "utils/entity/getPodcastUrl";
import { capitalize } from "utils/misc";
import { truncateString } from "utils/truncate";

import useActionCreators from "hooks/useActionCreators";
import useLoadEntity from "hooks/useLoadEntity";
import { useStyles } from "hooks/useStyles";

import ScreenSizes from "styles/ScreenSizes";

const mobileImageSize = "10rem";
const desktopImageSize = "10rem";

const baseStyles = {
  ...profileHoverCardStyles,
  content: {
    ...profileHoverCardStyles.content,
    textAlign: "center",
  },
  topInfoRow: {
    ...profileHoverCardStyles.topInfoRow,
    flexDirection: "column",
  },
  profileImage: {
    ...profileHoverCardStyles.profileImage,
    marginBottom: "1rem",
    marginRight: 0,
    width: mobileImageSize,
    height: mobileImageSize,

    [ScreenSizes.lgAndAbove]: {
      ...profileHoverCardStyles.profileImage[ScreenSizes.lgAndAbove],
      marginRight: 0,
      marginBottom: ".75rem",
      width: desktopImageSize,
      height: desktopImageSize,
    },
  },
  names: {
    marginBottom: "1rem",
  },
  name: {
    ...profileHoverCardStyles.name,
    marginBottom: ".75rem",
    display: "block",

    [ScreenSizes.lgAndAbove]: {
      ...profileHoverCardStyles.name[ScreenSizes.lgAndAbove],
      marginBottom: ".5rem",
      fontSize: "1rem",
    },
  },
  rating: {
    marginBottom: "0.5rem",
    fontSize: "0.875rem",
  },
  description: {
    ...profileHoverCardStyles.description,
    fontSize: "0.875rem",
  },
};

const bookmarkActionStyles = {
  button: {
    width: "40px",
    marginLeft: "6px",
    flexBasis: "20%",
  },
};

const subtitleStyles = {
  link: {
    ...profileHoverCardStyles.descriptionLink,
  },
};

const PodcastProfileHoverCard = (props) => {
  const { mobile, width, podcast_id, popoutProps } = props;
  const { styles } = useStyles(baseStyles, props);

  const { loadSpecificPodcast } = useActionCreators({
    loadSpecificPodcast: podcastActions.loadSpecificPodcast,
  });

  const { entity: podcast, loading } = useLoadEntity({
    entity_type: "podcast",
    entity_id: podcast_id,
    loader: loadSpecificPodcast,
    selector: selectSpecificPodcast,
    loadingSelector: selectPodcastLoading,
    failedSelector: selectPodcastFailed,
  });

  // TODO: This is a bit of a hack to 'fix' the text-based categories, just to get this working quickly.
  const podcastWithCategories = useMemo(() => {
    if (podcast) {
      return podcast.set(
        "categories",
        podcast.get("categories")
          ? podcast.get("categories").map((category) => {
              if (typeof category === "string") {
                return new Map([
                  [
                    "text",
                    category &&
                      category
                        .split("-")
                        .map((word) => capitalize(word))
                        .join(" ")
                        .replace(" Category", ""),
                  ],
                  ["slug", category],
                ]);
              }
              if (typeof category === "object") {
                return category;
              }
              return new Map([]);
            })
          : new Map([])
      );
    }
  }, [podcast]);

  const icons = useMemo(
    () => [
      {
        type: "authority",
        value: getPodcastAuthorityLevel(podcast, 0),
        to: getPodcastUrl(podcast, "/insights"),
        condition: () => !!getPodcastAuthorityLevel(podcast, 0),
        proOnly: true,
      },
    ],
    [podcast]
  );

  const renderInfo = useCallback(
    () => (
      <div className={css(styles.topInfoRow)}>
        <div className={css(styles.profileImage)}>
          <EntityImage
            entity={podcastWithCategories}
            entity_type="podcast"
            alt={podcast.get("title")}
            size={width}
            disablePopup
            fullWidth
          />
        </div>
        <EntityLink
          entity={podcast}
          entity_type="podcast"
          clamp={2}
          disablePopup
          avalon
          showClaimedBadge
        />
        {podcastWithCategories.get("categories").size > 0 && (
          <span className={css(styles.subtitle)}>
            <PodcastSubtitle
              podcast={podcastWithCategories}
              useUncategorised={false}
              styles={subtitleStyles}
            />
          </span>
        )}
        {podcastWithCategories.get("rating") && (
          <span className={css(styles.rating)}>
            <RatingWithHover
              rating={podcastWithCategories.get("rating")}
              id={podcastWithCategories.get("id")}
              quiet
              readonly
              fontSize="1em"
              ComponentType="span"
              entity={podcastWithCategories}
              isAggregate
              ariaLabelFunc={({ rating }) =>
                `Community Rating: ${Math.round(rating * 100) / 100} out of 5`
              }
              showTooltip
            />
          </span>
        )}
        <InfoIcons icons={icons} />
        {mobile && (
          <span className={css(styles.description)}>
            {truncateString(podcast.get("description"), "120")}
          </span>
        )}
        <span className={css(styles.divider)} />
      </div>
    ),
    [
      icons,
      mobile,
      podcast,
      podcastWithCategories,
      styles.description,
      styles.divider,
      styles.profileImage,
      styles.rating,
      styles.subtitle,
      styles.topInfoRow,
      width,
    ]
  );

  if (loading || !podcast) {
    return null;
  }

  return (
    <ProfileHoverPopoverContent popoutProps={popoutProps}>
      <span className={css(styles.content, mobile && styles.mobileContent)}>
        {renderInfo()}
        <div className={css(styles.buttonContainer)}>
          <HoverCardFollowButton
            name="Podcast"
            entity={podcast}
            entity_type="podcast"
          />
          <BookmarkAction
            actions={[{ type: "bookmark" }]}
            key="bookmark"
            customButtonStyles={bookmarkActionStyles}
            asButton
            noLabel
            variation="white"
            entity={podcast}
            entity_type="podcast"
          />
        </div>
        {mobile && (
          <StandardButton
            label="View Podcast"
            variation="white"
            to={getPodcastUrl(podcast)}
            link
            flat
          />
        )}
      </span>
    </ProfileHoverPopoverContent>
  );
};

PodcastProfileHoverCard.propTypes = {
  popoutProps: PropTypes.object.isRequired,
  podcast_id: PropTypes.number.isRequired,
  mobile: PropTypes.bool,
  width: PropTypes.number,
};

PodcastProfileHoverCard.defaultProps = {
  mobile: false,
  width: 160,
};

export default memo(PodcastProfileHoverCard);
