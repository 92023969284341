import { css } from "aphrodite";
import React from "react";

import NavDropdownMenu, {
  defaultMenuStyles,
} from "./NavMenuDropdownItem/DropdownNavMenu";
import useToolNavLinks from "./useToolNavLinks";

import { useStyles } from "hooks/useStyles";

import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  toolsNavMenu: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "51rem",

    [ScreenSizes.lgAndAbove]: {
      flexDirection: "row",
    },
  },
};

const leftMenuStyles = {
  ...defaultMenuStyles,
  menuItem: {
    ...defaultMenuStyles.menuItem,
    paddingRight: "1.5rem",
  },
};

const PodcastersToolsNavMenu = (props) => {
  const { styles } = useStyles(baseStyles, props);
  const { podcasterLinks } = useToolNavLinks();

  return (
    <div className={css(styles.toolsNavMenu)}>
      <NavDropdownMenu
        {...props}
        menuStyles={leftMenuStyles}
        menuItems={podcasterLinks}
        asLink
        key="PodcastersToolsNavMenu"
      />
    </div>
  );
};

export default PodcastersToolsNavMenu;
