import PropTypes from "prop-types";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  container: {
    position: "relative",
    display: "grid",
    gridTemplateRows: "repeat(3, max-content)",
    gridGap: "0.75rem",
    lineHeight: "1.375rem",
  },
  outer: {
    background: colours.white,
    position: "fixed",
    padding: "1.5rem 1.5rem 4rem 1.5rem",
    border: `1px solid ${colours.borderGrey}`,
    borderRadius: "1.5rem",
    boxShadow: "0 0.313rem 1rem 0 rgba(0,0,0,0.07)",
    zIndex: 10,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    bottom: 0,
    left: 0,

    [ScreenSizes.mdAndAbove]: {
      position: "absolute",
      padding: "1.5rem",
      marginTop: -216,
      width: 292,
      marginLeft: -138,
      bottom: "auto",
      borderBottomLeftRadius: "1.5rem",
      borderBottomRightRadius: "1.5rem",
    },
  },
  triangle: {
    height: "20px",
    width: "20px",
    backgroundColor: colours.white,
    border: "inherit",
    clipPath: "polygon(0% 0%, 100% 100%, 0% 100%)",
    transform: "rotate(315deg)",
    borderRadius: "0 0 0 0.35em",
    position: "absolute",
    bottom: -30,
    margin: "0 auto",
    left: 0,
    right: 0,
    display: "none",

    [ScreenSizes.mdAndAbove]: {
      display: "block",
    },
  },
};

export default function ReachPopUp(props) {
  const { styles, css } = useStyles(baseStyles, props);
  const { children, passedRef } = props;

  return (
    <div className={css(styles.outer)} ref={passedRef}>
      <div className={css(styles.container)}>
        <div className={css(styles.triangle)} />
        {children}
      </div>
    </div>
  );
}

ReachPopUp.propTypes = {
  children: PropTypes.node,
};
