import Loadable from "@loadable/component";
import React from "react";
const RemoveBrandsFromWatchlistModal = Loadable(() =>
  import("./RemoveBrandsFromWatchlistModal")
);

function RemoveBrandsFromWatchlistModalAsyncLoading() {
  return null;
}

const RemoveBrandsFromWatchlistModalContainer = (props) => {
  return (
    <RemoveBrandsFromWatchlistModal
      {...props}
      fallback={<RemoveBrandsFromWatchlistModalAsyncLoading />}
    />
  );
};

export default RemoveBrandsFromWatchlistModalContainer;
