import { useEffect } from "react";

import { userHasProPermission } from "utils/entity/user";

import useLoggedInUser from "hooks/useLoggedInUser";
import useUserHasInternalPermission from "hooks/useUserHasInternalPermission";
import useUserHasSponsorPermission from "hooks/useUserHasSponsorPermission";

function UseJimoTracker() {
  const user = useLoggedInUser();
  const userIsPro = userHasProPermission(user);
  const IsPodchaserStaff = useUserHasInternalPermission();
  const hasSponsorPermission = useUserHasSponsorPermission();

  const userId = user?.get("id");
  const userName = user?.get("username");
  const userEmail = user?.get("email");
  const hasClaimedPodcast = user?.get("claimed_podcasts_count");

  useEffect(() => {
    const eventName = "JIMO_IDENTIFY_READY";

    const couldIdentifyJimo =
      window.requestContext.environment === "production" && userId;

    function identifyJimo() {
      window.jimo.push(["set", "user:id", [userId]]);
      window.jimo.push(["set", "user:email", [userEmail]]);
      window.jimo.push(["set", "user:name", [userName]]);
      window.jimo.push([
        "set",
        "user:attributes",
        [
          {
            hasClaimedPodcast: hasClaimedPodcast >= 1,
            isPro: !!userIsPro,
            hasSponsorPermission,
            IsPodchaserStaff,
          },
        ],
      ]);
    }

    if (couldIdentifyJimo) {
      if (window.jimo) {
        identifyJimo();
      } else {
        window.addEventListener(eventName, identifyJimo);
      }
    }

    return () => {
      window.removeEventListener(eventName, identifyJimo);
    };
  }, [
    IsPodchaserStaff,
    hasClaimedPodcast,
    hasSponsorPermission,
    userEmail,
    userId,
    userIsPro,
    userName,
  ]);

  return null;
}

export default UseJimoTracker;
