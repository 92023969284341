import mixpanel from "mixpanel-browser";

import { categories } from "constants/ga";
import { log } from "utils/dev";

const sendGAEvent = ({
  category = categories.USER_ACTION,
  action,
  ...props
}) => {
  // these should only ever fire on the client
  if (!window || typeof window === "undefined") {
    return;
  }

  log("GA", category, action, props);

  // check for development or testing environment and don't trigger
  if (window?.requestContext?.environment !== "production") {
    return;
  }

  if (window.ga) {
    window.ga("send", "event", {
      ...props,
      eventCategory: category,
      eventAction: action,
      site: "coreSite",
    });
  }

  if (window.mixpanel) {
    mixpanel.track(action, { ...props, site: "coreSite" });
  }

  if (window.Intercom) {
    window.Intercom("trackEvent", action, props);
  }
};

export default sendGAEvent;
