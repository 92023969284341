import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const SingleReviewView = Loadable(() => import("./SingleReviewView"));

function SingleReviewViewAsyncLoading(props) {
  return <LoadableLoading {...props} />;
}

const SingleReviewViewContainer = (props) => {
  return (
    <SingleReviewView {...props} fallback={<SingleReviewViewAsyncLoading />} />
  );
};

export default SingleReviewViewContainer;
