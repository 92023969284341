import PropTypes from "prop-types";
import { useContext, useMemo } from "react";
import BaseDotdotdot from "react-dotdotdot";

import RequestContext from "pages/RequestContext";

import useWindowSize from "hooks/useWindowSize";

const Dotdotdot = (props) => {
  const {
    useNativeClamp,
    children: passedChildren,
    dangerouslySet,
    serverTruncateSM,
    serverTruncateMD,
    serverTruncateLG,
    animate,
    clamp,
    onClick,
    tagName,
    ...rest
  } = props;

  const { isWindowSizeOrMore } = useWindowSize();
  const isMediumOrLarge = isWindowSizeOrMore("medium");
  const isLarge = isWindowSizeOrMore("large");

  const requestContext = useContext(RequestContext);
  const trimOnServer =
    requestContext.server && typeof passedChildren === "string";

  const serverTruncate = useMemo(() => {
    if (isLarge) {
      return serverTruncateLG;
    }

    if (isMediumOrLarge) {
      return serverTruncateMD;
    }

    return serverTruncateSM;
  }, [
    isLarge,
    isMediumOrLarge,
    serverTruncateSM,
    serverTruncateLG,
    serverTruncateMD,
  ]);

  const children = useMemo(() => {
    let chil = passedChildren;

    if (trimOnServer) {
      chil = chil.substring(0, serverTruncate);
    } else if (dangerouslySet) {
      return <span dangerouslySetInnerHTML={{ __html: chil }} />;
    }

    return chil;
  }, [passedChildren, dangerouslySet, trimOnServer, serverTruncate]);

  if (trimOnServer) {
    if (dangerouslySet) {
      return <span {...rest} dangerouslySetInnerHTML={{ __html: children }} />;
    }

    return <span {...rest}>{children}</span>;
  }

  const truncated = (
    <BaseDotdotdot
      {...(onClick ? {} : rest)}
      clamp={clamp}
      useNativeClamp={useNativeClamp}
      tagName={tagName}
      animate={animate}
    >
      {children}
    </BaseDotdotdot>
  );

  if (onClick) {
    const TagName = tagName || "span";

    return (
      <TagName {...rest} onClick={onClick}>
        {truncated}
      </TagName>
    );
  }

  return truncated;
};

Dotdotdot.propTypes = {
  useNativeClamp: PropTypes.bool,
  children: PropTypes.node,
  dangerouslySet: PropTypes.bool,
  animate: PropTypes.bool,
  serverTruncate: PropTypes.number,
  serverTruncateSM: PropTypes.number,
  serverTruncateMD: PropTypes.number,
  serverTruncateLG: PropTypes.number,
  clamp: PropTypes.number,
  onClick: PropTypes.func,
  tagName: PropTypes.string,
};

Dotdotdot.defaultProps = {
  useNativeClamp: true,
  children: null,
  dangerouslySet: false,
  animate: false,
  serverTruncateSM: 131,
  serverTruncateMD: 198,
  serverTruncateLG: 244,
  clamp: null,
  onClick: null,
  tagName: undefined,
};

export default Dotdotdot;
