import Loadable from "@loadable/component";

const ChunkLoadErrorModal = Loadable(() => import("./ChunkLoadErrorModal"));

function AboutAsyncLoading() {
  return null;
}

const ChunkLoadErrorModalContainer = (props) => {
  return <ChunkLoadErrorModal {...props} fallback={<AboutAsyncLoading />} />;
};

export default ChunkLoadErrorModalContainer;
