import FooterColumn from "./FooterColumn";

const RESOURCES = [
  { title: "Resources" },
  {
    title: "Help Center",
    to: "https://support.podchaser.com/",
    external: true,
  },
  {
    title: "Newsletter",
    to: "https://podchaser.beehiiv.com/",
    external: true,
  },
  { title: "Blog", to: "/articles" },
  { title: "API", to: "https://api-docs.podchaser.com/", external: true },
];

const ResourcesFooter = () => <FooterColumn links={RESOURCES} />;

export default ResourcesFooter;
