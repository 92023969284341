import { css } from "aphrodite";
import PropTypes from "prop-types";
import { Grid, Row } from "react-flexgrid";
import { useRouteMatch } from "react-router-dom";

import BackBar from "components/Common/BackBar";
import Col from "components/Common/Col";
import ListPageHelmet from "pages/Common/ListPageHelmetAsync";
import HomepageSidebar from "pages/Homepage/HomepageSidebar/HomepageSidebar";

import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import ListPageStyles from "styles/ListPageStyles";

const baseStyles = {
  ...ListPageStyles,
};

const TITLE = "My Unlocked Badges";
const DESCRIPTION = "View badges already unlocked";

const PageWrapper = (props) => {
  const { children } = props;
  const { styles } = useStyles(baseStyles, props);

  const match = useRouteMatch();

  const { isWindowSizeOrLess } = useWindowSize();
  const mobile = isWindowSizeOrLess("medium");

  return (
    <div className={css(styles.contentOuter)}>
      {mobile && <BackBar label="Account" url="/account" />}
      <ListPageHelmet title={TITLE} url={match.url} description={DESCRIPTION} />
      <Grid className={css(styles.contentContainer)}>
        <Row>
          <Col sm={12}>
            <div className={css(styles.columnContainer)}>
              <div className={css(styles.mainColumn)}>
                <div className={css(styles.mainColumnInner)}>
                  <div className={css(styles.header)}>
                    <div className={css(styles.titleContainer)}>
                      <h1 className={css(styles.title)}>{TITLE}</h1>
                    </div>
                  </div>
                  {children}
                </div>
              </div>
              {!mobile && <HomepageSidebar />}
            </div>
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

PageWrapper.propTypes = {
  children: PropTypes.node,
};

PageWrapper.defaultProps = {
  children: null,
};

export default PageWrapper;
