import { Map } from "immutable";
import PropTypes from "prop-types";
import { useCallback } from "react";

import ProfileHoverPopover from "components/Profiles/ProfileHoverPopover";

import CreatorProfileHoverCard from "./CreatorProfileHoverCard";

const CreatorProfileHoverPopover = (props) => {
  const { creator, children, mobile, inline, expand } = props;

  const renderContent = useCallback(
    (popoutProps) => (
      <CreatorProfileHoverCard
        creator={creator}
        mobile={mobile}
        popoutProps={popoutProps}
      />
    ),
    [creator, mobile]
  );

  return (
    <ProfileHoverPopover
      renderContent={renderContent}
      mobile={mobile}
      inline={inline}
      expand={expand}
      ariaLabel="Show Creator Profile Details"
    >
      {children}
    </ProfileHoverPopover>
  );
};

CreatorProfileHoverPopover.propTypes = {
  creator: PropTypes.instanceOf(Map),
  children: PropTypes.node.isRequired,
  mobile: PropTypes.bool,
  inline: PropTypes.bool,
  expand: PropTypes.bool,
};

CreatorProfileHoverPopover.defaultProps = {
  creator: null,
  mobile: false,
  inline: false,
  expand: false,
};

export default CreatorProfileHoverPopover;
