import * as sortConstants from "constants/sort";

export const getPaginationListKeys = (location, pageSize, options = {}) => {
  let page =
    location &&
    location.query &&
    location.query[options.pageQueryName || "page"];

  return {
    pageSize,
    offset: page ? (page - 1) * pageSize : 0,
    paginated: options.allPaginated || page > 1,
  };
};

const paramEntityTypeMap = {
  list: "userlist",
};

export const getEntityTypeFromParameter = (param) =>
  paramEntityTypeMap[param] || param;

export const getSort = (sort, options) => {
  if (sort === sortConstants.SORT_ORDER_VARIABLE_ATTRIBUTE) {
    if (options) {
      const count_attribute = options.get
        ? options.get("count_attribute")
        : options.count_attribute;

      if (count_attribute) {
        return `${
          sortConstants.SORT_ORDER_VARIABLE_ATTRIBUTE
        }_${count_attribute.toUpperCase()}_COUNT`;
      }
    }

    return sortConstants.SORT_ORDER_RELEVANCE;
  }

  return sort;
};

export const getApiSort = (sort) => {
  if (sort?.includes(sortConstants.SORT_ORDER_VARIABLE_ATTRIBUTE)) {
    return sortConstants.SORT_ORDER_VARIABLE_ATTRIBUTE;
  }

  return sort;
};

export const getApiOptions = (sort, options) => {
  if (
    sort &&
    sort.includes(sortConstants.SORT_ORDER_VARIABLE_ATTRIBUTE) &&
    sort !== sortConstants.SORT_ORDER_VARIABLE_ATTRIBUTE
  ) {
    const sortConfig = sortConstants.getSortConfig(sort);

    if (sortConfig && sortConfig.sortProps && sortConfig.sortProps.options) {
      return sortConfig.sortProps.options;
    }
  }

  return options;
};
