import configGet from "../../config";

export const WEBSITE_TITLE = "Podchaser";

export const WEBSITE_DESCRIPTION =
  "A podcast discovery and data platform. " +
  "Follow, rate and review podcasts, create lists, and follow podcast creators. " +
  "Find the best podcasts using categories, tags, and advanced search tools.";

export const SEARCH_PLACEHOLDER = "Search Podchaser...";
export const TOP_SEARCH_PLACEHOLDER = "Search podcasts, credits, & more...";
export const SEARCH_PLACEHOLDER_SHORT = "Search...";

// TODO: load this from environment?
export const googleClientID =
  "696566924934-6r39d2dfip7qk15ipcg5ehn3hafj1eu2.apps.googleusercontent.com";

export const facebookAppID = configGet("facebookAppID");
export const LOCALSTORAGE_TOKEN_KEY = "podchaser_token";

// TODO: can we get this from the environment?
export const getBaseUrl = () =>
  configGet("base_url") || "https://www.podchaser.com";
export const MISSING_IMAGE_URL = `${getBaseUrl()}/images/missing-image.png`;
export const MISSING_PROFILE_IMAGE_URL = `${getBaseUrl()}/images/creators/creator.png`;
