import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const SearchesView = Loadable(() => import("./SearchesView"));

function SearchesViewAsyncLoading(props) {
  return <LoadableLoading {...props} />;
}

const SearchesViewContainer = (props) => {
  return <SearchesView {...props} fallback={<SearchesViewAsyncLoading />} />;
};

export default SearchesViewContainer;
