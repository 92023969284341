import { useContext } from "react";
import Helmet from "react-helmet-async";

import RequestContext from "pages/RequestContext";

import useTesting from "../hooks/useTesting";

import {
  facebookAppID,
  getBaseUrl,
  googleClientID,
  WEBSITE_DESCRIPTION,
  WEBSITE_TITLE,
} from "constants/base";

const shareImageUrl = `${getBaseUrl()}/images/sharing/podchaser-sharing-general-tiles.jpg`;
const META = [
  { name: "charset", content: "utf-8" },
  // This is important to signify your application is mobile responsive!
  {
    name: "viewport",
    content:
      "width=device-width, initial-scale=1, maximum-scale=5, user-scalable=yes",
  },
  { name: "description", content: WEBSITE_DESCRIPTION },
  { name: "application-name", content: "Podchaser" },
  // ANDROID CONFIGS
  { name: "theme-color", content: "#491d87" },
  { name: "mobile-web-app-capable", content: "yes" },
  // IOS CONFIGS
  { name: "apple-mobile-web-app-title", content: "Podchaser" },
  { name: "apple-mobile-web-app-capable", content: "yes" },
  { name: "apple-mobile-web-app-status-bar-style", content: "default" },
  // Windows CONFIGS
  { name: "msapplication-navbutton-color", content: "#491d87" },
  { name: "msapplication-TileColor", content: "#491d87" },
  { name: "msapplication-TileImage", content: "mstile-144x144.png?v=2022" },
  { name: "msapplication-config", content: "browserconfig.xml" },
  // UC Mobile Browser CONFIGS
  { name: "full-screen", content: "yes" },
  { name: "browsermode", content: "application" },
  { name: "google-signin-client_id", content: googleClientID },
  {
    property: "article:publisher",
    content: "https://www.facebook.com/podchaser",
  },
  // Schema.org markup for Google+
  { itemprop: "name", content: "Podchaser" },
  { itemprop: "description", content: WEBSITE_DESCRIPTION },
  { itemprop: "image", content: shareImageUrl },
  // Open Graph
  { property: "og:site_name", content: "Podchaser" },
  { property: "og:type", content: "website" },
  { property: "og:url", content: "https://podchaser.com" },
  { property: "og:locale", content: "en_US" },
  { property: "og:image", content: shareImageUrl },
  { property: "og:image:width", content: "1200" },
  { property: "og:image:height", content: "630" },
  // Facebook
  { property: "fb:app_id", content: facebookAppID },
  { property: "fb:admins", content: "" },
  // Twitter
  { property: "twitter:site", content: "@podchaser" },
  { property: "twitter:creator", content: "@podchaser" },
  { property: "twitter:card", content: "summary" },
  { property: "twitter:description", content: WEBSITE_DESCRIPTION },
  { property: "twitter:title", content: "Podcasts on Podchaser" },
  { property: "twitter:image", content: shareImageUrl },
  { property: "twitter:image:src", content: shareImageUrl },
];

// Note: Generate some of this through https://realfavicongenerator.net/

const LINKS = [
  {
    rel: "apple-touch-icon",
    sizes: "60x60",
    href: "/apple-touch-icon-60x60.png?v=2022",
  },
  {
    rel: "apple-touch-icon",
    sizes: "76x76",
    href: "/apple-touch-icon-76x76.png?v=2022",
  },
  {
    rel: "apple-touch-icon",
    sizes: "120x120",
    href: "/apple-touch-icon-120x120.png?v=2022",
  },
  {
    rel: "apple-touch-icon",
    sizes: "152x152",
    href: "/apple-touch-icon-152x152.png?v=2022",
  },
  {
    rel: "apple-touch-icon",
    sizes: "180x180",
    href: "/apple-touch-icon-180x180.png?v=2022",
  },

  {
    rel: "icon",
    type: "image/png",
    href: "/android-chrome-192x192.png?v=2022",
  },
  {
    rel: "icon",
    type: "image/png",
    href: "/favicon.svg?v=2022",
  },
  {
    rel: "icon",
    type: "image/png",
    href: "/favicon-32x32.png?v=2022",
  },
  {
    rel: "icon",
    type: "image/png",
    sizes: "32x32",
    href: "/favicon-32x32.png?v=2022",
  },
  {
    rel: "icon",
    type: "image/png",
    sizes: "192x192",
    href: "/android-chrome-192x192.png?v=2022",
  },
  {
    rel: "icon",
    type: "image/png",
    sizes: "16x16",
    href: "/favicon-16x16.png?v=2022",
  },

  {
    rel: "manifest",
    href: "/site.webmanifest?v=2022",
  },

  {
    rel: "shortcut icon",
    href: "/favicon.ico?v=2022",
  },

  {
    rel: "apple-touch-startup-image",
    href: "/android-chrome-512x512.png",
  },

  // old
  { rel: "manifest", href: "/manifest.json" },
  {
    rel: "dns-prefetch",
    href: "https://alb.reddit.com",
  },
  {
    rel: "dns-prefetch",
    href: "https://accounts.google.com/gsi/client",
  },
  {
    rel: "dns-prefetch",
    href: "https://faye.getstream.io",
  },
  {
    rel: "dns-prefetch",
    href: "https://stats.pusher.com",
  },
  {
    rel: "dns-prefetch",
    href: "https://accounts.google.com",
  },
  {
    rel: "dns-prefetch",
    href: "https://widget.intercom.io",
  },
  {
    rel: "dns-prefetch",
    href: "https://cachedimages.podchaser.com/",
  },
  {
    rel: "dns-prefetch",
    href: "https://cdn.polyfill.io/",
  },
  {
    rel: "preconnect",
    href: "https://www.google-analytics.com",
  },
  {
    rel: "preconnect",
    href: "https://connect.facebook.net",
  },
  {
    rel: "preconnect",
    href: "https://cachedimages.podchaser.com/",
  },
  {
    rel: "dns-prefetch",
    href: "https://cdn.polyfill.io/",
  },
  {
    rel: "search",
    type: "application/opensearchdescription+xml",
    title: "Podchaser", // Must match /opensearch.xml <ShortName>
    href: "/opensearch.xml",
  },
];

const SCRIPT = [
  {
    type: "application/ld+json",
    innerHTML: JSON.stringify({
      "@context": "http://schema.org",
      "@type": "Organization",
      url: "https://www.podchaser.com",
      logo: "https://www.podchaser.com/images/podchaser/logos/Podchaser - Logo - Icon - Large.png",
      sameAs: [
        "https://www.facebook.com/podchaser/",
        "https://www.twitter.com/podchaser/",
      ],
    }),
  },
];

const HTML_ATTRIBUTES = { lang: "en" };

const AppDocumentHead = () => {
  const { isCrawler } = useContext(RequestContext);

  const tryingDarkMode = useTesting("darkMode");
  const tryingDebugMode = useTesting("debugMode");

  const theme = tryingDarkMode ? "dark" : tryingDebugMode ? "debug" : "light";

  const scriptTags = isCrawler
    ? SCRIPT.filter((s) => s.type === "application/ld+json")
    : SCRIPT;

  return (
    <Helmet
      htmlAttributes={{
        ...HTML_ATTRIBUTES,
        "data-theme": theme,
      }}
      titleTemplate={`%s | ${WEBSITE_TITLE}`}
      defaultTitle={WEBSITE_TITLE}
      meta={META}
      link={LINKS}
      script={scriptTags}
    />
  );
};

export default AppDocumentHead;
