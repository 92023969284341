import PropTypes from "prop-types";
import { useContext, useMemo } from "react";

import { HeightManagerContext } from "components/Common/HeightManager";
import LazyHydrate from "components/Common/LazyHydrate";
import RequestContext from "pages/RequestContext";

const defaultOptions = {
  offset: 500,
  once: true,
  whenVisible: true,
};

const LazyLoadComponent = (props) => {
  const { id, children, renderOnMount, ...rest } = props;
  const heightContext = useContext(HeightManagerContext);
  const requestContext = useContext(RequestContext);

  const managedHeight = heightContext && heightContext.getHeight(id);

  const compProps = useMemo(
    () => ({
      id,
      height: managedHeight || rest.height,
      ...defaultOptions,
      ...rest,
    }),
    [rest, managedHeight, id]
  );

  if (renderOnMount) {
    return children;
  }

  if (requestContext.isTestingEnv) {
    return children;
  }

  return <LazyHydrate {...compProps}>{children}</LazyHydrate>;
};

LazyLoadComponent.propTypes = {
  children: PropTypes.node,
  renderOnMount: PropTypes.bool,
  id: PropTypes.string,
};

LazyLoadComponent.defaultProps = {
  children: null,
  renderOnMount: false,
  id: null,
};

export default LazyLoadComponent;
