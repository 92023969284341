import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const VanityURL = Loadable(() => import("./VanityURL"));

function VanityURLLoading(props) {
  return <LoadableLoading {...props} />;
}

const VanityURLContainer = (props) => {
  return <VanityURL {...props} fallback={<VanityURLLoading />} />;
};

export default VanityURLContainer;
