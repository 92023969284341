import Loadable from "@loadable/component";
const OnboardingModal = Loadable(() => import("./OnboardingModal"));

function AboutAsyncLoading() {
  return null;
}

const OnboardingModalContainer = (props) => {
  return <OnboardingModal {...props} fallback={<AboutAsyncLoading />} />;
};

export default OnboardingModalContainer;
