import { css, StyleSheet } from "aphrodite";
import merge from "ramda/src/mergeDeepRight";
import { useMemo } from "react";

import castArray from "utils/arrays/castArray";

import useStyleFlush from "hooks/useStyleFlush";

export const useMergedStyles = (styles, props = { styles: {} }) => {
  const [mergedStyles, doStyleFlush] = useMemo(() => {
    const mergedStyles = [
      ...castArray(styles).filter((f) => !!f),
      ...castArray(props?.styles).filter((f) => !!f),
    ].reduce((agg, next) => merge(agg, next), {});

    // Deprecated: Doesn't seem to be used. Changed to false in case there's some obscure code using it somewhere
    const hasTransition = false; /*Object.values(mergedStyles).some((obj) =>
      Object.keys(obj).includes("transition")
    );*/

    // Uncomment to show warnings for deprecated colours
    /* Object.values(mergedStyles).forEach(obj => {
      if ( obj.color && obj.color.substring(0,1) === "#" ) {
        console.warn("Using deprecated hex color", obj.color);
      }
      if ( obj.background && obj.background.substring(0,1) === "#" ) {
        console.warn("Using deprecated hex color", obj.background);
      }
      if ( obj.backgroundColor && obj.backgroundColor.substring(0,1) === "#" ) {
        console.warn("Using deprecated hex color", obj.backgroundColor);
      }
    }) */

    return [mergedStyles, hasTransition];
  }, [props, styles]);

  return {
    mergedStyles,
    doStyleFlush,
  };
};

export const useStyles = (styles, props = { styles: {} }) => {
  const { mergedStyles, doStyleFlush } = useMergedStyles(styles, props);

  const calcStyles = useMemo(
    () => StyleSheet.create(mergedStyles),
    [mergedStyles]
  );

  useStyleFlush(doStyleFlush);

  return { styles: calcStyles, css };
};

export const useForwardStyles = (baseStyles, { styles: passedStyles }) =>
  useMemo(() => {
    if (passedStyles) {
      return [baseStyles, passedStyles];
    }

    return baseStyles;
  }, [baseStyles, passedStyles]);
