import { faCheck } from "@fortawesome/pro-solid-svg-icons/faCheck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback } from "react";

import StandardButton from "components/Buttons/StandardButton";
import ContentContainer from "components/Common/Layout/ContentContainer";

import modalActions from "actions/modals";
import ProGraphBackground from "images/pro/illustrations-pro-computer.png";
import ProGraphBackground2x from "images/pro/illustrations-pro-computer2x.png";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  contentContainer: {
    background:
      "linear-gradient(90deg, rgb(43, 0, 143) 0%, rgb(167, 0, 176) 100%)",
    [ScreenSizes.mdAndAbove]: {
      paddingLeft: "1.5rem",
    },
  },
  contentWrapper: {
    paddingBottom: "2rem",
    color: colours.white,
    display: "flex",
    alignItems: "center",
    flexDirection: "column-reverse",
    gap: "1.5rem",
    marginTop: "2.5rem",
    justifyContent: "space-between",
    minHeight: "30rem",

    [ScreenSizes.mdAndAbove]: {
      marginTop: 0,
      minHeight: "300px",
    },
    [ScreenSizes.mdOnly]: {
      gap: 0,
      marginTop: "2rem",
    },
    [ScreenSizes.lgAndAbove]: {
      flexDirection: "row",
      paddingTop: "2.4rem",
      gap: "0",
    },
  },
  infoWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    maxWidth: "100%",
    marginLeft: "6vw",
    [ScreenSizes.mdAndAbove]: {
      marginLeft: "0",
    },
    [ScreenSizes.mdOnly]: {
      marginTop: "-2rem",
    },
  },
  title: {
    ...gStyles.avalonBold,
    maxWidth: "25rem",
    marginTop: "0.625rem",
    [ScreenSizes.smAndAbove]: {
      fontSize: "2rem",
    },
    [ScreenSizes.mdOnly]: {
      maxWidth: "unset",
    },
    [ScreenSizes.lgAndAbove]: {
      minWidth: "19rem",
    },
  },
  list: {
    display: "flex",
    fontWeight: "bolder",
    fontSize: "0.875rem",
    gap: "1.5rem",
    [ScreenSizes.mdAndAbove]: {
      gap: "2rem",
    },
    [ScreenSizes.mdOnly]: {
      alignSelf: "center",
      width: "100%",
      justifyContent: "space-evenly",
    },
  },
  listItems: {
    display: "flex",
    gap: ".5rem",
    alignItems: "center",
    marginBottom: ".8rem",
    ":nth-child(3)": {
      marginBottom: 0,
    },
    ":nth-child(6)": {
      marginBottom: 0,
    },
  },
  image: {
    display: "block",
    color: "#fff",
    width: "100%",
    minHeight: "10rem",
    maxWidth: "37.125rem",
    marginBottom: "3rem",
    [ScreenSizes.lgAndAbove]: {
      marginBottom: 0,
    },
  },
};

const buttonStyles = {
  button: {
    ...gStyles.avalonBold,
    borderRadius: 100,
    maxWidth: 150,
    fontSize: "0.9rem",
    marginLeft: "0 !important",
    marginTop: "2rem",
    [ScreenSizes.mdOnly]: {
      marginRight: "unset",
      alignSelf: "center",
    },
  },
};

const UnlockProFooterBanner = () => {
  const { styles, css } = useStyles(baseStyles);
  const { showModal } = useActionCreators({
    showModal: modalActions.showModal,
  });

  const onDemoRequestSubmitted = useCallback(() => {
    sendGAEvent({
      action: "submittedProLeadFormModal",
      context: "FooterBannerCTA",
    });
  }, []);

  const handleRequestPro = useCallback(() => {
    sendGAEvent({
      action: "openedProLeadFormModal",
      context: "FooterBannerCTA",
    });
    showModal("proLeadForm", {
      onDemoRequestSubmitted,
    });
  }, [showModal, onDemoRequestSubmitted]);

  return (
    <ContentContainer style={styles.contentContainer} noVerticalPadding>
      <div className={css(styles.contentWrapper)}>
        <div className={css(styles.infoWrapper)}>
          <h2 className={css(styles.title)}>Unlock more with Podchaser Pro</h2>

          <ul className={css(styles.list)}>
            <div>
              <li className={css(styles.listItems)}>
                <FontAwesomeIcon icon={faCheck} />
                Audience Insights
              </li>
              <li className={css(styles.listItems)}>
                <FontAwesomeIcon icon={faCheck} />
                Contact Information
              </li>

              <li className={css(styles.listItems)}>
                <FontAwesomeIcon icon={faCheck} />
                Demographics
              </li>
            </div>

            <div>
              <li className={css(styles.listItems)}>
                <FontAwesomeIcon icon={faCheck} />
                Charts
              </li>
              <li className={css(styles.listItems)}>
                <FontAwesomeIcon icon={faCheck} />
                Sponsor History
              </li>
              <li className={css(styles.listItems)}>
                <FontAwesomeIcon icon={faCheck} />
                and More!
              </li>
            </div>
          </ul>

          <StandardButton
            label="Request Demo"
            onClick={handleRequestPro}
            variation={"pink"}
            customStyles={buttonStyles}
          />
        </div>

        <picture>
          <source
            media="(min-width: 800px)"
            srcSet={`${ProGraphBackground} 1x, ${ProGraphBackground2x} 2x`}
          />
          <img
            src={ProGraphBackground}
            loading="lazy"
            className={css(styles.image)}
            alt="Pro Features"
            height={160}
          />
        </picture>
      </div>
    </ContentContainer>
  );
};

export default UnlockProFooterBanner;
