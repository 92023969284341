import { useMemo, useRef } from "react";

import { log } from "utils/dev";

function useLoggedMemo(func, deps, id = "useLoggedMemo") {
  const lastDeps = useRef(deps);
  const value = useMemo(func, deps);

  if (process.env.NODE_ENV === "development") {
    return value;
  }

  const changed = deps.map((dep, i) => dep !== lastDeps.current[i]);

  if (changed.some((i) => !!i)) {
    log(
      "useMemo has changed deps",
      id,
      { value },
      changed
        .map((c, i) =>
          c
            ? {
                index: i,
                prev: lastDeps.current[i],
                next: deps[i],
              }
            : null
        )
        .filter((i) => i !== null)
    );
    lastDeps.current = deps;
  }

  return value;
}

export default useLoggedMemo;
