import Loadable from "@loadable/component";
import React from "react";

import LoadableLoading from "components/Common/LoadableLoading";

const AlertsOverview = Loadable(() => import("./AlertsOverview"));

function AlertsOverviewAsyncLoading(props) {
  return <LoadableLoading {...props} page />;
}

const AlertsOverviewContainer = (props) => {
  return (
    <AlertsOverview {...props} fallback={<AlertsOverviewAsyncLoading />} />
  );
};

export default AlertsOverviewContainer;
