import Loadable from "@loadable/component";
const RecurringRoleQuestionModal = Loadable(() =>
  import("./RecurringRoleQuestionModal")
);

function RecurringRoleQuestionModalAsyncLoading() {
  return null;
}

const RecurringRoleQuestionModalContainer = (props) => {
  return (
    <RecurringRoleQuestionModal
      {...props}
      fallback={<RecurringRoleQuestionModalAsyncLoading />}
    />
  );
};

export default RecurringRoleQuestionModalContainer;
