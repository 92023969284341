import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const ChartsContainer = Loadable(() => import("./ChartsContainer"));

function ChartsAsyncLoading(props) {
  return (
    <LoadableLoading
      key="topLevelRouteLoadingOverlay"
      withPageHeader
      height={"136rem"}
    />
  );
}

const ChartsContainerWrapper = (props) => {
  return <ChartsContainer {...props} fallback={<ChartsAsyncLoading />} />;
};

export default ChartsContainerWrapper;
