import getUserListLinkID from "./getUserListLinkID";

import { formatURL } from "utils/format";
import slugify from "utils/misc/slugify";

export default (list) => {
  if (list && list.get("title")) {
    const listUrl = `/lists/${slugify(
      list.get("title").substr(0, 60)
    )}-${getUserListLinkID(list)}`;

    return formatURL(listUrl);
  }

  return null;
};
