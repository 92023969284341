import { css } from "aphrodite";
import { memo } from "react";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  Container: {
    ...gStyles.skeletonPlaceholder,
    width: "auto",
    height: "2.375rem",
    minHeight: "2.375rem",
    position: "relative",
    display: "flex",
    flex: 1,
    marginTop: "1rem",
    marginRight: "1rem",

    [ScreenSizes.mdAndAbove]: {
      minWidth: "8rem",
    },

    [ScreenSizes.lgAndAbove]: {
      width: "11.625rem",
    },
  },
};

const AsyncLoadingListSort = (props) => {
  const { styles } = useStyles(baseStyles, props);

  return <div className={css(styles.Container)} />;
};

export default memo(AsyncLoadingListSort);
