import { Map } from "immutable";
import { useMemo } from "react";

import {
  selectSpecificPodcast,
  selectSpecificEpisode,
} from "selectors/podcast";
import { selectSpecificReview } from "selectors/reviews";

import useReduxState from "hooks/useReduxState";

export default (review_id) => {
  const review = useReduxState(
    (state) => selectSpecificReview(state, review_id),
    [review_id]
  );
  const entity_type = review && review.get("entity_type");
  const entity_id = review && review.get("entity_id");

  const entity = useReduxState(
    (state) => {
      if (entity_type === "episode") {
        return selectSpecificEpisode(state, entity_id);
      }

      return selectSpecificPodcast(state, entity_id);
    },
    [entity_type, entity_id]
  );

  const podcast = useReduxState(
    (state) =>
      selectSpecificPodcast(
        state,
        entity_type === "episode" && entity && entity.get("podcast_id")
      ),
    [entity_type, entity]
  );

  const reviewWithSubEntities = useMemo(() => {
    if (review && entity) {
      if (entity_type === "episode" && podcast) {
        const reviewWithEntity = review
          .set("entity", entity)
          .setIn(["entity", "podcast"], podcast);

        return reviewWithEntity;
      }

      const reviewWithEntity = review.set("entity", entity);

      return reviewWithEntity;
    } else if (review) {
      return review.set("entity", new Map({}));
    }

    return null;
  }, [entity_type, entity, podcast, review]);

  return reviewWithSubEntities;
};
