import PropTypes from "prop-types";
import { useCallback } from "react";

import ProfileHoverPopover from "components/Profiles/ProfileHoverPopover";

import PodcastProfileHoverCard from "./PodcastProfileHoverCard";

const PodcastProfileHoverPopover = (props) => {
  const { children, mobile, inline, expand, entity_id } = props;

  const renderContent = useCallback(
    (popoutProps) => (
      <PodcastProfileHoverCard
        podcast_id={entity_id}
        mobile={mobile}
        popoutProps={popoutProps}
      />
    ),
    [entity_id, mobile]
  );

  return (
    <ProfileHoverPopover
      renderContent={renderContent}
      mobile={mobile}
      inline={inline}
      expand={expand}
      entity_id={entity_id}
      ariaLabel="Show Podcast Details"
    >
      {children}
    </ProfileHoverPopover>
  );
};

PodcastProfileHoverPopover.propTypes = {
  entity_id: PropTypes.number,
  children: PropTypes.node.isRequired,
  mobile: PropTypes.bool,
  inline: PropTypes.bool,
  expand: PropTypes.bool,
};

PodcastProfileHoverPopover.defaultProps = {
  entity_id: null,
  mobile: false,
  inline: false,
  expand: false,
};

export default PodcastProfileHoverPopover;
