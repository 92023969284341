import { Map } from "immutable";

import getEncodedEntityID from "./getEncodedEntityID";
import truncateUrlSlug from "./truncateUrlSlug";

import slugify from "utils/misc/slugify";

const getCreatorSlug = (creator) => {
  if (!creator || !Map.isMap(creator)) {
    return null;
  }

  let encodedID =
    creator.has("id") && creator.get("id")
      ? getEncodedEntityID(creator.get("id"))
      : 0;

  if (creator.has("identifier")) {
    encodedID = creator.get("identifier");
  }

  return (
    creator &&
    (creator.get("name") || encodedID) &&
    `${truncateUrlSlug(
      (slugify(creator.get("name")) || "").toLowerCase()
    )}-${encodedID}`
  );
};

export const getCreatorSlugByRawData = (
  creatorName,
  creatorId,
  creatorIdentifier
) => {
  let encodedID = creatorId ? getEncodedEntityID(creatorId) : 0;

  if (creatorIdentifier) {
    encodedID = creatorIdentifier;
  }

  return (
    creatorName &&
    `${truncateUrlSlug(
      (slugify(creatorName) || "").toLowerCase()
    )}-${encodedID}`
  );
};

export default getCreatorSlug;
