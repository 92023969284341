import { fromJS } from "immutable";
import { call, put } from "redux-saga/effects";

import * as paginationConstants from "constants/pagination";
import { SORT_ORDER_RANKING } from "constants/sort";

function* createAllCreatorsList(episode, pagination, result = null) {
  const key = `episode/${episode.get("id")}/creators`;

  // initialise the list and fire off a success with just our current values in there
  yield put({
    type: paginationConstants.PAGINATION_INIT_LIST_CREATE,
    key,
    list_type: "creators_filtered",
    entity_type: "creator",
    staticFilters: {
      episode_id: { value: episode.get("id") },
      confidence: { value: "all" },
    },
    sort: SORT_ORDER_RANKING,
    pageSize: 25,
    additional: result.getIn(["response", "additional"]),
    total: episode.get("all_creators").size,
    hasMore: false,
  });

  yield put({
    type: paginationConstants.PAGINATION_UPDATE_LIST,
    key,
    idsBySort: { [SORT_ORDER_RANKING]: episode.get("all_creators") },
  });
}

export default function* onEpisodeListLoad(passedData) {
  const data = fromJS(passedData);

  if (!data.hasIn(["response", "entities", "episodes"])) {
    return;
  }

  const episodesList = data
    .getIn(["response", "entities", "episodes"])
    .toList();

  // for each leaf node in the response, create a new list
  for (let i = 0; i < episodesList.size; i++) {
    if (episodesList.get(i).has("all_creators")) {
      yield call(
        createAllCreatorsList,
        episodesList.get(i),
        data.get("pagination"),
        data
      );
    }
  }
}
