import Loadable from "@loadable/component";
const EpisodeSelectionMenuModal = Loadable(() =>
  import("./EpisodeSelectionMenuModal")
);

function EpisodeSelectionMenuModalAsyncLoading() {
  return null;
}

const EpisodeSelectionMenuModalContainer = (props) => {
  return (
    <EpisodeSelectionMenuModal
      {...props}
      fallback={<EpisodeSelectionMenuModalAsyncLoading />}
    />
  );
};

export default EpisodeSelectionMenuModalContainer;
