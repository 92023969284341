import Loadable from "@loadable/component";

import TimelineSkeletonItem from "components/Timeline/TimelineSkeletonItem";

const IntegrationVoteActivity = Loadable(() =>
  import(
    /* webpackChunkName: "IntegrationVoteActivity" */ "./IntegrationVoteActivity"
  )
);

function IntegrationVoteActivityLoading(props) {
  return <TimelineSkeletonItem />;
}

const IntegrationVoteActivityContainer = (props) => {
  return (
    <IntegrationVoteActivity
      {...props}
      fallback={<IntegrationVoteActivityLoading />}
    />
  );
};

export default IntegrationVoteActivityContainer;
