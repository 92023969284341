import { useCallback, useState } from "react";

import SelectSearchDropDown from "components/Common/FilterSelects/SelectSearchDropDown";
import SingleSelectionList from "components/Common/FilterSelects/SingleSelectionList";

export default function SearchSingleSelectFilter(props) {
  const {
    filter,
    selected,
    onChange,
    label,
    topLabel,
    ariaLabel,
    defaultSelectedId,
    useInteracted,
  } = props;

  const [interacted, setInteracted] = useState(false);

  const handleClick = useCallback(
    (value, closer) => {
      if (useInteracted) {
        setInteracted(true);
      }
      onChange && onChange(value);
      closer && closer();
    },
    [onChange, useInteracted]
  );

  return (
    <SelectSearchDropDown
      selected={selected}
      filter={filter}
      topLabel={topLabel}
      ariaLabel={ariaLabel}
      useInteracted={useInteracted}
      interacted={interacted}
      styles={props?.styles}
      label={label}
      RenderList={SingleSelectionList}
      defaultSelectedId={defaultSelectedId}
      handleClick={handleClick}
    />
  );
}
