import { Map } from "immutable";
import PropTypes from "prop-types";
import { Fragment, useCallback, useMemo } from "react";

import { useEntityFromTimeline } from "../useEntityFromTimeline";
import Activity from "./Activity/ActivityAsync";

const ReleaseActivity = (props) => {
  const { activity, mobile } = props;

  const { entity: podcast } = useEntityFromTimeline(activity.get("actor"));
  const { entity: episode } = useEntityFromTimeline(activity.get("object"));

  const renderIntroText = useCallback(
    ({ renderEntityLink }) => (
      <Fragment>
        {renderEntityLink("podcast", podcast)} released a new episode
      </Fragment>
    ),
    [podcast]
  );

  const renderIntroImage = useCallback(
    ({ renderEntityImage }) => renderEntityImage("podcast", podcast),
    [podcast]
  );

  const sections = useMemo(
    () => [
      {
        type: "intro",
        renderImage: renderIntroImage,
        renderText: renderIntroText,
        highlightType: "Release",
      },
      {
        type: "entity",
        entityCardProps: {
          entity_type: "episode",
          entity: episode,
          showCreators: mobile,
          hideIcons: ["aired"],
          mobile,
        },
      },
    ],
    [renderIntroImage, renderIntroText, mobile, episode]
  );

  if (!podcast || !episode) {
    return null;
  }

  return (
    <Activity {...props} sections={sections} loaded={!!(podcast && episode)} />
  );
};

ReleaseActivity.propTypes = {
  activity: PropTypes.instanceOf(Map),
  mobile: PropTypes.bool,
};

ReleaseActivity.defaultProps = {
  activity: Map(),
  mobile: false,
};

export default ReleaseActivity;
