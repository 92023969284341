import { useEffect, useState } from "react";

const useCookieConsent = (consentGroup) => {
  const [isConsentGiven, setIsConsentGiven] = useState(false);

  useEffect(() => {
    const handleConsentUpdate = () => {
      if (window?.OnetrustActiveGroups) {
        const consentGranted =
          window?.OnetrustActiveGroups.includes(consentGroup);
        setIsConsentGiven(consentGranted);
      }
    };

    handleConsentUpdate();

    if (typeof window !== "undefined") {
      window.addEventListener("OneTrustGroupsUpdated", handleConsentUpdate);
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener(
          "OneTrustGroupsUpdated",
          handleConsentUpdate
        );
      }
    };
  }, [consentGroup]);

  return isConsentGiven;
};

export default useCookieConsent;
