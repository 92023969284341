import Loadable from "@loadable/component";
const ProfileImageInputModal = Loadable(() =>
  import("./ProfileImageInputModal")
);

function ProfileImageInputModalAsyncLoading() {
  return null;
}

const ProfileImageInputModalContainer = (props) => {
  return (
    <ProfileImageInputModal
      {...props}
      fallback={<ProfileImageInputModalAsyncLoading />}
    />
  );
};

export default ProfileImageInputModalContainer;
