import { camelCase, pascalCase, capitalCase, sentenceCase } from "change-case";

// put some safety around some 'change-case' functions

export const toCamelCase = (str) => camelCase(str || "");

export const toPascalCase = (str) => pascalCase(str || "");

export const capitalizeWords = (str) => capitalCase(str || "");

export const toSentenceCase = (str) => sentenceCase(str || "");
