import { Map } from "immutable";

export const selectSearchHasInput = (state, searchKey) =>
  state.search.getIn([
    "searchKeys",
    searchKey?.toString(),
    "search_has_input",
  ]) || false;
export const selectSearchLoading = (state, searchKey) =>
  state?.search?.getIn(["searchKeys", searchKey?.toString(), "searching"]);
export const selectSearchTerm = (state, searchKey = "") =>
  state.search.getIn(["searchKeys", searchKey?.toString(), "search_term"]);
export const selectSearchResults = (state, searchKey) =>
  state?.search?.getIn(["searchKeys", searchKey?.toString(), "results"]);
export const selectSearchLoaded = (state, searchKey) =>
  state?.search?.getIn(["searchKeys", searchKey?.toString(), "loaded"]);
export const selectSearch = (state, searchKey) =>
  state.search.getIn(["searchKeys", searchKey?.toString()], Map());

export const selectSearchLinks = (state, key) =>
  state.search.getIn(["links", key, "links"]);
export const selectSearchLinksLoading = (state, key) =>
  state.search.getIn(["links", key, "loading"]);
export const selectSelectedItem = (state, searchKey) =>
  state.search.getIn(["searchKeys", searchKey?.toString(), "selected_item"]);

export const selectSearchSaving = (state, searchId) =>
  state.saveSearches?.getIn(["savedSearches", "saving"])?.includes(searchId);
export const selectSearchError = (state, searchId) =>
  state.saveSearches?.getIn(["savedSearches", "error"])?.includes(searchId);
export const selectSavedSearchesLoading = (state) =>
  state.saveSearches?.getIn(["savedSearches", "loading"]);
export const selectSavedSearchesLoaded = (state) =>
  state.saveSearches?.getIn(["savedSearches", "loaded"]);
export const selectSavedSearchesError = (state) =>
  state.saveSearches?.getIn(["savedSearches", "error"]);
export const selectSavedSearches = (state) =>
  state.saveSearches?.getIn(["savedSearches", "entities"])?.toList();

export const selectRecentSearchHistory = (state) => {
  return state.search?.getIn(["recentSearchHistory"]);
};
