import colours from "../../styles/colours";
import gStyles from "../../styles/GenericStyles";
import ScreenSizes from "../../styles/ScreenSizes";

export const oAuthStyles = {
  oAuthPrompt: {
    ...gStyles.gradientBackground,
    ...gStyles.fontRegular,
    padding: "2rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100vw",
    height: "100vh",
    boxSizing: "border-box",
  },
  inner: {
    background: "#fff",
    color: "#333",
    maxWidth: 500,
    borderRadius: 20,
    width: "100%",
    padding: "1.5rem",
    maxHeight: "100%",
  },
  intro: {
    ...gStyles.fontRegular,
    fontSize: "1rem",
    margin: "0 0 1rem",
    padding: "0.75rem 0",
    textAlign: "center",
    lineHeight: 1.5,
    [ScreenSizes.mdAndAbove]: {
      fontSize: "1.25rem",
    },
  },
  errorMessage: {
    ...gStyles.avalonBold,
    background: "#ffefef",
    color: colours.negative,
    textAlign: "center",
    display: "block",
    padding: "0.75em 1em",
  },
};
