import Loadable from "@loadable/component";

import AsyncLoadingEntityCard from "../AsyncLoadingEntityCard";

const CreatorCardContainer = Loadable(() => import("./CreatorCardContainer"));

function ContainerAsyncLoading(props) {
  return <AsyncLoadingEntityCard {...props} />;
}

const CreatorCardContainerContainer = (props) => {
  return (
    <CreatorCardContainer {...props} fallback={<ContainerAsyncLoading />} />
  );
};

export default CreatorCardContainerContainer;
