/* eslint-disable max-len */

export default {
  prefix: "fpc",
  iconName: "pc-episode",
  icon: [
    512,
    512,
    [],
    null,
    "M256,512 C114.615104,512 0,397.384896 0,256 C0,114.615104 114.615104,0 256,0 C397.384896,0 512,114.615104 512,256 C512,397.384896 397.384896,512 256,512 Z M447.908514,299.714303 C455.829401,261.974081 478.857946,200.660776 453.771815,193.615395 C428.685683,186.570015 415.968826,263.68061 415.649654,264.617126 C409.20757,284.075301 401.429688,364.179688 382.854003,364.179688 C364.278318,364.179688 362.226724,302.357348 355.088904,253.544482 C347.951084,204.731616 337.82449,123 292.79248,123 C247.760469,123 236.007812,207.035156 224.925585,237.95144 C213.843358,268.867724 200.528922,297.42105 183.759708,297.42105 C166.990495,297.42105 160.667372,261 150.927826,237.95144 C141.188281,214.90288 123.587445,174.005864 93.8426613,174.005864 C64.0978772,174.005864 16.3211778,247.156701 42.5733988,261 C68.8256198,274.843299 73.1348024,219.143405 93.8426613,219.143405 C114.55052,219.143405 118.377142,258.787638 129.816634,284.862556 C141.256125,310.937473 152.328288,347.996548 183.759708,347.996548 C215.191128,347.996548 236.460938,317.480469 249.83295,284.862556 C263.204962,252.244642 276.306641,178.768104 292.79248,178.768104 C309.278319,178.768104 314.664008,270.380512 320.557578,299.714303 C327.161318,332.582785 337.82449,418.081292 382.854003,414.912306 C427.883515,411.74332 439.987628,337.454524 447.908514,299.714303 Z",
  ],
};
