import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import scrollToComponent from "react-scroll-to-component-ssr";

import useLayoutEffect from "hooks/useLayoutEffect";

const defaultOptions = { duration: 250, align: "top" };

export function useSmoothScroll(passedOptions = {}) {
  const options = { ...defaultOptions, ...passedOptions };

  const scrollTo = useCallback(
    (ref) => {
      scrollToComponent(ref, options);
    },
    [options]
  );

  const scrollToTop = useCallback(
    (component) => {
      if (component) {
        scrollToComponent(component, options);
      } else if (typeof window !== "undefined") {
        scrollToComponent(window.document.body, options);
      }
    },
    [options]
  );

  return { scrollToTop, scrollTo };
}

export const useScrollHashIntoView = ({
  hashId,
  elementRef,
  isDisabled = false,
  offset = -16,
}) => {
  const location = useLocation();
  const { hash } = location;

  const { scrollToTop } = useSmoothScroll({
    duration: 1000,
    align: "top",
    offset,
  });
  const isTargeted = hash && hash === `#${hashId}`;

  useLayoutEffect(() => {
    if (isTargeted && !isDisabled) {
      scrollToTop(elementRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTargeted]);

  return !!isTargeted;
};
