import { css } from "aphrodite";
import PropTypes from "prop-types";
import { Fragment, useCallback, useMemo } from "react";

import AggregateActivity from "./Activity/AggregateActivity";
import {
  getEntityCountString,
  getShownByField,
  getUniqueEntityCountString,
} from "./Activity/AggregateUtils";

import { useRelatedEntities } from "selectors/entity";

import { useStyles } from "hooks/useStyles";

import cardStyles from "styles/CardStyles";
import { feedColours as feedTextColours } from "styles/feedStyles";

const baseStyles = {
  verbText: {
    ...cardStyles.verbText,
    color: feedTextColours.feedListText || "#000",
  },
};

const UserListAggregate = (props) => {
  const { aggregateProps, mobile, ...rest } = props;
  const { styles } = useStyles(baseStyles, props);

  const {
    activities,
    activitiesByVerb,
    activitiesState: { firstActor, firstActorType },
  } = aggregateProps;

  const filteredActivities = useMemo(
    () =>
      activities.filter((activity) => {
        const objectType = activity.get("object").split(":")[0];

        return objectType === "podcast" || objectType === "episode";
      }),
    [activities]
  );

  const { userlist } = useRelatedEntities(filteredActivities.first());
  const filterEntities = useMemo(() => ({ userlist }), [userlist]);

  const countString = useMemo(
    () =>
      getUniqueEntityCountString(activities, [
        { key: "episode" },
        { key: "podcast" },
      ]),
    [activities]
  );

  const publishString = useMemo(
    () =>
      activitiesByVerb.has("publish_list") &&
      getEntityCountString(activitiesByVerb, [
        {
          key: "publish_list",
          displayName: "list",
          pre: "the",
        },
      ]),
    [activitiesByVerb]
  );

  const renderIntroText = useCallback(
    ({ renderEntityLink }) => {
      if (activitiesByVerb.has("add_item_to_list")) {
        return (
          <Fragment>
            {renderEntityLink(firstActorType, firstActor)}{" "}
            <span className={css(styles.verbText)}>added {countString}</span> to
            the list {userlist && renderEntityLink("userlist", userlist)}
          </Fragment>
        );
      }

      return (
        <Fragment>
          {renderEntityLink(firstActorType, firstActor)} published{" "}
          {publishString}
        </Fragment>
      );
    },
    [
      activitiesByVerb,
      firstActorType,
      firstActor,
      publishString,
      styles.verbText,
      countString,
      userlist,
    ]
  );

  return (
    <AggregateActivity
      {...rest}
      aggregateProps={aggregateProps}
      activities={filteredActivities}
      shownByField={getShownByField(aggregateProps)}
      renderIntroText={renderIntroText}
      mobile={mobile}
      highlightType="List"
      loaded
      filterEntities={filterEntities}
    />
  );
};

UserListAggregate.propTypes = {
  aggregateProps: PropTypes.object.isRequired,
  mobile: PropTypes.bool,
};

UserListAggregate.defaultProps = {
  mobile: false,
};

export default UserListAggregate;
