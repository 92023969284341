import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { useCallback, useMemo } from "react";

import RatingWithHover from "components/Rating/RatingWithHoverAsync";

import { useEntityFromTimeline } from "../useEntityFromTimeline";
import Activity from "./Activity/ActivityAsync";

import { entityNames } from "constants/entity";
import getUserDisplayName from "utils/entity/getUserDisplayName";
import indefiniteArticle from "utils/text/indefiniteArticle";

import { useStyles } from "hooks/useStyles";

import cardStyles from "styles/CardStyles";
import { feedColours as feedTextColours } from "styles/feedStyles";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  headerTextContainer: {
    width: "100%",
  },
  ratedText: {
    paddingLeft: ".2rem",
    marginRight: ".3rem",
  },
  ratingContainer: {
    ...gStyles.textEllipsis,
    fontSize: "0.625rem",
    marginTop: "-.1rem",
    display: "inline",

    [ScreenSizes.mdAndAbove]: {
      fontSize: "0.75rem",
    },
  },
  verbText: {
    ...cardStyles.verbText,
    color: feedTextColours.feedRateText || "#000",
  },
};

const RateActivity = (props) => {
  const { activity, mobile } = props;
  const { styles } = useStyles(baseStyles, props);

  const { entity: user } = useEntityFromTimeline(activity.get("actor"));
  const { entity: ratedEntity } = useEntityFromTimeline(activity.get("object"));

  const filterEntities = useMemo(
    () => ({
      [activity.get("object").split(":")[0]]: ratedEntity,
    }),
    [activity, ratedEntity]
  );

  const objectString = activity && activity.get("object");
  const [entity_type] = objectString ? objectString.split(":") : [""];

  const renderIntroText = useCallback(
    ({ renderEntityLink }) => (
      <div className={css(styles.headerTextContainer)}>
        {renderEntityLink("user", user)}
        <span className={css(styles.ratedText)}>
          <span className={css(styles.verbText)}>rated</span>
          {` ${indefiniteArticle(entity_type)} ${entityNames[entity_type]}`}
        </span>
        <span className={css(styles.ratingContainer)}>
          <RatingWithHover
            rating={activity.get("rating")}
            id={ratedEntity.get("id")}
            quiet
            readonly
            fontSize="1em"
            ComponentType="span"
            entity={ratedEntity}
            entity_type={entity_type}
            isAggregate={false}
            author={user}
            clickableStars
            ariaLabelFunc={({ rating }) =>
              `${getUserDisplayName(user, true)}'s Rating: ${
                Math.round(rating * 100) / 100
              } out of 5`
            }
          />
        </span>
      </div>
    ),
    [
      user,
      ratedEntity,
      activity,
      entity_type,
      styles.headerTextContainer,
      styles.ratingContainer,
      styles.ratedText,
      styles.verbText,
    ]
  );

  const renderIntroImage = useCallback(
    ({ renderEntityImage }) => renderEntityImage("user", user),
    [user]
  );

  const sections = useMemo(
    () => [
      {
        type: "intro",
        renderImage: renderIntroImage,
        renderText: renderIntroText,
        highlightType: "Rate",
      },
      {
        type: "entity",
        entityCardProps: {
          entity_type,
          entity: ratedEntity,
          showCreators: mobile,
          mobile,
          hideRating: true,
          hideEmptyRating: true,
        },
      },
    ],
    [renderIntroImage, renderIntroText, mobile, entity_type, ratedEntity]
  );

  if (!user || !ratedEntity) {
    return null;
  }

  return (
    <Activity
      {...props}
      sections={sections}
      loaded={!!(user && ratedEntity)}
      activity={activity}
      filterEntities={filterEntities}
    />
  );
};

RateActivity.propTypes = {
  activity: PropTypes.instanceOf(Map),
  mobile: PropTypes.bool,
};

RateActivity.defaultProps = {
  activity: Map(),
  mobile: false,
};

export default RateActivity;
