import FooterColumn from "./FooterColumn";

const PODCAST_LINKS = [
  { title: "Podcasts" },
  { title: "Best Podcasts", to: "/podcasts/ranking?ranking_period=month" },
  { title: "New Podcasts", to: "/podcasts/new" },
  { title: "Podcast Charts", to: "/charts/apple/us/top podcasts" },
  { title: "Best Episodes", to: "/episodes" },
  { title: "Add a Podcast", to: "/add" },
  { title: "Claim a Podcast", to: "/claim" },
  { title: "Podchaser 25", to: "/articles/podchaser-25" },
];

const PodcastFooterLinks = () => <FooterColumn links={PODCAST_LINKS} />;

export default PodcastFooterLinks;
