import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const PasswordReset = Loadable(() => import("./PasswordReset"));

function PasswordResetAsyncLoading(props) {
  return <LoadableLoading {...props} />;
}

const PasswordResetContainer = (props) => {
  return <PasswordReset {...props} fallback={<PasswordResetAsyncLoading />} />;
};

export default PasswordResetContainer;
