import { memo } from "react";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  header: {
    background: colours.mainHeaderBackground,
    height: "20.3rem",
    width: "100%",

    display: "flex",
    alignItems: "flex-end",
    paddingLeft: "3rem",
  },
  podcastImage: {
    ...gStyles.skeletonPlaceholder,
    borderRadius: 8,
    color: colours.grey,

    width: "10rem",
    height: "10rem",

    [ScreenSizes.lgAndAbove]: {
      width: "13rem",
      height: "12rem",
    },

    [ScreenSizes.xlAndAbove]: {
      width: "13rem",
      height: "12rem",
    },

    marginRight: "1rem",
  },
  podcastTitle: {
    ...gStyles.skeletonPlaceholder,
    borderRadius: 8,
    color: colours.grey,

    width: "24rem",
    height: "3rem",

    marginBottom: "1rem",
  },

  podcastSubTitle: {
    ...gStyles.skeletonPlaceholder,
    borderRadius: 8,
    color: colours.grey,

    width: "33rem",
    height: "2rem",
    marginBottom: "3rem",
  },
};

const PodcastHeaderSkeleton = (props) => {
  const { styles, css } = useStyles(baseStyles, props);

  return (
    <div className={css(styles.header)}>
      <div className={css(styles.podcastImage)} />
      <div>
        <div className={css(styles.podcastTitle)} />
        <div className={css(styles.podcastSubTitle)} />
      </div>
    </div>
  );
};

export default memo(PodcastHeaderSkeleton);
