import getFormattedPath from "./getFormattedPath";

import { formatURL } from "utils/format";

export default (category, path = "/podcasts") => {
  if (!category) {
    return "";
  }

  const categoryUrl = `/categories/${
    typeof category === "string" ? category : category.get("slug")
  }${getFormattedPath(path)}`;

  return formatURL(categoryUrl);
};
