import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const CreateListModal = Loadable(() => import("./CreateListModal"));

function CreateListModalAsyncLoading(props) {
  return <LoadableLoading {...props} />;
}

const CreateListModalContainer = (props) => {
  return (
    <CreateListModal {...props} fallback={<CreateListModalAsyncLoading />} />
  );
};

export default CreateListModalContainer;
