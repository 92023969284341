import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

const UnfollowModal = Loadable(() => import("./UnfollowModal"));

function UnfollowModalAsyncLoading(props) {
  return <LoadingOverlay {...props} span />;
}

const UnfollowModalContainer = (props) => {
  return <UnfollowModal {...props} fallback={<UnfollowModalAsyncLoading />} />;
};

export default UnfollowModalContainer;
