import atob from "atob";
import deepmerge from "deepmerge";
import qs from "qs";

const MAX_STORED_PROGRESS = 1000;

export const getStoredProgress = ({
  loggedOutLocalProgress,
  userLocalProgress = null,
  userProgress = null,
  isLoggedIn = false,
}) => {
  const parsedLoggedOutLocalProgress = loggedOutLocalProgress // get the general locally stored progress
    ? qs.parse(atob(loggedOutLocalProgress))
    : {};
  const parsedUserLocalProgress = userLocalProgress // get the locally stored progress for a specific user
    ? qs.parse(atob(userLocalProgress))
    : {};
  const parsedUserAccountProgress = userProgress || {}; // get the progress stored to a user's account

  // prioritises the account state, userLocal and finally loggedOutLocal progress
  return isLoggedIn
    ? deepmerge.all([
        parsedLoggedOutLocalProgress,
        parsedUserLocalProgress,
        parsedUserAccountProgress,
      ])
    : parsedLoggedOutLocalProgress;
};

export const deleteOldEpisodesProgress = (passedProgress) => {
  const progress = { ...passedProgress };
  // if there are over 100 episode progresses stored, delete the oldest
  if (Object.keys(progress).length > MAX_STORED_PROGRESS) {
    const sortedEpIds = Object.entries(progress)
      .sort((a, b) => {
        // sort new to oldest
        const { date: aDate } = a[1];
        const { date: bDate } = b[1];

        if (bDate === aDate) {
          return 0;
        }

        return bDate > aDate ? 1 : -1;
      })
      .map(([id]) => id);

    // get the oldest episodes above the cut off limit
    const oldestIds = sortedEpIds.slice(MAX_STORED_PROGRESS);

    oldestIds.forEach((id) => {
      delete progress[id];
    });
  }

  return progress;
};
