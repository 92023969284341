import PropTypes from "prop-types";

import OnResize from "components/Common/OnResize";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const contentDecorSize = 18;

const baseStyles = {
  contentWrapperOuter: {
    paddingTop: 12,
    paddingBottom: 12,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    zIndex: 9500,

    [ScreenSizes.mdAndAbove]: {
      position: "absolute",
    },
  },
  contentWrapperOuterAbsolute: {
    position: "absolute",
  },
  contentWrapperOuterAbsoluteTop: {
    bottom: "100%",
  },
  contentWrapperOuterNoPadding: {
    padding: 0,
  },
  contentWrapper: {
    ...gStyles.floatBubble,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9600,
    padding: "10px",
    position: "relative",
  },
  contentWrapperNoPadding: {
    padding: 0,
  },
  contentWrapperOffset: {
    left: ".75rem",
  },
  contentWrapperOffsetStart: {
    right: "1.5rem",

    [ScreenSizes.xlAndAbove]: {
      right: "4rem",
    },
  },
  contentWrapperPlacementTop: {
    marginBottom: 0,
  },
  contentWrapperPlacementBottom: {
    marginTop: 0, // contentDecorWidth,
  },
  contentDecor: {
    display: "block",
    zIndex: -1,
    position: "absolute",
    backgroundColor: "white",
    width: contentDecorSize,
    height: contentDecorSize,
    transform: "rotate(45deg)",
  },
  offsetContentDecor: {
    marginLeft: "-1.5rem",
  },
  offsetContentDecorStart: {
    marginRight: "-3rem",

    [ScreenSizes.xlAndAbove]: {
      marginRight: "-8rem",
    },
  },
  arrowBottom: {
    top: "-8px",
    boxShadow: "-2px -2px 3px 0 rgba(0, 0, 0, 0.1)",
  },
  arrowTop: {
    bottom: "-8px",
    boxShadow: "2px 2px 3px 0 rgba(0, 0, 0, 0.1)",
  },
  content: {
    display: "block",
  },
};

const ButtonPopout = (props) => {
  const {
    renderContent,
    offsetArrow,
    slideInFrom,
    noPadding,
    positionAbsolute,
    hideArrow,
    disablePortal,
    ariaLabel,
    placement,
    contentProps,
    popper,
    popperStyles,
    scheduleUpdate,
    arrow,
    arrowStyles,
    children,
    popperInstance,
  } = props;

  const { styles, css } = useStyles(baseStyles, props);

  const { getMenuProps } = contentProps;
  const isPlacedTop = placement.substr(0, 3) === "top";
  const isPlacedBottom = placement.substr(0, 6) === "bottom";
  const isPlacedStart = placement.includes("start");

  return (
    <span
      {...getMenuProps({
        "aria-labelledby": undefined,
        "aria-label": ariaLabel,
      })}
    >
      <span
        className={css(
          styles.contentWrapperOuter,
          noPadding && styles.contentWrapperOuterNoPadding,
          positionAbsolute && styles.contentWrapperOuterAbsolute,
          disablePortal &&
            positionAbsolute &&
            isPlacedTop &&
            styles.contentWrapperOuterAbsoluteTop
        )}
        ref={popper}
        style={popperStyles}
        data-placement={placement}
      >
        <span
          className={css(
            styles.contentWrapper,
            !hideArrow && isPlacedTop && styles.contentWrapperPlacementTop,
            !hideArrow &&
              isPlacedBottom &&
              styles.contentWrapperPlacementBottom,
            noPadding && styles.contentWrapperNoPadding,
            !slideInFrom &&
              offsetArrow &&
              (isPlacedStart
                ? styles.contentWrapperOffsetStart
                : styles.contentWrapperOffset)
          )}
        >
          {disablePortal ? (
            <OnResize onResize={scheduleUpdate}>
              {children ||
                renderContent({
                  ...contentProps,
                  scheduleUpdate,
                })}
            </OnResize>
          ) : (
            <>
              {popperInstance?.options && (
                <OnResize onResize={scheduleUpdate}>
                  {children ||
                    renderContent({
                      ...contentProps,
                      scheduleUpdate,
                    })}
                </OnResize>
              )}
            </>
          )}

          {!hideArrow && (
            <span
              className={css(
                styles.contentDecor,
                !slideInFrom &&
                  offsetArrow &&
                  (isPlacedStart
                    ? styles.offsetContentDecorStart
                    : styles.offsetContentDecor),
                placement && isPlacedTop && styles.arrowTop,
                placement && isPlacedBottom && styles.arrowBottom
              )}
              ref={arrow}
              style={arrowStyles}
            />
          )}
        </span>
      </span>
    </span>
  );
};

ButtonPopout.propTypes = {
  renderContent: PropTypes.func.isRequired,
  offsetArrow: PropTypes.bool,
  slideInFrom: PropTypes.string,
  noPadding: PropTypes.bool,
  hideArrow: PropTypes.bool,
  positionAbsolute: PropTypes.bool,
  disablePortal: PropTypes.bool,
  ariaLabel: PropTypes.string,
  contentProps: PropTypes.object.isRequired,
  popper: PropTypes.func.isRequired,
  popperStyles: PropTypes.object,
  scheduleUpdate: PropTypes.func.isRequired,
  arrow: PropTypes.object.isRequired,
  arrowStyles: PropTypes.object,
  placement: PropTypes.string,
  children: PropTypes.node,
};

ButtonPopout.defaultProps = {
  offsetArrow: false,
  slideInFrom: null,
  noPadding: false,
  hideArrow: false,
  positionAbsolute: false,
  disablePortal: false, // use this if used within a modal
  ariaLabel: undefined,
  popperStyles: undefined,
  arrowStyles: undefined,
  placement: undefined,
  children: null,
};

export default ButtonPopout;
