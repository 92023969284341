import * as constants from "constants/app";

const appActions = {
  navigateTo: (path) => ({
    type: constants.APP_NAVIGATE_TO,
    to: path,
  }),
  clearNavigateTo: () => ({
    type: constants.APP_CLEAR_NAVIGATE_TO,
  }),
  clearError: () => ({
    type: constants.APP_CLEAR_ERROR_REQUEST,
  }),
  catchUTM: (props) => ({
    type: constants.CATCH_UTM_PARAMS,
    params: props,
  }),
  changeWindowSize: (size, width) => ({
    type: constants.WINDOW_SIZE_CHANGED,
    size,
    width,
  }),
};

export default appActions;
