import PropTypes from "prop-types";
import { forwardRef, useCallback, useEffect, useMemo, useState } from "react";

import { isBeingViewedOnMobile } from "utils/size/isPhone";

const defaultTransform = (hover) => ({ hover });

const withHover =
  ({ transform = defaultTransform } = {}) =>
  (BaseComponent) => {
    const ReactWithHover = forwardRef((props, ref) => {
      const {
        onHoverChange,
        onMouseEnter: passedOnMouseEnter,
        onMouseLeave: passedOnMouseLeave,
        ...componentProps
      } = props;
      const { disabled } = props;
      const [hover, setHover] = useState(false);
      const isOnMobile = isBeingViewedOnMobile();
      const onMouseEnter = useCallback(
        (e) => {
          if (passedOnMouseEnter) {
            passedOnMouseEnter(e);
          }
          if (!disabled && !isOnMobile) {
            setHover(true);
          }
        },
        [disabled, isOnMobile, passedOnMouseEnter]
      );

      const onMouseLeave = useCallback(
        (e) => {
          if (passedOnMouseLeave) {
            passedOnMouseLeave(e);
          }
          if (!disabled) {
            setHover(false);
          }
        },
        [disabled, passedOnMouseLeave]
      );

      useEffect(() => {
        if (onHoverChange && !isOnMobile) {
          onHoverChange(hover);
        }
      }, [hover, onHoverChange, isOnMobile]);

      const hoverProps = useMemo(
        () => ({
          ref,
          onMouseEnter,
          onMouseLeave,
        }),
        [ref, onMouseEnter, onMouseLeave]
      );

      return (
        <BaseComponent
          {...transform(hover)}
          {...componentProps}
          {...hoverProps}
          hoverProps={hoverProps}
          ref={ref}
        />
      );
    });

    ReactWithHover.propTypes = {
      onHoverChange: PropTypes.func,
      disabled: PropTypes.bool,
      onMouseEnter: PropTypes.func,
      onMouseLeave: PropTypes.func,
    };

    ReactWithHover.defaultProps = {
      onHoverChange: null,
      disabled: false,
      onMouseEnter: null,
      onMouseLeave: null,
    };

    ReactWithHover.displayName = "ReactWithHover";

    return ReactWithHover;
  };

export default withHover;
