import { createRoutine, promisifyRoutine } from "redux-saga-routines";

import * as actions from "constants/auth";
import * as authStates from "constants/auth-states";

const login = createRoutine("LOGIN_FORM");
const register = createRoutine("REGISTER_FORM");
const requestPasswordReset = createRoutine("REQUEST_PASSWORD_RESET");
const passwordReset = createRoutine("PASSWORD_RESET");
const socialAuthUsername = createRoutine("SOCIAL_AUTH_USERNAME");

const authActions = {
  // redux-saga-routines actions
  login,
  loginFormHandler: promisifyRoutine(login),
  register,
  registerFormHandler: promisifyRoutine(register),
  requestPasswordReset,
  requestPasswordResetHandler: promisifyRoutine(requestPasswordReset),
  passwordReset,
  passwordResetHandler: promisifyRoutine(passwordReset),
  logout: () => ({
    type: actions.LOGOUT_REQUEST,
  }),
  loginSuccess: ({ token, user }) => ({
    type: actions.LOGIN_SUCCESS,
    token,
    user,
  }),
  tokenLoadRequest: ({ token }) => ({
    type: actions.TOKEN_LOAD_REQUEST,
    token,
  }),
  resumeOauth: (props) => ({
    type: actions.RESUME_OAUTH_PROCESS,
    payload: props,
  }),
  cancelLogin: () => ({
    type: actions.LOGIN_CANCELLED,
  }),
  startAuthFlow: (authState = authStates.LANDING, modalProps = {}) => {
    if (typeof authState === "string") {
      return {
        type: actions.START_AUTH_FLOW,
        authState,
        ...modalProps,
      };
    }

    return {
      type: actions.START_AUTH_FLOW,
      authState: authState ? authStates.LOGIN_FORM : authStates.REGISTER_FORM,
      ...modalProps,
    };
  },
  googleOneTapLogin: (response) => ({
    type: actions.GOOGLE_ONE_TAP_LOGIN,
    response,
  }),
  startSocialLoginFlow: (provider, authState = null) => ({
    type: actions.START_SOCIAL_LOGIN_FLOW,
    provider,
    authState,
  }),
  startSocialConnectFlow: (payload = {}) => ({
    type: actions.START_SOCIAL_CONNECT,
    ...payload,
  }),
  socialAuthUsername,
  socialAuthUsernameHandler: promisifyRoutine(socialAuthUsername),
  socialAuthPasswordSubmitted: ({ values: { password } }) => ({
    type: actions.SOCIAL_AUTH_PASSWORD_SUBMIT,
    password,
  }),
  submitSocialAuthPassword: ({ password }) => ({
    type: actions.SOCIAL_AUTH_PASSWORD_SUPPLIED,
    password,
  }),
  submitSocialAuthUsername: ({ username }) => ({
    type: actions.SOCIAL_AUTH_USERNAME_SUPPLIED,
    username,
  }),
  setAuthState: (authState, additionalAuthParams = null) => ({
    type: actions.SET_AUTH_STATE,
    authState,
    additionalAuthParams,
  }),
  noTokenAtInit: () => ({
    type: actions.NO_TOKEN_AT_INIT,
  }),
  clearSocialConnectError: () => ({
    type: actions.SOCIAL_CONNECT_DENIED,
    payload: null,
  }),
  setRecaptchaToken: (token = null, error = null) => ({
    type: "SET_RECAPTCHA_TOKEN",
    token,
    error,
  }),
  setSocialConnectionError: (
    socialType,
    entity_type,
    socialConnectionError
  ) => ({
    type: "SET_SOCIAL_CONNECTION_ERROR",
    socialConnectionError,
    socialType,
    entity_type,
  }),
  setSocialConnectSyncDetails: (
    socialType,
    entity_type,
    socialConnectSyncDetails
  ) => ({
    type: "SET_SOCIAL_CONNECT_SYNC_DETAILS",
    socialConnectSyncDetails,
    socialType,
    entity_type,
  }),
};

export default authActions;
