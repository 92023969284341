import { faSave } from "@fortawesome/free-solid-svg-icons/faSave";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useCallback } from "react";

import StandardButton from "components/Buttons/StandardButton";

import modalActions from "actions/modals";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import useLoggedInUser from "hooks/useLoggedInUser";
import useWindowSize from "hooks/useWindowSize";

import ScreenSizes from "styles/ScreenSizes";

const saveSearchButtonStyles = {
  button: {
    width: "2rem",
    height: "2rem",
    fontSize: "1rem",
    margin: 0,

    [ScreenSizes.lgAndAbove]: {
      padding: ".4rem 1rem",
      maxWidth: "7rem",
      fontSize: ".75rem",
      height: "auto",
      width: "100%",
    },
  },
};

const MobileSaveSearchButton = (props) => {
  const { entity_type, context } = props;
  const { isWindowSizeOrLess } = useWindowSize();
  const mobile = isWindowSizeOrLess("medium");

  const loggedInUser = useLoggedInUser();

  const { showModal } = useActionCreators({
    showModal: modalActions.showModal,
  });

  const handleOpenSaveSearchModal = useCallback(() => {
    showModal("saveSearch", { entity_type, context });
    sendGAEvent({
      action: "SaveSearchButtonClicked",
      context: "SearchResultsPageTitle",
      entity_type,
    });
  }, [entity_type, context, showModal]);

  if (loggedInUser && mobile) {
    return (
      <StandardButton
        variation="pink"
        tooltip="Save Search"
        label={mobile ? <FontAwesomeIcon icon={faSave} /> : "Save Search"}
        customStyles={saveSearchButtonStyles}
        onClick={handleOpenSaveSearchModal}
      />
    );
  }

  return null;
};

MobileSaveSearchButton.propTypes = {
  entity_type: PropTypes.string.isRequired,
  context: PropTypes.string.isRequired,
};

export default MobileSaveSearchButton;
