import { faGlobeAmericas } from "@fortawesome/free-solid-svg-icons/faGlobeAmericas";
import { faLink } from "@fortawesome/free-solid-svg-icons/faLink";
import { faLock } from "@fortawesome/free-solid-svg-icons/faLock";
import pluralize from "pluralize";

export const getSubtitleString = (list) => {
  if (list.get("entity_type")) {
    return `A curated ${list.get("entity_type")} list by`;
  }

  return "A brand new curated list by";
};

export const getUserlistMetaImageUrl = (userlist) => {
  if (userlist.get("privacy") === "link_only") {
    return `https://imagegen.podchaser.com/userlist/${userlist.get(
      "link_only_id"
    )}.png`;
  }
  return `https://imagegen.podchaser.com/userlist/${userlist.get("id")}.png`;
};

export const getListCountText = (count, entity_type) => {
  if (count === 0) {
    return "no items";
  }
  return `${count} ${pluralize(entity_type, count)}`;
};

export const getUserListCascadingImages = (list) =>
  list && list.has("preview_entities")
    ? list
        .get("preview_entities")
        .map((ent) => ({
          image: ent.get("image_url"),
          alt: ent.get("title"),
          title: ent.get("title"),
        }))
        .toJS()
    : [];

export const getUserListEditUrl = (list) =>
  `/profile/lists/${list.get("id")}/edit`;

export const PRIVACY_OPTIONS = [
  {
    name: "Public",
    value: "public",
    icon: faGlobeAmericas,
    description: "Anyone can view, rate and share your list.",
  },
  {
    name: "Private",
    value: "private",
    icon: faLock,
    description:
      "Only you can see your list. Useful for saving episodes to listen to later.",
  },
  {
    name: "Link Only",
    value: "link_only",
    icon: faLink,
    description:
      "Anyone with the link can see your list, but it won't be shown in search results or related pages.",
  },
];

export const getPrivacyDisplaySettings = (privacy) =>
  PRIVACY_OPTIONS.find((o) => o.value === privacy);

export const isLinkOnlyId = (id) => id && id.toString().substr(0, 3) === "pc!";
