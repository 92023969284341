import { Map } from "immutable";
import PropTypes from "prop-types";

import EpisodeTypeBadge from "./EpisodeTypeBadge";

import getEpisodeType from "utils/entity/getEpisodeType";

const EpisodeTypeBadgeContainer = (props) => {
  const { episode, showLabel, label, tooltipText, showTooltip } = props;

  const episodeType = getEpisodeType(episode);

  if (!episodeType) {
    return null;
  }

  return (
    <EpisodeTypeBadge
      episode={episode}
      showLabel={showLabel}
      label={label}
      tooltipText={tooltipText}
      showTooltip={showTooltip}
    />
  );
};

EpisodeTypeBadgeContainer.propTypes = {
  passedLabel: PropTypes.string,
  showLabel: PropTypes.bool,
  showTooltip: PropTypes.bool,
  tooltipText: PropTypes.string,
  padded: PropTypes.bool,
  size: PropTypes.number,
  label: PropTypes.node,
  episode: PropTypes.instanceOf(Map),
};

EpisodeTypeBadgeContainer.defaultProps = {
  passedLabel: null,
  showLabel: true,
  showTooltip: false,
  tooltipText: null,
  padded: false,
  size: undefined,
  label: null,
  episode: null,
};

export default EpisodeTypeBadgeContainer;
