import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const RatedView = Loadable(() => import("./RatedView"));

function RatedViewAsyncLoading(props) {
  return <LoadableLoading {...props} page />;
}

const RatedViewContainer = (props) => {
  return <RatedView {...props} fallback={<RatedViewAsyncLoading />} />;
};

export default RatedViewContainer;
