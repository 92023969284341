import {
  makeSelectSpecificPodcast,
  selectPodcastLoading,
  selectPodcastFailed,
} from "selectors/podcast";

import useReduxState from "hooks/useReduxState";

export function usePodcastData(podcastId) {
  const selectPodcasts = makeSelectSpecificPodcast();

  const podcast = useReduxState(
    (state) => selectPodcasts(state, podcastId),
    [podcastId]
  );

  return podcast;
}

export function usePodcastDataIsLoading(podcastID) {
  const loading = useReduxState(
    (state) => selectPodcastLoading(state, podcastID),
    [podcastID]
  );

  return loading;
}

export function usePodcastDataFailed(podcastID) {
  const failed = useReduxState(
    (state) => selectPodcastFailed(state, podcastID),
    [podcastID]
  );

  return failed;
}

export default usePodcastData;
