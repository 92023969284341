import { parseISO } from "date-fns/parseISO";
import PropTypes from "prop-types";
import { DayPicker } from "react-day-picker";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseCustomStyles = {
  day: {
    width: "1.75rem",
    height: "1.75rem",
    ...gStyles.fontRegular,
    fontSize: "0.625rem",
    textAlign: "center",
    border: "none",
    cursor: "pointer",
    color: colours.bodyText,
  },

  head_cell: {
    ...gStyles.fontRegular,
    fontSize: "0.625rem",
    textAlign: "center",
    color: colours.midGrey,
  },
  cell: {
    width: "1.75rem",
    height: "1.75rem",
  },
  caption_label: {
    ...gStyles.fontBold,
    fontSize: "0.625rem",
    textAlign: "left",
    color: colours.midGrey,
  },

  button: {
    width: "1.25rem",
    height: "1.25rem",
  },

  dropdown: {
    width: "6.25rem",
    padding: "0.313rem",
  },
};

const baseModifiersStyles = {
  selected: { backgroundColor: colours.selectedLightBlue },
  range_start: {
    color: colours.white,
    backgroundColor: colours.darkishBlue,
    fontWeight: "bold",
  },
  range_end: {
    color: colours.white,
    backgroundColor: colours.darkishBlue,
    fontWeight: "bold",
  },
  day_selected: {
    color: colours.white,
    backgroundColor: colours.darkishBlue,
    fontWeight: "bold",
  },
};

const baseStyles = {
  outer: {
    position: "relative",
  },
  dayPicker: {
    borderRadius: "0.5rem",
  },
};

function DatePickerCalendar(props) {
  const {
    captionLayout,
    mode,
    footer,
    fromDate: fromDateString,
    toDate: toDateString,
    selected,
    setSelected,
    month,
    onMonthChange,
    customStylesProp,
    customModifiersStylesProp,
  } = props;
  const { styles, css } = useStyles(baseStyles, props);

  const customStyles = { ...baseCustomStyles, ...customStylesProp };
  const modifiersStyles = {
    ...baseModifiersStyles,
    ...customModifiersStylesProp,
  };

  const fromDate = parseISO(fromDateString);
  const toDate = parseISO(toDateString);

  return (
    <DayPicker
      styles={customStyles}
      className={css(styles.dayPicker)}
      mode={mode}
      selected={selected}
      onSelect={setSelected}
      month={month}
      onMonthChange={onMonthChange}
      captionLayout={captionLayout}
      fromDate={fromDate}
      toDate={toDate}
      footer={footer}
      modifiersStyles={modifiersStyles}
    />
  );
}

DatePickerCalendar.propTypes = {
  captionLayout: PropTypes.string,
  mode: PropTypes.string,
  footer: PropTypes.node,
  fromDate: PropTypes.string,
  toDate: PropTypes.string,
  selected: PropTypes.object,
  setSelected: PropTypes.func,
  month: PropTypes.instanceOf(Date),
  onMonthChange: PropTypes.func,
};

export default DatePickerCalendar;
