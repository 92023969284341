import { formatURL } from "utils/format";

export default (string, separator = "-") => {
  if (string) {
    const dashString = (formatURL(string) || "")
      .toString()
      .trim()
      .replace(/\s+/g, separator)
      .toLowerCase();

    return dashString.replace(/[`~!@#$%^&*()|+\=?;:'",.<>\{\}\[\]\\\/]/gi, "");
  }

  return "";
};
