import Loadable from "@loadable/component";

import TimelineSkeletonItem from "components/Timeline/TimelineSkeletonItem";

const LikedActivity = Loadable(() =>
  import(/* webpackChunkName: "LikedActivity" */ "./LikedActivity")
);

function LikedActivityLoading(props) {
  return <TimelineSkeletonItem />;
}

const LikedActivityContainer = (props) => {
  return <LikedActivity {...props} fallback={<LikedActivityLoading />} />;
};

export default LikedActivityContainer;
