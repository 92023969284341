import PropTypes from "prop-types";
import { forwardRef } from "react";

import NavTitle from "./NavTitle";

const NavMenuItemLabel = forwardRef((props, ref) => {
  const { item } = props;

  const { id, label, icon } = item;

  return (
    <>
      <div ref={ref}>{icon}</div>
      {label && <NavTitle id={id}>{label}</NavTitle>}
    </>
  );
});

NavMenuItemLabel.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.node.isRequired,
    icon: PropTypes.object,
  }).isRequired,
};

NavMenuItemLabel.displayName = "NavMenuItemLabel";

export default NavMenuItemLabel;
