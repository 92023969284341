import { useEffect } from "react";

import { selectTimeline } from "selectors/timeline";

import useReduxState from "hooks/useReduxState";
import useTimelineAction from "hooks/useTimelineAction";

export default function useTimeline(timelineKey, options = {}) {
  const timeline = useReduxState(
    (state) => selectTimeline(state, timelineKey),
    [timelineKey]
  );

  const {
    actions: { loadTimeline },
    timelineModuleLoaded,
  } = useTimelineAction();

  useEffect(() => {
    if (
      timelineModuleLoaded &&
      timelineKey &&
      !options.doNotLoad &&
      !timeline?.get("loaded") &&
      !timeline?.get("loading") &&
      !timeline?.get("error")
    ) {
      loadTimeline({ timelineKey });
    }
  }, [
    loadTimeline,
    options.doNotLoad,
    timeline,
    timelineKey,
    timelineModuleLoaded,
  ]);

  // get it from state again so that the loaded state is included
  return useReduxState(
    (state) => selectTimeline(state, timelineKey),
    [timelineKey]
  );
}
