import colours from "../../styles/colours";
import gStyles from "../../styles/GenericStyles";
import buttonTokens from "../../styles/tokens/tokens-button";
import generateTransition from "../../utils/generateTransition";

export const sideIcon = {
  maxWidth: "1em",
  display: "block",
  flex: "0 0 auto",
  alignSelf: "center",
  justifySelf: "center",
  height: "auto",
  transition: generateTransition({
    targets: ["opacity", "transform"],
    speed: "400ms",
  }),
};

export const baseStyles = {
  outer: {
    width: "100%",
    position: "relative",
    margin: 0,
    fontSize: "inherit",
  },
  input: {
    ...gStyles.headerFilterButton,
  },
  content: {
    ...gStyles.headerFilterButtonContent,
  },
  icon: {
    ...gStyles.headerFilterButtonIcon,
  },
  text: {
    ...gStyles.headerFilterButtonLabel,
    paddingRight: "0.5em",
    ":last-child": {
      paddingRight: "0.5em",
    },
  },
  sideButtons: {
    display: "flex",
    flex: "0 0 auto",
    flexDirection: "row",
    height: "100%",
  },
  sideButton: {
    ...gStyles.noButton,
    display: "flex",
    flex: "0 0 auto",
    flexDirection: "column",
    borderLeft: `1px solid ${buttonTokens.color.border.inner.secondary.default.default}`,
    // alignSelf: 'stretch',
    justifySelf: "stretch",
    alignItems: "center",
    justifyContent: "center",
    width: "3em",
    textAlign: "center",
  },
  sideButtonLink: {
    padding: "0.7em 0.7em 0.6em",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ":hover": {
      background: colours.lightGreyishBlue,
    },
    ":focus": {
      background: colours.lightGreyishBlue,
    },
  },
  sideIcon: {
    ...sideIcon,
  },
  toggleIcon: {
    ...sideIcon,
    transformOrigin: "50%",
  },
  options: {
    ...gStyles.softShadow,
    position: "absolute",
    zIndex: 50,
    width: "100%",
    minWidth: "12em",
    background: "#fff",
    borderRadius: 14,
    overflow: "hidden",
  },
  option: {
    ...gStyles.fontSemiBold,
    ...gStyles.contentBox,
    borderTop: 0,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    height: "3em",
    minWidth: "12em",
    textAlign: "left",
    color: colours.bodyText,
    lineHeight: 1,
    position: "relative",
    ":last-child": {
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
    },
  },
  highlightedOption: {
    background: "var(--color-neutral-d95)",
  },
  selectedOption: {
    ...gStyles.fontBold,
    color: colours.greyishBlue,
    cursor: "default",
  },
  selectedHighlightedOption: {
    background: "none",
  },
  sortIcon: {
    color: colours.grey,
  },
  sortIconDisabled: {
    opacity: "0.3",
    cursor: "not-allowed",
  },
  openSideIcon: {
    opacity: 0.3,
  },
  openToggleIcon: {
    opacity: 0.3,
    transform: "scaleY(-1)",
  },
};
