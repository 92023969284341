import { Map } from "immutable";
import { memo, useCallback } from "react";

import RegisterAppModal from "components/Api/RegisterAppModalAsync";
import RequestApiPlanUpgradeModal from "components/Api/RequestApiPlanUpgradeModalAsync";
import PodcastClaimModal from "components/Claiming/PodcastClaimModal/async";
import AddVoiceActorCharacterModalAsync from "components/Creator/CreatorEditorNew/Modals/AddVoiceActorCharacterModalAsync";
import CreditsSubmittedModalAsync from "components/Creator/CreatorEditorNew/Modals/CreditsSubmittedModalAsync";
import EditCharacterRoleModalAsync from "components/Creator/CreatorEditorNew/Modals/EditCharacterRoleModalAsync";
import EpisodeSelectionMenuModalAsync from "components/Creator/CreatorEditorNew/Modals/EpisodeSelectionMenuModalAsync";
import RecurringCreditsSubmittedModalAsync from "components/Creator/CreatorEditorNew/Modals/RecurringCreditsSubmittedModalAsync";
import RecurringRoleQuestionModalAsync from "components/Creator/CreatorEditorNew/Modals/RecurringRoleQuestionModalAsync";
import VerifyCreatorModal from "components/Creator/VerifyCreatorModalAsync";
import PromptModal from "components/Entities/Prompts/PromptModalAsync";
import DismissableErrorModal from "components/Errors/DismissableErrorModalAsync";
import ExportModal from "components/Export/ExportModalAsync";
import EntityFollowModal from "components/Follow/EntityFollowModalAsync";
import UnfollowModal from "components/Follow/UnfollowModalAsync";
import ProfileImageInputModal from "components/Global/Inputs/ProfileImageInput/ProfileImageInputModalAsync";
import InsightsExportLimitModal from "components/InsightsExport/InsightsExportLimitModalAsync";
import InsightsExportModal from "components/InsightsExport/InsightsExportModalAsync";
import FilterSelectModal from "components/Lists/FilterSelect/FilterSelectModal/async";
import OnboardingModal from "components/OnboardingModal/OnboardingModalAsync";
import DownloadContactsModal from "components/Pro/DownloadContactsModal/async";
import ProConciergeQuestionModal from "components/Pro/ProConciergeQuestionModalAsync";
import PublicDownloadContactsModal from "components/Public/DownloadContactsModal/async";
import RatingStepsModal from "components/Rating/RatingStepsModal/async";
import SingleReviewModal from "components/Review/SingleReviewModalAsync";
import SaveSearchModal from "components/Search/SavedSearches/SaveSearchModalAsync";
import BadgesModal from "components/Tastemaker/BadgesModalAsync";
import CreateListModal from "components/UserLists/CreateListModal/async";
import VerifyEmailModal from "pages/Account/Settings/VerifyEmailModalAsync";
import BrandHeaderMobileEllipsisModal from "pages/BrandView/BrandHeader/BrandHeaderMobileEllipsisModal/BrandHeaderMobileEllipsisModalAsync";
import CreatorHeaderMobileEllipsisModal from "pages/CreatorView/CreatorHeader/CreatorHeaderMobileEllipsisModal/CreatorHeaderMobileEllipsisModalAsync";
import IntegrationDetailsModal from "pages/IntegrationsLeaderboard/IntegrationDetailsModal/async";
import IntegrationSuggestionModal from "pages/IntegrationsLeaderboard/IntegrationDetailsModal/IntegrationSuggestionModalAsync";
import ClaimNetworkModal from "pages/Networks/ClaimNetwork/ClaimNetworkModalAsync";
import UserListEditShareModal from "pages/UserListEdit/UserListEditShareModalAsync";
import UserHeaderMobileEllipsisModal from "pages/UserProfile/UserProfileInfoInputPanel/UserHeaderMobileEllipsisModalAsync";

import ApiTokenModal from "../../pages/Account/Settings/API/FirstTimeApiTokenModal/ApiTokenModalAsync";
import AdBuyingModalAsync from "../AdBuyingLeadForm/AdBuyingModalAsync";
import LoginModal from "../Auth/async";
import ChartsFilterModal from "../Lists/FilterSelect/FilterSelectModal/ChartsModal";
import ListDownloadModal from "../Lists/ListExporting/ListExportingModal/ListDownloadModalAsync";
import ProLeadFormModal from "../OnSiteProLeadForm/ProLeadFormModalAsync";
import OpenExclusiveModal from "../Player/OpenExclusiveModalAsync";
import SiteTourModal from "../SiteTour/SiteTourModalAsync";
import GenericModal from "./GenericModalAsync";
import LoadingModal from "./LoadingModalAsync";
import AlertLimitModal from "./Modals/Alerts/AlertLimitModalAsync";
import CreateAlertModal from "./Modals/Alerts/CreateAlertModalAsync";
import DeleteAlertModal from "./Modals/Alerts/DeleteAlertModalAsync";
import BrandWatchlistInfoModal from "./Modals/BrandWatchlistInfoModalAsync";
import PrivacyPolicyUpdateModal from "./Modals/PrivacyPolicyUpdateModalAsync";
import RemoveBrandsFromWatchlistModal from "./Modals/RemoveBrandsFromWatchlistModalAsync";
import SendAlertFeedbackModal from "./Modals/SendAlertFeedbackModalAsync";
import SendNetworkFeedbackModal from "./Modals/SendNetworkFeedbackModalAsync";
import SendSlackFeedbackModal from "./Modals/SendSlackFeedbackModalAsync";
import SharedEpisodeWithTimeModal from "./Modals/SharedEpisodeWithTimeModalAsync";
import TellMeWhyPromptModal from "./Modals/tellMeWhy/TellMeWhyModalAsync";

import actionCreators from "actions/modals";

import useActionCreators from "hooks/useActionCreators";
import useReduxState from "hooks/useReduxState";

export const modalComponents = {
  adBuyingLeadForm: AdBuyingModalAsync,
  addVoiceActorCharacter: AddVoiceActorCharacterModalAsync,
  alertLimit: AlertLimitModal,
  apiTokenModal: ApiTokenModal,
  badgesList: BadgesModal,
  brandEllipsisMenu: BrandHeaderMobileEllipsisModal,
  brandWatchlistInfo: BrandWatchlistInfoModal,
  createAlert: CreateAlertModal,
  createList: CreateListModal,
  claimNetwork: ClaimNetworkModal,
  creatorEllipsisMenu: CreatorHeaderMobileEllipsisModal,
  creditsSubmitted: CreditsSubmittedModalAsync,
  deleteAlert: DeleteAlertModal,
  downloadContacts: DownloadContactsModal,
  publicDownloadContacts: PublicDownloadContactsModal,
  editCharacterRole: EditCharacterRoleModalAsync,
  editShareList: UserListEditShareModal,
  episodePlayOnRender: SharedEpisodeWithTimeModal,
  error: DismissableErrorModal,
  export: ExportModal,
  filters: FilterSelectModal,
  chartsFilter: ChartsFilterModal,
  followList: EntityFollowModal,
  generic: GenericModal,
  insightsExport: InsightsExportModal,
  insightsExportLimit: InsightsExportLimitModal,
  integrationDetails: IntegrationDetailsModal,
  integrationSuggestion: IntegrationSuggestionModal,
  listDownloadModal: ListDownloadModal,
  loading: LoadingModal,
  login: LoginModal,
  onboarding: OnboardingModal,
  openExclusive: OpenExclusiveModal,
  podcastClaim: PodcastClaimModal,
  policyUpdated: PrivacyPolicyUpdateModal,
  proConcierge: ProConciergeQuestionModal,
  proLeadForm: ProLeadFormModal,
  profileImageInput: ProfileImageInputModal,
  prompt: PromptModal,
  ratingSteps: RatingStepsModal,
  recurringCreditsSubmitted: RecurringCreditsSubmittedModalAsync,
  registerApp: RegisterAppModal,
  removeBrandFromWatchlist: RemoveBrandsFromWatchlistModal,
  requestApiPlanUpgrade: RequestApiPlanUpgradeModal,
  review: SingleReviewModal,
  saveSearch: SaveSearchModal,
  sendAlertFeedback: SendAlertFeedbackModal,
  sendNetworkFeedback: SendNetworkFeedbackModal,
  sendSlackFeedback: SendSlackFeedbackModal,
  sendEmailVerification: VerifyEmailModal,
  showEpisodeSelectionMenu: EpisodeSelectionMenuModalAsync,
  showRecurringRoleQuestion: RecurringRoleQuestionModalAsync,
  siteTour: SiteTourModal,
  unfollow: UnfollowModal,
  userEllipsisMenu: UserHeaderMobileEllipsisModal,
  verifyCreator: VerifyCreatorModal,
  tellMyWhyPrompt: TellMeWhyPromptModal,
};

const ModalRoot = () => {
  const { modalType, modalProps, canClose } = useReduxState((state) => {
    if (!state.modals) {
      // not loaded yet
      return {};
    }
    return state.modals.getIn(["modalStack"]).size > 0
      ? state.modals
          .getIn(["modalStack"])
          .last()
          .updateIn(["modalProps"], Map(), (p) => p && p.toObject())
          .toObject()
      : {};
  }, []);

  const { closeModal } = useActionCreators(actionCreators);

  const handleCloseModal = useCallback(() => {
    closeModal(modalType);

    if (modalProps && modalProps.onClose) {
      modalProps.onClose();
    }
  }, [closeModal, modalType, modalProps]);

  if (modalComponents[modalType]) {
    const ModalComponent = modalComponents[modalType];
    const canCloseIdBool = typeof canClose === "boolean";

    return (
      <ModalComponent
        {...modalProps}
        closeModal={closeModal}
        onRequestClose={!canCloseIdBool || canClose ? handleCloseModal : null}
        isOpen
      />
    );
  }

  return null;
};

export default memo(ModalRoot);
