import Loadable from "@loadable/component";
const RequestApiPlanUpgradeModal = Loadable(() =>
  import("./RequestApiPlanUpgradeModal")
);

function RequestApiPlanUpgradeModalAsyncLoading() {
  return null;
}

const RequestApiPlanUpgradeModalContainer = (props) => {
  return (
    <RequestApiPlanUpgradeModal
      {...props}
      fallback={<RequestApiPlanUpgradeModalAsyncLoading />}
    />
  );
};

export default RequestApiPlanUpgradeModalContainer;
