/* eslint-disable max-len */

export default {
  prefix: "fpc",
  iconName: "pc-bolt-circle",
  icon: [
    512,
    512,
    [],
    null,
    "M256,1.13686838e-13 C397.384896,1.13686838e-13 512,114.615104 512,256 C512,397.384896 397.384896,512 256,512 C114.615104,512 0,397.384896 0,256 C0,114.615104 114.615104,1.13686838e-13 256,1.13686838e-13 Z M282.25545,88 L187.756524,88 C179.881613,88 173.18794,93.8232422 172.137951,101.609375 L151.13819,258.640625 C149.891329,268.0625 157.241246,276.4375 166.756763,276.4375 L244.652752,276.4375 L214.399971,403.698242 C212.037498,413.643555 219.649911,423 229.690422,423 C235.20286,423 240.4528,420.121094 243.340267,415.148438 L358.838955,216.242188 C364.94201,205.838867 357.395221,192.6875 345.254734,192.6875 L269.524345,192.6875 L297.480277,107.759766 C300.105247,97.8144531 292.558458,88 282.25545,88 Z",
  ],
};
