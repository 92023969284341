import { faFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF";
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons/faLinkedin";
import { faReddit } from "@fortawesome/free-brands-svg-icons/faReddit";
import { faSlack } from "@fortawesome/free-brands-svg-icons/faSlack";
import { faTiktok } from "@fortawesome/free-brands-svg-icons/faTiktok";

import SocialMedicaIcons from "./SocialMediaIcons";

import { useStyles } from "hooks/useStyles";

import faXTwitter from "styles/icons/faXTwitter";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  socialList: {
    display: "flex",
    height: "fit-content",
    gap: ".5rem",
    flexWrap: "wrap",
    [ScreenSizes.mdAndAbove]: {
      flexWrap: "nowrap",
      justifyContent: "flex-end",
      gap: "0",
    },
    [ScreenSizes.lgAndAbove]: {
      gap: "0",
    },
  },
};
const SocialMedia = () => {
  const { styles, css } = useStyles(baseStyles);
  return (
    <ul className={css(styles.socialList)}>
      <SocialMedicaIcons
        label="X/Twitter"
        to="https://www.twitter.com/Podchaser"
        icon={faXTwitter}
      />
      <SocialMedicaIcons
        label="Facebook"
        to="https://www.facebook.com/Podchaser"
        icon={faFacebookF}
      />

      <SocialMedicaIcons
        label="Linkedin"
        to="https://www.linkedin.com/company/Podchaser"
        icon={faLinkedin}
      />
      <SocialMedicaIcons
        label="Instagram"
        to="https://www.instagram.com/Podchaser"
        icon={faInstagram}
      />
      <SocialMedicaIcons
        label="Reddit"
        to="https://www.reddit.com/u/podchaser"
        icon={faReddit}
      />
      <SocialMedicaIcons
        label="TikTok"
        to="https://www.tiktok.com/@podchaser"
        icon={faTiktok}
      />
      <SocialMedicaIcons
        label="Slack"
        to="https://join.slack.com/t/podchaser-community/shared_invite/enQtNDE5MjEzNjMyOTM1LTFjMmZmOWNmMmUyMGE3ZDM0NWZiNzYxMGRkZDE5NDUwZjUzYmY2MzAyNGU2MTZhNGVmZjg5YzZlZTNkYzZkNDE"
        icon={faSlack}
      />
    </ul>
  );
};

export default SocialMedia;
