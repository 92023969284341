import PropTypes from "prop-types";
import { memo, useMemo } from "react";

import { selectEntity } from "selectors/entity";
import { selectListEntityType } from "selectors/pagination";

import useReduxState from "hooks/useReduxState";

const EntityListItem = (props) => {
  const { renderItem, itemId, index, small, listKey, getEntity } = props;

  const item = useReduxState(
    (state) => {
      const entity_type = selectListEntityType(state, listKey);

      return getEntity
        ? getEntity(state, itemId)
        : selectEntity(state, itemId, entity_type);
    },
    [itemId, listKey, getEntity]
  );

  return useMemo(
    () => renderItem(item, index, small),
    [renderItem, item, index, small]
  );
};

EntityListItem.propTypes = {
  listKey: PropTypes.string.isRequired,
  renderItem: PropTypes.func.isRequired,
  itemId: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  getEntity: PropTypes.func,
  small: PropTypes.bool,
};

EntityListItem.defaultProps = {
  getEntity: null,
  small: false,
};

export default memo(EntityListItem);
