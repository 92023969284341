import { css } from "aphrodite";
import { useCallback } from "react";

import ShareButton from "components/Buttons/Items/ShareButtonAsync";
import LoadingOverlay from "components/Common/LoadingOverlay";

import accessibleClickProps from "../../../utils/misc/accessibleClickProps";
import PageWrapper from "./BadgeViewWrapper";
import EarnedBadge from "./EarnedBadge";

import { selectBadgesLoading, selectBadgesFailed } from "selectors/badges";
import { selectUserLoading, selectUserFailed } from "selectors/user";

import useLoggedInUser from "hooks/useLoggedInUser";
import useReduxState from "hooks/useReduxState";
import { useStyles } from "hooks/useStyles";
import useUserBadges from "hooks/useUserBadges";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  list: {
    display: "inline-grid",
    columnGap: 20,
    rowGap: 20,
    gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
    justifyItems: "start",
  },
  share: {
    ...gStyles.fontSemiBold,
    appearance: "none",
    border: "none",
    fontSize: ".70rem",
    color: colours.negative,
    margin: 0,
    textAlign: "center",
    cursor: "pointer",
  },
  emptyMessage: {
    ...gStyles.fontRegular,
    fontSize: ".875rem",
    lineHeight: "1.4rem",
  },
};

const ERROR_MESSAGE =
  "It seems that an error has occurred, please try again later.";

const BadgesView = (props) => {
  const { styles } = useStyles(baseStyles, props);
  const user = useLoggedInUser();

  const userId = user?.get("id");
  const userIsLoading = useReduxState((state) =>
    selectUserLoading(state, userId)
  );
  const badgesAreLoading = useReduxState((state) =>
    selectBadgesLoading(state.badges)
  );

  const userLoadFailed = useReduxState((state) =>
    selectUserFailed(state, userId)
  );
  const badgesLoadFailed = useReduxState((state) =>
    selectBadgesFailed(state.badges)
  );

  const { listOfBadges, isAwaitingForBadgesRequest } = useUserBadges(
    user?.get("badges_awarded", [])
  );

  const renderShareButton = useCallback(
    ({ toggleMenu, ref }) => (
      <button
        className={css(styles.share)}
        ref={ref}
        {...accessibleClickProps(toggleMenu)}
      >
        Share
      </button>
    ),
    [styles.share]
  );

  const renderEarnedBadge = (badge) => {
    if (badge) {
      return (
        <EarnedBadge
          key={badge.get("id")}
          title={badge.get("title")}
          description={badge.get("description")}
          badge_image_url={badge.get("badge_image_url")}
        >
          <ShareButton
            entity_type="badge"
            entity={{ user, badge }}
            renderButton={renderShareButton}
          />
        </EarnedBadge>
      );
    }

    return null;
  };

  if (userIsLoading || badgesAreLoading || isAwaitingForBadgesRequest) {
    return (
      <PageWrapper>
        <LoadingOverlay />
      </PageWrapper>
    );
  }

  if (userLoadFailed || badgesLoadFailed) {
    return (
      <PageWrapper>
        <div className={css(styles.emptyMessage)}>{ERROR_MESSAGE}</div>
      </PageWrapper>
    );
  }

  if (!listOfBadges || listOfBadges.size === 0 || listOfBadges.length === 0) {
    return (
      <PageWrapper>
        <div className={css(styles.emptyMessage)}>
          <p>No badges unlocked yet!</p>
          <p>
            But within you lies unimaginable greatness! Continue exploring and
            interacting with the Podchaser community to unlock badges. Once
            earned, you{"'"}ll be able to view your achievements here.
          </p>
          <p>Onward!</p>
        </div>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <div className={css(styles.list)}>
        {listOfBadges?.map(renderEarnedBadge)}
      </div>
    </PageWrapper>
  );
};

export default BadgesView;
