import { badgeSchema } from "api/schema/badge";
import {
  creatorSchema,
  episodeSchema,
  podcastSchema,
} from "api/schema/creator_podcast";
import { userSchema } from "api/schema/user";
import { fromJS } from "immutable";
import { normalize, schema } from "normalizr";
import { CANCEL } from "redux-saga";

import client from "./client";

export function loadSpecificUser({ user_id }) {
  return client
    .get(`/user/${user_id}/`)
    .then((response) => normalize(response.data, userSchema));
}

export function loadLoggedInUser() {
  return client
    .get("/user/me")
    .then((response) => normalize(response.data, userSchema));
}

export function loadUserRequests({
  sort_order,
  sort_direction,
  offset,
  count,
  filters,
}) {
  const request = client
    .post("/list/user_request", {
      start: offset,
      count,
      sort_order,
      sort_direction,
      filters,
    })
    .then((response) => {
      const { entities, additional_entities, ...data } = response.data;

      const normalised = normalize(
        { ...additional_entities },
        {
          users: [userSchema],
          podcasts: [podcastSchema],
          episodes: [episodeSchema],
          creators: [creatorSchema],
          badges: [badgeSchema],
        }
      );

      return { result: fromJS(entities), ...normalised.entities, meta: data };
    });
  return request;
}

export function tellMeWhy({ prompt, title, id }) {
  return client
    .post("/my/user/prompts", {
      type: "campaign",
      prompt,
      title,
      id,
    })
    .then((response) => {
      return response.data;
    });
}

export function loadTellMeWhyPromptResponse({ id }) {
  return client
    .get(`/my/user/prompts/responses/${id}`)
    .then((response) => response.data);
}

export function loadTellMeWhyPromptList() {
  return client
    .get("/my/user/prompts?type=campaign")
    .then((response) => response.data);
}

export function deleteCampaign({ id }) {
  return client.delete(`/my/user/prompts/${id}`).then((response) => {
    return response.data;
  });
}

export function loadUsersFilterList({
  sort_order,
  sort_direction,
  offset,
  count,
  filters,
  suggestions,
  rescore,
  cancelToken,
  options,
  omit_results,
  total_hits,
}) {
  const request = client
    .post(
      "/list/user",
      {
        start: offset,
        count,
        sort_order,
        sort_direction,
        filters,
        suggestions,
        rescore,
        options,
        omit_results,
        total_hits,
      },
      { cancelToken: cancelToken.token }
    )
    .then((response) => {
      const { entities, additional_entities, ...data } = response.data;

      const { result, ...rest } = normalize(
        { users: entities, ...additional_entities },
        {
          users: new schema.Array(userSchema),
          badges: new schema.Array(badgeSchema),
        }
      );

      return { result: result.users, ...rest, meta: { ...data } };
    });

  request[CANCEL] = () => cancelToken.cancel();

  return request;
}

export function confirmFollowRequest({ id }) {
  return client.put(`/my/user/follow_requests/${id}`).then((response) => {
    const { additional_entities, follow_request } = response.data;

    const normalised = normalize(additional_entities, {
      users: new schema.Array(userSchema),
    });

    return { ...normalised, follow_request };
  });
}

export function ignoreFollowRequest({ id }) {
  return client.delete(`/my/user/follow_requests/${id}`).then((response) => {
    const { additional_entities, follow_request } = response.data;

    const normalised = normalize(additional_entities, {
      users: new schema.Array(userSchema),
    });

    return { ...normalised, follow_request };
  });
}

export function dismissEarnedBadge({ id }) {
  return client.post(`/my/badges/${id}/seen`).then((response) => {
    const { user_badge } = response;

    return { user_badge };
  });
}

export function sendDirectRecommendation({
  user_id,
  entity_type,
  entity_id,
  message,
  email,
}) {
  return client
    .post(`/recommend/${entity_type}`, {
      user_id,
      entity_type,
      entity_id,
      message,
      email,
    })
    .then((response) => response.data);
}

export function userRecommendationResponse({ id, response_text }) {
  return client
    .put(`/my/user/recommendations/${id}`, { response: response_text })
    .then((response) => {
      const normalised = normalize(response.data, userSchema);

      return normalised;
    });
}

export function deleteUserRecommendation({ id }) {
  return client.delete(`/my/user/recommendations/${id}`).then((response) => {
    const normalised = normalize(response.data, userSchema);

    return normalised;
  });
}
