import Loadable from "@loadable/component";
const GenericModal = Loadable(() => import("./GenericModal"));

function GenericModalAsyncLoading() {
  return null;
}

const GenericModalContainer = (props) => {
  return <GenericModal {...props} fallback={<GenericModalAsyncLoading />} />;
};

export default GenericModalContainer;
