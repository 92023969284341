import { Link } from "react-router-dom";

import batmanModeMobile from "./images/batman-mode-mobile.svg";
import batmanMode from "./images/batman-mode.svg";

import { useStyles } from "hooks/useStyles";

const baseStyles = {
  link: {
    display: "flex",
    alignItems: "center",
  },
};

const HeaderStandardBatmanLogo = (props) => {
  const { styles, css } = useStyles(baseStyles, props);
  return (
    <Link to="/" className={css(styles.link)}>
      <picture>
        <source
          media="(min-width: 640px)"
          srcSet={batmanMode}
          alt="Batman Mode"
          width={192}
          height={38}
        />
        <img src={batmanModeMobile} alt="Batman Mode" width={40} height={38} />
      </picture>
    </Link>
  );
};

export default HeaderStandardBatmanLogo;
