import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const NotificationsList = Loadable(() => import("./NotificationsList"));

function NotificationsListAsyncLoading(props) {
  return <LoadableLoading {...props} />;
}

const NotificationsListContainer = (props) => {
  return (
    <NotificationsList
      {...props}
      fallback={<NotificationsListAsyncLoading />}
    />
  );
};

export default NotificationsListContainer;
