import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const BlogAuthorView = Loadable(() => import("./BlogAuthorView"));

function BlogAuthorViewAsyncLoading(props) {
  return <LoadableLoading {...props} />;
}

const BlogAuthorViewContainer = (props) => {
  return (
    <BlogAuthorView {...props} fallback={<BlogAuthorViewAsyncLoading />} />
  );
};

export default BlogAuthorViewContainer;
