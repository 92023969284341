import modalActions from "actions/modals";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import { useStyles } from "hooks/useStyles";
import useUserHasPro from "hooks/useUserHasPro";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  cta: {
    ...gStyles.avalonBold,
    fontSize: 12,
    color: colours.primary,
    border: "none",
    marginBottom: "1.5rem",
    cursor: "pointer",
    marginLeft: "0.5rem",
  },
};

function UnlockChartsPast90DaysCTA(props) {
  const { styles, css } = useStyles(baseStyles, props);

  const { page, additionalEventTrackingProps } = props;

  const { showModal } = useActionCreators({
    showModal: modalActions.showModal,
  });

  const onDemoRequestSubmitted = () => {
    sendGAEvent({
      action: "submittedProLeadForm",
      context: "ProLeadForm",
      page,
      ...(additionalEventTrackingProps || {}),
    });
  };

  const handleCTAClick = () => {
    sendGAEvent({
      action: `openedProLeadForm`,
      context: "ProLeadForm",
      page,
      ...(additionalEventTrackingProps || {}),
    });
    showModal("proLeadForm", {
      onDemoRequestSubmitted: onDemoRequestSubmitted,
    });
  };

  const hasPro = useUserHasPro();

  if (!hasPro) {
    return (
      <button onClick={handleCTAClick} className={css(styles.cta)}>
        Unlock past 90 days with Podchaser Pro
      </button>
    );
  }

  return null;
}

export default UnlockChartsPast90DaysCTA;
