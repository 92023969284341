import {
  creatorSchema,
  creatorSubmissionSchema,
  creditSubmissionSchema,
  episodeCreditSchema,
  episodeSchema,
  episodesGroupSchema,
  groupedCreditSchema,
  podcastCreditSchema,
  podcastSchema,
  recurringCreditSchema,
  roleSchema,
  suggestedCreditSchema,
} from "api/schema/creator_podcast";
import { userSchema } from "api/schema/user";
import { List } from "immutable";
import { normalize } from "normalizr";
import qs from "qs";
import { CANCEL } from "redux-saga";
import uuid from "uuid/v4";

import client from "./client";

function getAdditional(response) {
  return Object.entries(response.data.additional_entities).reduce(
    (additional, [key, value]) => {
      if (key === "count") {
        return { ...additional, episodeCount: value };
      }
      if (typeof value === "object") {
        return { ...additional, [key]: Object.keys(value).length };
      }
      if (Array.isArray(value)) {
        return { ...additional, [key]: value.length };
      }

      return { ...additional, [key]: value };
    },
    {}
  );
}

export function loadSpecificCreator({ creator_id }) {
  return client
    .get(`/creators/${creator_id}/`)
    .then((response) => normalize(response.data, creatorSchema));
}

export function loadSpecificCreatorByPCID({ creator_id }) {
  return client
    .get(`/creators/pcid/${creator_id}/`)
    .then((response) => normalize(response.data, creatorSchema));
}

export function saveBio({ bio, axiosOptions, recaptchaToken }) {
  return client
    .post("creators", bio, axiosOptions, recaptchaToken)
    .then((response) => normalize(response.data.creator, creatorSchema));
}

export function updateBio({ bio, axiosOptions }) {
  return client
    .put(`creators/${bio.id}`, bio, axiosOptions)
    .then((response) => normalize(response.data, creatorSchema));
}

export function saveEntityCredit({ creator_id, credits }) {
  return client
    .put(`creators/${creator_id}/credits`, {
      credits: credits
        .map((c) =>
          c.updateIn(["roles"], List(), (roles) =>
            roles.map((r) => r.set("role", r.getIn(["role", "code"])))
          )
        )
        .toJS(),
    })
    .then((response) => {
      const normalized = normalize(response.data, {
        creator: creatorSchema,
        submissions: [creditSubmissionSchema],
      });
      return normalized;
    });
}

export function deleteEntityCredit({ creator_id, credit }) {
  const { id, entity_id, entity_type } = credit.toJS();

  return client
    .delete(
      `creators/${creator_id}/credits?${qs.stringify({
        credit: {
          id,
          entity_id,
          entity_type,
        },
      })}`
    )
    .then((response) => response.data);
}

export function getTwitterDetails({ twitter_handle, cancelToken }) {
  const request = client.get(
    `creators/twitter/search?${qs.stringify({ term: twitter_handle })}`
  );

  request[CANCEL] = () => cancelToken.cancel();

  return request;
}

export function loadCreditsFilterList({
  sort_order,
  sort_direction,
  offset,
  count,
  filters,
  groups,
  cancelToken,
  options,
}) {
  const request = client
    .post(
      "/list/credit",
      {
        start: offset,
        count,
        sort_order,
        sort_direction,
        filters,
        groups,
        options,
      },
      { cancelToken: cancelToken.token }
    )
    .then((response) => {
      const { entities, additional_entities, ...data } = response.data;

      entities.parentID = uuid();
      const normalised = normalize(
        {
          credits: groups.size > 0 ? [entities] : entities,
          ...additional_entities,
        },
        {
          credits: [
            groups.size > 0 ? groupedCreditSchema : episodeCreditSchema,
          ],
          podcasts: [podcastSchema],
          episodes: [episodeSchema],
          creators: [creatorSchema],
          roles: [roleSchema],
        }
      );

      const { result, ...rest } = normalised;

      return {
        result: result.credits,
        ...rest,
        meta: { ...data },
        additional: getAdditional(response),
      };
    });

  request[CANCEL] = () => cancelToken.cancel();

  return request;
}

export function loadCreditSubmissionFilterList({
  sort_order,
  sort_direction,
  offset,
  count,
  filters,
  cancelToken,
  options,
}) {
  const request = client
    .post(
      "/list/credit_submission",
      {
        start: offset,
        count,
        sort_order,
        sort_direction,
        filters,
        options,
      },
      { cancelToken: cancelToken.token }
    )
    .then((response) => {
      const { entities, additional_entities, ...data } = response.data;

      const { credit_submissions, ...restOfAdditional } = additional_entities;

      const normalised = normalize(
        {
          credit_submissions: { ...entities, ...credit_submissions },
          ...restOfAdditional,
        },
        {
          credit_submissions: [creditSubmissionSchema],
          podcasts: [podcastSchema],
          episodes: [episodeSchema],
          creators: [creatorSchema],
          roles: [roleSchema],
          users: [userSchema],
        }
      );

      const { result, ...rest } = normalised;

      return {
        result: result.credit_submissions,
        ...rest,
        meta: { ...data },
        additional: getAdditional(response),
      };
    });

  request[CANCEL] = () => cancelToken.cancel();

  return request;
}

export function loadCreatorsFilterList({
  sort_order,
  sort_direction,
  offset,
  count,
  filters,
  options,
  groups,
  cancelToken,
  rescore,
  omit_results,
  total_hits,
}) {
  const request = client
    .post(
      "/list/creator",
      {
        start: offset,
        count,
        sort_order,
        sort_direction,
        filters,
        options,
        groups,
        rescore,
        omit_results,
        total_hits,
      },
      { cancelToken: cancelToken.token }
    )
    .then((response) => {
      const {
        entities,
        additional_entities: { credits, ...moreAdditional },
        ...data
      } = response.data;

      const mappedCredits = credits
        ? Object.keys(credits).map((id) => ({
            parentID: `creator_to_approve-${id}`,
            credits: credits[id],
          }))
        : [];

      const normalised = normalize(
        {
          creators: entities,
          credits: mappedCredits,
          ...moreAdditional,
        },
        {
          creators: [creatorSchema],
          users: [userSchema],
          credits: [groupedCreditSchema],
          suggested_credits: [suggestedCreditSchema],
          podcasts: [podcastSchema],
          episodes: [episodeSchema],
          roles: [roleSchema],
        }
      );

      const { result, ...rest } = normalised;

      return {
        result: result.creators,
        ...rest,
        meta: { ...data },
        additional: getAdditional(response),
      };
    });

  request[CANCEL] = () => cancelToken.cancel();

  return request;
}

export function loadSuggestedCreditsList({
  sort_order,
  sort_direction,
  offset,
  count,
  filters,
  groups,
  cancelToken,
}) {
  const request = client
    .post(
      "/list/suggested_credit",
      {
        start: offset,
        count,
        sort_order,
        sort_direction,
        filters,
        groups,
      },
      { cancelToken: cancelToken.token }
    )
    .then((response) => {
      const {
        entities,
        additional_entities: { podcasts, episodes },
        ...data
      } = response.data;

      const normalised = normalize(
        {
          podcasts,
          episodes,
          credits: entities,
        },
        {
          podcasts: [podcastSchema],
          episodes: [episodeSchema],
          credits: [suggestedCreditSchema],
        }
      );

      const { result, ...rest } = normalised;

      return {
        meta: data,
        result: result.credits,
        ...rest,
      };
    });

  return request;
}

export function rejectSuggestedCredits({ suggested_credit_ids }) {
  return client
    .post("/creators/reject_suggested", {
      suggested_credit_ids,
    })
    .then((response) => response.data);
}

export function reindexCreator({ creator_id }) {
  return client
    .post(`/creators/${creator_id}/reindex`)
    .then((response) => response.data);
}

export function loadCreditSubmissions({ podcast_id, episode_id, creator_id }) {
  return client
    .get(
      `/my/credits/submissions?${qs.stringify({
        podcast_id,
        episode_id,
        creator_id,
      })}`
    )
    .then((response) => response.data);
}

export function deleteCreditSubmission({ creditSubmission }) {
  return client
    .delete(
      `/credits/submissions/${creditSubmission.get(
        "submission_type"
      )}/${creditSubmission.get("id")}`
    )
    .then((response) => response.data);
}

export function loadListOfCurrentCredits({
  cancelToken,
  page = 1,
  size = 10,
  creatorId,
}) {
  const request = client
    .get(
      `credit_editor/creator/${creatorId}/podcast_credits?${qs.stringify({
        size,
        page,
      })}`,
      { cancelToken: cancelToken.token }
    )
    .then((response) => {
      const { entities, additional_entities, ...data } = response.data;

      const normalised = normalize(
        {
          podcastCredits: entities,
          ...additional_entities,
        },
        {
          podcastCredits: [podcastCreditSchema],
          podcasts: [podcastSchema],
          roles: [roleSchema],
        }
      );

      const { result, ...rest } = normalised;

      return {
        result: result.podcastCredits,
        ...rest,
        meta: { ...data },
        additional: getAdditional(response),
      };
    });

  request[CANCEL] = () => cancelToken.cancel();

  return request;
}

export function loadListOfCurrentCreditsOfPodcast({
  cancelToken,
  with_episode_ids = true,
  creatorId,
  podcastId,
}) {
  const request = client
    .get(
      `credit_editor/creator/${creatorId}/podcast_credits/${podcastId}/?${qs.stringify(
        { with_episode_ids }
      )}`,
      { cancelToken: cancelToken.token }
    )
    .then((response) => {
      const { entities, additional_entities, ...data } = response.data;

      const normalised = normalize(
        {
          podcastCredits: entities,
          ...additional_entities,
        },
        {
          podcastCredits: [podcastCreditSchema],
          podcasts: [podcastSchema],
          roles: [roleSchema],
        }
      );

      const { result, ...rest } = normalised;

      return {
        result: result.podcastCredits,
        ...rest,
        meta: { ...data },
        additional: getAdditional(response),
      };
    });

  request[CANCEL] = () => cancelToken.cancel();

  return request;
}

export function loadCreatorRoles() {
  const request = client.get("credit_editor/roles").then((response) => {
    const normalised = normalize(
      {
        roles: response.data,
      },
      {
        roles: [roleSchema],
      }
    );

    const { result, ...rest } = normalised;

    return {
      result: result.roles,
      ...rest,
    };
  });

  return request;
}

export function loadListOfRecurringCredits({
  cancelToken,
  page = 1,
  size = 10,
  creatorId,
}) {
  const request = client
    .get(
      `credit_editor/creator/${creatorId}/recurring_credits?${qs.stringify({
        size,
        page,
      })}`,
      { cancelToken: cancelToken.token }
    )
    .then((response) => {
      const { entities, additional_entities, ...data } = response.data;

      const normalised = normalize(
        {
          recurringCredits: entities,
          ...additional_entities,
        },
        {
          recurringCredits: [recurringCreditSchema],
          podcasts: [podcastSchema],
          roles: [roleSchema],
          credit_submissions: [creditSubmissionSchema],
        }
      );

      const { result, ...rest } = normalised;

      return {
        result: result.recurringCredits,
        ...rest,
        meta: { ...data },
        additional: getAdditional(response),
      };
    });

  request[CANCEL] = () => cancelToken.cancel();

  return request;
}

export function loadListOfEpisodesGroups({
  cancelToken,
  sort = "SORT_ORDER_RECENT",
  sort_direction = "desc",
  podcastId,
  filters,
}) {
  const request = client
    .post(
      `credit_editor/episodes/${podcastId}`,
      {
        sort_order: sort,
        sort_direction,
        filters,
      },
      { cancelToken: cancelToken.token }
    )
    .then((response) => {
      const { entities, ...data } = response.data;

      const normalised = normalize(
        {
          episodesGroup: entities,
        },
        {
          episodesGroup: [episodesGroupSchema],
        }
      );

      const { result, ...rest } = normalised;

      return {
        result: result.episodesGroup,
        ...rest,
        meta: { ...data },
      };
    });

  request[CANCEL] = () => cancelToken.cancel();

  return request;
}

export function submitCredit({ cancelToken, submissions, cancelSubmissions }) {
  const request = client
    .post(
      "credit_editor/submit",
      {
        submissions,
        cancel_submissions: cancelSubmissions,
      },
      { cancelToken: cancelToken.token }
    )
    .then((response) => {
      const { credit_submissions, additional_entities, ...data } =
        response.data;

      const normalised = normalize(
        {
          credit_submissions,
          ...additional_entities,
        },
        {
          credit_submissions: [creditSubmissionSchema],
        }
      );

      const { result, ...rest } = normalised;

      return {
        ...data,
        ...rest,
        result: result.episodesGroup,
      };
    });

  request[CANCEL] = () => cancelToken.cancel();

  return request;
}

export function loadSpecificCreatorSubmission({ submissionId, cancelToken }) {
  const request = client
    .get(`/creators/submissions/${submissionId}`, {
      cancelToken: cancelToken.token,
    })
    .then((response) => {
      const { data } = response;

      const normalised = normalize(data, creatorSubmissionSchema);

      const { result, ...rest } = normalised;

      return {
        result,
        ...rest,
        meta: { ...data },
      };
    });

  request[CANCEL] = () => cancelToken.cancel();

  return request;
}
