import TagManager from "react-gtm-module";
import { useEffect } from "react";

const tagManagerArgs = {
  gtmId: "GTM-PLFP2NS",
};

const GoogleTagManagerScript = () => {
  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  });

  return null;
};

export default GoogleTagManagerScript;
