import PropTypes from "prop-types";
import { useCallback } from "react";
import { Link, Route } from "react-router-dom";

/**
 * A <Link> wrapper that knows if it's "active" or not.
 */
const NavLink = (props) => {
  const {
    to,
    exact,
    strict,
    location: passedLocation,
    activeClassName,
    className,
    activeStyle,
    style,
    isActive: getIsActive,
    children,
    activeChildren,
    path,
    ...rest
  } = props;

  const renderChildren = useCallback(
    ({ location, match }) => {
      const isActive = !!(getIsActive ? getIsActive(match, location) : match);

      /* TODO: Get it working the old way: <Link
     to={to}
     className={isActive ? [ className, activeClassName ].filter(i => i).join(' ') : className}
     style={isActive ? { ...style, ...activeStyle } : style}
     {...rest}
     /> */

      return (
        <Link
          to={to}
          className={isActive ? activeClassName : className}
          style={isActive ? activeStyle : style}
          {...rest}
        >
          {isActive && activeChildren ? activeChildren : children}
        </Link>
      );
    },
    [
      activeChildren,
      activeClassName,
      activeStyle,
      children,
      className,
      getIsActive,
      style,
      to,
      rest,
    ]
  );

  return (
    <Route
      path={path || (typeof to === "object" ? to.pathname : to)}
      exact={exact}
      strict={strict}
      location={passedLocation}
    >
      {renderChildren}
    </Route>
  );
};

NavLink.propTypes = {
  to: Link.propTypes.to,
  exact: PropTypes.bool,
  strict: PropTypes.bool,
  location: PropTypes.object,
  activeClassName: PropTypes.string,
  className: PropTypes.string,
  activeStyle: PropTypes.object,
  style: PropTypes.object,
  isActive: PropTypes.func,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.node,
  activeChildren: PropTypes.node,
};

NavLink.defaultProps = {
  activeClassName: "active",
  path: null,
  to: null,
  exact: false,
  strict: false,
  location: null,
  className: null,
  activeStyle: null,
  style: null,
  isActive: null,
  children: null,
  activeChildren: null,
};

export default NavLink;
