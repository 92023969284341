import PropTypes from "prop-types";
import { memo, useCallback } from "react";

import EntityImage from "components/Entities/EntityImage";
import entityList from "components/Entities/EntityList";

import HomepageSidebarSection from "./HomepageSidebarSection";

import * as sortConstants from "constants/sort";
import loadPodcastsList from "sagas/pagination/lists/loadPodcastsList";
import { selectSpecificPodcast } from "selectors/podcast";

import useWindowSize from "hooks/useWindowSize";

import ScreenSizes from "styles/ScreenSizes";

const entityImagePanelSmallStyles = {
  entityImage: {
    width: "calc(25% - .375rem)",
    marginBottom: ".5rem",
  },
};

const entityImagePanelLargeStyles = {
  entityImage: {
    width: "calc(50% - .25rem)",
    marginBottom: ".5rem",
  },
};

const entityImagePanelSmallFeedStyles = {
  entityImage: {
    width: "calc(50% - .375rem)",
    marginBottom: ".5rem",

    [ScreenSizes.mdAndAbove]: {
      width: "calc(25% - .25rem)",
    },
  },
};

const entityImagePanelLargeFeedStyles = {
  entityImage: {
    width: "calc(50% - .25rem)",
    marginBottom: ".5rem",

    [ScreenSizes.mdAndAbove]: {
      width: "calc(25% - .25rem)",
    },
  },
};

const PopularPodcastsList = entityList({
  key: "homepageView/popularPodcasts",
  sort: sortConstants.SORT_ORDER_RANKING,
  list_type: "podcasts_filtered",
  loadListAction: loadPodcastsList,
  entity_type: "podcast",
  pageSize: 16,
  staticFilters: {
    rank: {
      value: 50,
    },
  },
  rescore: { sort: sortConstants.SORT_ORDER_RANDOM, window: 100 },
  getEntity: (state, id) => selectSpecificPodcast(state, id),
});

const IMAGE_CACHE_OPTIONS = { variance: 0.2 };

const HomepageSidebarPodcasts = (props) => {
  const { inFeed, showLink, showExtra } = props;
  const { isWindowSizeOrLess } = useWindowSize();
  const mobile = isWindowSizeOrLess("large");

  const HEADER = {
    title: "Popular Podcasts",
    to: showLink ? "/podcasts" : null,
  };

  const renderPodcastPanel = useCallback(
    (podcast, index) => {
      const limit = showExtra ? 16 : 10;
      let imageStyles = inFeed
        ? entityImagePanelSmallFeedStyles
        : entityImagePanelSmallStyles;

      const isLarge = index < (showExtra ? 8 : 2);
      let size = isLarge ? 146 : 69;

      if (!inFeed && index >= limit) {
        return null;
      }
      if (isLarge) {
        imageStyles = inFeed
          ? entityImagePanelLargeFeedStyles
          : entityImagePanelLargeStyles;
      }
      if (mobile) {
        size = 256;
      }

      return (
        <EntityImage
          styles={imageStyles}
          entity={podcast}
          entity_type="podcast"
          size={size}
          imageCacheOptions={IMAGE_CACHE_OPTIONS}
        />
      );
    },
    [inFeed, mobile, showExtra]
  );

  return (
    <HomepageSidebarSection
      header={HEADER}
      showAsList
      TitleComponent="h3"
      inFeed={inFeed}
    >
      <PopularPodcastsList
        renderItem={renderPodcastPanel}
        inFeed={inFeed}
        showExtra={showExtra}
      />
    </HomepageSidebarSection>
  );
};

HomepageSidebarPodcasts.propTypes = {
  inFeed: PropTypes.bool,
  showLink: PropTypes.bool,
  showExtra: PropTypes.bool,
};

HomepageSidebarPodcasts.defaultProps = {
  inFeed: false,
  showLink: true,
  showExtra: false,
};

export default memo(HomepageSidebarPodcasts);
