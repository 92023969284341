import Loadable from "@loadable/component";
const ListImage = Loadable(() => import("./ListImage"));

function ListImageAsyncLoading() {
  return null;
}

const ListImageContainer = (props) => {
  return <ListImage {...props} fallback={<ListImageAsyncLoading />} />;
};

export default ListImageContainer;
