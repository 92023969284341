import { memo } from "react";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  sidebar: {
    ...gStyles.skeletonPlaceholder,
    borderRadius: 8,
    color: colours.grey,

    height: "375rem",
    width: "100%",
  },
};

const PodcastViewSidebarSkeleton = (props) => {
  const { styles, css } = useStyles(baseStyles, props);

  return <div className={css(styles.sidebar)} />;
};

export default memo(PodcastViewSidebarSkeleton);
