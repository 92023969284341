import Loadable from "@loadable/component";
const ClaimedBadge = Loadable(() => import("./ClaimedBadge"));

function ClaimedBadgeAsyncLoading() {
  return null;
}

const ClaimedBadgeContainer = (props) => {
  return <ClaimedBadge {...props} fallback={<ClaimedBadgeAsyncLoading />} />;
};

export default ClaimedBadgeContainer;
