import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import { memo, Fragment, useCallback } from "react";

import Col from "components/Common/Col";
import LoginButtonContainer from "components/Layout/Header/LoginButtonContainer";
import UserContainer from "components/Layout/Header/UserContainer";
import ProfileImage from "components/Layout/ProfileMenu/ProfileImage";
import ProfileMenu from "components/Layout/ProfileMenu/ProfileMenuAsync";

import HeaderNotifications from "./HeaderNotificationsAsync";

import authActions from "actions/auth";
import paginationActions from "actions/pagination";
import { selectAuthLoggingIn } from "selectors/auth";

import useActionCreators from "hooks/useActionCreators";
import useCookie from "hooks/useCookie";
import useLoggedInUser, { useLoggedIn } from "hooks/useLoggedInUser";
import useReduxState from "hooks/useReduxState";
import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import { showRegisterAbove, mobileHeaderPadding } from "styles/HeaderStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  userDetailsColumn: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",

    [ScreenSizes.smOnly]: {
      paddingRight: 0,
    },
    [ScreenSizes.mdAndBelow]: {
      textAlign: "right",
    },
    [ScreenSizes.lgOnly]: {
      paddingLeft: 0,
    },
  },
  userDetails: {
    lineHeight: 1,
    zIndex: 10,
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "flex-end",

    [ScreenSizes.mdAndBelow]: {
      paddingRight: mobileHeaderPadding - 10, // Minus normal column padding
      position: "relative",
      justifyContent: "inherit",
      right: -5,
    },

    [ScreenSizes.mdAndAbove]: {
      display: "none",
    },
  },
  buttons: {
    margin: 0,
    maxHeight: 50,
    alignSelf: "center",
    textAlign: "right",

    [ScreenSizes.lgAndAbove]: {
      display: "block",
    },
  },
  mobileLoginButton: {
    width: 32,
    height: 32,
    padding: 5,
    borderRadius: 32,
    background: "rgba(255,255,255,0.2)",
    border: "1px rgba(255,255,255,0.8) solid",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: colours.white,

    [ScreenSizes.mdAndAbove]: {
      width: 44,
      height: 44,
      borderRadius: 44,
    },
  },
};

const HeaderUser = (props) => {
  const isLoggedIn = useLoggedIn();
  const user = useLoggedInUser();

  const { startAuthFlow } = useActionCreators({
    startAuthFlow: authActions.startAuthFlow,
    replaceList: paginationActions.replaceList,
  });

  const { isWindowWidthOrMore } = useWindowSize();

  const showRegister = isWindowWidthOrMore(showRegisterAbove);

  const { styles } = useStyles(baseStyles, props);

  const isLoggingIn = useReduxState((state) => selectAuthLoggingIn(state), []);

  const hasCookie = useCookie();
  const showLoading = isLoggingIn || (!isLoggedIn && hasCookie);

  const handleMobileLoginClick = useCallback(
    () =>
      startAuthFlow(undefined, {
        analyticsVariables: {
          triggered_by: "mobileHeaderLoginButton",
        },
      }),
    [startAuthFlow]
  );

  return (
    <Col className={css(styles.userDetailsColumn)}>
      {(isLoggedIn || (!isLoggedIn && hasCookie)) && <HeaderNotifications />}
      {showLoading && <ProfileImage loading />}
      {!showLoading && (
        <Fragment>
          {!isLoggedIn && showRegister && (
            <div className={css(styles.buttons)}>
              <LoginButtonContainer />
            </div>
          )}
          {!isLoggedIn && !showRegister && (
            <FontAwesomeIcon
              data-id="header-login-button"
              icon={faUser}
              className={css(styles.mobileLoginButton)}
              onClick={handleMobileLoginClick}
            />
          )}
          {isLoggedIn && (
            <UserContainer key={user.get("id")}>
              {user ? (
                <ProfileMenu user={user} key={user.get("id")} />
              ) : (
                <ProfileImage loading />
              )}
            </UserContainer>
          )}
        </Fragment>
      )}
    </Col>
  );
};

export default memo(HeaderUser);
