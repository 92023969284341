import Loadable from "@loadable/component";

import TimelineSkeletonItem from "components/Timeline/TimelineSkeletonItem";

const RateActivity = Loadable(() =>
  import(/* webpackChunkName: "RateActivity" */ "./RateActivity")
);

function RateActivityLoading(props) {
  return <TimelineSkeletonItem />;
}

const RateActivityContainer = (props) => {
  return <RateActivity {...props} fallback={<RateActivityLoading />} />;
};

export default RateActivityContainer;
