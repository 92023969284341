import { Map } from "immutable";
import { createSelector } from "reselect";

export const selectAppErrorPage = (state) => state.app.getIn(["errorPage"]);
export const selectAllEntities = createSelector(
  (state) => state,
  (state) =>
    Map({
      userlists: state.userlist.getIn(["lists", "entities"]),
      podcasts: state.podcast.getIn(["podcasts", "entities"]),
      episodes: state.podcast.getIn(["episodes", "entities"]),
      categories: state.category.getIn(["categories", "entities"]),
      users: state.user.getIn(["users", "entities"]),
      creators: state.creator.getIn(["creators", "entities"]),
    })
);
