import { selectEntity } from "selectors/entity";

import useReduxState from "hooks/useReduxState";

export const useEntityFromTimeline = (passedCode) => {
  const [type, id] = splitObjectToEntityAndID(passedCode);

  const entity = useReduxState(
    (state) => selectEntity(state, id, type),
    [id, type]
  );

  return {
    type,
    entity,
  };
};

export const splitObjectToEntityAndID = (object) => {
  const code = object || "";
  if (typeof code !== "string") {
    return ["", ""];
  }
  const [type, id] = code.split(":");

  return [type, id];
};
