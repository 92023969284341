import * as ratingConstants from "constants/rating";

export default {
  openRatingModal: (props = {}) => ({
    type: ratingConstants.OPEN_ITEM_RATING_MODAL,
    entityAction: "rate",
    rating: props.initialRating,
    ...props,
  }),
  openEpisodeRatingModal: (episode_id, props = {}) => ({
    type: ratingConstants.OPEN_ITEM_RATING_MODAL,
    entity_type: "episode",
    entity_id: episode_id,
    entityAction: "rate",
    rating: props.initialRating,
    ...props,
  }),
  openPodcastRatingModal: (podcast_id, props = {}) => ({
    type: ratingConstants.OPEN_ITEM_RATING_MODAL,
    entity_type: "podcast",
    entity_id: podcast_id,
    entityAction: "rate",
    rating: props.initialRating,
    ...props,
  }),
};
