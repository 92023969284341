import Loadable from "@loadable/component";
import React from "react";
const BrandWatchlistInfoModal = Loadable(() =>
  import("./BrandWatchlistInfoModal")
);

function BrandWatchlistInfoModalAsyncLoading() {
  return null;
}

const BrandWatchlistInfoModalContainer = (props) => {
  return (
    <BrandWatchlistInfoModal
      {...props}
      fallback={<BrandWatchlistInfoModalAsyncLoading />}
    />
  );
};

export default BrandWatchlistInfoModalContainer;
