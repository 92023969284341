import Loadable from "@loadable/component";

const SendSlackFeedbackModal = Loadable(() =>
  import("./SendSlackFeedbackModal")
);

function SendSlackFeedbackModalAsyncLoading() {
  return null;
}

const SendSlackFeedbackModalContainer = (props) => {
  return (
    <SendSlackFeedbackModal
      {...props}
      fallback={<SendSlackFeedbackModalAsyncLoading />}
    />
  );
};

export default SendSlackFeedbackModalContainer;
