import PropTypes from "prop-types";
import { useCallback } from "react";

import Menu from "components/Common/FlexMenu/Menu";

import DropdownNavMenuItem from "./DropdownNavMenuItem";

export const defaultMenuStyles = {
  menuContainer: {
    borderRadius: 6,
    overflow: "hidden",
    minWidth: "9rem",
  },
  menuItem: {
    borderTop: "none",
    padding: ".5rem 1rem .5rem 1.5rem",
    margin: ".25rem 0",

    ":first-child": {
      marginTop: "1.25rem",
    },
    ":last-child": {
      marginBottom: "1.25rem",
    },
  },
};

const DropdownNavMenu = (props) => {
  const { menuStyles: passedMenuStyles, closeMenu } = props;

  const renderItem = useCallback(
    (dropdownProps, menuItemProps, index) => (
      <DropdownNavMenuItem
        key={menuItemProps.id}
        dropdownProps={dropdownProps}
        menuItemProps={menuItemProps}
        closeMenu={closeMenu}
        index={index}
      />
    ),
    [closeMenu]
  );

  return (
    <Menu
      {...props}
      styles={passedMenuStyles || defaultMenuStyles}
      renderMenuItem={renderItem}
    />
  );
};

DropdownNavMenu.propTypes = {
  closeMenu: PropTypes.func.isRequired,
  menuStyles: PropTypes.object,
};

DropdownNavMenu.defaultProp = {
  menuStyles: null,
};

export default DropdownNavMenu;
