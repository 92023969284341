import { faLandmark } from "@fortawesome/free-solid-svg-icons/faLandmark";
import { faMobileAlt } from "@fortawesome/free-solid-svg-icons/faMobileAlt";
import { faMusic } from "@fortawesome/free-solid-svg-icons/faMusic";
import { faLaugh } from "@fortawesome/pro-regular-svg-icons/faLaugh";
import { faBasketballBall } from "@fortawesome/pro-solid-svg-icons/faBasketballBall";
import { faBriefcase } from "@fortawesome/pro-solid-svg-icons/faBriefcase";
import { faKnifeKitchen } from "@fortawesome/pro-solid-svg-icons/faKnifeKitchen";
import { faNewspaper } from "@fortawesome/pro-solid-svg-icons/faNewspaper";
import { faTheaterMasks } from "@fortawesome/pro-solid-svg-icons/faTheaterMasks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import { fromJS } from "immutable";
import PropTypes from "prop-types";
import { memo, useMemo } from "react";
import { Link } from "react-router-dom";

import HomepageSidebarSection from "./HomepageSidebarSection";

import getCategoryText from "utils/entity/getCategoryText";
import getCategoryUrl from "utils/entity/getCategoryUrl";
import sendGAEvent from "utils/sendGAEvent";

import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  categoryPanel: {
    ...gStyles.gradientBackground,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: colours.white,
    borderRadius: 4,
    paddingBottom: 5,
    marginBottom: ".8rem",
    width: "calc(33% - .33rem)",
    height: "4.25rem",
    fontSize: ".625rem",

    ":hover": {
      opacity: 0.9,
    },
    ":focus": {
      opacity: 0.8,
    },
  },
  categoryPanelInFeed: {
    width: "calc(20% - .33rem)",

    [ScreenSizes.mdAndAbove]: {
      height: "auto",
      paddingTop: "1rem",
      paddingBottom: "1rem",
      fontSize: ".8125rem",
    },
  },
  categoryIcon: {
    fontSize: "2rem",
  },
  categoryTitle: {
    ...gStyles.avalonBold,
    display: "block",
    marginTop: ".5rem",
    textTransform: "uppercase",
  },
};

const POPULAR_CATEGORIES = [
  {
    text: "Business",
    slug: "business",
    icon: faBriefcase,
  },
  {
    text: "Comedy",
    slug: "comedy",
    icon: faLaugh,
  },
  {
    text: "Music",
    slug: "music",
    icon: faMusic,
  },
  {
    text: "Audio Drama",
    slug: "audio-drama",
    icon: faTheaterMasks,
  },
  {
    text: "True Crime",
    slug: "true-crime-category",
    icon: faKnifeKitchen,
  },
  {
    text: "Society",
    slug: "society",
    icon: faLandmark,
  },
  {
    text: "News",
    slug: "news",
    icon: faNewspaper,
  },
  {
    text: "Technology",
    slug: "technology",
    icon: faMobileAlt,
  },
  {
    text: "Sports",
    slug: "sports",
    icon: faBasketballBall,
  },
];

const HEADER = {
  title: "Top Podcast Categories",
  to: "/categories",
};

const HomepageSidebarCategories = (props) => {
  const { inFeed } = props;
  const { styles } = useStyles(baseStyles, props);
  const { isWindowSizeOrLess } = useWindowSize();
  const mobile = isWindowSizeOrLess("large");

  const categoriesToRender = useMemo(
    () =>
      fromJS(
        [
          ...POPULAR_CATEGORIES,
          ...(inFeed && !mobile
            ? [
                {
                  text: "Other",
                  slug: "other1",
                  icon: faBasketballBall,
                },
              ]
            : []),
        ].filter((i) => !!i)
      ),
    [inFeed, mobile]
  );

  const renderCategoryPanel = (category) => (
    <Link
      key={category.get("id") || category.get("slug")}
      className={css(
        styles.categoryPanel,
        !mobile && inFeed && styles.categoryPanelInFeed
      )}
      to={getCategoryUrl(category)}
      title={`${getCategoryText(category, { singular: true })} Podcasts`}
      onClick={() => {
        sendGAEvent({
          action: "topPodcastCategoryClick",
          context: "HomePage",
          categorySlug: category.get("slug"),
        });
      }}
    >
      <FontAwesomeIcon
        icon={category.get("icon").toJS()}
        className={css(styles.categoryIcon)}
      />
      <span className={css(styles.categoryTitle)}>
        {getCategoryText(category)}
      </span>
    </Link>
  );

  return (
    <HomepageSidebarSection
      header={HEADER}
      showAsList
      TitleComponent="h3"
      inFeed={inFeed}
    >
      {categoriesToRender.map((c) => renderCategoryPanel(c))}
    </HomepageSidebarSection>
  );
};

HomepageSidebarCategories.propTypes = {
  inFeed: PropTypes.bool,
};

HomepageSidebarCategories.defaultProps = {
  inFeed: false,
};

export default memo(HomepageSidebarCategories);
