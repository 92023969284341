import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

const ChartsHubPage = Loadable(() => import("./ChartsHubPage"));

function ChartsHubPageAsync() {
  return <LoadingOverlay height={"231.25rem"} />;
}

const ChartsHubPageContainer = (props) => {
  return <ChartsHubPage {...props} fallback={<ChartsHubPageAsync />} />;
};

export default ChartsHubPageContainer;
