export const isPhone = (userAgent = "") =>
  (userAgent.match(/iPhone/i) && !userAgent.match(/iPad/i)) ||
  (userAgent.match(/Android/i) && userAgent.match(/Mobile/i)) ||
  (userAgent.match(/Android/i) && userAgent.match(/Mobile/i)) ||
  userAgent.match(/Windows Phone/i) ||
  userAgent.match(/Windows Phone/i) ||
  userAgent.match(/BB10/i) ||
  userAgent.match(/BB10/i) ||
  userAgent.match(/BlackBerry/i);

export const isBeingViewedOnMobile = () =>
  window &&
  window.navigator &&
  window.navigator.userAgent &&
  isPhone(window.navigator.userAgent);

export function getEstimatedSizeFromServerUserAgent(userAgent = "") {
  if (isPhone(userAgent)) {
    return {
      sizeString: "small",
      dimensions: { width: 360 },
    };
  }
  return {
    sizeString: "xxlarge",
    dimensions: { width: 1600 },
  };
}
