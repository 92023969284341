import { useCallback, useEffect, useRef } from "react";

import MenuItemWithSubMenu from "../../Lists/FilterSelect/Filters/components/MenuItemWithSubMenu";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  option: {
    border: "none",
    outline: "none",
    ...gStyles.fontMedium,
    color: colours.black,
    fontSize: "0.75rem",
    minHeight: "1.5rem",
    textAlign: "start",
    borderTop: `1px solid ${colours.white}`,

    ":hover": {
      cursor: "pointer",
      background: colours.borderGrey,
      borderRadius: 4,
      height: "100%",
    },
  },
  active: {
    ...gStyles.fontBold,
    color: colours.darkishBlue,
    background: colours.selectedLightBlue,
    borderRadius: 4,
    display: "grid",
    alignItems: "center",
    height: "100%",
    paddingLeft: 4,
  },
  listContainer: {
    display: "flex",
    flexDirection: "column",
    maxHeight: 150,
    overflow: "auto",
  },
};

export default function SingleSelectionList(props) {
  const { styles, css } = useStyles(baseStyles, props);
  const { toggleButton, data, selected, handleClick, isSearching } = props;

  const selectedRef = useRef();
  const ContainerRef = useRef();
  const handleSubAction = useCallback(
    (parentId, childId, closer) => {
      handleClick(`${parentId}/${childId}`, closer);
    },
    [handleClick]
  );

  const handleMainAction = useCallback(
    (parentId, closer) => {
      handleClick(parentId, closer);
    },
    [handleClick]
  );

  useEffect(() => {
    if (selectedRef.current) {
      selectedRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  }, []);

  return (
    <div className={css(styles.listContainer)} ref={ContainerRef}>
      {data?.map((option) => {
        const item =
          option?.get("text") || option?.get("label") || option?.get("value");

        const subOptions = option?.get("subOptions");

        const active =
          selected?.toLowerCase() === option?.get("value")?.toLowerCase() ||
          selected?.toLowerCase() === option?.get("id")?.toLowerCase();

        return subOptions?.size > 0 ? (
          <MenuItemWithSubMenu
            key={option?.get("id")}
            parentOption={option}
            item={item}
            subOptions={subOptions}
            handleMainAction={handleMainAction}
            handleSubAction={handleSubAction}
            toggleButton={toggleButton}
            selected={selected}
            isSearching={isSearching}
            passedRef={selectedRef}
          />
        ) : (
          <button
            ref={active ? selectedRef : null}
            key={option?.get("id")}
            onClick={() => handleMainAction(option?.get("id"), toggleButton)}
            className={css(styles.option, active && styles.active)}
          >
            {item}
          </button>
        );
      })}
    </div>
  );
}
