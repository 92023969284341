/* eslint-disable max-len */

export default {
  prefix: "fpc",
  iconName: "pc-list-small",
  icon: [
    10,
    9,
    [],
    null,
    "M8,8.0992928 L8,8.5043081 L7,8.38272753 L7,0.621833995 L8,0.500253418 L8,1.21023333 L8,8.0992928 Z M9,7.79914075 L9,1.10537008 L10,1.00050684 L10,7.904004 L9,7.79914075 Z M6,8.26114695 L6,8.90451084 L0,8.22821888 L0,0.676291962 L6,-7.4162898e-14 L6,0.743414573 L6,8.26114695 Z",
  ],
};
