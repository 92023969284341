import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const AudioPlayerContainer = Loadable(() => import("./AudioPlayerContainer"));

function AudioPlayerContainerAsyncLoading(props) {
  return <LoadableLoading {...props} />;
}

const AudioPlayerContainerContainer = (props) => {
  return (
    <AudioPlayerContainer
      {...props}
      fallback={<AudioPlayerContainerAsyncLoading />}
    />
  );
};

export default AudioPlayerContainerContainer;
