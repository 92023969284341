import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import PropTypes from "prop-types";
import { useCallback } from "react";
import { Link } from "react-router-dom";

import sendGAEvent from "utils/sendGAEvent";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  profileSubtitleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    position: "relative",
  },
  profileSubtitle: {
    ...gStyles.avalonBold,
    fontSize: "1rem",
    color: colours.bodyText,
  },
  profileSubtitleIcon: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: 23,
    height: 23,
    borderRadius: "50%",
    background:
      "linear-gradient(-225deg, rgb(39, 73, 153) 0%, rgb(151, 46, 138) 100%) rgb(34, 50, 91)",
    color: colours.white,
    marginRight: ".625rem",
    fontSize: ".75rem",
  },
  profileSubtitleImage: {
    background: "transparent",
    marginRight: ".75rem",
  },
  viewAll: {
    ...gStyles.avalonLight,
    fontSize: "1em",
    margin: "0 0 0 auto",
    padding: 0,
    ":focus": {
      textDecoration: "underline",
    },
  },
};

const ProfileSubtitle = (props) => {
  const { icon, children, viewAll, title, image } = props;
  const { styles } = useStyles(baseStyles, props);

  const handleViewAllClick = useCallback(() => {
    sendGAEvent({ action: "sidebarViewAllClick", panelTitle: title });
  }, [title]);

  const renderIcon = (image) => (
    <div
      className={css(
        styles.profileSubtitleIcon,
        image && styles.profileSubtitleImage
      )}
    >
      {image || <FontAwesomeIcon icon={icon} />}
    </div>
  );

  if (icon && !children) {
    return renderIcon();
  }

  return (
    <div className={css(styles.profileSubtitleContainer)}>
      {icon && renderIcon()}
      {image && renderIcon(image)}
      <div className={css(styles.profileSubtitle)}>{children}</div>
      {viewAll && (
        <Link
          to={viewAll}
          className={css(styles.viewAll)}
          onClick={handleViewAllClick}
        >
          View All
        </Link>
      )}
    </div>
  );
};

ProfileSubtitle.propTypes = {
  icon: PropTypes.object,
  children: PropTypes.node,
  viewAll: PropTypes.string,
  title: PropTypes.node,
  image: PropTypes.node,
};

ProfileSubtitle.defaultProps = {
  icon: null,
  children: null,
  viewAll: null,
  title: null,
  image: null,
};

export default ProfileSubtitle;
