import Loadable from "@loadable/component";
const InfoIcons = Loadable(() => import("./InfoIcons"));

function InfoIconsAsyncLoading() {
  return null;
}

const InfoIconsContainer = (props) => {
  return <InfoIcons {...props} fallback={<InfoIconsAsyncLoading />} />;
};

export default InfoIconsContainer;
