import { css } from "aphrodite";
import PropTypes from "prop-types";

import { useStyles } from "hooks/useStyles";

const baseStyles = {
  notificationsPlacement: {
    position: "fixed",
    zIndex: 9999,
  },
  placementTop: {
    top: 0,
  },
  placementBottom: {
    bottom: 0,
  },
  placementLeft: {
    left: 0,
  },
  placementRight: {
    right: 0,
  },
};

const NotificationsPlacement = (props) => {
  const { children, placement } = props;
  const { styles } = useStyles(baseStyles, props);

  return (
    <div
      className={css(
        styles.notificationsPlacement,
        placement.includes("top") && styles.placementTop,
        placement.includes("bottom") && styles.placementBottom,
        placement.includes("left") && styles.placementLeft,
        placement.includes("right") && styles.placementRight
      )}
    >
      {children}
    </div>
  );
};

NotificationsPlacement.propTypes = {
  children: PropTypes.node,
  placement: PropTypes.string,
};
NotificationsPlacement.defaultProps = {
  children: null,
  placement: "bottom-right",
};

export default NotificationsPlacement;
