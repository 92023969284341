import getCreatorSlug from "./getCreatorSlug";

import { formatURL } from "utils/format";

export default (creator, tab = null) => {
  const creatorUrl = `/creators/${getCreatorSlug(creator)}${
    tab ? `/${tab}` : ""
  }`;

  return formatURL(creatorUrl);
};
