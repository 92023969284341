import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const CreatorWizardAsync = Loadable(() =>
  import("components/Creator/CreatorWizard/CreatorWizard")
);

function CreatorWizardAsyncLoading(props) {
  return <LoadableLoading {...props} page />;
}

const CreatorWizardAsyncContainer = (props) => {
  return (
    <CreatorWizardAsync {...props} fallback={<CreatorWizardAsyncLoading />} />
  );
};

export default CreatorWizardAsyncContainer;
