import Loadable from "@loadable/component";
const NetworkImage = Loadable(() => import("./NetworkImage"));

function NetworkImageAsyncLoading() {
  return null;
}

const NetworkImageContainer = (props) => {
  return <NetworkImage {...props} fallback={<NetworkImageAsyncLoading />} />;
};

export default NetworkImageContainer;
