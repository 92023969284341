import PropTypes from "prop-types";
import { Fragment, useContext } from "react";

import RequestContext from "pages/RequestContext";

import GoogleLoader from "./GoogleLoader";
import GoogleOneTapLoader from "./GoogleOneTapLoader";
import GoogleTagManagerScript from "./GoogleTagManagerScript";
import IntercomLoader from "./IntercomLoader";
import UseJimoLoader from "./UseJimoLoader";

import { isHydrateRender } from "utils/misc";

import useCookieConsent from "hooks/useCookieConsent";

const ScriptLoader = ({ nonce }) => {
  const hasPerformanceConsent = useCookieConsent("C0002");
  const hasTargetingConsent = useCookieConsent("C0004");

  return (
    <Fragment>
      <GoogleTagManagerScript />
      {hasPerformanceConsent && <GoogleOneTapLoader />}
      {hasPerformanceConsent && <GoogleLoader />}
      {hasTargetingConsent && <IntercomLoader />}
      {hasPerformanceConsent && <UseJimoLoader />}
    </Fragment>
  );
};

ScriptLoader.propTypes = {
  nonce: PropTypes.string,
};

ScriptLoader.defaultProps = {
  nonce: null,
};

export default ScriptLoader;
