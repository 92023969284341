import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const AccountHubContainer = Loadable(() => import("./AccountHubContainer"));

function AccountHubContainerAsyncLoading(props) {
  return <LoadableLoading {...props} page />;
}

const AccountHubContainerContainer = (props) => {
  return (
    <AccountHubContainer
      {...props}
      fallback={<AccountHubContainerAsyncLoading />}
    />
  );
};

export default AccountHubContainerContainer;
