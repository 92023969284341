import Loadable from "@loadable/component";

const BaseCardAction = Loadable(() =>
  import(/* webpackChunkName: "BaseCardAction" */ "./BaseCardAction")
);

function BaseCardActionLoading() {
  return null;
}

const BaseCardActionContainer = (props) => {
  return <BaseCardAction {...props} fallback={<BaseCardActionLoading />} />;
};

export default BaseCardActionContainer;
