import PropTypes from "prop-types";
import { createContext, useCallback, useContext, useMemo, useRef } from "react";

import { StreamFeedContext } from "components/Analytics/StreamFeedProvider";

export const TimelineContext = createContext();

function TimelineProvider(props) {
  const { children, feedID, timelinePage, myFeed, pageType } = props;

  const { registerImpression: streamRegisterImpression } =
    useContext(StreamFeedContext);

  const sendImpressionTimer = useRef(null);
  const impressionBuffer = useRef([]);

  const registerImpression = useCallback(
    (foreignID) => {
      if (sendImpressionTimer.current) {
        clearTimeout(sendImpressionTimer.current);
      }
      impressionBuffer.current.push(foreignID);
      sendImpressionTimer.current = setTimeout(() => {
        const content_list = impressionBuffer.current;
        impressionBuffer.current = [];
        streamRegisterImpression({
          content_list,
          feed_id: feedID,
          location: timelinePage,
        });
      }, 3000);
    },
    [feedID, streamRegisterImpression, timelinePage]
  );

  const context = useMemo(
    () => ({
      registerImpression,
      feed_id: feedID,
      myFeed,
      feed_type: myFeed ? "my-feed" : "popular-feed",
      pageType,
    }),
    [registerImpression, feedID, myFeed, pageType]
  );

  return (
    <TimelineContext.Provider value={context}>
      {children}
    </TimelineContext.Provider>
  );
}

TimelineProvider.propTypes = {
  feedID: PropTypes.string.isRequired,
  timelinePage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node.isRequired,
  pageType: PropTypes.string.isRequired,
  myFeed: PropTypes.bool,
};

TimelineProvider.defaultProps = {
  myFeed: false,
};

export default TimelineProvider;
