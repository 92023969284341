import FooterColumn from "./FooterColumn";

const CompanyFooterLinks = () => {
  const companyLinks = [
    { title: "Company" },
    { title: "About", to: "/about" },
    { title: "Integrations", to: "/integrations" },
    { title: "Careers", to: "/articles/jobs" },
    { title: "Our Values", to: "/articles/inside-podchaser/our-values" },
  ];

  return <FooterColumn links={companyLinks} />;
};

export default CompanyFooterLinks;
