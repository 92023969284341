import { fromJS } from "immutable";

export const PAGINATION_INIT_LIST = "PAGINATION_INIT_LIST";
export const PAGINATION_INIT_LIST_CREATE = "PAGINATION_INIT_LIST_CREATE";
export const PAGINATION_UPDATE_LIST = "PAGINATION_UPDATE_LIST";
export const PAGINATION_NEXT_PAGE_REQUEST = "PAGINATION_NEXT_PAGE_REQUEST";
export const PAGINATION_NEXT_PAGE_LOADING = "PAGINATION_NEXT_PAGE_LOADING";
export const PAGINATION_NEXT_PAGE_SUCCESS = "PAGINATION_NEXT_PAGE_SUCCESS";
export const PAGINATION_NEXT_PAGE_FAILURE = "PAGINATION_NEXT_PAGE_FAILURE";
export const PAGINATION_SET_SORT_REQUEST = "PAGINATION_SET_SORT_REQUEST";
export const PAGINATION_SET_GROUPS_REQUEST = "PAGINATION_SET_GROUPS_REQUEST";
export const LIST_GET_FILTERS_REQUEST = "LIST_GET_FILTERS_REQUEST";
export const LIST_GET_FILTERS_LOADING = "LIST_GET_FILTERS_LOADING";
export const LIST_GET_FILTERS_SUCCESS = "LIST_GET_FILTERS_SUCCESS";
export const LIST_GET_FILTERS_FAILURE = "LIST_GET_FILTERS_FAILURE";

export const LIST_APPLY_FILTERS = "LIST_APPLY_FILTERS";
export const PAGINATION_GET_TOTAL_REQUEST = "PAGINATION_GET_TOTAL_REQUEST";
export const PAGINATION_GET_TOTAL_SUCCESS = "PAGINATION_GET_TOTAL_SUCCESS";

export const LIST_APPLY_CONFIG = "LIST_APPLY_CONFIG";
export const LIST_ADD_FILTERS = "LIST_ADD_FILTERS";
export const LIST_REMOVE_FILTER = "LIST_REMOVE_FILTER";
export const LIST_CLEAR_AVAILABLE_FILTERS = "LIST_CLEAR_AVAILABLE_FILTERS";
export const LIST_SET_SEARCH_TERM = "LIST_SET_SEARCH_TERM";
export const LIST_UPDATE = "LIST_UPDATE";
export const LIST_UPDATE_TOTAL = "LIST_UPDATE_TOTAL";
export const REMOVE_LIST_SORT_IDS = "REMOVE_LIST_SORT_IDS";
export const REMOVE_ID_FROM_LIST = "REMOVE_ID_FROM_LIST";
export const SAVE_ALL_FILTERS_FOR_TYPE = "SAVE_ALL_FILTERS_FOR_TYPE";

export const HOME_EPISODES_KEY = "home/episodes";
export const HOME_PODCASTS_KEY = "home/podcasts";
export const HOME_NETWORKS_KEY = "home/networks";

export const SEARCH_EPISODES_KEY = "search/episodes";
export const SEARCH_PODCASTS_KEY = "search/podcasts";
export const SEARCH_CATEGORIES_KEY = "search/categories";

export const LOAD_CATEGORIES_LIST = "LOAD_CATEGORIES_LIST";
export const LOAD_CREATORS_LIST = "LOAD_CREATORS_LIST";
export const LOAD_CREDITS_LIST = "LOAD_CREDITS_LIST";
export const LOAD_EPISODES_LIST = "LOAD_EPISODES_LIST";
export const LOAD_INTEGRATIONS_LIST = "LOAD_INTEGRATIONS_LIST";
export const LOAD_MERCH_LIST = "LOAD_MERCH_LIST";
export const LOAD_MODERATION_TASKS_LIST = "LOAD_MODERATION_TASKS_LIST";
export const LOAD_PODCASTS_LIST = "LOAD_PODCASTS_LIST";
export const LOAD_CONTACTS_LIST = "LOAD_CONTACTS_LIST";

export const LOAD_POSTS_LIST = "LOAD_POSTS_LIST";
export const LOAD_REVIEWS_LIST = "LOAD_REVIEWS_LIST";
export const LOAD_SEARCH_RESULTS_LIST = "LOAD_SEARCH_RESULTS_LIST";
export const LOAD_SUGGESTED_CREDITS_LIST = "LOAD_SUGGESTED_CREDITS_LIST";
export const LOAD_USER_LISTS_LIST = "LOAD_USER_LISTS_LIST";
export const LOAD_USER_REQUESTS_LIST = "LOAD_USER_REQUESTS_LIST";
export const LOAD_USERS_LIST = "LOAD_USERS_LIST";

export const ALL_LOCATIONS = fromJS({
  key: "all_locations",
  title: "Location",
  type: "mega_multiselect",
  pro: true,
  beta: true,
  internal_only: false,
  options: [],
  info: {
    paragraph:
      "Our estimate of the top locations where the podcast's audience resides. Filter thresholds are 25% for country and state/province, and 15% for DMA.",
    infoCTA: "demographics",
    ctaUrl:
      "https://support.podchaser.com/en/articles/8672076-demographics-and-reach",
  },
});

export const FiltersByEntityType = {
  podcast: {
    number_of_episodes: {
      key: "number_of_episodes",
      title: "Episode Count",
      type: "multiselect",
      pro: false,
      beta: false,
      internal_only: false,
      options: [],
    },
    claimed_podcast: {
      key: "claimed_podcast",
      title: "Claimed",
      type: "dropdown",
      beta: false,
      internal_only: true,
      pro: false,
      options: [],
    },
    podcast_status: {
      key: "podcast_status",
      title: "Active Status",
      type: "dropdown",
      pro: false,
      beta: false,
      internal_only: false,
      options: [],
      info: {
        title: "Active Status",
        paragraph:
          "Podcasts are considered active if they've released an episode within the last 60 days.",
      },
    },
    podcast_last_n_days: {
      key: "podcast_last_n_days",
      title: "Date of Release",
      pro: false,
      beta: false,
      internal_only: false,
      prefix: "Last",
      suffix: "Days",
      type: "number",
      default: 30,
      maximum: 12509,
    },
    language: {
      key: "language",
      title: "Language",
      type: "searchable_multiselect",
      pro: false,
      beta: false,
      internal_only: false,
      options: [],
    },
    date_of_first_episode: {
      key: "date_of_first_episode",
      title: "Date of First Episode",
      pro: false,
      beta: false,
      internal_only: false,
      type: "date_range",
      min: null,
      max: null,
    },
    hosting_provider: {
      key: "hosting_provider",
      title: "Hosting Provider",
      type: "searchable_multiselect",
      pro: false,
      beta: false,
      internal_only: false,
      options: [],
    },
    income_tier: {
      key: "income_tier",
      title: "Income",
      type: "multiselect",
      pro: true,
      beta: false,
      internal_only: false,
      options: [],
      info: {
        paragraph:
          "Our estimate of how much the average listener of this podcast earns (more green dollar signs means higher income), as determined on predictive models. Please contact the podcast for precise demographics.",
        infoCTA: "demographics",
        ctaUrl:
          "https://support.podchaser.com/en/articles/8672076-demographics-and-reach",
      },
    },
    political_lean: {
      key: "political_lean",
      title: "Political Skew",
      type: "multiselect",
      pro: true,
      beta: false,
      internal_only: false,
      political_permission: true,
      options: [],
      info: {
        paragraph:
          "Our estimate of the political leaning of a podcast’s content, as determined by predictive models.",
        infoCTA: "Political Skews",
        ctaUrl:
          "https://support.podchaser.com/en/articles/10030080-political-skew-insights",
      },
    },
    industry: {
      key: "industry",
      title: "Sponsor Industry",
      type: "searchable_multiselect",
      pro: false,
      beta: true,
      internal_only: true,
      options: [],
      info: {
        paragraph:
          "We track the top 5,000 podcasts for sponsor data. Each brand is categorized by its industry. This filter is applicable to the top 5,000 podcasts only.",
      },
    },
    network: {
      key: "network",
      title: "Network",
      type: "searchable_multiselect",
      pro: false,
      beta: false,
      internal_only: false,
      options: [],
      info: {
        paragraph:
          "Our prediction of a podcast's current network affiliation, based on publicly available data. The results show public podcasts listed on Podchaser. For private feeds or full catalog information, please contact the network directly.",
      },
    },
    has_host_credits: {
      key: "has_host_credits",
      title: "Host Credits",
      type: "dropdown",
      beta: false,
      internal_only: true,
      pro: false,
      options: [],
    },
    date_of_latest_episode: {
      key: "date_of_latest_episode",
      title: "Date of Last Episode",
      pro: false,
      beta: false,
      internal_only: false,
      type: "date_range",
      min: null,
      max: null,
    },
    gender_bias: {
      key: "gender_bias",
      title: "Gender",
      type: "multiselect",
      pro: true,
      beta: false,
      internal_only: false,
      options: [],
      info: {
        paragraph:
          "Our estimate of the gender distribution (male-female skew) of a podcast's audience, as determined by predictive models. Please contact the podcast for precise demographics.",
        infoCTA: "demographic",
        ctaUrl:
          "https://support.podchaser.com/en/articles/8672076-demographics-and-reach",
      },
    },
    rating: {
      key: "rating",
      title: "Rating",
      type: "dropdown",
      pro: false,
      beta: false,
      internal_only: false,
      options: [
        {
          id: "all",
          title: "Any Rating",
          value: "all",
        },
        {
          value: 5,
          title: "",
        },
        {
          value: 4,
          title: "and up",
        },
        {
          value: 3,
          title: "and up",
        },
        {
          value: 2,
          title: "and up",
        },
        {
          value: 1,
          title: "and up",
        },
      ],
    },
    has_guests: {
      key: "has_guests",
      title: "Has Guests",
      type: "dropdown",
      beta: false,
      internal_only: false,
      pro: true,
      options: [],
      info: {
        paragraph:
          "Our predictive indicator for determining the likelihood of guest appearances on a podcast, based on credits and episode descriptions.",
      },
    },
    top_countries: {
      key: "top_countries",
      title: "Country",
      type: "multiselect",
      pro: true,
      beta: true,
      internal_only: false,
      options: [],
      info: {
        paragraph:
          "Our estimate of the top locations where the podcast's audience resides. Filter thresholds are 25% for country and state/province, and 15% for DMA.",
        infoCTA: "demographics",
        ctaUrl:
          "https://support.podchaser.com/en/articles/8672076-demographics-and-reach",
      },
    },
    average_episode_length: {
      key: "average_episode_length",
      title: "Episode Length",
      type: "multiselect",
      pro: false,
      beta: false,
      internal_only: false,
      options: [],
    },
    brand_safety: {
      key: "brand_safety",
      title: "Brand Safety & Suitability",
      type: "multiselect",
      pro: true,
      beta: false,
      internal_only: false,
      sponsors_permission: true,
      options: [],
      disclaimer: "Select to exclude high-risk podcasts.",
      info: {
        paragraph:
          "Requires add-on. We analyze episode transcripts to assign risk scores for content categories. This filter is applicable to the top 5,000 podcasts only.",
        infoCTA: "Brand Safety & Suitability",
        ctaUrl:
          "https://support.podchaser.com/en/articles/8672076-demographics-and-reach",
      },
    },
    listener_age_range: {
      key: "listener_age_range",
      title: "Age",
      type: "multiselect",
      pro: true,
      beta: false,
      internal_only: false,
      options: [],
      info: {
        paragraph:
          "Our estimate of how old the average listener of this podcast is, as determined by predictive models. Please contact the podcast for precise demographics.",
        infoCTA: "demographics",
        ctaUrl:
          "https://support.podchaser.com/en/articles/8672076-demographics-and-reach",
      },
    },
    episode_frequency: {
      key: "episode_frequency",
      title: "Release Schedule",
      type: "multiselect",
      pro: false,
      beta: false,
      internal_only: false,
      options: [],
    },
    power_score: {
      key: "power_score",
      title: "Power Score™",
      type: "multiselect",
      pro: true,
      beta: false,
      internal_only: false,
      options: [],
      info: {
        paragraph:
          "Our metric indicating this podcast's overall popularity and influence, based on Podchaser’s unique algorithm that looks at 30+ different data points.",
        infoCTA: "Power Score™",
        ctaUrl:
          "https://support.podchaser.com/en/articles/8672076-demographics-and-reach",
      },
    },
    estimated_episode_reach: {
      key: "estimated_episode_reach",
      title: "Reach (Episode Average)",
      type: "text_range",
      pro: true,
      beta: false,
      internal_only: false,
      min: null,
      max: null,
    },
    explicit_podcast: {
      key: "explicit_podcast",
      title: "Explicit",
      type: "dropdown",
      beta: false,
      internal_only: false,
      pro: false,
      options: [],
      info: {
        paragraph:
          "Podcasters self-identify whether their podcast is explicit or clean in their RSS feed. This field is not moderated by Podchaser, so we cannot guarantee accuracy.",
      },
    },
    top_races: {
      key: "top_races",
      title: "Ethnicity",
      type: "multiselect",
      pro: true,
      beta: true,
      internal_only: false,
      options: [],
      info: {
        paragraph:
          "Our estimate of the podcast audience's ethnicity. Filter threshold is 25%.",
        infoCTA: "demographics",
        ctaUrl:
          "https://support.podchaser.com/en/articles/8672076-demographics-and-reach",
      },
    },
    category: {
      key: "category",
      title: "Category",
      type: "searchable_multiselect",
      pro: false,
      beta: false,
      internal_only: false,
      options: [],
    },
    verified_partner_status: {
      key: "verified_partner_status",
      title: "Verified Partner",
      type: "dropdown",
      pro: true,
      beta: false,
      internal_only: false,
      option: [],
      info: {
        paragraph:
          "Podcasts marked as verified partners have had their podcast level reach indepently verified by Podchaser's partner verification program.",
      },
    },
    top_dmas: {
      key: "top_dmas",
      title: "DMA",
      type: "multiselect",
      pro: true,
      beta: true,
      internal_only: false,
      options: [],
      info: {
        paragraph:
          "Our estimate of the top locations where the podcast's audience resides. Filter thresholds are 25% for country and state/province, and 15% for DMA.",
        infoCTA: "demographics",
        ctaUrl:
          "https://support.podchaser.com/en/articles/8672076-demographics-and-reach",
      },
    },
    top_interests: {
      key: "top_interests",
      title: "Interests",
      type: "searchable_multiselect",
      pro: true,
      beta: true,
      internal_only: false,
      options: [],
      info: {
        paragraph:
          "Our estimate of the most common subjects listeners of this podcast are interested in (sorted by audience size), as determined by predictive models. Please contact the podcast for precise demographics.",
        infoCTA: "demographics",
        ctaUrl:
          "https://support.podchaser.com/en/articles/8672076-demographics-and-reach",
      },
    },
    top_occupations: {
      key: "top_occupations",
      title: "Jobs",
      type: "searchable_multiselect",
      pro: true,
      beta: true,
      internal_only: false,
      options: [],
      info: {
        paragraph:
          "Our estimate of the most common job titles held by listeners of this podcast (sorted by audience size), as determined by predictive models. Please contact the podcast for precise demographics.",
        infoCTA: "demographics",
        ctaUrl:
          "https://support.podchaser.com/en/articles/8672076-demographics-and-reach",
      },
    },
    top_parental_status: {
      key: "top_parental_status",
      title: "Parental Status",
      type: "multiselect",
      pro: true,
      beta: true,
      internal_only: false,
      options: [],
      info: {
        paragraph:
          "Our estimate of the parental status distribution (parents vs. non-parents skew) of a podcast's audience, as determined by predictive models. Please contact the podcast for precise demographics.",
        infoCTA: "demographics",
        ctaUrl:
          "https://support.podchaser.com/en/articles/8672076-demographics-and-reach",
      },
    },
    top_states: {
      key: "top_states",
      title: "State/Province",
      type: "multiselect",
      pro: true,
      beta: true,
      internal_only: false,
      options: [],
      info: {
        paragraph:
          "Our estimate of the top locations where the podcast's audience resides. Filter thresholds are 25% for country and state/province, and 15% for DMA.",
        infoCTA: "demographics",
        ctaUrl:
          "https://support.podchaser.com/en/articles/8672076-demographics-and-reach",
      },
    },
  },
  episode: {
    category: {
      key: "category",
      title: "Category",
      type: "searchable_multiselect",
      pro: false,
      beta: false,
      internal_only: false,
      options: [],
    },
    reach_estimate: {
      key: "reach_estimate",
      title: "Episode Reach",
      type: "text_range",
      pro: true,
      beta: false,
      internal_only: false,
      min: null,
      max: null,
      info: {
        paragraph:
          "Our estimate of how many listens a new episode of this podcast will receive within its first 30 days, based on real data from millions of devices. Please contact the podcast for precise listenership.",
        infoCTA: "Episode Reach",
        ctaUrl:
          "https://support.podchaser.com/en/articles/8672076-demographics-and-reach",
      },
    },
    episode_type: {
      key: "episode_type",
      title: "Episode Type",
      type: "multiselect",
      pro: false,
      beta: false,
      internal_only: false,
      options: [],
      info: {
        paragraph: `A full episode is the standard, full-length podcast episode.
      A bonus episode provides extra content beyond your regular schedule. 
      A trailer is a short, promotional piece of content that serves as a preview for a podcast.`,
      },
    },
    air_date: {
      key: "air_date",
      title: "Date Aired",
      pro: false,
      beta: false,
      internal_only: false,
      type: "date_range",
      min: null,
      max: null,
    },
    last_n_days: {
      key: "last_n_days",
      title: "Released Recently",
      prefix: "Last",
      suffix: "Days",
      type: "number",
      default: 30,
      maximum: 12077,
    },
    episode_podcast_status: {
      key: "episode_podcast_status",
      title: "Active Status",
      type: "dropdown",
      pro: false,
      beta: false,
      internal_only: false,
      options: [],
      info: {
        paragraph:
          "Podcasts are considered active if they've released an episode within the last 60 days.",
      },
    },
    explicit_episode: {
      key: "explicit_episode",
      title: "Explicit",
      type: "dropdown",
      beta: false,
      internal_only: false,
      pro: false,
      options: [],
      info: {
        paragraph:
          "Podcasters self-identify whether their podcast is explicit or clean in their RSS feed. This field is not moderated by Podchaser, so we cannot guarantee accuracy.",
      },
    },
    language: {
      key: "language",
      title: "Language",
      type: "searchable_multiselect",
      pro: false,
      beta: false,
      internal_only: false,
      options: [],
    },
    power_score: {
      key: "power_score",
      title: "Power Score™",
      type: "multiselect",
      pro: true,
      beta: false,
      internal_only: false,
      options: [],
      info: {
        paragraph:
          "Our metric indicating this podcast's overall popularity and influence, based on Podchaser’s unique algorithm that looks at 30+ different data points.",
        infoCTA: "Power Score™",
        ctaUrl:
          "https://support.podchaser.com/en/articles/8672076-demographics-and-reach",
      },
    },
    episode_length: {
      key: "episode_length",
      title: "Episode Length",
      type: "multiselect",
      pro: false,
      beta: false,
      internal_only: false,
      options: [],
    },
    series_has_guests: {
      key: "series_has_guests",
      title: "Has Guests",
      type: "dropdown",
      beta: false,
      internal_only: false,
      pro: true,
      options: [],
      info: {
        paragraph:
          "Our predictive indicator for determining the likelihood of guest appearances on a podcast, based on credits and episode descriptions.",
      },
    },
  },
  network: {
    network_catalog_size: {
      key: "network_catalog_size",
      title: "Network Size",
      type: "multiselect",
      pro: false,
      beta: false,
      internal_only: false,
      options: [],
      info: {
        paragraph:
          "Our prediction of how many podcasts are currently affiliated with a network, based on publicly available data.",
      },
    },
    category: {
      key: "category",
      title: "Podcast Category",
      type: "searchable_multiselect",
      pro: true,
      beta: false,
      internal_only: false,
      options: [],
      info: {
        paragraph:
          "Filter to show podcast networks with at least 3 podcasts in your selected categories. Use this filter to quickly find networks that match your RFP.",
      },
      disclaimer: "Min. 3 matching podcasts",
    },
    power_score: {
      key: "power_score",
      title: "Power Score™",
      type: "multiselect",
      pro: true,
      beta: false,
      internal_only: false,
      options: [],
      info: {
        paragraph:
          "Filter to show podcast networks with at least 3 podcasts in your selected Power Score bracket. Power Score is our metric for indicating a podcast's overall popularity and influence, based on Podchaser's unique algorithm that looks at 30+ different data points.",
        infoCTA: "Power Score™",
        ctaUrl:
          "https://support.podchaser.com/en/articles/8672076-demographics-and-reach",
      },
      disclaimer: "Min. 3 matching podcasts",
    },
    gender_bias: {
      key: "gender_bias",
      title: "Gender",
      type: "multiselect",
      pro: true,
      beta: false,
      internal_only: false,
      options: [],
      info: {
        paragraph:
          "Our estimate of the gender distribution (male-female skew) of a podcast's audience, as determined by predictive models. Filter to show podcast networks with at least 3 podcasts with audiences that match your skew preference. Please contact the Network for precise demographics.",
        infoCTA: "demographics here",
        ctaUrl:
          "https://support.podchaser.com/en/articles/8672076-demographics-and-reach",
      },
      disclaimer: "Min. 3 matching podcasts",
    },
    listener_age_range: {
      key: "listener_age_range",
      title: "Age",
      type: "multiselect",
      pro: true,
      beta: false,
      internal_only: false,
      options: [],
      info: {
        paragraph:
          "Our estimate of how old the average listener of a podcast is, as determined by predictive models. Filter to show podcast networks with at least 3 podcasts with audiences in your selected age brackets. Please contact the Network for precise demographics.",
        infoCTA: "demographics here",
        ctaUrl:
          "https://support.podchaser.com/en/articles/8672076-demographics-and-reach",
      },
      disclaimer: "Min. 3 matching podcasts",
    },
    income_tier: {
      key: "income_tier",
      title: "Income",
      type: "multiselect",
      pro: true,
      beta: false,
      internal_only: false,
      options: [],
      info: {
        paragraph:
          "Our estimate of how much the average listener of this podcast earns, as determined by predictive models. Filter to show podcast networks with at least 3 podcasts with audiences in your selected income brackets. Please contact the Network for precise demographics",
        infoCTA: "demographics here",
        ctaUrl:
          "https://support.podchaser.com/en/articles/8672076-demographics-and-reach",
      },
      disclaimer: "Min. 3 matching podcasts",
    },
    top_countries: {
      key: "top_countries",
      title: "Country",
      type: "multiselect",
      pro: true,
      beta: true,
      internal_only: false,
      options: [],
      info: {
        paragraph:
          "Our estimate of the top locations where the podcast's audience resides, as determined by predictive models. Filter to show podcast networks with at least 3 podcasts with audiences in the top locations chosen. Please contact the Network for precise demographics.",
        infoCTA: "demographics here",
        ctaUrl:
          "https://support.podchaser.com/en/articles/8672076-demographics-and-reach",
      },
      disclaimer: "Min. 3 matching podcasts",
    },
    top_parental_status: {
      key: "top_parental_status",
      title: "Parental Status",
      type: "multiselect",
      pro: true,
      beta: true,
      internal_only: false,
      options: [],
      info: {
        paragraph:
          "Our estimate of the parental status distribution (parent vs. non-parents skew) of a podcast's audience, as determined by predictive models. Filter to show podcast networks with at least 3 podcasts with audiences that match your parental skew preference. Please contact the Network for precise demographics.",
        infoCTA: "demographics here",
        ctaUrl:
          "https://support.podchaser.com/en/articles/8672076-demographics-and-reach",
      },
      disclaimer: "Min. 3 matching podcasts",
    },
    top_interests: {
      key: "top_interests",
      title: "Interests",
      type: "searchable_multiselect",
      pro: true,
      beta: true,
      internal_only: false,
      options: [],
      info: {
        paragraph:
          "Our estimate of the most common subjects listeners of a podcast are interested in (sorted by audience size), as determined by predictive models. Filter to show podcast networks with at least 3 podcasts with relevant audience interests. Please contact the Network for precise demographics.",
        infoCTA: "demographics here",
        ctaUrl:
          "https://support.podchaser.com/en/articles/8672076-demographics-and-reach",
      },
      disclaimer: "Min. 3 matching podcasts",
    },
  },
  creator: {
    top_categories: {
      key: "top_categories",
      title: "Top Categories",
      type: "searchable_multiselect",
      pro: false,
      beta: false,
      internal_only: false,
      options: [],
      info: {
        paragraph:
          "Creators can be filtered and sorted based on the categories of podcasts for which they have received credits.",
      },
    },
  },
  userlist: {
    created_at: {
      key: "created_at",
      title: "Date Created",
      pro: false,
      beta: false,
      internal_only: false,
      type: "date_range",
      min: null,
      max: null,
    },
    created_last_n_days: {
      key: "created_last_n_days",
      title: "Created Recently",
      prefix: "Last",
      suffix: "Days",
      type: "number",
      default: 30,
      maximum: 1810,
    },
    num_list_items: {
      key: "num_list_items",
      title: "List Size",
      type: "multiselect",
      pro: false,
      beta: false,
      internal_only: false,
      options: [],
    },
  },
};
