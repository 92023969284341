import getFormattedPath from "./getFormattedPath";
import truncateUrlSlug from "./truncateUrlSlug";

import { formatURL } from "utils/format";

export default (podcast, path = "") => {
  if (podcast) {
    const podcastURL = `/podcasts/${truncateUrlSlug(
      podcast.get("slug")
    )}-${podcast.get("id")}${getFormattedPath(path)}`;

    return formatURL(podcastURL);
  }

  return podcast;
};
