import { useHistory } from "react-router-dom";

import useActionCreators from "../hooks/useActionCreators";

import appActions from "actions/app";

import useLayoutEffect from "hooks/useLayoutEffect";
import useReduxState from "hooks/useReduxState";

function HandleRedirect() {
  const redirectTo = useReduxState((state) => state.app.get("redirect"), []);
  const history = useHistory();

  const { clearNavigateTo } = useActionCreators({
    clearNavigateTo: appActions.clearNavigateTo,
  });

  useLayoutEffect(() => {
    if (
      redirectTo &&
      ((typeof redirectTo === "string" && redirectTo.length > 0) ||
        typeof redirectTo === "object")
    ) {
      history.push(redirectTo);
      clearNavigateTo();
    }
  }, [clearNavigateTo, history, redirectTo]);

  return null;
}

export default HandleRedirect;
