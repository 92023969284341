import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const AdminSection = Loadable(() => import("./AdminSection"));

function AdminSectionAsyncLoading(props) {
  return <LoadableLoading {...props} page />;
}

const AdminSectionContainer = (props) => {
  return <AdminSection {...props} fallback={<AdminSectionAsyncLoading />} />;
};

export default AdminSectionContainer;
