import Loadable from "@loadable/component";
const AddVoiceActorCharacterModal = Loadable(() =>
  import("./AddVoiceActorCharacterModal")
);

function AddVoiceActorCharacterModalAsyncLoading() {
  return null;
}

const AddVoiceActorCharacterModalContainer = (props) => {
  return (
    <AddVoiceActorCharacterModal
      {...props}
      fallback={<AddVoiceActorCharacterModalAsyncLoading />}
    />
  );
};

export default AddVoiceActorCharacterModalContainer;
