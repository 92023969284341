export default function getUserDisplayName(user, informal = false) {
  if (!user) {
    return null;
  }
  const displayType = user.get("name_display_type");
  if (displayType === "realname") {
    if (informal && !!user.get("informal_name")) {
      return user.get("informal_name");
    }
    if (user.get("display_name")) {
      return user.get("display_name");
    }
    if (!!user.get("informal_name")) {
      // Fall back to informal
      return user.get("informal_name");
    }
  }
  return user.get("username");
}
