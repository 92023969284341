import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const Overlay = Loadable(() => import("./Overlay"));

function OverlayAsyncLoading(props) {
  return <LoadableLoading {...props} />;
}

const OverlayContainer = (props) => {
  return <Overlay {...props} fallback={<OverlayAsyncLoading />} />;
};

export default OverlayContainer;
