import Loadable from "@loadable/component";

import TimelineSkeletonItem from "components/Timeline/TimelineSkeletonItem";

const ActivityCard = Loadable(() =>
  import(/* webpackChunkName: "ActivityCard" */ "./ActivityCard")
);

function ActivityCardLoading(props) {
  return <TimelineSkeletonItem />;
}

const ActivityCardContainer = (props) => {
  return <ActivityCard {...props} fallback={<ActivityCardLoading />} />;
};

export default ActivityCardContainer;
