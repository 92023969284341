import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const AboutView = Loadable(() => import("./AboutView"));

function AboutViewAsyncLoading(props) {
  return <LoadableLoading {...props} page />;
}

const AboutViewContainer = (props) => {
  return <AboutView {...props} fallback={<AboutViewAsyncLoading />} />;
};

export default AboutViewContainer;
