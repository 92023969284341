import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { useMemo } from "react";

import LoadingOverlay from "components/Common/LoadingOverlay";

import EntityImage from "./EntityImage";

import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";

const baseStyles = {
  entitySplitImage: {
    display: "block",
    width: "100%",
    position: "relative",
  },
  entitySplitImageSub: {
    position: "absolute",
    border: "2px solid #fff",
  },
  entitySplitImageSubRound: {
    borderRadius: "50%",
  },
  subLoadingContainer: {
    backgroundColor: colours.white,
    fontSize: ".6rem",
    display: "flex",
    justifyContent: "center",
    border: "1px solid #ddd !important",
    alignItems: "center",
  },
  passedSubImage: {
    border: "none",
  },
};

const CACHE_OPTIONS = {
  variance: 0.2,
};

const loadingStyles = {
  noOverlay: {
    color: colours.midGrey,
  },
};

const EntitySplitImage = (props) => {
  const {
    mainEntity,
    mainEntityId,
    mainEntityType,
    subEntity,
    subEntityId,
    subEntityType,
    subImage,
    mobileMainSize,
    mainSize,
    mobileSubSize,
    subSize,
    bottom,
    right,
    subLoading,
    ...rest
  } = props;

  const finalStyles = useMemo(
    () => [
      baseStyles,
      {
        entitySplitImageSub: {
          width: subSize,
          minWidth: subSize,
          height: subSize,
          bottom,
          right,
        },
      },
    ],
    [subSize, bottom, right]
  );

  const { styles } = useStyles(finalStyles, props);
  const { isWindowSizeOrLess } = useWindowSize();
  const mobile = isWindowSizeOrLess("large");

  const renderSubImage = () => {
    if (subImage) {
      return subImage;
    }

    return (
      <EntityImage
        entity_type={subEntityType}
        entity={subEntity}
        entity_id={subEntityId}
        size={mobile ? mobileSubSize || subSize : subSize}
        showTitle
        fullWidth
        {...rest}
      />
    );
  };

  return (
    <div className={css(styles.entitySplitImage)}>
      <EntityImage
        entity_type={mainEntityType}
        entity={mainEntity}
        entity_id={mainEntityId}
        size={mobile ? mobileMainSize || mainSize : mainSize}
        fullWidth
        imageCacheOptions={CACHE_OPTIONS}
        {...rest}
      />
      {(subEntity || subEntityId || subLoading || subImage) && (
        <div
          className={css(
            styles.entitySplitImageSub,
            (subEntityType === "user" ||
              subEntityType === "creator" ||
              subLoading) &&
              styles.entitySplitImageSubRound,
            subLoading && styles.subLoadingContainer,
            subImage && styles.passedSubImage
          )}
        >
          {subLoading ? (
            <LoadingOverlay styles={loadingStyles} noPadding noOverlay />
          ) : (
            renderSubImage()
          )}
        </div>
      )}
    </div>
  );
};

EntitySplitImage.propTypes = {
  mainEntity: PropTypes.instanceOf(Map),
  mainEntityType: PropTypes.string,
  mainEntityId: PropTypes.number,
  subEntity: PropTypes.instanceOf(Map),
  subEntityType: PropTypes.string,
  subEntityId: PropTypes.number,
  subImage: PropTypes.node,
  mobileMainSize: PropTypes.number,
  mainSize: PropTypes.number,
  mobileSubSize: PropTypes.number,
  subSize: PropTypes.number,
  bottom: PropTypes.number,
  right: PropTypes.number,
  subLoading: PropTypes.bool,
};
EntitySplitImage.defaultProps = {
  mainEntity: null,
  mainEntityType: null,
  mainEntityId: null,
  subEntity: null,
  subEntityType: null,
  subEntityId: null,
  subImage: null,
  mobileMainSize: 256,
  mainSize: 146,
  mobileSubSize: 54,
  subSize: 54,
  bottom: -2,
  right: -2,
  subLoading: false,
};

export default EntitySplitImage;
