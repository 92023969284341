import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

const UserListActivity = Loadable(() =>
  import(/* webpackChunkName: "UserListActivity" */ "./UserListActivity")
);

function UserListActivityLoading(props) {
  return <LoadingOverlay {...props} span />;
}

const UserListActivityContainer = (props) => {
  return <UserListActivity {...props} fallback={<UserListActivityLoading />} />;
};

export default UserListActivityContainer;
