import { faSearchPlus } from "@fortawesome/free-solid-svg-icons/faSearchPlus";
import { faBook } from "@fortawesome/pro-solid-svg-icons/faBook";
import { faGlobeAmericas } from "@fortawesome/pro-solid-svg-icons/faGlobeAmericas";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons/faInfoCircle";
import { faPlug } from "@fortawesome/pro-solid-svg-icons/faPlug";
import { faTrophyAlt } from "@fortawesome/pro-solid-svg-icons/faTrophyAlt";
import { faUserHeadset } from "@fortawesome/pro-solid-svg-icons/faUserHeadset";
import React, { useMemo } from "react";

import { BLOG_BASE_PATH } from "../../../utils/blog";
import NavDropdownMenu, {
  defaultMenuStyles,
} from "./NavMenuDropdownItem/DropdownNavMenu";

import modalActions from "actions/modals";

import useActionCreators from "hooks/useActionCreators";

import ScreenSizes from "styles/ScreenSizes";

const menuStyles = {
  ...defaultMenuStyles,
  menuContainer: {
    ...defaultMenuStyles.menuContainer,
    maxWidth: "100%",

    [ScreenSizes.lgAndAbove]: {
      width: "max-content",
    },
  },
  item: {
    ...defaultMenuStyles.item,
    padding: 0,
  },
};

const MoreNavMenu = (props) => {
  const { showModal } = useActionCreators({
    showModal: modalActions.showModal,
  });

  const navLinks = useMemo(
    () => [
      {
        id: "about",
        title: "About Podchaser",
        label: "About Podchaser",
        url: "/about",
        exact: true,
        context: "DropdownNavMenuItemSecondary",
        icon: faGlobeAmericas,
      },
      {
        id: "integrations",
        title: "Integrations",
        label: "Integrations",
        url: "/integrations",
        context: "DropdownNavMenuItemSecondary",
        icon: faPlug,
      },
      {
        id: "blog",
        title: "Articles",
        label: "Articles",
        url: BLOG_BASE_PATH,
        context: "DropdownNavMenuItemSecondary",
        icon: faBook,
      },
      {
        id: "leaderboard",
        title: "Leaderboard",
        description: "Find popular tastemakers on Podchaser",
        label: "Leaderboard",
        url: "/leaderboard/reviews",
        context: "DropdownNavMenuItemSecondary",
        icon: faTrophyAlt,
      },
      {
        id: "findFriends",
        label: "Find Friends",
        description: "Find your friends on Podchaser",
        action: () => showModal("onboarding", { onboardingStep: "users" }),
        context: "DropdownNavMenuItemSecondary",
        icon: faSearchPlus,
      },
      {
        id: "helpCenter",
        title: "Help Center",
        label: "Help Center",
        url: "https://support.podchaser.com/",
        context: "DropdownNavMenuItemSecondary",
        external: true,
        icon: faInfoCircle,
      },
      {
        id: "liveChat",
        title: "Live Chat",
        label: "Live Chat",
        action: () => window && window.Intercom && window.Intercom("show"),
        context: "DropdownNavMenuItemSecondary",
        icon: faUserHeadset,
      },
    ],
    []
  );

  return (
    <NavDropdownMenu {...props} menuItems={navLinks} menuStyles={menuStyles} />
  );
};

export default MoreNavMenu;
