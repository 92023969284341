import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const BlogCategoryView = Loadable(() => import("./BlogCategoryView"));

function BlogCategoryViewAsyncLoading(props) {
  return <LoadableLoading {...props} />;
}

const BlogCategoryViewContainer = (props) => {
  return (
    <BlogCategoryView {...props} fallback={<BlogCategoryViewAsyncLoading />} />
  );
};

export default BlogCategoryViewContainer;
