import * as constants from "constants/search";

export default {
  onSearchChange: ({
    term,
    searchKey,
    types,
    size = 5,
    start = 0,
    ...rest
  }) => ({
    type: constants.SEARCH_ON_CHANGE,
    searchKey,
    term,
    types,
    size,
    start,
    replaceList: true,
    ...rest,
  }),
  nextSearchPage: ({ size = 5, start = 0, ...rest }) => ({
    type: constants.SEARCH_ON_CHANGE,
    size,
    start,
    ...rest,
  }),
  cancelSearch: ({ searchKey }) => ({
    type: constants.SEARCH_CANCEL,
    searchKey,
  }),
  submitFeedUrl: (feedUrl) => ({
    type: constants.SEARCH_SUBMIT_FEED_URL_REQUEST,
    url: feedUrl,
  }),
  initFacetSearch: (props) => ({
    type: constants.FACETED_SEARCH_INIT,
    ...props,
  }),
  facetChange: (key, value) => ({
    type: constants.FACETED_SEARCH_FACET_CHANGE,
    change: { [key]: value },
  }),
  searchWithinFacets: (value) => ({
    type: constants.SEARCH_WITHIN_FACETS,
    value,
  }),
  sortChange: (key, direction) => ({
    type: constants.FACETED_SEARCH_FACET_CHANGE,
    sort: { key, direction },
  }),
  pageChange: ({ start, size }) => ({
    type: constants.FACETED_SEARCH_FACET_CHANGE,
    page: { start, size },
  }),
  singleSelectItem: (searchKey, item) => ({
    type: constants.SEARCH_ITEM_SELECTED,
    searchKey,
    item,
  }),

  loadRecentSearchHistory: () => ({
    type: constants.GET_RECENT_SEARCH_HISTORY,
  }),

  saveRecentSearch: ({ search_term }) => ({
    type: constants.POST_RECENT_SEARCH,
    search_term,
  }),

  deleteRecentSearch: ({ search_term }) => ({
    type: constants.DELETE_RECENT_SEARCH,
    search_term,
  }),
};
