import { userHasProPermission } from "utils/entity/user";

import useLoggedInUser from "hooks/useLoggedInUser";

const useUserHasPro = () => {
  const user = useLoggedInUser();

  return userHasProPermission(user);
};

export default useUserHasPro;
