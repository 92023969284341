import { useContext } from "react";

import { HeightManagerContext } from "components/Common/HeightManager";

import useLayoutEffect from "hooks/useLayoutEffect";

function useManagedHeight(key, ref) {
  const heightManager = useContext(HeightManagerContext);

  const hasHeightManagement =
    key && ref && ref.current && ref.current.getBoundingClientRect;
  const height = hasHeightManagement
    ? ref.current.getBoundingClientRect().height
    : 0;

  useLayoutEffect(() => {
    if (hasHeightManagement && heightManager) {
      heightManager.registerElement(key, height);
    }
  }, [key, height, hasHeightManagement, heightManager]);
}

export default useManagedHeight;
