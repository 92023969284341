import { useMemo } from "react";

import FooterColumn from "./FooterColumn";

import useUserHasPro from "hooks/useUserHasPro";
import { useIntercomHasLoaded } from "hooks/useWindowScripts";

import colours from "styles/colours";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  linksColumn: {
    fontSize: "0.9rem",
    color: colours.grey,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: 0,
    marginTop: "2rem",
    [ScreenSizes.lgAndAbove]: {
      marginTop: "0",
      paddingLeft: "initial",
      padding: 0,
    },
    [ScreenSizes.mdAndAbove]: {
      flexDirection: "row",
      gap: "1rem",
    },
  },
  link: {
    lineHeight: "2em !important",
    [ScreenSizes.mdAndAbove]: {
      fontSize: "0.8rem",
    },
    [ScreenSizes.lgAndAbove]: {
      fontSize: ".875rem",
    },
  },
  listItem: {
    alignSelf: "start",
    [ScreenSizes.mdAndAbove]: {
      fontSize: "0.7rem",
    },
    [ScreenSizes.lgAndAbove]: {
      alignSelf: "center",
      fontSize: ".875rem",
    },
  },
};
const PolicyAndTermsFooterLink = () => {
  const hasPro = useUserHasPro();
  const intercomHasLoaded = useIntercomHasLoaded();
  const currentYear = new Date().getFullYear();

  const PolicyAndTerms = useMemo(
    () => [
      { title: `© ${currentYear} Podchaser, Inc.`, copyright: true },
      { title: "Privacy Policy", to: "/privacy.html", external: true },
      { title: "Terms of Service", to: "/terms.html", external: true },
      hasPro
        ? {
            title: "Subscription Terms",
            to: "/subscription-terms.html",
            external: true,
          }
        : null,

      intercomHasLoaded
        ? {
            title: "Contact Us",
            action: () => window.Intercom("show"),
          }
        : {
            title: "Contact Us",
            to: "mailto:contact@podchaser.com",
            external: true,
          },
    ],
    [hasPro, intercomHasLoaded]
  );

  return <FooterColumn styles={baseStyles} links={PolicyAndTerms} />;
};

export default PolicyAndTermsFooterLink;
