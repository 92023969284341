import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const DownloadContactsModal = Loadable(() => import("./DownloadContactsModal"));

function DownloadContactsModalAsyncLoading(props) {
  return <LoadableLoading {...props} />;
}

const DownloadContactsModalContainer = (props) => {
  return (
    <DownloadContactsModal
      {...props}
      fallback={<DownloadContactsModalAsyncLoading />}
    />
  );
};

export default DownloadContactsModalContainer;
