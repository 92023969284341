import PropTypes from "prop-types";
import { useCallback, useEffect, useState, useMemo } from "react";

import appActions from "actions/app";
import searchActions from "actions/search";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import useSearch from "hooks/useSearch";

/**
 * This class passes state and functions for a specific instance of a search autocomplete
 * It passes responsibility for handling a selection to the parent
 */

function SearchFieldContainer(props) {
  const {
    children,
    render,
    withPagination,
    size: passedSize,
    searchKey,
    types: passedTypes,
    onSearchChange: passedOnSearchChange,
    onCancelSearch: passedOnCancelSearch,
    preventSearch,
    getSearchTerm,
    analyticsVariables,
  } = props;
  const [lastTerm, setLastTerm] = useState(null);
  const [start, setStart] = useState(0);
  const [selectedType, setSelectedType] = useState("all");

  const searchProps = useSearch(searchKey);
  const { search_term, size: pulledSize, total } = searchProps;
  const size = passedSize || pulledSize;

  const { onSearchChange, cancelSearch, navigateTo } = useActionCreators({
    onSearchChange: searchActions.onSearchChange,
    cancelSearch: searchActions.onSearchChange,
    navigateTo: appActions.navigateTo,
  });

  useEffect(() => {
    if (search_term && !search_term.match(/^\s*$/)) {
      setLastTerm(search_term);
    }
  }, [search_term]);

  const types = useMemo(() => {
    if (selectedType === "all") {
      return passedTypes;
    }

    return [selectedType];
  }, [selectedType, passedTypes]);

  const handleSearchChange = useCallback(
    (term, override = {}) => {
      let newStart = 0;

      if (override.start !== undefined) {
        newStart = override.start;
      } else if (term === lastTerm) {
        newStart = start;
      }

      if (passedOnSearchChange) {
        passedOnSearchChange(term);
      }

      setStart(newStart);

      if (!preventSearch) {
        onSearchChange({
          term: getSearchTerm ? getSearchTerm(term) : term,
          searchKey,
          types,
          start: newStart,
          size,
          analyticsVariables,
          ...override,
        });
      }
    },
    [
      lastTerm,
      onSearchChange,
      passedOnSearchChange,
      searchKey,
      size,
      start,
      types,
      getSearchTerm,
      preventSearch,
      analyticsVariables,
    ]
  );

  const handleTypeChange = useCallback(
    (type) => {
      if (search_term) {
        handleSearchChange(search_term, {
          types: type === "all" ? passedTypes : [type],
        });
      }

      setSelectedType(type);
    },
    [search_term, handleSearchChange, passedTypes]
  );

  const changePage = useCallback(
    (nextStart) => {
      handleSearchChange(lastTerm, { start: nextStart });
      sendGAEvent({
        action: "searchFieldPaginationClicked",
        start_index: nextStart,
        key: searchKey,
        ...(analyticsVariables || {}),
      });
    },
    [handleSearchChange, lastTerm, searchKey, analyticsVariables]
  );

  const handlePrevPage = useCallback(
    () => changePage(start - size),
    [changePage, size, start]
  );
  const handleNextPage = useCallback(
    () => changePage(start + size),
    [changePage, size, start]
  );

  const handleCancelSearch = useCallback(() => {
    if (passedOnCancelSearch) {
      passedOnCancelSearch();
    }

    cancelSearch({ searchKey, analyticsVariables });
  }, [cancelSearch, searchKey, passedOnCancelSearch, analyticsVariables]);

  return (children || render)({
    ...props,
    ...searchProps,
    navigateTo,
    pagination: withPagination && {
      isPrev: start > 0,
      isNext: size + start < total,
      prevPage: handlePrevPage,
      nextPage: handleNextPage,
      currentPage: start / size + 1,
      totalPages: total ? Math.ceil(total / size) : null,
    },
    onSearchChange: handleSearchChange,
    onTypeChange: handleTypeChange,
    selectedType,
    cancelSearch: handleCancelSearch,
  });
}

SearchFieldContainer.propTypes = {
  searchKey: PropTypes.string.isRequired,
  withPagination: PropTypes.bool,
  size: PropTypes.number,
  types: PropTypes.array,
  render: PropTypes.func,
  children: PropTypes.func,
  onSearchChange: PropTypes.func,
  onCancelSearch: PropTypes.func,
  preventSearch: PropTypes.bool,
  getSearchTerm: PropTypes.func,
};

SearchFieldContainer.defaultProps = {
  render: null,
  withPagination: false,
  size: 4,
  types: undefined,
  children: undefined,
  onSearchChange: null,
  onCancelSearch: null,
  preventSearch: false,
  getSearchTerm: null,
};

export default SearchFieldContainer;
