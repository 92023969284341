import { css } from "aphrodite";
import { isImmutable, List } from "immutable";
import PropTypes from "prop-types";
import {
  createContext,
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link, Redirect } from "react-router-dom";
import { useLocation } from "react-router-dom";

import ScrollControlButtons from "components/Buttons/ScrollControlButtons";

import PodchaserProBadge from "../Branding/PodchaserProBadge";
import tabStyles from "./tabs.styles";

import eventIsFieldTrigger from "utils/misc/eventIsFieldTrigger";
import { matchesRouteWithParams } from "utils/url";

import useLayoutEffect from "hooks/useLayoutEffect";
import useQuery from "hooks/useQuery";
import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

export const TabsContext = createContext({});

function Tabs(props) {
  const {
    hideTabs,
    renderLayout,
    tabs,
    byRoute,
    baseRoute,
    tabKey: propsTabKey,
    customTabs,
    tabProps,
    renderTab,
    renderContent,
    showScrollControls,
    byQuery,
    justTabs,
    intialTabKey,
  } = props;

  const [tabKey, setTabKey] = useState(intialTabKey);
  const defaultTabsWider = justTabs ? false : tabs.length > 4;
  const [tabsWiderThanContainer, setTabsWiderThanContainer] =
    useState(defaultTabsWider);
  const [tabsWidth, setTabsWidth] = useState(0);
  const { styles } = useStyles(tabStyles, props);
  const contentRef = useRef(null);
  const tabsRef = useRef(null);
  const tabContainerWidths = useRef({});
  const { isWindowSizeOrMore } = useWindowSize();
  const isLargeThanMobile = isWindowSizeOrMore("large");
  const displayScrollControls = showScrollControls && isLargeThanMobile;

  const getTab = useCallback(
    (index) => (isImmutable(tabs) ? tabs.get(index) : tabs[index]),
    [tabs]
  );

  const tabsCombinedWidth =
    tabContainerWidths.current &&
    Object.values(tabContainerWidths.current).reduce(
      (combined, width) => combined + (width || 0),
      0
    );

  const location = useLocation();
  const [allQueries] = useQuery();

  const query = useMemo(() => {
    return allQueries[byQuery];
  }, [allQueries, byQuery]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    // this makes sure the tabsWidth definitely gets updated
    if (
      tabsRef.current &&
      tabsRef.current.clientWidth !== tabsWidth &&
      !justTabs
    ) {
      setTabsWidth(tabsRef.current.clientWidth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    const newTabsWiderThanContainer = tabsCombinedWidth > tabsWidth;

    if (
      tabsCombinedWidth &&
      tabsWiderThanContainer !== newTabsWiderThanContainer &&
      !justTabs
    ) {
      setTabsWiderThanContainer(newTabsWiderThanContainer);
    }
    if (tabsRef.current && tabsRef.current.scrollLeft > 0) {
      tabsRef.current.scrollLeft = 0;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabsWidth, tabsCombinedWidth]);

  const rootTabIndex = useMemo(
    () =>
      tabs.reduce((rootIndex, tab, index) => (tab.root ? index : rootIndex), 0),
    [tabs]
  );

  const currentTab = useMemo(() => {
    const foundTab = tabs.find((tab) => {
      if (query) {
        return query && query === tab.key;
      }
      if (byRoute) {
        const testByRoute = (key) => {
          const tabRoute = `${baseRoute ? `${baseRoute}/` : ""}${key}`;

          return (
            location.pathname === tabRoute ||
            matchesRouteWithParams(location.pathname, tabRoute)
          );
        };

        return (
          testByRoute(tab.path) ||
          testByRoute(tab.key) ||
          (tab.fallbackKeys &&
            tab.fallbackKeys.some((fallbackKey) => testByRoute(fallbackKey)))
        );
      }

      return propsTabKey ? tab.key === propsTabKey : tab.key === tabKey;
    });

    return foundTab || getTab(rootTabIndex) || {};
  }, [
    baseRoute,
    byRoute,
    location.pathname,
    propsTabKey,
    query,
    tabKey,
    tabs,
    getTab,
    rootTabIndex,
  ]);

  const setTabContainerWidth = useCallback(
    (key) => (e) => (tabContainerWidths.current[key] = e && e.clientWidth),
    []
  );

  const handleTabClick =
    (tab, isKeyDownHandler = false) =>
    (e) => {
      const { onTabChange } = props;

      if (isKeyDownHandler && e.keyCode !== 13) {
        return;
      }

      // this will prevent the Link element from adding an additional history push
      e.preventDefault();

      if (!tab.preventTabChange) {
        setTabKey(tab.key);
      }
      if (tab.onClick) {
        tab.onClick(tab, e);
      }
      if (onTabChange) {
        onTabChange(tab, e);
      }
    };

  const providerValue = useMemo(() => ({ contentRef }), []);

  const renderSingleTab = (tab, index) => {
    const isButton = tab.componentType === "button";
    const anchorComponentType = tab.anchor ? Link : "a";

    const ComponentType = isButton ? "button" : anchorComponentType;
    const isCurrent = currentTab.key === tab.key;
    const isLast = getTab((tabs.length || tabs.size) - 1).key === tab.key;
    const isFirst = getTab(0).key === tab.key;

    if (tab.renderTab || renderTab) {
      const tabRenderProps = {
        tab,
        index,
        tabProps,
        onClick: handleTabClick(tab),
        isCurrent,
        isFirst,
        isLast,
      };

      if (tab.renderTab) {
        return tab.renderTab(tabRenderProps);
      }
      if (renderTab) {
        return renderTab(tabRenderProps);
      }
    }

    return (
      <div
        key={tab.key}
        className={css(
          styles.tabContainer,
          tabsWiderThanContainer && styles.tabContainerWide,
          isCurrent && styles.currentTabContainer
        )}
        tabIndex="0"
        onKeyDown={handleTabClick(tab, true)}
        ref={setTabContainerWidth(tab.key)}
      >
        <ComponentType
          href={tab.anchor ? undefined : "#"}
          className={css(
            styles.tab,
            styles[`tab-${tab.key}`],
            isFirst && styles.firstTab,
            isLast && styles.lastTab,
            isCurrent && styles.currentTab,
            isCurrent && styles[`currentTab-${tab.key}`]
          )}
          title={tab.hiddenTitle}
          to={tab.anchor ? tab.anchor : undefined}
          onClick={tab.onClick ? tab.onClick : handleTabClick(tab)}
          onKeyDown={(e) => {
            eventIsFieldTrigger(e) && e.preventDefault();
            eventIsFieldTrigger(e) && handleTabClick(tab)(e);
          }}
          data-id={tab.dataId || `tab-link-${tab.key}`}
          data-value={isCurrent ? "selected" : null}
          tabIndex="-1"
        >
          {tab.badge && tab.badge !== null && tab.badgeBeforeTitle && (
            <div className={css(styles.tabBadge, styles.tabBadgeBefore)}>
              {tab.badge}
            </div>
          )}
          {tab.title}
          {tab.badge && tab.badge !== null && !tab.badgeBeforeTitle && (
            <div className={css(styles.tabBadge)}>{tab.badge}</div>
          )}
          <span>
            {tab.pro && (
              <span className={css(styles.proBadge)}>
                <PodchaserProBadge></PodchaserProBadge>
              </span>
            )}
          </span>
        </ComponentType>
      </div>
    );
  };

  const renderTabs = () => (
    <div
      className={css(
        styles.tabs,
        tabsWiderThanContainer && styles.tabsWide,
        displayScrollControls && styles.tabsWithScrollControls,
        currentTab && currentTab.key && styles[`tabs_${currentTab.key}`]
      )}
      ref={tabsRef}
    >
      {tabs
        .filter(
          (tab) =>
            (!Object.prototype.hasOwnProperty.call(tab, "condition") ||
              tab.condition) &&
            (!Object.prototype.hasOwnProperty.call(tab, "tabCondition") ||
              tab.tabCondition)
        )
        .map(renderSingleTab)}
      {displayScrollControls && <ScrollControlButtons scrollRef={tabsRef} />}
    </div>
  );

  if (renderLayout) {
    return renderLayout({
      tabs: customTabs ? tabs : renderTabs(),
      onTabChange: handleTabClick,
      currentTab,
    });
  }

  const renderTabContent = (tab, hidden) => {
    let content = null;

    if (
      ((Object.prototype.hasOwnProperty.call(tab, "condition") &&
        !tab.condition) ||
        (Object.prototype.hasOwnProperty.call(tab, "contentCondition") &&
          !tab.contentCondition)) &&
      tab.redirect &&
      !hidden
    ) {
      return <Redirect to={tab.redirect} />;
    }
    if (tab.contentComponent) {
      content = <tab.contentComponent {...tabProps} />;
    } else if (tab.renderContent) {
      content = tab.renderContent();
    } else {
      content = renderContent && renderContent(tab);
    }

    return (
      <div
        key={tab.key}
        className={css(
          styles.tabContent,
          styles[`tabContent_${tab.key}`],
          hidden && styles.tabContentHidden
        )}
        data-id={`tab-content-${tab.key}`}
      >
        {content}
      </div>
    );
  };

  if (justTabs) {
    return renderTabs();
  }

  const canShowTabs = () => {
    if (hideTabs) {
      return false;
    }

    if (currentTab?.hideTabs) {
      return false;
    }

    return true;
  };

  return (
    <TabsContext.Provider value={providerValue}>
      <div
        className={css(
          styles.container,
          displayScrollControls && styles.containerWithScrollControls
        )}
        ref={contentRef}
      >
        {canShowTabs() && renderTabs()}
        {renderTabContent(currentTab, false)}
      </div>
    </TabsContext.Provider>
  );
}

Tabs.propTypes = {
  tabs: PropTypes.oneOfType([PropTypes.instanceOf(List), PropTypes.array]),
  tabProps: PropTypes.object,
  tabKey: PropTypes.string,
  renderContent: PropTypes.func,
  onTabChange: PropTypes.func,
  hideTabs: PropTypes.bool,
  byRoute: PropTypes.bool,
  baseRoute: PropTypes.string,
  renderLayout: PropTypes.func,
  renderTab: PropTypes.func,
  customTabs: PropTypes.bool,
  showScrollControls: PropTypes.bool,
  byQuery: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  justTabs: PropTypes.bool,
  intialTabKey: PropTypes.string,
};

Tabs.defaultProps = {
  tabProps: {},
  tabs: [],
  tabKey: null,
  renderContent: null,
  onTabChange: null,
  hideTabs: false,
  byRoute: false,
  baseRoute: null,
  renderLayout: null,
  renderTab: null,
  customTabs: false,
  showScrollControls: false,
  byQuery: false,
  justTabs: false,
  intialTabKey: null,
};

export default memo(Tabs);
