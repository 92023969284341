import Loadable from "@loadable/component";

import PodcastViewSkeleton from "./PodcastViewSkeleton";

const PodcastViewContainer = Loadable(() =>
  import(
    /* webpackChunkName: "PodcastViewContainer" */ "./PodcastViewContainer"
  )
);

function PodcastViewAsyncLoading() {
  return <PodcastViewSkeleton />;
}

const PodcastViewContainerContainer = (props) => {
  return (
    <PodcastViewContainer {...props} fallback={<PodcastViewAsyncLoading />} />
  );
};

export default PodcastViewContainerContainer;
