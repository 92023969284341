import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

import ScreenSizes from "styles/ScreenSizes";

const loadingStyles = {
  height: {
    height: "8.1rem",
    maxHeight: "8.1rem",
    minHeight: 0,

    [ScreenSizes.lgAndAbove]: {
      height: "9.3rem",
      maxHeight: "9.3rem",
    },
  },
};

const ListPageHeader = Loadable(() =>
  import(/* webpackChunkName: "ListPageHeader" */ "./ListPageHeader")
);

function ListPageHeaderAsyncLoading() {
  return <LoadingOverlay height={"8.1rem"} styles={loadingStyles} />;
}

const ListPageHeaderContainer = (props) => {
  return (
    <ListPageHeader {...props} fallback={<ListPageHeaderAsyncLoading />} />
  );
};

export default ListPageHeaderContainer;
