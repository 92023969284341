import { createRoutine } from "redux-saga-routines";

import * as claimConstants from "constants/claiming";

export default {
  openClaimModal: (podcast_id, props = {}) => ({
    type: claimConstants.CLAIM_PODCAST_OPEN_MODAL,
    podcast_id,
    ...props,
  }),
  requestPodcastClaimEmail: (podcast_id, options = {}) => ({
    type: claimConstants.CLAIM_PODCAST_SEND_CLAIM_REQUEST,
    podcast_id,
    ...options,
  }),
  validatePodcastClaim: (token, options = {}) => ({
    type: claimConstants.CLAIM_PODCAST_VALIDATE_CLAIM_REQUEST,
    token,
    ...options,
  }),
  tokenEnteredInField: (token) => ({
    type: claimConstants.CLAIM_TOKEN_ENTERED_IN_FORM,
    token,
  }),
  loadSpecificClaimedPodcast: createRoutine("LOAD_SPECIFIC_CLAIMED_PODCAST"),
  selectClaimMethod: (method) => ({
    type:
      method === "feed_token"
        ? claimConstants.CLAIM_METHOD_SELECTED_FEED_TOKEN
        : claimConstants.CLAIM_METHOD_SELECTED_EMAIL_TOKEN,
  }),
  feedTokenAdded: (params = {}) => ({
    type: claimConstants.CLAIM_PODCAST_FEED_TOKEN_ADDED,
    ...params,
  }),
  claimingMethod: (podcast_id, method, params = {}) => ({
    type: claimConstants.CLAIMING_METHOD,
    podcast_id,
    method,
    ...params,
  }),
};
