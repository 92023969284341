import set from "lodash/set";
import { applyMiddleware } from "redux";
import { createStore } from "redux-dynamic-modules";

import { getSagaExtension } from "components/Common/ReduxDynamicModulesSaga";

async function configureStore({
  initialState,
  moduleRegister,
  request,
  requestContext,
}) {
  const serverLogger = () => (next) => (action) => {
    console.log(action.type);

    return next(action);
  };

  const requestContextInjection = () => (next) => (action) =>
    next({
      ...action,
      isServer: requestContext.server,
    });

  let middlewares = [requestContextInjection];

  if (process.env.BUILD_FLAG_IS_DEV) {
    if (requestContext.server) {
      middlewares = [serverLogger, ...middlewares];
    }
  }

  const enhancers = [
    // bugReporter,
    // Middleware store enhancer.
    applyMiddleware(...middlewares),
  ];

  const initialModulesGetter = await import(
    `./initialModules.${requestContext.appCode}`
  );
  const initialModules = initialModulesGetter.default();

  // log("INITIAL MODULES", initialModules);

  let modulePromises = [];

  if (!requestContext.server && window && window.__PC_INITIAL_MODULES__) {
    /* eslint-disable */
    modulePromises = window.__PC_INITIAL_MODULES__.map((name) =>
      import(/* webpackChunkName: "modules" */ "../../modules/" + name + ".js")
    );
    /* eslint-enable */
  }

  if (requestContext.server) {
    modulePromises = moduleRegister.map((name) => {
      console.log(`Importing module ${name}`);
      return import(
        `../../modules/${name}.js`
      ); /* webpackChunkName: "modulesSSR" */
    });
  }

  const resolvedModules = await Promise.all(modulePromises);

  resolvedModules.forEach((m) => initialModules.push(m.default()));

  const requestEffectMiddleware = (next) => (effect) => {
    if (effect.type === "CALL" && effect?.payload?.fn?.name === "apiCall") {
      Promise.resolve().then(() => {
        set(effect, "payload.args[0].requestContext", requestContext);
        next(effect);
      });
      return;
    }

    return next(effect);
  };

  const sagaExtension = getSagaExtension({ request }, [
    requestEffectMiddleware,
  ]);

  requestContext.sagaManager = sagaExtension.manager;

  // console.log("Initial state passing to createStore", initialState);

  const store = initialState
    ? createStore(initialState, enhancers, [sagaExtension], ...initialModules)
    : createStore({}, enhancers, [sagaExtension], ...initialModules);

  if (window && window.Cypress) {
    window.__store__ = store;
  }

  if (process.env.NODE_ENV === "development" && module.hot) {
    // Enable Webpack hot module replacement for reducers. This is so that we
    // don't lose all of our current application state during hot reloading.
    module.hot.accept("../../reducers", () => {
      const nextRootReducer = require("../../reducers").default; // eslint-disable-line global-require

      store.replaceReducer(nextRootReducer);
    });
  }

  // console.log("initial state", store.getState());

  return store;
}

export default configureStore;
