import { oAuthStyles } from "./OAuthStyles";

import { useStyles } from "hooks/useStyles";

const baseStyles = {
  oAuthPrompt: {
    ...oAuthStyles.oAuthPrompt,
  },
  inner: {
    ...oAuthStyles.inner,
  },
};

function OauthStyleWrapper(props) {
  const { styles, css } = useStyles(baseStyles, props);

  return (
    <div className={css(styles.oAuthPrompt)}>
      <style>
        {`html, body, #app {
      padding: 0;
      margin: 0;
      min-height: 100%;
      height: auto;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      width: 100%;
    }
    `}
      </style>
      <div className={css(styles.inner)}>{props.children}</div>
    </div>
  );
}

export default OauthStyleWrapper;
