import { css } from "aphrodite";
import { List } from "immutable";
import PropTypes from "prop-types";
import { useCallback, useRef } from "react";
import { InView } from "react-intersection-observer";

import useManagedHeight from "hooks/useManagedHeight";
import { useStyles } from "hooks/useStyles";

const baseStyles = {
  sensorItem: {
    width: "100%",
    overflow: "hidden",
  },
  inlineSensorItem: {
    display: "flex",
    flex: 1,
  },
};

const InfiniteListSensorItem = (props) => {
  const {
    renderItem,
    items,
    loadAtPercentage,
    loadItemsSize,
    onLoadMore,
    loadOnCountFromEnd,
    inlineItems,
    index,
    id,
    isCarousel,
    getHeightManagementId,
  } = props;
  const { styles } = useStyles(baseStyles, props);
  const itemRef = useRef(null);
  const heightManagerId = getHeightManagementId
    ? getHeightManagementId(id, index)
    : null;
  const mainClassName = css(
    inlineItems ? styles.inlineSensorItem : styles.sensorItem
  );

  useManagedHeight(heightManagerId, itemRef);

  const handleVisibilityChange = useCallback(
    (isVisible = false) => {
      if (isVisible) {
        const loadedItemsCount = items.length || items.size;
        let startLoadingAtIndex = loadedItemsCount - 10;

        if (loadOnCountFromEnd) {
          startLoadingAtIndex = loadedItemsCount - loadOnCountFromEnd;
        } else if (loadAtPercentage && loadItemsSize) {
          const startLoadingLimit = parseInt(
            loadItemsSize * (loadAtPercentage / 100),
            10
          );

          startLoadingAtIndex = loadedItemsCount - startLoadingLimit;
        }

        if (index > startLoadingAtIndex) {
          onLoadMore(id, index);
        }
      }
    },
    [
      onLoadMore,
      items,
      loadAtPercentage,
      loadItemsSize,
      loadOnCountFromEnd,
      id,
      index,
    ]
  );

  const renderContent = () => {
    const content = id ? renderItem(id, index, isCarousel) : <div />;

    if (getHeightManagementId) {
      return (
        <div ref={itemRef} className={mainClassName}>
          {content}
        </div>
      );
    }

    return content;
  };

  return (
    <InView
      key={index}
      onChange={handleVisibilityChange}
      threshold={0.1}
      className={mainClassName}
    >
      {renderContent()}
    </InView>
  );
};

InfiniteListSensorItem.propTypes = {
  renderItem: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func,
  items: PropTypes.oneOfType([PropTypes.array, PropTypes.instanceOf(List)]),
  loadItemsSize: PropTypes.number,
  loadAtPercentage: PropTypes.number,
  loadOnCountFromEnd: PropTypes.number,
  inlineItems: PropTypes.bool,
  index: PropTypes.number.isRequired,
  id: PropTypes.any.isRequired,
  isCarousel: PropTypes.bool,
  getHeightManagementId: PropTypes.func,
};

InfiniteListSensorItem.defaultProps = {
  items: [],
  loadItemsSize: undefined,
  loadAtPercentage: undefined,
  loadOnCountFromEnd: undefined,
  onLoadMore: null,
  inlineItems: false,
  isCarousel: false,
  getHeightManagementId: null,
};

export default InfiniteListSensorItem;
