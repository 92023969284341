import generateTransition from "utils/generateTransition";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

export const mobileHeaderPadding = 20;

export const showRegisterAbove = 330;

const headerStyles = {
  menuToggleContainer: {
    color: colours.white,
    display: "flex",
    alignItems: "center",
    lineHeight: 1,
    paddingLeft: mobileHeaderPadding - 10, // Minus normal column padding

    border: "none",

    [ScreenSizes.smOnly]: {
      paddingRight: 0,
    },
    [ScreenSizes.lgAndAbove]: {
      display: "none",
    },
  },
  menuToggle: {
    cursor: "pointer",
    border: "none",

    [ScreenSizes.mdAndBelow]: {
      alignSelf: "center",
      fontSize: "1.7rem",
      lineHeight: 1,
      color: colours.white,
      zIndex: 99,
    },
  },
  topBar: {
    [ScreenSizes.mdAndBelow]: {
      justifyContent: "flex-end",
    },
  },

  content: {
    alignSelf: "center",
  },
  title: {
    ...gStyles.fontBold,
    color: colours.white,
    textAlign: "center",
    textTransform: "uppercase",
    cursor: "default",
    fontSize: "1.4em",
    lineHeight: 2,
    letterSpacing: 4,
    marginTop: "0.4em",
    marginBottom: "1.2em",

    [ScreenSizes.smAndAbove]: {
      fontSize: "1.8em",
      letterSpacing: 4,
      marginBottom: "1.2em",
    },
    [ScreenSizes.mdAndAbove]: {
      fontSize: "2.6em",
      letterSpacing: 12,
      marginBottom: "1.2em",
    },
    [ScreenSizes.lgAndAbove]: {
      fontSize: "2.8em",
      letterSpacing: 12,
      marginBottom: "1.2em",
    },
    [ScreenSizes.xlAndAbove]: {
      fontSize: "3.4em",
      letterSpacing: 14,
      marginBottom: "1em",
    },
  },
  titleLine: {
    display: "block",
  },
  subTitle: {
    ...gStyles.fontRegular,
    color: colours.white,
    textAlign: "center",
    textTransform: "uppercase",
    marginBottom: 10,
    letterSpacing: 8,
    fontSize: "0.9em",

    [ScreenSizes.xlAndAbove]: {
      marginBottom: 20,
      letterSpacing: 8,
      fontSize: "1.1em",
    },
  },
  search: {
    paddingBottom: 50,
    alignSelf: "center",
    width: "100%",
    maxWidth: 500,

    [ScreenSizes.mdAndBelow]: {
      paddingLeft: 5,
      paddingRight: 5,
    },
  },

  headerContentContainer: {
    top: 0,
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "grid",
    placeItems: "Center",

    [ScreenSizes.mdAndBelow]: {
      top: "auto",
      position: "static",
    },
  },
  headerContent: {
    ...gStyles.contentContainer,
    width: "100%",
    height: "100%",
    transition: generateTransition({
      target: "visual",
      targets: ["height"],
      speed: "150ms",
    }),
    maxHeight: "5rem",

    [ScreenSizes.xsOnly]: {
      ...gStyles.contentContainer[ScreenSizes.xsOnly],
    },
    [ScreenSizes.smOnly]: {
      ...gStyles.contentContainer[ScreenSizes.smOnly],
      paddingTop: 0,
      paddingBottom: 0,
    },
    [ScreenSizes.mdOnly]: {
      ...gStyles.contentContainer[ScreenSizes.mdOnly],
      paddingTop: 0,
      paddingBottom: 0,
    },
    [ScreenSizes.lgOnly]: {
      ...gStyles.contentContainer[ScreenSizes.lgOnly],
      paddingTop: 0,
      paddingBottom: "0.313rem",
    },
    [ScreenSizes.xlOnly]: {
      ...gStyles.contentContainer[ScreenSizes.xlOnly],
      paddingTop: 0,
      paddingBottom: 0,
    },
    [ScreenSizes.xlAndAbove]: {
      ...gStyles.contentContainer[ScreenSizes.xlAndAbove],
      paddingTop: 0,
      paddingBottom: 0,
    },
    [ScreenSizes.mdAndBelow]: {
      ...gStyles.contentContainer[ScreenSizes.mdAndBelow],
      paddingTop: 0,
      paddingBottom: 0,
      top: "auto",
      position: "static",
    },
  },
  headerRow: {
    height: "100%",
    marginLeft: "-1rem",
    marginRight: "-1rem",

    [ScreenSizes.mdAndBelow]: {
      justifyContent: "space-between",
      marginLeft: 0,
      marginRight: 0,
    },
  },
  menuContainer: {
    width: "100%",
    maxWidth: "50%",
  },
  headerSearch: {
    opacity: 1,
    transition: "opacity 0.5s",

    [ScreenSizes.mdAndBelow]: {
      ...gStyles.gradientBackground,
      position: "fixed", // Was absolute but it covered verification bar
      left: 0,
      top: -80,
      margin: 0,
      width: "100%",
      maxWidth: "100%",
      padding: 8,
      boxShadow: "inset 0 0em 0.6em rgba(0,0,0,0.04)",
      display: "block",
      zIndex: 100,
      transition: generateTransition({
        target: "visual",
        targets: ["transform"],
        speed: "250ms",
      }),
    },

    [ScreenSizes.xlAndAbove]: {
      minWidth: "100%",
    },
  },
  showHeaderSearch: {
    [ScreenSizes.mdAndBelow]: {
      transform: "translateY(80px)",
      position: "absolute",
    },
  },
  searchToggle: {
    color: colours.white,
    alignSelf: "center",
    fontSize: "1.25rem",
    lineHeight: 1,
    display: "none",
    cursor: "pointer",
    border: "none",

    [ScreenSizes.mdAndBelow]: {
      display: "block",
      marginRight: "2rem",
    },
    [ScreenSizes.smAndBelow]: {
      display: "block",
      marginRight: "1.5rem",
    },
  },
  searchToggleLeft: {
    marginLeft: "1.5rem",
  },
  sidebarToggle: {
    color: colours.white,
    alignSelf: "center",
    fontSize: "1.4rem",
    padding: "0 0.3em",
    border: `2px solid ${colours.white}`,
    marginLeft: 10,
  },
  loggingInIcon: {
    color: colours.white,
    fontSize: "1.4em",
  },
  notifications: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    listStyle: "none",
    margin: "0 0 0 1rem",
    padding: 0,
    color: colours.white,
    marginRight: "1.5rem",

    [ScreenSizes.smAndAbove]: {
      marginLeft: "1.25rem",
    },

    [ScreenSizes.mdAndAbove]: {
      marginRight: "1.5rem",
    },

    [ScreenSizes.lgAndAbove]: {
      marginLeft: 0,
    },
  },
  whiteNavBarNotificationItem: {
    color: colours.bodyText,
  },
  notificationItem: {
    listStyle: "none",
    margin: "0 0.5rem 0 0",
    padding: 0,

    [ScreenSizes.mdAndAbove]: {
      marginRight: "0.75rem",
    },
    [ScreenSizes.lgAndAbove]: {
      marginRight: "1rem",
    },

    ":last-child": {
      marginRight: 0,
    },
  },
};

export default headerStyles;
