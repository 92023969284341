import { Map } from "immutable";
import PropTypes from "prop-types";

import BaseEntityLink from "./BaseEntityLink";

import { selectSpecificPodcast } from "selectors/podcast";

import usePodcastUrl from "hooks/usePodcastUrl";
import useReduxState from "hooks/useReduxState";

const PodcastLink = (props) => {
  const { entity: passedEntity, entity_id } = props;

  const entity = useReduxState(
    (state) => passedEntity || selectSpecificPodcast(state, entity_id),
    [passedEntity, entity_id]
  );

  const podcastUrl = usePodcastUrl(entity);

  return (
    <BaseEntityLink
      {...props}
      entity={entity}
      to={podcastUrl}
      name={entity && entity.get("title")}
      isClaimed={entity && entity.get("is_claimed")}
      isClaimableEntity
      isProfileType
    />
  );
};

PodcastLink.propTypes = {
  entity: PropTypes.instanceOf(Map),
  entity_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

PodcastLink.defaultProps = {
  entity: null,
  entity_id: null,
};

export default PodcastLink;
