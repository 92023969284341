import Loadable from "@loadable/component";
const SiteTourModal = Loadable(() => import("./SiteTourModal"));

function SiteTourModalAsyncLoading() {
  return null;
}

const SiteTourModalContainer = (props) => {
  return <SiteTourModal {...props} fallback={<SiteTourModalAsyncLoading />} />;
};

export default SiteTourModalContainer;
