import Loadable from "@loadable/component";
const PromptModal = Loadable(() => import("./PromptModal"));

function PromptModalAsyncLoading() {
  return null;
}

const PromptModalContainer = (props) => {
  return <PromptModal {...props} fallback={<PromptModalAsyncLoading />} />;
};

export default PromptModalContainer;
