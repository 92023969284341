import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";

import appActions from "actions/app";
import { getQueries } from "utils/url";

import useActionCreators from "hooks/useActionCreators";

const UTMCatcher = () => {
  const location = useLocation();
  const { search } = location;

  const { catchUTM } = useActionCreators({ catchUTM: appActions.catchUTM });

  const catchParams = useCallback(() => {
    let utm_params = Object.entries(getQueries(search.substr(1))).reduce(
      (result, [value, key]) => {
        if (key.substr(0, 3).toLowerCase() === "utm") {
          return { ...result, [key]: value };
        }
        return result;
      },
      {}
    );

    catchUTM(utm_params);
  }, [catchUTM, search]);

  useEffect(() => {
    catchParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // only on search updates
  useEffect(() => {
    catchParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return null;
};

export default UTMCatcher;
