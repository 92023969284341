import { css } from "aphrodite";
import { Map, List } from "immutable";
import PropTypes from "prop-types";
import { memo, useMemo } from "react";
import { Link } from "react-router-dom";

import getCategoryUrl from "utils/entity/getCategoryUrl";
import { capitalize } from "utils/misc";
import commaList from "utils/text/commaList";
import indefiniteArticle from "utils/text/indefiniteArticle";
import { getCreatorUrl } from "utils/url/creatorUrls";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import getHoverQuery from "styles/getHoverQuery";

const baseStyles = {
  link: {
    ...gStyles.link,
    color: colours.grey,
    textDecoration: "none",

    ...getHoverQuery({
      textDecoration: "underline",
    }),
  },
  subtleLink: {
    ...gStyles.link,
    color: "inherit",
  },
};

const PodcastSubtitle = (props) => {
  const { podcast, hosts, subtle, useUncategorised } = props;
  const { styles } = useStyles(baseStyles, props);

  const categoryBit = useMemo(() => {
    if (!podcast.has("categories") || podcast.get("categories").size === 0) {
      return useUncategorised ? "An uncategorised podcast" : "A podcast";
    }

    const article = capitalize(
      indefiniteArticle(podcast.get("categories").first().get("text"))
    );

    const categories = commaList(
      podcast.get("categories").slice(0, 3),
      (cat, i) => (
        <Link
          className={css(styles.link, subtle && styles.subtleLink)}
          to={getCategoryUrl(cat, "/podcasts")}
          key={`${cat.get("slug") && cat.get("slug")}-${i}`}
        >
          {cat.get("text")}
        </Link>
      )
    );

    return (
      <span>
        <span>{article}</span> {categories} <span>podcast</span>
      </span>
    );
  }, [podcast, subtle, useUncategorised, styles.link, styles.subtleLink]);

  const hostList = useMemo(
    () =>
      commaList(hosts.slice(0, 3), (host, i) => (
        <Link
          key={`${host.get("name") && host.get("name").join("-")}-${i}`}
          to={getCreatorUrl(host)}
        >
          {host.get("name")}
        </Link>
      )),
    [hosts]
  );

  const hostsBit = () => {
    if (!hosts || hosts.size === 0) {
      return "";
    }

    return (
      <span>
        <span> featuring </span>
        {hostList}
      </span>
    );
  };

  return (
    <span>
      {categoryBit}
      {hostsBit()}
    </span>
  );
};

PodcastSubtitle.propTypes = {
  podcast: PropTypes.instanceOf(Map).isRequired,
  hosts: PropTypes.instanceOf(List),
  subtle: PropTypes.bool,
  useUncategorised: PropTypes.bool,
};
PodcastSubtitle.defaultProps = {
  hosts: List([]),
  subtle: false,
  useUncategorised: true,
};

export default memo(PodcastSubtitle);
