export const startTime = (server = false) => {
  if (!server) {
    return () => 0;
  }
  const start = process.hrtime();
  return () => {
    const time = getProcessTimeInSeconds(start);
    return time;
  };
};

export const getProcessTimeInSeconds = (startTime) => {
  const timeTaken = process.hrtime(startTime);
  const timeTakenSeconds = timeTaken[0] + timeTaken[1] / 1000000000;
  return timeTakenSeconds;
};

const timeInMs = () => {
  if (typeof process !== "undefined" && process.hrtime) {
    const time = process.hrtime();
    return time[0] * 1000 + time[1] / 1000000;
  }
  if (performance) {
    return performance.now();
  }
};

export const axiosTiming = (instance, callback) => {
  instance.interceptors.request.use((request) => {
    request.ts = timeInMs();

    return request;
  });

  instance.interceptors.response.use((response) => {
    callback(Number(timeInMs() - response.config.ts));
    return response;
  });
};
