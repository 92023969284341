import { Map } from "immutable";
import PropTypes from "prop-types";
import { Fragment, useCallback, useMemo } from "react";

import { useEntityFromTimeline } from "../useEntityFromTimeline";
import Activity from "./Activity/ActivityAsync";

const IntegrationVoteActivity = (props) => {
  const { activity, mobile } = props;

  const { entity: user } = useEntityFromTimeline(activity.get("actor"));
  const { entity: integration } = useEntityFromTimeline(activity.get("object"));

  const renderIntroText = useCallback(
    ({ renderEntityLink }) => (
      <Fragment>
        {renderEntityLink("user", user)}
        {" voted for an integration"}
      </Fragment>
    ),
    [user]
  );

  const renderIntroImage = useCallback(
    ({ renderEntityImage }) => renderEntityImage("user", user),
    [user]
  );

  const sections = useMemo(
    () => [
      {
        type: "intro",
        renderImage: renderIntroImage,
        renderText: renderIntroText,
      },
      {
        type: "entity",
        entityCardProps: {
          entity_type: "integration",
          entity: integration,
          showCreators: mobile,
          mobile,
        },
      },
    ],
    [renderIntroImage, renderIntroText, integration, mobile]
  );

  if (!activity) {
    return "No activity?";
  }

  if (!user || !integration) {
    return null;
  }

  return (
    <Activity {...props} sections={sections} loaded={!!(user && integration)} />
  );
};

IntegrationVoteActivity.propTypes = {
  activity: PropTypes.instanceOf(Map),
  mobile: PropTypes.bool,
};

IntegrationVoteActivity.defaultProps = {
  activity: Map(),
  mobile: false,
};

export default IntegrationVoteActivity;
