import { faClock } from "@fortawesome/free-solid-svg-icons/faClock";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback } from "react";

import BasicTooltip from "components/Common/Tooltip/BasicTooltip";

import searchActions from "actions/search";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import useClearSearchResults from "hooks/useClearSearchResults";
import useHover from "hooks/useHover";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  resultItem: {
    display: "flex",
    flexDirecton: "row",
    alignItems: "center",
    ":last-child": {
      marginBottom: 0,
    },

    padding: "0 1.2rem",
    [ScreenSizes.lgAndAbove]: {
      padding: "0 1.563rem",
    },
  },
  title: {
    display: "-webkit-box",
    "-webkit-line-clamp": "1",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    width: "100%",
    lineHeight: 2,
    padding: "0.625rem 0",
    [ScreenSizes.lgAndAbove]: {
      padding: "0.625rem 0",
    },
  },
  resultItemHover: {
    backgroundColor: colours.pinkMenuHover,
  },
  removeButton: {
    padding: "10px",
    background: "transparent",
    cursor: "pointer",
    border: "none",
    marginLeft: "auto",
    opacity: "25%",
  },
  clockIcon: {
    marginRight: "1rem",
    [ScreenSizes.lgAndAbove]: {
      marginLeft: "0.7rem",
    },
  },
};

function TopSearchRecentSearchesItem(props) {
  const { result } = props;
  const { styles, css } = useStyles(baseStyles, props);
  const [resultItemHoverRef, isResultItemHovered] = useHover();
  const { deleteRecentSearch } = useActionCreators({
    deleteRecentSearch: searchActions.deleteRecentSearch,
  });
  const savedSearchTerm = result.get("term");
  const linkUrl = `/search/all/q/${savedSearchTerm}`;

  const handleDelete = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      deleteRecentSearch({ search_term: savedSearchTerm });

      sendGAEvent({
        action: "deleteSearchHistoryItem",
        title: savedSearchTerm,
      });
    },
    [savedSearchTerm, deleteRecentSearch]
  );

  const clearSearchResults = useClearSearchResults();

  const handleItemClick = () => {
    sendGAEvent({
      action: "TopSearchSubmit",
      search_term: savedSearchTerm,
      context: "RecentSearchTermUsed",
    });

    clearSearchResults();
  };

  return (
    <a
      href={linkUrl}
      onClick={handleItemClick}
      ref={resultItemHoverRef}
      className={css(
        styles.resultItem,
        isResultItemHovered && styles.resultItemHover
      )}
      data-testid="result-item"
    >
      <FontAwesomeIcon className={css(styles.clockIcon)} icon={faClock} />

      <p title={savedSearchTerm} className={css(styles.title)}>
        {savedSearchTerm}
      </p>

      <button
        onClick={handleDelete}
        data-testid="delete-history-item"
        className={css(styles.removeButton)}
      >
        <BasicTooltip
          renderTooltip={() => "Delete from history"}
          zIndex={99999}
        >
          {(tooltipProps) => (
            <div {...tooltipProps}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
          )}
        </BasicTooltip>
      </button>
    </a>
  );
}

export default TopSearchRecentSearchesItem;
