import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const AddPodcast = Loadable(() => import("./AddPodcast"));

function AddPodcastAsyncLoading(props) {
  return <LoadableLoading {...props} page />;
}

const AddPodcastContainer = (props) => {
  return <AddPodcast {...props} fallback={<AddPodcastAsyncLoading />} />;
};

export default AddPodcastContainer;
