export default function getEntityType(entity) {
  if (entity.has("updated_at") && entity.has("privacy")) {
    return "userlist"; // This has to come first because entity_type isn't the same as on other entities.
  }
  if (entity.has("podcast") && entity.has("air_date")) {
    return "episode";
  }
  if (entity.has("number_of_episodes")) {
    return "podcast";
  }
  if (entity.has("role_summary")) {
    return "creator";
  }
  if (entity.has("username")) {
    return "user";
  }
  if (entity.has("entity_type")) {
    // Last resort, this isn't as reliable as I thought.
    return entity.get("entity_type");
  }
  return null;
}
