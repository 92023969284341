import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

import ScreenSizes from "styles/ScreenSizes";

export const loadingStyles = {
  height: {
    height: "135rem",
    maxHeight: "135rem",
    minHeight: 0,

    [ScreenSizes.lgAndAbove]: {
      height: "136rem",
      maxHeight: "136rem",
    },
  },
};

const SearchResults = Loadable(() =>
  import(/* webpackChunkName: "SearchResults" */ "./SearchResults")
);

function SearchResultsAsyncLoading(props) {
  return <LoadingOverlay height={"135rem"} styles={loadingStyles} />;
}

const SearchResultsContainer = (props) => {
  return <SearchResults {...props} fallback={<SearchResultsAsyncLoading />} />;
};

export default SearchResultsContainer;
