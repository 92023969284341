import { useEffect } from "react";

import modalActions from "actions/modals";
import { setShowLogs } from "utils/dev";

import useActionCreators from "hooks/useActionCreators";
import useLoggedInUser, { useLoggedIn } from "hooks/useLoggedInUser";
import useQuery from "hooks/useQuery";

const AppActionHandler = () => {
  const { showModal } = useActionCreators({
    showModal: modalActions.showModal,
  });

  const [query, setQuery] = useQuery();

  const isLoggedIn = useLoggedIn();
  const user = useLoggedInUser();

  useEffect(() => {
    setShowLogs(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setShowLogs(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn && query.modal) {
      const modalProps = Object.entries(query).reduce((pr, [key, value]) => {
        if (key.includes("modalprop_")) {
          return {
            ...pr,
            [key.replace("modalprop_", "")]: value,
          };
        }

        return pr;
      }, {});
      showModal(query.modal, modalProps);
      setQuery({});
    }
    // only run if isLoggedIn changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return null;
};

export default AppActionHandler;
