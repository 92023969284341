import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const IntegrationDetailsModal = Loadable(() =>
  import("./IntegrationDetailsModal")
);

function IntegrationDetailsModalAsyncLoading(props) {
  return <LoadableLoading {...props} />;
}

const IntegrationDetailsModalContainer = (props) => {
  return (
    <IntegrationDetailsModal
      {...props}
      fallback={<IntegrationDetailsModalAsyncLoading />}
    />
  );
};

export default IntegrationDetailsModalContainer;
