import { css } from "aphrodite";
import React from "react";

import NavDropdownMenu, {
  defaultMenuStyles,
} from "./NavMenuDropdownItem/DropdownNavMenu";
import useToolNavLinks from "./useToolNavLinks";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  toolsNavMenu: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "51rem",

    [ScreenSizes.lgAndAbove]: {
      flexDirection: "row",
    },
  },
  toolsNavMenuDivider: {
    backgroundColor: colours.greyBorder,
    minHeight: "1px",
    width: "calc(100% - 2.5rem)",
    margin: "0 1.25rem",

    [ScreenSizes.lgAndAbove]: {
      minHeight: "calc(100% - 2rem)",
      width: "1px",
      margin: "1rem 0",
    },
  },
};

const leftMenuStyles = {
  ...defaultMenuStyles,
  menuItem: {
    ...defaultMenuStyles.menuItem,
    paddingRight: "1.5rem",
  },
};

const ProToolsNavMenu = (props) => {
  const { styles } = useStyles(baseStyles, props);
  const { brandLinks } = useToolNavLinks();

  return (
    <div className={css(styles.toolsNavMenu)}>
      <NavDropdownMenu
        {...props}
        menuItems={brandLinks}
        menuStyles={leftMenuStyles}
        asLink
      />
    </div>
  );
};

export default ProToolsNavMenu;
