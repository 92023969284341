import { Map } from "immutable";
import PropTypes from "prop-types";

import BaseEntityLink from "./BaseEntityLink";

import { selectSpecificUser } from "selectors/user";
import getUserDisplayName from "utils/entity/getUserDisplayName";
import getUserUrl from "utils/entity/getUserUrl";

import useReduxState from "hooks/useReduxState";

const UserLink = (props) => {
  const { entity: passedEntity, entity_id } = props;

  const entity = useReduxState(
    (state) => passedEntity || selectSpecificUser(state, entity_id),
    [passedEntity, entity_id]
  );

  return (
    <BaseEntityLink
      {...props}
      entity={entity}
      to={getUserUrl(entity)}
      name={getUserDisplayName(entity)}
      isProfileType
    />
  );
};

UserLink.propTypes = {
  entity: PropTypes.instanceOf(Map),
  entity_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

UserLink.defaultProps = {
  entity: null,
  entity_id: null,
};

export default UserLink;
