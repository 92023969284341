import { flushToStyleTag } from "aphrodite";
import { useContext } from "react";

import RequestContext from "pages/RequestContext";

import useLayoutEffect from "hooks/useLayoutEffect";

function useStyleFlush(doIt) {
  const requestContext = useContext(RequestContext);

  if (requestContext.server) {
    return null;
  }

  // We only skip this hook on the server
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useLayoutEffect(() => {
    if (doIt) {
      flushToStyleTag();
    }
  });
}

export default useStyleFlush;
