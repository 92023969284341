import { useRef } from "react";
import { useInView } from "react-intersection-observer";
import scrollIntoView from "scroll-into-view";

import useLayoutEffect from "hooks/useLayoutEffect";

export default ({
  focused = false,
  intersectingRef = null,
  forceScrollTo = false,
  disabled = false,
}) => {
  const elementRef = useRef(null);
  const [setVisibleRef, inView] = useInView({ threshold: 1 });
  const hasRef = !!elementRef.current;
  const intersectingRect =
    intersectingRef && intersectingRef.current
      ? intersectingRef.current.getBoundingClientRect()
      : null;
  const elementRect =
    elementRef && elementRef.current
      ? elementRef.current.getBoundingClientRect()
      : null;
  const isIntersecting =
    intersectingRect &&
    elementRect &&
    !(
      elementRect.right < intersectingRect.left ||
      elementRect.left > intersectingRect.right ||
      elementRect.bottom < intersectingRect.top ||
      elementRect.top > intersectingRect.bottom
    );

  useLayoutEffect(() => {
    if (hasRef) {
      setVisibleRef(elementRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasRef]);

  useLayoutEffect(() => {
    if (
      (focused || forceScrollTo) &&
      (!inView || isIntersecting) &&
      !disabled
    ) {
      scrollIntoView(elementRef.current, { time: 100 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focused, forceScrollTo]);

  return elementRef;
};
