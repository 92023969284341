import { css } from "aphrodite";
import PropTypes from "prop-types";
import React, { useCallback } from "react";

import LoginButton from "components/Layout/Header/LoginButton";

import authActions from "actions/auth";
import * as authStates from "constants/auth-states";

import useActionCreators from "hooks/useActionCreators";
import { useStyles } from "hooks/useStyles";

const baseStyles = {
  loginButton: {
    display: "inline-block",
    marginTop: "-0.25rem",
    minWidth: "max-content",
  },
  signupButton: {
    display: "inline-block",
  },
  expandContainer: {
    display: "flex",
  },
  expandButton: {
    flex: "1 1 50%",
  },
  buttons: {
    display: "flex",
    alignItems: "center",
  },
};

const LoginButtonContainer = (props) => {
  const { expand, login } = props;
  const { styles } = useStyles(baseStyles, props);

  const { startAuthFlow } = useActionCreators({
    startAuthFlow: authActions.startAuthFlow,
  });

  const showLoginModal = useCallback(
    () =>
      startAuthFlow(authStates.LOGIN_FORM, {
        analyticsVariables: {
          triggered_by: "loginButton",
        },
      }),
    [startAuthFlow]
  );

  return (
    <div
      className={css(expand && styles.expandContainer, styles.buttons)}
      data-id="login_signup_buttons"
    >
      {login && (
        <span
          className={css(styles.signupButton, expand && styles.expandButton)}
        >
          <LoginButton
            dataId="header-login-button"
            onClick={showLoginModal}
            expand={expand}
          />
        </span>
      )}
    </div>
  );
};

LoginButtonContainer.propTypes = {
  login: PropTypes.bool,
  signup: PropTypes.bool,
  expand: PropTypes.bool,
  switchToWhiteStyles: PropTypes.bool,
};

LoginButtonContainer.defaultProps = {
  login: true,
  signup: true,
  expand: false,
};

export default React.memo(LoginButtonContainer);
