import { useEffect } from "react";

import { setLoggedOut, setUser } from "utils/ga";

import useLoggedInUser from "hooks/useLoggedInUser";
import useUserFeaturedBadge from "hooks/useUserFeaturedBadge";

/** this is just to have a single place to trigger any user based JS that relies on the page being mounted */
const UserWatcher = () => {
  const user = useLoggedInUser();
  const badge = useUserFeaturedBadge(user);

  useEffect(() => {
    if (user) {
      setUser(user, badge);
    } else {
      setLoggedOut();
    }
  }, [badge, user]);

  return null;
};

export default UserWatcher;
