import PropTypes from "prop-types";
import { memo, useContext, useMemo } from "react";

import ActivityContext from "components/Analytics/ActivityContext";
import { TimelineContext } from "components/Analytics/TimelineProvider";
import { useAggregate } from "components/Timeline/Activities/Activity/AggregateUtils";
import AppearAggregate from "components/Timeline/Activities/AppearAggregate";
import FollowAggregate from "components/Timeline/Activities/FollowAggregate";
import IntegrationVoteAggregate from "components/Timeline/Activities/IntegrationVoteAggregate";
import LikedAggregate from "components/Timeline/Activities/LikedAggregate";
import RateReviewAggregate from "components/Timeline/Activities/RateReviewAggregate";
import ReleaseAggregate from "components/Timeline/Activities/ReleaseAggregate";
import UserEarnedBadgeAggregate from "components/Timeline/Activities/UserEarnedBadgeAggregate";
import UserListAggregate from "components/Timeline/Activities/UserListAggregate";
import TimelineActivitySelector from "components/Timeline/TimelineActivitySelector";

const TimelineAggregateSelector = memo((props) => {
  const { group, ...rest } = props;
  const timelineContext = useContext(TimelineContext);
  const aggregateProps = useAggregate(group);
  const { activitiesByVerb, activities } = aggregateProps;

  const aggregateRenderProps = useMemo(
    () => ({
      activities,
      aggregateProps,
      group,
      ...rest,
    }),
    [activities, aggregateProps, group, rest]
  );

  let card = null;
  let feed_card_type = "Unknown";

  if (
    activitiesByVerb.has("add_item_to_list") ||
    activitiesByVerb.has("publish_list")
  ) {
    card = <UserListAggregate {...aggregateRenderProps} />;
    feed_card_type = "userListAggregate";
  } else if (
    activitiesByVerb.has("appear") &&
    !activitiesByVerb.has("release")
  ) {
    card = <AppearAggregate {...aggregateRenderProps} />;
    feed_card_type = "appearAggregate";
  } else if (activitiesByVerb.has("follow")) {
    card = <FollowAggregate {...aggregateRenderProps} />;
    feed_card_type = "followAggregate";
  } else if (activitiesByVerb.has("like")) {
    card = <LikedAggregate {...aggregateRenderProps} />;
    feed_card_type = "likedAggregate";
  } else if (activitiesByVerb.has("review") || activitiesByVerb.has("rate")) {
    card = <RateReviewAggregate {...aggregateRenderProps} />;
    feed_card_type = "rateReviewAggregate";
  } else if (activitiesByVerb.has("release")) {
    card = <ReleaseAggregate {...aggregateRenderProps} />;
    feed_card_type = "releaseAggregate";
  } else if (activitiesByVerb.has("integration_vote")) {
    card = <IntegrationVoteAggregate {...aggregateRenderProps} />;
    feed_card_type = "integrationVoteAggregate";
  } else if (activitiesByVerb.has("award_badge")) {
    card = <UserEarnedBadgeAggregate {...aggregateRenderProps} />;
    feed_card_type = "badge_awarded";
  }

  const contextValue = useMemo(
    () => ({
      ...(timelineContext || {}),
      feed_card_type,
      in_feed: true,
    }),
    [feed_card_type, timelineContext]
  );

  if (
    activities.size === 1 ||
    (activitiesByVerb.has("add_item_to_list") &&
      activitiesByVerb.has("publish_list") &&
      activitiesByVerb.get("add_item_to_list").size === 1)
  ) {
    return <TimelineActivitySelector activity={activities.first()} {...rest} />;
  }

  if (!card) {
    return <TimelineActivitySelector activity={activities.first()} {...rest} />;
  }

  return (
    <ActivityContext.Provider value={contextValue}>
      {card}
    </ActivityContext.Provider>
  );
});

TimelineAggregateSelector.propTypes = {
  group: PropTypes.object.isRequired,
};

TimelineAggregateSelector.displayName = "TimelineAggregateSelector";

export default memo(TimelineAggregateSelector);
