import PropTypes from "prop-types";
import { memo, useContext, useEffect } from "react";

import { LoadableContext } from "pages/Common/TopLevelRoute";

import LoadingOverlay from "./LoadingOverlay";

import useWindowSize from "hooks/useWindowSize";

function LoadableLoading(props) {
  const { pastDelay, transparent, height, noPadding, noOverlay, page } = props;

  const { isWindowSizeOrMore } = useWindowSize();

  const { loadableChange } = useContext(LoadableContext);

  useEffect(() => {
    loadableChange(true);
    return () => loadableChange(false);
  }, [loadableChange]);

  return (
    <LoadingOverlay
      height={page ? 500 : height}
      withPageHeader={transparent && isWindowSizeOrMore("large")}
      noSpinner={!pastDelay}
      key="loadableLoadingLoadingOverlay"
      noPadding={!page && noPadding}
      noOverlay={!page && noOverlay}
    />
  );
}

LoadableLoading.propTypes = {
  pastDelay: PropTypes.any,
  error: PropTypes.node,
  transparent: PropTypes.bool,
  height: PropTypes.string,
  noPadding: PropTypes.bool,
  noOverlay: PropTypes.bool,
  page: PropTypes.bool,
};

LoadableLoading.defaultProps = {
  pastDelay: null,
  error: null,
  transparent: false,
  height: null,
  noPadding: true,
  noOverlay: true,
  page: false,
};

export default memo(LoadableLoading);
