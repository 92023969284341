import { selectBadgeById } from "selectors/badges";

import useReduxState from "hooks/useReduxState";

export default function useUserFeaturedBadge(user) {
  const userBadge = user?.get("featuredBadge")?.toString();

  return useReduxState(
    (state) => selectBadgeById(state.badges, userBadge),
    [userBadge]
  );
}
