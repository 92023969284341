import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const ProfileReviewedView = Loadable(() => import("./ProfileReviewedView"));

function ReviewedViewAsyncLoading(props) {
  return <LoadableLoading {...props} page />;
}

const ProfileReviewedViewContainer = (props) => {
  return (
    <ProfileReviewedView {...props} fallback={<ReviewedViewAsyncLoading />} />
  );
};

export default ProfileReviewedViewContainer;
