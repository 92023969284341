import { Map } from "immutable";
import PropTypes from "prop-types";
import { useMemo } from "react";

import LazyLoadComponent from "components/Common/LazyLoad/LazyLoadComponent";
import EpisodeTypeBadge from "components/Podcast/EpisodeTypeBadgeContainer";

import BaseEntityLink from "./BaseEntityLink";

import { EPISODE_TYPE_BADGE_HEIGHT } from "constants/podcast";
import {
  selectSpecificEpisode,
  selectSpecificPodcast,
} from "selectors/podcast";
import getEpisodeUrl from "utils/entity/getEpisodeUrl";

import useReduxState from "hooks/useReduxState";

const episodeTypeBadgeStyles = {
  outer: {
    marginRight: "0.5em",
  },
};

const EpisodeLink = (props) => {
  const {
    entity: passedEntity,
    entity_id,
    children,
    hideEpisodeTypeBadge,
    renderOnMount,
  } = props;

  const pulledEntity = useReduxState(
    (state) => selectSpecificEpisode(state, entity_id),
    [entity_id]
  );
  const entity = passedEntity || pulledEntity;

  const episodeAlreadyHasPodcastEntity =
    entity && typeof entity.get("podcast") === "object";
  const loadedPodcast = useReduxState(
    (state) =>
      selectSpecificPodcast(
        state,
        entity && (entity.get("podcast") || entity.get("podcast_id"))
      ),
    [passedEntity, entity_id]
  );
  const podcast = episodeAlreadyHasPodcastEntity
    ? entity && entity.get("podcast")
    : loadedPodcast;
  const entityWithPodcast = useMemo(
    () => entity && entity.set("podcast", podcast),
    [podcast, entity]
  );

  return (
    <BaseEntityLink
      {...props}
      entity={entityWithPodcast}
      to={getEpisodeUrl(entity, { podcast })}
      name={entity && entity.get("title")}
      isClaimed={entity && entity.getIn(["podcast", "is_claimed"])}
      renderOnMount={renderOnMount}
      badge={
        !children && !hideEpisodeTypeBadge ? (
          <LazyLoadComponent
            renderOnMount={renderOnMount}
            height={EPISODE_TYPE_BADGE_HEIGHT}
          >
            <EpisodeTypeBadge
              episode={entity}
              styles={episodeTypeBadgeStyles}
            />
          </LazyLoadComponent>
        ) : null
      }
    />
  );
};

EpisodeLink.propTypes = {
  entity: PropTypes.instanceOf(Map),
  entity_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.node,
  hideEpisodeTypeBadge: PropTypes.bool,
  renderOnMount: PropTypes.bool,
};

EpisodeLink.defaultProps = {
  entity: null,
  entity_id: null,
  children: null,
  hideEpisodeTypeBadge: false,
  renderOnMount: false,
};

export default EpisodeLink;
