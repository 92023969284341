export function sagaEquals(a, b) {
  if (typeof a === "function" && typeof b === "function") {
    return a === b;
  }

  if (!a || !b) {
    return a === b;
  }

  if (typeof a === "function") {
    const sagaA = a;
    const sagaB = b;
    return sagaA === sagaB.saga && !sagaB.argument;
  }
  if (typeof b === "function") {
    const sagaA = a;
    const sagaB = b;

    return sagaA.saga === sagaB && !sagaA.argument;
  }

  // both are objects
  const sagaA = a;
  const sagaB = b;

  return (
    sagaA.saga === sagaB.saga && sagaA.argument === sagaB.argument // TODO: This needs to be a deep equals
  );
}
