import Loadable from "@loadable/component";
const DeleteAlertModal = Loadable(() => import("./DeleteAlertModal"));

function DeleteAlertModalAsyncLoading() {
  return null;
}

const DeleteAlertModalContainer = (props) => {
  return (
    <DeleteAlertModal {...props} fallback={<DeleteAlertModalAsyncLoading />} />
  );
};

export default DeleteAlertModalContainer;
