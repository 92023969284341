import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo, useCallback } from "react";

import { modalButtonStyles } from "components/Global/Buttons/buttonStyles";

import generateTransition from "utils/generateTransition";
import eventIsFieldTrigger from "utils/misc/eventIsFieldTrigger";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  closeButton: {
    ...modalButtonStyles,
    position: "absolute",
    top: ".5rem",
    right: ".5rem",
    zIndex: 10,
    transition: generateTransition({
      targets: ["right"],
      speed: "50ms",
    }),
  },
  disableCloseButton: {
    cursor: "not-allowed",
    opacity: 0.5,
  },
  light: {
    color: colours.white,

    ":hover": {
      backgroundColor: "rgba(0,0,0,0.05)",
    },
    ":focus": {
      backgroundColor: "rgba(0,0,0,0.015)",
      color: colours.white,
    },
  },
  withScrollBar: {
    [ScreenSizes.lgAndAbove]: {
      right: "calc(.5rem + 17px)",
    },
  },
};

const ModalCloseButton = (props) => {
  const { onClose, disableClose, dataId, light, withScrollBar } = props;
  const { styles } = useStyles(baseStyles, props);

  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      onClose(e);
    },
    [onClose]
  );

  const handleKeyDown = useCallback(
    (e) => {
      if (eventIsFieldTrigger(e)) {
        e.preventDefault();
        onClose(e);
      }
    },
    [onClose]
  );

  return (
    <button
      data-id={dataId || "generic-modal-close-button"}
      className={css(
        styles.closeButton,
        disableClose && styles.disableCloseButton,
        light && styles.light,
        withScrollBar && styles.withScrollBar
      )}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      aria-label="Close"
    >
      <FontAwesomeIcon icon={faTimes} />
    </button>
  );
};

ModalCloseButton.propTypes = {
  dataId: PropTypes.string,
  onClose: PropTypes.func,
  disableClose: PropTypes.bool,
  light: PropTypes.bool,
  withScrollBar: PropTypes.bool,
};

ModalCloseButton.defaultProps = {
  dataId: null,
  onClose: null,
  disableClose: false,
  light: false,
  withScrollBar: false,
};

export default memo(ModalCloseButton);
