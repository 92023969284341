import Loadable from "@loadable/component";
const LoadingModal = Loadable(() => import("./LoadingModal"));

function LoadingModalAsyncLoading() {
  return null;
}

const LoadingModalContainer = (props) => {
  return <LoadingModal {...props} fallback={<LoadingModalAsyncLoading />} />;
};

export default LoadingModalContainer;
