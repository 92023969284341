import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const ManageCreators = Loadable(() => import("./ManageCreators"));

function ManageCreatorsAsyncLoading(props) {
  return <LoadableLoading {...props} page />;
}

const ManageCreatorsContainer = (props) => {
  return (
    <ManageCreators {...props} fallback={<ManageCreatorsAsyncLoading />} />
  );
};

export default ManageCreatorsContainer;
