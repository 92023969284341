import getEntityType from "utils/entity/getEntityType";
import { truncateString } from "utils/truncate";

export function addCustomMeta(el, meta) {
  if (!el) {
    return;
  }
  Object.keys(meta).forEach((key) => {
    el.setAttribute(key, meta[key]);
  });
}

export function customMeta(meta) {
  return {
    ref: customMetaRefValue(meta),
  };
}

export function customMetaRefValue(meta) {
  return function (el) {
    addCustomMeta(el, meta);
  };
}

export function camelCaseToDashCase(text) {
  return text.replace(/([a-z][A-Z])/g, (g) => `${g[0]}-${g[1].toLowerCase()}`);
}

export function dashCaseToCamelCase(text) {
  return text.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
}

function filterMetaData(data) {
  let decodedTitle = data.title;
  let decodedDescription = data.description;
  try {
    decodedTitle = data.title && decodeURIComponent(data.title);
  } catch (e) {
    // ignore, we'll just use the default
  }

  try {
    decodedDescription =
      data.description && decodeURIComponent(data.description);
  } catch (e) {
    // ignore, we'll just use the default
  }

  return {
    ...data,
    title: decodedTitle,
    description: decodedDescription,
  };
}

export const truncateStringsToFit = (strings, length) => {
  const eachStringLength = Math.floor(length / strings.length);
  return strings.map((string) => truncateString(string, eachStringLength));
};

// See: https://github.com/joshbuchea/HEAD
// See: https://gist.github.com/lancejpollard/1978404
// See: https://dev.twitter.com/cards/markup
// See: https://davidwalsh.name/twitter-cards
export function generateMetaArray(passedData, meta = []) {
  let data = passedData;

  data = filterMetaData(data);

  const basic_names = [
    "charset",
    "viewport",
    "description",
    "name",
    "theme-color",
    "robots",
  ];
  for (let i = 0; i < basic_names.length; i++) {
    const camelName = dashCaseToCamelCase(basic_names[i]);
    if (typeof data[camelName] !== "undefined") {
      meta.push({ name: basic_names[i], content: data[camelName] });
    }
  }
  const basic_itemprops = ["name", "description"];
  for (let i = 0; i < basic_itemprops.length; i++) {
    const camelName = dashCaseToCamelCase(basic_itemprops[i]);
    if (typeof data[camelName] !== "undefined") {
      meta.push({ itemprop: basic_itemprops[i], content: data[camelName] });
    }
  }
  const basic_properties = ["name", "description"];
  for (let i = 0; i < basic_properties.length; i++) {
    const camelName = dashCaseToCamelCase(basic_properties[i]);
    if (typeof data[camelName] !== "undefined") {
      meta.push({ property: basic_properties[i], content: data[camelName] });
    }
  }

  if (typeof data.index !== "undefined" || typeof data.follow !== "undefined") {
    const robotsIndex = data.index ? "index" : "noindex";
    const robotsFollow = data.follow ? "follow" : "nofollow";
    meta.push({ name: "robots", content: `${robotsIndex},${robotsFollow}` });
  }
  if (data.type) {
    meta.push({ property: "og:type", content: data.type });
  }
  if (data.image) {
    if (typeof data.image === "string") {
      data.image = { url: data.image };
    }
    data.image.url && meta.push({ name: "image", content: data.image.url });
    data.image.url && meta.push({ itemprop: "image", content: data.image.url });
    data.image.url && meta.push({ property: "image", content: data.image.url });
    data.image.url &&
      meta.push({ property: "og:image", content: data.image.url });
    data.image.url &&
      meta.push({ property: "twitter:image", content: data.image.url });
    data.image.url &&
      meta.push({ property: "twitter:image:src", content: data.image.url });
    data.image.width &&
      meta.push({ property: "og:image:width", content: data.image.width });
    data.image.height &&
      meta.push({ property: "og:image:height", content: data.image.height });
    data.image.secure &&
      meta.push({
        property: "og:image:secure_url",
        content: data.image.secure,
      });
    data.image.secure_url &&
      meta.push({
        property: "og:image:secure_url",
        content: data.image.secure_url,
      });
    data.image.alt &&
      meta.push({ property: "og:image:alt", content: data.image.alt });
  }

  if (data.audio) {
    data.audio.url &&
      meta.push({ property: "og:audio", content: data.audio.url });
    data.audio.secure &&
      meta.push({
        property: "og:audio:secure_url",
        content: data.audio.secure,
      });
    data.audio.secure_url &&
      meta.push({
        property: "og:audio:secure_url",
        content: data.audio.secure_url,
      });
    data.audio.type &&
      meta.push({ property: "og:audio:type", content: data.audio.type });
  }

  if (data.title) {
    meta.push({ property: "title", content: data.title });
    meta.push({ property: "og:title", content: data.title });
    meta.push({ property: "twitter:title", content: data.title });
  }
  if (data.website) {
    meta.push({ property: "og:site_name", content: data.website });
  }
  if (data.locale) {
    meta.push({ property: "og:locale", content: data.locale });
  }
  if (data.url) {
    meta.push({ property: "og:url", content: data.url });
  }
  if (data.publisher) {
    meta.push({ property: "article:publisher", content: data.publisher });
  }
  if (data.description) {
    // meta.push({ name: 'description', content: data.description });
    // meta.push({ itemprop: 'description', content: data.description });
    meta.push({ property: "twitter:description", content: data.description });
    meta.push({ property: "og:description", content: data.description });
  }
  if (data.profile) {
    data.profile.firstName &&
      meta.push({
        property: "profile:first_name",
        content: data.profile.firstName,
      });
    data.profile.lastName &&
      meta.push({
        property: "profile:last_name",
        content: data.profile.lastName,
      });
    data.profile.username &&
      meta.push({
        property: "profile:username",
        content: data.profile.username,
      });
    data.profile.gender &&
      meta.push({ property: "profile:gender", content: data.profile.gender });
  }
  if (data.creator) {
    data.profile.firstName &&
      meta.push({
        property: "profile:first_name",
        content: data.profile.firstName,
      });
    data.profile.lastName &&
      meta.push({
        property: "profile:last_name",
        content: data.profile.lastName,
      });
    data.profile.username &&
      meta.push({
        property: "profile:username",
        content: data.profile.username,
      });
    data.profile.gender &&
      meta.push({ property: "profile:gender", content: data.profile.gender });
  }
  if (data.twitter) {
    data.twitter.title &&
      meta.push({ property: "twitter:title", content: data.twitter.title });
    data.twitter.description &&
      meta.push({
        property: "twitter:title",
        content: data.twitter.description,
      });
    // @podchaser
    data.twitter.site &&
      meta.push({ property: "twitter:site", content: data.twitter.site });
    // @ryanstockau
    data.twitter.creator &&
      meta.push({ property: "twitter:creator", content: data.twitter.creator });
    // summary, photo, video, product, app, gallery, and “large version” summary
    data.twitter.card &&
      meta.push({ property: "twitter:card", content: data.twitter.card });
  }
  if (!data.twitter || !data.twitter.card) {
    meta.push({ property: "twitter:card", content: "summary_large_image" });
  }
  if (typeof data.webAppCapable !== "undefined") {
    meta.push({
      name: "apple-mobile-web-app-capable",
      content:
        typeof data.webAppCapable === "string"
          ? data.webAppCapable
          : !!data.webAppCapable,
    });
  }
  return meta;
}

export const getEntitySchemaType = (entity, type = false) => {
  const useableType = type || getEntityType(entity);
  switch (useableType) {
    case "podcast":
      return "http://schema.org/PodcastSeries";
    case "episode":
      return "http://schema.org/PodcastEpisode";
    case "creator":
      return "http://schema.org/Person"; // TODO: Check
    case "review":
      return "http://schema.org/Review";
    case "user":
      return "http://schema.org/Person"; // TODO: Check
    case "userlist":
      return "http://schema.org/ItemList";
  }
};
