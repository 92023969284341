import Loadable from "@loadable/component";

import AsyncLoadingListSort from "../../Entities/ListControls/AsyncLoadingListSort";

const CustomSelectField = Loadable(() => import("./CustomSelectField"));

function CustomSelectFieldAsyncLoading(props) {
  return <AsyncLoadingListSort {...props} />;
}

const CustomSelectFieldContainer = (props) => {
  return (
    <CustomSelectField
      {...props}
      fallback={<CustomSelectFieldAsyncLoading />}
    />
  );
};

export default CustomSelectFieldContainer;
