import Loadable from "@loadable/component";

import TimelineSkeletonItem from "components/Timeline/TimelineSkeletonItem";

const ReleaseActivity = Loadable(() =>
  import(/* webpackChunkName: "ReleaseActivity" */ "./ReleaseActivity")
);

function ReleaseActivityLoading(props) {
  return <TimelineSkeletonItem />;
}

const ReleaseActivityContainer = (props) => {
  return <ReleaseActivity {...props} fallback={<ReleaseActivityLoading />} />;
};

export default ReleaseActivityContainer;
