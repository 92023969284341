import { css } from "aphrodite";

import { useStyles } from "hooks/useStyles";

const baseStyles = {
  body: {
    minHeight: "160rem",
  },
  header: {
    minHeight: "27.5rem",
    width: "100%",
    background: "#570f8a",
    position: "relative",
  },
};

const UserListViewSkeleton = (props) => {
  const { styles } = useStyles(baseStyles, props);

  return (
    <div>
      <div className={css(styles.header)} />
      <div className={css(styles.body)} />
    </div>
  );
};

export default UserListViewSkeleton;
