import Loadable from "@loadable/component";
const ListDownloadModal = Loadable(() => import("./ListDownloadModal"));

function ListDownloadModalAsyncLoading() {
  return null;
}

const ListDownloadModalContainer = (props) => {
  return (
    <ListDownloadModal
      {...props}
      fallback={<ListDownloadModalAsyncLoading />}
    />
  );
};

export default ListDownloadModalContainer;
