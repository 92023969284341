import Loadable from "@loadable/component";

import TimelineSkeletonItem from "components/Timeline/TimelineSkeletonItem";
const FollowActivity = Loadable(() =>
  import(/* webpackChunkName: "FollowActivity" */ "./FollowActivity")
);

function FollowActivityLoading(props) {
  return <TimelineSkeletonItem />;
}

const FollowActivityContainer = (props) => {
  return <FollowActivity {...props} fallback={<FollowActivityLoading />} />;
};

export default FollowActivityContainer;
