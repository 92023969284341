import Loadable from "@loadable/component";

import LoadableLoading from "../../components/Common/LoadableLoading";

const UserListsHubContainer = Loadable(() => import("./UserListsHubContainer"));

function UserListsHubContainerAsyncLoading(props) {
  return <LoadableLoading {...props} transparent page />;
}

const UserListsHubContainerContainer = (props) => {
  return (
    <UserListsHubContainer
      {...props}
      fallback={<UserListsHubContainerAsyncLoading />}
    />
  );
};

export default UserListsHubContainerContainer;
