import { css } from "aphrodite";

import TimelineSkeletonItem from "./TimelineSkeletonItem";

import { useStyles } from "hooks/useStyles";

const baseStyles = {
  loadingContainer: {
    width: "100%",
  },
};

const TimelineSkeleton = (props) => {
  const { styles } = useStyles(baseStyles, props);

  return (
    <div className={css(styles.loadingContainer)}>
      <TimelineSkeletonItem key="1" />
      <TimelineSkeletonItem key="2" />
      <TimelineSkeletonItem key="3" />
      <TimelineSkeletonItem key="4" />
      <TimelineSkeletonItem key="5" />
      <TimelineSkeletonItem key="6" />
      <TimelineSkeletonItem key="7" />
      <TimelineSkeletonItem key="8" />
      <TimelineSkeletonItem key="9" />
      <TimelineSkeletonItem key="10" />
    </div>
  );
};

export default TimelineSkeleton;
