import { useContext } from "react";

import { UseIsModeratorContext } from "components/UseIsModerator/UseIsModeratorProvider";

function useIsModerator() {
  const context = useContext(UseIsModeratorContext);

  return context;
}

export default useIsModerator;
