import { css } from "aphrodite";
import PropTypes from "prop-types";
import { useCallback, useMemo } from "react";

import BasicTooltip from "components/Common/Tooltip/BasicTooltip";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";

const baseStyles = {
  proCircleIcon: {
    position: "relative",
  },
  proCircleIconAbberation: {
    ...gStyles.chromaticAbberationBlock,
    width: ".6rem",
    height: ".6rem",
    position: "absolute",
    top: ".8rem",
    right: "-.2rem",
    borderRadius: 5,
    zIndex: 1,
  },
  proCircleIconNotClickable: {
    pointerEvents: "none",
  },
  proCircleIconAbberationThin: {
    ...gStyles.chromaticAbberationBlockThin,
    width: "0.3rem",
    height: ".3rem",
    top: "-.6rem",
    right: "-.1rem",
  },
  proCircleIconAbberationThinnest: {
    ...gStyles.chromaticAbberationBlockThinnest,
    width: ".25rem",
    height: ".25rem",
    top: "-.5rem",
    right: "-.1rem",
  },
};

const ProCircleIcon = (props) => {
  const {
    children,
    thin,
    thinnest,
    top,
    right,
    width,
    height,
    tooltip,
    withTooltip,
    tooltipPlacement,
    zIndex,
  } = props;

  const positionStyles = useMemo(() => {
    const proCircleIconAbberationPosition = {};

    if (top) {
      proCircleIconAbberationPosition.top = top;
    }
    if (right) {
      proCircleIconAbberationPosition.right = right;
    }
    if (width) {
      proCircleIconAbberationPosition.width = width;
    }
    if (height) {
      proCircleIconAbberationPosition.height = height;
    }

    return { proCircleIconAbberationPosition };
  }, [top, right, width, height]);

  const { styles } = useStyles([baseStyles, positionStyles], props);

  const renderTooltip = useCallback(
    () => <span>{tooltip || "Exclusive to Pro"}</span>,
    [tooltip]
  );

  const renderAbberationBlock = (tooltipProps = {}) => (
    <div
      {...tooltipProps}
      className={css(
        styles.proCircleIconAbberation,
        thin && styles.proCircleIconAbberationThin,
        thinnest && styles.proCircleIconAbberationThinnest,
        styles.proCircleIconAbberationPosition,
        !withTooltip && styles.proCircleIconNotClickable
      )}
    />
  );

  const abberationBlock = withTooltip ? (
    <BasicTooltip
      renderTooltip={renderTooltip}
      placement={tooltipPlacement}
      zIndex={zIndex}
    >
      {(tooltipProps) => renderAbberationBlock(tooltipProps)}
    </BasicTooltip>
  ) : (
    renderAbberationBlock()
  );

  return (
    <div className={css(styles.proCircleIcon)}>
      {children}
      {abberationBlock}
    </div>
  );
};

ProCircleIcon.propTypes = {
  children: PropTypes.node,
  thin: PropTypes.bool,
  thinnest: PropTypes.bool,
  top: PropTypes.string,
  right: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  withTooltip: PropTypes.bool,
  tooltipPlacement: PropTypes.string,
  tooltip: PropTypes.node,
  zIndex: PropTypes.number,
};
ProCircleIcon.defaultProps = {
  children: null,
  top: null,
  right: null,
  width: null,
  height: null,
  withTooltip: false,
  tooltipPlacement: undefined,
  tooltip: null,
  zIndex: 1000,
};

export default ProCircleIcon;
