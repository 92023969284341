/* eslint-disable react/no-did-mount-set-state */
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import PropTypes from "prop-types";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import AriaModal from "react-aria-modal";

import Overlay from "./OverlayAsync";

import generateTransition from "utils/generateTransition";

import { useStyles } from "hooks/useStyles";

import ScreenSizes from "styles/ScreenSizes";

export const SLIDING_TIMING = 300;

const baseStyles = {
  ariaContainer: {
    overflow: "hidden",
  },
  outer: {
    position: "fixed",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    pointerEvents: "none",
    zIndex: 1000,
  },
  fixer: {
    position: "relative",
    height: "100%",
    width: "100%",
  },
  container: {
    overflow: "hidden",
    transition: generateTransition({
      targets: ["border", "top", "margin-top", "transform"],
      speed: `${SLIDING_TIMING}ms`,
    }),
    position: "absolute",
  },
  inner: {
    position: "relative",
    zIndex: 100,
    width: "auto",
    height: "auto",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    whiteSpace: "nowrap",
    minWidth: "100vw",
    minHeight: "100%",
    maxWidth: "100%",
    maxHeight: "100%",
    overflowX: "hidden",
    overflowY: "auto",
  },
  noOverflowContent: {
    overflowY: "hidden",
  },
  children: {
    display: "flex",
    flex: 1,
    pointerEvents: "auto",
    opacity: 0.5,
    cursor: "pointer",
    transition: "opacity 200ms cubic-bezier(0.465, 0.183, 0.153, 0.946) 200ms",
    maxWidth: "100%",
    width: "100vw",
    height: "100vh",
    maxHeight: "100%",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    "-webkit-tap-highlight-color": "rgb(0,0,0,0)",
  },
  noSlideFromChildren: {
    opacity: 0,
    transform: "scale(0.025,0.05)",
    transitionDelay: "0ms",
  },
  noSlideFromChildrenOpen: {
    transform: "scale(1,1)",
    transitionDelay: "400ms",
  },
  childrenOpen: {
    opacity: 1,
  },
  closeIcon: {
    transition: generateTransition({ target: "opacity", speed: "200ms" }),
    position: "fixed",
    top: "1rem",
    right: "1rem",
    color: "#fff",
    opacity: 0,
    zIndex: 10,
  },
  closeIconOpen: {
    opacity: 1,
  },
  innerFullWidth: {
    width: "100vw",
  },
  innerFullHeight: {
    height: "100%",
  },
  childrenInner: {
    overflowX: "hidden",
    overflowY: "auto",
    maxHeight: "100%",
    height: "100%",
    whiteSpace: "normal",
    borderRadius: 6,
    position: "absolute",
    maxWidth: "100%",
    left: 0,
    right: 0,
    cursor: "auto",

    [ScreenSizes.mdAndAbove]: {
      maxWidth: "90%",
      width: "100%",
    },
    [ScreenSizes.lgAndAbove]: {
      maxWidth: "100%",
      width: "33.125rem",
    },
  },
  childrenInnerAutoWidth: {
    top: "1rem",
    bottom: "1rem",
    left: "1rem",
    right: "1rem",
    margin: "auto",
  },
  childrenInnerAutoHeight: {
    top: "none",
    bottom: "none",
    height: "auto",
  },
  arrowContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    position: "absolute",
    pointerEvents: "none",
  },
  arrowContainerTop: {
    borderTop: "10px solid white",
    bottom: "-10px",
  },
  arrowContainerBottom: {
    borderBottom: "10px solid white",
    top: "-10px",
  },
  arrow: {
    width: 0,
    height: 0,
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",
  },
  arrowTop: {
    borderTop: "10px solid white",
    bottom: "-10px",
  },
  arrowBottom: {
    borderBottom: "10px solid white",
    top: "-10px",
  },
};

const multiHandler = (handlers) => {
  if (Array.isArray(handlers)) {
    return (e) => handlers.forEach((handler) => handler(e));
  }

  return handlers;
};

const applyHandlerToEveryActionType = (handler) => ({
  onClick: multiHandler(handler),
  onKeyDown: multiHandler(handler), // TODO: Should this (and below key events) only target Enter and Space?
  onKeyUp: multiHandler(handler),
  onMouseDown: multiHandler(handler),
  onMouseUp: multiHandler(handler),
  onTouchStart: multiHandler(handler),
  onTouchEnd: multiHandler(handler),
});

const applyHandlerToEveryActionTypeExceptClick = (handler) => ({
  // onClick: handler,
  onKeyDown: handler,
  onKeyUp: handler,
  onMouseDown: handler,
  // onMouseUp: handler,
  onTouchStart: handler,
  onTouchEnd: handler,
});

const SlidingContainer = forwardRef((props, ref) => {
  const {
    onClose,
    title,
    zIndex,
    alwaysCloseOnClick,
    onUnmount,
    open,
    slideInFrom,
    fullWidth,
    fullHeight,
    growTransition,
    children,
    renderContent: passedRenderContent,
    contentClassName,
    noOverflow,
    childrenInnerAutoHeight,
    showOverlay,
    hideOverlayClose,
    showClose,
    disableClose,
    disableCloseOnOverlayClick,
    showArrow,
    arrowAnchorRef,
    extraOnClose,
    dataId,
    underlayStyle: passedUnderlayStyle,
    overlayStyles,
    onCloseTriggered,
  } = props;
  const { styles } = useStyles(baseStyles, props);
  const [mounted, setMounted] = useState(false);
  const [modalMounted, setModalMounted] = useState(false);
  const [showModalContent, setShowModalContent] = useState(true);
  const [stage, setStage] = useState(props.stage);
  const [changeStageTimeout, setChangeStageTimeout] = useState(null);
  const [changeStage, setChangeStage] = useState(null);
  const [closeTimeout, setCloseTimeout] = useState(null);
  const innerRef = useRef(null);
  const containerRef = useRef(null);
  const changeStageTimeoutCallback = useCallback(() => {
    setShowModalContent(true);
    setStage(changeStage);
    setChangeStageTimeout(null);
  }, [changeStage]);

  useEffect(() => {
    if (!closeTimeout) {
      setMounted(true);
    }

    if (
      !changeStageTimeout &&
      changeStage === props.stage &&
      changeStage !== stage
    ) {
      const csTimeout = setTimeout(changeStageTimeoutCallback, 200);
      setChangeStageTimeout(csTimeout);
    } else if (
      (!changeStageTimeout || changeStage !== props.stage) &&
      props.stage &&
      stage !== props.stage
    ) {
      setShowModalContent(true);
      setChangeStage(props.stage);
    }

    return () => {
      if (closeTimeout) {
        clearTimeout(closeTimeout);
      }
      if (changeStageTimeout) {
        clearTimeout(changeStageTimeout);
      }
    };
  }, [
    props.stage,
    stage,
    changeStage,
    closeTimeout,
    changeStageTimeout,
    changeStageTimeoutCallback,
  ]);

  const underlayStyle = useMemo(
    () => ({
      ...(passedUnderlayStyle || {}),
      background: "transparent",
      zIndex,
    }),
    [passedUnderlayStyle, zIndex]
  );

  const preventBubble = useCallback(
    (e) => {
      if (!alwaysCloseOnClick) {
        e.stopPropagation();
      }
    },
    [alwaysCloseOnClick]
  );

  const handleClose = useCallback(() => {
    if (!disableClose) {
      setMounted(false);
      const cTimeout = setTimeout(() => {
        if (onClose) {
          onClose();
        }
        if (onUnmount) {
          onUnmount();
        }
        if (extraOnClose) {
          extraOnClose();
        }

        if (closeTimeout) {
          setMounted(true);
        }
      }, 500);

      setCloseTimeout(cTimeout);
    } else if (onCloseTriggered) {
      onCloseTriggered();
    }
  }, [
    closeTimeout,
    disableClose,
    extraOnClose,
    onClose,
    onUnmount,
    onCloseTriggered,
  ]);

  useImperativeHandle(ref, () => ({
    close: handleClose,
  }));

  const handleModalMounted = useCallback(() => setModalMounted(true), []);
  const isOpen = useMemo(
    () => modalMounted && mounted && open,
    [modalMounted, mounted, open]
  );
  const isHorizontalSlide = useMemo(
    () => slideInFrom === "right" || slideInFrom === "left",
    [slideInFrom]
  );
  const style = useMemo(() => {
    let openStyle;

    if (slideInFrom) {
      if (isHorizontalSlide) {
        const width =
          innerRef.current && !fullWidth
            ? innerRef.current.clientWidth
            : "100%";

        openStyle = growTransition
          ? { width: isOpen ? width : 0, height: "100%" }
          : {
              transform: `translateX(${isOpen ? 0 : width})`,
              height: "100%",
              width: "100%",
            };
      } else {
        const height =
          innerRef.current && !fullHeight
            ? innerRef.current.clientHeight
            : "100%";

        openStyle = growTransition
          ? { height: isOpen ? height : 0, width: "100%" }
          : {
              transform: `translateY(${isOpen ? 0 : height})`,
              width: "100%",
              height: "100%",
            };
      }
    } else {
      openStyle = { width: "100vw", height: "100%", top: 0, left: 0 };
    }

    return { ...openStyle, [slideInFrom]: 0 };
  }, [
    slideInFrom,
    isHorizontalSlide,
    fullWidth,
    growTransition,
    isOpen,
    fullHeight,
  ]);

  const handleEscapeClose = useCallback(
    (e) => {
      if (!disableClose && !disableCloseOnOverlayClick && e.keyCode === 27) {
        onClose(e);
      }
    },
    [disableClose, disableCloseOnOverlayClick, onClose]
  );

  const renderCloseIcon = () => (
    <span className={css(styles.closeIcon, mounted && styles.closeIconOpen)}>
      <FontAwesomeIcon icon={faTimes} />
    </span>
  );

  const renderArrow = () => {
    const boundingRect = arrowAnchorRef.current.getBoundingClientRect();
    const isBottom = slideInFrom && slideInFrom.substr(0, 6) === "bottom";

    return (
      <div
        className={css(
          styles.arrowContainer,
          isBottom ? styles.arrowContainerBottom : styles.arrowContainerTop
        )}
        style={{ left: boundingRect.x, width: boundingRect.width }}
      >
        <div
          className={css(
            styles.arrow,
            isBottom ? styles.arrowBottom : styles.arrowTop
          )}
        />
      </div>
    );
  };

  const renderContent = () => {
    const contentOpen = isOpen && showModalContent;
    const noSlide = !slideInFrom || slideInFrom === "none";
    let content = children;

    if (stage) {
      content = passedRenderContent(stage)({ onClose: handleClose });
    } else if (passedRenderContent) {
      content = passedRenderContent({ onClose: handleClose });
    }

    return (
      <div
        data-id={dataId}
        ref={containerRef}
        className={css(styles.container)}
        style={style}
      >
        <div
          className={css(
            styles.inner,
            (noSlide || fullWidth) && styles.innerFullWidth,
            (noSlide || fullHeight) && styles.innerFullHeight
          )}
        >
          <div
            ref={innerRef}
            className={css(
              styles.content,
              noOverflow && styles.noOverflowContent
            )}
          >
            <div
              className={css(
                styles.children,
                noSlide && styles.noSlideFromChildren,
                contentOpen ? styles.childrenOpen : null,
                noSlide && contentOpen ? styles.noSlideFromChildrenOpen : null
              )}
              {...applyHandlerToEveryActionTypeExceptClick(
                disableCloseOnOverlayClick ? null : handleClose
              )}
            >
              {showArrow &&
                arrowAnchorRef &&
                arrowAnchorRef.current &&
                renderArrow()}
              <div
                className={css(
                  styles.childrenInner,
                  !fullWidth && styles.childrenInnerAutoWidth,
                  (!fullHeight || childrenInnerAutoHeight) &&
                    styles.childrenInnerAutoHeight,
                  contentClassName
                )}
                {...applyHandlerToEveryActionType([
                  preventBubble,
                  handleEscapeClose,
                ])}
              >
                {showOverlay && showClose && renderCloseIcon()}
                {content}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderFloatingMenu = () => (
    <div className={css(styles.outer)}>
      {showOverlay && !hideOverlayClose && renderCloseIcon()}
      <div className={css(styles.fixer)}>
        {showOverlay && (
          <Overlay
            open={isOpen}
            onClick={disableCloseOnOverlayClick ? null : handleClose}
            styles={overlayStyles}
          />
        )}
        {renderContent()}
      </div>
    </div>
  );

  const getApplicationNode = useCallback(
    () => document.getElementById("app"),
    []
  );

  return (
    <div
      className={css(styles.ariaContainer)}
      {...applyHandlerToEveryActionType([preventBubble, handleEscapeClose])}
    >
      <AriaModal
        titleText={title || "Dialog"}
        onEnter={handleModalMounted}
        underlayStyle={underlayStyle}
        getApplicationNode={getApplicationNode}
      >
        {renderFloatingMenu()}
      </AriaModal>
    </div>
  );
});

SlidingContainer.propTypes = {
  children: PropTypes.node,
  renderContent: PropTypes.func,
  fullHeight: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  slideInFrom: PropTypes.string,
  showOverlay: PropTypes.bool,
  showClose: PropTypes.bool,
  hideOverlayClose: PropTypes.bool,
  growTransition: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  contentClassName: PropTypes.object,
  noOverflow: PropTypes.bool,
  childrenInnerAutoHeight: PropTypes.bool,
  alwaysCloseOnClick: PropTypes.bool,
  stage: PropTypes.string,
  zIndex: PropTypes.number,
  onUnmount: PropTypes.func,
  disableClose: PropTypes.bool,
  disableCloseOnOverlayClick: PropTypes.bool,
  arrowAnchorRef: PropTypes.object,
  showArrow: PropTypes.bool,
  dataId: PropTypes.string,
  underlayStyle: PropTypes.object,
  overlayStyles: PropTypes.object,
  extraOnClose: PropTypes.func,
  onCloseTriggered: PropTypes.func,
};

SlidingContainer.defaultProps = {
  children: null,
  renderContent: null,
  fullHeight: false,
  fullWidth: false,
  onClose: null,
  open: true,
  slideInFrom: null,
  showOverlay: false,
  showClose: false,
  hideOverlayClose: false,
  growTransition: false,
  title: "Podchaser Dialog",
  contentClassName: null,
  noOverflow: false,
  childrenInnerAutoHeight: false,
  alwaysCloseOnClick: false,
  stage: null,
  zIndex: 9500,
  onUnmount: null,
  disableClose: false,
  disableCloseOnOverlayClick: false,
  arrowAnchorRef: null,
  showArrow: false,
  dataId: "sliding-container",
  underlayStyle: null,
  overlayStyles: null,
  extraOnClose: null,
  onCloseTriggered: null,
};

SlidingContainer.displayName = "SlidingContainerWithRef";

export default SlidingContainer;
