import { List, Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useCallback, useMemo } from "react";

import Activity from "./ActivityAsync";
import ActivityCard from "./ActivityCardAsync";
import {
  allActivitiesEpisodeOfTheSamePodcast,
  hideActivity,
} from "./AggregateUtils";

import { selectSpecificPodcast } from "selectors/podcast";

import useReduxState from "hooks/useReduxState";

const AggregateActivity = (props) => {
  const {
    aggregateProps,
    renderItem,
    renderIntroText,
    getSections: passedGetSections,
    activitiesByType,
    loaded,
    activities,
    shownByField,
    mobile,
    entity_type,
    entity,
    card_entity,
    card_entity_type,
    noPanel,
    insidePanel,
    highlightType,
    filterEntities,
    collapsable,
    getActivtyListDefaultCount,
  } = props;
  const {
    activitiesState: {
      firstActor,
      firstActorType,
      firstObject,
      firstObjectType,
      hasOneActor,
      hasOneObject,
    },
  } = aggregateProps;

  const activity = activities && activities.first();
  const podcastID = allActivitiesEpisodeOfTheSamePodcast(
    activities || activitiesByType,
    activitiesByType
  );
  const podcast = useReduxState(
    (state) => selectSpecificPodcast(state, podcastID),
    [podcastID]
  );

  const renderDefaultIntroImage = useCallback(
    ({ renderEntityImage }) => {
      if (entity_type && entity) {
        return renderEntityImage(entity_type, entity);
      }
      if (hasOneActor) {
        return renderEntityImage(firstActorType, firstActor);
      }
      if (hasOneObject) {
        return renderEntityImage(firstObjectType, firstObject);
      }

      return null;
    },
    [
      hasOneActor,
      hasOneObject,
      firstActor,
      firstActorType,
      firstObject,
      firstObjectType,
      entity_type,
      entity,
    ]
  );

  const entitySection = useMemo(() => {
    if (podcast || hasOneObject || card_entity) {
      return {
        type: "entity",
        entityCardProps: {
          entity_type:
            card_entity_type || (hasOneObject ? firstObjectType : "podcast"),
          entity: card_entity || (hasOneObject ? firstObject : podcast),
          mobile,
          forceBorder: true,
        },
      };
    }

    return null;
  }, [
    podcast,
    hasOneObject,
    firstObjectType,
    firstObject,
    mobile,
    card_entity,
    card_entity_type,
  ]);

  const getSections = useCallback(
    (type = null, byTypeActivities = null, index = 0) =>
      [
        index === 0
          ? {
              type: "intro",
              renderImage: renderDefaultIntroImage,
              renderText: renderIntroText,
              highlightType,
              entity: hasOneActor ? firstActor : firstObject,
              entityType: hasOneActor ? firstActorType : firstObjectType,
              isAggregate: true,
              hasOneActor,
              hasOneObject,
              podcast,
            }
          : null,
        index === 0 && entitySection,
        {
          type: "list",
          renderItem: renderItem ? renderItem(type) : null,
          items: byTypeActivities || activities,
          getActivtyListDefaultCount,
          shownByField,
          aggregateProps,
          collapsable,
        },
      ].filter((sec) => !!sec),
    [
      renderDefaultIntroImage,
      renderIntroText,
      highlightType,
      entitySection,
      renderItem,
      activities,
      hasOneObject,
      shownByField,
      aggregateProps,
      podcast,
      hasOneActor,
      firstActor,
      firstActorType,
      firstObject,
      firstObjectType,
      collapsable,
      getActivtyListDefaultCount,
    ]
  );

  const items = useMemo(
    () =>
      activitiesByType &&
      activitiesByType.map(([type, byTypeActivities], index) => ({
        key: type,
        sections: (passedGetSections || getSections)(
          type,
          byTypeActivities,
          index
        ),
      })),
    [getSections, passedGetSections, activitiesByType]
  );

  const renderActivity = (activityProps) => (
    <Activity
      activity={activity}
      aggregateProps={aggregateProps}
      mobile={mobile}
      allEpisodesOfSamePodcast={!!podcast}
      noPanel={noPanel}
      insidePanel={insidePanel}
      loaded
      noCard
      {...activityProps}
    />
  );

  if (hideActivity(filterEntities)) {
    return null;
  }

  return (
    <ActivityCard
      activity={activity}
      loaded={loaded}
      noPanel={noPanel}
      highlightType={highlightType}
      insidePanel={insidePanel}
    >
      {activitiesByType
        ? items.map(renderActivity)
        : renderActivity({ sections: getSections() })}
    </ActivityCard>
  );
};

AggregateActivity.propTypes = {
  activities: PropTypes.instanceOf(List),
  activitiesByType: PropTypes.instanceOf(Map),
  aggregateProps: PropTypes.object,
  renderItem: PropTypes.func,
  renderIntroText: PropTypes.func,
  getSections: PropTypes.func,
  loaded: PropTypes.bool,
  shownByField: PropTypes.string,
  mobile: PropTypes.bool,
  entity_type: PropTypes.string,
  entity: PropTypes.instanceOf(Map),
  card_entity: PropTypes.instanceOf(Map),
  card_entity_type: PropTypes.string,
  noPanel: PropTypes.bool,
  highlightType: PropTypes.string,
  filterEntities: PropTypes.object,
  insidePanel: PropTypes.bool,
  collapsable: PropTypes.bool,
  getActivtyListDefaultCount: PropTypes.func,
};

AggregateActivity.defaultProps = {
  activities: null,
  activitiesByType: null,
  aggregateProps: { activitiesState: {} },
  renderItem: null,
  renderIntroText: null,
  getSections: null,
  loaded: false,
  shownByField: null,
  mobile: false,
  entity_type: null,
  entity: null,
  card_entity: null,
  card_entity_type: null,
  noPanel: false,
  insidePanel: false,
  highlightType: null,
  filterEntities: null,
  collapsable: false,
  getActivtyListDefaultCount: null,
};

export default memo(AggregateActivity);
