export const modalButtonStyles = {
  display: "block",
  padding: "0.25rem 0.5rem",
  cursor: "pointer",
  color: "#555",
  lineHeight: 1,
  borderRadius: 4,
  border: "none",
  outline: "none",
  appearance: "none",
  ":hover": {
    backgroundColor: "rgba(0,0,0,0.05)",
  },
  ":focus": {
    backgroundColor: "rgba(0,0,0,0.03)",
    color: "#333",
  },
};

export const buttonAccessiblityStyles = {
  ":focus": {
    opacity: "0.8",
  },
};
