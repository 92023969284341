import Loadable from "@loadable/component";

import UserListViewSkeleton from "./UserListViewSkeleton";

const UserListViewContainer = Loadable(() => import("./UserListViewContainer"));

function UserListViewContainerAsyncLoading(props) {
  return <UserListViewSkeleton />;
}

const UserListViewContainerContainer = (props) => {
  return (
    <UserListViewContainer
      {...props}
      fallback={<UserListViewContainerAsyncLoading />}
    />
  );
};

export default UserListViewContainerContainer;
