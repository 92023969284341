import Loadable from "@loadable/component";

import TimelineSkeletonItem from "components/Timeline/TimelineSkeletonItem";

const Activity = Loadable(() =>
  import(/* webpackChunkName: "Activity" */ "./Activity")
);

function ActivityLoading(props) {
  return <TimelineSkeletonItem />;
}

const ActivityContainer = (props) => {
  return <Activity {...props} fallback={<ActivityLoading />} />;
};

export default ActivityContainer;
