import Loadable from "@loadable/component";
const CreatorHeaderMobileEllipsisModal = Loadable(() =>
  import("./CreatorHeaderMobileEllipsisModal")
);

function CreatorHeaderMobileEllipsisModalAsyncLoading() {
  return null;
}

const CreatorHeaderMobileEllipsisModalContainer = (props) => {
  return (
    <CreatorHeaderMobileEllipsisModal
      {...props}
      fallback={<CreatorHeaderMobileEllipsisModalAsyncLoading />}
    />
  );
};

export default CreatorHeaderMobileEllipsisModalContainer;
