import { fromJS } from "immutable";
import PropTypes from "prop-types";
import { useCallback, useMemo, useState } from "react";

import GenericModal from "components/Common/GenericModal";
import Filters from "pages/Discover/Charts/Sidebar/Filters";

import useChartFilters from "hooks/useChartFilters";

const ChartsFilterModal = (props) => {
  const {
    title,
    closeModal,
    platform: passedPlatform,
    country: passedCountry,
    category: passedCategory,
    date: passedDate,
    handleCTA,
    handleMobileFilterUpdate,
  } = props;

  const [filters, setFilters] = useState({
    platform: passedPlatform,
    country: passedCountry,
    category: passedCategory,
    date: passedDate,
  });

  const {
    platformOptions,
    appleCategoryOptions,
    appleCountryOptions,
    spotifyCategoryOptions,
    spotifyCountryOptions,
  } = useChartFilters();

  const { activeCategories, activeCountries } = useMemo(() => {
    let activeCategories = [];
    let activeCountries = [];

    switch (filters.platform) {
      case "apple":
        activeCategories = appleCategoryOptions;
        activeCountries = appleCountryOptions;
        break;
      case "spotify":
        activeCategories = spotifyCategoryOptions;
        activeCountries = spotifyCountryOptions;
        break;

      default:
        activeCategories = appleCategoryOptions;
        activeCountries = appleCountryOptions;
        break;
    }

    return {
      activeCategories,
      activeCountries,
    };
  }, [
    appleCategoryOptions,
    appleCountryOptions,
    filters.platform,
    spotifyCategoryOptions,
    spotifyCountryOptions,
  ]);

  const categoryFilter = fromJS({
    title: "categories",
    options: activeCategories,
  });
  const countryFilter = fromJS({
    title: "countries",
    options: activeCountries,
  });

  const handleViewCharClick = useCallback(
    ({ platform, country, category, date }) => {
      handleMobileFilterUpdate({
        platform,
        country,
        category,
        date,
      });
      closeModal();
    },
    [closeModal, handleMobileFilterUpdate]
  );

  const renderContent = () => (
    <Filters
      platform={filters.platform}
      country={filters.country}
      category={filters.category}
      date={filters.date}
      platformOptions={platformOptions}
      countryFilter={countryFilter}
      categoryFilter={categoryFilter}
      handleCTA={handleCTA}
      handleViewCharClick={handleViewCharClick}
      setFilters={setFilters}
    />
  );

  return (
    <GenericModal
      title={title}
      renderContent={renderContent}
      onRequestClose={closeModal}
    />
  );
};

ChartsFilterModal.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
};

export default ChartsFilterModal;
