import { createSelector } from "reselect";

export const selectBadgeById = createSelector(
  (badges) => badges,
  (_, badgeId) => badgeId,
  (badges, badgeId) => badges?.getIn(["entities", badgeId?.toString()])
);

export const selectListOfBadgeById = createSelector(
  (badges) => badges,
  (_, listOfBadgeId) => listOfBadgeId,
  (badges, listOfBadgeId) => {
    if (!listOfBadgeId) {
      return new Map();
    }

    return listOfBadgeId
      .map((badgeId) => badges?.getIn(["entities", badgeId?.toString()]))
      .filter((item) => item);
  }
);

export const selectBadgesLoading = (badges) => badges.getIn(["loading"]);

export const selectBadgesFailed = (badges) => badges.getIn(["failed"]);
