/* eslint-disable max-len */

export default {
  prefix: "fpc",
  iconName: "pc-podchaser25",
  icon: [
    570,
    512,
    [],
    null,
    "M279.272726,426.631697 C265.052339,416.225893 253.894184,405.215322 245.798271,393.599983 C237.702353,381.984646 231.568409,368.185967 227.396434,352.203948 L127.860328,352.203948 C158.303267,324.506153 172.002587,310.918555 188.238818,292.104959 C223.755577,251.864766 241.006577,212.147173 241.006577,172.429581 C241.006577,113.898392 191.790498,70 125.323418,70 C80.1664,70 41.0979616,90.3813962 19.7879102,124.35039 C6.0885847,146.822186 1.01476412,166.158382 2.84217094e-14,204.830775 L82.7033103,204.830775 C84.7328385,166.158382 96.9100129,148.912585 121.771744,148.912585 C141.052267,148.912585 154.24421,163.022783 154.24421,182.881579 C154.24421,210.579374 130.397244,250.296966 90.8214232,290.537159 C66.9744565,314.054155 56.8268154,321.893153 3.04429235,357.952547 L3.04429235,427.458333 L279.272726,426.631697 Z M379.864139,443 C417.911042,443 449.788172,430.457602 473.953096,405.895407 C497.603877,381.855811 512,347.886818 512,315.485624 C512,287.787829 501.202904,258.522234 483.20775,236.573038 C462.12771,210.965643 432.821313,197.900646 397.345146,197.900646 C377.807549,197.900646 364.439721,201.036245 350.557741,208.352644 L358.269952,160.796052 L481.15116,160.796052 L481.15116,85.5416667 L290.402503,85.5416667 L261.096105,283.607029 L332.048439,301.375427 C340.274792,282.03923 356.213362,271.587232 376.779254,271.587232 C404.029062,271.587232 424.080807,291.446028 424.080807,317.576024 C424.080807,342.660819 402.48662,364.087415 377.293401,364.087415 C361.354837,364.087415 347.472856,356.248416 337.189913,341.615619 L248.242425,341.615619 C260.06781,403.282407 311.482547,443 379.864139,443 Z",
  ],
};
