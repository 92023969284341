import { createRoutine, promisifyRoutine } from "redux-saga-routines";

export const setListened = createRoutine("SET_LISTENED");
export const setListenedPromise = promisifyRoutine(setListened);

export const setAllListenedForPodcast = createRoutine(
  "SET_ALL_LISTENED_FOR_PODCAST"
);
export const setAllListenedForPodcastPromise = promisifyRoutine(
  setAllListenedForPodcast
);
