import { useMemo } from "react";
import { useLocation } from "react-router-dom";

import { getQueries } from "utils/url";

function useTesting(feature) {
  const location = useLocation();

  const { testrun } = useMemo(() => getQueries(location.search), [location]);

  return feature ? testrun === feature : !!testrun;
}

export default useTesting;
