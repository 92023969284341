import { List } from "immutable";
import PropTypes from "prop-types";
import { Fragment, useCallback, useMemo } from "react";

import AggregateActivity from "./Activity/AggregateActivity";
import {
  allActivitiesEpisodeOfTheSamePodcast,
  getActorLinks,
  getUniqueEntityCountString,
} from "./Activity/AggregateUtils";
import ReleaseActivity from "./ReleaseActivity";

import { selectSpecificPodcast } from "selectors/podcast";

import useReduxState from "hooks/useReduxState";

const ReleaseAggregate = (props) => {
  const { aggregateProps } = props;

  const { activities, activitiesByObjectType, activitiesByVerb } =
    aggregateProps;

  const episodeCountString = useMemo(
    () => getUniqueEntityCountString(activities),
    [activities]
  );

  const allEpisodesOfSamePodcast = useMemo(
    () => allActivitiesEpisodeOfTheSamePodcast(activitiesByObjectType, true),
    [activitiesByObjectType]
  );

  const podcastEntity = useReduxState(
    (state) => selectSpecificPodcast(state, allEpisodesOfSamePodcast),
    [allEpisodesOfSamePodcast]
  );

  const guestAppearanceCreators = useMemo(() => {
    if (activitiesByVerb.has("appear")) {
      return activitiesByVerb.get("appear").reduce((appearances, activity) => {
        if (activity.get("role") === "guest") {
          return appearances.push(activity.get("actor"));
        }

        return appearances;
      }, List());
    }

    return List();
  }, [activitiesByVerb]);

  const guestAppearanceActivities = useMemo(() => {
    if (guestAppearanceCreators.size > 0) {
      return activitiesByVerb
        .get("appear")
        .filter((activity) => activity.get("role") === "guest");
    }

    return List();
  }, [activitiesByVerb, guestAppearanceCreators]);

  const renderGuestAppearanceCount = useCallback(() => {
    if (guestAppearanceCreators.size === 1) {
      return (
        <Fragment>
          with a guest credit by {getActorLinks(guestAppearanceCreators)}
        </Fragment>
      );
    }

    return (
      <Fragment>
        with guest credits by {getActorLinks(guestAppearanceCreators)}
      </Fragment>
    );
  }, [guestAppearanceCreators]);

  const renderIntroText = useCallback(
    ({ renderEntityLink }) => (
      <Fragment>
        {allEpisodesOfSamePodcast && renderEntityLink("podcast", podcastEntity)}{" "}
        {`released ${episodeCountString}`}{" "}
        {guestAppearanceCreators.size > 0 && renderGuestAppearanceCount()}
      </Fragment>
    ),
    [
      allEpisodesOfSamePodcast,
      podcastEntity,
      episodeCountString,
      guestAppearanceCreators,
      renderGuestAppearanceCount,
    ]
  );

  if (
    activitiesByVerb.get("release").size === 1 &&
    guestAppearanceCreators.size === 0
  ) {
    return (
      <ReleaseActivity
        {...props}
        activity={activitiesByVerb.get("release").first()}
      />
    );
  }

  return (
    <AggregateActivity
      {...props}
      activities={
        activitiesByVerb.get("release").size > 1
          ? activitiesByVerb.get("release")
          : guestAppearanceActivities
      }
      renderIntroText={renderIntroText}
      highlightType="Release"
      loaded
    />
  );
};

ReleaseAggregate.propTypes = {
  aggregateProps: PropTypes.object.isRequired,
};

export default ReleaseAggregate;
