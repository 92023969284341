import pluralize from "pluralize";

import { capitalizeWords } from "utils/case";

export default function getCategoryText(category, options = {}) {
  let cleanCategory = null;
  if (!category) {
    return null;
  }
  if (category.get && category.get("text")) {
    cleanCategory = category.get("text");
  } else if (category.get && category.get("slug")) {
    cleanCategory = capitalizeWords(
      category
        .get("slug")
        .replace(/\-/g, " ")
        .replace(/ category$/gi, "")
    );
  } else if (typeof category === "string") {
    cleanCategory = capitalizeWords(
      category.replace(/\-/g, " ").replace(/ category$/gi, "")
    );
  } else {
    return null;
  }

  switch (cleanCategory.toLowerCase()) {
    // Words that the pluralize plugin doesn't fix
    case "christmas":
      return options.plural ? "Christmases" : "Christmas";

    // Words that remain as plural
    case "kids":
      return "Kids";

    // Words that remain as capitals
    case ".net":
      return ".NET";

    // Abbreviations
    case "adhd":
      return "ADHD";
    case "aew":
      return "AEW";
    case "ahl":
      return "AHL";
    case "asmr":
      return "ASMR";
    case "aws":
      return "AWS";
    case "bbq":
      return "BBQ";
    case "bdsm":
      return "BDSM";
    case "cad":
      return "CAD";
    case "diy":
      return "DIY";
    case "edm":
      return "EDM";
    case "fsu":
      return "FSU";
    case "gaap":
      return "GAAP";
    case "gdpr":
      return "GDPR";
    case "hpc":
      return "HPC";
    case "hsie":
      return "HSIE";
    case "mcu":
      return "MCU";
    case "mlb":
      return "MLB";
    case "moba":
      return "MOBA";
    case "mma":
      return "MMA";
    case "nba":
      return "NBA";
    case "nba fantasy":
      return "NBA Fantasy";
    case "ncaa":
      return "NCAA";
    case "nfl":
      return "NFL";
    case "nhl":
      return "NHL";
    case "ocd":
      return "OCD";
    case "odu":
      return "ODU";
    case "ongd":
      return "ONGD";
    case "osr":
      return "OSR";
    case "potus":
      return "POTUS";
    case "ptsd":
      return "PTSD";
    case "scotus":
      return "SCOTUS";
    case "seo":
      return "SEO";
    case "sose":
      return "SOSE";
    case "stem":
      return "STEM";
    case "tv":
      return "TV";
    case "ufc":
      return "UFC";
    case "uae":
      return "UAE";
    case "wwe":
      return "WWE";
  }
  if (cleanCategory.substring(0, 3) === "LGB") {
    return cleanCategory;
  } else if (cleanCategory.substring(0, 3).toLowerCase() === "lgb") {
    return cleanCategory.toUpperCase();
  }

  if (cleanCategory.match(/ics$/i)) {
    return cleanCategory;
  }

  if (options.plural) {
    return pluralize(cleanCategory);
  }

  return options.singular ? pluralize.singular(cleanCategory) : cleanCategory;
}
