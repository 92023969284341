import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

import ScreenSizes from "styles/ScreenSizes";

const loadingStyles = {
  height: {
    height: "318rem",
    maxHeight: "318rem",
    minHeight: 0,

    [ScreenSizes.lgAndAbove]: {
      height: "200rem",
      maxHeight: "200rem",
    },
  },
};

const EpisodeViewContainer = Loadable(() => import("./EpisodeViewContainer"));

function EpisodeViewAsyncLoading(props) {
  return <LoadingOverlay height={"136rem"} styles={loadingStyles} topAlign />;
}

const EpisodeViewContainerContainer = (props) => {
  return (
    <EpisodeViewContainer {...props} fallback={<EpisodeViewAsyncLoading />} />
  );
};

export default EpisodeViewContainerContainer;
