import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const BadgesView = Loadable(() => import("./BadgesView"));

function BadgesViewAsyncLoading(props) {
  return <LoadableLoading {...props} />;
}

const BadgesViewContainer = (props) => {
  return <BadgesView {...props} fallback={<BadgesViewAsyncLoading />} />;
};

export default BadgesViewContainer;
