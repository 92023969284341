import getInsightPodcastUrl from "utils/entity/getInsightPodcastUrl";
import getPodcastUrl from "utils/entity/getPodcastUrl";

import useUserHasPro from "hooks/useUserHasPro";

function usePodcastUrl(podcast) {
  const hasPro = useUserHasPro();

  return hasPro ? getInsightPodcastUrl(podcast) : getPodcastUrl(podcast);
}

export function getPodcastUrlByUserPermission(hasPro, podcast) {
  return hasPro ? getInsightPodcastUrl(podcast) : getPodcastUrl(podcast);
}

export default usePodcastUrl;
