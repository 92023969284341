import { Map } from "immutable";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { loadSpecificBrand as loadSpecificBrandAction } from "routines/brand";

import BrandViewEpisode from "./BrandViewEpisode";

import useActionCreators from "hooks/useActionCreators";
import useBrandData, { useBrandDataIsLoading } from "hooks/useBrandData";
import useExecOnMount from "hooks/useExecOnMount";

const compareFunc = (compProps, oldCompProps) =>
  compProps.brandId !== oldCompProps.brandId;

const BrandViewEpisodeContainer = (props) => {
  const { brand: nativeBrand } = props;
  const brandId = nativeBrand?.get("id");
  const brand = useBrandData(brandId);

  const loading = useBrandDataIsLoading(brandId);

  const { loadSpecificBrand } = useActionCreators({
    loadSpecificBrand: loadSpecificBrandAction,
  });

  const loadBrand = useCallback(
    () => brandId && loadSpecificBrand({ brandId }),
    [brandId, loadSpecificBrand]
  );

  useExecOnMount(
    loadBrand,
    brandId && !brand && !loading,
    { brandId },
    compareFunc
  );

  const brandIdConverted =
    brand && brand?.get("id") && Number(brand?.get("id"));

  return <BrandViewEpisode brandId={brandIdConverted} />;
};

BrandViewEpisodeContainer.propTypes = {
  brand: PropTypes.instanceOf(Map),
};

export default React.memo(BrandViewEpisodeContainer);
