import { css } from "aphrodite";
import { Map, List } from "immutable";
import PropTypes from "prop-types";
import { memo, Fragment, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";

import StandardButton from "components/Buttons/StandardButton";
import ImageFallback from "components/Global/Images/ImageFallback";

import EntityImage from "../Entities/EntityImage";
import HoverCardFollowButton from "./HoverCardFollowButton";
import profileHoverCardStyles from "./ProfileHoverCardStyles";
import ProfileHoverPopoverContent from "./ProfileHoverPopoverContent";

import cachedImage from "utils/entity/cachedImage";
import getPodcastUrl from "utils/entity/getPodcastUrl";
import commaList from "utils/text/commaList";
import { getCreatorUrl } from "utils/url/creatorUrls";

import useLoggedInUser from "hooks/useLoggedInUser";
import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const mobileImageSize = "7rem";
const desktopImageSize = "5rem";

const baseStyles = {
  ...profileHoverCardStyles,
  content: {
    ...profileHoverCardStyles.content,
    textAlign: "center",
  },
  topInfoRow: {
    ...profileHoverCardStyles.topInfoRow,
    flexDirection: "column",
  },
  profileImage: {
    ...profileHoverCardStyles.profileImage,
    marginBottom: "1rem",
    width: mobileImageSize,
    height: mobileImageSize,

    [ScreenSizes.lgAndAbove]: {
      ...profileHoverCardStyles.profileImage[ScreenSizes.lgAndAbove],
      marginBottom: ".75rem",
      width: desktopImageSize,
      height: desktopImageSize,
    },
  },
  name: {
    ...profileHoverCardStyles.name,
    marginBottom: ".75rem",
    display: "block",

    [ScreenSizes.lgAndAbove]: {
      ...profileHoverCardStyles.name[ScreenSizes.lgAndAbove],
      marginBottom: ".5rem",
      fontSize: "1rem",
    },
  },
  roleSummary: {
    ...gStyles.textEllipsis,
    ...gStyles.fontSemiBold,
    fontSize: "1rem",
    color: "#a3a3a3",
    display: "block",
    width: "100%",

    [ScreenSizes.lgAndAbove]: {
      fontSize: ".6875rem",
    },
  },
  appearedOnTitle: {
    ...gStyles.fontSemiBold,
    display: "block",
    color: "#343434",
    fontSize: "1rem",
    marginBottom: "1rem",

    [ScreenSizes.lgAndAbove]: {
      fontSize: ".688rem",
      marginBottom: ".625rem",
    },
  },
  appearedOnTilesContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    margin: "-0.5rem 0",

    [ScreenSizes.lgAndAbove]: {
      margin: "0 auto",
      width: "100%",
    },
  },
  appearedOnTile: {
    position: "relative",
    width: "calc(33.33% - .8rem)",
    minWidth: "calc(33.33% - .8rem)",
    maxHeight: "calc(33.33% - .8rem)",
    minHeight: "calc(33.33% - .8rem)",
    height: "calc(33.33% - .8rem)",
    paddingBottom: "calc(33.33% - .8rem)",
    margin: ".4rem 0",
    marginLeft: "1.2rem",

    ":first-child": {
      marginLeft: 0,
    },

    [ScreenSizes.lgAndAbove]: {
      width: "calc(33.33% - .33rem)",
      minWidth: "calc(33.33% - .33rem)",
      maxHeight: "calc(33.33% - .33rem)",
      height: "calc(33.33% - .33rem)",
      paddingBottom: "calc(33.33% - .33rem)",
      margin: 0,
      marginLeft: ".49rem",

      ":first-child": {
        marginLeft: 0,
      },
    },
  },
  appearedOnMoreTile: {
    ...gStyles.fontSemiBold,
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1.8rem",
    color: "var(--color-neutral-d3)",
    backgroundColor: "#CCC",
    opacity: 0.6,

    [ScreenSizes.lgAndAbove]: {
      marginBottom: 0,
      fontSize: "1.2rem",
    },
  },
};

const imageFallbackStyles = {
  imageFallback: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
};

const APPEAR_ON_PODCASTS_SIZE = 3;

const CreatorProfileHoverCard = (props) => {
  const { creator, mobile, popoutProps } = props;
  const { styles } = useStyles(baseStyles, props);

  const displayName = creator && creator.get("name");
  const creatorUrl = getCreatorUrl(creator);
  const authUser = useLoggedInUser();
  const userIsCreator =
    authUser &&
    creator &&
    creator.get("creator_user") &&
    authUser.get("id") === creator.getIn(["creator_user", "id"]);

  const roleSummary = useMemo(() => {
    const roleTitles =
      creator && creator.get("role_summary")
        ? creator.get("role_summary").reduce((roles, podcast) => {
            podcast.get("roles").forEach((role) => {
              if (!roles.includes(role.get("title"))) {
                roles.push(role.get("title"));
              }
            });

            return roles;
          }, [])
        : [];

    return roleTitles.length > 0 ? (
      <span>Podcast {commaList(roleTitles, (role) => role)}</span>
    ) : (
      "Podcast Enthusiast!"
    );
  }, [creator]);

  const appearedOnPodcasts = useMemo(() => {
    if (creator && creator.get("role_summary")) {
      return creator
        .get("role_summary")
        .map((podcastRole) => podcastRole.get("entity"));
    }

    return List([]);
  }, [creator]);

  const hasMorePodcasts = appearedOnPodcasts.size > APPEAR_ON_PODCASTS_SIZE;

  const truncatedAppearedOnPodcasts = useMemo(
    () =>
      appearedOnPodcasts.slice(
        0,
        hasMorePodcasts ? APPEAR_ON_PODCASTS_SIZE - 1 : APPEAR_ON_PODCASTS_SIZE
      ),
    [appearedOnPodcasts, hasMorePodcasts]
  );

  const renderInfo = useCallback(
    () => (
      <div className={css(styles.topInfoRow)}>
        <div className={css(styles.profileImage)}>
          <EntityImage
            entity={creator}
            entity_type="creator"
            size={mobile ? mobileImageSize : desktopImageSize}
            disablePopup
            fullWidth
          />
        </div>
        <Link className={css(styles.namesColumn)} to={creatorUrl}>
          <span className={css(styles.name)}>{displayName}</span>
          <span className={css(styles.roleSummary)}>{roleSummary}</span>
        </Link>
      </div>
    ),
    [
      styles.topInfoRow,
      styles.profileImage,
      styles.namesColumn,
      styles.name,
      styles.roleSummary,
      creatorUrl,
      mobile,
      creator,
      displayName,
      roleSummary,
    ]
  );

  const renderPodcast = useCallback(
    (podcast) =>
      podcast && (
        <Link
          key={podcast.get("id")}
          className={css(styles.appearedOnTile)}
          to={getPodcastUrl(podcast)}
        >
          <ImageFallback
            styles={imageFallbackStyles}
            src={cachedImage(podcast.get("image_url"), 128)}
            title={podcast.get("title")}
            alt={podcast.get("title")}
            width={64}
            height={64}
          />
        </Link>
      ),
    [styles.appearedOnTile]
  );

  const renderCredits = useCallback(
    () => (
      <Fragment>
        <span className={css(styles.divider)} />
        <span className={css(styles.appearedOnTitle)}>Appeared On</span>
        <span className={css(styles.appearedOnTilesContainer)}>
          {truncatedAppearedOnPodcasts &&
            truncatedAppearedOnPodcasts.map(renderPodcast)}
          {hasMorePodcasts && (
            <Link
              className={css(styles.appearedOnTile)}
              to={getCreatorUrl(creator, "appearances")}
            >
              <span className={css(styles.appearedOnMoreTile)}>
                {`+${
                  creator.get("podcast_credit_count") -
                  (APPEAR_ON_PODCASTS_SIZE - 1)
                }`}
              </span>
            </Link>
          )}
        </span>
      </Fragment>
    ),
    [
      styles,
      renderPodcast,
      truncatedAppearedOnPodcasts,
      hasMorePodcasts,
      creator,
    ]
  );

  if (!creator) {
    return null;
  }

  return (
    <ProfileHoverPopoverContent popoutProps={popoutProps}>
      <span className={css(styles.content, mobile && styles.mobileContent)}>
        {renderInfo()}
        {creator.get("role_summary") &&
          creator.get("role_summary").size > 0 &&
          renderCredits()}
        {(!userIsCreator || mobile) && <span className={css(styles.divider)} />}
        {!userIsCreator && (
          <span className={css(styles.buttonContainer)}>
            <HoverCardFollowButton
              name={displayName}
              entity_type="creator"
              entity={creator}
            />
          </span>
        )}
        {mobile && (
          <StandardButton
            label="View Profile"
            variation="white"
            to={creatorUrl}
            link
            flat
          />
        )}
      </span>
    </ProfileHoverPopoverContent>
  );
};

CreatorProfileHoverCard.propTypes = {
  popoutProps: PropTypes.object.isRequired,
  creator: PropTypes.instanceOf(Map).isRequired,
  mobile: PropTypes.bool,
};

CreatorProfileHoverCard.defaultProps = {
  mobile: false,
};

export default memo(CreatorProfileHoverCard);
