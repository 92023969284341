import { css } from "aphrodite";

import HomepageSidebarCategories from "./HomepageSidebarCategories";
import HomepageSidebarCreators from "./HomepageSidebarCreators";
import HomepageSidebarMore from "./HomepageSidebarMore";
import HomepageSidebarPodcasts from "./HomepageSidebarPodcasts";
import HomepageSidebarSuggestedFollows from "./HomepageSidebarSuggestedFollows";

import useLoggedInUser from "hooks/useLoggedInUser";
import { useStyles } from "hooks/useStyles";

const baseStyles = {
  sidebar: {
    display: "flex",
    flexDirection: "column",
  },
};

const HomepageSidebarInFeed = (props) => {
  const user = useLoggedInUser();
  const { styles } = useStyles(baseStyles, props);

  return (
    <aside className={css(styles.sidebar)}>
      {user && <HomepageSidebarSuggestedFollows inFeed />}
      <HomepageSidebarCategories inFeed />
      <HomepageSidebarPodcasts inFeed />
      <HomepageSidebarCreators inFeed />
      <HomepageSidebarMore inFeed />
    </aside>
  );
};

export default HomepageSidebarInFeed;
