import { useCallback, useMemo } from "react";

import FooterColumn from "./FooterColumn";

import authActionCreators from "actions/auth";
import modalActions from "actions/modals";
import * as authStates from "constants/auth-states";
import { selectAuthLoggedIn } from "selectors/auth";

import useActionCreators from "hooks/useActionCreators";
import useLoggedInUser from "hooks/useLoggedInUser";
import useReduxState from "hooks/useReduxState";

const AccountFooterLinks = () => {
  const user = useLoggedInUser();

  const PROFILE_ACCOUNT_LINKS = [
    { title: "Account Settings", to: "/profile/settings/account" },
    user && { title: "User Profile", to: `/users/${user.get("username")}` },
    { title: "Manage my podcast", to: "/profile/claimed" },
    { title: "Manage my credits", to: "/profile/creators" },
    { title: "Manage my API", to: "/profile/settings/api" },
  ];

  const isLoggedIn = useReduxState((state) => selectAuthLoggedIn(state), []);

  const { startAuthFlow, logout, showModal } = useActionCreators({
    ...modalActions,
    ...authActionCreators,
  });

  const handleFindFriends = useCallback(
    () => showModal("onboarding", { onboardingStep: "users" }),
    [showModal]
  );

  const handleRegister = useCallback(
    () =>
      startAuthFlow(authStates.REGISTER_FORM, {
        analyticsVariables: { triggered_by: "footerRegister" },
      }),
    [startAuthFlow]
  );

  const handleLogin = useCallback(
    () =>
      startAuthFlow(authStates.LOGIN_FORM, {
        analyticsVariables: { triggered_by: "footerLogin" },
      }),
    [startAuthFlow]
  );

  const handleLoggedOutFindFriends = useCallback(
    () =>
      startAuthFlow(authStates.LOGIN_FORM, {
        entityAction: "action",
        actionTitle:
          "Login or create an account to find your friends on Podchaser",
        analyticsVariables: { triggered_by: "footerFindFriends" },
      }),
    [startAuthFlow]
  );

  const accountLinks = useMemo(() => {
    if (isLoggedIn) {
      return [
        { title: "Account" },
        ...PROFILE_ACCOUNT_LINKS,
        { title: "Find Friends", action: handleFindFriends },
      ];
    }

    return [
      { title: "Account" },
      { title: "Register", action: handleRegister },
      { title: "Log In", action: handleLogin },
      { title: "Find Friends", action: handleLoggedOutFindFriends },
    ];
  }, [
    isLoggedIn,
    handleFindFriends,
    logout,
    handleRegister,
    handleLogin,
    handleLoggedOutFindFriends,
  ]);

  return <FooterColumn links={accountLinks} />;
};

export default AccountFooterLinks;
