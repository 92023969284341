import { css } from "aphrodite";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  activitySkeletonBody: {
    ...gStyles.skeletonPlaceholder,
    borderRadius: 8,
    color: colours.grey,
    height: "13rem",
    width: "100%",

    [ScreenSizes.lgAndAbove]: {
      height: "14rem",
    },
  },
};

const TimelineSkeletonItemBody = (props) => {
  const { styles } = useStyles(baseStyles, props);

  return <div className={css(styles.activitySkeletonBody)} />;
};

export default TimelineSkeletonItemBody;
