import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const ProfileBookmarks = Loadable(() => import("./ProfileBookmarks"));

function BookmarksViewAsyncLoading(props) {
  return <LoadableLoading {...props} page />;
}

const ProfileBookmarksContainer = (props) => {
  return (
    <ProfileBookmarks {...props} fallback={<BookmarksViewAsyncLoading />} />
  );
};

export default ProfileBookmarksContainer;
