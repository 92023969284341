export const LANDING = "auth/LANDING";
export const LOGIN_FORM = "auth/LOGIN_FORM";
export const REGISTER_FORM = "auth/REGISTER_FORM";
export const SOCIAL_AUTH_LOADING = "auth/SOCIAL_AUTH_LOADING";
export const SOCIAL_USERNAME_FORM = "auth/SOCIAL_USERNAME_FORM";
export const SOCIAL_PASSWORD_FORM = "auth/SOCIAL_PASSWORD_FORM";
export const REQUEST_PASSWORD_RESET_FORM = "auth/REQUEST_PASSWORD_RESET_FORM";
export const PASSWORD_RESET_SENT = "auth/PASSWORD_RESET_SENT";
export const PASSWORD_RESET_FORM = "auth/PASSWORD_RESET_FORM";
export const PASSWORD_RESET_SUCCESS = "auth/PASSWORD_RESET_SUCCESS";
export const LOGGED_IN = "auth/LOGGED_IN";
export const AUTH_LOADING = "auth/AUTH_LOADING";
export const AUTH_ERROR = "auth/AUTH_ERROR";
export const MAGIC_LINK_PROFILE_COMPLETION = "MAGIC_LINK_PROFILE_COMPLETION";
