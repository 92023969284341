import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

import ScreenSizes from "styles/ScreenSizes";

const loadingStyles = {
  height: {
    height: "137rem",
    maxHeight: "137rem",
    minHeight: 0,

    [ScreenSizes.lgAndAbove]: {
      height: "87rem",
      maxHeight: "87rem",
    },
  },
};

const CategorySearch = Loadable(() => import("./CategorySearch"));

function CategorySearchAsyncLoading(props) {
  return <LoadingOverlay height={"137rem"} styles={loadingStyles} />;
}

const CategorySearchContainer = (props) => {
  return (
    <CategorySearch {...props} fallback={<CategorySearchAsyncLoading />} />
  );
};

export default CategorySearchContainer;
