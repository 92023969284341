import PropTypes from "prop-types";
import { Component } from "react";

import ChunkLoadErrorModal from "./ChunkLoadErrorModalAsync";

const isChunkLoadError = (error) => /ChunkLoadError/.test(error.name);

class ChunkLoadErrorBoundary extends Component {
  state = {
    isChunkLoadError: false,
    hasError: false,
    error: null,
  };

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { isChunkLoadError: isChunkLoadError(error), hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.isChunkLoadError) {
      return <ChunkLoadErrorModal />;
    }

    if (this.state.hasError && !this.state.isChunkLoadError) {
      throw this.state.error;
    }

    return this.props.children;
  }
}

ChunkLoadErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.array,
  ]).isRequired,
};

export default ChunkLoadErrorBoundary;
