import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

import ScreenSizes from "styles/ScreenSizes";

const loadingStyles = {
  height: {
    height: "406rem",
    maxHeight: "406rem",
    minHeight: 0,

    [ScreenSizes.lgAndAbove]: {
      height: "350rem",
      maxHeight: "350rem",
    },
  },
};

const CategoryView = Loadable(() => import("./CategoryViewContainer"));

function CategoryViewAsyncLoading() {
  return <LoadingOverlay height={"137rem"} styles={loadingStyles} />;
}

const CategoryViewContainer = (props) => {
  return <CategoryView {...props} fallback={<CategoryViewAsyncLoading />} />;
};

export default CategoryViewContainer;
