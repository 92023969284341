import Loadable from "@loadable/component";
const UserListEditShareModal = Loadable(() =>
  import("./UserListEditShareModal")
);

function AboutAsyncLoading() {
  return null;
}

const UserListEditShareModalContainer = (props) => {
  return <UserListEditShareModal {...props} fallback={<AboutAsyncLoading />} />;
};

export default UserListEditShareModalContainer;
