import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

const HomepageFeedLoggedOut = Loadable(() =>
  import(
    /* webpackChunkName: "HomepageFeedLoggedOut" */ "./HomepageFeedLoggedOut"
  )
);

function HomepageFeedLoggedOutLoading(props) {
  return <LoadingOverlay {...props} span height={"136rem"} />;
}

const HomepageFeedLoggedOutContainer = (props) => {
  return (
    <HomepageFeedLoggedOut
      {...props}
      fallback={<HomepageFeedLoggedOutLoading />}
    />
  );
};

export default HomepageFeedLoggedOutContainer;
