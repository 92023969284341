import Loadable from "@loadable/component";
const UserProfileHoverPopover = Loadable(() =>
  import("./UserProfileHoverPopover")
);

function PopoverLoading() {
  return null;
}

const UserProfileHoverPopoverContainer = (props) => {
  return <UserProfileHoverPopover {...props} fallback={<PopoverLoading />} />;
};

export default UserProfileHoverPopoverContainer;
