import Loadable from "@loadable/component";

const AdBuyingModal = Loadable(() => import("./AdBuyingModal"));

function AdBuyingModalAsyncLoading(props) {
  return null;
}

const AdBuyingModalContainer = (props) => {
  return <AdBuyingModal {...props} fallback={<AdBuyingModalAsyncLoading />} />;
};

export default AdBuyingModalContainer;
