import { List, Set } from "immutable";
import PropTypes from "prop-types";
import { Fragment, useCallback, useMemo } from "react";

import AggregateActivity from "./Activity/AggregateActivity";
import {
  getActivitiesByActorOrObjectType,
  getActorLinks,
  getAggregateEntity,
  getEntityTypeCounts,
  getShownByField,
  getUniqueFields,
  groupActivities,
  useAggregateEntities,
} from "./Activity/AggregateUtils";
import AppearActivity from "./AppearActivityAsync";

import commaList from "utils/text/commaList";
import indefiniteArticle from "utils/text/indefiniteArticle";

const AppearAggregate = (props) => {
  const { aggregateProps, mobile, ...rest } = props;

  const getActorRoles = useCallback(
    (passedActivities) =>
      Set(passedActivities.map((activity) => activity.get("role"))).toArray(),
    []
  );

  const filteredActivities = useMemo(
    () => getActivitiesByActorOrObjectType(aggregateProps),
    [aggregateProps]
  );
  const entities = useAggregateEntities(aggregateProps.activities);

  const groupedActivities = useMemo(() => {
    const { grouped: actorGrouped, ungrouped: actorUngrouped } =
      groupActivities(filteredActivities.toArray(), "actor");
    const { grouped: objectGrouped, ungrouped: objectUngrouped } =
      groupActivities(actorUngrouped, "object");
    const { grouped: podcastGrouped, ungrouped: podcastUngrouped } =
      groupActivities(objectUngrouped, "podcast_id");

    return [
      ...actorGrouped,
      ...objectGrouped,
      ...podcastGrouped,
      ...podcastUngrouped.map((act) => ({
        activities: List([act]),
        groupedBy: "none",
      })),
    ];
  }, [filteredActivities]);

  // eslint-disable-next-line react/display-name
  const renderIntroText = useCallback(
    (appearProps) => (introProps) => {
      if (appearProps.singleActor) {
        return (
          <Fragment>
            {introProps.renderEntityLink("creator", appearProps.singleActor)}{" "}
            appeared on {getEntityTypeCounts(appearProps.objects)}{" "}
            {appearProps.allSamePodcast && (
              <Fragment>
                of{" "}
                {introProps.renderEntityLink(
                  "podcast",
                  appearProps.allSamePodcast
                )}
              </Fragment>
            )}
            {" as "}
            {commaList(getActorRoles(appearProps.activities), (role, index) => {
              if (index === 0) {
                return `${indefiniteArticle(role)} ${role}`;
              }

              return role;
            })}
          </Fragment>
        );
      }

      return (
        <Fragment>
          {getActorLinks(appearProps.actors)} appeared on{" "}
          {appearProps.allSameEpisode
            ? "an episode"
            : getEntityTypeCounts(appearProps.objects)}
          {appearProps.allSamePodcast && (
            <Fragment>
              {" "}
              of{" "}
              {introProps.renderEntityLink(
                "podcast",
                appearProps.allSamePodcast
              )}
            </Fragment>
          )}
        </Fragment>
      );
    },
    [getActorRoles]
  );

  const renderAggregate = useCallback(
    (grouped) => {
      const actors = getUniqueFields(grouped.activities, "actor");
      const objects = getUniqueFields(grouped.activities, "object");
      const podcast_ids = getUniqueFields(grouped.activities, "podcast_id");

      let singleActor = null;
      let allSamePodcast = null;
      let allSameEpisode = false;
      let entity_type = null;
      let entity = null;
      let card_entity_type = null;
      let card_entity = null;

      if (grouped.groupedBy === "actor") {
        const [actor_type, actor_id] = actors.first().split(":");
        const actor = getAggregateEntity(entities, actor_type, actor_id);

        entity_type = actor_type;
        entity = actor;
        singleActor = actor;

        if (podcast_ids.size === 1) {
          const podcast = getAggregateEntity(
            entities,
            "podcast",
            podcast_ids.first()
          );

          card_entity_type = "podcast";
          card_entity = podcast;
        }
      } else if (grouped.groupedBy === "object") {
        const [object_type, object_id] = objects.first().split(":");
        const object = getAggregateEntity(entities, object_type, object_id);

        entity_type = object_type;
        entity = object;
        allSameEpisode = true;
        allSamePodcast = getAggregateEntity(
          entities,
          "podcast",
          podcast_ids.first()
        );
      } else if (grouped.groupedBy === "podcast_id") {
        const podcast = getAggregateEntity(
          entities,
          "podcast",
          podcast_ids.first()
        );

        entity_type = "podcast";
        entity = podcast;
        allSamePodcast = podcast;
      }

      const appearProps = {
        actors,
        objects,
        singleActor,
        allSameEpisode,
        allSamePodcast,
        entity_type,
        entity,
        activities: grouped.activities,
      };

      return (
        <AggregateActivity
          {...rest}
          key={grouped.activities.first().get("id")}
          aggregateProps={aggregateProps}
          activities={grouped.activities}
          shownByField={getShownByField(aggregateProps)}
          renderIntroText={renderIntroText(appearProps)}
          mobile={mobile}
          entity_type={entity_type}
          entity={entity}
          card_entity_type={card_entity_type}
          card_entity={card_entity}
          loaded
          highlightType="Appear"
        />
      );
    },
    [entities, aggregateProps, mobile, renderIntroText, rest]
  );

  const renderActivity = (activity) => (
    <AppearActivity {...props} key={activity.get("id")} />
  );

  if (filteredActivities.size === 1) {
    return renderActivity(filteredActivities.first());
  }

  return (
    <Fragment>
      {groupedActivities.map((grouped) => {
        if (grouped.groupedBy === "none") {
          return renderActivity(grouped.activities.first());
        }

        return renderAggregate(grouped);
      })}
    </Fragment>
  );
};

AppearAggregate.propTypes = {
  aggregateProps: PropTypes.object.isRequired,
  mobile: PropTypes.bool,
};

AppearAggregate.defaultProps = {
  mobile: false,
};

export default AppearAggregate;
