import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

import ScreenSizes from "styles/ScreenSizes";

export const loadingStyles = {
  height: {
    height: "231.25rem",
    maxHeight: "231.25rem",
    minHeight: 0,

    [ScreenSizes.lgAndAbove]: {
      height: "350rem",
      maxHeight: "350rem",
    },
  },
};

const LoggedOutHomepageContainer = Loadable(() =>
  import(/* webpackChunkName: "LoggedOutHomepage" */ "./LoggedOutHomepage")
);

function LoggedOutHomepageLoading(props) {
  return <LoadingOverlay {...props} height={"450rem"} styles={loadingStyles} />;
}

const LoggedOutHomepageAsyncContainer = (props) => {
  return (
    <LoggedOutHomepageContainer
      {...props}
      fallback={<LoggedOutHomepageLoading />}
    />
  );
};

export default LoggedOutHomepageAsyncContainer;
