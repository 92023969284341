import { API_ACCESS_MODULE } from "modules/moduleIds";
import { loadAppDataPromise } from "routines/apiAccess";

import {
  selectAppData,
  selectAppDataError,
  selectAppDataLoading,
  selectAppDataLoaded,
} from "selectors/account";

import useAddModules from "hooks/useAddModules";
import { useLoggedIn } from "hooks/useLoggedInUser";
import useReduxState from "hooks/useReduxState";
import useRoutinePromises from "hooks/useRoutinePromises";

let beganLoading = false;

const MODULE_CONFIG = {
  path: "useAppData",
  modules: [API_ACCESS_MODULE],
};

export default function useAppData() {
  const isLoggedIn = useLoggedIn();
  const { loadAppData } = useRoutinePromises({
    loadAppData: loadAppDataPromise,
  });
  const { allModulesLoaded } = useAddModules(MODULE_CONFIG);

  const appData = useReduxState((state) => {
    const data = selectAppData(state);

    return !data || data.size === 0 ? null : data;
  }, []);
  const loaded = useReduxState((state) => selectAppDataLoaded(state), []);
  const loading = useReduxState(
    (state) => !allModulesLoaded || selectAppDataLoading(state),
    [allModulesLoaded]
  );
  const error = useReduxState((state) => selectAppDataError(state), []);

  if (allModulesLoaded && !beganLoading && isLoggedIn && !loaded && !loading) {
    beganLoading = true;
    loadAppData();
  }

  return {
    appData,
    loaded,
    loading,
    error,
  };
}

export const useHasGeneratedAppToken = () => {
  const { appData } = useAppData();

  return !!appData;
};
