import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import BasicTooltip from "components/Common/Tooltip/BasicTooltip";

import { useStyles } from "hooks/useStyles";

const baseStyles = {
  socialListItem: {
    height: "fit-content",
    marginRight: "0.6rem",
    fontSize: "1.5rem",
    margin: "0 0.25rem",
  },
  socialListItemAnchor: {
    padding: "0.5rem",
  },
};
const SocialMedicaIcons = (props) => {
  const { styles, css } = useStyles(baseStyles);

  const { to, label, icon } = props;

  return (
    <li className={css(styles.socialListItem)}>
      <BasicTooltip renderTooltip={() => label}>
        {(tooltipProps) => (
          <div {...tooltipProps}>
            <a
              className={css(styles.socialListItemAnchor)}
              href={to}
              title={label}
            >
              <FontAwesomeIcon icon={icon} />
            </a>
          </div>
        )}
      </BasicTooltip>
    </li>
  );
};

export default SocialMedicaIcons;
