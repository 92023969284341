import atob from "atob";
import axios from "axios";
import ls from "local-storage";

import { setCookie, removeCookie } from "./cookies";

import client from "api/live/client";
import { LOCALSTORAGE_TOKEN_KEY } from "constants/base";
import { prodLog } from "utils/dev";

export function setToken(newToken) {
  ls(LOCALSTORAGE_TOKEN_KEY, newToken);
  setCookie(LOCALSTORAGE_TOKEN_KEY, newToken, {
    path: "/",
    maxAge: 7 * 24 * 60 * 60,
  });
}

export const getStoredToken = () => {
  return ls(LOCALSTORAGE_TOKEN_KEY);
};

export async function getToken(forceRefreshToken = false) {
  const storageToken = getStoredToken();

  if (forceRefreshToken) {
    return refreshToken(storageToken);
  }

  if (storageToken && tokenExpired(storageToken)) {
    return refreshToken(storageToken);
  } else {
    return storageToken;
  }
}

let _activeRefresh;
function refreshToken(tokenToRefresh) {
  if (!_activeRefresh) {
    _activeRefresh = axios
      .get("/auth/refresh", {
        headers: { Authorization: `Bearer ${tokenToRefresh}` },
      })
      .then((response) => {
        return response?.data?.token;
      })
      .catch((error) => {
        return Promise.resolve();
      })
      .then((newToken) => {
        if (newToken) {
          setToken(newToken, true);
        } else {
          const currentStoredToken = getStoredToken();
          if (currentStoredToken === tokenToRefresh) {
            clearToken();
          } else {
            return currentStoredToken;
          }
          clearToken();
        }
        return newToken;
      });

    _activeRefresh.then((_token) =>
      setTimeout(() => (_activeRefresh = undefined), 1)
    );
  }
  return _activeRefresh;
}

function tokenExpired(token) {
  if (!token) {
    return true;
  }

  try {
    const obj = JSON.parse(atob(token.split(".")[1]));
    const now = Math.floor(new Date().getTime() / 1000);
    return now + 5 * 60 >= obj.exp;
  } catch (e) {
    prodLog("tokenExpired catch", e);

    return false;
  }
}

export const clearToken = () => {
  removeCookie(LOCALSTORAGE_TOKEN_KEY, { path: "/", secure: true });
  removeCookie(LOCALSTORAGE_TOKEN_KEY, { path: "/", secure: false });
  removeCookie(LOCALSTORAGE_TOKEN_KEY);
  ls.remove(LOCALSTORAGE_TOKEN_KEY);

  removeCookie("pc-user-roles", { path: "/", secure: true });
  removeCookie("pc-user-roles", { path: "/", secure: false });
  removeCookie("pc-user-roles");
  ls.remove("pc-user-roles");

  // trigger custom event used by useLocalStorage in usehooks-ts to ensure anything using useCookie is updated
  if (typeof window !== "undefined") {
    window.dispatchEvent(
      new StorageEvent("local-storage", { key: LOCALSTORAGE_TOKEN_KEY })
    );
    window.dispatchEvent(
      new StorageEvent("local-storage", { key: "pc-user-roles" })
    );
  }

  delete client.defaults.headers.common.Authorization;
};

export const parseJwt = (token) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join("")
  );

  return JSON.parse(jsonPayload);
};
