import Loadable from "@loadable/component";

import TimelineSkeleton from "components/Timeline/TimelineSkeleton";

const HomepageFeed = Loadable(() =>
  import(/* webpackChunkName: "HomepageFeed" */ "./HomepageFeed")
);

function HomepageFeedLoading(props) {
  return <TimelineSkeleton />;
}

const HomepageFeedContainer = (props) => {
  return <HomepageFeed {...props} fallback={<HomepageFeedLoading />} />;
};

export default HomepageFeedContainer;
