/* eslint-disable max-len */

export default {
  prefix: "fpc",
  iconName: "pc-follow",
  icon: [
    512,
    512,
    [],
    null,
    "M326.999861,340.49997 C326.999861,379.988641 294.540586,412 254.499373,412 C214.45816,412 181.999991,379.988641 181.999991,340.49997 C181.999991,301.011298 214.459266,268.99994 254.500479,268.99994 C294.541692,268.99994 326.999861,301.012389 326.999861,340.49997 Z M485.056954,186.85789 C492.743188,193.696685 492.994203,205.565638 485.691925,212.806765 L447.320389,250.851752 C440.603903,257.511225 429.790172,257.959515 422.651173,251.746785 C327.365264,168.823302 184.431768,169.00025 89.3478177,251.746667 C82.209603,257.960165 71.3958708,257.511868 64.6786014,250.851617 L26.3072471,212.806394 C19.0055348,205.566244 19.2573209,193.696518 26.9435496,186.857728 C157.159645,70.9855368 354.979535,71.1092737 485.056954,186.85789 Z",
  ],
};
