import pluralize from "pluralize";
import { memo } from "react";

import BetaLogo from "components/Common/Branding/BetaLogo";

import { useStyles } from "hooks/useStyles";

const baseStyles = {
  container: {
    position: "relative",
  },
};

const betaTagStyles = {
  betaTag: {
    right: "-1.75rem",
  },
};

function SearchHeadingBrands(props) {
  const { styles, css } = useStyles(baseStyles, props);

  return (
    <span className={css(styles.container)}>
      {pluralize("Brand")}
      <BetaLogo styles={betaTagStyles}>Beta</BetaLogo>
    </span>
  );
}

export default memo(SearchHeadingBrands);
