export const networkAggregationTransformer = (value) => {
  const aggregationData = value.categories.buckets.map((bucket) => {
    let activeCount = 0;
    let inactiveCount = 0;

    bucket.aggregations.statuses.buckets.forEach((bucket) => {
      if (bucket?.key === "Active") {
        activeCount += bucket?.doc_count;
      }
      if (bucket?.key === "Idle") {
        inactiveCount += bucket?.doc_count;
      }
    });

    return {
      label: bucket.entity.text,
      activeCount,
      inactiveCount,
    };
  });

  aggregationData?.sort((a, b) => b.activeCount - a.activeCount);

  return aggregationData;
};
