import truncateUrlSlug from "./truncateUrlSlug";

import slugify from "utils/misc/slugify";

const getBrandSlug = (brandId, brandName) => {
  if (!brandId || !brandName) {
    return null;
  }

  const slug = slugify(brandName) || "";

  return `${truncateUrlSlug(slug.toLowerCase())}-${brandId}`;
};

export default getBrandSlug;
