import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo } from "react";
import { Link } from "react-router-dom";

import ProfileNavigationSidebarStyles from "components/Profiles/ProfileNavigationSidebar/ProfileNavigationSidebarStyles";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";

const baseStyles = {
  verifyLink: {
    ...ProfileNavigationSidebarStyles.profileNavigationSidebarPanel,
    border: "1px solid rgb(229, 229, 229)",
    borderRadius: 5,
  },
  verifyLinkIcon: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    color: colours.primary,
    width: "2.5rem",
    height: "2.5rem",
    fontSize: "1.25rem",
  },
  verifyLinkLabel: {
    ...ProfileNavigationSidebarStyles.profileNavigationSidebarPanelTitle,
    marginBottom: 0,
    marginLeft: ".5rem",
  },
};

const AccountHubVerifyLink = (props) => {
  const { url, action, icon, label, ...linkProps } = props;
  const { styles } = useStyles(baseStyles, props);

  const ComponentType = url ? Link : "div";
  const componentProps = url ? { to: url } : { onClick: action };

  return (
    <ComponentType
      className={css(styles.verifyLink)}
      {...componentProps}
      {...linkProps}
    >
      <div className={css(styles.verifyLinkIcon)}>
        <FontAwesomeIcon icon={icon} />
      </div>
      <div className={css(styles.verifyLinkLabel)}>{label}</div>
    </ComponentType>
  );
};

AccountHubVerifyLink.propTypes = {
  url: PropTypes.string,
  action: PropTypes.func,
  icon: PropTypes.node,
  label: PropTypes.node,
};

export default memo(AccountHubVerifyLink);
