import { createSelector } from "reselect";

import { selectSpecificUser } from "selectors/user";
import { userHasPermission, userHasProPermission } from "utils/entity/user";

export const selectAuthLoggedIn = (state) =>
  state.auth.getIn(["isLoggedIn"]) &&
  state.auth.get("user_id") &&
  !!selectSpecificUser(state, state.auth.get("user_id"));
export const selectAuthLoggingIn = (state) => state.auth.getIn(["loggingIn"]);
export const selectHasLoggedOut = (state) => state.auth.getIn(["hasLoggedOut"]);
export const selectAuthUser = (state) => {
  if (state.auth.get("user_id")) {
    return selectSpecificUser(state, state.auth.get("user_id"));
  }

  return null;
};
export const selectAuthUsername = createSelector(selectAuthUser, (user) =>
  user ? user.get("username") : null
);
export const selectAuthEmail = createSelector(selectAuthUser, (user) =>
  user ? user.get("email") : null
);
export const selectUserHasRole = (state, role) => {
  const user = selectAuthUser(state);
  return user && user.get("roles") && user.get("roles").includes(role);
};
export const selectUserHasPermission = (state, permission) => {
  const user = selectAuthUser(state);

  return userHasPermission(user, permission);
};
export const selectUserPermission = (state) => {
  const user = selectAuthUser(state);

  return user?.get("permissions");
};
export const selectUserHasProPermission = (state) => {
  const user = selectAuthUser(state);

  return userHasProPermission(user);
};

export const selectUserHasAnyRole = (state, roles) => {
  const user = selectAuthUser(state);
  return (
    user &&
    user.get("roles") &&
    user.get("roles").some((r) => roles.includes(r))
  );
};
export const selectPreAuthData = (state) => state.auth.get("preAuthData");

export const selectSocialConnectDenial = (state) =>
  state.auth.get("socialConnectDenial");

export const selectRecaptchaToken = (state) => state.auth.get("recaptchaToken");

export const selectSocialConnectionError = (state, socialType, entity_type) =>
  state.auth.getIn(["socialConnectionError", socialType, entity_type]);
export const selectSocialConnectSyncDetails = (
  state,
  socialType,
  entity_type
) => state.auth.getIn(["socialConnectSyncDetails", socialType, entity_type]);
