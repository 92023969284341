import { faRedo } from "@fortawesome/free-solid-svg-icons/faRedo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo, useCallback, useMemo } from "react";

import ActivityContext from "components/Analytics/ActivityContext";
import LoadingOverlay from "components/Common/LoadingOverlay";
import CreatorCard from "components/Entities/Cards/CreatorCards/CreatorCardContainerAsync";
import PodcastCard from "components/Entities/Cards/PodcastCards/PodcastCardContainerAsync";
import UserCard from "components/Entities/Cards/UserCards/UserCardContainerAsync";

import HomepageSidebarSection from "./HomepageSidebarSection";

import modalActions from "actions/modals";
import paginationActions from "actions/pagination";
import * as sortConstants from "constants/sort";
import loadCreatorsList from "sagas/pagination/lists/loadCreatorsList";
import loadPodcastsList from "sagas/pagination/lists/loadPodcastsList";
import loadUsersList from "sagas/pagination/lists/loadUsersList";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import useList from "hooks/useList";
import useLoadListEffect from "hooks/useLoadListEffect";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  entityContainer: {
    width: "100%",
    marginTop: "1rem",

    ":first-child": {
      marginTop: 0,
    },
  },
  showMoreLink: {
    ...gStyles.fontSemiBold,
    fontSize: ".75rem",
    color: colours.primaryTextBackground,
    marginTop: "1rem",
    cursor: "pointer",
  },
  reloadIcon: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
    fontSize: ".75rem",
    cursor: "pointer",
    transition: "500ms color",
    color: "#999",
    marginRight: ".5rem",

    ":hover": {
      color: colours.lightGrey,
    },
  },
};

const loadingStyles = {
  noOverlay: {
    fontSize: "1.4rem",
  },
  icon: {
    maxWidth: "1.4em",
  },
};

const PODCASTS_LIST_CONFIG = {
  sort: sortConstants.SORT_ORDER_RANDOM,
  list_type: "podcasts_filtered",
  loadListAction: loadPodcastsList,
  entity_type: "podcast",
  pageSize: 1,
  staticFilters: {
    rank: {
      value: 50,
    },
  },
  rescore: { sort: sortConstants.SORT_ORDER_RANDOM, window: 100 },
};

const CREATORS_LIST_CONFIG = {
  sort: sortConstants.SORT_ORDER_RANDOM,
  list_type: "creators_filtered",
  loadListAction: loadCreatorsList,
  entity_type: "creator",
  pageSize: 1,
  staticFilters: {
    rank: {
      value: 50,
    },
    profile_image: {
      value: "has_image",
    },
  },
  rescore: { sort: sortConstants.SORT_ORDER_RANDOM, window: 100 },
};

const USERS_LIST_CONFIG = {
  sort: sortConstants.SORT_ORDER_RANDOM,
  sort_direction: "desc",
  list_type: "users_filtered",
  loadListAction: loadUsersList,
  entity_type: "user",
  pageSize: 1,
  staticFilters: {
    rank: {
      value: 50,
    },
    profile_image: {
      value: "has_image",
    },
    visible_for: {
      value: ["suggestions"],
    },
  },
  rescore: { sort: sortConstants.SORT_ORDER_RANDOM, window: 100 },
};

const PODCASTS_LIST_KEY = "homepage/sidebar/follow/podcasts";
const CREATORS_LIST_KEY = "homepage/sidebar/follow/creators";
const USERS_LIST_KEY = "homepage/sidebar/follow/users";

const HomepageSidebarSuggestedFollows = (props) => {
  const { inFeed } = props;
  const { styles } = useStyles(baseStyles, props);

  const { showModal, replaceList } = useActionCreators({
    showModal: modalActions.showModal,
    replaceList: paginationActions.replaceList,
  });

  const activityContext = useMemo(
    () => ({
      section: "sidebar_suggested",
    }),
    []
  );

  const { loading: podcastsLoading, ids: podcasts } =
    useList(PODCASTS_LIST_KEY);
  const { loading: creatorsLoading, ids: creators } =
    useList(CREATORS_LIST_KEY);
  const { loading: usersLoading, ids: users } = useList(USERS_LIST_KEY);

  const loading = podcastsLoading || creatorsLoading || usersLoading;

  useLoadListEffect(PODCASTS_LIST_KEY, PODCASTS_LIST_CONFIG);
  useLoadListEffect(CREATORS_LIST_KEY, CREATORS_LIST_CONFIG);
  useLoadListEffect(USERS_LIST_KEY, USERS_LIST_CONFIG);

  const handleShowMore = useCallback(() => {
    showModal("onboarding", { showFollowStages: true });
    sendGAEvent({ action: "sidebarSuggestedFollowsShowMoreClicked" });
  }, [showModal]);

  const handlerReload = useCallback(() => {
    sendGAEvent({ action: "sidebarSuggestedFollowsReloadClicked" });
    replaceList({ key: PODCASTS_LIST_KEY });
    replaceList({ key: CREATORS_LIST_KEY });
    replaceList({ key: USERS_LIST_KEY });
  }, [replaceList]);

  const header = useMemo(
    () => ({
      title: "Suggested Follows",
      inlineWithTitle: (
        <div className={css(styles.reloadIcon)} onClick={handlerReload}>
          <FontAwesomeIcon icon={faRedo} spin={loading} />
        </div>
      ),
    }),
    [handlerReload, loading, styles.reloadIcon]
  );

  const renderEntity = (entity_type, CardType) => (entity_id) =>
    (
      <div
        key={`${entity_type}_${entity_id}`}
        className={css(styles.entityContainer)}
      >
        <CardType entity_id={entity_id} layout="compact" />
      </div>
    );

  return (
    <HomepageSidebarSection
      header={header}
      showAsList
      TitleComponent="h3"
      inFeed={inFeed}
    >
      <ActivityContext.Provider value={activityContext}>
        {!loading &&
          podcasts &&
          podcasts.map(renderEntity("podcast", PodcastCard))}
        {!loading &&
          creators &&
          creators.map(renderEntity("creator", CreatorCard))}
        {!loading && users && users.map(renderEntity("user", UserCard))}
        {loading && (
          <LoadingOverlay styles={loadingStyles} noOverlay noPadding />
        )}
        <div className={css(styles.showMoreLink)} onClick={handleShowMore}>
          Show More
        </div>
      </ActivityContext.Provider>
    </HomepageSidebarSection>
  );
};

HomepageSidebarSuggestedFollows.propTypes = {
  inFeed: PropTypes.bool,
};

HomepageSidebarSuggestedFollows.defaultProps = {
  inFeed: false,
};

export default memo(HomepageSidebarSuggestedFollows);
