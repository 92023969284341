import { VIEW_SPONSOR_HISTORY_PERMISSION } from "utils/entity/user";

import useUserHasPermission from "hooks/useUserHasPermission";

export default () => {
  const userHasSponsorHistoryPermission = useUserHasPermission(
    VIEW_SPONSOR_HISTORY_PERMISSION
  );

  return userHasSponsorHistoryPermission;
};
