import { css } from "aphrodite";
import PropTypes from "prop-types";
import { useCallback } from "react";

import StandardButton from "components/Buttons/StandardButton";

import authActions from "actions/auth";
import * as authStates from "constants/auth-states";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  container: {
    ...gStyles.gradientBackground,
    color: "#fff",
    padding: "1.5rem 1.25rem 1.25rem",
    textAlign: "center",
    [ScreenSizes.lgAndAbove]: {
      borderRadius: 20,
    },
  },
  containerNoBackground: {
    background: "none",
  },
  heading: {
    ...gStyles.avalonBold,
    margin: "0 0 1rem",
    padding: 0,
  },
  content: {
    margin: "0 0 1rem",
    padding: 0,
  },
  ul: {
    margin: 0,
    padding: 0,
    fontSize: "0.875rem",
  },
  li: {
    marginBottom: "0.5rem",
  },
  strong: {
    ...gStyles.fontSemiBold,
  },
};

function CTABox(props) {
  const {
    title,
    content,
    children,
    button,
    buttonLabel,
    buttonLink,
    handleButtonClick,
    hideButton,
    hideTitle,
    hideContent,
    noBackground,
    dataId,
    analyticsVariables,
  } = props;
  const { styles } = useStyles(baseStyles, props);

  const { startAuthFlow } = useActionCreators({
    startAuthFlow: authActions.startAuthFlow,
  });

  const defaultHandleButtonClick = useCallback(
    () =>
      startAuthFlow(authStates.REGISTER_FORM, {
        analyticsVariables: {
          triggered_by: "CTABox",
        },
      }),
    [startAuthFlow]
  );

  const handleClick = useCallback(
    (e) => {
      sendGAEvent({
        action: "pageCTAClicked",
        ...(analyticsVariables || {}),
      });
      if (handleButtonClick) {
        handleButtonClick(e);
      } else {
        defaultHandleButtonClick();
      }
    },
    [defaultHandleButtonClick, handleButtonClick, analyticsVariables]
  );

  const renderDefaultContent = () => (
    <ul className={css(styles.ul)}>
      <li className={css(styles.li)}>
        <strong className={css(styles.strong)}>Rate</strong>
        {" podcasts and episodes"}
      </li>
      <li className={css(styles.li)}>
        <strong className={css(styles.strong)}>Follow</strong>
        {" podcasts and creators"}
      </li>
      <li className={css(styles.li)}>
        <strong className={css(styles.strong)}>Create</strong>
        {" podcast and episode lists"}
      </li>
      <li className={css(styles.li)}>{"& much more"}</li>
    </ul>
  );

  const buttonProps = {
    label: buttonLabel,
    variation: "pink",
    customStyles: {
      button: {
        height: "auto",
      },
    },
    dataId,
  };
  if (buttonLink) {
    buttonProps.href = buttonLink;
  } else {
    buttonProps.onClick = handleClick;
  }

  const renderDefaultButton = () => <StandardButton {...buttonProps} rounded />;

  return (
    <div
      className={css(
        styles.container,
        noBackground && styles.containerNoBackground
      )}
    >
      {!hideTitle && <h3 className={css(styles.heading)}>{title}</h3>}
      <div className={css(styles.content)}>
        {children || (!hideContent && (content || renderDefaultContent()))}
      </div>
      {!hideButton && (button || renderDefaultButton())}
    </div>
  );
}

CTABox.propTypes = {
  hideButton: PropTypes.bool,
  hideTitle: PropTypes.bool,
  hideContent: PropTypes.bool,
  title: PropTypes.string,
  buttonLabel: PropTypes.string,
  dataId: PropTypes.string,
  analyticsVariables: PropTypes.object,
  content: PropTypes.node,
  children: PropTypes.node,
  button: PropTypes.node,
  buttonLink: PropTypes.string,
  handleButtonClick: PropTypes.func,
  noBackground: PropTypes.bool,
};

CTABox.defaultProps = {
  hideButton: false,
  hideTitle: false,
  hideContent: false,
  content: null,
  title: "Join Podchaser to...",
  buttonLabel: "Create an Account",
  dataId: "cta-box-button",
  analyticsVariables: null,
};

export default CTABox;
