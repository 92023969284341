import Loadable from "@loadable/component";
const PodcastProfileHoverPopover = Loadable(() =>
  import("./PodcastProfileHoverPopover")
);

function PopoverLoading() {
  return null;
}

const PodcastProfileHoverPopoverContainer = (props) => {
  return (
    <PodcastProfileHoverPopover {...props} fallback={<PopoverLoading />} />
  );
};

export default PodcastProfileHoverPopoverContainer;
