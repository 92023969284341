import equals from "ramda/src/equals";
import { useCallback, useEffect, useRef } from "react";

import { log } from "utils/dev";

const requestIdleCallback =
  typeof window !== "undefined" ? window.requestIdleCallback : null;
const connection =
  typeof window !== "undefined"
    ? window.navigator && window.navigator.connection
    : null;

let scriptLoaded = false;
const IDLE_PERIOD = 250;
const MOBILE_TIMEOUT = 25000; // 25 seconds

const useLoadScript = (scriptName, scriptLoader, options = {}) => {
  const {
    disable = false,
    reload = null,
    loadOnMount = false,
    idlePeriod = IDLE_PERIOD,
    timeout = MOBILE_TIMEOUT,
  } = options;
  const lastReload = useRef(reload);

  const loadScript = useCallback(() => {
    if (!scriptLoader) {
      // eslint-disable-next-line no-console
      console.error("No script loading function given to userLoadScript");
      return;
    }

    if (!scriptLoaded || equals(reload, lastReload.current)) {
      scriptLoaded = true;
      scriptLoader();
      if (performance) {
        log(`start loading: ${scriptName}, time origin: ${performance.now()}`);
      }
    }
  }, [scriptName, scriptLoader, reload]);

  useEffect(() => {
    // Don't load if idlePeriod is 0, null or undefined
    if (typeof window === "undefined" || !idlePeriod || disable) {
      return;
    }

    // Don't load if 2g connection or save-data is enabled
    if (
      connection &&
      (connection.saveData || /2g/.test(connection.effectiveType))
    ) {
      return;
    }

    // deadline.timeRemaining() has an upper limit of 50 milliseconds
    // We want to ensure the page has been idle for a significant period of time
    // Therefore we count consecutive maximum timeRemaining counts and load chat when we reach our threshold
    let elapsedIdlePeriod = 0;
    let previousTimeRemaining = 0;

    const scheduleLoadChat = (deadline) => {
      if (
        // load script if page has beccome idle or has been open for over TIMEOUT seconds
        elapsedIdlePeriod > idlePeriod ||
        (performance && performance.now() > timeout)
      ) {
        return loadScript();
      }

      const timeRemaining = deadline.timeRemaining();
      // To ensure browser is idle, only accumalte elapsedIdlePeriod when
      // two consecutive maximum timeRemaining's have been observed
      if (previousTimeRemaining > 49 && timeRemaining > 49) {
        elapsedIdlePeriod += timeRemaining;
      }

      previousTimeRemaining = timeRemaining;
      requestIdleCallback(scheduleLoadChat, { timeout });
    };

    if (loadOnMount) {
      loadScript();
    } else if (requestIdleCallback) {
      requestIdleCallback(scheduleLoadChat, { timeout });
    } else {
      setTimeout(() => loadScript(), idlePeriod);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disable, reload]);
};

export default useLoadScript;
