import { faAlignLeft } from "@fortawesome/pro-regular-svg-icons/faAlignLeft";
import { faBadgeDollar } from "@fortawesome/pro-solid-svg-icons/faBadgeDollar";
import { faBellOn } from "@fortawesome/pro-solid-svg-icons/faBellOn";
import React from "react";

import NavDropdownMenu, {
  defaultMenuStyles,
} from "./NavMenuDropdownItem/DropdownNavMenu";

import userHasInternalPermission from "hooks/useUserHasInternalPermission";

import ScreenSizes from "styles/ScreenSizes";

const menuStyles = {
  ...defaultMenuStyles,
  menuContainer: {
    ...defaultMenuStyles.menuContainer,
    maxWidth: "100%",

    [ScreenSizes.lgAndAbove]: {
      width: "max-content",
    },
  },
  item: {
    ...defaultMenuStyles.item,
    padding: 0,
  },
};

export const NAV_LINKS = [
  {
    id: "alerts",
    label: "Alerts",
    url: "/alerts",
    icon: faBellOn,
    description:
      "Get notified every time your keyword is mentioned on a podcast.",
  },
  {
    id: "brands",
    label: "Brands",
    description: "View sponsor data across top podcasts",
    url: "/brands",
    icon: faBadgeDollar,
  },
  {
    id: "reports",
    label: "[Internal Only] Monitoring Report",
    description: "Generate a keyword report",
    url: "/monitoring-report",
    icon: faAlignLeft,
  },
];
const MonitoringNavMenu = (props) => {
  const userIsInternal = userHasInternalPermission();
  const filteredNavLinks = userIsInternal
    ? NAV_LINKS
    : NAV_LINKS.filter((item) => item.id !== "reports");

  return (
    <NavDropdownMenu
      {...props}
      menuItems={filteredNavLinks}
      menuStyles={menuStyles}
    />
  );
};

export default MonitoringNavMenu;
