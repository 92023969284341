/* eslint-disable no-prototype-builtins */
import { css } from "aphrodite";
import MaybeDownshift from "downshift";
import { List } from "immutable";
import PropTypes from "prop-types";
import pick from "ramda/src/pick";
import { useCallback, useRef } from "react";

import SearchField from "./SearchField";
import SearchResults from "./SearchResultsAsync";

import { log } from "utils/dev";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const Downshift = MaybeDownshift.default || MaybeDownshift;

const baseStyles = {
  autocompleteOuter: {
    ...gStyles.fontRegular,
    fontSize: ".813rem",
    backgroundColor: "white",
    padding: "5px",
    position: "absolute",
    zIndex: 999,
    width: "100%",
    maxWidth: "100%",
    boxShadow: "0 5px 16px 0 rgba(0,0,0,0.07)",
    borderBottomLeftRadius: "25px",
    borderBottomRightRadius: "25px",
    [ScreenSizes.lgAndAbove]: {
      padding: "2rem 0.313rem 1rem 0.313rem",
      marginTop: "-1.25rem",
      maxHeight: "calc(100vh - 3.75rem)",
      overflowY: "auto",
    },
  },
  noFloat: {
    position: "relative",
    boxShadow: "none",
    padding: 0,
  },
  message: {
    ...gStyles.fontSemiBold,
    padding: "0.4em 0.6em",
    color: colours.bodyText,
    textAlign: "center",
  },
  searchContainer: {
    position: "relative",
    maxWidth: "47rem",
    margin: "0 auto",
  },
};

const DEFAULT_HIGHLIGHT_INDEX = null;

function SearchAutocomplete(props) {
  const {
    ariaLabel,
    avoidIconEffect,
    cancelSearch,
    dataId,
    forwardedRef,
    getResultItemSubtitle,
    hideHeading,
    hideResults,
    hideType,
    hideViewAll,
    noFloatingResults,
    noResultsMessage,
    onCloseClick,
    onInputValueChange,
    onItemSelect,
    onKeyDown,
    onTermChange,
    preventClickOverride,
    preventRedirect,
    renderExtraIconWithSearchTip,
    renderNoResults,
    renderResultItem,
    resultItemStyles,
    results,
    searchIconOnly,
    searchMessage,
    searching,
    selectedType,
    showViewLink,
    switchToWhiteStyles,
    withSectionHeaders,
    mobileSearch,
    onFocus,
    containerRef,
    renderTopSearchResults,
  } = props;

  const { styles } = useStyles(baseStyles, props);
  const inputRef = useRef(null);

  const setInputRef = useCallback(
    (element) => {
      if (forwardedRef && typeof forwardedRef === "function") {
        forwardedRef(element);
      } else if (forwardedRef && typeof forwardedRef === "object") {
        forwardedRef.current = element;
      }

      inputRef.current = element;
      if (mobileSearch && element) {
        element.focus();
      }
    },
    [forwardedRef, mobileSearch]
  );

  const onClose = useCallback(() => {
    cancelSearch && cancelSearch();
    onCloseClick();
  }, [cancelSearch, onCloseClick]);

  const itemToString = useCallback((item) => {
    if (item && item.getIn) {
      return item.getIn(["_source", "title"]);
    }

    return "";
  }, []);

  const handleInputValueChanges = useCallback(
    (value, changes) => {
      const itemString =
        changes.selectedItem && itemToString(changes.selectedItem);

      if (
        Object.prototype.hasOwnProperty.call(changes, "inputValue") &&
        value !== undefined &&
        itemString !== changes.inputValue
      ) {
        // don't clear input value on mouse up
        if (
          (changes.type !== Downshift.stateChangeTypes.mouseUp &&
            changes.type !== Downshift.stateChangeTypes.touchEnd) ||
          value !== ""
        ) {
          if (onInputValueChange) {
            onInputValueChange(value);
          }
          if (onTermChange) {
            onTermChange(value);
          }
        }
      }
    },
    [onInputValueChange, onTermChange, itemToString]
  );

  const handleOnChange = useCallback(
    (selectedItem, e) => {
      if (selectedItem && onItemSelect) {
        if (inputRef.current) {
          inputRef.current.blur();
        }

        onItemSelect(selectedItem, { cancelSearch }, e);
      }
    },
    [onItemSelect, cancelSearch]
  );

  const handleOnFocus = useCallback(
    (inputValue, openMenu) => (e) => {
      if (onFocus) {
        onFocus(e);
      }

      if (inputValue !== "") {
        openMenu();
      }
    },
    [onFocus]
  );

  const stateReducer = useCallback((state, changes) => {
    log("SearchAutocomplete downshift stateReducer", state, changes);
    if (changes.type === Downshift.stateChangeTypes.keyDownEscape) {
      // make sure the item is deselected on escape
      return { ...state, ...changes, selectedItem: undefined };
    }
    return { ...state, ...changes };
  }, []);

  return (
    <Downshift
      onSelect={handleOnChange}
      itemToString={itemToString}
      stateReducer={stateReducer}
      defaultHighlightedIndex={DEFAULT_HIGHLIGHT_INDEX}
      onInputValueChange={handleInputValueChanges}
      inputValue={props.topSearchTerm || props.search_term || ""}
    >
      {({
        getInputProps,
        getItemProps,
        getRootProps,
        isOpen,
        inputValue,
        highlightedIndex,
        openMenu,
        closeMenu,
      }) => (
        <div
          data-id={dataId}
          {...getRootProps({}, { suppressRefError: true })}
          aria-labelledby={undefined}
          aria-label={ariaLabel}
          className={css(styles.searchContainer)}
        >
          <SearchField
            switchToWhiteStyles={switchToWhiteStyles}
            getInputProps={getInputProps}
            placeholder={props.placeholder}
            styles={props.searchFieldStyles}
            searching={searching}
            onCloseClick={onClose}
            renderTopSearchResults={renderTopSearchResults}
            onFocus={handleOnFocus(inputValue, openMenu)}
            ref={setInputRef}
            containerRef={containerRef}
            onKeyDown={onKeyDown && onKeyDown(closeMenu)}
            {...pick(
              [
                "onBlur",
                "onTypeChange",
                "renderInputExtra",
                "searchKey",
                "searchStyles",
                "selectedType",
                "showCloseWhenEmpty",
                "showCloseWhenTextEntered",
                "showTypeSelect",
                "types",
                "white",
              ],
              props
            )}
            ariaLabel={props.placeholder}
            isOpen={isOpen}
            avoidIconEffect={avoidIconEffect}
            renderExtraIconWithSearchTip={renderExtraIconWithSearchTip}
            searchIconOnly={searchIconOnly}
          />
          {isOpen && inputValue && !hideResults && (
            <div
              className={css(
                styles.autocompleteOuter,
                noFloatingResults && styles.noFloat
              )}
            >
              {searching && (!results || results.size === 0) && (
                <div className={css(styles.message)}>{searchMessage}</div>
              )}
              <SearchResults
                closeMenu={() => {
                  closeMenu();
                  onClose();
                }}
                getItemProps={getItemProps}
                getResultItemSubtitle={getResultItemSubtitle}
                hideHeading={hideHeading}
                hideType={hideType}
                hideViewAll={hideViewAll}
                highlightedIndex={highlightedIndex}
                preventClickOverride={preventClickOverride}
                preventRedirect={preventRedirect}
                renderResultItem={renderResultItem}
                resultItemStyles={resultItemStyles}
                results={results}
                selectedType={selectedType}
                showViewLink={showViewLink}
                term={inputValue}
                withSectionHeaders={withSectionHeaders}
              />
              {!searching &&
                (!results || results.size === 0) &&
                (renderNoResults ? (
                  renderNoResults({
                    highlightedIndex,
                    getItemProps,
                    inputValue,
                  })
                ) : (
                  <div className={css(styles.message)}>{noResultsMessage}</div>
                ))}
            </div>
          )}
        </div>
      )}
    </Downshift>
  );
}

SearchAutocomplete.propTypes = {
  ariaLabel: PropTypes.string,
  avoidIconEffect: PropTypes.bool,
  cancelSearch: PropTypes.func.isRequired,
  dataId: PropTypes.string,
  forwardedRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  getResultItemSubtitle: PropTypes.func,
  hideHeading: PropTypes.bool,
  hideResults: PropTypes.bool,
  hideType: PropTypes.bool,
  hideViewAll: PropTypes.bool,
  noFloatingResults: PropTypes.bool,
  noResultsMessage: PropTypes.node,
  onCloseClick: PropTypes.func,
  onFocus: PropTypes.func,
  onInputValueChange: PropTypes.func.isRequired,
  onItemSelect: PropTypes.func,
  onKeyDown: PropTypes.func,
  onTermChange: PropTypes.func,
  placeholder: PropTypes.string,
  preventClickOverride: PropTypes.bool,
  preventRedirect: PropTypes.bool,
  renderExtraIconWithSearchTip: PropTypes.func,
  renderNoResults: PropTypes.func,
  renderResultItem: PropTypes.func,
  resultItemStyles: PropTypes.object,
  results: PropTypes.instanceOf(List),
  searchFieldStyles: PropTypes.object,
  searchIconOnly: PropTypes.bool,
  searchMessage: PropTypes.node,
  search_term: PropTypes.string,
  searching: PropTypes.bool,
  showViewLink: PropTypes.bool,
  switchToWhiteStyles: PropTypes.bool,
  withSectionHeaders: PropTypes.bool,
};

SearchAutocomplete.defaultProps = {
  ariaLabel: undefined,
  dataId: "search-auto-complete",
  forwardedRef: () => undefined,
  getResultItemSubtitle: null,
  hideHeading: false,
  hideResults: false,
  hideType: false,
  hideViewAll: false,
  noFloatingResults: false,
  noResultsMessage: "No results",
  onCloseClick: () => undefined,
  onItemSelect: null,
  onTermChange: null,
  placeholder: null,
  preventClickOverride: false,
  preventRedirect: false,
  renderNoResults: null,
  renderResultItem: null,
  resultItemStyles: null,
  results: undefined,
  searchFieldStyles: null,
  searchMessage: "Searching titles...",
  search_term: undefined,
  searching: false,
  showViewLink: false,
  withSectionHeaders: true,
};

export default SearchAutocomplete;
