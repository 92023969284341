import { getBaseUrl } from "constants/base";
import { log } from "utils/dev";

export const getPostMedia = (post, size) => {
  let details;
  let url;
  let height;
  let width;
  let title;

  const base_details = post.getIn(["_embedded", "wp:featuredmedia", "0"], null);

  if (!base_details) {
    return null;
  }

  if (size) {
    const media_details = base_details.getIn(["media_details"]);

    log("sizes", media_details ? media_details.toJS() : undefined);

    details =
      base_details.getIn(["media_details", "sizes", size]) || base_details;

    url = details.get("source_url");
    height = details.get("height");
    width = details.get("width");
    title = base_details.getIn(["title", "rendered"]);
  } else {
    url = base_details.get("source_url");
    height = base_details.getIn(["media_details", "height"]);
    width = base_details.getIn(["media_details", "width"]);
    title = base_details.getIn(["title", "rendered"]);
  }

  return {
    url,
    height,
    width,
    title,
  };
};

export const BLOG_BASE_PATH = "/articles";

export const generateBlogBaseUrl = () => `${getBaseUrl()}${BLOG_BASE_PATH}`;

export const generateBlogPostPath = (post) => {
  const category =
    post &&
    post.getIn(["_embedded", "wp:term", "0", "0", "slug"], "Uncategorized");
  return category && `${BLOG_BASE_PATH}/${category}/${post.get("slug")}`;
};

export const generateBlogCategoryPath = (category) =>
  category
    ? `${BLOG_BASE_PATH}/${
        typeof category === "string" ? category : category.slug
      }`
    : category;

export const generateBlogAuthorPath = (author) =>
  author
    ? `${BLOG_BASE_PATH}/authors/${
        typeof author === "string" ? author : author.slug
      }`
    : null;

export const blogTitleTemplate = "%s | Articles on Podchaser";
