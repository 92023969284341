import Loadable from "@loadable/component";
const IntegrationSuggestionModal = Loadable(() =>
  import("./IntegrationSuggestionModal")
);

function AboutAsyncLoading() {
  return null;
}

const IntegrationSuggestionModalContainer = (props) => {
  return (
    <IntegrationSuggestionModal {...props} fallback={<AboutAsyncLoading />} />
  );
};

export default IntegrationSuggestionModalContainer;
