import { useCallback, useMemo } from "react";

import appActions from "actions/app";
import { size } from "utils/size";

import useActionCreators from "hooks/useActionCreators";
import useWindowSize from "hooks/useWindowSize";

import { standardBreakpoints } from "styles/ScreenSizes";

const useWindowResizeListener = () => {
  const { getWindowWidth } = useWindowSize();
  const { changeWindowSize } = useActionCreators(appActions);

  const onResize = useCallback(
    (wSize) => {
      // only fire of a window size change if the width changes, we don't care about the height
      if (wSize.windowWidth !== getWindowWidth()) {
        changeWindowSize(
          size(standardBreakpoints)({ width: wSize.windowWidth }).sizeString,
          wSize.windowWidth
        );
      }
    },
    [changeWindowSize, getWindowWidth]
  );

  return useMemo(() => ({ onResize }), [onResize]);
};

export default useWindowResizeListener;
