import { faFacebook } from "@fortawesome/free-brands-svg-icons/faFacebook";
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faReddit } from "@fortawesome/free-brands-svg-icons/faReddit";
import { faSlack } from "@fortawesome/free-brands-svg-icons/faSlack";
import { faTiktok } from "@fortawesome/free-brands-svg-icons/faTiktok";
import { faCommentLines } from "@fortawesome/pro-solid-svg-icons/faCommentLines";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo, Fragment, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";

import HomepageSidebarSection from "./HomepageSidebarSection";

import eventIsFieldTrigger from "utils/misc/eventIsFieldTrigger";
import sendGAEvent from "utils/sendGAEvent";

import { useStyles } from "hooks/useStyles";
import { useIntercomHasLoaded } from "hooks/useWindowScripts";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import faXTwitter from "styles/icons/faXTwitter";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  linksColumn: {
    flex: 1,

    [ScreenSizes.mdAndBelow]: {
      display: "flex",
      flex: "1 50%",
      justifyContent: "center",
      marginBottom: "1rem",
    },
  },
  linksList: {
    [ScreenSizes.mdAndBelow]: {
      width: "100%",
      textAlign: "center",
    },
  },
  headerLink: {
    fontWeight: 600,
    fontSize: "1em",
    lineHeight: "2.5em",
  },
  linkItem: {
    ...gStyles.fontSemiBold,
    lineHeight: 1,
    cursor: "pointer",
    fontSize: "1rem",
    marginBottom: "0.5em",
  },
  link: {
    color: colours.greyishBlue,
    display: "flex",
    alignItems: "center",

    ":hover": {
      color: colours.greyishBlueDarker,
    },
    ":focus": {
      color: colours.greyishBlueDarker,
    },
  },
  icon: {
    marginRight: "0.3em",
    fontSize: "2rem",
  },
  label: {
    color: "#444",
  },
};

const HEADER = {
  title: "Connect With Podchaser",
};

const CONTACT_LINK = {
  label: "Contact",
  title: "Contact Podchaser Directly",
  icon: faCommentLines,
};

const HomepageSidebarMenu = (props) => {
  const { inFeed } = props;
  const { styles } = useStyles(baseStyles, props);
  const intercomHasLoaded = useIntercomHasLoaded();

  const handleClick = useCallback(
    (linkProps) => () =>
      sendGAEvent({
        action: "sidebarMenuLinkClick",
        link: linkProps.label,
      }),
    []
  );

  const menuLinks = useMemo(
    () => [
      [
        {
          label: "X/Twitter",
          title: "Podchaser Twitter",
          external: true,
          to: "https://twitter.com/Podchaser",
          icon: faXTwitter,
        },
        {
          label: "TikTok",
          title: "Podchaser TikTok",
          external: true,
          to: "https://tiktok.com/@podchaser",
          icon: faTiktok,
        },
        {
          label: "Slack Channel",
          title: "Podchaser Slack",
          external: true,
          to:
            "https://join.slack.com/t/podchaser-community/shared_invite" +
            "/enQtNDE5MjEzNjMyOTM1LTFjMmZmOWNmMmUyMGE3ZDM0NWZiNzYxMGRkZDE5NDUwZjUzYmY2MzAyNGU2MTZhNGVmZjg5YzZlZTNkYzZkNDE",
          icon: faSlack,
        },
        {
          label: "Facebook",
          title: "Podchaser Facebook",
          external: true,
          to: "https://facebook.com/Podchaser",
          icon: faFacebook,
        },
        {
          label: "Reddit",
          title: "Podchaser Reddit",
          external: true,
          to: "https://www.reddit.com/u/podchaser",
          icon: faReddit,
        },
        {
          label: "Instagram",
          title: "Podchaser Instagram",
          external: true,
          to: "https://www.instagram.com/podchaser",
          icon: faInstagram,
        },
        intercomHasLoaded
          ? {
              action: () => window.Intercom("show"),
              ...CONTACT_LINK,
            }
          : {
              to: "mailto:contact@podchaser.com",
              external: true,
              ...CONTACT_LINK,
            },
      ], // Social
    ],
    [intercomHasLoaded]
  );

  const renderLink = (linkProps, index) => {
    const { label, title, to, action, external, icon } = linkProps;
    let linkComponent = null;
    const linkCompProps = {
      "data-id": `footer-link-${label.toLowerCase()}`,
      className: css(styles.link),
      title,
      onClick: handleClick(linkProps),
    };

    const itemContent = (
      <Fragment>
        {icon && (
          <span className={css(styles.icon)}>
            <FontAwesomeIcon icon={icon} fixedWidth />
          </span>
        )}
        <span className={css(styles.label)}>{label}</span>
      </Fragment>
    );

    if (to) {
      linkComponent = external ? (
        <a
          href={to}
          target="_blank"
          rel="noopener noreferrer"
          {...linkCompProps}
        >
          {itemContent}
        </a>
      ) : (
        <Link to={to} {...linkCompProps}>
          {itemContent}
        </Link>
      );
    } else {
      linkComponent = <span {...linkCompProps}>{itemContent}</span>;
    }

    return (
      <li
        key={index}
        className={css(styles.linkItem)}
        onClick={action}
        onKeyDown={(e) => eventIsFieldTrigger(e) && action(e)}
      >
        {linkComponent}
      </li>
    );
  };

  const renderLinksColumn = (links, index) => (
    <div key={index} className={css(styles.linksColumn)}>
      <ul className={css(styles.linksList)}>{links.map(renderLink)}</ul>
    </div>
  );

  return (
    <HomepageSidebarSection header={HEADER} inFeed={inFeed}>
      {menuLinks.map(renderLinksColumn)}
    </HomepageSidebarSection>
  );
};

HomepageSidebarMenu.propTypes = {
  inFeed: PropTypes.bool,
};

HomepageSidebarMenu.defaultProps = {
  inFeed: false,
};

export default memo(HomepageSidebarMenu);
