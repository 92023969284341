import { faShareSquare } from "@fortawesome/free-solid-svg-icons/faShareSquare";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import MaybeDownshift from "downshift";
import PropTypes from "prop-types";
import { memo, Fragment, useCallback, useContext } from "react";
import { createPortal } from "react-dom";

import SlidingContainer from "components/Common/FlexMenu/SlidingContainerAsync";
import RequestContext from "pages/RequestContext";

import ButtonPopout from "./ButtonPopout";

import generateTransition from "utils/generateTransition";

import usePopper from "hooks/usePopper";
import { useStyles } from "hooks/useStyles";

import getHoverQuery from "styles/getHoverQuery";

const Downshift = MaybeDownshift.default || MaybeDownshift;

const baseStyles = {
  btn: {
    background: "#fff",
    color: "var(--color-neutral-d3)",
    padding: "1.125em 1.375em 0.875em",
    fontSize: "0.82em",
    fontFamily: "Montserrat",
    fontWeight: 600,
    boxShadow: "0px 3px 9px 0 rgba(0, 0, 0, 0.07)",
    textTransform: "capitalize",
    border: "none",
    cursor: "pointer",
    display: "inline-block",
    textAlign: "left",
    width: "auto",
    borderRadius: 4,

    transition: generateTransition({
      targets: ["box-shadow", "background", "color", "opacity", "border-color"],
      speed: "300ms",
    }),

    ...getHoverQuery({
      boxShadow: "0px 4px 12px 0 rgba(0, 0, 0, 0.15)",
    }),
  },
  btnSmall: {
    boxShadow: "none",
    padding: "0.5em 0.7em 0.4em",

    ...getHoverQuery({
      boxShadow: "none",
    }),
  },
  btnFullWidth: {
    display: "block",
    textAlign: "center",
    width: "100%",
  },
  icon: {
    marginRight: "0.1em",
  },
};

const MODIFIERS = {
  offset: { offset: "0,10" },
  flip: { enabled: true },
};

const ButtonWithPopout = (props) => {
  const {
    ariaLabel,
    customPopout,
    customStyles,
    disablePortal,
    fullWidth,
    hideArrow,
    id,
    isOpen,
    modifiers,
    noPadding,
    offsetArrow,
    onButtonToggle,
    placement,
    positionAbsolute,
    positionFixed,
    propsForDownshift,
    ref: passedRef,
    renderButton,
    renderContent,
    slideInFrom,
    slidingProps,
    small,
    styles: passedStyles,
    zIndex,
  } = props;
  const requestContext = useContext(RequestContext);

  const {
    reference,
    popper,
    popperStyles,
    arrow,
    arrowStyles,
    scheduleUpdate,
    placement: popperPlacement,
    popperInstance,
  } = usePopper({
    placement,
    modifiers,
    positionFixed,
    ref: passedRef,
  });

  const overwriteStyles = {
    contentWrapperOuter: {
      zIndex,
    },
    contentWrapper: {
      zIndex,
    },
  };

  const { styles } = useStyles(
    [
      baseStyles,
      { custom: customStyles ? customStyles() : {} },
      overwriteStyles,
    ],
    props
  );

  const handleStateChange = useCallback(
    (changes) => {
      // eslint-disable-next-line no-prototype-builtins
      if (
        Object.prototype.hasOwnProperty.call(changes, "isOpen") &&
        onButtonToggle
      ) {
        onButtonToggle(changes.isOpen);
      }
    },
    [onButtonToggle]
  );

  const handleClick = useCallback(
    (toggleMenu) => (e) => {
      e.preventDefault();
      toggleMenu(e);
    },
    []
  );

  // todo: update this button to AbstractButton after it will publish
  const renderDefaultButton = (buttonProps) => {
    const { toggleMenu, ref } = buttonProps;

    return (
      <button
        type="button"
        onClick={handleClick(toggleMenu)}
        className={css(
          styles.btn,
          styles.custom,
          fullWidth && styles.btnFullWidth,
          small && styles.btnSmall
        )}
        aria-label={ariaLabel}
        ref={ref}
      >
        <FontAwesomeIcon className={css(styles.icon)} icon={faShareSquare} />
        &nbsp; Share
      </button>
    );
  };

  const renderSlidingMenu = (downshiftProps) => (
    <SlidingContainer
      slideInFrom={slideInFrom}
      styles={slidingProps ? slidingProps.slidingStyles : null}
      {...slidingProps}
      onClose={downshiftProps.closeMenu}
      arrowAnchorRef={reference}
      showArrow={!hideArrow}
    >
      {renderContent(downshiftProps)}
    </SlidingContainer>
  );

  const renderRegularPopover = (contentProps) => {
    const popoutProps = {
      renderContent,
      offsetArrow,
      slideInFrom,
      noPadding,
      positionAbsolute,
      hideArrow,
      disablePortal,
      ariaLabel,
      placement: popperPlacement,
      contentProps,
      popper,
      popperStyles,
      scheduleUpdate,
      arrow,
      arrowStyles,
      styles: passedStyles,
      popperInstance,
    };

    if (customPopout) {
      return renderContent(popoutProps);
    }

    return <ButtonPopout {...popoutProps} />;
  };

  const renderReference = (contentProps) => {
    const finalRenderButton = renderButton || renderDefaultButton;

    return finalRenderButton({
      ...contentProps,
      fullWidth,
      small,
      ref: reference,
      styles,
    });
  };

  const renderPopoverContent = (contentProps) => {
    const { isOpen: downshiftIsOpen } = contentProps;

    if (downshiftIsOpen) {
      if (disablePortal) {
        return renderRegularPopover(contentProps);
      }

      return createPortal(
        slideInFrom
          ? renderSlidingMenu(contentProps)
          : renderRegularPopover(contentProps),
        document && document.querySelector("#app-base")
      );
    }

    return null;
  };

  const renderMainContent = (contentProps) => (
    <Fragment>
      {renderReference({
        ...contentProps,
        ...contentProps.getRootProps({}, { suppressRefError: true }),
      })}
      {requestContext.server ? null : renderPopoverContent(contentProps)}
    </Fragment>
  );

  return (
    <Downshift
      id={id}
      isOpen={isOpen}
      onStateChange={handleStateChange}
      {...propsForDownshift}
    >
      {renderMainContent}
    </Downshift>
  );
};

ButtonWithPopout.propTypes = {
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  customPopout: PropTypes.bool,
  customStyles: PropTypes.func,
  disablePortal: PropTypes.bool,
  expand: PropTypes.bool,
  fullWidth: PropTypes.bool,
  hideArrow: PropTypes.bool,
  id: PropTypes.string,
  inline: PropTypes.bool,
  isOpen: PropTypes.any,
  modifiers: PropTypes.object,
  noPadding: PropTypes.bool,
  offsetArrow: PropTypes.bool,
  onButtonToggle: PropTypes.func,
  placement: PropTypes.string,
  positionAbsolute: PropTypes.bool,
  positionFixed: PropTypes.bool,
  propsForDownshift: PropTypes.object,
  ref: PropTypes.object,
  renderButton: PropTypes.func,
  renderContent: PropTypes.func.isRequired,
  slideInFrom: PropTypes.string,
  slidingProps: PropTypes.object,
  small: PropTypes.bool,
  styles: PropTypes.object,
  zIndex: PropTypes.number,
};

ButtonWithPopout.defaultProps = {
  ariaLabel: undefined,
  className: "",
  customPopout: false,
  customStyles: null,
  disablePortal: false, // use this if used within a modal
  expand: false,
  fullWidth: false,
  hideArrow: false,
  id: null,
  inline: false,
  isOpen: undefined,
  modifiers: MODIFIERS,
  noPadding: false,
  offsetArrow: false,
  onButtonToggle: null,
  placement: "bottom-end",
  positionAbsolute: false,
  positionFixed: false,
  propsForDownshift: {},
  ref: null,
  renderButton: undefined,
  slideInFrom: null,
  slidingProps: {},
  small: false,
  styles: undefined,
  zIndex: 9600,
};

export default memo(ButtonWithPopout);
