import throttle from "lodash/throttle";
import PropTypes from "prop-types";
import { useCallback, useMemo, useRef, useState } from "react";

import ButtonWithPopout from "components/Buttons/ButtonWithPopout";

import NavMenuDropdownToggleButton from "./NavMenuDropdownToggleButton";

import appActions from "actions/app";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import useWindowSize from "hooks/useWindowSize";
import useWindowSizeChangeEffect from "hooks/useWindowSizeChangeEffect";

import ScreenSizes from "styles/ScreenSizes";

const dropdownMenuStyles = {
  contentWrapperOuter: {
    [ScreenSizes.mdAndBelow]: {
      right: ".75rem",
    },
    [ScreenSizes.lgAndAbove]: {
      paddingTop: 0,
    },
  },
  contentWrapper: {
    padding: 0,
    borderRadius: 6,
  },
};

const NavMenuItemWithDropdown = (props) => {
  const { item, switchToWhiteStyles } = props;

  const { isWindowSizeOrLess } = useWindowSize();
  const mobile = isWindowSizeOrLess("medium");

  const [isOpen, setIsOpen] = useState(false);
  const throttledSetIsOpenRef = useRef(throttle(() => setIsOpen(false), 200));

  const { menu: MenuComponent } = item;

  const { navigateTo } = useActionCreators({
    navigateTo: appActions.navigateTo,
  });

  useWindowSizeChangeEffect(() => {
    throttledSetIsOpenRef.current();
  });

  const handleMenuItemClick = useCallback(
    (selectedItem, downshiftProps) => {
      if (!selectedItem) {
        return null;
      }

      if (selectedItem.action) {
        selectedItem.action();
      }
      if (selectedItem.url) {
        if (selectedItem.external) {
          window.open(selectedItem.url, "_blank");
        } else {
          navigateTo(selectedItem.url);
        }
      }

      downshiftProps.clearSelection();
      sendGAEvent({
        action: "navMenuLinkClicked",
        link: selectedItem.id,
        route: selectedItem.url,
        context: selectedItem.context || "DropdownNavMenuItem",
      });

      return null;
    },
    [navigateTo]
  );

  const handleButtonToggle = useCallback(
    (open) => {
      if (open) {
        sendGAEvent({
          action: "navMenuLinkClicked",
          context: item.context || "NavMenuItemWithDropdown",
          triggeredBy: "dropdownOpened",
          link: item.id,
        });
      }

      setIsOpen(open);
    },
    [item]
  );

  const handleCloseMenu = useCallback(() => setIsOpen(false), []);

  const propsForDownshift = useMemo(
    () => ({
      onSelect: handleMenuItemClick,
    }),
    [handleMenuItemClick]
  );

  const renderContent = useCallback(
    (downshiftProps) => (
      <MenuComponent
        downshiftProps={downshiftProps}
        closeMenu={handleCloseMenu}
      />
    ),
    [handleCloseMenu]
  );

  const renderButton = useCallback(
    (downshiftProps) => (
      <NavMenuDropdownToggleButton
        downshiftProps={downshiftProps}
        item={item}
        switchToWhiteStyles={switchToWhiteStyles}
        {...props}
      />
    ),
    [item, switchToWhiteStyles]
  );

  return (
    <ButtonWithPopout
      isOpen={isOpen}
      propsForDownshift={propsForDownshift}
      renderContent={renderContent}
      renderButton={renderButton}
      onButtonToggle={handleButtonToggle}
      styles={dropdownMenuStyles}
      ariaLabel="Show item sub-menu"
      positionFixed={mobile}
      placement={mobile ? "top-middle" : item.arrowPlacement || "top-start"}
      offsetArrow={!mobile}
    />
  );
};

NavMenuItemWithDropdown.propTypes = {
  item: PropTypes.shape({
    badge: PropTypes.object,
    menu: PropTypes.func,
    id: PropTypes.string,
    context: PropTypes.string,
    arrowPlacement: PropTypes.string,
  }).isRequired,
  switchToWhiteStyles: PropTypes.bool,
  passedLabelStyles: PropTypes.bool,
};

export default NavMenuItemWithDropdown;
