import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const MonitoringReportPage = Loadable(() => import("./MonitoringReportPage"));

function MonitoringReportPageAsyncLoading(props) {
  return <LoadableLoading {...props} page />;
}

const LandingPageContainer = (props) => {
  return (
    <MonitoringReportPage
      {...props}
      fallback={<MonitoringReportPageAsyncLoading />}
    />
  );
};

export default LandingPageContainer;
