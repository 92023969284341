import { useMemo } from "react";

import {
  selectPostFailed,
  selectPostLoading,
  selectSpecificPost,
} from "./blog";
import {
  selectCategoryFailed,
  selectCategoryLoading,
  selectSpecificCategory,
} from "./category";
import { selectSpecificTag, selectTagFailed, selectTagLoading } from "./tags";
import {
  selectListFailed,
  selectListLoading,
  selectSpecificList,
} from "./userlist";

import { selectBadgeById } from "selectors/badges";
import {
  selectCreatorFailed,
  selectCreatorLoading,
  selectSpecificCreator,
  selectSpecificCreditSubmission,
} from "selectors/creator";
import { selectSpecificIntegration } from "selectors/integration";
import {
  selectPodcastFailed,
  selectPodcastLoading,
  selectSpecificPodcast,
  selectSpecificPodcasts,
  selectEpisodeFailed,
  selectEpisodeLoading,
  selectSpecificEpisode,
  selectProDataForPodcast,
  selectProDataLoading,
  selectProDataFailed,
} from "selectors/podcast";
import { selectSpecificReview } from "selectors/reviews";
import {
  selectSpecificUser,
  selectUserFailed,
  selectUserLoading,
} from "selectors/user";

import useReduxState from "hooks/useReduxState";

export const selectEntityProtected = (state, entity_type, entity_id) => {
  const entity = selectEntity(state, entity_id, entity_type);
  switch (entity_type) {
    case "user":
    default:
      return !!entity.get("protected_profile");
  }
};

export const selectEntityRequiresFollowConfirmation = (
  state,
  entity_type,
  entity_id
) => {
  const entity = selectEntity(state, entity_id, entity_type);
  if (!entity) {
    return false;
  }

  switch (entity_type) {
    case "user":
    default:
      return !!entity.get("requires_follow_confirmation");
  }
};

export const selectEntity = (state, entity_id, entity_type) => {
  switch (entity_type) {
    case "podcast":
      return selectSpecificPodcast(state, entity_id);
    case "episode":
      return selectSpecificEpisode(state, entity_id);
    case "creator":
      return selectSpecificCreator(state, entity_id);
    case "userlist":
      return selectSpecificList(state, entity_id);
    case "post":
      return selectSpecificPost(state, entity_id);
    case "category":
      return selectSpecificCategory(state, entity_id);
    case "tag":
      return selectSpecificTag(state, entity_id);
    case "review":
      return selectSpecificReview(state, entity_id);
    case "user":
    case "user_by_username":
      return selectSpecificUser(
        state,
        entity_id,
        entity_type === "user_by_username"
      );
    case "integration":
      return selectSpecificIntegration(state, entity_id);
    case "pro_data":
      return selectProDataForPodcast(state, entity_id);
    case "credit_submission":
      return selectSpecificCreditSubmission(state, entity_id);
    case "badge":
      return selectBadgeById(state.badges, entity_id);
    default:
      return null;
  }
};
export const selectEntities = (state, entity_ids, entity_type) => {
  switch (entity_type) {
    case "podcast":
      return selectSpecificPodcasts(state, entity_ids);
    default:
      return null;
  }
};

export const selectEntityFromMap = (allEntities, entity_type, entity_id) => {
  switch (entity_type) {
    case "podcast":
      return allEntities.getIn(["podcasts", entity_id?.toString()]);
    case "episode":
      return allEntities.getIn(["episodes", entity_id?.toString()]);
    case "userlist":
      return allEntities.getIn(["userlists", entity_id?.toString()]);
    case "creator":
      return allEntities.getIn(["creators", entity_id?.toString()]);
    default:
      return null;
  }
};

export const selectEntityWithNestedEntities = (
  state,
  entity_type,
  entity_id
) => {
  let entity = selectEntity(state, entity_type, entity_id);

  if (entity && (entity.has("podcast") || entity.has("podcast_id"))) {
    const podcast_id = entity.get("podcast_id") || entity.get("podcast");
    const podcast = selectSpecificPodcast(state, podcast_id);

    entity = entity.set("podcast", podcast);
  }
  if (entity && entity.has("user") && typeof entity.get("user") === "number") {
    const user = selectSpecificUser(state, entity.get("user"));

    entity = entity.set("user_entity", user);
  }
  if (entity && entity.get("entity_type") && entity.get("entity_id")) {
    entity = entity.set(
      "entity",
      selectEntityWithNestedEntities(
        state,
        entity.get("entity_type"),
        entity.get("entity_id")
      )
    );
  }

  return entity;
};

export const useRelatedEntities = (entity) => {
  const entities = {};
  let podcast_id;
  let user_id;
  let userlist_id;
  let foreign_id;
  let foreign_type;

  const isReview = entity && entity.get("entity_type") === "review";

  if (entity && (entity.has("podcast") || entity.has("podcast_id"))) {
    podcast_id = entity.get("podcast_id") || entity.get("podcast");
  }
  if (
    entity &&
    (entity.has("user_id") ||
      (entity.has("user") && typeof entity.get("user") === "number"))
  ) {
    user_id = entity.get("user_id") || entity.get("user");
  }
  if (entity && entity.has("foreign_id")) {
    foreign_id = entity.get("foreign_id").split(":")[1];
    foreign_type = entity.get("foreign_id").split(":")[0];
  }
  if (
    entity &&
    (entity.has("userlist_id") ||
      (entity.has("userlist") && typeof entity.get("userlist") === "number"))
  ) {
    userlist_id = entity.get("userlist_id") || entity.get("userlist");
  }

  entities.podcast = useReduxState(
    (state) => selectSpecificPodcast(state, podcast_id),
    [podcast_id]
  );
  entities.user = useReduxState(
    (state) => selectSpecificUser(state, user_id),
    [user_id]
  );
  entities.userlist = useReduxState(
    (state) => userlist_id && selectSpecificList(state, userlist_id),
    [userlist_id]
  );

  const entity_type = entity && entity.get("entity_type");
  const entity_id = entity && entity.get("entity_id");

  entities.entity = useReduxState(
    (state) =>
      entity_type && entity_id && selectEntity(state, entity_type, entity_id),
    [entity_type, entity_id]
  );
  entities[foreign_type] = useReduxState(
    (state) =>
      foreign_type &&
      foreign_id &&
      selectEntity(state, foreign_type, foreign_id),
    [foreign_type, foreign_id]
  );

  const reviewedId = isReview && entities.entity.get("entity_id");
  const reviewedType =
    isReview && reviewedId && entities.entity.get("entity_type");
  const reviewed = {
    entity_type: isReview && entities.entity.get("entity_type"),
    entity: useReduxState(
      (state) =>
        reviewedId &&
        reviewedType &&
        selectEntity(state, reviewedType, reviewedId),
      [reviewedType, reviewedId]
    ),
  };

  if (isReview) {
    entities.reviewed = reviewed;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => entities, [entity]);
};

export const selectEntityLoading = (state, entity_id, entity_type) => {
  switch (entity_type) {
    case "podcast":
      return selectPodcastLoading(state, entity_id);
    case "episode":
      return selectEpisodeLoading(state, entity_id);
    case "userlist":
      return selectListLoading(state, entity_id);
    case "post":
      return selectPostLoading(state, entity_id);
    case "category":
      return selectCategoryLoading(state, entity_id);
    case "tag":
      return selectTagLoading(state, entity_id);
    case "creator":
      return selectCreatorLoading(state, entity_id);
    case "user":
    case "user_by_username":
      return selectUserLoading(
        state,
        entity_id,
        entity_type === "user_by_username"
      );
    case "pro_data":
      return selectProDataLoading(state, entity_id);
  }
};

export const selectEntityFailed = (state, entity_id, entity_type) => {
  switch (entity_type) {
    case "podcast":
      return selectPodcastFailed(state, entity_id);
    case "episode":
      return selectEpisodeFailed(state, entity_id);
    case "userlist":
      return selectListFailed(state, entity_id);
    case "post":
      return selectPostFailed(state, entity_id);
    case "category":
      return selectCategoryFailed(state, entity_id);
    case "creator":
      return selectCreatorFailed(state, entity_id);
    case "tag":
      return selectTagFailed(state, entity_id);
    case "user":
    case "user_by_username":
      return selectUserFailed(
        state,
        entity_id,
        entity_type === "user_by_username"
      );
    case "pro_data":
      return selectProDataFailed(state, entity_id);
  }
};
