import Loadable from "@loadable/component";

const InsightsExportLimitModal = Loadable(() =>
  import("./InsightsExportLimitModal")
);

function AsyncLoading() {
  return null;
}

const InsightsExportLimitModalContainer = (props) => {
  return <InsightsExportLimitModal {...props} fallback={<AsyncLoading />} />;
};

export default InsightsExportLimitModalContainer;
