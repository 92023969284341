import { useLocalStorage } from "usehooks-ts";

import { userHasBatmanPermission } from "utils/entity/user";

import useLoggedInUser from "hooks/useLoggedInUser";

function useIsBatmanMode() {
  const [isBatmanMode, setIsBatmanMode] = useLocalStorage(`batman-mode`, false);

  const user = useLoggedInUser();

  if (!user) {
    return {
      isBatmanMode: false,
      toggleBatmanMode: () => {},
      turnOffBatmanMode: () => {},
    };
  }

  if (!userHasBatmanPermission(user)) {
    return {
      isBatmanMode: false,
      toggleBatmanMode: () => {},
      turnOffBatmanMode: () => {},
    };
  }

  function toggleBatmanMode() {
    setIsBatmanMode((oldState) => !oldState);
  }
  function turnOffBatmanMode() {
    setIsBatmanMode(false);
  }

  return { isBatmanMode, toggleBatmanMode, turnOffBatmanMode };
}

export default useIsBatmanMode;
