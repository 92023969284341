import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

const UserHeaderMobileEllipsisModal = Loadable(() =>
  import("./UserHeaderMobileEllipsisModal")
);

function UserHeaderMobileEllipsisModalAsyncLoading(props) {
  return <LoadingOverlay {...props} span />;
}

const UserHeaderMobileEllipsisModalContainer = (props) => {
  return (
    <UserHeaderMobileEllipsisModal
      {...props}
      fallback={<UserHeaderMobileEllipsisModalAsyncLoading />}
    />
  );
};

export default UserHeaderMobileEllipsisModalContainer;
