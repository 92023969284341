import { Fragment, useEffect, useRef } from "react";
import Helmet from "react-helmet-async";
import { useHistory } from "react-router-dom";

import AccountHub from "./AccountHub";

import useWindowSize from "hooks/useWindowSize";

const AccountHubContainer = (props) => {
  const { isWindowSizeOrMore } = useWindowSize();
  const isMediumOrMore = isWindowSizeOrMore("medium");
  const history = useHistory();

  const firstRenderRef = useRef(true);

  useEffect(() => {
    // if screen changes to be large go back to last page
    if (isMediumOrMore && !firstRenderRef.current) {
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMediumOrMore]); // only change on window size changes

  useEffect(() => {
    // if desktop user goes directly to /account go to account settings
    if (isMediumOrMore) {
      history.replace("/profile/settings/account");
    }

    firstRenderRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Helmet title="Account" />
      <AccountHub {...props} />
    </Fragment>
  );
};

export default AccountHubContainer;
