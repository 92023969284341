import PopperJS from "popper.js";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

const usePopper = (options) => {
  const isClient = !!window;

  const defaultRef = useRef();
  const arrowRef = useRef();
  const popupRef = useRef();

  const ref = (options && options.ref) || defaultRef;

  const [hasPopperRef, setHasPopperRef] = useState(false);
  const [popperInstance, setPopperInstance] = useState(null);
  const [popper, setPopper] = useState(null);

  const popperOptions = useMemo(
    () => ({
      onCreate: (newPopper) => setPopper(newPopper),
      eventsEnabled: true,
      referenceElement: undefined,
      positionFixed: false,
      ...options,
      placement: options && options.placement ? options.placement : "bottom",
      modifiers: {
        ...(options && options.modifiers ? options.modifiers : {}),
        ...(arrowRef.current
          ? {
              arrow: {
                element: arrowRef.current,
              },
            }
          : {}),
      },
    }),
    [options]
  );

  const handlePopupRefChanged = useCallback((newRef) => {
    popupRef.current = newRef;

    if (newRef) {
      // trigger effect with the counter
      setHasPopperRef(true);
    } else {
      setHasPopperRef(false);
      setPopperInstance(null);
      setPopper(null);
    }
  }, []);

  useEffect(() => {
    if (
      isClient &&
      hasPopperRef &&
      ref.current &&
      popupRef.current &&
      !popperInstance
    ) {
      const instance = new PopperJS(
        ref.current,
        popupRef.current,
        popperOptions
      );

      setPopperInstance(instance);
    }
  }, [hasPopperRef, isClient, popperInstance, popperOptions, popper, ref]);

  useEffect(
    () => () => {
      if (popper && popperInstance) {
        popperInstance.destroy();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    []
  ); // should only be called once on unmount

  return {
    reference: ref,
    popper: handlePopupRefChanged,
    popperStyles: popper ? popper.styles : {},
    arrow: arrowRef,
    arrowStyles: popper ? popper.arrowStyles : {},
    placement: popper ? popper.placement : popperOptions.placement,
    scheduleUpdate: popperInstance ? popperInstance.scheduleUpdate : () => null,
    popperInstance: popperInstance || {},
    popperData: popper,
  };
};

export default usePopper;
