import { hasSubOption } from "pages/Discover/Charts/Charts";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  option: {
    border: "none",
    outline: "none",
    ...gStyles.fontMedium,
    color: colours.black,
    fontSize: "0.75rem",
    minHeight: "1.5rem",
    textAlign: "start",
    borderTop: `1px solid ${colours.white}`,

    ":hover": {
      cursor: "pointer",
      background: colours.borderGrey,
      borderRadius: 4,
      height: "100%",
    },
  },
  outerContainer: {
    display: "grid",
  },
  optionWithSubSectionContainer: {
    border: "none",
    outline: "none",
    display: "grid",
    gridTemplateRows: "max-content 1fr",
  },
  optionWithSubSection: {
    border: "none",
    outline: "none",
    ...gStyles.fontMedium,
    fontSize: "0.75rem",
    minHeight: "1.5rem",
    textAlign: "start",
    display: "grid",
    gridTemplateColumns: "1fr max-content",
    gridGap: "0.5rem",
    alignItems: "center",

    ":hover": {
      cursor: "pointer",
    },
  },
  expanded: {
    borderLeft: `4px solid var(--color-neutral-d9)`,
    display: "flex",
    flexDirection: "column",
  },
  active: {
    ...gStyles.fontBold,
    color: colours.darkishBlue,
  },
  parentActive: {
    background: colours.selectedLightBlue,
    ...gStyles.fontBold,
    color: colours.darkishBlue,
    borderRadius: 4,
    display: "grid",
    alignItems: "center",
    height: "100%",
    paddingLeft: 4,
  },
  subActive: {
    background: colours.selectedLightBlue,
    ...gStyles.fontBold,
    color: colours.darkishBlue,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    display: "grid",
    alignItems: "center",
    height: "100%",
  },
  icon: {
    width: "0.75rem",
    height: "0.75rem",
    display: "grid",
    placeItems: "center",
  },
};

export const compareFunc = (a, b) => {
  return a && b && a?.toLowerCase() === b?.toLowerCase();
};

export default function MenuItemWithSubMenu(props) {
  const { styles, css } = useStyles(baseStyles, props);
  const {
    subOptions,
    parentOption,
    selected,
    handleMainAction,
    handleSubAction,
    toggleButton,
    isSearching,
    passedRef,
  } = props;

  const childSelectedOption = hasSubOption(selected);
  let subOptionActive = null;
  subOptions?.forEach((element) => {
    if (
      compareFunc(childSelectedOption, element?.get("value")) ||
      compareFunc(childSelectedOption, element?.get("id"))
    ) {
      subOptionActive = childSelectedOption;
    }
  });

  const parentLabel = parentOption?.get("label");
  const showParent = !isSearching || parentOption?.get("resultInParent");

  return (
    <div className={css(styles.outerContainer)}>
      {showParent && (
        <button
          key={parentLabel}
          onClick={() =>
            handleMainAction(parentOption?.get("id"), toggleButton)
          }
          ref={compareFunc(selected, parentLabel) ? passedRef : null}
          className={css(
            styles.option,
            compareFunc(selected, parentLabel) && styles.active
          )}
        >
          {parentLabel}
        </button>
      )}
      {subOptions?.map((subOption) => {
        const item =
          subOption?.get("text") ||
          subOption?.get("label") ||
          subOption?.get("value");

        return (
          <button
            key={subOption?.get("id")}
            onClick={() =>
              handleSubAction(
                parentOption?.get("id"),
                subOption?.get("id"),
                toggleButton
              )
            }
            ref={
              compareFunc(subOptionActive, subOption?.get("id"))
                ? passedRef
                : null
            }
            className={css(
              styles.option,
              compareFunc(subOptionActive, subOption?.get("id")) &&
                styles.subActive
            )}
          >
            {`${parentLabel} / ${item}`}
          </button>
        );
      })}
    </div>
  );
}
