import Loadable from "@loadable/component";
import React from "react";

import LoadableLoading from "components/Common/LoadableLoading";

const AlertViewContainer = Loadable(() => import("./AlertViewContainer"));

function AlertsViewAsyncLoading(props) {
  return <LoadableLoading {...props} />;
}

const AlertViewContainerContainer = (props) => {
  return (
    <AlertViewContainer {...props} fallback={<AlertsViewAsyncLoading />} />
  );
};

export default AlertViewContainerContainer;
