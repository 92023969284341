import PropTypes from "prop-types";
import { memo } from "react";
import { Route } from "react-router-dom";

import LoadingOverlay from "components/Common/LoadingOverlay";
import ModalRoot from "components/Common/ModalRoot";
import WindowResizeListener from "components/Common/WindowResizeListener";
import ErrorBoundary from "components/Errors/ErrorBoundary";
import AppDocumentHead from "pages/AppDocumentHead";

import useAddModules from "hooks/useAddModules";
import useWindowResizeListener from "hooks/useWindowResizeListener";

function OAuthRoute(props) {
  const { component, modules, path } = props;

  const { allModulesLoaded } = useAddModules({ modules, path });

  const { onResize } = useWindowResizeListener();

  return (
    <ErrorBoundary>
      <WindowResizeListener onResize={onResize} />
      <AppDocumentHead />
      <Route
        path={path}
        children={() => {
          return allModulesLoaded ? (
            component
          ) : (
            <LoadingOverlay withPageHeader />
          );
        }}
      />
      <ModalRoot />
    </ErrorBoundary>
  );
}

OAuthRoute.propTypes = {
  modules: PropTypes.array,
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  component: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.element,
  ]),
};

OAuthRoute.defaultProps = {
  modules: [],
  path: null,
  component: null,
};

export default memo(OAuthRoute);
