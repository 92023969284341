import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const ProfileProView = Loadable(() => import("./ProfileProView"));

function ProViewAsyncLoading(props) {
  return <LoadableLoading {...props} page />;
}

const ProfileProViewContainer = (props) => {
  return <ProfileProView {...props} fallback={<ProViewAsyncLoading />} />;
};

export default ProfileProViewContainer;
