/* eslint-disable react/jsx-no-target-blank */
import { css } from "aphrodite";
import { memo, useState, useCallback } from "react";
import { Transition } from "react-transition-group";

import PodchaserProBadge from "components/Branding/PodchaserProBadge";
import Tabs from "components/Common/Tabs";
import HeaderSearch from "components/Layout/Header/HeaderSearch";

import tabTokens from "../../styles/tokens/tokens-tab";
import HomepageDesktopHeaderSubTitle from "./HomepageDesktopHeaderSubTitle";
import HomepageBackgroundLeft from "./Images/HomepageBackgroundLeft.png";
import HomepageBackgroundRight from "./Images/HomepageBackgroundRight.png";

import modalActions from "actions/modals";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes, { createBreakpoint } from "styles/ScreenSizes";

const baseStyles = {
  container: {
    ...gStyles.gradientBackground,
    color: colours.white,
    position: "relative",
    paddingTop: 0,

    [ScreenSizes.lgAndAbove]: {
      paddingTop: 110,
    },

    [createBreakpoint({ min: 1400 })]: {
      paddingTop: 74,
    },
  },
  wrapper: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    ...gStyles.avalonBold,
    fontSize: "2rem",
    textAlign: "center",
    margin: 0,
    marginBottom: "2rem",
    marginTop: "1.5rem",

    [ScreenSizes.lgAndAbove]: {
      marginTop: "3rem",
      fontSize: "3rem",
    },
  },
  show: {
    display: "block",
  },
  hide: {
    display: "none",
    opacity: 0,
  },
  subTitle: {
    ...gStyles.fontRegular,
    fontSize: "1rem",
    textAlign: "center",
    maxWidth: "50rem",
    margin: "0 auto",
    padding: "0 0.5rem",
    lineHeight: "2rem",
  },
  tabsContainer: {
    marginTop: "2rem",
    zIndex: 1,

    display: "inline-flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",

    padding: "1rem 0.5rem",

    borderRadius: "24px",
    border: "1px solid var(--secondary-d-2, #3A0066)",
    background:
      "linear-gradient(180deg, rgba(90, 8, 145, 0.80) 0%, rgba(51, 0, 102, 0.80) 100%), #8824D7",
    boxShadow: "0px 32px 64px -12px rgba(16, 24, 40, 0.14)",

    [ScreenSizes.lgAndAbove]: {
      minWidth: "45rem",

      gap: "1.5rem",

      padding: "2rem",
      marginTop: "3rem",
    },
  },
  tabsSelectorContainer: {
    display: "inline-flex",
    alignItems: "flex-start",
    gap: "2rem",
    margin: "0 1rem",
  },
  tabButtonHideOnMobile: {
    display: "none",

    [ScreenSizes.lgAndAbove]: {
      display: "block",
    },
  },
  inputSearch: {
    width: "614px",
    height: "48px",
    flexShrink: 0,
  },
  proSection: {
    ...gStyles.fontRegular,

    background: "#1B003366",
    width: "100%",

    textAlign: "center",
    fontSize: "1rem",
    lineHeight: "2rem",

    paddingTop: "3.5rem",
    paddingBottom: "3rem",
    marginTop: "-2rem",
    marginBottom: 0,

    [ScreenSizes.lgAndAbove]: {
      paddingBottom: "1.5rem",
    },
  },
  proBadge: {
    marginLeft: "0.25rem",
    marginRight: "0.25rem",
  },
  proSectionBold: {
    ...gStyles.avalonBold,
  },
  backgroundLeftImage: {
    backgroundImage: `url(${HomepageBackgroundLeft})`,
    backgroundSize: "contain",

    position: "absolute",
    top: 0,

    width: "50%",
    height: "100%",
  },
  backgroundRightImage: {
    backgroundImage: `url(${HomepageBackgroundRight})`,
    backgroundSize: "contain",
    backgroundPosition: "right top",

    position: "absolute",
    right: 0,
    top: 0,

    width: "50%",
    height: "100%",
  },
  proSectionViewDemo: {
    ...gStyles.avalonBold,
    border: "none",
    cursor: "pointer",
  },
};

export const TABS_KEYS = {
  ALL: "ALL",
  PODCASTS: "PODCASTS",
  EPISODES: "EPISODES",
  CREDITS: "CREDITS",
  LISTS: "LISTS",
  BRANDS: "BRANDS",
};

const TABS_MOBILE = [
  {
    key: TABS_KEYS.ALL,
    title: "All",
    componentType: "button",
  },
  {
    key: TABS_KEYS.PODCASTS,
    title: "Podcasts",
    componentType: "button",
  },
  {
    key: TABS_KEYS.CREDITS,
    title: "Credits",
    componentType: "button",
  },
  {
    key: TABS_KEYS.LISTS,
    title: "Lists",
    componentType: "button",
  },
];

const TABS = [
  {
    key: TABS_KEYS.ALL,
    title: "All",
    componentType: "button",
  },
  {
    key: TABS_KEYS.PODCASTS,
    title: "Podcasts",
    componentType: "button",
  },
  {
    key: TABS_KEYS.EPISODES,
    title: "Episodes",
    componentType: "button",
  },
  {
    key: TABS_KEYS.CREDITS,
    title: "Credits",
    componentType: "button",
  },
  {
    key: TABS_KEYS.LISTS,
    title: "Lists",
    componentType: "button",
  },
  {
    key: TABS_KEYS.BRANDS,
    title: "Brands",
    anchor: "/brands",
    onClick: () => {
      sendGAEvent({
        action: "headerTabClick",
        context: "HomePage",
        tab: TABS_KEYS.BRANDS,
      });
    },
    pro: true,
  },
];

const TAB_STYLES = {
  tab: {
    ...gStyles.avalonBold,
    display: "inline-flex",
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: 10,
    borderBottom: "2px transparent solid",
    cursor: "pointer",

    color: tabTokens.color.text.inactive.onImpact.default,
    fontSize: "1rem",
    lineHeight: "1.5rem",
    border: "none",
  },
  currentTab: {
    paddingBottom: 10,
    borderBottom: `4px ${colours.primary} solid`,
    color: tabTokens.color.text.active.onImpact.default,
  },
  tabContainer: {
    padding: "0 0.375rem",
  },
  tabs: {
    marginBottom: "0.5rem",
  },
};

const headerSearchOverride = {
  headerSearch: {
    width: "100%",

    [ScreenSizes.mdAndBelow]: {
      background: "none",
      position: "static",
      left: 0,
      top: 0,
      margin: 0,
      width: "90%",
      maxWidth: "90%",
      transform: "none",
    },

    [ScreenSizes.xlAndAbove]: {
      minWidth: "100%",
    },
  },
  showHeaderSearch: {
    [ScreenSizes.mdAndBelow]: {
      transform: "none",
      position: "static",
    },
  },
};

const SEARCH_AUTO_COMPLETE_STYLES = {
  container: {
    zIndex: 1,
    transition: "none",
    [ScreenSizes.lgAndAbove]: {
      transition: "none",
      maxWidth: "47rem",
      minWidth: "17rem",
    },
    [ScreenSizes.xlAndAbove]: {
      minWidth: "24.375rem",
    },
    [createBreakpoint({ min: 1400 })]: {
      marginLeft: 0,
    },
  },
};

const duration = 350;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
  height: 0,
};

const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1, height: "auto" },
  exiting: { opacity: 0, height: "auto" },
  exited: { opacity: 0 },
};

const HomepageDesktopHeader = (props) => {
  const { styles } = useStyles(baseStyles, props);

  const windowSize = useWindowSize();
  const isMobileAndTablet = windowSize.isWindowSizeOrLess("medium");

  const [tab, setTab] = useState(TABS_KEYS.ALL);

  const { showModal } = useActionCreators({
    showModal: modalActions.showModal,
  });

  const handleFocus = useCallback(() => {
    sendGAEvent({
      action: "searchFocus",
      page: "LoggedOutHomepage",
    });
  }, []);

  const handleSearchChange = useCallback((term) => {
    sendGAEvent({
      action: "searchChange",
      term,
      page: "LoggedOutHomepage",
    });
  }, []);

  const handleTabChange = useCallback((tab) => {
    setTab(tab.key);

    sendGAEvent({
      action: "headerTabClick",
      context: "HomePage",
      page: "LoggedOutHomepage",
      tab: tab.key,
    });
  }, []);

  const handleProClick = (event) => {
    event.preventDefault();

    sendGAEvent({
      action: "submittedProLeadFormModal",
      entity_type: "podcast",
      page: "NewHomePage",
      context: "NewHomePageHeader",
    });

    showModal("proLeadForm", {
      onDemoRequestSubmitted: () =>
        sendGAEvent({
          action: `submittedProLeadFormModal`,
          entity_type: "podcast",
          page: "NewHomePage",
          context: "NewHomePageHeader",
        }),
    });
  };

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.backgroundLeftImage)} />
      <div className={css(styles.backgroundRightImage)} />
      <div className={css(styles.wrapper)}>
        <Transition in={tab === TABS_KEYS.ALL} timeout={duration}>
          {(state) => (
            <div
              style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}
            >
              <h1 className={css(styles.title)}>
                The ultimate database for everything podcasting
              </h1>
              <h2 className={css(styles.subTitle)}>
                Podchaser is the world’s best podcast database and search engine
                – powering discovery for listeners, creators, brands, and
                agencies.
              </h2>
            </div>
          )}
        </Transition>

        <Transition in={tab === TABS_KEYS.PODCASTS} timeout={duration}>
          {(state) => (
            <div
              style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}
            >
              <h1 className={css(styles.title)}>
                Search and browse over 5 million podcasts
              </h1>
              <h2 className={css(styles.subTitle)}>
                Explore the world’s largest selection of podcasts by categories,
                demographics, ratings, reviews, host and guest credits, and
                more.
              </h2>
            </div>
          )}
        </Transition>

        <Transition in={tab === TABS_KEYS.EPISODES} timeout={duration}>
          {(state) => (
            <div
              style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}
            >
              <h1 className={css(styles.title)}>
                Discover episodes on your favourite topics
              </h1>
              <h2 className={css(styles.subTitle)}>
                Explore a world of topics in every episode. Search hundreds of
                millions of podcast episodes — keep up with the latest news,
                follow your interests, or learn all about a new subject.
              </h2>
            </div>
          )}
        </Transition>

        <Transition in={tab === TABS_KEYS.CREDITS} timeout={duration}>
          {(state) => (
            <div
              style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}
            >
              <h1 className={css(styles.title)}>
                Meet the people behind your favorite podcasts
              </h1>
              <h2 className={css(styles.subTitle)}>
                Just like movie credits, podcast credits let you browse and keep
                track of each podcast’s hosts, guests, voice actors, and crew.
              </h2>
            </div>
          )}
        </Transition>

        <Transition in={tab === TABS_KEYS.LISTS} timeout={duration}>
          {(state) => (
            <div
              style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}
            >
              <h1 className={css(styles.title)}>
                Podcast playlists handpicked by real people
              </h1>
              <h2 className={css(styles.subTitle)}>
                Discover lists of podcasts and episodes curated by the Podchaser
                community or build your own to share with friends and
                colleagues.
              </h2>
            </div>
          )}
        </Transition>

        <Transition in={tab === TABS_KEYS.BRANDS} timeout={duration}>
          {(state) => (
            <div
              style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}
            >
              <h1 className={css(styles.title)}>
                Track podcast sponsors and advertisers
              </h1>
              <h2 className={css(styles.subTitle)}>
                Keep tabs on how brands invest in podcasts using Podchaser's
                unique tools for ad tracking, spend data, airchecks, and more.
              </h2>
            </div>
          )}
        </Transition>

        <div className={css(styles.tabsContainer)}>
          <div className={css(styles.tabsSelectorContainer)}>
            <Tabs
              tabs={isMobileAndTablet ? TABS_MOBILE : TABS}
              tabKey={tab}
              onTabChange={handleTabChange}
              styles={TAB_STYLES}
            />
          </div>

          <HeaderSearch
            showTip={!isMobileAndTablet}
            styles={headerSearchOverride}
            switchToWhiteStyles
            onFocus={handleFocus}
            onSearchChange={handleSearchChange}
            searchAutocompleteStyles={SEARCH_AUTO_COMPLETE_STYLES}
            {...(tab === TABS_KEYS.PODCASTS
              ? {
                  searchHref: "/search/podcasts/q/",
                  placeholder: "Search podcasts...",
                }
              : {})}
            {...(tab === TABS_KEYS.EPISODES
              ? {
                  searchHref: "/search/episodes/q/",
                  placeholder: "Search episodes...",
                }
              : {})}
            {...(tab === TABS_KEYS.CREDITS
              ? {
                  searchHref: "/search/creators/q/",
                  placeholder: "Search creators...",
                }
              : {})}
            {...(tab === TABS_KEYS.LISTS
              ? {
                  searchHref: "/search/lists/q/",
                  placeholder: "Search lists...",
                }
              : {})}
          />

          <HomepageDesktopHeaderSubTitle tab={tab} />
        </div>

        <h2 className={css(styles.proSection)}>
          <span className={css(styles.proSectionBold)}>
            Go{" "}
            <span className={css(styles.proBadge)}>
              <PodchaserProBadge height={"1.875rem"} />
            </span>{" "}
          </span>
          Access podcast audience data, demographics, contacts, sponsors, &
          more.
          <button
            className={css(styles.proSectionViewDemo)}
            onClick={handleProClick}
          >
            View Demo
          </button>
        </h2>
      </div>
    </div>
  );
};

export default memo(HomepageDesktopHeader);
