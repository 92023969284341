import { useMemo, useRef } from "react";
import { useDispatch } from "redux-react-hook";
import { bindPromiseCreators } from "redux-saga-routines";

function useRoutinePromises(promisifiedRoutines) {
  const dispatch = useDispatch();

  // let's us pass in an object that's created in the render, but won't change
  const promisesRef = useRef(promisifiedRoutines);

  return useMemo(
    () => bindPromiseCreators(promisesRef.current, dispatch),
    [dispatch]
  );
}

export default useRoutinePromises;
