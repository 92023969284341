import getFormattedPath from "./getFormattedPath";
import truncateUrlSlug from "./truncateUrlSlug";

import { formatURL } from "utils/format";

export function getRawEpisodeUrl(
  podcastId,
  podcastSlug,
  episodeId,
  episodeSlug,
  path = ""
) {
  const podcastSlugPart = podcastSlug ? `${truncateUrlSlug(podcastSlug)}-` : "";
  const episodeSlugPart = episodeSlug ? `${truncateUrlSlug(episodeSlug)}-` : "";
  const episodeUrl = `/podcasts/${podcastSlugPart}${
    podcastId || "0"
  }/episodes/${episodeSlugPart}${episodeId}${getFormattedPath(path)}`;

  return formatURL(episodeUrl);
}

export default function getEpisodeUrl(entity, options = {}) {
  if (entity) {
    return getRawEpisodeUrl(
      entity.getIn(["podcast", "id"]) ||
        options.podcastId ||
        (options.podcast && options.podcast.get("id")),
      entity.getIn(["podcast", "slug"]) ||
        options.podcastSlug ||
        (options.podcast && options.podcast.get("slug")),
      entity.getIn(["id"]),
      entity.getIn(["slug"]),
      options.path
    );
  }

  return undefined;
}
