import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const BlogPostViewContainer = Loadable(() => import("./BlogPostViewContainer"));

function BlogPostViewContainerAsyncLoading(props) {
  return <LoadableLoading {...props} page />;
}

const BlogPostViewContainerContainer = (props) => {
  return (
    <BlogPostViewContainer
      {...props}
      fallback={<BlogPostViewContainerAsyncLoading />}
    />
  );
};

export default BlogPostViewContainerContainer;
